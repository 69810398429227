export const SET_PRODUCT_IMPRESSION_STATE = 'SET_PRODUCT_IMPRESSION_STATE';
export type SET_PRODUCT_IMPRESSION_STATE = typeof SET_PRODUCT_IMPRESSION_STATE;

export const GET_PRODUCT_IMPRESSION_STATE = 'GET_PRODUCT_IMPRESSION_STATE';
export type GET_PRODUCT_IMPRESSION_STATE = typeof GET_PRODUCT_IMPRESSION_STATE;

export const CLEAR_PRODUCT_IMPRESSION = 'CLEAR_PRODUCT_IMPRESSION';
export type CLEAR_PRODUCT_IMPRESSION = typeof CLEAR_PRODUCT_IMPRESSION;

export const CLEAR_PRODUCT_DETAIL_IMPRESSION = 'CLEAR_PRODUCT_DETAIL_IMPRESSION';
export type CLEAR_PRODUCT_DETAIL_IMPRESSION = typeof CLEAR_PRODUCT_DETAIL_IMPRESSION;

export const BATCH_SEND_PRODUCT_IMPRESSION = 'BATCH_SEND_PRODUCT_IMPRESSION';
export type BATCH_SEND_PRODUCT_IMPRESSION = typeof BATCH_SEND_PRODUCT_IMPRESSION;
