import { useEffect } from 'react';
import { useMarketingWidget } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { clearSimilarProductCarousel, getSimilarProductCarousel } from 'store/reducers/ProductCarousel/actions';
import { SimilarProductLocation } from 'store/reducers/ProductCarousel/interface';
import { IMarketing, MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { getWidgetParamByMarketingType } from 'components/common/Widget/widget.util';
import { Product } from 'store/reducers/Product';
import {
  similarProductCarouselLoadingSelector,
  similarProductCarouselSelector,
  similarProductCarouselSkuCodeSelector,
} from 'store/reducers/ProductCarousel/selectors';

interface IUseSimilarProduct {
  similarProducts: Product[];
  similarproductLoading: boolean;
  clearSimilarProduct: () => void;
  skuCode: string | null;
  similarProductMarketing: IMarketing;
}

export const useSimilarProductDetail = (product: Product): IUseSimilarProduct => {
  const { marketingList } = useMarketingWidget();
  const dispatch = useDispatch();

  const [similarProductMarketing] =
    marketingList.filter((marketing) => marketing.type === MarketingType.SIMILAR_PRODUCT) || [];

  const params = getWidgetParamByMarketingType(MarketingType.SIMILAR_PRODUCT, product);

  const similarProductList = useSelector(similarProductCarouselSelector(SimilarProductLocation.DETAIL_PAGE));
  const loading = useSelector(similarProductCarouselLoadingSelector(SimilarProductLocation.DETAIL_PAGE));
  const skuCode = useSelector(similarProductCarouselSkuCodeSelector(SimilarProductLocation.DETAIL_PAGE));
  const clearSimilarProduct = (): void => {
    dispatch(clearSimilarProductCarousel(SimilarProductLocation.DETAIL_PAGE));
  };

  useEffect(() => {
    if (!similarProductMarketing) return;
    if (!product.sku_code) return;
    dispatch(
      getSimilarProductCarousel(
        { marketingId: similarProductMarketing.id, marketingType: similarProductMarketing.type, ...params },
        SimilarProductLocation.DETAIL_PAGE,
      ),
    );

    // eslint-disable-next-line consistent-return
    return () => {
      clearSimilarProduct();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [similarProductMarketing?.id, product.sku_code]);

  return {
    similarProducts: similarProductList,
    similarproductLoading: loading,
    clearSimilarProduct,
    skuCode,
    similarProductMarketing,
  };
};
