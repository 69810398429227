import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import * as colors from 'components/styles/colors';

const Wrapper = styled(Grid)`
  && {
    width: 100%;
  }
`;

const BaseContainer = styled(Grid)`
  && {
    padding: 10px 15px;
    background-color: ${colors.WHITE};
  }
`;

const TextContainer = styled.div`
  && {
    background: ${colors.REDISH_GRAY};
    border: 1px dashed #ff5b5b;
    border-radius: 4px;
    padding: 10px 15px;
  }
`;

const StockOutText = styled(Typography)`
  && {
    display: inline;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.16px;
  }
`;

const NotifyText = styled(Typography)`
  && {
    display: inline;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.16px;
  }
`;

export const S = { Wrapper, BaseContainer, TextContainer, StockOutText, NotifyText };
