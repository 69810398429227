import React from 'react';
import { translate } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import MessageIcon from '@material-ui/icons/Message';
import isEmpty from 'lodash/isEmpty';

import { State as StoreState } from 'store';
import { Modal } from 'components/common';

import { S } from './orders-status-modal.styles';
import { OrdersModalProducts } from '../orders-modal/orders-modal-products.component';

interface Props {
  open: boolean;
  onClose: () => void;
  openChat: () => void;
  t: any;
}

const OrderStatusModalChangesComponent: React.FC<React.PropsWithChildren<Props>> = ({ open, onClose, openChat, t }) => {
  const {
    purchaseOrder: { orderDetail },
  } = useSelector((state: StoreState) => state, shallowEqual);
  const items = !isEmpty(orderDetail) ? orderDetail.items : [];

  return (
    <Modal open={open} onClose={onClose}>
      <S.Wrapper>
        <S.Header>
          <Grid container alignItems="center" justify="center">
            <S.Title>{t('detailChanges')}</S.Title>
          </Grid>
          <S.CancelBtn onClick={onClose} />
        </S.Header>
        <div style={{ margin: '10px 0' }}>
          {items?.map((item) => (
            <OrdersModalProducts orderItem={item} key={item.product_id} />
          ))}
        </div>
        <S.Text style={{ padding: '10px 20px 20px', textAlign: 'center' }}>{t('needAssistanceForReturn')}</S.Text>
        <S.NeedAssistanceContainer style={{ margin: '10px' }}>
          <S.NeedAssistanceBtn onClick={openChat}>
            <MessageIcon /> {t('needAssistance')}
          </S.NeedAssistanceBtn>
        </S.NeedAssistanceContainer>
      </S.Wrapper>
    </Modal>
  );
};

export const OrderStatusModalChanges = translate('orders')(OrderStatusModalChangesComponent);
