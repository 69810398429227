import React from 'react';

import { translate } from 'react-i18next';
import { TranslateProps } from 'utils/Localization/types';

import { S } from './orders-history-filters.styles';

interface Props {
  isCalendarActive: boolean;
  isFilterActive: boolean;
  toggleCalendarModal: () => void;
  toggleFiltersModal: () => void;
}

const OrdersHistoryFiltersComponent: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({
  toggleCalendarModal,
  toggleFiltersModal,
  isCalendarActive,
  isFilterActive,
  t,
}) => (
  <S.PillFilterContainer>
    <S.PillContainer>
      <S.Pill>
        <S.PillButton order={1} onClick={toggleCalendarModal} data-testid="order-calendar">
          {isCalendarActive ? <S.ActiveIcon /> : ''}
          <S.CalendarIcon />
          {t('orderHistory.date')}
        </S.PillButton>
        <S.PillButton onClick={toggleFiltersModal} data-testid="order-filters">
          {isFilterActive ? <S.ActiveIcon /> : ''}
          <S.FilterIcon />
          Filters
        </S.PillButton>
      </S.Pill>
    </S.PillContainer>
  </S.PillFilterContainer>
);

export const OrdersHistoryFilters = translate('orders')(OrdersHistoryFiltersComponent);
