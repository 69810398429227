import { Button, Typography, Divider } from '@material-ui/core';
import { NotificationsActive } from '@material-ui/icons';
import * as colors from 'components/styles/colors';
import styled from 'styled-components';

export const OutOfStockText = styled(Typography)`
  && {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.25px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: ${colors.RED};
  }
`;

export const PriceLabel = styled(Typography)`
  && {
    color: ${colors.GRAY};
    font-weight: 700;
    font-size: 10px;
  }
`;

export const SubtotalPrice = styled(Typography)`
  && {
    color: ${colors.LIGHT_GRAY};
    text-decoration: line-through;
    font-weight: 400;
    font-size: 10px;
  }
`;

export const TotalPrice = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
  }
`;

export const RoundedButton = styled(Button)`
  && {
    border-radius: 20px;
    border-color: ${colors.REBRAND_GREEN};
    flex: 100%;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0.2rem 1rem;
`;

export const PriceContainer = styled.div`
  flex: 100%;
  margin-left: 10px;
`;

export const OutOfStockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.LIGHT_RED};
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 2px;
  padding: 5px 12px 5px 12px;
  height: min-content;
`;

export const MaxPurchaseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.PURPLE_LIGHT};
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 2px;
  padding: 5px 12px 5px 12px;
  height: min-content;
`;

export const MaxPurchaseText = styled(Typography)`
  && {
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-align: center;
    color: ${colors.PURPLE_DARK};
  }
`;

export const BellIcon = styled(NotificationsActive)`
  font-size: 16px;
  margin-right: 8px;
`;

export const CartDivider = styled(Divider)`
  && {
    color: ${colors.WEAK_GRAY};
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const NotAvailableText = styled(Typography)`
  && {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    width: 100%;
    color: ${colors.GRAY50};
  }
`;
