import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { NotificationsActiveOutlined } from '@material-ui/icons';

import { translate } from 'react-i18next';

import { DistributorCartProduct } from 'store/reducers/Cart';
import { TranslateProps } from 'utils/Localization';

interface Props {
  product: DistributorCartProduct;
}

const LowOnStockBox = styled(Grid)`
  width: 100%;
  height: 38px;
  background: #fff7ef;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 2px 0px;

  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16px;

  color: #dd5435;

  .notification-icon {
    font-size: 24px;
    margin: 0px 10px;
  }
`;

const BaseProductCardLowStockInfo: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ t, product }) => {
  const { stock } = product;
  const { remaining_quantity, quantity_threshold, low_stock_threshold } = stock;

  if (remaining_quantity <= low_stock_threshold) {
    const availableStock = remaining_quantity - quantity_threshold;
    const stockLeft = remaining_quantity >= quantity_threshold ? availableStock : 0;
    if (stockLeft <= 0) return null;
    return (
      <LowOnStockBox className="ls-box">
        <NotificationsActiveOutlined className="notification-icon" /> {t('lowOnStock', { stockLeft })}
      </LowOnStockBox>
    );
  }
  return null;
};

export const ProductCardLowStockInfo = translate('stockOut')(BaseProductCardLowStockInfo);
