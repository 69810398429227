import styled from 'styled-components';
import { Favorite } from '@material-ui/icons';
import * as colors from 'components/styles/colors';

export const FavoriteLink = styled(Favorite)`
  && {
    color: ${colors.BLUISH_GRAY};
    width: 1em;
    height: 1em;
  }
`;

export const FavoriteLinkContainer = styled.div`
  padding: 3px 3px 3px 3px;
  display: flex;
`;
