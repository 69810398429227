export const DOCUMENT_REQUIREMENT_STATUS_UPLOADED = 'UPLOADED' as const;
export const DOCUMENT_REQUIREMENT_STATUS_PENDING = 'PENDING' as const;

export const DOCUMENT_REQUIREMENT_TYPE_PREKURSOR = 'prekursor_document';
export const DOCUMENT_REQUIREMENT_TYPE_COLDCHAIN = 'coldchain_document';
export const DOCUMENT_REQUIREMENT_TYPE_ALKES = 'alkes';
export const DOCUMENT_REQUIREMENT_TYPE_REGULAR = 'regular';

export const DOCUMENT_REQUIREMENT_PREKURSOR_FLAG = 'P';
export const DOCUMENT_REQUIREMENT_COLDCHAIN_FLAG = 'C';
export const DOCUMENT_REQUIREMENT_ALKES_FLAG = 'A';

export type DocumentRequirementStatus =
  | typeof DOCUMENT_REQUIREMENT_STATUS_UPLOADED
  | typeof DOCUMENT_REQUIREMENT_STATUS_PENDING;

export type DocumentRequirementType =
  | typeof DOCUMENT_REQUIREMENT_TYPE_PREKURSOR
  | typeof DOCUMENT_REQUIREMENT_TYPE_COLDCHAIN
  | typeof DOCUMENT_REQUIREMENT_TYPE_ALKES;

export interface PurchaseOrderDocumentRequirement {
  requirement_type: DocumentRequirementType;
  status: DocumentRequirementStatus;
  purchase_order_id: number;
  value?: string;
}
