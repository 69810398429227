import React, { useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-i18next';
import qs from 'query-string';

import { Header, HeaderContent } from 'components/common/header';

import { PaymentDirectPaymentStatus } from 'services/swipe-rx-pt/resources/payment-direct-bills/types';
import { VoucherUsage } from 'components/common/VoucherUsage';
import { routeKey } from 'utils/route.utils';
import { SkeletonLoader } from '../../common/skeleton-loader';

import { PaymentDetails, DarkerSkeletonLoader } from './components';
import { BillPaymentStatusNotification } from './BillPaymentStatusNotification.component';
import S from './DirectPaymentPage.styles';
import { useGetBillDetails } from './useGetBillDetails.hook';
import { usePayNow } from './usePayNow.hook';

interface OwnProps {
  bill_code: string;
}

type Props = { t: any } & RouteComponentProps<OwnProps>;

export const DirectPaymentPageComponent: React.FC<React.PropsWithChildren<Props>> = ({ t, match, history, location }) => {
  const [finalQrCode, setFinalQrCode] = useState('');
  const [finalBillReference, setFinalBillReference] = useState('');
  const { bill_code } = match.params;

  const queryParams = qs.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const isFullVoucherPayment = queryParams.voucherPaid;

  const {
    isLoading: gbIsLoading,
    billReference: gbBillReference,
    qrCodeUrl: gbQrCodeUrl,
    amount,
    paymentStatus,
    paymentDirectId,
    voucherUsage,
    updateBillReference,
  } = useGetBillDetails(bill_code);

  const initializePayNow = !gbIsLoading && !gbBillReference;
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: pnIsLoading,
    qrCodeUrl: pnQrCodeUrl,
    billReference: pnBillReference,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    message: pnMessage,
  } = usePayNow(paymentDirectId, initializePayNow, t);

  useEffect(() => {
    setFinalBillReference(gbBillReference || pnBillReference);

    if (pnBillReference) {
      updateBillReference(pnBillReference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gbBillReference, pnBillReference]);

  useEffect(() => {
    setFinalQrCode(gbQrCodeUrl || pnQrCodeUrl);
  }, [gbQrCodeUrl, pnQrCodeUrl]);

  const isBillPaid = paymentStatus === PaymentDirectPaymentStatus.PAID;

  const handleCloseClick = (): void => history.push(`${routeKey('main_payments')}/unpaid`);

  const FooterInstructionSteps = (): JSX.Element => (
    <>
      {Array(5)
        .fill(0)
        .map((_item, index) => (
          <S.FooterInstructionItem>
            {index + 1}. {t(`footerInstructionDetails.step${index + 1}`)}
          </S.FooterInstructionItem>
        ))}
    </>
  );

  return (
    <S.Container>
      <Header>
        <HeaderContent title={t('headerTitle')} goBack={handleCloseClick} />
      </Header>
      <Grid container direction="column" alignItems="center" justify="center">
        <BillPaymentStatusNotification
          billReference={gbBillReference}
          isBillPaid={isBillPaid}
          isFullyPaid={isFullVoucherPayment && !gbIsLoading}
        />
        {!isFullVoucherPayment && (
          <>
            <Grid item>
              <S.Instructions style={{ marginTop: isBillPaid ? '14px' : '26px' }}>{t('instructions')}</S.Instructions>
              {!finalBillReference && <DarkerSkeletonLoader height={20} width={268} />}
              {finalBillReference && <S.BillReference>{finalBillReference}</S.BillReference>}
            </Grid>
            <Grid item>
              <S.QRCodeSection>
                {!finalQrCode && <DarkerSkeletonLoader height={268} width={268} />}
                {finalQrCode && <S.QRCode alt="Payment QR Code" src={finalQrCode} />}
              </S.QRCodeSection>
            </Grid>
          </>
        )}

        <S.PaymentDetailsSection>
          {!gbIsLoading && (
            <PaymentDetails
              amount={Math.max(amount - (voucherUsage?.total_discount || 0), 0)}
              paid={isFullVoucherPayment}
              billCode={bill_code}
              billReference={finalBillReference}
            />
          )}
          {!isFullVoucherPayment && gbIsLoading && <SkeletonLoader height={52} width={500} />}
        </S.PaymentDetailsSection>

        <div style={{ width: '100%' }}>
          <VoucherUsage voucherUsage={voucherUsage} />
        </div>

        {!isFullVoucherPayment && (
          <S.FooterInstruction>
            <S.FooterInstructionTitle>
              <S.FooterInstructionTitleIcon />
              {` ${t('footerInstructionTitle')}`}
            </S.FooterInstructionTitle>
            <FooterInstructionSteps />
          </S.FooterInstruction>
        )}
      </Grid>
    </S.Container>
  );
};

export const DirectPaymentPage = translate('directPayment')(DirectPaymentPageComponent);
