import React from 'react';
import * as colors from 'components/styles/colors';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

export const OrdersPrekursorDownloadIcon: React.FC<React.PropsWithChildren<Props>> = ({ height, width, fill }) => (
  <svg width={width} height={height} viewBox="0 0 12 11" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33333 9.58333H10.6667V5.5H11.8333V10.1667C11.8333 10.3214 11.7719 10.4697 11.6625 10.5791C11.5531 10.6885 11.4047 10.75 11.25 10.75H0.749997C0.595288 10.75 0.446915 10.6885 0.337518 10.5791C0.228122 10.4697 0.166664 10.3214 0.166664 10.1667V5.5H1.33333V9.58333ZM7.16666 3.75H10.0833L6 7.83333L1.91666 3.75H4.83333V0.25H7.16666V3.75Z"
      fill={fill}
    />
  </svg>
);

OrdersPrekursorDownloadIcon.defaultProps = {
  fill: colors.PRIMARY_COLOR,
  width: '12px',
  height: '11px',
};
