import * as React from 'react';

import { Favorite as MUIFavorite, FavoriteBorder as MUINoFavorite } from '@material-ui/icons';

import ShowIf from 'components/views/ShowIf';
import { FavoriteButtonContainer, StyledIconButton } from './favorite-button.styles';

interface Props {
  id?: string;
  favorited: boolean;
  location?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => any;
}

export const FavoriteButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { id, favorited, location = { bottom: '8px', right: '8px' }, onClick } = props;

  return (
    <FavoriteButtonContainer location={location} id={id}>
      <StyledIconButton onClick={onClick}>
        <ShowIf condition={favorited}>
          <MUIFavorite data-testid="favorite-btn" style={{ fill: 'orangered' }} />
        </ShowIf>
        <ShowIf condition={!favorited}>
          <MUINoFavorite />
        </ShowIf>
      </StyledIconButton>
    </FavoriteButtonContainer>
  );
};

FavoriteButton.defaultProps = {
  id: undefined,
  location: undefined,
  onClick: undefined,
};

export default FavoriteButton;
