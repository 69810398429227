import { useDispatch } from 'react-redux';
import { setOpenSnackbar } from 'store/reducers/Utils/actions';
import { routeKey } from 'utils/route.utils';
import { State as AuthState } from 'store/reducers/Auth';
import { Product } from 'store/reducers/Product';
import { isVerifiedNoSipnapAndPrekursor } from 'utils/Membership';
import { SnackbarHook } from '../snackbar.type';

export const useUnverifiedInfoHook = (auth: AuthState, product: Product): SnackbarHook => {
  const dispatch = useDispatch();

  const isSipnapNoVerified = isVerifiedNoSipnapAndPrekursor(auth.coreUser?.membership_status, product?.flag);

  const unverifiedInfoSnackbar = {
    open: true,
    message: isSipnapNoVerified ? 'unverifiedPharmacy.missingSipnap' : 'unverifiedPharmacy.unpurchasableInfo',
    titleAction: 'unverifiedPharmacy.learnMore',
    contentProps: { background: '#1d27f1' },
    actionPath: routeKey('main_accounts_benefits'),
  };

  const showMessage = (): void => {
    dispatch(setOpenSnackbar(unverifiedInfoSnackbar));
  };

  return {
    showMessage,
  };
};
