import React, { useState } from 'react';

import { Box, IconButton } from '@material-ui/core';

import { ChevronRight, Search } from '@material-ui/icons';
import ShowIf from 'components/views/ShowIf';
import { generateRandomId } from 'utils/Random';
import { Img } from 'components/pages/ProductDetailPage/components/ProductImage/style';
import { Button } from 'components/common/Button';

import { S } from './search-sections.style';
import { SectionHeader } from './SectionHeader.component';
import { itemToString } from '../../utils';
import { getStyledProductName, SuggestionBoldStyling } from './search-sections.util';
import { SECTION_TITLE_TRANSLATION } from '../../constant';
import { IBaseSection, IItemConfig } from '../interface';
import { AddToCartIconButton } from './AddToCartIconButton.component';
import { ListSectionLoader } from './ListSectionLoader.component';

interface IListSection extends IBaseSection {
  hideThumbnail?: boolean;
  boldStyling?: SuggestionBoldStyling;
  sectionId: string;
  showCartButton?: boolean;
  hideManufacturer?: boolean;
  onSeeAllButton?: () => void;
  items: IItemConfig;
  highlightedIndex: number;
  sectionIndex: number;
  isSectionGeneral: boolean;
  sectionSeeAllAction?: (param?: any) => void;
  onClickCartIcon?: (prodId?: any) => void;
  totalCount?: number;
  showSearchIcon?: boolean;
  shouldNotShowLoadingIfEmpty?: boolean;
  isLoading?: boolean;
  onSelect: (selectedItem) => void;
}

export const ListSection: React.FC<React.PropsWithChildren<IListSection>> = (props) => {
  const {
    sectionId,
    t,
    title,
    expandable,
    subTitle,
    data = [],
    expanded = false,
    limit,
    additionalExpandFunction,
    onSeeAllButton,
    items,
    isSectionGeneral,
    sectionIndex,
    hideThumbnail,
    hideManufacturer,
    highlightedIndex,
    inputValue = '',
    showCartButton,
    sectionSeeAllAction,
    expandSection = '',
    onClickCartIcon,
    onExpand,
    boldStyling,
    totalCount,
    showSearchIcon,
    isLoading = false,
    shouldNotShowLoadingIfEmpty = true,
    onSelect,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const dataDisplay = !isExpanded ? data.slice(0, limit) : data;

  const toggle = (): void => {
    const newExpandedValue = !isExpanded;
    setIsExpanded(newExpandedValue);
    if (onExpand && newExpandedValue) {
      onExpand();
    }

    if (additionalExpandFunction) {
      const titleExpand = isExpanded ? '' : title;
      additionalExpandFunction(titleExpand);
    }
  };

  const styledNameMargin = showSearchIcon ? { marginLeft: '32px' } : undefined;
  const showSkeletonLoader = !shouldNotShowLoadingIfEmpty && !data.length && isLoading;

  if (showSkeletonLoader) {
    return <ListSectionLoader title={title} subTitle={subTitle} t={t} />;
  }
  const hasEmptyData = !data.length && !isLoading;

  return (
    <ShowIf condition={(!expandSection || expandSection === title) && !hasEmptyData}>
      <S.Section id={`tour__search__${title}`}>
        <SectionHeader
          t={t}
          isExpanded={isExpanded}
          title={title === SECTION_TITLE_TRANSLATION.PRODUCTS ? '' : title}
          toggle={toggle}
          subTitle={subTitle}
          expandable={expandable}
          sectionSeeAllAction={sectionSeeAllAction}
          onSeeAllButton={onSeeAllButton}
          headerIcon={
            <IconButton style={{ position: 'absolute', top: 4, right: 0 }} onClick={onSeeAllButton}>
              <ChevronRight />
            </IconButton>
          }
        />

        <ShowIf condition={!!dataDisplay.length}>
          {dataDisplay.map((product, dataIndex) => {
            items.itemIndex += 1;
            const index = items.itemIndex;
            const productName = itemToString(product);
            const styledProductName = getStyledProductName(productName, inputValue, isSectionGeneral, boldStyling);
            const key = generateRandomId(sectionIndex + productName);
            const handleOnClick = (): void => {
              onSelect({
                ...product,
                sectionId,
                index: dataIndex + 1,
                title,
                isActive: highlightedIndex === index,
              });
            };
            return (
              <div style={{ position: 'relative' }} key={key}>
                <S.Item
                  data-testid={`searched-product-${product?.id}`}
                  key={key}
                  customPadding={!hideThumbnail && '4rem'}
                  marginRight={showCartButton && '42px'}
                  onClick={handleOnClick}
                >
                  <ShowIf condition={!hideThumbnail}>
                    <S.ImageWrapper isEmpty={!product.thumbnail_photo}>
                      <Img src={product.thumbnail_photo} alt="" />
                    </S.ImageWrapper>
                  </ShowIf>

                  <div style={{ position: 'absolute' }}>
                    <ShowIf condition={!!showSearchIcon}>
                      <Search />
                    </ShowIf>
                  </div>

                  <div style={styledNameMargin}>
                    {styledProductName}
                    <div style={{ color: 'gray', fontSize: '.7em' }}>
                      {product.packaging && <span id="tour__search__packaging"> {product.packaging} </span>}
                    </div>
                    <div style={{ color: '#B3B3B3', fontSize: '8px' }}>
                      {product.manufacturer && !hideManufacturer && (
                        <span id="tour__search__manufacturer"> {product.manufacturer} </span>
                      )}
                    </div>
                  </div>
                </S.Item>
                <ShowIf condition={!!showCartButton}>
                  <AddToCartIconButton onClickCartIcon={onClickCartIcon} product={product} />{' '}
                </ShowIf>
              </div>
            );
          })}
        </ShowIf>
        <ShowIf condition={!!onSeeAllButton}>
          <Box px="16px" pb="16px">
            <Button fullWidth color="primary" variant="contained" onClick={onSeeAllButton}>
              {t('seeAllRelated', { count: totalCount })}
            </Button>
          </Box>
        </ShowIf>
      </S.Section>
    </ShowIf>
  );
};

ListSection.defaultProps = {
  hideThumbnail: false,
  showCartButton: false,
  hideManufacturer: false,
  onSeeAllButton: undefined,
  sectionSeeAllAction: undefined,
  onClickCartIcon: undefined,
  boldStyling: SuggestionBoldStyling.BOLD_QUERY,
  totalCount: 0,
  showSearchIcon: false,
  shouldNotShowLoadingIfEmpty: true,
  isLoading: false,
};
