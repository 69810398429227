import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import * as colors from 'components/styles/colors';

const Header = styled(Grid)`
  background: ${colors.WHITE};
  text-transform: capitalize;
  width: 100vw;
  padding: 18px 20px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 26px;
  z-index: 10;
`;

export const S = {
  Header
};
