import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Header, HeaderContent } from 'components/common/header';
import Layout from 'components/views/Layout';
import { TranslateProps } from 'utils/Localization/types';
import { Tab } from 'components/common/Tabs';
import { TabPage } from 'components/common/Tabs/Tab.types';
import { ProductRequestDialog } from 'components/common/ProductRequest';
import { routeKey } from 'utils/route.utils';

import * as colors from '../../styles/colors';
import { WishlistContents as TabContents } from './components';

export const WishlistPage: React.FunctionComponent<React.PropsWithChildren<RouteComponentProps<any> & TranslateProps>> = (props) => {
  const { t, history } = props;
  const {
    location: { pathname, state },
  } = history;
  const { from_instock_reminder } = (state as { from_instock_reminder?: string }) || {};
  const shouldBackToHome = window.history.length <= 3 || from_instock_reminder;
  const tabs: TabPage[] = [
    {
      id: 'tour__favorites__tab',
      path: 'favorites',
      label: t('favorites'),
    },
    {
      id: 'tour__reminders__tab',
      path: 'reminders',
      label: t('reminders'),
    },
    {
      id: 'tour__suggestions__tab',
      path: 'suggestions',
      label: t('comingSoon'),
    },
  ];
  const lastPath = pathname.split('/')[3];
  return (
    <Layout full background={colors.OFF_WHITE}>
      <Header id="tour__wishlist">
        <HeaderContent
          showContent
          goBack={() => {
            if (shouldBackToHome) {
              history.push({
                pathname: `${routeKey('main_home')}/`,
              });
            } else {
              history.goBack();
            }
          }}
          title={t('wishlist')}
        />
      </Header>
      <Tab
        tabs={tabs}
        tabContents={TabContents}
        pathname={lastPath}
        style={{ backgroundColor: colors.WHITE, textAlign: 'center', justifyContent: 'center' }}
        overrideBg={colors.WHITE}
        noBrowserHistory
      />
      <ProductRequestDialog />
    </Layout>
  );
};
