/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from 'reselect';
import { State as StoreState } from 'store';

import { State as ProductCarouselState } from '.';
import { SimilarProductLocation } from './interface';

const selectorProductCarousel = (state: StoreState): ProductCarouselState => state.productCarousel;

// Selector Similar product state based on location
export const similarProductCarouselState = (location: SimilarProductLocation) =>
  createSelector(selectorProductCarousel, (state) => state.similarProduct[location]);

// Selector product
export const similarProductCarouselSelector = (location: SimilarProductLocation) =>
  createSelector(selectorProductCarousel, (state) => state.similarProduct[location].products);

// Selector sku code
export const similarProductCarouselSkuCodeSelector = (location: SimilarProductLocation) =>
  createSelector(selectorProductCarousel, (state) => state.similarProduct[location].skuCode);

// Selector loading
export const similarProductCarouselLoadingSelector = (location: SimilarProductLocation) =>
  createSelector(selectorProductCarousel, (state) => state.similarProduct[location].loading);

// Selector cachedId
export const similarProductCachedIdSelector = (id: number, location: SimilarProductLocation) =>
  createSelector(selectorProductCarousel, (state) => {
    const cacheKey = `${id}-${location}`;
    const cachedIds = state.similarProcessedIds;

    return cachedIds.includes(cacheKey);
  });
