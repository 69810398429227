import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getMostRecentSuspensionReason } from 'utils/organization-settings.util';

import { DPDBlockMessage } from './DPDBlockMessage.component';

const mapStateToProps = ({ payment: { dpdBlockStatus }, prices: { settings } }) => {
  const mostRecentSuspension = getMostRecentSuspensionReason(settings);

  return {
    ...dpdBlockStatus,
    reason: mostRecentSuspension ? mostRecentSuspension.value : null,
  };
};

export const DPDBlockMessageContainer = translate('app')(connect(mapStateToProps)(DPDBlockMessage));
