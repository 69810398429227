import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { HelpDialogContainer, HelpDialogContent } from './HelpDialog.styles';
import { Frame } from './HelpDialog.components';

interface Props {
  open: boolean;
  close: () => void;
  link: string;
}

export const HelpDialog: React.FC<React.PropsWithChildren<Props>> = ({ open, close, link }) => (
  <HelpDialogContainer fullScreen open={open}>
    <HelpDialogContent>
      <Grid container direction="column">
        <Grid item>
          <Frame link={link} />
        </Grid>
        <Grid item container>
          <Button className="close-button" fullWidth onClick={close}>
            Close
          </Button>
        </Grid>
      </Grid>
    </HelpDialogContent>
  </HelpDialogContainer>
);
