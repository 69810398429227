import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import debounce from 'lodash/debounce';

import { State } from 'store';
import { useGetCurrentUser } from 'hooks';
import { fetchPotentialPoints } from 'store/reducers/loyalty-program';

const getPotentialPointsDebounce = debounce((organization_id, dispatch) => {
  dispatch(fetchPotentialPoints(organization_id));
}, 1000);

interface IUseGetPotentialPoints {
  enabled: boolean;
  registered: boolean;
  hasLoyalty: boolean;
  potentialPoints: number;
  potentialPointsDisabled: boolean;
  shouldDisplayPendingPoint: boolean;
  hasPotentialPoints(sku: string): boolean;
  getPotentialPoints(sku: string): number;
  hasPotentialRewards(sku: string): boolean;
  getPotentialRewards(sku: string): string[];
  updatePotentialPoints: () => void;
}

export const useGetPotentialPoints = (): IUseGetPotentialPoints => {
  const dispatch = useDispatch();
  const {
    coreUser: { organization_id },
  } = useGetCurrentUser();

  const {
    enabled,
    registered,
    displayPendingPoint,
    info: { potentialPoints, potentialPointsDisabled, potentialProductPoints = {} },
  } = useSelector((state: State) => state.loyalty, shallowEqual);

  const shouldDisplayPendingPoint =
    !potentialPointsDisabled && (enabled as boolean) && (registered as boolean) && displayPendingPoint;

  const updatePotentialPoints = () => {
    if (enabled && registered) {
      getPotentialPointsDebounce(organization_id, dispatch);
    }
  };

  useEffect(() => {
    updatePotentialPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization_id, enabled, registered, dispatch]);

  const hasLoyalty = enabled && registered;
  const hasPotentialPoints = (sku: string): boolean =>
    (enabled as boolean) && (registered as boolean) && sku in potentialProductPoints;
  const getPotentialPoints = (sku: string): number => potentialProductPoints?.[sku]?.points ?? 0;
  const hasPotentialRewards = (sku: string): boolean => potentialProductPoints?.[sku]?.rewards?.length > 0;
  const getPotentialRewards = (sku: string): string[] => potentialProductPoints?.[sku]?.rewards ?? [];

  return {
    enabled: enabled || false,
    registered: registered || false,
    hasLoyalty: hasLoyalty || false,
    potentialPoints,
    potentialPointsDisabled,
    shouldDisplayPendingPoint,
    hasPotentialPoints,
    getPotentialPoints,
    hasPotentialRewards,
    getPotentialRewards,
    updatePotentialPoints,
  };
};
