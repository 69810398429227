import React, { useState } from 'react';

import styled from 'styled-components';
import { Grid, Typography, Divider as DividerBase, Box, Button, Card, Snackbar } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import AlertIconBase from '@material-ui/icons/Warning';
import FileCopyBase from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';

import DepositDetailIcon from 'images/deposit-details.svg';
import EmptyStateImageBase from 'images/payment_empty_state.svg';

import * as colors from 'components/styles/colors';
import ScrollView from 'components/views/ScrollView';

const Wrapper = styled(ScrollView)`
  height: 100vh;
  background-color: ${colors.SNOW};
`;

const Header = styled(Grid)`
  && {
    padding: 15px 25px 10px 20px;
    background-color: ${colors.WHITE};
    box-shadow: 0px 0px 1px #b5b9c2;
  }
`;

const Content = styled(Grid)`
  && {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    min-height: calc(100vh - 66px);
  }
`;

const BackIcon = styled(ArrowBackIosIcon)`
  && {
    padding-right: 10px;
    fill: ${colors.TEXT};
    height: 20px;
    width: 20px;
  }
`;

const PurchaseOrderDetail = styled(ChevronRightOutlined)`
  && {
    fill: ${colors.BLUISH_GRAY_LIGHT};
    height: 18px;
    width: 18px;
    transform: translateY(3px);
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 700;
    color: ${colors.BLACK};
    text-align: center;
    text-transform: capitalize;
  }
`;

const Label = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.BLACK};
  }
`;

const Total = styled(Typography)`
  && {
    font-size: 18px;
    font-weight: 700;
    color: ${colors.BLUE};
    text-align: right;
  }
`;

const TotalContainer = styled(Grid).attrs({ container: true, alignItems: 'center', justify: 'space-between' })`
  && {
    margin: 10px 0px;
    background-color: ${colors.WHITE};
    padding: 18px 17px;
  }
`;

const SubLabel = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.BLUISH_GRAY_LIGHT};
  }
`;

const Value = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.BLACK};
    text-align: right;
    margin: 3px 0px;
  }
`;

const Container = styled(Grid)`
  padding: 0 0 16px 0;
  justify-content: normal;
`;

const DetailIcon = styled.img.attrs({ src: DepositDetailIcon })``;

const DetailContainer = styled(Grid)`
  && {
    padding: 10px 16px;
    margin-bottom: 10px;
    background: ${colors.WHITE};
  }
`;

const ContactUs = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.BLACK};
  }
`;

const ContactNumber = styled(Typography)`
  && {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.REBRAND_GREEN};
  }
`;

const Divider = styled(DividerBase)`
  && {
    background: ${colors.WEAK_GRAY};
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const HelpContainer = styled(Box)`
  && {
    margin-top: 18px;
    padding: 0px 17px;
  }
`;

const ShowMoreButton = styled(Button).attrs({ disableElevation: true, variant: 'contained', color: 'primary' })`
  && {
    transform: translateX(17px);
    width: calc(100% - 34px);
    color: ${colors.WHITE};
    border-radius: 30px;
    margin-top: 15px;
  }
`;

const MemoCard = styled(Card).attrs({ elevation: 0 })`
  && {
    background: ${colors.ORANGE}1A;
    color: ${colors.REBRAND_DARK_BLUE};
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 16px;
  }
`;

const AlertIcon = styled(AlertIconBase)`
  && {
    color: ${colors.ORANGE};
  }
`;

export const FileCopy = styled(FileCopyBase)`
  && {
    width: 12px;
    color: ${colors.REBRAND_GREEN};
  }
`;

export interface CopyProps {
  text?: string;
  message: string;
}

export const Copy: React.FC<React.PropsWithChildren<CopyProps>> = ({ text, message }) => {
  const [openToast, setOpenToast] = useState<boolean>(false);

  const onCopy = (): void => {
    setOpenToast(true);
  };

  const onClose = (): void => {
    setOpenToast(false);
  };
  return (
    <>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <IconButton style={{ padding: 0, marginRight: 5 }}>
          <S.FileCopy />
        </IconButton>
      </CopyToClipboard>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openToast}
        onClose={onClose}
        message={message}
        autoHideDuration={2000}
      />
    </>
  );
};

export const GhostButton = styled(Button).attrs({ disableElevation: true, variant: 'text', color: 'primary' })`
  && {
    padding: 0px;
    font-weight: 700;
  }
`;

export const EmptyStateImage = styled.img.attrs({ src: EmptyStateImageBase, alt: 'No Invoice', height: 90 })`
  height: 90px;
  margin-top: 30px;
`;

export const EmptyStateContainer = styled(Box)`
  && {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const EmptyStateTitle = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    color: ${colors.REBRAND_GREEN};
    margin-bottom: 5px;
  }
`;
export const EmptyStateSubTitle = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 15px;
  }
`;
export const GoBack = styled(Button).attrs({ disableElevation: true, variant: 'contained', color: 'primary' })`
  && {
    color: ${colors.WHITE};
    border-radius: 20px;
    min-width: 100px;
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const S = {
  AlertIcon,
  BackIcon,
  ContactNumber,
  ContactUs,
  Container,
  Content,
  Copy,
  DetailContainer,
  DetailIcon,
  Divider,
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStateSubTitle,
  EmptyStateTitle,
  FileCopy,
  GhostButton,
  GoBack,
  Header,
  HelpContainer,
  Label,
  MemoCard,
  PurchaseOrderDetail,
  ShowMoreButton,
  SubLabel,
  Title,
  Total,
  TotalContainer,
  Value,
  Wrapper,
};
