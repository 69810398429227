import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import * as colors from 'components/styles/colors';

const Wrapper = styled(Grid)`
  background-color: ${colors.WHITE};
  position: relative;
`;

export const S = { Wrapper };
