import { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMarketing } from 'hooks';
import qs from 'query-string';
import { State as StoreState } from 'store';

import { ProductSimpleSearchLocationProps } from 'utils/Analytics/Segment';
import { setCampaignDetail } from 'store/reducers/Campaign/actions';
import { getAnalyticsTag, removeAnalyticsTag } from 'utils/Analytics/utils/branchIoAnalyticsTag.utils';
import { getTemporaryUtmDetails } from 'utils/Analytics/utils/temporary-utm.utils';
import { BRACKET_QS_OPTIONS } from 'utils/constants';

export const useCampaignTracking = (marketing_id?: number | string, marketing_name?: string): any => {
  const { marketing } = useMarketing();
  const location = useLocation();
  const { utmSource, utmMedium } = useSelector((state: StoreState) => state.campaign, shallowEqual);
  const dispatch = useDispatch();
  const {
    utm, // SPT-14197: to be removed: fix branch io data attributes
    utm_source: utm_source_qs,
    utm_medium: utm_medium_qs,
    marketing_name: marketing_name_qs,
    marketing_id: marketing_id_qs,
  }: ProductSimpleSearchLocationProps = qs.parse(location.search, BRACKET_QS_OPTIONS) || {};

  const { campaign: branchIoMarketingName, channel: branchIoSource, feature: branchIoMedium } = getAnalyticsTag();

  // SPT-14197: to be removed: fix branch io data attributes
  const tempUtmDetails = getTemporaryUtmDetails(utm);

  const getMarketingId = (): number | undefined => {
    const marketingId = marketing?.id || marketing_id || marketing_id_qs;
    if (marketingId) {
      return typeof marketingId === 'number' ? marketingId : parseInt(marketingId, 10);
    }
    return undefined;
  };

  // Prioritity 1: State Manager; Priority 2: Query String; Priority 3: BranchIo;
  const getMarketingName = (): string | undefined =>
    marketing?.name || marketing_name || marketing_name_qs || branchIoMarketingName;

  // Priority 1: BranchIo; Prioritity 2: State Manager; Priority 3: Query String
  const getUtmSource = (): string | undefined => {
    if (tempUtmDetails) {
      // SPT-14197: to be removed: fix branch io data attributes
      return tempUtmDetails.utmSource;
    }
    return branchIoSource || utmSource || utm_source_qs;
  };

  const getUtmMedium = (): string | undefined => {
    if (tempUtmDetails) {
      // SPT-14197: to be removed: fix branch io data attributes
      return tempUtmDetails.utmMedium;
    }

    return branchIoMedium || utmMedium || utm_medium_qs;
  };

  useEffect(() => {
    const isNotCampaignSet = !utmSource && !utmMedium;
    const source = getUtmSource();
    const medium = getUtmMedium();
    if (isNotCampaignSet && source && medium) {
      dispatch(setCampaignDetail(source, medium));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => removeAnalyticsTag, []);

  return {
    utmSource: getUtmSource(),
    utmMedium: getUtmMedium(),
    marketingId: getMarketingId(),
    marketingName: getMarketingName(),
  };
};
