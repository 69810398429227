import React from 'react';
import { useRouteMatch, Route, useLocation, Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import * as colors from '../../styles/colors';
import { TabNavLink } from './TabNavLink.component';
import { TabPanel } from './TabPanel.component';
import { Tabs } from './Tab.styles';
import { TabPage, A11yProps } from './Tab.types';

const styles = {
  indicator: {
    backgroundColor: colors.TRANSPARENT,
  },
};

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  classes: any;
  active: number;
  tabs: TabPage[];
  tabContents: React.FC<React.PropsWithChildren<unknown>>;
  a11yProps: (x: number) => A11yProps;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  overrideBg?: string;
  noBrowserHistory?: boolean;
}

const TabComponentBase: React.FC<React.PropsWithChildren<Props>> = ({
  active,
  tabs,
  tabContents,
  a11yProps,
  style,
  fullWidth,
  overrideBg,
  noBrowserHistory,
}) => {
  const match = useRouteMatch();
  const location = useLocation();
  const defaultPage = tabs.find((tab) => tab.isDefault);

  return (
    <div style={{ background: overrideBg || colors.BLUE, marginTop: -1, height: 77 }}>
      <Tabs
        value={active}
        aria-label="simple tabs"
        variant={fullWidth ? 'fullWidth' : 'standard'}
        centered
        style={{
          paddingBottom: 1,
          ...(!fullWidth
            ? {
                display: 'flex',
                justifyContent: 'flex-start',
              }
            : {}),
          ...style,
        }}
      >
        {tabs.map((tab, index) => (
          <TabNavLink
            id={tab.id as string}
            key={tab.id}
            tab={tab}
            index={index}
            a11yProps={a11yProps}
            fullWidth
            noBrowserHistory={noBrowserHistory}
          />
        ))}
      </Tabs>

      <TabPanel>
        <Route path={`${match.path}/:id`} component={tabContents} />
        {defaultPage && (
          <Route render={() => <Redirect to={`${match.path}/${defaultPage.path}${location.search}`} />} />
        )}
      </TabPanel>
    </div>
  );
};

TabComponentBase.defaultProps = {
  style: undefined,
  fullWidth: false,
  overrideBg: undefined,
  noBrowserHistory: false,
};

export const TabComponent = withStyles(styles)(TabComponentBase);
