import React from 'react';
import { translate } from 'react-i18next';

import { NoAvailableProductsStyle as S } from './no-available-products.styles';
import ShowIf from 'components/views/ShowIf';
import { SEARCH_INPUT_CANCEL_BUTTON_ID } from 'components/common/Search/component';
import NoProducsImg from 'images/no_available_products.svg';
import { TranslateProps } from 'utils/Localization/types';

export interface NoAvailableProductsProps extends TranslateProps {
  isHorizontalLayout?: boolean;
  query?: string;
}

const defaultClick = () => {
  let cancelButton = document.getElementById(SEARCH_INPUT_CANCEL_BUTTON_ID);
  if (cancelButton) {
    cancelButton.click();
  }
};

const NoAvailableProductsBase: React.FC<React.PropsWithChildren<NoAvailableProductsProps>> = ({
  t,
  isHorizontalLayout = false,
  query,
}) => {
  return (
    <S.Wrapper isHorizontalLayout={isHorizontalLayout}>
      <S.ImageAndTextWrapper isHorizontalLayout={isHorizontalLayout}>
        <S.Image
          isHorizontalLayout={isHorizontalLayout}
          alt="no available products"
          src={NoProducsImg}
          className="image"
        />
        <S.TextWrapper isHorizontalLayout={isHorizontalLayout}>
          <S.Title isHorizontalLayout={isHorizontalLayout}>
            {isHorizontalLayout ? t('fuzzyTitle', { query }) : t('title')}
          </S.Title>
          <S.Subtitle isHorizontalLayout={isHorizontalLayout}>{t('subtitle')}</S.Subtitle>
        </S.TextWrapper>
      </S.ImageAndTextWrapper>
      <S.Button onClick={defaultClick}>{t('button')}</S.Button>
      <S.Divider isHorizontalLayout={isHorizontalLayout} />
      <ShowIf condition={isHorizontalLayout}>
        <S.RelatesTitle>{t('relatesTo', { query })}</S.RelatesTitle>
      </ShowIf>
    </S.Wrapper>
  );
};

export const NoAvailableProducts = translate('noAvailableProducts')(NoAvailableProductsBase);
