import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export interface Props {
  className?: string;
}

const RelativeGrid = styled(Grid)`
  && {
    flex: 1;
    position: relative;
  }
`;

const Content: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => <RelativeGrid {...props}>{children}</RelativeGrid>;

export default Content;
