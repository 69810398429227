/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { store } from 'store';

import { IMarketing, MarketingType, ISpecialMarketing } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { v3Client } from 'utils/Client/v3';
import {
  MarketingAction,
  MarketingActions,
  MarketingState,
  useMarketingDispatch,
  useMarketingState,
} from 'components/pages/ProductListingPage/components/marketingContext';
import { BRACKET_QS_OPTIONS } from 'utils/constants';

import {
  BasePageTracker,
  LAST_PURCHASED_PATH_NAME,
  RECENTLY_VIEWED_PATH_NAME,
  SIMILAR_PRODUCT_PATH_NAME,
} from 'utils/Analytics/Segment';
import { usePageIdentity } from './useProductSearch';

export interface MarketingHook {
  marketing: IMarketing | ISpecialMarketing | undefined;
}

interface NavigationState {
  marketing?: IMarketing;
}

const PAGES_WITHOUT_MARKETING_IDS = [
  BasePageTracker.LAST_PURCHASED_SEARCH,
  BasePageTracker.LAST_PURCHASED,
  BasePageTracker.RECENTLY_VIEWED,
  BasePageTracker.RECENTLY_VIEWED_SEARCH,
  BasePageTracker.SIMILAR_PRODUCTS,
];

const MarketingTypeMapping = {
  [BasePageTracker.RECENTLY_VIEWED]: MarketingType.RECENT,
  [BasePageTracker.RECENTLY_VIEWED_SEARCH]: MarketingType.RECENT,
  [BasePageTracker.LAST_PURCHASED]: MarketingType.REPURCHASE,
  [BasePageTracker.LAST_PURCHASED_SEARCH]: MarketingType.REPURCHASE,
  [BasePageTracker.SIMILAR_PRODUCTS]: MarketingType.SIMILAR_PRODUCT,
};

export const useMarketing = (shouldReload?: boolean): MarketingHook => {
  const { id } = useParams<any>();
  const location = useLocation();
  const { base_page } = usePageIdentity();

  const { marketing_id } = qs.parse(location.search, BRACKET_QS_OPTIONS) || '';
  const locationState = (location?.state || {}) as NavigationState;
  const marketingId = marketing_id || id || locationState.marketing?.id;

  const marketingDispatch = useMarketingDispatch();
  const marketingState = useMarketingState();

  const { search } = store.getState();

  // get marketing info from marketing state
  // verify if marketingId is same on marketingState
  // if it's same we can reuse the state
  // if it's different, have to get from API

  useEffect(() => {
    if (
      shouldReload &&
      marketingId &&
      marketingState.marketing_id !== Number.parseInt(marketingId, 10) &&
      !PAGES_WITHOUT_MARKETING_IDS.includes(base_page) // TODO: could use specialMarketingBasePage to avoid being called on product detail
    ) {
      v3Client.get(`marketing/${marketingId}`, {}).then(({ data }) => {
        const newMarketing = {
          marketing_name: data.name,
          marketing_type: data.type,
          marketing_id: data.id,
        } as MarketingState;

        const marketingAction = {
          type: MarketingActions.SET_DATA,
          marketing: newMarketing,
        } as MarketingAction;
        marketingDispatch(marketingAction);
      });
    } else {
      // console.log('marketing, not new'); // For Debugging purposes only
    }
  }, [marketingId]);

  const isReturnMarketing = marketingId && Number.parseInt(marketingId, 10) === marketingState.marketing_id;
  if (isReturnMarketing) {
    return {
      marketing: {
        id: marketingState.marketing_id,
        name: marketingState.marketing_name,
        type: marketingState.marketing_type || MarketingType.RECENT,
        config: marketingState.marketing_config,
      },
    };
  }

  // if page is from push with maketing data
  if (
    PAGES_WITHOUT_MARKETING_IDS.includes(base_page) &&
    !marketingState.marketing_id &&
    locationState.marketing?.id &&
    locationState.marketing?.name
  ) {
    return {
      marketing: {
        id: locationState.marketing.id,
        name: locationState.marketing.name,
        type: locationState.marketing.type,
        config: locationState.marketing.config,
      },
    };
  }

  // if page is reloaded
  // once the data is loaded from app.component
  if (search.specialMarketings) {
    let specialMarketingBasePage = base_page;

    const isRecentlyViewed = location?.pathname?.includes(RECENTLY_VIEWED_PATH_NAME);
    const isLastPurchase = location?.pathname?.includes(LAST_PURCHASED_PATH_NAME);
    const isSimilarProduct = location?.pathname?.includes(SIMILAR_PRODUCT_PATH_NAME);
    const isProductDetail = base_page === BasePageTracker.PRODUCT_DETAIL;

    const isSearch = location?.pathname?.includes('search');
    if (isProductDetail && isRecentlyViewed) {
      specialMarketingBasePage = isSearch ? BasePageTracker.RECENTLY_VIEWED_SEARCH : BasePageTracker.RECENTLY_VIEWED;
    }
    if (isProductDetail && isLastPurchase) {
      specialMarketingBasePage = isSearch ? BasePageTracker.LAST_PURCHASED_SEARCH : BasePageTracker.LAST_PURCHASED;
    }

    if (isProductDetail && isSimilarProduct) {
      specialMarketingBasePage = BasePageTracker.SIMILAR_PRODUCTS;
    }

    // ensure that the page is configured in the mapping
    if (
      PAGES_WITHOUT_MARKETING_IDS.includes(specialMarketingBasePage) &&
      MarketingTypeMapping[specialMarketingBasePage]
    ) {
      const assignedMarketingType = MarketingTypeMapping[specialMarketingBasePage];
      const marketingDataResp = search.specialMarketings[assignedMarketingType];

      if (marketingDataResp) {
        return {
          marketing: marketingDataResp,
        };
      }
    }
  }

  return { marketing: undefined };
};
