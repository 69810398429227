import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import ICheck from 'images/ic-check.svg';
import { TranslateProps } from '../../../../../utils/Localization/types';
import { S } from './PrekursorDialogSuccess.style';

export interface Props {
  show: boolean;
  onClose: (close) => void;
}

export const PrekursorDialogSuccess: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ show, onClose, t }) => (
  <S.ModalDialog open={show} onClose={() => onClose(false)}>
    <S.DialogTitleText disableTypography>
      <img src={ICheck} alt="success" />
      <Typography>{t('prekursor.uploadSuccess.title')}</Typography>
    </S.DialogTitleText>
    <DialogContent>
      <S.DialogText>{t('prekursor.uploadSuccess.info')}</S.DialogText>
    </DialogContent>
    <DialogActions>
      <S.Button onClick={() => onClose(false)}>{t('prekursor.uploadSuccess.action')}</S.Button>
    </DialogActions>
  </S.ModalDialog>
);
