/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { PharmacyOrderStatus, OrderPrecursorStatus } from 'services';
// import { PaymentProvider } from 'utils/constants';
// import { getPaymentProvider } from 'utils/MarketConfig';

export const getAvailableOrderStatus = (useDirectPayment = false): PharmacyOrderStatus[] => {
  const base = [PharmacyOrderStatus.ALL_ORDERS];

  // const paymentProvider = getPaymentProvider() as PaymentProvider;
  // if ([PaymentProvider.DOKU].includes(paymentProvider)) {
  //   base.push(PharmacyOrderStatus.WAITING_DOCUMENT);
  // }

  if (useDirectPayment) {
    base.push(PharmacyOrderStatus.UNPAID);
    base.push(PharmacyOrderStatus.PAID);
  } else {
    base.push(PharmacyOrderStatus.WAITING_DOCUMENT);
    base.push(PharmacyOrderStatus.ORDER_RECEIVED);
  }

  return [
    ...base,
    ...[
      PharmacyOrderStatus.PREPARING_GOODS,
      PharmacyOrderStatus.OUT_OF_DELIVERY,
      PharmacyOrderStatus.DELIVERED,
      PharmacyOrderStatus.CANCELLED,
    ],
  ];
};

// eslint-disable-next-line max-len
export const getAvailableOrderPrecursorStatus = (orderStatus: PharmacyOrderStatus): OrderPrecursorStatus[] => {
  const orderPrecursorStatusMap = {
    [PharmacyOrderStatus.WAITING_DOCUMENT]: [
      'all',
      OrderPrecursorStatus.DOCUMENT_REQUIRED,
      OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL,
      OrderPrecursorStatus.DOCUMENT_REJECTED,
      OrderPrecursorStatus.DOCUMENT_CANCELLED,
    ],
  };
  return orderPrecursorStatusMap[orderStatus] ?? [];
};
