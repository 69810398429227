import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import { ISearchItem } from '../components/interface';

export const downshiftStateReducer = (
  state: DownshiftState<ISearchItem>,
  changes: StateChangeOptions<ISearchItem>,
): StateChangeOptions<ISearchItem> => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
      return {
        ...changes,
        inputValue: state.inputValue,
      };

    case Downshift.stateChangeTypes.blurButton:
      return {
        ...changes,
      };

    default:
      return changes;
  }
};
