import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';

import { S } from './orders-modal-subtitle.styles';

interface Props {
  text: string;
  children?: React.ReactNode;
  style?: CSSProperties;
}

export const OrdersModalSubTitle: React.FC<React.PropsWithChildren<Props>> = ({ children, text, style }) => (
  <S.Container style={style}>
    <S.SubTitle>{text}</S.SubTitle>
    {children}
  </S.Container>
);
