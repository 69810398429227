import React from 'react';
import { translate, Trans } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { OrderStatus } from 'services';

import { State as StoreState } from 'store';
import { closeOrderStatus, openOrderRating, setOrderRating } from 'store/reducers/Orders/actions';
import { TFunction } from 'utils/Localization';

import { S } from './orders-rating.styles';
import { Stars } from './stars';

export interface Props {
  t: TFunction;
}

const OrdersRatingEntrypointBase: React.FC<React.PropsWithChildren<Props>> = ({ t }) => {
  const dispatch = useDispatch();

  const {
    purchaseOrder: { orderDetail },
    orders: {
      orderRating: { currentOrderRating, isLoading },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const handleOpenRating = (value: number): void => {
    if (!currentOrderRating) dispatch(setOrderRating(value));
    dispatch(openOrderRating());
    dispatch(closeOrderStatus());
  };

  if (![OrderStatus.FULFILLED, OrderStatus.COMPLETED].includes(orderDetail.status)) return null;

  return (
    <S.EntrypointWrapper>
      <S.LetsRateYourOrder>{t('letsRateYourOrder')}</S.LetsRateYourOrder>
      <S.HowDidYouLikeYourOrder>
        <Trans
          t={t}
          i18nKey="howDidYouLikeYourOrder"
          values={{ distributor: orderDetail.distributor?.name || 'SwipeRx' }}
        >
          <strong>&nbsp;</strong>
        </Trans>
      </S.HowDidYouLikeYourOrder>
      <Stars
        handleClick={handleOpenRating}
        t={t}
        currentRate={currentOrderRating?.rate || 0}
        currentOrderRating={currentOrderRating}
        disabled={isLoading}
      />
    </S.EntrypointWrapper>
  );
};

export const OrdersRatingEntrypoint = translate('orders')(OrdersRatingEntrypointBase);
