import { AnalyticsEvent } from '../interfaces';
import { SwipeRxAnalytics } from '../SwipeRxAnalytics';
import { UndefinedAnalyticsProps, TrackPageViewAnalyticsProps } from './segment.types';

export const makeAnalyticsRequest = (
  event: string,
  type: AnalyticsEvent,
  payload: UndefinedAnalyticsProps | TrackPageViewAnalyticsProps,
): void => {
  SwipeRxAnalytics.getInstance().request(type, event, payload);
};
