import styled from 'styled-components';
import DividerBase from '@material-ui/core/Divider';
import TypographyBase from '@material-ui/core/Typography';
import { LIGHT_CHARCOAL, TINT_GRAY } from 'components/styles/colors';

const TEXT_LINE_HEIGHT = 14;

const Wrapper = styled.div<{ margin: number }>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin - TEXT_LINE_HEIGHT / 2}px ${(props) => props.margin}px;
`;

// Left Divider or Right Divider
const Divider = styled(DividerBase)`
  && {
    flex: 1;
    background-color: ${TINT_GRAY};
  }
`;

const Text = styled(TypographyBase)<{ margin: number }>`
  && {
    font-family: Nunito Sans;
    font-size: 10px;
    font-weight: 600;
    line-height: ${TEXT_LINE_HEIGHT}px;
    letter-spacing: 0.16px;
    text-align: center;
    padding: 0 ${(props) => props.margin}px;
    color: ${LIGHT_CHARCOAL};
  }
`;

export const DividerWithTextStyle = {
  Wrapper,
  Divider,
  Text,
};
