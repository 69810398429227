import styled from 'styled-components';

export const Container = styled.div`
  .slick-slide img {
    display: block;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-dots {
    display: flex !important;
    position: static;
    padding: 8px 0;
  }

  .slick-dots li {
    margin: 0;
    width: 12px;
  }

  .slick-dots li button:before {
    font-size: 8px;
    line-height: 16px;
    color: black;
    opacity: 0.25 !important;
  }

  .slick-dots li.slick-active button:before {
    color: #14c47d;
    opacity: 1 !important;
  }
`;
