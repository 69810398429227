enum constants {
  SHOW_ERROR = 'SHOW_ERROR',
  SHOW_SUCCESS = 'SHOW_SUCCESS',
  HIDE_ERROR = 'HIDE_ERROR',
  HIDE_SUCCESS = 'HIDE_SUCCESS',
  START_APP_LOADING = 'START_APP_LOADING',
  END_APP_LOADING = 'END_APP_LOADING',
  SET_BACK_STACK = 'SET_BACK_STACK',
  SET_HOME_WIDGET_FINISH_LOADED = 'SET_HOME_WIDGET_FINISH_LOADED',
  RESET_HOME_WIDGETS_LOADED = 'RESET_HOME_WIDGETS_LOADED',
}

export default constants;
