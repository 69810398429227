import React from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import { S } from './SectionCardCloseButton.style';

export const SectionCardCloseButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => (
  <S.Wrapper {...props}>
    <CloseIcon />
  </S.Wrapper>
);
