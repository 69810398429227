import * as Redux from 'redux';
import * as constants from './constants';
import { Actions } from './actions';
import { PharmacySearchSquareResDto } from './types';

export interface CampaignSearchMarketingState {
  data: PharmacySearchSquareResDto | undefined;
}

const initialState: CampaignSearchMarketingState = {
  data: undefined,
  // loadedOnce: false, // TODO:
  // expiry:
};

const CampaignSearchMarketingReducer: Redux.Reducer<CampaignSearchMarketingState, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case constants.SET_CAMPAIGN_SEARCH_MARKETING_DATA:
      return {
        ...state,
        data: action.payload,
      } as CampaignSearchMarketingState;
    default:
      return state;
  }
};

export default CampaignSearchMarketingReducer;
