import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const DialogContent = styled(Grid)`
  && {
    width: 100%;
    max-width: 350px;
  }
`;

export const S = { DialogContent };
