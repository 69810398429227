import produce, { Draft } from 'immer';

import { LoyaltyHistoryGroup } from 'services/swipe-rx-pt/resources/loyalty/interfaces';
import { LoyaltyHistoryStatus } from 'services/swipe-rx-pt/resources/loyalty/types';
import { LoyaltyProgramStore, LoyaltyRewardPreviewType, LoyaltyTierName } from './loyalty-program.inteface';
import { LoyaltyProgramActionTypes, LoyaltyProgramActions } from './loyalty-program-actions.type';
import { getCountLoyaltyHistoryGroup } from './loyalty-program.actions';

const initLoyaltyPoints = {
  tier: LoyaltyTierName.BRONZE,
  totalPoints: null,
  pointsNeeded: 0,
  pointsLeft: 0,
  cycle: 1,
  endOfCycleDate: new Date(),
};

const loyaltyState: LoyaltyProgramStore = {
  registered: null,
  enabled: null,
  loading: false,
  history: {
    loading: false,
    data: [],
    dataLength: 0,
    meta: {
      page: 0,
      page_size: 0,
      page_count: 0,
      total_count: 0,
    },
  },
  info: {
    claimedRewardsCount: 0,
    pendingPoints: 0,
    firstName: '',
    lastName: '',
    customerId: '',
    points: 0,
    p2pPoints: 0,
    totalEarnedPoints: 0,
    usedPoints: 0,
    expiredPoints: 0,
    lockedPoints: 0,
    blockedPoints: 0,
    potentialPoints: 0,
    potentialPointsDisabled: false,
    level: '',
    levelName: '',
    levelConditionValue: 0,
    nextLevelConditionValue: 0,
    transactionsAmountWithoutDeliveryCosts: 0,
    averageTransactionsAmount: '',
    transactionsCount: 0,
    transactionsAmount: 0,
    currency: '',
    sumOfExchangedPointsToday: 0,
    sumOfCurrencySuccessfullyRedeemed: 0,
    pointsExpiringNextMonth: 0,
    pointsExpiringBreakdown: {},
    storeCode: '',
    levelAchievementDate: '',
    createdAt: '',
    invitationToken: '',
    nextLevel: '',
    nextLevelName: '',
    firstTransactionDate: '',
    lastTransactionDate: '',
    transactionsAmountToNextLevelWithoutDeliveryCosts: 0,
    deductedPoints: {
      calculating: false,
      points: 0,
    },
    redeemedPoints: {
      calculating: false,
      points: 0,
    },
    expiringPoints: {
      calculating: false,
      points: 0,
    },
    bonusPoints: {
      calculating: false,
      points: 0,
    },
    urls: {
      frequently_asked_question: '',
      how_to_earn: '',
      privacy_policy: '',
      term_of_use: '',
      tier_and_benefit: '',
    },
    cycle: {
      cycleMonth: 0,
      cycleNumber: 0,
    },
  },
  error: null,
  rewards: {
    data: [],
    meta: {
      page: 0,
      page_size: 0,
      page_count: 0,
      total_count: 0,
    },
    type: null,
    loading: true,
    queries: {},
  },
  rewardInfo: null,
  rewardInfoLoading: false,
  rewardRedeem: null,
  registration: null,
  redeemFailed: null,
  redeemLoading: false,
  rewardCategory: {
    data: [],
    selected: null,
    loading: true,
  },
  displayPendingPoint: true,
  displayMission: true,
  displayWelcomeMission: false,
  // preview rewards
  previewMostPopularRewards: {
    loading: false,
    data: [],
  },
  previewLatestRewards: {
    loading: false,
    data: [],
  },
  previewAllRewards: {
    loading: false,
    data: [],
  },
  previewMyRewards: {
    loading: false,
    data: [],
  },
  pointsToMaintain: {
    loading: true,
    data: initLoyaltyPoints,
  },
  pointsToUpgrade: {
    loading: true,
    data: initLoyaltyPoints,
  },
  pointsInProgress: {
    loading: true,
    type: 'upgrade',
    isMaxTier: false,
    data: initLoyaltyPoints,
  },
  missions: {
    error: null,
    loading: true,
    data: [],
    meta: {
      page: 0,
      page_size: 0,
      page_count: 0,
      total_count: 0,
    },
  },
  missionInfo: {
    loading: false,
  },
  missionsBanner: undefined,
  totalActiveMissions: undefined,
  completedMissions: [],
};

export const loyaltyProgramReducer = (
  state: LoyaltyProgramStore = loyaltyState,
  action: LoyaltyProgramActions,
): LoyaltyProgramStore =>
  produce(state, (draft: Draft<LoyaltyProgramStore>) => {
    switch (action.type) {
      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_LOADING:
        draft.loading = action.payload.loading;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_ERROR:
        draft.error = action.payload.error;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_HISTORY_LOADING:
        draft.history.loading = action.payload.loading;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_HISTORY_LIST:
        {
          const { data, meta } = action.payload;

          draft.history.meta = meta;
          if (data.length === 0) {
            draft.history.data = [...draft.history.data, ...data];
            draft.history.dataLength = getCountLoyaltyHistoryGroup(draft.history.data);
            return;
          }

          const rest: LoyaltyHistoryGroup[] = [];
          for (let index = 0; index < data.length; index += 1) {
            const { date, points, type, sortBy } = data[index];
            const existingDateIndex = draft.history.data.findIndex((item) => item.date === date);
            if (existingDateIndex > -1) {
              const historyPoints = [...draft.history.data[existingDateIndex].points, ...points];
              if ([LoyaltyHistoryStatus.EXPIRED, LoyaltyHistoryStatus.EXPIRING_SOON].includes(type)) {
                const totalPoints = historyPoints.reduce((acc, item) => acc + item.points, 0);
                draft.history.data[existingDateIndex].points = [
                  {
                    ...historyPoints[0],
                    points: totalPoints,
                  },
                ];
              } else {
                if (sortBy) {
                  historyPoints.sort((a, b) =>
                    sortBy.toLowerCase() === 'desc'
                      ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                      : new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
                  );
                }
                draft.history.data[existingDateIndex].points = historyPoints;
              }
            } else {
              rest.push({ date, points, type });
            }
          }
          const newHistoryData = rest.length > 0 ? rest : [];

          draft.history.data = [...draft.history.data, ...newHistoryData];
          draft.history.dataLength = getCountLoyaltyHistoryGroup(draft.history.data);
        }
        break;

      case LoyaltyProgramActionTypes.RESET_LOYALTY_HISTORY_LIST:
        draft.history = {
          loading: false,
          data: [],
          dataLength: 0,
          meta: {
            page: 0,
            page_size: 0,
            page_count: 0,
            total_count: 0,
          },
        };
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_INFO:
        draft.info = {
          ...draft.info,
          ...action.payload.data,
          lastFetchedAt: new Date(),
        };
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_CALCULATING_POINTS:
        {
          const { key, calculating } = action.payload;
          const points = key ? [key] : ['deductedPoints', 'redeemedPoints', 'expiringPoints', 'bonusPoints'];
          for (let i = 0; i < points.length; i += 1) {
            const pointKey = points[i];
            if (pointKey in draft.info) {
              draft.info[pointKey].calculating = calculating;
            }
          }
        }
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_DEDUCTED_POINTS:
        draft.info.deductedPoints = {
          calculating: false,
          points: action.payload.points,
        };
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_REDEEMED_POINTS:
        draft.info.redeemedPoints = {
          calculating: false,
          points: action.payload.points,
        };
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_EXPIRING_POINTS:
        draft.info.expiringPoints = {
          calculating: false,
          points: action.payload.points,
        };
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_BONUS_POINTS:
        draft.info.bonusPoints = {
          calculating: false,
          points: action.payload.points,
        };
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_LOADING:
        draft.rewards.loading = action.payload.loading;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_QUERIES:
        draft.rewards.queries = { ...draft.rewards.queries, ...action.payload.queries };
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_LIST:
        draft.rewards.data = [...draft.rewards.data, ...action.payload.data];
        draft.rewards.meta = { ...draft.rewards.meta, ...action.payload.meta };
        draft.rewards.type = action.payload.type;
        draft.rewards.loading = false;
        break;

      case LoyaltyProgramActionTypes.RESET_LOYALTY_REWARDS_LIST:
        {
          const payload: Record<string, any> = {
            data: [],
            meta: {
              page: 0,
              page_size: 0,
              page_count: 0,
              total_count: 0,
            },
            type: null,
            loading: false,
          };
          if (action.payload.all) {
            payload.queries = {};
          }
          draft.rewards = { ...draft.rewards, ...payload };
        }
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_INFO:
        draft.rewardInfo = action.payload.data;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_INFO_LOADING:
        draft.rewardInfoLoading = action.payload.loading;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_REDEEM:
        draft.rewardRedeem = action.payload.data;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_BANNER_DATA:
        draft.enabled = action.payload.enabled;
        draft.registered = action.payload.registered;
        draft.displayPendingPoint = action.payload.displayPendingPoint;
        draft.displayMission = action.payload.displayMission;
        draft.displayWelcomeMission = action.payload.displayWelcomeMission;
        draft.totalActiveMissions = action.payload.totalActiveMissions;
        draft.info.points = action.payload.points;
        draft.info.pendingPoints = action.payload.pendingPoints;
        draft.info.levelName = action.payload.tier;
        draft.info.cycle.cycleNumber = action.payload.cycle;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REGISTRATION_DATA:
        draft.registered = action.payload.registered;
        draft.registration = action.payload.registration;
        if (action.payload.termOfUseURL) {
          draft.info.urls.term_of_use = action.payload.termOfUseURL;
        }
        if (action.payload.privacyPolicyURL) {
          draft.info.urls.privacy_policy = action.payload.privacyPolicyURL;
        }
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_POTENTIAL_POINTS:
        draft.info.potentialPoints = action.payload.potentialPoints;
        draft.info.potentialPointsDisabled = action.payload.potentialPointsDisabled;
        draft.info.potentialProductPoints = action.payload.potentialProductPoints;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REDEEM_FAILED:
        draft.redeemFailed = action.payload.redeemFailed;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REDEEM_LOADING:
        draft.redeemLoading = action.payload.loading;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORY_LOADING:
        draft.rewardCategory.loading = action.payload.loading;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORIES:
        draft.rewardCategory.data = action.payload.data;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORY:
        draft.rewardCategory.selected = action.payload.selected;
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_LOADING:
        {
          const { type, loading } = action.payload;
          switch (type) {
            case LoyaltyRewardPreviewType.ALL:
              draft.previewAllRewards.loading = loading;
              draft.previewAllRewards.error = undefined;
              break;
            case LoyaltyRewardPreviewType.MOST_POPULAR:
              draft.previewMostPopularRewards.loading = loading;
              draft.previewMostPopularRewards.error = undefined;
              break;
            case LoyaltyRewardPreviewType.LATEST:
              draft.previewLatestRewards.loading = loading;
              draft.previewLatestRewards.error = undefined;
              break;
            case LoyaltyRewardPreviewType.MY_REWARDS:
              draft.previewMyRewards.loading = loading;
              draft.previewMyRewards.error = undefined;
              break;
          }
        }
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_LIST:
        {
          const { type, data } = action.payload;
          switch (type) {
            case LoyaltyRewardPreviewType.ALL:
              draft.previewAllRewards.loading = false;
              draft.previewAllRewards.error = undefined;
              draft.previewAllRewards.data = data;
              break;
            case LoyaltyRewardPreviewType.MOST_POPULAR:
              draft.previewMostPopularRewards.loading = false;
              draft.previewMostPopularRewards.error = undefined;
              draft.previewMostPopularRewards.data = data;
              break;
            case LoyaltyRewardPreviewType.LATEST:
              draft.previewLatestRewards.loading = false;
              draft.previewLatestRewards.error = undefined;
              draft.previewLatestRewards.data = data;
              break;
            case LoyaltyRewardPreviewType.MY_REWARDS:
              draft.previewMyRewards.loading = false;
              draft.previewMyRewards.error = undefined;
              draft.previewMyRewards.data = data;
              break;
          }
        }
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_ERROR:
        {
          const { type, error } = action.payload;
          switch (type) {
            case LoyaltyRewardPreviewType.ALL:
              draft.previewAllRewards.loading = false;
              draft.previewAllRewards.error = error;
              break;
            case LoyaltyRewardPreviewType.MOST_POPULAR:
              draft.previewMostPopularRewards.loading = false;
              draft.previewMostPopularRewards.error = error;
              break;
            case LoyaltyRewardPreviewType.LATEST:
              draft.previewLatestRewards.loading = false;
              draft.previewLatestRewards.error = error;
              break;
            case LoyaltyRewardPreviewType.MY_REWARDS:
              draft.previewMyRewards.loading = false;
              draft.previewMyRewards.error = error;
              break;
          }
        }
        break;

      case LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_TO_MAINTAIN:
        draft.pointsToMaintain = action.payload;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_TO_UPGRADE:
        draft.pointsToUpgrade = action.payload;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_IN_PROGRESS:
        draft.pointsInProgress = action.payload;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_LIST:
        draft.missions = action.payload;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_MISSION_INFO:
        draft.missionInfo = action.payload;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_BANNER:
        draft.missionsBanner = action.payload;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_TOTAL_ACTIVE_MISSIONS:
        draft.totalActiveMissions = action.payload;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_CONFIG:
        draft.displayWelcomeMission = action.payload.displayWelcomeMission;
        break;
      case LoyaltyProgramActionTypes.SET_LOYALTY_COMPLETED_MISSIONS:
        draft.completedMissions = action.payload;
        break;
    }
  });
