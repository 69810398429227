import qs from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { getQueryObject } from 'utils/http';

export function getSwipeRxToken(): string {
  const swiperxToken = decodeURIComponent(localStorage.getItem('swiperx_token') || '');
  return swiperxToken;
}

export function storeSwipeRxToken({ location, history }: RouteComponentProps): void {
  if (!location || !history) {
    return;
  }
  const { s, ...otherSearch } = getQueryObject(location.search);
  if (s) {
    localStorage.setItem('swiperx_token', encodeURIComponent(s as string));

    const search = qs.stringify(otherSearch);
    history.replace({ ...location, search });
  }
}
