import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useWindowSize } from 'hooks/useWindowSize';
import { breakpoints } from 'components/styles/breakpoints';
import { S } from './ProductCardLoader.styles';

export const ProductCardLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { width } = useWindowSize();

  const isPhoneSmall = breakpoints.phoneSmall === `${width}px`;

  return (
    <S.Wrapper container direction="column" justify="center" alignItems="center">
      <S.ProductCardContainer>
        <S.ProductImageContainer>
          <SkeletonTheme>
            <Skeleton height={isPhoneSmall ? 103 : 117} width={isPhoneSmall ? 103 : 117} />
          </SkeletonTheme>
        </S.ProductImageContainer>
        <S.ProductDetailsContainer>
          <Grid>
            <SkeletonTheme>
              <Skeleton height={isPhoneSmall ? 10 : 15} width={isPhoneSmall ? 180 : 200} />
            </SkeletonTheme>
            <SkeletonTheme>
              <Skeleton height={isPhoneSmall ? 10 : 13} width={120} />
            </SkeletonTheme>
          </Grid>
          <S.PricingContainer>
            <Grid>
              <SkeletonTheme>
                <Skeleton height={10} width={100} />
              </SkeletonTheme>
              <SkeletonTheme>
                <Skeleton height={isPhoneSmall ? 10 : 12} width={130} />
              </SkeletonTheme>
            </Grid>
            <Grid>
              <SkeletonTheme>
                <Skeleton height={isPhoneSmall ? 18 : 22} width={isPhoneSmall ? 50 : 72} />
              </SkeletonTheme>
            </Grid>
          </S.PricingContainer>
        </S.ProductDetailsContainer>
      </S.ProductCardContainer>
    </S.Wrapper>
  );
};
