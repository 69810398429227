import { SwipeRxPt } from '../../SwipeRxPt.class';

export class SwiperxPtPrecursorStatus {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async upload(prekursorStatusId: number, file: File): Promise<any> {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await this.base.postMultipart(`/precursor/${prekursorStatusId}/upload`, formData);

    return data;
  }

  async getPendingUpload(): Promise<any> {
    const { data } = await this.base.get(`/precursor/pending-upload`);

    return data;
  }
}
