/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import debounce from 'lodash/debounce';
import qs from 'query-string';

import { useGetCurrentUser } from 'hooks';
import { State } from 'store';
import {
  fetchPurchaseOrderList,
  resetPurchaseOrderList,
  fetchInitialPurchaseOrderList,
  PurchaseOrderStore,
} from 'store/reducers';
import { OrderPrecursorStatus, PharmacyOrderStatus } from 'services';
import {
  getOrderStatusFilters,
  getOrderDateRangeFilter,
  getAvailableOrderStatus,
  getAvailableOrderPrecursorStatus,
} from 'utils/helpers';
import { getPaymentMethod } from 'utils/MarketConfig';
import { PaymentMethods } from 'utils/constants';

import { useHistory, useLocation } from 'react-router-dom';
import { routeKey } from 'utils/route.utils';
import { RangeModifier } from 'react-day-picker';
import { UseOrderFilters, useOrderFilters as userOrderDistributorFilter } from '../orders-filter-modal/hooks';

interface IUserOrdersHistoryDiftributorFilter extends UseOrderFilters {
  onSaveHandler: () => void;
}
interface IUserOrdersHistory extends Pick<PurchaseOrderStore, 'orders' | 'loading'> {
  search: string;
  hasMore: boolean;
  dateRangeFilter: {
    dateRange: RangeModifier;
    applyDateRangeFilter: (range: RangeModifier) => void;
  };
  orderStatusFilter: {
    statusFilter: PharmacyOrderStatus;
    orderStatusFilters: PharmacyOrderStatus[];
    selectOrderStatusFilter: (status: PharmacyOrderStatus) => void;
  };
  orderPrecursorStatusFilter: {
    hasPrecursorStatusFilter: boolean;
    precursorStatusFilter: OrderPrecursorStatus | null;
    orderPrecursorStatusFilters: OrderPrecursorStatus[];
    selectOrderPrecursorStatusFilter: (precursorStatus: OrderPrecursorStatus) => void;
  };
  distributorFilter: IUserOrdersHistoryDiftributorFilter;
  incrementPage: () => void;
  searchOrder: (query: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOrdersHistory = (defaultStatus: PharmacyOrderStatus | null = null): IUserOrdersHistory => {
  const history = useHistory();

  const {
    coreUser: { organization_id: organizationId },
  } = useGetCurrentUser();

  const { REACT_APP_CLICK_DEBOUNCE = 300, REACT_APP_CLICK_DEBOUNCE_MAX_WAIT = 1000 } = process.env;
  const wait = +REACT_APP_CLICK_DEBOUNCE;
  const maxWait = +REACT_APP_CLICK_DEBOUNCE_MAX_WAIT;

  const paymentMethod = getPaymentMethod();
  const isDirectPayment = paymentMethod === PaymentMethods.DIRECT_PAYMENT;
  const orderStatusFilters = getAvailableOrderStatus(isDirectPayment);
  const defaultFilter = defaultStatus || orderStatusFilters[0];

  const dispatch = useDispatch();

  const distributorFilter = userOrderDistributorFilter();

  const { orders, loading } = useSelector((state: State) => state.purchaseOrder, shallowEqual);

  const hasMore = !orders.meta.end;

  const [statusFilter, setStatusFilter] = useState<PharmacyOrderStatus>(defaultFilter);

  const [dateRange, setDateRange] = useState<RangeModifier>({
    from: null,
    to: null,
  });

  const [search, setSearch] = useState<string>('');

  const distributorFilterOnSave = (): void => {
    distributorFilter.onSaveHandler();
  };

  const selectOrderStatusFilter = (status: PharmacyOrderStatus): void => {
    setStatusFilter(status);
    history.push(`${routeKey('main_orders_history')}/${status}`);
  };

  const applyDateRangeFilter = (range: RangeModifier): void => {
    setDateRange(range);
  };

  const searchOrder = (query: string): void => {
    setSearch(query);
  };

  /*
   * order precursor status
   */
  const location = useLocation();
  // eslint-disable-next-line max-len
  const { precursor_status: precursorStatus = null } = qs.parse(location.search) as {
    precursor_status?: OrderPrecursorStatus;
  };
  const defaultOrderPrecursorStatusFilter = {
    hasPrecursorStatusFilter: false,
    precursorStatusFilter: precursorStatus ?? null,
    orderPrecursorStatusFilters: [],
  };
  const [orderPrecursorStatusFilter, setOrderPrecursorStatusFilter] = useState<
    Omit<IUserOrdersHistory['orderPrecursorStatusFilter'], 'selectOrderPrecursorStatusFilter'>
  >(defaultOrderPrecursorStatusFilter);

  useEffect(() => {
    const orderPrecursorStatusFilters = getAvailableOrderPrecursorStatus(statusFilter);
    setOrderPrecursorStatusFilter((oldStatusSecondary) => ({
      hasPrecursorStatusFilter: orderPrecursorStatusFilters.length > 0,
      precursorStatusFilter: oldStatusSecondary.precursorStatusFilter,
      orderPrecursorStatusFilters,
    }));
    return () => setOrderPrecursorStatusFilter(defaultOrderPrecursorStatusFilter);
  }, [statusFilter]);

  const selectOrderPrecursorStatusFilter = (precursorStatus: OrderPrecursorStatus | 'all'): void => {
    const precursorStatusFilter = precursorStatus === 'all' ? null : precursorStatus;
    setOrderPrecursorStatusFilter((orderPrecursorStatus) => ({
      ...orderPrecursorStatus,
      precursorStatusFilter,
    }));
    let url = location.pathname;
    if (precursorStatusFilter) {
      url += `?precursor_status=${precursorStatusFilter}`;
    }
    history.push(url);
  };

  const initialSearch = (options: {
    organizationId: number;
    statusFilter: PharmacyOrderStatus;
    precursorStatusFilter: OrderPrecursorStatus | null;
    distributors: number[];
    dateRange: RangeModifier;
    search: string;
  }): void => {
    const { organizationId, statusFilter, precursorStatusFilter, distributors, dateRange, search } = options;
    // eslint-disable-next-line no-use-before-define
    if (debouncedGetInitialSearchResults) debouncedGetInitialSearchResults.cancel();
    if (search.length === 0 || search.length >= 4) {
      dispatch(
        fetchInitialPurchaseOrderList(organizationId, {
          distributor_ids: distributors,
          ...getOrderStatusFilters(statusFilter, precursorStatusFilter),
          ...getOrderDateRangeFilter(dateRange),
          search_number: search,
        }),
      );
    }
  };

  const incrementPage = (): void => {
    if (hasMore) {
      dispatch(
        fetchPurchaseOrderList(organizationId, {
          row: (orders.meta.row ?? 0) + orders.meta.page_size,
          no_count: true,
          distributor_ids: distributorFilter.filters.distributors,
          ...getOrderStatusFilters(statusFilter, orderPrecursorStatusFilter.precursorStatusFilter),
          ...getOrderDateRangeFilter(dateRange),
          search_number: search,
        }),
      );
    }
  };

  const debouncedGetInitialSearchResults = useCallback(
    debounce(initialSearch, wait, { leading: false, trailing: true, maxWait }),
    [],
  );

  useEffect(() => {
    debouncedGetInitialSearchResults({
      organizationId,
      statusFilter,
      precursorStatusFilter: orderPrecursorStatusFilter.precursorStatusFilter,
      distributors: distributorFilter.filters.distributors,
      dateRange,
      search,
    });
  }, [
    statusFilter,
    dateRange,
    search,
    orderPrecursorStatusFilter.precursorStatusFilter,
    distributorFilter.filters.distributors,
  ]);

  useEffect(
    () => () => {
      dispatch(resetPurchaseOrderList());
    },
    [],
  );

  return {
    incrementPage,
    orders,
    loading,
    hasMore,
    dateRangeFilter: {
      dateRange,
      applyDateRangeFilter,
    },
    orderStatusFilter: {
      orderStatusFilters,
      selectOrderStatusFilter,
      statusFilter,
    },
    orderPrecursorStatusFilter: {
      ...orderPrecursorStatusFilter,
      selectOrderPrecursorStatusFilter,
    },
    distributorFilter: {
      ...distributorFilter,
      onSaveHandler: distributorFilterOnSave,
    },
    searchOrder,
    search,
  };
};
