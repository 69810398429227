import { ButtonBase } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

interface WrapperProps {
  width: string;
  height: string;
}

const ImageWrapper = styled(ButtonBase)`
  position: relative;
  width: ${(props: WrapperProps) => props.width};
  height: ${(props: WrapperProps) => props.height};
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  &&.animation {
    animation: ${loadingAnimation} 1s infinite;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const S = {
  ImageWrapper,
  Placeholder,
  StyledImage,
};
