/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useSelector, useDispatch } from 'react-redux';

import { State } from 'store';
import { fetchPurchaseOrderDetails } from 'store/reducers/purchase-order/purchase-order.actions';
import { uploadPrekursorStatusDocument } from 'store/reducers/PrekursorStatus/actions';
import { OrderDetails } from 'store/reducers/Orders';
import { useEffect } from 'react';
import { clearSPDocument, getSPDocument } from 'store/reducers/Orders/actions';
import { DataObject } from 'types';
import { AnalyticsEvent, makeAnalyticsRequest } from 'utils/Analytics/Segment';
import { isWebview } from 'utils/helpers/check-platform';

type UseOrderPrekursorStatus = {
  loading: boolean;
  disabled: boolean;
  error: string | null;
  refetchPrekursorStatus: (poId: number) => void;
  uploadPrekursorDocument: (purchaseOrder: OrderDetails, file: File) => void;
  spLoading: boolean;
  checkSPDocument: (poId: number) => void;
};

export const useOrderPrekursorStatus = (order: DataObject<any>): UseOrderPrekursorStatus => {
  const dispatch = useDispatch();

  const { loading, disabled, error } = useSelector((state: State) => state.precursorStatus);
  const { spDocumentUrl, spLoading } = useSelector((state: State) => state.orders);
  const uploadPrekursorDocument = async (purchaseOrder: OrderDetails, file: File) => {
    await dispatch(uploadPrekursorStatusDocument(purchaseOrder.precursor_status?.id, file));
    makeAnalyticsRequest('pt_precursor_upload_document', AnalyticsEvent.Track, {
      pharmacyId: purchaseOrder.pharmacy_id,
      poNumber: purchaseOrder.po_number,
      status: purchaseOrder.precursor_status?.status,
    });
    dispatch(fetchPurchaseOrderDetails(purchaseOrder.id));
  };

  const refetchPrekursorStatus = (poId: number): void => {
    dispatch(fetchPurchaseOrderDetails(poId));
  };

  const checkSPDocument = (poId: number) => {
    dispatch(getSPDocument(poId));
  };

  useEffect(() => {
    if (spDocumentUrl) {
      const anchor = document.createElement('a');
      anchor.href = spDocumentUrl;
      anchor.download = `SP_${order.pharmacy_id}_${order.po_number}.pdf`;
      if (!isWebview()) anchor.target = '_blank';
      anchor.click();
      makeAnalyticsRequest('pt_precursor_download_document', AnalyticsEvent.Track, {
        pharmacyId: order.pharmacy_id,
        poNumber: order.po_number,
        status: order.precursor_status?.status,
      });
    }
    return (): any => dispatch(clearSPDocument());
  }, [spDocumentUrl, dispatch]);

  useEffect(() => {
    makeAnalyticsRequest('pt_precursor_open_detail_page', AnalyticsEvent.Page, {
      pharmacyId: order.pharmacy_id,
      poNumber: order.po_number,
      status: order.precursor_status?.status,
    });
  }, []);
  return {
    loading,
    disabled,
    error,
    refetchPrekursorStatus,
    uploadPrekursorDocument,
    checkSPDocument,
    spLoading,
  };
};
