import styled from 'styled-components';

import Button, { ButtonProps } from '@material-ui/core/Button';

const isContained = (variant: ButtonProps['variant']): boolean =>
  variant === 'contained';

const isDefaultColor = (color: ButtonProps['color']): boolean =>
  !color || color === 'default';

const Wrapper = styled(Button)`
  && {
    background: ${({ color, variant }: ButtonProps) => {
      if (isDefaultColor(color) && isContained(variant)) {
        return '#E9E9E9';
      }

      return undefined;
    }};
    color: ${({ color, variant }: ButtonProps) => {
      if (isDefaultColor(color) && isContained(variant)) {
        return '#919191';
      }

      if (isContained(variant)) {
        return '#fff';
      }

      return undefined;
    }};
    border-radius: 40px;
    box-shadow: none;
    font-size: 0.9rem;
    font-weight: 900;
  }
`;

export const S = {
  Wrapper,
};
