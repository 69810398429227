import React, { useEffect, useMemo } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as UploadContainerBackgroud } from 'images/upload-container.svg';
import { ReactComponent as UploadImageIcon } from 'images/upload-photo-icon.svg';
import { S } from './file-upload.style';
import {
  MAX_FILE_SIZE_IN_BYTES,
  MAX_FILE_SIZE_IN_MB,
} from 'components/pages/OrderPage/components/PrekursorDocument/Precursor.constant';
import { TranslateProps } from 'utils/Localization';

export interface Props {
  title: string;
  loading: boolean;
  handleFile: (files) => void;
  setOpen: (open: boolean) => void;
  setMessage: (message: string) => void;
}

export const FileUpload: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({
  title,
  loading,
  handleFile,
  setOpen,
  setMessage,
  t,
}) => {
  const icons = useMemo(
    () => ({
      upload: <UploadImageIcon />,
      container: <UploadContainerBackgroud />,
    }),
    [],
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/*',
    onDrop: handleFile,
    noDrag: true,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      const error = fileRejections[0].errors[0];
      switch (error.code) {
        case ErrorCode.FileTooLarge:
          setMessage(t('prekursor.fileSizeValidationError', { size: MAX_FILE_SIZE_IN_MB }));
          break;
        case ErrorCode.FileInvalidType:
          setMessage(t('prekursor.fileTypeValidationError'));
          break;
        default:
          setMessage(t('prekursor.generalValidationError'));
          break;
      }
      setOpen(true);
    }
  }, [fileRejections]);

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} type="file" />

      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <S.Label>
          {icons.upload}
          <p>{title}</p>
        </S.Label>
      )}
    </div>
  );
};
