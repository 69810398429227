import styled from 'styled-components';

import * as colors from '../../../../../styles/colors';

export interface ContainerProps {
  hidden?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: ${colors.WHITE};
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  top: 0px;
  z-index: 1200;
  ${(props: ContainerProps) => props.hidden && 'display:none;'}
`;
