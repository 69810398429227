import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Chip } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Img } from 'components/pages/ProductDetailPage/components/ProductImage/style';
import { HelpDialog } from 'components/common/HelpDialog';
import styled from 'styled-components';
import { useLinkHandler } from 'hooks';
import { setMenuDrawer } from 'store/reducers/SearchSuggestions/actions';
import { useCampaignSearchMarketing } from 'hooks/useCampaignSearchMarketing';
import { SearchSection } from 'utils/Analytics/Segment';
import * as colors from '../../../../styles/colors';
import { SectionHeader } from './SectionHeader.component';
import { S } from './search-sections.style';
import { IconedRowLoader } from './IconedRowLoader.component';
import { KeywordLoader } from './KeywordLoader.component';
import { IBaseSection } from '../interface';

interface ICampaignBasedSection extends IBaseSection {}

const KeywordChipsMultiRow = styled.div<{ isEmpty?: boolean }>`
  ${({ isEmpty }) => isEmpty && 'height:0'}
`;
const IconedGenSquareList = styled.div<{ isEmpty?: boolean }>`
  ${({ isEmpty }) => isEmpty && 'height:0'}
`;

const chipWrapperStyle = {
  flexWrap: 'wrap !important',
  lineHeight: '2em',
  height: '4.3em',
  overflow: 'hidden',
};

const CampaigBasedSuggestionContainerBase: React.FC<React.PropsWithChildren<ICampaignBasedSection>> = ({ t, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [loadedIcons, setLoadedIcons] = useState(false);
  const [loadedKeywords, setLoadedKeywords] = useState(false);

  const dispatch = useDispatch();

  const { onClick: handleLinkClick, showHelpDialog, helpDialogURL, toggleHelpDialog } = useLinkHandler();
  const { marketing, data: campaignSearchState } = useCampaignSearchMarketing();

  useEffect(() => {
    setLoaded(!!campaignSearchState);
    if (!!campaignSearchState && campaignSearchState) {
      // marketing data was fetched once and we need to check if there are visible cards
      setLoadedIcons(campaignSearchState.icons.length > 0);
      setLoadedKeywords(campaignSearchState.keywords.length > 0);
    }
  }, [campaignSearchState]);

  const closeDrawer = (): void => {
    dispatch(setMenuDrawer(false));
  };

  const handleClick = (url: string): void => {
    handleLinkClick(url, closeDrawer, {
      search_section: SearchSection.CAMPAIGN_LISTING_SEARCH,
    });
  };
  const showKeywords =
    loaded && // TODO: SPT-17933 remove !isLoading , show old data if there is any, load in background
    loadedKeywords &&
    !!campaignSearchState &&
    campaignSearchState.keywords.length > 0;

  const showIcons =
    loaded && // TODO: SPT-17933 remove !isLoading , show old data if there is any, load in background
    loadedIcons &&
    !!campaignSearchState &&
    campaignSearchState.icons.length > 0;

  if (
    props.expandSection ||
    !marketing ||
    (loaded && !showKeywords && !showIcons) ||
    !loaded // NOTE: prevents shimmering SPT-18140
  ) {
    return null;
  }

  return (
    <S.Section id="tour__search__campaign_based">
      <SectionHeader t={t} title={props.title} toggle={() => null} isExpanded={false} />
      <HelpDialog open={showHelpDialog} close={toggleHelpDialog} link={helpDialogURL} />
      <KeywordChipsMultiRow isEmpty={!showKeywords && loaded}>
        <S.ChipsWrapper {...chipWrapperStyle}>
          {!loaded && <KeywordLoader />}
          {showKeywords &&
            campaignSearchState?.keywords.map((square) => (
              <Chip
                key={square.id}
                data-testid={`search-square-${square.id}`}
                id={`tour__search__campaign_based-iconed-${square.id}-description`}
                style={{
                  margin: '4px 8px 4px 4px',
                  color: colors.PRIMARY_COLOR,
                  backgroundColor: colors.backgrounds.LIGHT_PRIMARY_COLOR_BG_10,
                }}
                avatar={<TrendingUpIcon fontSize="small" style={{ color: colors.PRIMARY_COLOR }} />}
                label={square.title}
                onClick={(_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  handleClick(square.listing_link);
                }}
                clickable
              />
            ))}
        </S.ChipsWrapper>
      </KeywordChipsMultiRow>

      <IconedGenSquareList isEmpty={!showIcons && loaded}>
        {!loaded && <IconedRowLoader />}
        {showIcons &&
          campaignSearchState?.icons.map((square) => (
            <S.Item
              minHeight="24px"
              key={square.id}
              data-testid={`general-square-icon-${square.id}`}
              customPadding="4rem"
              onClick={(_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                handleClick(square.listing_link);
              }}
            >
              <S.ImageWrapper top="8px" backgroundColor={square.icon_url ? 'transparent' : ''}>
                <Img src={square.icon_url} />
              </S.ImageWrapper>

              <div>
                {square.title}
                <div style={{ color: 'gray', fontSize: '.7em' }}>
                  {square.description && (
                    <span id={`tour__search__campaign_based-keyword-${square.id}-description`}>
                      {square.description}
                    </span>
                  )}
                </div>
              </div>
            </S.Item>
          ))}
      </IconedGenSquareList>
      <HelpDialog open={showHelpDialog} close={toggleHelpDialog} link={helpDialogURL} />
    </S.Section>
  );
};

export const CampaigBasedSuggestionContainer = CampaigBasedSuggestionContainerBase;
