import { Action } from 'redux';
import { v3Client } from 'utils/Client/v3';
import { recordException } from 'utils/Reporting/Sentry';
import { trackAddProductSuggestion } from 'utils/Analytics/Segment';
import { ThunkActionCreator } from '../../../types/thunk';

import constants from './constants';
import { State } from './index';
import { Actions as GlobalActions, showError, showSuccess } from '../Global/actions';

interface ShowProductAction extends Action {
  type: constants.SHOW_DIALOG;
}

interface HideProductAction extends Action {
  type: constants.HIDE_DIALOG;
}

interface SubmitSuggestionAction extends Action {
  type: constants.SUBMIT_SUGGESTION;
}

interface MessageCollection {
  success: string;
  failure: {
    lengthLessThan3: string;
    unknown: string;
  };
}

export type Actions = GlobalActions | ShowProductAction | HideProductAction | SubmitSuggestionAction;

export const showDialog: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.SHOW_DIALOG,
  });
};

export const hideDialog: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.HIDE_DIALOG,
  });
};

export const submitProductRequest: ThunkActionCreator<Actions, State> = (
  productName: string,
  { success, failure }: MessageCollection,
) => async (dispatch) => {
  try {
    if (!productName || productName.length < 3) {
      dispatch(showError(failure.lengthLessThan3));
      return;
    }

    await v3Client.post('products/suggestions', {
      product_name: productName,
    });

    dispatch({ type: constants.HIDE_DIALOG });
    dispatch(showSuccess(success));

    trackAddProductSuggestion({
      product_name: productName,
    });
  } catch (error) {
    recordException(error, 'submitProductRequest', { productName });
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(showError(failure.unknown));
  }
};
