import React from 'react';

import { Grid } from '@material-ui/core';

import { ReactComponent as RemoveIcon } from 'images/ic_remove.svg';

import { IItemConfig, ISearchItem } from '../interface';
import { S } from './ChipSectionExpanded.style';

interface IChipSectionExpanded {
  sectionId: string;
  title?: string;
  onDelete?: (param?: ISearchItem) => void;
  dataDisplay: ISearchItem[];
  highlightedIndex: number;
  items: IItemConfig;
  additionalExpandFunction?: (key: string) => void;
  onSelect: (selectedItem) => void;
}

export const ChipSectionExpanded: React.FC<React.PropsWithChildren<IChipSectionExpanded>> = (props) => {
  const { title, onDelete, dataDisplay, sectionId, highlightedIndex, items, additionalExpandFunction, onSelect } =
    props;

  return (
    <Grid container direction="column" style={{ width: '97%' }}>
      {dataDisplay.map((product) => {
        items.itemIndex += 1;
        const index = items.itemIndex;

        return (
          <S.SectionRowGrid item xs={12}>
            <Grid container>
              <S.ItemNameGrid
                item
                xs={11}
                data-testid={`select-product-${title}-${product?.id}`}
                onClick={() => {
                  onSelect({
                    ...product,
                    sectionId,
                    isActive: highlightedIndex === index,
                  });
                  if (additionalExpandFunction) additionalExpandFunction('');
                }}
              >
                <>{product.name}</>
              </S.ItemNameGrid>
              <S.ItemDeleteGrid item xs={1}>
                <RemoveIcon
                  height={15}
                  width={15}
                  onClick={onDelete ? () => onDelete(product) : undefined}
                  data-testid={`remove-product-${title}-${product?.id}`}
                />
              </S.ItemDeleteGrid>
            </Grid>
          </S.SectionRowGrid>
        );
      })}
    </Grid>
  );
};

ChipSectionExpanded.defaultProps = {
  onDelete: undefined,
  title: undefined,
  additionalExpandFunction: undefined,
};
