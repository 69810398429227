import React from 'react';
import styled from 'styled-components';

import WarningIcon from '@material-ui/icons/Warning';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { Card as CardBase, CardHeader, ButtonBase } from '@material-ui/core';

import * as colors from 'components/styles/colors';

export const Card = styled(CardBase).attrs({ elevation: 0 })`
  margin-top: 10px;

  && {
    background: ${colors.DANGER}19;
    color: ${colors.DANGER_DARKER};
  }
`;

const Button = styled(ButtonBase)`
  && {
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.DANGER_DARKER};
  }
`;

interface WarningCardProps {
  title: string | React.ReactNode;
  actionTitle?: string | React.ReactNode;
  onClick?: () => void;
}

export const WarningCard: React.FC<React.PropsWithChildren<WarningCardProps>> = (props) => {
  const { title, actionTitle, onClick } = props;
  return (
    <Card>
      <CardHeader
        avatar={<WarningIcon />}
        title={title}
        subheader={
          actionTitle && (
            <Button onClick={onClick}>
              {actionTitle} <ChevronRight />
            </Button>
          )
        }
      />
    </Card>
  );
};
