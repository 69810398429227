import React from 'react';
import Typography from '@material-ui/core/Typography';
import { AllZIndexes } from 'utils/constants';
import { TooltipTour, useTooltipTour } from '../TooltipTour';

import { S } from './filter-tab.styles';
import { FilterTabsProps } from './filter-tab.type';

export const FilterTabs = <T extends string>({
  filters,
  onClick,
  value,
  t,
  tKey = '',
  className = '',
}: FilterTabsProps<T>): JSX.Element => {
  let tooltip: Record<string, any> | null = null;

  return (
    <>
      <S.Container className={className}>
        {filters.map((filter) => {
          const isSelected = value === filter;
          const isPrekursor = filter === 'waiting-document';

          if (isPrekursor) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            tooltip = useTooltipTour('prekursorTooltipTour.activityFilter', 'bottom');
          }

          return (
            <S.Pill
              data-testid={`filter-${filter}`}
              onClick={() => onClick(filter)}
              key={filter}
              isSelected={isSelected}
              className={isSelected ? 'active' : ''}
            >
              <S.Label isSelected={isSelected}>{tKey !== '' ? t(`${tKey}.${filter}`) : filter}</S.Label>
              {tooltip && isPrekursor && <div ref={tooltip.tooltipParams.setTriggerRef} />}
            </S.Pill>
          );
        })}
      </S.Container>
      {tooltip && (
        <TooltipTour
          name="prekursorTooltipTour.activityFilter"
          tooltipParams={(tooltip as any).tooltipParams}
          visible={(tooltip as any).visible}
          width={210}
          offsetY={11}
          offsetX={-85}
          zIndex={AllZIndexes.FilterTabTour}
        >
          <Typography>{t('prekursorTooltipTour.activityFilter')}</Typography>
        </TooltipTour>
      )}
    </>
  );
};
