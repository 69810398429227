import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchPurchaseOrderDetails } from 'store/reducers';
import { TranslateProps } from 'utils/Localization/types';
import { OrdersModal } from 'components/pages/orders-page/components';
import { State as StoreState } from 'store';
import { toggleOrderModal } from 'store/reducers/Orders/actions';
import { OrderDetailContainer } from './OrderDetail.style';
import { OrderDetailCard } from './orders-details-card';

interface OwnProps {
  orders: any;
}

const OrderDetail: React.FC<React.PropsWithChildren<OwnProps & TranslateProps>> = (props) => {
  const { t, orders } = props;
  const dispatch = useDispatch();

  const {
    orders: {
      orderModal: { isOpen: isOrdersOpen },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const toggleOrdersModal = (): void => {
    dispatch(toggleOrderModal(!isOrdersOpen));
  };

  const closeOrdersModal = (): void => {
    dispatch(toggleOrderModal(false));
  };
  const goToOrder = (orderId: number): void => {
    dispatch(fetchPurchaseOrderDetails(orderId));
    toggleOrdersModal();
  };

  return (
    <OrderDetailContainer>
      {orders.map((order) => (
        <OrderDetailCard key={order.id} order={order} onClick={() => goToOrder(order.id)} t={t} />
      ))}
      <OrdersModal open={isOrdersOpen} onClose={closeOrdersModal} />
    </OrderDetailContainer>
  );
};

export default OrderDetail;
