import * as React from 'react';

export interface Props {
  className?: string;
  width?: number | string;
  height?: number | string;
  full?: boolean;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  centered?: boolean;
  background?: string;
}

/**
 * Wraps compoonents in an absolute container that can be full or centered
 */
const Layout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, width, height, full, centered, top, left, bottom, right, background, children } = props;

  const style: React.CSSProperties = {
    position: 'absolute',
    width,
    height,
    top: full ? 0 : top || (centered ? '50%' : 0),
    left: full ? 0 : left || (centered ? '50%' : 0),
    bottom: full ? 0 : bottom,
    right: full ? 0 : right,
    background,
    display: 'block',
    WebkitTransform: centered ? 'translate(-50%, -50%)' : undefined,
    transform: centered ? 'translate(-50%, -50%)' : undefined,
    overflow: 'hidden',
  };

  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

export default Layout;
