import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { GRAY } from 'components/styles/colors';

const Wrapper = styled(Grid)`
  height: 100%;
  flex-direction: column;
  position: absolute;
`;

const PageEnd = styled(Typography)`
  && {
    color: #7c7c7c;
    text-align: center;
    padding-bottom: 8px;

    svg {
      color: #44a340;
      font-size: 40px;
      margin: 0 auto 4px;
      display: block;
    }
  }
`;

const Message = styled.div`
  color: ${GRAY};
  padding: 10px;
  margin: 0;
`;

export const S = {
  Wrapper,
  PageEnd,
  Message,
};

// NOTE: From Product Page and using by another component
export const LoadingContainer = styled.div<{ padding?: string | number }>`
  display: flex;
  justify-content: center;
  padding: ${(props) => props.padding || `8px 0`};
`;
