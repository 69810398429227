import styled from 'styled-components';
import { Grid, Badge as MuiBadge } from '@material-ui/core';
import MuiBasketIcon from '@material-ui/icons/ShoppingBasket';
import * as colors from 'components/styles/colors';

const Badge = styled(MuiBadge)`
  span {
    color: white;
    margin-top: 3px;
  }
`;

const BasketIcon = styled(MuiBasketIcon)<any>`
  && {
    color: ${colors.BLUISH_GRAY};
    width: 1em;
    height: 1em;
  }
`;

const BasketContainer = styled(Grid)`
  padding: 3px 3px 3px 3px;
  display: flex;
`;

export const S = {
  Badge,
  BasketIcon,
  BasketContainer,
};
