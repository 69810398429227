export const CREATE_CREDIT_REQUEST = 'CREATE_CREDIT_REQUEST';
export type CREATE_CREDIT_REQUEST = typeof CREATE_CREDIT_REQUEST;

export const GET_CREDIT_REQUEST = 'GET_CREDIT_REQUEST';
export type GET_CREDIT_REQUEST = typeof GET_CREDIT_REQUEST;

export const READ_CREDIT_REQUEST = 'READ_CREDIT_REQUEST';
export type READ_CREDIT_REQUEST = typeof READ_CREDIT_REQUEST;

export const RESET_CREDIT_REQUEST = 'RESET_CREDIT_REQUEST';
export type RESET_CREDIT_REQUEST = typeof RESET_CREDIT_REQUEST;

export const SET_CREDIT_REQUEST_LOADING = 'SET_CREDIT_REQUEST_LOADING';
export type SET_CREDIT_REQUEST_LOADING = typeof SET_CREDIT_REQUEST_LOADING;
