import React, { CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router';
import throttle from 'lodash/throttle';

import { routeKey, RenderRoutes } from 'utils/route.utils';
import { BasePageTracker, getEventPageIdentity } from 'utils/Analytics/Segment/Helper';
import { SearchMenuSuggestions } from 'components/common';
import { InitialSuggestionsEnum } from 'hooks';
import qs from 'query-string';

import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { FiltersSortsHolder } from 'components/common/FilterToolbar/interface';
import * as colors from '../../styles/colors';
import Layout from '../../views/Layout';
import Content from '../../views/Content';
import Container from '../../views/Container';
import ShowIf from '../../views/ShowIf';
import AppNav from '../../common/AppNav/component';
import Search from '../../common/Search/component';
import { LogoSmall } from '../../common/LogoSmall';
import BottomNav from '../../common/BottomNav';
import TermsDialog from './components/TermsDialog';
import { TranslateProps, FALLBACK_LOCALE, ID_LOCALE } from '../../../utils/Localization/types';
import { Basket } from './components/basket';
import { FavoriteLinkIcon } from './components/favorite-link/favorite-link.component';
import { marketingSearchPlaceHolder } from './utils';
import { market } from '../../../utils/MarketConfig';
import { getSearchProps } from '../ProductListingPage/utils/ProductlistingPage.util';
/**
 * Typings
 */
export interface StateProps {
  cartItems: number;
  agreed: boolean;
  tours: string[];
  triggerRender: boolean;
  config: any;
  isSearchMenuOpen: boolean;
}

export interface DispatchProps {
  agreeToTerms: () => any;
  saveTours: (request: any) => any;
  TriggerRenderTours: (value: boolean) => any;
  fetchTours: () => any;
}

export interface OwnProps extends RouteComponentProps<any> {}

/**
 * The main screen component
 */
class MainPage extends React.Component<StateProps & DispatchProps & OwnProps & TranslateProps> {
  titleStyle: CSSProperties = { fontSize: '12px', fontWeight: 'bold', paddingTop: '16px' };

  contentStyle: CSSProperties = { fontSize: '12px' };

  state = {
    showDrawer: false,
    stepIndex: 0,
  };

  get shouldShowDrawer(): boolean {
    const { showDrawer } = this.state;
    return showDrawer;
  }

  get shouldShowTerms(): boolean {
    const { agreed } = this.props;
    return !agreed;
  }

  get cartCount(): number | undefined {
    const { cartItems } = this.props;
    const cartCount = cartItems === 0 ? undefined : cartItems;

    return cartCount;
  }

  showDrawer = (): void => {
    this.setState({ showDrawer: true });
  };

  hideDrawer = (): void => {
    this.setState({ showDrawer: false });
  };

  goTo = (page: string) => (): void => {
    const { history, match } = this.props;
    const pageIdentity = getEventPageIdentity(match, window.location.search);

    const productPathname =  '/id/main/products';
    if ( match.url === page && productPathname === page) {
     return;
    }

    // NOTE: workaround since types is lost somewhere.
    const urlParams = qs.parse(history.location.search, BRACKET_QS_OPTIONS) as unknown as Partial<FiltersSortsHolder>;

    // cleanup
    delete urlParams.filters;
    delete urlParams.sorts;
  
    history.push({
      pathname: page,
      search: qs.stringify(urlParams, BRACKET_QS_OPTIONS),
      state: { source: 'search_box', initial_page: pageIdentity },
    });
  };

  goBack = (): void => {
    const {
      history: { goBack },
    } = this.props;
    goBack();
  };

  exit = (): void => {
    window.location.href = 'swrx://exit';
  };

  agree = (): void => {
    const { agreeToTerms } = this.props;
    agreeToTerms();
  };

  handleClickNextButton = (): void => {
    const { stepIndex } = this.state;

    if (stepIndex === 1) {
      this.setState({
        stepIndex: stepIndex + 1,
      });
    }
  };

  render(): JSX.Element {
    const { t, match, config, isSearchMenuOpen } = this.props;
    const removeBorder = !!(match.params.tab === 'home' || match.params.tab === 'orders');
    // SPT-12687: Marketing place holder. Temporary solution will be remove soon
    const currDate = new Date().toISOString().slice(0, 10);
    const marketId = market.marketId || FALLBACK_LOCALE;

    const placeholder =
      marketingSearchPlaceHolder[currDate] && marketId === ID_LOCALE
        ? marketingSearchPlaceHolder[currDate]
        : t('searchInput');
    const searchProps = getSearchProps(BasePageTracker.GENERAL_SEARCH, placeholder, {});
    return (
      <Layout full background={colors.OFF_WHITE}>
        <Container>
          <ShowIf condition={['home'].includes(match.params.tab)}>
            <AppNav
              withBranding
              onBack={this.goBack}
              onClickOptions={this.showDrawer}
              removeBorder={removeBorder}
              removeMenuOption
            >
              <ShowIf condition={['accounts', 'home', 'products'].includes(match.params.tab)}>
                <Basket onClick={this.goTo} cartCount={this.cartCount} />
                <FavoriteLinkIcon id="tour__wishlist" onClick={this.goTo(routeKey('wishlist_favorites'))} />
                <SearchMenuSuggestions
                  placeholder={placeholder}
                  searchResultsPage={`/${marketId}/search`}
                  initialSuggestions={[
                    InitialSuggestionsEnum.CAMPAIGN_BASED,
                    InitialSuggestionsEnum.RECENTLY_SEARCHED,
                    InitialSuggestionsEnum.RECENTLY_VIEWED,
                  ]}
                  autoFocus={false}
                  searchUrl="products/search"
                  hideBackOnClose
                  autoFill={false}
                  {...searchProps}
                />
                {!isSearchMenuOpen && <LogoSmall />}
              </ShowIf>
              <ShowIf condition={['carts'].includes(match.params.tab)}>
                <Search readOnly placeholder={t('searchInput')} onClick={this.goTo(routeKey('search'))} />
              </ShowIf>
            </AppNav>
          </ShowIf>
          <Content>
            <RenderRoutes routeKey="main" match={match} />
          </Content>
          <BottomNav value={match.params.tab} cartBadge={this.cartCount} onNavigate={this.goTo} />
        </Container>
        <TermsDialog
          config={config}
          open={this.shouldShowTerms}
          onAgree={throttle(this.agree, 200)}
          onClose={this.exit}
          t={t}
        />
      </Layout>
    );
  }
}

export default MainPage;
