import styled from 'styled-components';

import * as colors from 'components/styles/colors';

export const Salutation = styled.div`
  font-size: 15px;
  font-weight: 700;
  padding: 8px 12px;
`;

export const StickyBottom = styled.div`
  display: flex;
  position: sticky;
  bottom: 8px;
  justify-content: center;

  .more {
    color: ${colors.PRIMARY_COLOR};
    background: white;
    border-radius: 24px;

    :hover {
      background: white;
    }

    .arrow {
      margin-left: -4px;
      margin-right: 4px;
    }
  }
`;

export const defaultFirstWidgetConfig = {
  styling: {
    color: 'white',
    dot_navigation: {
      hidden: false,
    },
    see_all: {
      hidden: false,
      position: 'bottom',
    },
  },
};

export const defaultWidgetConfig = {
  styling: {
    color: 'white',
    dot_navigation: {
      hidden: true,
    },
    see_all: {
      hidden: false,
      position: 'top',
    },
  },
};
