import React from 'react';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import { IVoucherUsage } from 'services/swipe-rx-pt/resources/voucher/interface/voucher-usage.interface';

import { ReactComponent as VoucherIcon } from 'images/ic_voucher.svg';

import { toCurrency } from 'utils/Format';
import { TranslateProps } from 'utils/Localization';

import { S } from './VoucherUsage.style';

interface Props extends TranslateProps {
  voucherUsage?: IVoucherUsage;
}

export const VoucherUsageComponent: React.FC<React.PropsWithChildren<Props>> = ({ voucherUsage }) => {
  const isUsingVoucher = Boolean(voucherUsage);

  if (!isUsingVoucher) return null;

  return (
    <>
      <S.VoucherTitleContainer>
        <Grid item xs={1}>
          <VoucherIcon style={{ fill: '#FF7F30' }} />
        </Grid>
        <Grid item xs={11}>
          <S.Title>Voucher</S.Title>
        </Grid>
      </S.VoucherTitleContainer>

      <S.VoucherContainer>
        <Grid item xs={8}>
          <S.VoucherCode>{voucherUsage?.voucher?.code}</S.VoucherCode>
        </Grid>
        <Grid item xs={4}>
          <S.VoucherUsed>- {toCurrency(voucherUsage?.total_discount || 0)}</S.VoucherUsed>
        </Grid>
      </S.VoucherContainer>
    </>
  );
};

export const VoucherUsage = translate()(VoucherUsageComponent);
