import { Action } from 'redux';
import { swipeRxPt } from 'services';
import { recordException } from 'utils/Reporting/Sentry';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';

/**
 * Action types
 */
export interface FailUploadRequirementAction extends Action {
  type: constants.FAIL_PATCH_PREKURSOR_STATUS;
  payload: {
    error: any;
    errorCode: any;
  };
}
export interface SuccessUploadRequirementAction extends Action {
  type: constants.SUCCESS_PATCH_PREKURSOR_STATUS;
  payload: any;
}

export interface GetPendingUploadAction extends Action {
  type: constants.SUCCESS_GET_PENDING_UPLOAD_PREKURSOR;
  payload: any;
}
export interface FailGetPendingUploadAction extends Action {
  type: constants.FAIL_GET_PENDING_UPLOAD_PREKURSOR;
  payload: any;
}

export interface SetPrekursorStatusDisabled extends Action {
  type: constants.SET_PREKURSOR_STATUS_DISABLED;
}

export interface SetPrekursorStatusLoading extends Action {
  type: constants.SET_PREKURSOR_STATUS_LOADING;
}

export type Actions =
  | FailUploadRequirementAction
  | SetPrekursorStatusDisabled
  | SetPrekursorStatusLoading
  | SuccessUploadRequirementAction
  | GetPendingUploadAction
  | FailGetPendingUploadAction;

export const uploadPrekursorStatusDocument: ThunkActionCreator<Action> =
  (id: number | null, file: File) => async (dispatch) => {
    if (!id) return;

    dispatch({
      type: constants.SET_PREKURSOR_STATUS_LOADING,
    });

    try {
      const result = await swipeRxPt.prekursorStatus.upload(id, file);
      dispatch({
        type: constants.SUCCESS_PATCH_PREKURSOR_STATUS,
        payload: result,
      });
    } catch (error) {
      const errorCast = error as Error;
      recordException(errorCast, 'uploadPrekursorStatusDocument', { id, fileName: file.name });
      dispatch({
        type: constants.FAIL_PATCH_PREKURSOR_STATUS,
        payload: { error: errorCast.name },
      });
    }
  };

export const getPendingUpload: ThunkActionCreator<Action> = () => async (dispatch) => {
  try {
    const result = await swipeRxPt.prekursorStatus.getPendingUpload();
    dispatch({
      type: constants.SUCCESS_GET_PENDING_UPLOAD_PREKURSOR,
      payload: result,
    });
  } catch (error) {
    recordException(error as Error, 'getPendingUpload');
    dispatch({
      type: constants.FAIL_GET_PENDING_UPLOAD_PREKURSOR,
      payload: { error: (error as Error).message },
    });
  }
};
