import { store } from 'store';
import { Store } from 'redux';
import { History, Location as HistoryLocation } from 'history';

import uniq from 'lodash/uniq';
import { NavigationAction } from 'utils/constants';
import globalConstants from '../../store/reducers/Global/constants';

const updateBackStack = (storeInstance: Store, backStack?: HistoryLocation[]) => {
  const uniqueBackstack = uniq(backStack);
  storeInstance.dispatch({
    type: globalConstants.SET_BACK_STACK,
    backStackLength: uniqueBackstack?.length || 0,
    backStack: uniqueBackstack,
  });
};

const getQueryAndProductIdValue = (search: string) => {
  const urlSearchParams = new URLSearchParams(search || '');
  const query = urlSearchParams.get('query') || '';
  const productId = urlSearchParams.get('product_id') || '';
  return { query, productId };
};
const getIsSameLocation = (loc1: HistoryLocation, loc2: HistoryLocation) => {
  const queryParam1 = getQueryAndProductIdValue(loc1?.search);
  const queryParam2 = getQueryAndProductIdValue(loc2?.search);

  return (
    loc1?.pathname === loc2?.pathname &&
    queryParam1?.productId === queryParam2?.productId &&
    queryParam1?.query === queryParam2?.query
  );
};

const getActionState = (
  location: HistoryLocation<any>,
  action: NavigationAction,
  backStack: HistoryLocation[],
): NavigationAction => {
  let actionState = action;
  const lastBackStackLocation = backStack[backStack?.length - 1];

  if (location?.state?.isCloseModalAction) {
    actionState = NavigationAction.POP;
  }
  if (location?.state?.isOpenModalAction && action !== NavigationAction.POP) {
    return NavigationAction.PUSH;
  }
  if (actionState === NavigationAction.POP) {
    const isSameLocation = getIsSameLocation(lastBackStackLocation, location);
    if (isSameLocation) {
      return NavigationAction.DEFAULT;
    }
  }
  return actionState;
};

export const browserBackStackListener = (history: History): (() => void) => {
  let currentBackStack: HistoryLocation[] = [];
  const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    currentBackStack = state.global.backStack;
  });
  const currentBackStackLength = currentBackStack?.length || 0;

  history.listen((location, action) => {
    const actionState = getActionState(location, action as NavigationAction, currentBackStack);

    switch (actionState) {
      case NavigationAction.POP:
        updateBackStack(store, currentBackStack.slice(0, currentBackStackLength - 1));
        break;
      case NavigationAction.PUSH:
        updateBackStack(store, [...currentBackStack, location]);
        break;
      case NavigationAction.REPLACE:
        if (currentBackStackLength > 0) {
          updateBackStack(store, [...currentBackStack.slice(0, currentBackStackLength - 1), location]);
        }
        break;
      default:
        updateBackStack(store, currentBackStack);
    }
  });

  return () => {
    unsubscribe();
  };
};
