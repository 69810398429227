import React from 'react';

import { S } from './GradientCard.style';

export interface GradientCardProps {
  bgImage?: string;
  color: {
    start: string;
    end: string;
  };
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const GradientCard: React.FC<React.PropsWithChildren<GradientCardProps>> = ({ bgImage, color, children, onClick, style }) => (
  <S.Wrapper color={color} onClick={onClick} style={style}>
    <S.BGIcon className="bg-image" bgImage={bgImage} />
    {children}
  </S.Wrapper>
);
