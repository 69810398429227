import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButtonBase from '@material-ui/core/IconButton';
import FileCopyBase from '@material-ui/icons/FileCopy';

import * as colors from '../../../../../styles/colors';

interface ContainerProps {
  width?: string;
  paid?: boolean;
}

interface ValueProps {
  size?: number;
  color?: string;
  weight?: number;
}
interface LabelProps {
  weight?: number;
  size?: number;
  color?: string;
  textTransform?: string;
}

export const Container = styled(Grid)<ContainerProps>`
  && {
    background: ${(props: ContainerProps) => (props.paid ? 'white' : '#fff9f2')};
    ${(props: ContainerProps) => (!props.paid ? 'border-top: 1px solid rgba(0, 0, 0, 0.1);' : '')};
    ${(props: ContainerProps) => (!props.paid ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1);' : '')};
    padding: 8px 2px;
    width: ${(props: ContainerProps) => (props && props.width ? props.width : '50vw')};
    align-items: center;

    ${(props: ContainerProps) =>
      props.paid ? 'box-shadow: 0px 3px 3px rgb(58 62 71 / 10%), 0px 3px 3px rgb(99 106 122 / 10%)' : ''};
  }
`;

export const BaseFont = styled(Typography)`
  && {
    font-style: normal;
    font-weight: normal;
    line-height: 160%;
    letter-spacing: 0.16px;
  }
`;

export const Label = styled(BaseFont)<any>`
  && {
    font-weight: ${(props: LabelProps) => (props && props.weight ? props.weight : 'bold')};
    font-size: ${(props: LabelProps) => (props && props.size ? props.size : 12)}px;
    text-transform: ${(props: LabelProps) => (props && props.textTransform ? props.textTransform : 'uppercase')};
    color: ${(props: LabelProps) => (props && props.color ? props.color : '#8e8e8e')};
  }
`;

export const Value = styled(BaseFont)<any>`
  && {
    font-size: ${(props: ValueProps) => (props && props.size ? props.size : 13)}px;
    color: ${(props: ValueProps) => (props && props.color ? props.color : '#000')};
    ${(props: ValueProps) => (props && props.weight ? `font-weight: ${props.weight} !important;` : null)};
  }
`;

export const IconButton = styled(IconButtonBase)`
  && {
    width: 12px;
    height: 12px;
    background: transparent;
    color: transparent;
    margin-left: 5px;
  }
`;

export const FileCopy = styled(FileCopyBase)`
  && {
    width: 12px;
    color: ${colors.REBRAND_GREEN};
  }
`;
