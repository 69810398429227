import styled from 'styled-components';
import { shade } from 'polished';

interface Props {
  color: string;
  dark: boolean;
}

const Wrapper = styled.div<any>`
  background: ${({ color }: Props) => color};
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 40px;

  .search-bar {
    border: 0;
    background: ${({ color }: Props) => shade(0.1, color)} !important;
    border-radius: 60px;
    margin: 0;
    padding: 5px 8px;
    z-index: -1;

    div::before,
    div::after {
      border: 0;
    }
  }

  svg,
  input {
    color: ${({ dark }: Props) => (dark ? '#818181' : 'rgba(255, 255, 255, 0.7)')} !important;
  }

  input {
    padding-right: 20px;
  }

  svg:first-of-type {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const S = {
  Wrapper,
};
