import { Action } from 'redux';
import { v3Client } from 'utils/Client/v3';
import { store } from 'store';
import { ThunkActionCreator } from '../../../types/thunk';

import constants from './constants';
import { State } from './index';

import { Actions as GlobalActions, showError } from '../Global/actions';

interface ShowDialogAction extends Action {
  type: constants.SHOW_LOYALTY_POINT_DIALOG;
  data: string[];
}

interface HideDialogAction extends Action {
  type: constants.HIDE_LOYALTY_POINT_DIALOG;
}

interface MessageCollection {
  success: string;
  failure: {
    lengthLessThan3: string;
    unknown: string;
  };
}

export type Actions = GlobalActions | ShowDialogAction | HideDialogAction;

export const showDialog: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  const organization_id = 0; // point desc applied to all org
  try {
    const marketLanguage = store.getState().config.market.language;
    const lang = !marketLanguage ? 'en' : marketLanguage;
    const result = await v3Client.get(`loyalty/${organization_id}/point-description?lang=${lang}`);
    const { data } = result;
    // console.log(data)
    if (data) {
      dispatch({
        type: constants.SHOW_LOYALTY_POINT_DIALOG,
        data,
      });
    }
  } catch (error) {
    dispatch(showError(error));
  }
};

export const hideDialog: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.HIDE_LOYALTY_POINT_DIALOG,
  });
};
