export const GET_PRODUCTS = 'GET_PRODUCTS';
export type GET_PRODUCTS = typeof GET_PRODUCTS;

export const START_SEARCH = 'START_SEARCH';
export type START_SEARCH = typeof START_SEARCH;

export const END_SEARCH = 'END_SEARCH';
export type END_SEARCH = typeof END_SEARCH;

export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export type CLEAR_PRODUCTS = typeof CLEAR_PRODUCTS;

export const FAIL_GET_PRODUCTS = 'FAIL_GET_PRODUCTS';
export type FAIL_GET_PRODUCTS = typeof FAIL_GET_PRODUCTS;

export const CLEAR_PRODUCT_ERROR = 'CLEAR_PRODUCT_ERROR';
export type CLEAR_PRODUCT_ERROR = typeof CLEAR_PRODUCT_ERROR;

export const SET_LISTING_PAGE_REFERER = 'SET_LISTING_PAGE_REFERER';
export type SET_LISTING_PAGE_REFERER = typeof SET_LISTING_PAGE_REFERER;

export const CLEAR_LISTING_PAGE_REFERER = 'CLEAR_LISTING_PAGE_REFERER';
export type CLEAR_LISTING_PAGE_REFERER = typeof CLEAR_LISTING_PAGE_REFERER;
