import React, { useState, useEffect, useRef } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { NotificationsActive } from '@material-ui/icons';
import qs from 'query-string';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { State } from 'store';
import { useTierPricing } from 'hooks/useTierPricing';
import { useStockOut } from 'hooks/useStockOut';
import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG } from 'services';
import * as CounterActions from 'store/reducers/Counter/actions';
import * as FavoriteProductActions from 'store/reducers/FavoriteProduct/actions';
import * as ProductDetailActions from 'store/reducers/ProductDetail/actions';
import { CausalFoundryTrackImpressionDataLog } from 'utils/Analytics/components/causal-foundry-track-impression-data-log.component';
import { useMarketing } from 'hooks';
import { WithImpression } from 'utils/ProductImpressions';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';

import {
  BasePageTracker,
  getEventPageIdentity,
  ImpressionType,
  ProductSimpleSearchLocationProps,
  SearchSectionState,
  SegmentPropsBuilder,
  trackProductDetail,
} from 'utils/Analytics/Segment';
import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { DocumentIcon } from '../DocumentIcon';
import { FavoriteButton } from '../FavoriteButton';
import { TooltipTour, useTooltipTour } from '../TooltipTour';

import { ProductCardDetails } from './components/product-card-details/ProductCardDetails.component';
import { ProductCardRibbon } from './components/product-card-ribbon/ProductCardRibbon.component';
import { ProductCardTierDiscount } from './components/product-card-tier-discount/ProductCardTierDiscount.component';
import { ProductCardImageContainer } from './components/product-card-image/ProductCardImage.container';
import { ProductCardStockOut } from './components/product-card-stock-out';
import { StockOutDialog } from './components/stock-out-dialog';
import { ProductCardLoyaltyPoint } from './components/product-card-loyalty-point';
import { ProductCardContainerProps, DispatchProps } from './ProductCard.interface';
import { S } from './ProductCard.styles';
import { AvailabilityTimer } from './components/AvailabilityTimer';
import { useProductLimit } from './components/product-card-quantity-box/hooks/use-product-limit.hook';
import { ProductContext } from './ProductCard.context';
import { ILocationProps } from '../SearchMenuSuggestions/searchMenuSuggestion.interface';
import { FiltersSortsHolder } from '../FilterToolbar/interface';

const ProductCardContainerBase: React.FC<React.PropsWithChildren<ProductCardContainerProps>> = ({
  product,
  promoted,
  tourIds,
  isReminder,
  styles,
  analyticsDataLog,
  addOrUpdateProductQuantity,
  onClear,
  t,
  indexNumber,
  impressionCollector,
  productImpressionPage,
  singleImpression = false,
  detailPageDisplayType = DetailPageDisplayType.ROUTE,
  setProductDetail,
  setProductDetailId,
  setproductDetailModalToggle,
}) => {
  const {
    itemCount,
    color,
    changeColor,
    isPromoApplied,
    activePrice,
    activeDiscount,
    activeDiscountedPrice,
    isTierDiscountAvailable,
  } = useTierPricing(product, promoted);
  const ref = useRef(null);
  const [currentProduct, setCurrentProduct] = useState(product);
  const { isOpen, toggle: toggleDialog, requestAvailability, isStockOut, availabilityRequested } = useStockOut(product);
  const { tooltipParams, visible } = useTooltipTour('prekursorTooltipTour.productCard', 'right');
  const [hasLoyaltyPoint, setHasLoyaltyPoint] = useState(false);
  const location = useLocation<ProductSimpleSearchLocationProps>();
  const history = useHistory();
  const match = useRouteMatch();
  const { marketing } = useMarketing();
  const campaignState = useSelector((state: State) => state.campaign, shallowEqual);
  const { maxPurchasedReached, maxPurchaseMonthlyReached } = useProductLimit(product, {});
  const pageEventIdentity = getEventPageIdentity(match, location?.search);

  const parsedLocationSearch =
    qs.parse(location.search, BRACKET_QS_OPTIONS) || ('' as unknown as ILocationProps & FiltersSortsHolder);
  const marketingValues = {
    query: parsedLocationSearch.query as string,
    marketing_id: marketing?.id,
    marketing_name: marketing?.name,
    category_ids: parsedLocationSearch.category_ids,
  };

  const rankIndex = [BasePageTracker.GENERAL_SEARCH, BasePageTracker.CATEGORY_LISTING].includes(pageEventIdentity)
    ? indexNumber
    : undefined;

  const trackProductDetailBuilder = (): void => {
    const search_section = location?.state?.search_section;
    const locationParsed = qs.parse(location.search, BRACKET_QS_OPTIONS) as ProductSimpleSearchLocationProps;
    const locationData = {
      ...locationParsed,
      ...{ marketing_id: marketing?.id, marketing_name: marketing?.name || '' },
    };
    const productDetailPayload = SegmentPropsBuilder.buildProductDetailMapping(
      product,
      isStockOut,
      locationData,
      search_section,
      campaignState,
      rankIndex,
    );
    trackProductDetail(productDetailPayload);
  };

  const handleOpenModal = (): void => {
    const combinedQuery = qs.stringify(
      {
        ...marketingValues,
        goBackTo: parsedLocationSearch.goBackTo,
        isModalOpen: true,
        product_id: product.id,
        filters: parsedLocationSearch.filters,
        sorts: parsedLocationSearch.sorts,
      },
      BRACKET_QS_OPTIONS,
    );

    setProductDetailId(product.id);
    setProductDetail(currentProduct);
    history.replace({
      pathname: match.url,
      search: combinedQuery,
      state: { ...location.state, indexNumber: rankIndex, isOpenModalAction: true, isCloseModalAction: false },
    });
    setproductDetailModalToggle(true);
  };

  const tooltipYPosition = (ref.current as any)?.getBoundingClientRect().top || window.innerHeight;
  // 0.8 to indicate height 80%
  // 25 is fixed pixels number; since header have fixed height
  const tooltipOffsetX =
    tooltipYPosition / window.innerHeight < 0.8 && tooltipYPosition > 25 ? -75 : -75 * window.innerWidth * 5;

  const queryString = qs.stringify(marketingValues, BRACKET_QS_OPTIONS);

  useEffect(() => {
    setCurrentProduct(product);
    setHasLoyaltyPoint(Number(product.loyalty_point?.points) > 0);
  }, [product]);

  const handleClear = (): void => {
    if (onClear) {
      onClear(currentProduct);
    }
  };

  return (
    <WithImpression
      product={currentProduct}
      impressionCollector={impressionCollector}
      key={product.id}
      productImpressionPage={productImpressionPage}
      searchQuery={parsedLocationSearch.query}
      orderNumber={indexNumber}
      singleImpression={singleImpression}
      marketingQuery={marketingValues}
      locationState={location?.state as SearchSectionState}
      impressionType={ImpressionType.LISTING}
    >
      <ProductContext.Provider value={{ indexNumber }}>
        <div ref={ref as any}>
          <S.Wrapper
            data-testid="container-productCard"
            id={tourIds.cardId}
            container
            direction="column"
            justify="center"
            alignItems="center"
            key={product.id}
            style={styles?.wrapper}
          >
            <S.ProductCardContainer promo={isPromoApplied} maxPurchasedReached={maxPurchasedReached}>
              {onClear && (
                <S.ClearButton id="tour__bell_icon" onClick={handleClear}>
                  <NotificationsActive color="secondary" />
                </S.ClearButton>
              )}
              <ProductCardRibbon
                discount={activeDiscount}
                color={color.secondary}
                isStockOut={isStockOut}
                isUnpurchasable={product?.is_unpurchasable}
              />
              <ProductCardImageContainer
                queryString={queryString}
                tourIds={tourIds}
                product={product}
                isStockOut={isStockOut}
                detailPageDisplayType={detailPageDisplayType}
                handleOpenModal={handleOpenModal}
                trackProductDetail={trackProductDetailBuilder}
              />
              <div ref={tooltipParams.setTriggerRef}>
                <DocumentIcon flag={product.flag} className={hasLoyaltyPoint ? 'doc-middle' : ''} />
              </div>
              <ProductCardLoyaltyPoint point={product.loyalty_point} />

              <ProductCardDetails
                color={color}
                product={product}
                tourIds={tourIds}
                changeColor={changeColor}
                isReminder={isReminder}
                isStockOut={isStockOut}
                toggleDialog={requestAvailability}
                sellingPrice={activePrice}
                discount={activeDiscount}
                discountedPrice={activeDiscountedPrice}
                availabilityRequested={availabilityRequested}
                queryString={queryString}
                detailPageDisplayType={detailPageDisplayType}
                handleOpenModal={handleOpenModal}
                trackProductDetail={trackProductDetailBuilder}
              >
                <FavoriteButton
                  id={tourIds.favoriteId}
                  location={{ top: '4px', right: '4px' }}
                  product={currentProduct}
                  t={t}
                />
              </ProductCardDetails>
            </S.ProductCardContainer>
            <AvailabilityTimer
              show={maxPurchasedReached || maxPurchaseMonthlyReached}
              isMonthly={maxPurchaseMonthlyReached}
              bordered
              gutter
            />
            {isStockOut && !availabilityRequested && <ProductCardStockOut />}
            {!isStockOut && (
              <ProductCardTierDiscount
                product={product}
                color={color}
                itemCount={itemCount}
                activeDiscount={activeDiscount}
                isPromoApplied={isPromoApplied}
                isTierDiscountAvailable={isTierDiscountAvailable}
                addOrUpdateProductQuantity={addOrUpdateProductQuantity}
                styles={styles?.tierPricings}
              />
            )}
          </S.Wrapper>
          <StockOutDialog isOpen={isOpen} toggle={toggleDialog} />
          <TooltipTour
            name="prekursorTooltipTour.productCard"
            tooltipParams={tooltipParams}
            visible={visible && product.flag && product.flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG}
            width={164}
            offsetY={5}
            offsetX={tooltipOffsetX}
            arrowOffsetX={0}
            arrowOffsetY={31}
          >
            <Typography style={{ fontWeight: 'bold' }}>{t('prekursorTooltipTour.productCardTitle')}</Typography>
            <Typography>{t('prekursorTooltipTour.productCard')}</Typography>
          </TooltipTour>
          <CausalFoundryTrackImpressionDataLog
            product={product}
            promoId={marketing?.id ?? undefined}
            className={analyticsDataLog?.causalFoundry?.itemClassName || 'causal-foundry-product-card-details'}
          />
        </div>
      </ProductContext.Provider>
    </WithImpression>
  );
};

const mapDispatchToProps: DispatchProps = {
  addOrUpdateProductQuantity: CounterActions.addOrUpdateProductQuantity,
  addToFavoriteProduct: FavoriteProductActions.addToFavoriteProduct,
  removeFromFavoriteProduct: FavoriteProductActions.removeFromFavoriteProduct,
  setProductDetail: ProductDetailActions.setProductDetail,
  setProductDetailId: ProductDetailActions.setDetailPageProductId,
  setproductDetailModalToggle: ProductDetailActions.setDetailPageModalToggle,
};

export const ProductCard = translate('productCard')(connect(null, mapDispatchToProps)(ProductCardContainerBase));
