import * as React from 'react';
import { AppBar, Toolbar, IconButton, Grid, Typography } from '@material-ui/core';
import { Menu as MenuIcon, Close, ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import styled from 'styled-components';
import * as colors from '../../styles/colors';
import Content from '../../views/Content';
import ShowIf from '../../views/ShowIf';

/*
    @removeToolbar: This flag is more of a hack because there are children
    components/ui being rendered within the component.
    What happens in some cases is that it pushes the children content.
    This is technical debt that should be discussed.
    - Immanuel
*/
export interface Props {
  withBranding?: boolean;
  title?: string;
  onBack?: () => void;
  onClickOptions?: () => void;
  removeBorder?: boolean;
  removeMenuOption?: boolean;
  removeToolbar?: boolean;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  goTo?: () => void;
  theme?: 'light' | 'dark';
  children?: React.ReactNode;
}

// TODO : remove z-index when refactor Search Component / add Downshift inside it
const AppBarContainer = styled(AppBar)`
  && {
    position: static;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding-top: 15px;
    box-shadow: none;
    z-index: 99;
  }

  .back-button {
    color: ${colors.WHITE};
  }

  .header {
    .branding {
      height: 24pt;
      width: auto;
    }

    .title {
      color: ${colors.WHITE};
      font-size: 12pt;
      font-weight: 400;
    }
  }

  .icon-white {
    color: ${colors.WHITE};
  }

  .options-button {
    color: ${colors.WHITE};
  }
`;

const AppNav: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    children,
    withBranding,
    title,
    onBack,
    goTo,
    onClickOptions,
    removeBorder = false,
    removeMenuOption,
    removeToolbar = false,
    showBackButton = true,
    showCloseButton = false,
    theme,
  } = props;

  const shouldShowBranding = (): boolean => (typeof withBranding === 'undefined' ? false : withBranding);

  const shouldShowTitle = (): boolean => !shouldShowBranding() && typeof title !== 'undefined';

  const shouldShowMenu = (): boolean => typeof onClickOptions !== 'undefined';

  const themeStyles = {
    color: theme === 'light' ? colors.TEXT_LIGHT : colors.WHITE,
    backgroundColor: theme === 'light' ? colors.WHITE : colors.OFF_WHITE,
  };

  return (
    <AppBarContainer
      style={{
        // Only the home page should have a box-shadow because there's a background extension
        // of the same background-color required on the design
        boxShadow: removeBorder ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.1)',
        paddingLeft: '16px',
        paddingRight: '16px',
        height: 60,
        backgroundColor: themeStyles.backgroundColor,
      }}
    >
      <Toolbar
        style={{
          padding: '0px',
          flex: removeToolbar ? 1 : '',
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            flex: 1,
          }}
        >
          <ShowIf condition={shouldShowTitle() && showBackButton}>
            <IconButton
              data-testid="btn-header-back"
              className="back-button"
              onClick={onBack}
              style={{
                padding: '0 5px 0 0',
              }}
            >
              <ArrowBackIosIcon style={{ fill: themeStyles.color, height: 16, width: 16 }} />
            </IconButton>
          </ShowIf>
          <Content className="header">
            <ShowIf condition={shouldShowTitle()}>
              <Typography
                variant="h2"
                style={{
                  flex: '1',
                  fontSize: '12pt',
                  fontWeight: 800,
                  color: themeStyles.color,
                }}
              >
                {title}
              </Typography>
            </ShowIf>
          </Content>
          <ShowIf condition={shouldShowMenu()}>
            <IconButton
              className="options-button"
              onClick={onClickOptions}
              style={{
                padding: 0,
              }}
            >
              {removeMenuOption ? null : <MenuIcon style={{ fontSize: '30px' }} />}
            </IconButton>
          </ShowIf>
          <ShowIf condition={showCloseButton}>
            <IconButton onClick={goTo}>
              <Close className="icon-white" />
            </IconButton>
          </ShowIf>
        </Grid>
      </Toolbar>
      {children}
    </AppBarContainer>
  );
};

export default AppNav;
