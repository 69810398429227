import React from 'react';
import styled from 'styled-components';

import { Button, ButtonBase, Typography } from '@material-ui/core';
import { ChevronRight, LocalOffer } from '@material-ui/icons';

import * as colors from 'components/styles/colors';
import { breakpoints } from 'components/styles/breakpoints';
import { DocumentIcon } from '../DocumentIcon';
import { DocumentIconProps, ProductImageProps } from './ProductCarouselCard.interface';

const maxCardWidth = '87%';
const maxLeftPaneWidth = '32%';
const rightPanelPadding = '10px';

export const WidgetContainer = styled.div`
  display: flex;
  width: ${maxCardWidth};
  min-width: ${maxCardWidth};
  max-width: ${maxCardWidth};
  margin-right: 12px;
  background: white;
  box-shadow: 0px 0px 1px rgba(99, 106, 122, 0.12), 0px 2px 10px rgba(58, 62, 71, 0.22);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const LeftPane = styled.div`
  position: relative;
  max-width: ${maxLeftPaneWidth};
  min-width: ${maxLeftPaneWidth};
`;

export const RightPane = styled.div`
  position: relative;
  padding: ${rightPanelPadding};
  max-width: calc(100% - ${maxLeftPaneWidth} - (${rightPanelPadding} * 2));
  min-width: calc(100% - ${maxLeftPaneWidth} - (${rightPanelPadding} * 2));
  display: flex;
  flex-direction: column;

  .ls-box {
    margin: 4px 0;
    max-height: 28px;

    .notification-icon {
      font-size: 16px;
    }

    @media (max-width: ${breakpoints.phoneSmall}) {
      font-size: 8px;
    }

    @media (min-width: ${breakpoints.phoneSmall}) and (max-width: ${breakpoints.phoneMedium}) {
      font-size: 10px;
    }
  }
`;

export const ImageContainer = styled(ButtonBase)`
  && {
    display: block;
    width: 100%;
  }
`;

export const Image = styled.img<ProductImageProps>`
  display: block;
  outline: none;
  border: none;
  width: 100%;
  height: auto;
  background-color: ${(props) => (props.isEmpty ? 'none' : '#E4E7ED')};
  opacity: ${(props) => (props.isStockOut ? 0.5 : 1)};
  margin-top: ${(props) => props.isUnpurchasable && '28px'};
  cursor: pointer;
`;

export const PrecursorContainer = styled.div<DocumentIconProps>`
  position: absolute;
  left: 8px;
  bottom: ${({ haveLoyaltyReward }) => (haveLoyaltyReward ? '36' : '8')}px;
  width: 24px;
  height: 24px;
`;

export const PrecursorIcon = styled(DocumentIcon)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const TitleText = styled(({ ...props }) => <Typography {...props} />)`
  && {
    /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
    line-height: 1rem;
    max-height: 3rem;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    max-width: calc(100% - 32px);
    margin-top: ${(props) => props.isUnpurchasable && '28px'};
    word-break: break-word;
    cursor: pointer;

    @media (max-width: ${breakpoints.phoneSmall}) {
      font-size: 10px;
    }
  }
`;

export const MaxQty = styled.span`
  color: ${colors.PURPLE_DARK};
`;

export const PackagingText = styled(TitleText)`
  && {
    -webkit-line-clamp: 2;
    color: ${colors.BLUISH_GRAY_LIGHT};
    max-width: calc(100% - 28px);
    font-weight: normal;
    cursor: pointer;
    word-break: break-word;
  }
`;

export const DiscountButton = styled(Button).attrs({
  fullWidth: true,
  startIcon: <LocalOffer />,
  endIcon: <ChevronRight style={{ fontSize: 16 }} />,
})`
  && {
    margin: 4px 0;
    font-size: 12px;
    font-weight: bold;
    font-style: italic;
    max-height: 28px;
    color: rgba(23, 51, 232, 1);
    background: rgba(23, 51, 232, 0.05);
    text-transform: none;
    justify-content: left;

    :hover {
      background: rgba(23, 51, 232, 0.05) !important;
    }

    .MuiButton-startIcon {
      margin-left: 2px;
      margin-right: 10px;

      & > *:first-child {
        font-size: 16px;
      }
    }

    .MuiButton-endIcon {
      font-size: 16px;
      position: absolute;
      right: 8px;
    }

    @media (max-width: ${breakpoints.phoneSmall}) {
      font-size: 8px;
    }

    @media (min-width: ${breakpoints.phoneSmall}) and (max-width: ${breakpoints.phoneMedium}) {
      font-size: 10px;
    }
  }
`;

export const BottomContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
`;

export const PricingContainer = styled.div`
  flex: 1;
`;

export const DiscountedPrice = styled(Typography)`
  && {
    font-size: 12px;
    line-height: 12px;
    color: ${colors.BLUISH_GRAY_LIGHT};
    text-decoration: line-through;

    @media (max-width: ${breakpoints.phoneSmall}) {
      font-size: 8px;
    }
  }
`;

export const ActivePrice = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 800;
    line-height: 1rem;

    @media (max-width: ${breakpoints.phoneSmall}) {
      font-size: 10px;
    }
  }
`;

export const QtyContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row-reverse;

  .add-to-cart {
    border-radius: 20px;
  }
`;
