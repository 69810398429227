import React from 'react';
import { translate } from 'react-i18next';

import { PharmacyOrderStatus } from 'services';

import { S } from './activity-card-status.styles';

interface Props {
  t: any;
  status: PharmacyOrderStatus;
}

const ActivityCardStatusBase: React.FC<React.PropsWithChildren<Props>> = ({ t, status }) => (
  <S.Container status={status}>
    <S.Status status={status} data-testid="activity-card-component-status">
      {t(`orderStatus.${status}`)}
    </S.Status>
  </S.Container>
);

export const ActivityCardStatus = translate('orders')(ActivityCardStatusBase);
