/* eslint-disable react/require-default-props */
import React from 'react';
import { NoAvailableProducts } from './no-available-products.component';
import { S } from '../product-list.styles';

export const TopMessage: React.FC<{
  query?: string;
  fuzzy: boolean;
  empty: boolean;
  message?: string | JSX.Element;
}> = ({ query, fuzzy: isFuzzy, message, empty }): JSX.Element | null => {
  if (empty) {
    return <NoAvailableProducts isHorizontalLayout={isFuzzy} query={query} />;
  }

  if (!message) {
    return null;
  }

  if (React.isValidElement(message)) {
    return message;
  }

  return <S.Message>{message}</S.Message>;
};
