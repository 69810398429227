import { connect } from 'react-redux';

import { State as StoreState } from '../../../store';
import Search, { MapStateProps } from './component';

const mapStateToProps = (state: StoreState): MapStateProps => ({
  maxSearchInputLength: state.config?.market?.featureConfigs?.maxSearchInputLength,
});

export default connect(mapStateToProps)(Search);
