import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider as LocalizationProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import i18n from './utils/Localization';
import { store } from './store';
import { theme } from './components/styles/theme';
import App from './components/app';
import { unregister } from './registerServiceWorker';
import 'typeface-roboto';
import './components/styles/base.css';
import './components/styles/transitions.css';
import './utils/Format/config';
import { initialiseAnalytics } from './utils/Analytics/init';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import 'react-popper-tooltip/dist/styles.css';

import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'polyfill-array-includes';

// Render the app
try {
  unregister();

  initialiseAnalytics();

  const container = document.querySelector('#root') as Element | DocumentFragment;
  const root = createRoot(container);

  root.render(
    <LocalizationProvider i18n={i18n}>
      <Provider store={store}>
        <Router>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              autoHideDuration={3000}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </Router>
      </Provider>
    </LocalizationProvider>,
  );
} catch (error) {
  if (error instanceof Error) {
    document.body.innerText = `There was an error loading this page. Please try again later. ERROR: '${error.message}'.`;
  }
}
