import { Paper, Typography } from '@material-ui/core';
import { LocalOffer } from '@material-ui/icons';

import Slider from 'react-slick';
import styled from 'styled-components';

import * as colors from '../../../../styles/colors';

export const Container = styled(Slider)`
  height: 80px;
  max-width: calc(100% - 1rem);

  .slick-slide {
    margin-right: 12px;
  }

  .slick-list {
    margin-right: -12px;
  }

  .slick-track {
    display: flex;
  }

  .slick-dots {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button:before {
    font-size: 10px;
  }

  .slick-dots li.slick-active button:before {
    color: #14C47D;
  }
`;

export const ItemContainer = styled(Paper)`&& {
  height: 60px;
  min-width: 200px;
  position: relative;
  overflow: hidden;
  border: 1px solid ${colors.PRIMARY_COLOR};

  &::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    top: -30px;
    left: -65px;
    border-radius: 50%;
    background: ${colors.PRIMARY_COLOR};
  }
}`;

export const TagIcon = styled(LocalOffer)`&& {
  position: absolute;
  top: 18px;
  left: 14px;
  color: ${colors.WHITE};
}`;

export const LabelContainer = styled.div`
  padding-top: 10px;
  padding-left: 65px;
  padding-right: 10px;
`;

export const Label = styled(Typography)`&& {
  color: rgba(107, 124, 147, 1);
  font-weight: 400;
  font-size: 14px;
}`;

export const DiscountLabel = styled(Typography)`&& {
  color: ${colors.PRIMARY_COLOR};
  font-weight: 700;
  font-size: 16px;
}`;
