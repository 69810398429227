import { LABEL_VALUE_SEPARATOR } from 'store/reducers/FilterToolbar/constants';
import { FilterType } from 'components/common/FilterToolbar/constants';
import { ISearchSection } from '../components/interface';

export interface ICategoryFilterSeparator {
  category_ids: number[];
  filters: string[];
}

export const updateSuggestionThumbnail = (
  generalSections: ISearchSection[],
  suggestionSelectedFields: string[],
): ISearchSection[] => {
  if (suggestionSelectedFields.length) {
    const hideThumbnail = !suggestionSelectedFields.join().includes('thumbnail_photo');
    generalSections = generalSections.map((section) => ({ ...section, hideThumbnail }));
  }
  return generalSections;
};

export const categoryFilterSeparator = (filterFarams: string[]): ICategoryFilterSeparator => {
  const initialValue = {
    category_ids: [],
    filters: [],
  };

  if (!filterFarams.length) {
    return initialValue;
  }

  return filterFarams.reduce(
    (acc, filter) => {
      const [prefix, value] = filter.split(LABEL_VALUE_SEPARATOR);
      if (prefix === FilterType.CATEGORY) {
        acc.category_ids.push(Number(value));
      } else {
        acc.filters.push(filter);
      }
      return acc;
    },
    { category_ids: [], filters: [] } as ICategoryFilterSeparator,
  );
};
