import React from 'react';
import { translate } from 'react-i18next';

import { ReactComponent as OrderRefundIcon } from 'images/order-refund-square.svg';

import { Divider, Grid, List, ListItem } from '@material-ui/core';
import { IInvoiceReturn } from 'store/reducers/Return';
import moment from 'moment';
import { toCurrency } from 'utils/Format';
import { S } from './orders-modal-refund-info.styles';
import { Section } from './order-modal-section';

type RefundInfoPops = {
  refund: IInvoiceReturn;
  taxRate: number;
  t: any;
};
const OrdersModalRefundInfoComponent: React.FC<React.PropsWithChildren<RefundInfoPops>> = (props) => {
  const { refund, taxRate, t } = props;

  const amount = refund.return_items.reduce((total, item) => {
    const net = item.price * item.quantity;
    const discount = net * (item?.discount || 0);

    return total + (net - discount);
  }, 0);

  const tax = Math.ceil(amount * taxRate);
  const price = refund.total_amount - tax;

  return (
    <Grid style={{ padding: '16px 0' }} data-testid="orders-modal-refund-information-section">
      <Grid style={{ marginLeft: 16 }}>
        <Section title={t('refund.title')} icon={OrderRefundIcon} />
      </Grid>

      <S.Row direction="row" justifyContent="space-between" gutter>
        <S.Label>{t('refund.date')}</S.Label>
        <S.Value>
          {moment(refund?.created_at).format('DD MMM YYYY')}
          <br />
          {moment(refund?.created_at).format('hh:mm A')}
        </S.Value>
      </S.Row>

      <S.Row>
        <List component="div" style={{ width: '100%' }}>
          <ListItem style={{ justifyContent: 'space-between' }}>
            <S.Label>{t('refund.value')}</S.Label>
            <S.Value>{toCurrency(price)}</S.Value>
          </ListItem>
          <ListItem style={{ justifyContent: 'space-between' }}>
            <S.Label>{t('refund.tax')}</S.Label>
            <S.Value> {toCurrency(tax)}</S.Value>
          </ListItem>
          <Divider />
          <ListItem style={{ justifyContent: 'space-between' }}>
            <S.Label bold>{t('refund.total')}</S.Label>
            <S.Value bold>{toCurrency(refund?.total_amount || 0)}</S.Value>
          </ListItem>
        </List>
      </S.Row>
    </Grid>
  );
};

export const OrdersModalRefundInfo = translate('orders')(OrdersModalRefundInfoComponent);
