import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { State as StoreState } from 'store';
import {
  fetchProductReminders,
  deleteProductReminder,
  clearProductReminders,
} from 'store/reducers/ProductReminder/actions';
import { setCloseSnackbarCart } from 'store/reducers/Utils/actions';
import { ProductRemindersPage, StateProps } from './component';

const mapStateToProps = ({ productReminder, utils }: StoreState): StateProps => ({
  productReminder,
  snackbarCart: utils.snackbarCart,
});

const mapDispatchToProps = {
  fetchProductReminders,
  deleteProductReminder,
  setCloseSnackbarCart,
  clearProductReminders,
};

export default translate('productRemindersPage')(connect(mapStateToProps, mapDispatchToProps)(ProductRemindersPage));
