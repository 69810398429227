export enum PaymentActionTypes {
  // BANK ACCOUNT
  UPDATE_BANK_ACCOUNT = '@@payment/UPDATE_BANK_ACCOUNT',
  FAIL_BANK_ACCOUNT = '@@payment/FAIL_BANK_ACCOUNT',

  // DAYS PAST DUE
  SET_DPD_BLOCK_STATUS = '@@payment/SET_DPD_BLOCK_STATUS',
  FETCH_DPD_BLOCK_STATUS_FAIL = '@@payment/SET_DPD_BLOCK_STATUS_FAIL',

  // DEPOSITS
  SET_DEPOSITS_LOADING = '@@payment/SET_DEPOSITS_LOADING',
  FETCH_DEPOSITS = '@@payment/FETCH_DEPOSITS',
  CLEAR_DEPOSITS = '@@payment/CLEAR_DEPOSITS',
  SET_ALLOCATIONS_LOADING = '@@payment/SET_ALLOCATIONS_LOADING',
  FETCH_TOTAL_DEPOSITS = '@@payment/FETCH_TOTAL_DEPOSITS',
  FETCH_INVOICE_ALLOCATIONS = '@@payment/FETCH_INVOICE_ALLOCATIONS',

  // ALL DUE
  SET_ALL_DUE_LOADING = '@@payment/SET_ALL_DUE_LOADING',
  FETCH_ALL_DUE_PAYMENTS = '@@payment/FETCH_ALL_DUE_PAYMENTS',
  PAGINATE_ALL_DUE_PAYMENTS = '@@payment/PAGINATE_ALL_DUE_PAYMENTS',
  CLEAR_ALL_DUE_PAYMENTS = '@@payment/CLEAR_ALL_DUE_PAYMENTS',

  // DUE TODAY
  SET_DUE_TODAY_LOADING = '@@payment/SET_DUE_TODAY_LOADING',
  FETCH_DUE_TODAY_PAYMENTS = '@@payment/FETCH_DUE_TODAY_PAYMENTS',
  PAGINATE_DUE_TODAY_PAYMENTS = '@@payment/PAGINATE_DUE_TODAY_PAYMENTS',
  CLEAR_DUE_TODAY_PAYMENTS = '@@payment/CLEAR_DUE_TODAY_PAYMENTS',

  // DUE WITHIN WEEK
  SET_DUE_WITHIN_WEEK_LOADING = '@@payment/SET_DUE_WITHIN_WEEK_LOADING',
  FETCH_DUE_WITHIN_WEEK_PAYMENTS = '@@payment/FETCH_DUE_WITHIN_WEEK_PAYMENTS',
  PAGINATE_NOT_YET_DUE_PAYMENTS = '@@payment/PAGINATE_NOT_YET_DUE_PAYMENTS',
  CLEAR_DUE_WITHIN_WEEK_PAYMENTS = '@@payment/CLEAR_DUE_WITHIN_WEEK_PAYMENTS',

  // NOT YET DUE
  SET_NOT_DUE_LOADING = '@@payment/SET_NOT_DUE_LOADING',
  FETCH_NOT_YET_DUE_PAYMENTS = '@@payment/FETCH_NOT_YET_DUE_PAYMENTS',
  PAGINATE_DUE_WITHIN_WEEK_PAYMENTS = '@@payment/PAGINATE_DUE_WITHIN_WEEK_PAYMENTS',
  CLEAR_NOT_YET_DUE_PAYMENTS = '@@payment/CLEAR_NOT_YET_DUE_PAYMENTS',

  // HISTORY
  SET_PAYMENT_HISTORY_LOADING = '@@payment/SET_PAYMENT_HISTORY_LOADING',
  FETCH_PAYMENT_HISTORY = '@@payment/FETCH_PAYMENT_HISTORY',
  PAGINATE_PAYMENT_HISTORY = '@@payment/PAGINATE_PAYMENT_HISTORY',
  CLEAR_PAYMENT_HISTORY = '@@payment/CLEAR_PAYMENT_HISTORY',

  // INVOICE SUMMARY
  SET_DOWNLOAD_INVOICE_SUMMARY_LOADING = '@@payment/DOWNLOAD_INVOICE_SUMMARY_LOADING',
  SET_DOWNLOAD_INVOICE_SUMMARY_SUCCESS = '@@payment/SET_DOWNLOAD_INVOICE_SUMMARY_SUCCESS',
  SET_DOWNLOAD_INVOICE_SUMMARY_FAILED = '@@payment/SET_DOWNLOAD_INVOICE_SUMMARY_FAILED',

  // PAYMENT SELECTION
  PAYMENT_SELECTION_SET = '@@payment/PAYMENT_SELECTION_SET',

  // PAYMENT OPTION
  PAYMENT_OPTION_SET = '@@payment/PAYMENT_OPTION_SET',

  // PAYMENT INFORMATION
  PAYMENT_INFORMATION_SET = '@@payment/PAYMENT_INFORMATION_SET',

  FETCH_INVOICE_SUMMARY = '@@payment/FETCH_INVOICE_SUMMARY',
}
