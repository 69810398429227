export const TOURS = {
  product: [
    {
      title: 'tour.naviTitle',
      content: 'tour.naviContent',
      target: 'tour__bottomNav',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homeTitle',
      content: 'tour.homeContent',
      target: 'tour__home',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productTitle',
      content: 'tour.productContent',
      target: 'tour__products',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.orderTitle',
      content: 'tour.orderContent',
      target: 'tour__order',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.accountTitle',
      content: 'tour.accountTitleContent',
      target: 'tour__accounts',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.basketTitle',
      content: 'tour.basketContent',
      target: 'tour__basket',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.product.appChatTitle',
      content: 'tour.product.appChat',
      target: 'tour__appChat',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.wishlistLink.title',
      content: 'tour.wishlistLink.content',
      target: 'tour__wishlist',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.searchTitle',
      content: 'tour.searchContent',
      target: 'tour__searchbox',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.product.quickFilterTitle',
      content: 'tour.product.quickFilterContent',
      target: 'tour__productSearchLetter',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.product.productCardTitle',
      content: 'tour.product.productCardContent',
      target: 'tour__productCard__0',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.product.productImageTitle',
      content: 'tour.product.productImageContent',
      target: 'tour__productImg__0',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.product.productFavoriteTitle',
      content: 'tour.product.productFavoriteContent',
      target: 'tour__productFavorite__0',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: '',
      content: 'tour.product.productAddContent',
      target: 'tour__addBtn__0',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: '',
      content: 'tour.product.productQuantityContent',
      target: 'tour__quantityBtn__0',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.tierDiscount.title',
      content: 'tour.tierDiscount.content',
      target: 'tour__tierPricing',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: '',
      content: 'tour.tierDiscount.expandBtn',
      target: 'tour__tierExpandBtn',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'bottom',
      options: {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
      },
    },
    {
      title: '',
      content: 'tour.tierDiscount.discountBtn',
      target: 'tour__tierDiscountBtn',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
  ],

  tier_pricing: [
    {
      title: 'tour.tierDiscount.title',
      content: 'tour.tierDiscount.content',
      target: 'tour__tierPricing',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: '',
      content: 'tour.tierDiscount.expandBtn',
      target: 'tour__tierExpandBtn',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'bottom',
      options: {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
      },
    },
    {
      title: '',
      content: 'tour.tierDiscount.discountBtn',
      target: 'tour__tierDiscountBtn',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
  ],

  in_app_chat: [
    {
      title: '',
      content: 'tour.product.appChat',
      target: 'tour__appChat',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
  ],

  tab_updates: [
    {
      title: 'tour.updates.title',
      content: 'tour.updates.content',
      target: 'body',
      placement: 'center',
      textAlign: 'center',
      placementBeacon: 'top',
      options: {
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        spotlightClicks: true,
      },
    },
    {
      title: 'tour.updates.basketTitle',
      content: 'tour.updates.basketContent',
      target: 'tour__basket',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.updates.accountTitle',
      content: 'tour.updates.accountContent',
      target: 'tour__accounts',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
  ],

  orders_tab: [
    {
      title: 'tour.orderTab.search',
      content: 'tour.orderTab.searchDesc',
      target: 'tour_orders_tab_search',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.orderTab.activities',
      content: 'tour.orderTab.activitiesDesc',
      target: 'tour_orders_tab_activities',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.orderTab.end',
      content: 'tour.orderTab.endDesc',
      chatLink: 'tour.orderTab.clickHere',
      target: 'infinite-scrollable',
      placement: 'center',
      textAlign: 'center',
      options: {
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: false,
      },
    },
  ],

  orders_modal: [
    {
      title: 'tour.orderModal.status',
      content: 'tour.orderModal.statusDesc',
      target: 'tour_orders_tab_status',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.orderModal.documents',
      content: 'tour.orderModal.documentsDesc',
      target: 'tour_orders_tab_documents',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.orderModal.products',
      content: 'tour.orderModal.productsDesc',
      target: 'tour_orders_tab_products',
      placement: 'bottom',
      textAlign: 'center',
      options: {
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: false,
      },
    },
  ],

  basket: [{}],
  account: [
    {
      title: 'tour.detail',
      content: 'tour.detailContent',
      target: 'tour__account__detail',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.credit',
      content: 'tour.creditContent',
      target: 'tour__account__credit',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.due',
      content: 'tour.dueContent',
      target: 'tour__account__due',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.payment',
      content: 'tour.paymentContent',
      target: 'tour__account__payment',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.swipe',
      content: 'tour.swipeContent',
      target: 'tour__account__swiperx',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homePage',
      content: 'tour.homePageContent',
      target: 'tour__account__helpSetHomepage',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.exit',
      content: 'tour.exitContent',
      target: 'tour__account__exit',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
  ],

  search_page: [
    {
      title: 'tour.searchPage.mostPopularTitle',
      content: 'tour.searchPage.mostPopularContent',
      target: 'tour__search__mostPopular',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.searchPage.packagingTitle',
      content: 'tour.searchPage.packagingContent',
      target: 'tour__search__packaging',
      placement: 'bottom',
      placementBeacon: 'top',
    },
  ],

  search_page_empty: [
    {
      title: 'tour.searchPage.noResult.suggestionTitle',
      content: 'tour.searchPage.noResult.suggestionContent',
      target: 'tour__suggest__a__product',
      placement: 'bottom',
      placementBeacon: 'top',
    },
  ],

  wishlist_page: [
    {
      title: 'tour.wishlistPage.favoritesTabTitle',
      content: 'tour.wishlistPage.favoritesTabContent',
      target: 'tour__favorites__tab',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.wishlistPage.remindersTabTitle',
      content: 'tour.wishlistPage.remindersTabContent',
      target: 'tour__reminders__tab',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.wishlistPage.comingSoonTabTitle',
      content: 'tour.wishlistPage.comingSoonTabContent',
      target: 'tour__suggestions__tab',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.favoritesPage.removeFavoriteTitle',
      content: 'tour.favoritesPage.removeFavoriteContent',
      target: 'tour__productFavorite__0',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.wishlistPage.askCsTitle',
      content: 'tour.wishlistPage.askCsContent',
      target: 'tour__appChat',
      placement: 'bottom',
      placementBeacon: 'top',
    },
  ],

  v1_34_0: [
    {
      title: 'tour.productDetailPage.linkToPageTitle',
      content: 'tour.productDetailPage.linkToPageContent',
      target: 'tour__productCard__0',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
  ],

  product_detail_page: [
    {
      title: 'tour.productDetailPage.promotionTitle',
      content: 'tour.productDetailPage.promotionContent',
      target: 'tour__prod_promotion',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.descriptionTitle',
      content: 'tour.productDetailPage.descriptionContent',
      target: 'tour__prod_description',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.compositionTitle',
      content: 'tour.productDetailPage.compositionContent',
      target: 'tour__prod_composition',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.coreMoleculeTitle',
      content: 'tour.productDetailPage.coreMoleculeContent',
      target: 'tour__prod_coreMolecule',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.categoryTitle',
      content: 'tour.productDetailPage.categoryContent',
      target: 'tour__prod_category',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.atcTitle',
      content: 'tour.productDetailPage.atcContent',
      target: 'tour__prod_atc',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.manufacturerTitle',
      content: 'tour.productDetailPage.manufacturerContent',
      target: 'tour__prod_manufacturer',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.shippingTitle',
      content: 'tour.productDetailPage.shippingContent',
      target: 'tour__prod_shipping',
      placement: 'bottom',
      placementBeacon: 'top',
    },
    {
      title: 'tour.productDetailPage.tierTitle',
      content: 'tour.productDetailPage.tierContent',
      target: 'tour__tierPricing',
      placement: 'bottom',
      placementBeacon: 'top',
    },
  ],

  'homepage_v2.1.0': [
    {
      title: 'tour.homepage.loyaltyTitle',
      content: 'tour.homepage.loyaltyContent',
      target: 'tour__loyalty',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.mainBannerTitle',
      content: 'tour.homepage.mainBannerContent',
      target: 'tour__main_banner',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.benefitsTitle',
      content: 'tour.homepage.benefitsContent',
      target: 'tour__key_benefits',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.newProductsTitle',
      content: 'tour.homepage.newProductsContent',
      target: 'tour__new_products',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.topWeeklyProductsTitle',
      content: 'tour.homepage.topWeeklyProductsContent',
      target: 'tour__top_weekly_products',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.campaignListTitle',
      content: 'tour.homepage.campaignListContent',
      target: 'tour__campaign_list',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.hotDealsTitle',
      content: 'tour.homepage.hotDealsContent',
      target: 'tour__hot_deals',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.featuredProductsTitle',
      content: 'tour.homepage.featuredProductsContent',
      target: 'tour__featured_products',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.homepage.recentlyViewedTitle',
      content: 'tour.homepage.recentlyViewedContent',
      target: 'tour__recently_viewed',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
  ],

  'reminders_v2.1.0': [
    {
      title: 'tour.remindersPage.bellIconTitle',
      content: 'tour.remindersPage.bellIconContent',
      target: 'tour__bell_icon',
      placement: 'bottom',
      textAlign: 'center',
      options: {
        disableBeacon: true,
      },
    },
  ],
  product_listing_filter: [
    {
      title: 'tour.productListingPage.filterToolbarTitle',
      content: 'tour.productListingPage.filterToolbarContent',
      target: 'tour__filter_bar',
      placement: 'bottom',
      textAlign: 'center',
      options: {
        disableBeacon: true,
      },
    },
    {
      title: 'tour.productListingPage.filterButtonTitle',
      content: 'tour.productListingPage.filterButtonContent',
      target: 'tour__filter_button',
      placement: 'bottom',
      textAlign: 'center',
      options: {
        disableBeacon: true,
      },
    },
  ],
  filter_sort_modal: [
    {
      title: 'tour.productListingPage.filterModalTitle',
      content: 'tour.productListingPage.filterModalContent',
      target: 'tour__filter_menu',
      placement: 'bottom',
      textAlign: 'center',
      options: {
        disableBeacon: true,
      },
    },
  ],
  'loyalty_landing_v2.4.0': [
    {
      title: 'tour.loyaltyLandingPage.membershipDetailTitle',
      content: 'tour.loyaltyLandingPage.membershipDetailMessage',
      target: 'tour__ll_membership_detail',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyLandingPage.activePointsTitle',
      content: 'tour.loyaltyLandingPage.activePointsMessage',
      target: 'tour__ll_active_point',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyLandingPage.mostPopularRewardsTitle',
      content: 'tour.loyaltyLandingPage.mostPopularRewardsMessage',
      target: 'tour__ll_most_popular_rewards',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyLandingPage.howToGetPointsTitle',
      content: 'tour.loyaltyLandingPage.howToGetPointsMessage',
      target: 'tour__ll_how_to_get_points',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.loyaltyLandingPage.allRewardsTitle',
      content: 'tour.loyaltyLandingPage.allRewardsMessage',
      target: 'tour__all_rewards',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
  ],
  'loyalty_membership_v2.4.0': [
    {
      title: 'tour.loyaltyMembershipPage.membershipDetailTitle',
      content: 'tour.loyaltyMembershipPage.membershipDetailMessage',
      target: 'tour__lm_membership_detail',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyMembershipPage.pointSummaryTitle',
      content: 'tour.loyaltyMembershipPage.pointSummaryMessage',
      target: 'tour__lm_point_summary',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyMembershipPage.myRewardsTitle',
      content: 'tour.loyaltyMembershipPage.myRewardsMessage',
      target: 'tour__lm_my_rewards',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyMembershipPage.membershipBenefitsTitle',
      content: 'tour.loyaltyMembershipPage.membershipBenefitsMessage',
      target: 'tour__lm_membership_benefits',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
  ],
  'loyalty__hot_rewards_v2.4.0': [
    {
      title: 'tour.loyaltyHotRewardPage.searchTitle',
      content: 'tour.loyaltyHotRewardPage.searchMessage',
      target: 'tour__lr_search',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyHotRewardPage.pointsRequiredTitle',
      content: 'tour.loyaltyHotRewardPage.pointsRequiredMessage',
      target: 'tour__lr_points_required',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyHotRewardPage.vendorTitle',
      content: 'tour.loyaltyHotRewardPage.vendorMessage',
      target: 'tour__lr_vendor',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyHotRewardPage.categoryTitle',
      content: 'tour.loyaltyHotRewardPage.categoryMessage',
      target: 'tour__lr_category',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
  ],
  'loyalty__my_rewards_v2.4.0': [
    {
      title: 'tour.loyaltyMyRewardPage.searchTitle',
      content: 'tour.loyaltyMyRewardPage.searchMessage',
      target: 'tour__lr_search',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
  ],
  'loyalty_landing_v3.10.0': [
    {
      title: 'tour.loyaltyLandingPage.membershipDetailTitle',
      content: 'tour.loyaltyLandingPage.membershipDetailMessage',
      target: 'tour__ll_membership_detail',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyLandingPage.activePointsTitle',
      content: 'tour.loyaltyLandingPage.activePointsMessage',
      target: 'tour__ll_active_point',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyLandingPage.featuredMissionsTitle',
      content: 'tour.loyaltyLandingPage.featuredMissionsMessage',
      target: 'tour__ll_featured_missions',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyLandingPage.mostPopularRewardsTitle',
      content: 'tour.loyaltyLandingPage.mostPopularRewardsMessage',
      target: 'tour__ll_most_popular_rewards',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
    {
      title: 'tour.loyaltyLandingPage.howToGetPointsTitle',
      content: 'tour.loyaltyLandingPage.howToGetPointsMessage',
      target: 'tour__ll_how_to_get_points',
      placement: 'bottom',
      textAlign: 'center',
      placementBeacon: 'top',
    },
    {
      title: 'tour.loyaltyLandingPage.allRewardsTitle',
      content: 'tour.loyaltyLandingPage.allRewardsMessage',
      target: 'tour__all_rewards',
      placement: 'top',
      textAlign: 'center',
      placementBeacon: 'bottom',
    },
  ],
  'loyalty_landing_mission_v3.10.0': [],
};
