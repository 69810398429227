import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

import { WHITE } from '../../styles/colors';

const Wrapper = styled(Grid)`
  padding: 5px 0;
`;

const ProductCardContainer = styled((props) => <Grid {...props} />)`
  && {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: ${WHITE};
    align-items: center;
    position: relative;
    z-index: 5;
    transition: 0.3s ease-in;
    opacity: ${({ maxPurchasedReached }) => (maxPurchasedReached ? '0.65' : '1')};
  }
`;

const ClearButton = styled(IconButton)`
  && {
    padding: 5px;
    position: absolute;
    top: 32px;
    right: 3px;
  }
`;

export const S = {
  Wrapper,
  ProductCardContainer,
  ClearButton,
};
