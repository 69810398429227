import { makeAnalyticsRequest, AnalyticsEvent } from 'utils/Analytics/Segment';

interface Campaign {
  id?: number;
  name?: string;
}

interface WidgetTrackerHook {
  track: (event: string, type: AnalyticsEvent, payload?: any) => void;
}

export const useWidgetTracker = (campaign: Campaign): WidgetTrackerHook => {
  const track = (event: string, type: AnalyticsEvent, payload: any = {}): void => {
    if (!campaign.id || !campaign.name) return;

    makeAnalyticsRequest(event, type, {
      ...payload,
      campaign_id: campaign.id,
      campaign_name: campaign.name,
    });
  };

  return {
    track,
  };
};
