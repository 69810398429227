import { useEffect } from 'react';
import { BasePageTracker, getEventPageIdentity } from 'utils/Analytics/Segment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State as StoreState } from 'store';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { ISearchSection } from 'components/common/SearchMenuSuggestions/components/interface';
import { fetchMostPopularProducts } from 'store/reducers/Search/actions';
import {
  buildMostPopularSection,
  buildCampaingBasedSearchSection,
  buildRecentlySearchSection,
  buildRecentlyViewedSection,
  buildMarketingFeaturesSection,
} from './search-section/sections';

export enum InitialSuggestionsEnum {
  RECENTLY_SEARCHED = 'recently_searched',
  RECENTLY_VIEWED = 'recently_viewed',
  LAST_PURCHASED = 'recently_purchased',
  MOST_POPULAR = 'most_popular',
  CAMPAIGN_BASED = 'campaign_based',
}

export enum FixedSuggestionsEnum {
  MOST_POPULAR = 'most_popular_listing_search',
  MARKETING_FEATURE_WIDGETS = 'marketing_feature_widgets',
}

interface SearchSectionBuilder {
  initialSections?: ISearchSection[] | any[];
  fixedSections?: ISearchSection[] | any[];
}

export interface ISectionsConfig {
  initialSuggestions?: InitialSuggestionsEnum[];
  fixedSuggestions?: FixedSuggestionsEnum[];
  skipFetchSection?: boolean;
}

const filterSections = (currentSection: InitialSuggestionsEnum | FixedSuggestionsEnum, sections?: any[]): boolean => {
  const shouldShow = sections?.find((section) => section === currentSection);
  return !!shouldShow ?? false;
};

export const useSearchSectionBuilder = (config: ISectionsConfig): SearchSectionBuilder => {
  const { recentSearchProducts, mostPopularProducts, recentlyViewedSectionProducts } = useSelector(
    (state: StoreState) => ({ ...state.search, ...state.searchSuggestions }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const { location } = useHistory();
  // const { enqueueSnackbar } = useSnackbar();

  const { initialSuggestions = [], fixedSuggestions = [] } = config || {};

  const eventPageIdentity = getEventPageIdentity(match, location.search);

  const shouldShowRecentlyViewed = filterSections(InitialSuggestionsEnum.RECENTLY_VIEWED, initialSuggestions);
  const shouldShowMostPopular = filterSections(InitialSuggestionsEnum.MOST_POPULAR, initialSuggestions);
  const shouldShowCampaignBased = filterSections(InitialSuggestionsEnum.CAMPAIGN_BASED, initialSuggestions);
  const disabledFetchMostPopular = [BasePageTracker.PRODUCT_DETAIL].includes(eventPageIdentity);
  const shouldShowMarketingFeatureSection = filterSections(
    FixedSuggestionsEnum.MARKETING_FEATURE_WIDGETS,
    fixedSuggestions,
  );

  useEffect(() => {
    if (disabledFetchMostPopular && mostPopularProducts.length) return;
    if (!shouldShowMostPopular) return;
    dispatch(fetchMostPopularProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { data: recentlyPurchased } = useRecentPurchase({
  //   page: 1,
  //   pageSize: 5,
  //   showStockout: false,
  //   marketingType: MarketingType.REPURCHASE,
  // });

  const mostPopularSection: ISearchSection | undefined =
    mostPopularProducts && shouldShowMostPopular ? buildMostPopularSection(mostPopularProducts) : undefined;

  const campaigBasedSearchSection: ISearchSection | undefined = shouldShowCampaignBased
    ? buildCampaingBasedSearchSection()
    : undefined;

  const recentlySearchedSection: ISearchSection | undefined =
    recentSearchProducts && filterSections(InitialSuggestionsEnum.RECENTLY_SEARCHED, initialSuggestions)
      ? buildRecentlySearchSection(recentSearchProducts, dispatch)
      : undefined;

  const recentlyViewedSection: ISearchSection | undefined =
    recentlyViewedSectionProducts?.length && shouldShowRecentlyViewed
      ? buildRecentlyViewedSection(recentlyViewedSectionProducts)
      : undefined;

  const marketingFeatureWidgetSection: ISearchSection | undefined = shouldShowMarketingFeatureSection
    ? buildMarketingFeaturesSection()
    : undefined;

  // const shouldShowRecentlyPurchased = filterSections(InitialSuggestionsEnum.LAST_PURCHASED, initialSuggestions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const recentlyPurchasedSection: ISearchSection | undefined =
  //   recentlyPurchased?.length && shouldShowRecentlyPurchased
  //     ? {
  //         id: BasePageTracker.LAST_PURCHASED_SEARCH,
  //         title: 'recentlyPurchased',
  //         products: recentlyPurchased,
  //         shouldNotShowLoadingIfEmpty: true,
  //         display: SectionType.LIST,
  //         limit: 5,
  //         expandable: true,
  //         onExpand: () => {
  //           // trackRecentlyViewedPage(); // FIXME: replaceme
  //           history.push(`${routeKey('recently_purchase')}`);
  //         },
  //         showCartButton: true,
  //         onClickCartIcon: async (product: Product) => {
  //           const updatedProduct = await dispatch(incrementProductQuantity(product));
  //           // FIXME: dispatch type
  //           dispatch(updateCartItem(updatedProduct as unknown as any, UpdateCartItemType.CREATE_OR_UPDATE));

  //           enqueueSnackbar(product.name, {
  //             key: product.id,
  //             persist: false,
  //             content: AddToCartSnackBar,
  //             preventDuplicate: true,
  //           });
  //         },
  //       }
  //     : undefined;

  const initialSections =
    // [recentlySearchedSection, recentlyViewedSection, mostPopularSection, recentlyPurchasedSection].filter(
    [campaigBasedSearchSection, recentlySearchedSection, recentlyViewedSection, mostPopularSection].filter(
      (section) => !!section,
    ) ?? []; // FIXME: temporarily hide this as SPT-16011, to show it the analytic must be ready first SPT-14890

  const fixedSections = [marketingFeatureWidgetSection].filter((section) => !!section) ?? [];

  return {
    initialSections,
    fixedSections,
  };
};
