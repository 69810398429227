import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ReactComponent as ErrorImg } from 'images/account-suspended.svg';

import { store } from 'store';

import { DialogPage } from 'components/common/DialogPage/DialogPage.component';
import { AUTH_SESSION_ERRORS } from 'store/reducers/Auth/constants';
import * as colors from '../../styles/colors';
import Layout from '../../views/Layout';
import ShowIf from '../../views/ShowIf';
import { TranslateProps } from '../../../utils/Localization/types';

import { ErrorContainer } from './styles';

import { Props } from './interface';

const ErrorPage: React.FC<React.PropsWithChildren<Props & TranslateProps>> = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t, message, canContact } = props;

  const { config, auth } = store.getState();

  const query = new URLSearchParams(useLocation().search);
  const guestLandingUrl = query.get('guestLandingUrl') || config?.market?.guestLandingUrl;

  const refresh = (): void => {
    window.location.reload(true);
  };

  const exit = (): void => {
    window.location.href = 'swrx://exit';
  };

  const shouldShowContact = (): boolean => (typeof canContact === 'undefined' ? false : canContact);

  const shouldOpenFallbackPage = (): boolean =>
    !!guestLandingUrl &&
    !!auth.errorCode &&
    [
      AUTH_SESSION_ERRORS.NO_ASSIGNED_ORGANIZATION,
      AUTH_SESSION_ERRORS.INVALID_USER_ACTIVATION,
      AUTH_SESSION_ERRORS.USER_NOT_FOUND,
    ].includes(auth.errorCode);

  useEffect(() => {
    if (shouldOpenFallbackPage()) {
      setDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, auth]);

  return (
    <>
      <ShowIf condition={!shouldOpenFallbackPage()}>
        <Layout full background={colors.PRIMARY_COLOR}>
          <Layout centered>
            <ErrorContainer>
              <Card square className="box">
                <CardContent className="content">
                  <ErrorImg />

                  <Typography className="title">{t('sorryMessage')}</Typography>

                  <Typography className="message">{t('error', { message })}</Typography>

                  <Typography className="description">
                    {t('contactMessage')}

                    <ShowIf condition={shouldShowContact()}>
                      <span> {t('pressingButton')}</span>
                    </ShowIf>
                  </Typography>
                  <ShowIf condition={shouldShowContact()}>
                    <Button className="main-button" fullWidth>
                      {t('contactUs')}
                    </Button>

                    <Typography className="refresh-link" onClick={refresh}>
                      {t('tryAgain')}
                    </Typography>

                    <Typography className="exit-link" onClick={exit}>
                      {t('closeApp')}
                    </Typography>
                  </ShowIf>
                  <ShowIf condition={!shouldShowContact()}>
                    <Button className="main-button" fullWidth onClick={refresh}>
                      {t('tryAgain')}
                    </Button>
                    <Typography className="exit-link" onClick={exit}>
                      {t('closeApp')}
                    </Typography>
                  </ShowIf>
                </CardContent>
              </Card>
            </ErrorContainer>
          </Layout>
        </Layout>
      </ShowIf>
      <ShowIf condition={shouldOpenFallbackPage()}>
        <DialogPage
          open={dialogOpen}
          link={guestLandingUrl as string}
          close={() => {
            exit();
            setDialogOpen(false);
          }}
        />
      </ShowIf>
    </>
  );
};

export default ErrorPage;
