import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import * as colors from 'components/styles/colors';
import Button from '@material-ui/core/Button';

const ChangePassContainer = styled(Grid)`
  padding: 20px;
  background-color: ${colors.WHITE};
  height: 100%;
  overflow: scroll;
`;

const ImgSwipeLogo = styled(Grid)`
  margin-top: 40px;
`;

const FormTitle = styled(Grid)`
  width: 100%;
  height: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 20px;
  position: static;
  text-align: justify;
  font-family: Nunito Sans, sans-serif;
`;

const SuccessTitle = styled(Grid)`
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  flex-direction: column-reverse;
`;

const SuccessHeader = styled(Grid)`
  font-family: Nunito Sans, sans-serif;
  font-weight: 200;
`;

const GeneralError = styled(Grid)`
  font-family: Nunito Sans, sans-serif;
  font-weight: 200;
  margin: auto;
  text-align: center;
`;

const SuccessFooter = styled(Grid)`
  font-family: Nunito Sans, sans-serif;
  margin: auto;
  font-weight: 200;
`;

const LoginButton = styled(Button)`
  background: #14c470;
  margin: auto;
  color: #ffff !important;
`;

const ChangePassButtonContainer = styled(Grid)`
  border-radius: 20px;
  display: flex;
  background-color: #14c47d;
  flex-direction: column;
  margin: 38px auto;
  max-width: 280px;
  min-width: 180px;
`;

const ChangePassButton = styled(Button)`
  background: #14c470;
  margin: auto;
  color: #ffff !important;
`;

const PasswordReq = styled(Grid)`
  font-size: 12px;
  color: #828282;
  font-weight: 400;
  font-style: italic;
  font-family: Nunito Sans, sans-serif;
  margin-top: 22px;
  text-align: justify;
`;

const InputPassword = styled.input`
  height: 44px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-size: 14px;
  align-items: center;
  padding: 10px;
  font-family: Nunito Sans, sans-serif;
`;

const ChangePassForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  padding-bottom: 2px;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  width: 176px;
  height: 24px;
  padding-top: 4px;
  color: #333333;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const FormContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RequiredFields = styled.span`
  color: red;
`;

const ErrorContainer = styled.div`
  color: red;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 1px;
  font-family: Nunito Sans, sans-serif;
  text-align: justify;
  margin-top: 20px;
`;

const IconPass = styled.i`
  margin-top: -30px;
  margin-inline-start: auto;
  padding-right: 10px;
  width: 19px;
  height: 22px;
`;

export const S = {
  ErrorContainer,
  RequiredFields,
  FormContainer,
  FormLabel,
  ChangePassForm,
  InputPassword,
  PasswordReq,
  ChangePassButton,
  ChangePassButtonContainer,
  LoginButton,
  SuccessFooter,
  SuccessHeader,
  SuccessTitle,
  FormTitle,
  ImgSwipeLogo,
  ChangePassContainer,
  IconPass,
  GeneralError,
};
