import { CurrencyCode } from '@causalfoundry/js-sdk';
import { store } from 'store';
import { ICausalFoundryConfig } from 'store/reducers/MarketConfig/config.type';
import { Locale } from 'utils/Localization';

export const getMarketId = () => {
  const state = store.getState();
  return state.config.marketId || '';
};

export const getOpenAmountInputFlag = () => {
  const state = store.getState();
  return state.config?.market?.featureConfigs.payment?.allowInputOpenAmount;
};

export const getCurrency = (): CurrencyCode => {
  const {
    config: {
      marketId,
      market: { currency },
    },
  } = store.getState();
  if (currency) {
    return currency.code as CurrencyCode;
  }
  // fallback to marketid if no currency is found
  switch (marketId) {
    case 'id':
      return CurrencyCode.IDR;
    case 'th':
      return CurrencyCode.THB;
    default:
      return CurrencyCode.IDR;
  }
};

export const getMarketProductRequirements = (): string[] => {
  const state = store.getState();
  return state.config.market?.requirements?.product || [];
};

export const getCustomerSuccessSupport = (): {
  target: '_self' | '_blank';
  link: string;
} => {
  const state = store.getState();
  return state.config.market.customerSuccessSupport;
};

export const getMinimumInvoiceAmount = (): number => {
  const state = store.getState();

  return state.config.market.orders?.minimum_invoice_amount || 250000;
};

export const getWhatsappSupportLink = (message?: string): string | null => {
  const state = store.getState();
  const { contactWhatsapp } = state.config.market;
  if (!contactWhatsapp) return null;

  const whatsappNumber = contactWhatsapp.replace(/\D/g, '');
  let whatsappLink = `https://wa.me/${whatsappNumber}`;
  if (message) whatsappLink += `?text=${encodeURIComponent(message)}`;
  return whatsappLink;
};

export const getFreeDeliveryURL = (language: Locale): string | null => {
  const state = store.getState();
  const { freeDeliveryURL } = state.config.market;
  if (freeDeliveryURL) {
    return freeDeliveryURL[language] ?? null;
  }
  return null;
};

export const getCausalFoundryConfig = (): ICausalFoundryConfig => {
  const state = store.getState();
  const defaultConfig: ICausalFoundryConfig = { enabled: false, selfManagedNudgesEnabled: false };
  return { ...defaultConfig, ...state.config.market.thirdPartyConfig?.causalFoundry };
};

export const getCausalFoundryIsEnabled = (): boolean => {
  const cfConfig = getCausalFoundryConfig();
  const isMarketConfigEnabled = cfConfig.enabled;

  const causalFoundryToken = process.env.REACT_APP_CAUSAL_FOUNDRY_TOKEN as string;

  const isEnvReady = !!causalFoundryToken;
  return isMarketConfigEnabled && isEnvReady;
};

export const getCallCenterNumber = (): string | null => {
  const state = store.getState();
  const { callCenterNumber } = state.config.market;

  return callCenterNumber || null;
};
