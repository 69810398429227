import { ConjuctionType } from 'store/reducers/FilterToolbar/interface';
import { getFilterMenuHorizontal } from './FilterToolbar.config';

export enum SortType {
  DEFAULT_SORT = 'default_sort',
  DEFAULT = 'default',
  STATE_SORT = 'state_sort',
  RANDOM = 'random',
  PRODUCT_ID = 'product.id',

  MOST_PURCHASED = 'most_purchased',
  LOWEST_PRICE = 'lowest_price',
  HIGHEST_DISCOUNT = 'highest_discount',
  LATEST = 'latest',
  MOST_SEEN = 'most_seen',
}

export enum FilterType {
  CATEGORY = 'category_filter',
  ALPHABETICAL = 'alphabetical_filter',
  ALL = 'all_filter',
  LOYALTY_EXTRA_POINTS = 'loyalty_extra_points',
  DISCOUNT = 'discount',
  LAST_PURCHASED = 'last_purchased_filter',
}

export const MANAGED_PARAMS = [...(Object.values(FilterType) as string[]), ...(Object.values(SortType) as string[])];

// return horizontal conjunction filter type for example [FilterType.ALPHABETICAL, FilterType.LAST_PURCHASED]
export const CONJUNCTION_HORIZONTAL_FILTER_PAIRS = getFilterMenuHorizontal()
  .filter((filterOption) => filterOption?.conjunction === ConjuctionType.HORIZONTAL)
  .map((filterOption) => filterOption?.type) as string[];

export const HORIZONTAL_FILTER_GROUP = [FilterType.ALPHABETICAL, FilterType.LAST_PURCHASED, FilterType.ALL] as string[];
