import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'components/styles/breakpoints';

import MButton, { ButtonProps } from '@material-ui/core/Button';
import Grid, { GridProps } from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { BaseCard } from '../BaseCard';

interface Props {
  fullControl: boolean;
}

const BaseText = styled(Typography)`
  display: block;
  padding: 2px 0;
  white-space: nowrap;
`;

const Wrapper = styled(BaseCard)<any>`
  && {
    display: inline-block;
    margin-right: 15px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    min-width: ${({ fullControl }: Props) => (fullControl ? '305px' : undefined)};
  }
`;

const Image = styled.img`
  height: 25px;
  margin: 0 auto;
`;

const Title = styled(BaseText)`
  && {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

const Number = styled(BaseText)<any>`
  && {
    color: #000;
    font-weight: bold;
    font-size: 26px;
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 20px;
    }
  }
`;

const ControlGrid = styled(Grid)`
  && {
    margin-top: 10px;
  }
`;

const Button = styled(MButton)`
  span:first-of-type {
    font-size: 0.85rem;
  }
`;

export const S = {
  Wrapper,
  Image,
  Title,
  Number,
  ControlGrid: ControlGrid as React.ComponentType<React.PropsWithChildren<GridProps>>,
  Button: Button as React.ComponentType<React.PropsWithChildren<ButtonProps>>,
};
