export const GET_PURCHASE_ORDER_REQUIREMENT = 'GET_PURCHASE_ORDER_REQUIREMENT';
export type GET_PURCHASE_ORDER_REQUIREMENT = typeof GET_PURCHASE_ORDER_REQUIREMENT;

export const PATCH_PURCHASE_ORDER_REQUIREMENT = 'PATCH_PURCHASE_ORDER_REQUIREMENT';
export type PATCH_PURCHASE_ORDER_REQUIREMENT = typeof PATCH_PURCHASE_ORDER_REQUIREMENT;

export const FAIL_PATCH_PURCHASE_ORDER_REQUIREMENT = 'FAIL_PATCH_PURCHASE_ORDER_REQUIREMENT';
export type FAIL_PATCH_PURCHASE_ORDER_REQUIREMENT = typeof FAIL_PATCH_PURCHASE_ORDER_REQUIREMENT;

export const SET_PURCHASE_ORDER_REQUIREMENT_LOADING = 'SET_PURCHASE_ORDER_REQUIREMENT_LOADING';
export type SET_PURCHASE_ORDER_REQUIREMENT_LOADING = typeof SET_PURCHASE_ORDER_REQUIREMENT_LOADING;

export const SET_PURCHASE_ORDER_REQUIREMENT_DISABLED = 'SET_PURCHASE_ORDER_REQUIREMENT_DISABLED';
export type SET_PURCHASE_ORDER_REQUIREMENT_DISABLED = typeof SET_PURCHASE_ORDER_REQUIREMENT_DISABLED;
