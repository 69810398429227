import React from 'react';
import ReactJoyride, { CommonProps as RJProps } from 'react-joyride';
import { TranslateProps } from 'utils/Localization/types';

import { useTours } from 'hooks';
import { toursTemplate } from 'utils/tours';
import * as colors from 'components/styles/colors';
import { TOURS } from '../Tours';

export interface OwnProps extends RJProps {
  module: keyof typeof TOURS;
  welcomeTitle?: string | undefined;
  welcomeMessage?: string | undefined;
  continuous?: boolean;

  onCallback?: (data: any) => void;
}

type Props = OwnProps & TranslateProps;

export const T: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    t: translate,
    module,
    welcomeTitle,
    welcomeMessage,
    continuous,
    onCallback,

    ...joyrideProps
  } = props;

  const { tourState, handleJoyride } = useTours({
    t: translate,
    tourName: module,
    template: toursTemplate,
    initialTour:
      welcomeTitle && welcomeMessage
        ? {
            title: translate(welcomeTitle),
            description: translate(welcomeMessage),
          }
        : undefined,
  });

  const propagate = (data: any): void => {
    handleJoyride(data);

    if (!onCallback) return;
    onCallback(data);
  };

  return (
    <ReactJoyride
      {...tourState}
      {...joyrideProps}
      locale={{
        last: translate(`tour.gotIt`),
      }}
      callback={propagate}
      continuous={continuous}
      styles={{
        ...joyrideProps.styles,
        options: {
          ...joyrideProps.styles?.options,
          primaryColor: colors.PRIMARY_COLOR,
        },
      }}
    />
  );
};

T.defaultProps = {
  continuous: true,
};
