import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const FavoriteButtonContainer = styled.div<{
  location: { top?: string; bottom?: string; left?: string; right?: string };
}>`
  width: 32px;
  height: 32px;
  position: absolute;
  top: ${({ location }) => location.top};
  bottom: ${({ location }) => location.bottom};
  left: ${({ location }) => location.left};
  right: ${({ location }) => location.right};
  background: white;
  border-radius: 16px;
  z-index: 5;
`;

export const StyledIconButton = styled(IconButton)`
  && {
    width: 32px;
    height: 32px;
    padding: 0;
  }
`;
