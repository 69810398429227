import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';

import { S } from './TemporaryDrawer.style';

interface Props extends DrawerProps {
  title: string;
  onClose?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

const styles = {
  paper: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: '30px 25px',
  },
};

const TemporaryDrawerBase: React.FC<React.PropsWithChildren<Props>> = ({ anchor, children, classes, open, onClose, title }) => (
  <Drawer anchor={anchor} classes={classes} onClose={onClose} open={open}>
    <S.Title variant="h5">{title}</S.Title>
    <S.CloseButton onClick={onClose} />
    {children}
  </Drawer>
);

export const TemporaryDrawer = withStyles(styles)(TemporaryDrawerBase);
