/* eslint-disable dot-notation */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import AuthReducer, { State as AuthState } from './reducers/Auth';
import CartReducer, { State as CartState } from './reducers/Cart';
import OrdersReducer, { State as OrdersState } from './reducers/Orders';
import CreditReducer, { State as CreditState } from './reducers/Credit';
import PaymentReducer, { State as PaymentState } from './reducers/Payment';
import PricesReducer, { State as PricesState } from './reducers/Prices';
import SearchReducer, { State as SearchState } from './reducers/Search';
import GlobalReducer, { State as GlobalState } from './reducers/Global';
import PromotedReducer, { State as PromotedState } from './reducers/Promoted';
import ProductDetailReducer, { State as ProductDetailState } from './reducers/ProductDetail';
import CounterReducer, { State as CounterState } from './reducers/Counter';
import BannerReducer, { State as BannerState } from './reducers/Banner';
import TourReducer, { State as TourState } from './reducers/Tour';
import AccountReducer, { State as AccountState } from './reducers/Account';
import MarketConfigReducer, { ConfigState } from './reducers/MarketConfig';
import { purchaseOrderReducer, PurchaseOrderStore } from './reducers/purchase-order';
import { pharmacyReducer, PharmacyStore } from './reducers/pharmacy';
import FavoriteReducer, { State as FavoriteState } from './reducers/FavoriteProduct';
import ProductReminderReducer, { State as ProductReminderState } from './reducers/ProductReminder';
import { loyaltyProgramReducer, LoyaltyProgramStore } from './reducers/loyalty-program';
import ProductRequestReducer, { State as ProductRequestState } from './reducers/ProductRequest';
import LoyaltyPointDescriptionReducer, {
  State as LoyaltyPointDescriptionState,
} from './reducers/LoyaltyPointDescription';
import { paymentDirectReducer, PaymentDirectStore } from './reducers/PaymentDirect';
import { DepositRequestReducer, State as DepositRequestStore } from './reducers/DepositRequest';
import { AnalyticsReducer, AnalyticsStore } from './reducers/Analytics';

import { SectionCardReducer, SectionCardState } from './reducers';
import { RequirementReducer, State as RequirementState } from './reducers/Requirement';
import { VoucherReducer, State as VoucherState } from './reducers/Voucher';
import CampaignReducer, { State as CampaignState } from './reducers/Campaign';
import UtilsReducer, { State as UtilsState } from './reducers/Utils';
import { CreditRequestReducer, State as CreditRequestReducerState } from './reducers/CreditRequest';
import SearchSuggestionsReducer, { State as SearchSuggestionsState } from './reducers/SearchSuggestions';

import { sentryReduxEnhancer } from '../utils/Reporting';
import ProductListingReducer, { State as ProductListingState } from './reducers/ProductListing';
import FilterToolbarStateReducer, { State as FilterToolbarState } from './reducers/FilterToolbar';
import PrekursorStatusReducer, { State as PrekursorStatusState } from './reducers/PrekursorStatus';
import CampaignSearchMarketingReducer, { CampaignSearchMarketingState } from './reducers/CampaignSearchMarketing';
import MarketingFeaturesReducer, { State as MarketingFeaturesState } from './reducers/MarketingFeatures';
import ProductImpressionReducer, { State as ProductImpressionState } from './reducers/ProductImpression';
import ProductCarouselReducer, { State as ProductCarouselState } from './reducers/ProductCarousel';

export interface State {
  account: AccountState;
  analytics: AnalyticsStore;
  auth: AuthState;
  banner: BannerState;
  campaign: CampaignState;
  campaignSearchMarketing: CampaignSearchMarketingState;
  cart: CartState;
  config: ConfigState;
  counter: CounterState;
  credit: CreditState;
  creditRequest: CreditRequestReducerState;
  depositRequest: DepositRequestStore;
  favorites: FavoriteState;
  filterToolbar: FilterToolbarState;
  global: GlobalState;
  loyalty: LoyaltyProgramStore;
  loyaltyPointDescription: LoyaltyPointDescriptionState;
  marketingFeatures: MarketingFeaturesState;
  orders: OrdersState;
  payment: PaymentState;
  paymentDirect: PaymentDirectStore;
  pharmacy: PharmacyStore;
  precursorStatus: PrekursorStatusState;
  prices: PricesState;
  productCarousel: ProductCarouselState;
  productDetail: ProductDetailState;
  productImpression: ProductImpressionState;
  productListing: ProductListingState;
  productReminder: ProductReminderState;
  productRequest: ProductRequestState;
  promoted: PromotedState;
  purchaseOrder: PurchaseOrderStore;
  requirement: RequirementState;
  search: SearchState;
  searchSuggestions: SearchSuggestionsState;
  sectionCard: SectionCardState;
  tours: TourState;
  utils: UtilsState;
  voucher: VoucherState;
}

export type IAllState = State;

// Add redux devtools during development mode
const composeEnhancers =
  process.env.NODE_ENV === 'development' ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose : compose;

export const store = createStore(
  combineReducers<State>({
    account: AccountReducer,
    analytics: AnalyticsReducer,
    auth: AuthReducer,
    banner: BannerReducer,
    campaign: CampaignReducer,
    campaignSearchMarketing: CampaignSearchMarketingReducer,
    cart: CartReducer,
    config: MarketConfigReducer,
    counter: CounterReducer,
    credit: CreditReducer,
    creditRequest: CreditRequestReducer,
    depositRequest: DepositRequestReducer,
    favorites: FavoriteReducer,
    filterToolbar: FilterToolbarStateReducer,
    global: GlobalReducer,
    loyalty: loyaltyProgramReducer,
    loyaltyPointDescription: LoyaltyPointDescriptionReducer,
    marketingFeatures: MarketingFeaturesReducer,
    orders: OrdersReducer,
    payment: PaymentReducer,
    paymentDirect: paymentDirectReducer,
    pharmacy: pharmacyReducer,
    precursorStatus: PrekursorStatusReducer,
    prices: PricesReducer,
    productCarousel: ProductCarouselReducer,
    productDetail: ProductDetailReducer,
    productImpression: ProductImpressionReducer,
    productListing: ProductListingReducer,
    productReminder: ProductReminderReducer,
    productRequest: ProductRequestReducer,
    promoted: PromotedReducer,
    purchaseOrder: purchaseOrderReducer,
    requirement: RequirementReducer,
    search: SearchReducer,
    searchSuggestions: SearchSuggestionsReducer,
    sectionCard: SectionCardReducer,
    tours: TourReducer,
    utils: UtilsReducer,
    voucher: VoucherReducer,
  }),
  composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer),
);
