import { Location } from 'history';

import { ISearchItem } from 'components/common/SearchMenuSuggestions/components/interface';
import { LoyaltyProductEarnPoint } from 'store/reducers';
import { PtCoreUser } from 'store/reducers/Auth';
import { DistributorCartProduct } from 'store/reducers/Cart';
import { Product } from 'store/reducers/Product';
import { PushActionDeliveryType } from '../interfaces/analytics.interface';
import { BasePageTracker } from './Helper';

export * from '../interfaces';

export type UndefinedAnalyticsProps = any;
export interface TrackPageViewAnalyticsProps {
  name?: PtCoreUser['user_name'];
  email?: PtCoreUser['email'];
  membership_status?: PtCoreUser['membership_status'];
  pharmacyId?: PtCoreUser['organization_id'];
  referrer?: string;
  campaign_name?: string | null;
  campaign_id?: any;
  [k: string]: any; // LoyaltyHistoryStatus etc
}

export interface ProductAnalyticsProps extends ProductListPageAnalyticsProps, Product, ProductListPageSearchProps {}

export interface SearchSectionProps {
  result_count?: number;
}

export interface ProductListPageSearchProps {
  query?: string;
  search_section?: string;
}

export interface ProductListPageAnalyticsProps {
  marketing_id?: number | string;
  marketing_name?: string;
  utm_source?: string;
  utm_medium?: string;
  product_added_from?: string;
  query?: string;
  quantity?: number;
  from_page?: BasePageTracker;
  page?: BasePageTracker;
  referrer_sku_code?: string;
  from_instock_reminder?: boolean;
}

export interface SimilarProductListPageAnalyticsProps {
  result_count: number;
  from_page: string;
  referrer_sku_code: string;
}

export interface AddProductAnalayticsProps extends ProductListPageAnalyticsProps {
  utmSource?: string;
  utmMedium?: string;
}

export interface FavoritedProductAnalayticsProps extends ProductListPageAnalyticsProps {
  product_favorited_from?: string;
  product_unfavorited_from?: string;
  recommendation_widget?: string;
}

export interface ProductSimpleAnalyticsProps extends ProductListPageAnalyticsProps {
  product_id: number;
  sku_code?: string;
  query?: string;
  search_section?: string;
  product_availability?: string;
}

export interface ProductSuggestionDataAnalyticsProps {
  product_name: string;
}

export interface ProductDetailDataAnalyticsProps extends ProductSimpleAnalyticsProps {
  sku_code: string;
  marketing_id?: number | string;
  marketing_name?: string;
  discount_rate: number;
  favorited?: boolean;
  is_unpurchasable: boolean;
  low_stock_threshold: number;
  loyalty_point: Partial<LoyaltyProductEarnPoint>;
  manufacturer?: string;
  molecule?: string;
  name: string;
  net_price: number;
  packaging: string;
  quantity_threshold: number;
  remaining_quantity: number;
  reminder_removal_date?: string;
  selling_price: number;
}

export interface CartProductDataAnalyticsProps {
  [productName: string]: {
    [orderId: string]: DistributorCartProduct;
  };
}

export interface SearchProductDataAnalyticsProps {
  id: string;
  name: string;
  selling_price: number;
  discount_rate: number;
  sku_code: string;
  net_price: number;
  packaging: string;
  remaining_quantity: number;
  low_stock_threshold: number;
  quantity_threshold: number;
  distributor_id: number;
}

export interface SearchDataAnalyticsProps {
  query?: string;
  type: string;
  search_section?: string;
  info: {
    id?: number;
    type: string;
    name: string;
    results_count?: number;
    filter: false | any[];
    fuzzy_search?: boolean;
    respond_at?: Date | null;
    respond_time?: number;
    pharmacy_id?: PtCoreUser['organization_id'];
    market_id?: string;
    products?: SearchProductDataAnalyticsProps[] | ISearchItem[];
    from_page: string;
    marketing_id?: number;
    marketing_name?: string;
  };
}

export interface StoredPharmacyIdPayload {
  pharmacyId: string | undefined;
}

export interface CategoryPayload {
  category_id?: number;
  name?: string;
}

export interface TierDiscountPayload {
  min?: number;
  price?: number;
}

export interface IProductPayloadTrimmed {
  available: boolean;
  categories?: CategoryPayload[];
  discount_rate: number;
  distributor: {
    id: number;
    name: string;
  };
  distributor_product_id: number;
  favorited?: boolean;
  flag?: string | null;
  id: number;
  is_unpurchasable: boolean;
  low_stock_threshold: number;
  loyalty_point: Partial<LoyaltyProductEarnPoint>;
  manufacturer?: string;
  availability_requested?: boolean;
  molecule?: string;
  name: string;
  net_price: number;
  packaging: string;
  quantity_threshold: number;
  remaining_quantity: number;
  reminder_removal_date?: string;
  selling_price: number;
  sku_code: string;
  tier_discount?: TierDiscountPayload[];
  index_number?: number;
}
export interface ImpressionDataAnalyticProps extends ProductListPageAnalyticsProps {
  impression_time?: number;
  impression_from?: BasePageTracker;
  impression_type?: ImpressionType;
  product_order?: number;
  product_ids: number[] | number;
  sku_codes?: string[] | string;
  products: IProductPayloadTrimmed[] | IProductPayloadTrimmed;
  search_section?: SearchSection;
}

export interface InstockReminderDataAnalyticProps {
  action_delivery_type: PushActionDeliveryType;
  sku_codes: string[];
  product_ids: number[];
}

export interface IMappingProductDataAnalytic
  extends Omit<IProductPayloadTrimmed, 'id' | 'name'>,
    ProductListPageAnalyticsProps,
    ProductListPageSearchProps {
  product_id: number;
  product_name: string;
  search_reciprocal_rank?: number;
}

export interface LocationAnalyticState {
  initial_page?: string;
  base_page?: string;
}

export interface LocationAnalyticProps extends Location {
  state: LocationAnalyticState;
}
export interface HomesquareWidgetProps {
  homesquare_id: number;
  homesquare_name: string;
  listing_link: string;
}

export interface FilterSortDataProps {
  pharmacy_id: number;
  from_page: BasePageTracker;
  marketing_name?: string;
  selected_filter?: string[];
  selected_sort?: string;
}

export interface CommunityDataProps {
  pharmacyId: number;
  url: string;
}

export enum RecommendationWidget {
  SIMILAR_PRODUCT = 'similar_product',
}

export enum SearchDataAnalyticsInfoType {
  GENERAL_SEARCH = 'general-search',
  PRODUCT_LISTING = 'product-listing',
  SEARCH_SUGGESTIONS = 'search-suggestions',
}

export enum SearchSection {
  CAMPAIGN_LISTING_SEARCH = 'campaign_listing_search',
}

export enum ImpressionType {
  LISTING = 'listing',
  CAROUSEL = 'carousel',
}

export enum CommunityEndpoint {
  NEWS = 'newsfeed',
  EDUCATION = 'cpd_list_public',
}
