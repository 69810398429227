import React from 'react';

import { S } from './LazyImage.styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  width?: number | undefined;
  height?: number | undefined;
  src?: string | undefined;
  alt: string;
  className?: string;
  children?: React.ReactNode;
}

export const LazyImage: React.FC<React.PropsWithChildren<Props>> = ({
  width,
  height,
  src,
  alt,
  className,
  children,
  ...props
}) => {
  const refPlaceholder = React.useRef<any>();
  const refImg = React.useRef<any>();

  const removePlaceholder = () => {
    if (refPlaceholder?.current?.remove) {
      refPlaceholder?.current?.remove();
    }
  };
  const removeImg = () => {
    if (refImg?.current?.remove) {
      refImg?.current?.remove();
    }
  };

  return (
    <S.ImageWrapper width={width ? `${width}px` : '100%'} height={height ? `${height}px` : '100%'} {...props}>
      <S.Placeholder ref={refPlaceholder} className={!children ? 'animation' : ''}>
        {children}
      </S.Placeholder>
      {src && (
        <S.StyledImage
          ref={refImg}
          onLoad={removePlaceholder}
          onError={removeImg}
          src={src}
          alt={alt}
          className={className ?? ''}
        />
      )}
    </S.ImageWrapper>
  );
};
