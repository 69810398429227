export const SET_PRODUCT_REMINDERS = '@@product-reminder/SET_REMINDERS';
export type SET_PRODUCT_REMINDERS = typeof SET_PRODUCT_REMINDERS;

export const CLEAR_PRODUCT_REMINDERS = '@@product-reminder/CLEAR_REMINDERS';
export type CLEAR_PRODUCT_REMINDERS = typeof CLEAR_PRODUCT_REMINDERS;

export const SET_DELAYED_AVAILABILITY_REQUEST = 'SET_DELAYED_AVAILABILITY_REQUEST';
export type SET_DELAYED_AVAILABILITY_REQUEST = typeof SET_DELAYED_AVAILABILITY_REQUEST;

export const SET_DELAYED_DELETE_AVAILABILITY_REQUEST = 'SET_DELAYED_DELETE_AVAILABILITY_REQUEST';
export type SET_DELAYED_DELETE_AVAILABILITY_REQUEST = typeof SET_DELAYED_DELETE_AVAILABILITY_REQUEST;

export const CLEAR_DELAYED_AVAILABILITY_REQUEST = 'CLEAR_DELAYED_AVAILABILITY_REQUEST';
export type CLEAR_DELAYED_AVAILABILITY_REQUEST = typeof CLEAR_DELAYED_AVAILABILITY_REQUEST;
