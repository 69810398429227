import * as Redux from 'redux';
import uniqBy from 'lodash/uniqBy';
import { IVoucher } from 'services/swipe-rx-pt/resources/voucher/interface/voucher.interface';
import { VoucherRuleError } from 'components/pages/PaymentsPage/components/Voucher/type';
import { Actions } from './actions';
import * as constants from './constants';

export interface VoucherMeta {
  page_size: number;
  page: number;
  page_count: number;
  total_count: number;
}

export interface State {
  loading: boolean;
  vouchers: IVoucher[];
  meta: VoucherMeta;
  used?: IVoucher;
  isApplyError: boolean;
  isEligibilityError: boolean;
  selected?: IVoucher;
  error: string | VoucherRuleError | null;
}

const InitialState: State = {
  loading: false,
  vouchers: [],
  meta: { page_size: 0, page: 0, page_count: 0, total_count: 0 },
  isApplyError: false,
  isEligibilityError: false,
  error: null,
};

export const VoucherReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.FETCH_AVAILABLE_VOUCHER:
      if (action.payload.refreshVoucher) {
        return {
          ...state,
          loading: false,
          error: null,
          vouchers: action.payload.data,
          meta: action.payload.meta,
        };
      }

      return {
        ...state,
        loading: false,
        error: null,
        vouchers: uniqBy([...state.vouchers, ...action.payload.data], 'voucher_usage_id'),
        meta: action.payload.meta,
      };

    case constants.FETCH_AVAILABLE_VOUCHER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case constants.SELECTED_VOUCHER_ELIGIBILE:
      return {
        ...state,
        used: action.payload,
        selected: action.payload,
        isEligibilityError: false,
      };

    case constants.SELECTED_VOUCHER_ELIGIBILITY_FAILED:
      return {
        ...state,
        loading: false,
        isEligibilityError: true,
      };

    case constants.CLEAR_SELECTED_VOUCHER:
      return {
        ...state,
        selected: undefined,
        isEligibilityError: false,
      };

    case constants.APPLY_VOUCHER:
      return {
        ...state,
        used: undefined,
        isApplyError: false,
      };

    case constants.CLEAR_APPLIED_VOUCHER:
      return {
        ...state,
        used: undefined,
        isApplyError: false,
        error: null,
      };

    case constants.APPLY_VOUCHER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        isApplyError: true,
      };

    case constants.FETCH_AVAILABLE_VOUCHER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        vouchers: [],
      };

    default:
      return state;
  }
};

export default VoucherReducer;
