import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';

const Wrapper = styled(Fab)<any>`
  && {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    right: 40px;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const S = { Wrapper };
