import React from 'react';
import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { TranslateProps } from 'utils/Localization';

import PharmacistThumbsUp from '../../../images/male_thumbs_up_pharmacist.svg';
import { ReloadDialogContainer, ReloadContent, ReloadImageContainer } from './ReloadDialog.styles';
import * as colors from '../../styles/colors';

interface Props {
  open: boolean;
  close: () => void;
}

const ReloadDialog: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ open, close, t }) => (
  <ReloadDialogContainer fullScreen open={open}>
    <ReloadContent>
      <Grid container direction="column">
        <Grid item>
          <ReloadImageContainer>
            <img src={PharmacistThumbsUp} alt="new update available" width={250} height={250} />
          </ReloadImageContainer>
        </Grid>
        <Grid item>
          <Typography align="center" style={{ margin: 30 }}>
            {t('reloadMessage')}
          </Typography>
        </Grid>
        <Grid item container>
          <Button
            className="close-button"
            style={{
              backgroundColor: colors.REBRAND_GREEN,
              color: colors.WHITE,
            }}
            data-testid="reload-dialog-btn"
            fullWidth
            onClick={close}
          >
            {t('close')}
          </Button>
        </Grid>
      </Grid>
    </ReloadContent>
  </ReloadDialogContainer>
);

export default translate('maintenancePage')(ReloadDialog);
