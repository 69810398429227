import moment from 'moment-timezone';

interface DateRange {
  from?: null | Date;
  to?: null | Date;
}

const convertToStandardDate = (date): string => moment(date).format('DD/MM/YYYY');

export const getOrderDateRangeFilter = (dateRange): DateRange => {
  const { from, to } = dateRange;
  let dateFilter = {};

  if (from && to) {
    dateFilter = {
      order_date_start: convertToStandardDate(from),
      order_date_end: convertToStandardDate(to),
    };
  }

  return dateFilter;
};