import { SwipeRxPt } from '../../SwipeRxPt.class';
import { InvoiceList } from './interfaces/invoice-list.interface';
import { InvoiceSummary } from './types';
import { InvoiceListParam } from './types/invoice-list-param.type';

export class SwipeRxPtInvoice {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async list(params: InvoiceListParam): Promise<InvoiceList> {
    const response = await this.base.getV2('/invoice', params);
    return response;
  }

  async summary(id: number): Promise<InvoiceSummary> {
    const { data } = await this.base.getV2(`/invoice/summary/${id}`);
    return data;
  }

  async download(): Promise<{ recipient_email: string }> {
    const { data } = await this.base.getV2('/invoice/summary/download');
    return data;
  }
}
