import React from 'react';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { Star, ExpandMore, ChevronRightRounded } from '@material-ui/icons';
import { WHITE } from 'components/styles/colors';
import { breakpoints } from 'components/styles/breakpoints';

const RoundedExpansionPanel = withStyles({
  rounded: {
    '&:last-child': {
      borderBottomLeftRadius: `13px`,
      borderBottomRightRadius: '13px',
    },
  },
})(ExpansionPanel);

const TierDiscountPanel = styled(RoundedExpansionPanel)<any>`
  && {
    width: 100%;
    min-height: 0;
    padding-top: 10px;
    margin-top: -12px;
    background-color: ${(props) => props.customcolor.lightest};
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    max-width: 100%;
    box-sizing: border-box;
  }
`;

const TierDiscountPanelSummary = styled(ExpansionPanelSummary)`
  && {
    padding: 0px 10px;
  }

  & .MuiIconButton-root-138 {
    padding: 0;
  }
`;

const SingleTierDiscount = styled(Grid)<any>`
  && {
    width: 100%;
    padding-top: 10px;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    background-color: ${(props) => props.customcolor.lightest};
    border: 1px solid rgba(99, 106, 122, 0.12);
  }
`;

const MinimumPurchase = styled(Typography)<any>`
  && {
    margin-right: auto;
    font-size: 14px;
    line-height: 14px;
    font-weight: inherit;
    color: ${(props) => (props.selected ? WHITE : props.customcolor.normal)};
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 10px;
    }
  }
`;

const PromoText = styled(({ ...props }) => <Typography {...props} />)`
  && {
    padding-top: 3px;
    padding-left: 10px;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    color: ${(props) => (props.selected ? WHITE : props.customcolor.dark)};
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 10px;
    }
  }
`;

const TierDiscountPanelDetails = styled(ExpansionPanelDetails)`
  && {
    padding: 0px 12px;
    padding-bottom: 10px;
  }
`;

const TierDiscountButton = styled(({ ...props }) => <Typography {...props} />)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 5px 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in;
    box-shadow: 0px 1px 1px rgba(186, 194, 192, 0.75);
    font-weight: normal;
    background-color: ${(props) => (props.selected ? props.customcolor.light : WHITE)};
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      padding: 10px;
    }
  }
`;

const Chevron = styled(ExpandMore)<any>`
  && {
    fill: rgba(0, 0, 0, 0.3);
  }
`;

const ChevronRight = styled(({ ...props }) => <ChevronRightRounded {...props} />)`
  && {
    fill: ${(props) => props.customcolor};
  }
`;

const StarIcon = styled(Star)<any>`
  && {
    margin-left: 7px;
    width: 14px;
    height: 14px;
    fill: ${(props) => props.customcolor};
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      width: 12px;
      height: 12px;
    }
  }
`;

const PromoDetails = styled(Typography)`
  && {
    font-size: 14px;
    padding-top: 3px;
    padding-left: 10px;
    font-weight: bold;
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 12px;
    }
  }
`;

export const S = {
  TierDiscountPanel,
  TierDiscountPanelSummary,
  MinimumPurchase,
  PromoText,
  TierDiscountPanelDetails,
  TierDiscountButton,
  Chevron,
  ChevronRight,
  StarIcon,
  PromoDetails,
  SingleTierDiscount,
};
