import { GridProps } from '@material-ui/core';
import styled from 'styled-components';

const SectionTitle = styled.div`
  padding: 0.8rem 1.1rem;
  font-family: Roboto, Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.16px;
`;

const SectionSubtitle = styled.div`
  padding: 0 1.1rem 0.8rem 1.1rem;
  margin-top: -0.6rem;
  color: gray;
  font-family: Roboto, Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.16px;
`;

const Item = styled.div<any>`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  color: #000000;
  font-size: 14px;
  text-transform: none;
  box-shadow: none;
  padding: 0.8rem 1.1rem;
  padding-left: ${(props) => props.customPadding};
  margin-right: ${(props) => props.marginRight};
  white-space: normal;
  font-family: Roboto, Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
  z-index: 0;
  ${(props) => props.minHeight && `min-height: ${props.minHeight};`}
`;

const Section = styled.div`
  padding-top: 0;
  background-color: #fafafa;
  margin-bottom: 1rem;
`;

const ActionText = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: #14c47d;
  display: flex;
  position: absolute;
  top: 0;
  right: 16px;
  cursor: pointer;
`;

const ChipsWrapper = styled.div<
  {
    flexWrap?: string;
    lineHeight?: string | number;
    height?: string | number;
    overflow?: string;
    width?: number | string;
  } & GridProps
>`
  display: flex;
  padding: 16px 8px;
  flex-wrap: ${(props) => props.flexWrap};
  line-height: ${(props) => props.lineHeight};
  max-height: ${(props) => props.height};
  overflow: ${(props) => props.overflow};
  display: inline-block;
  width: ${(props) => props.width};
`;

const ListActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  color: #696969;
  padding: 0px 16px;
`;

const ListTitle = styled.div`
  margin-right: 16px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
`;

const ListButton = styled.button`
  border: none;
  padding: 4px;
  cursor: pointer;
  background-color: transparent;
  color: #696969;
`;

const ImageWrapper = styled.div<any>`
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  margin: auto;
  left: 16px;
  top: ${(props) => (props.top ? props.top : '0.8rem')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#e4e7ed')}; ;
`;

export const S = {
  SectionTitle,
  SectionSubtitle,
  Item,
  Section,
  ActionText,
  ListActionWrapper,
  ListTitle,
  ListButton,
  ImageWrapper,
  ChipsWrapper,
};
