export enum OrderStatus {
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  DELIVERED = 'delivered',
  PENDING = 'pending',
  PROCESSING = 'processing',
  DISPATCHED = 'dispatched',
  FULFILLED = 'fulfilled',
  ORDER_CHANGED = 'order_changed',
  RETURNED = 'returned',
}

export enum OrderPrecursorStatus {
  DOCUMENT_REQUIRED = 'required',
  DOCUMENT_WAITING_APPROVAL = 'submitted',
  DOCUMENT_RESUBMITTED = 'resubmitted',
  DOCUMENT_REJECTED = 'rejected',
  DOCUMENT_CANCELLED = 'cancelled',
  DOCUMENT_APPROVED = 'approved',
}
