import React, { useMemo } from 'react';
import { Product } from 'store/reducers/Product';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { getMarketId } from 'utils/MarketConfig';
import { useSearchProfiler } from 'hooks/use-search-profiler';
import { ProductCardImage } from './ProductCardImage.component';
import { TourIds } from '../../ProductCard.interface';

interface Props {
  product: Product;
  tourIds: TourIds;
  isStockOut: boolean;
  queryString?: string;
  detailPageDisplayType?: DetailPageDisplayType;
  handleOpenModal: () => void;
  trackProductDetail: () => void;
}

export const ProductCardImageContainer: React.FC<React.PropsWithChildren<Props>> = ({
  product,
  tourIds,
  isStockOut,
  children,
  queryString,
  detailPageDisplayType,
  handleOpenModal,
  trackProductDetail,
}) => {
  const { push } = useHistory();
  const marketId = getMarketId();
  const location = useLocation();

  const { clickThroughSRPTrack } = useSearchProfiler();
  const handleClick = (): void => {
    clickThroughSRPTrack(product.id);
    trackProductDetail();
    const pathname = `/${marketId}/product/${product.id}`;

    if (detailPageDisplayType === DetailPageDisplayType.MODAL) {
      handleOpenModal();
    } else {
      push({
        pathname,
        search: queryString,
        state: location?.state,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToProductPage = useMemo(() => handleClick, [queryString, location.state, location.search]);

  return (
    <ProductCardImage isStockOut={isStockOut} tourIds={tourIds} product={product} onClick={goToProductPage}>
      {children}
    </ProductCardImage>
  );
};

ProductCardImageContainer.defaultProps = {
  queryString: undefined,
  detailPageDisplayType: DetailPageDisplayType.ROUTE,
};
