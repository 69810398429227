import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { BasePageTracker } from 'utils/Analytics/Segment';

export const ERROR_CODE = { FORBIDDEN_MARKETING_SEGMENTS_MISMATCH: 'FORBIDDEN_MARKETING_SEGMENTS_MISMATCH' };

export const DEFAULT_TITLE_STYLE = {
  fontFamily: 'Nunito Sans',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '27px',
  letterSpacing: '0.16px',
  textAlign: 'center',
  width: '100%',
};

export const DEFAULT_LISTING_STYLE = {
  position: 'absolute',
  top: 8,
  left: 0,
  right: 0,
  bottom: 60,
  overflow: 'scroll',
};

export const MARKETING_LISTING_PAGES = [
  BasePageTracker.MARKETING_LISTING,
  BasePageTracker.LOYALTY,
  BasePageTracker.LAST_PURCHASED,
  BasePageTracker.RECENTLY_VIEWED,
];

export const MARKETING_LISTPAGES_WITHOUT_ID = [BasePageTracker.LAST_PURCHASED, BasePageTracker.RECENTLY_VIEWED];

export const GENERAL_SEARCH_PAGES = [
  BasePageTracker.HOME,
  BasePageTracker.GENERAL_SEARCH,
  BasePageTracker.PRODUCT_DETAIL,
  BasePageTracker.CATEGORY_LISTING,
];

export const PRODUCT_TAB_PAGES = [BasePageTracker.ALPHABETICAL];

export const MarketIdAssignedPageMarketType = {
  [BasePageTracker.LAST_PURCHASED]: MarketingType.REPURCHASE,
  [BasePageTracker.LAST_PURCHASED_SEARCH]: MarketingType.REPURCHASE,
  [BasePageTracker.RECENTLY_VIEWED]: MarketingType.RECENT,
  [BasePageTracker.RECENTLY_VIEWED_SEARCH]: MarketingType.RECENT,
};
