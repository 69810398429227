import React from 'react';

import { Box, IconButton } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import range from 'lodash/range';

import * as colors from 'components/styles/colors';

import { TFunction } from 'utils/Localization';
import ShowIf from 'components/views/ShowIf';
import { CurrentOrderRating } from 'store/reducers/Orders';
import { S } from './orders-rating.styles';

export interface StarsProps {
  t: TFunction;
  handleClick?: (value: number) => void;
  currentRate: number;
  disabled?: boolean;
  currentOrderRating?: CurrentOrderRating | null;
}

export const Stars: React.FC<React.PropsWithChildren<StarsProps>> = (props) => {
  const { handleClick, t, currentRate, disabled, currentOrderRating } = props;

  const onClick = (rate: number) => () => {
    if (handleClick) handleClick(rate);
  };

  const rate = currentOrderRating?.rate || currentRate;

  return (
    <>
      <Box flex textAlign="center" style={{ marginTop: '12px' }}>
        {range(5).map((index) => (
          <IconButton onClick={onClick(index + 1)} disabled={disabled}>
            <Star
              style={{
                fontSize: 40,
                color: rate >= index + 1 ? colors.STAR_COLOR : colors.BLUISH_GRAY_LIGHTER,
              }}
            />
          </IconButton>
        ))}
      </Box>

      <ShowIf condition={!rate || rate === 0}>
        <S.TapTheStarToRate>{t('tapTheStarToRate')}</S.TapTheStarToRate>
      </ShowIf>

      <ShowIf condition={rate === 1}>
        <S.Rate>
          {t('starRating.terrible')}{' '}
          <span role="img" aria-label="terrible">
            😥
          </span>
        </S.Rate>
      </ShowIf>

      <ShowIf condition={rate === 2}>
        <S.Rate>
          {t('starRating.poor')}{' '}
          <span role="img" aria-label="poor">
            🙁
          </span>
        </S.Rate>
      </ShowIf>

      <ShowIf condition={rate === 3}>
        <S.Rate>
          {t('starRating.ok')}{' '}
          <span role="img" aria-label="ok">
            👌🏻
          </span>
        </S.Rate>
      </ShowIf>

      <ShowIf condition={rate === 4}>
        <S.Rate>
          {t('starRating.good')}{' '}
          <span role="img" aria-label="good">
            😀
          </span>
        </S.Rate>
      </ShowIf>

      <ShowIf condition={rate === 5}>
        <S.Rate>
          {t('starRating.awesome')}{' '}
          <span role="img" aria-label="awesome">
            😍
          </span>
        </S.Rate>
      </ShowIf>
    </>
  );
};
