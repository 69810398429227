/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useEffect } from 'react';
import { translate } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactJoyride from 'react-joyride';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ConditionalRender, SearchInput } from 'components/common';
import * as colors from 'components/styles/colors';
import { useToggle, useTours } from 'hooks';
import { useParams } from 'react-router-dom';
import { PharmacyOrderStatus } from 'services';
import { State as StoreState } from 'store';
import { fetchPurchaseOrderDetails } from 'store/reducers';
import { toggleOrderModal } from 'store/reducers/Orders/actions';
import { TFunction } from 'utils/Localization';
import { toursTemplate } from 'utils/tours';
import { OrdersDistributorFilterModal, OrdersHistoryCard, OrdersModal, OrdersNoDataDisplay, OrdersWrapper } from '..';
import { OrdersCalendarModal } from '../orders-calendar-modal';
import { OrdersHistoryCardLoader } from '../orders-history-card/orders-history-card.loader';
import { OrdersHistoryFilterPrecursorTabs, OrdersHistoryFilters, OrdersHistoryFilterTabs } from './components';
import { S } from './orders-history.styles';
import { useOrdersHistory } from './use-orders-history.hook';

interface Props {
  t: TFunction;
  status: PharmacyOrderStatus;
}

const OrdersHistoryComponent: React.FC<React.PropsWithChildren<Props>> = ({ t, status }) => {
  const {
    incrementPage,
    loading,
    hasMore,
    orders: { data },
    dateRangeFilter: { dateRange, applyDateRangeFilter },
    orderStatusFilter: { orderStatusFilters, selectOrderStatusFilter, statusFilter },
    orderPrecursorStatusFilter: {
      hasPrecursorStatusFilter,
      precursorStatusFilter,
      orderPrecursorStatusFilters,
      selectOrderPrecursorStatusFilter,
    },
    distributorFilter,
    searchOrder,
    search,
  } = useOrdersHistory(status);

  const { orderId } = useParams<any>();

  const hasFilteredDateRange = dateRange && dateRange.from !== null && dateRange.to !== null;
  const hasFilterDistributor = distributorFilter.filters.distributors.length > 0;

  const dispatch = useDispatch();

  const { handleJoyride, tourState } = useTours({
    t,
    tourName: 'orders_tab',
    template: toursTemplate,
    initialTour: { title: t('tour.orderTab.title'), description: t('tour.orderTab.description') },
  });

  const {
    orders: {
      orderModal: { isOpen: isOrdersOpen },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const toggleOrdersModal = (): void => {
    dispatch(toggleOrderModal(!isOrdersOpen));
  };

  const closeOrdersModal = (): void => {
    dispatch(toggleOrderModal(false));
  };
  const { isOpen: isFiltersOpen, toggle: toggleFiltersModal, closeHandler: closeFiltersModal } = useToggle();
  const { isOpen: isCalendarOpen, toggle: toggleCalendarModal, closeHandler: closeCalendarModal } = useToggle();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchOrder(e.target.value);
  };
  const handleInputClear: React.ChangeEventHandler<HTMLInputElement> = () => {
    searchOrder('');
  };

  const openOrderDetailsModal = useCallback((orderId: number) => {
    toggleOrdersModal();
    dispatch(fetchPurchaseOrderDetails(orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openOrderDetailsModalByUrl = (): void => {
    if (orderId) {
      openOrderDetailsModal(Number(orderId));
    }
  };

  useEffect(() => {
    openOrderDetailsModalByUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = useCallback(
    (e: { target: HTMLElement }) => {
      const { target } = e;
      const clientHeight =
        target === document.body || target === document.documentElement
          ? window.screen.availHeight
          : target.clientHeight;
      const atBottom = target.scrollTop + clientHeight >= target.scrollHeight;
      // if has search term
      // trigger fetchPurchaseOrderList when scroll at the bottom
      if (atBottom && search.length > 0) {
        incrementPage();
      }
    },
    [search, incrementPage],
  );

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={incrementPage}
      hasMore={hasMore}
      loader={
        <Grid container justify="center">
          <S.Loader />
        </Grid>
      }
      scrollableTarget="infinite-scrollable"
      onScroll={onScroll}
      style={{
        paddingBottom: '60px',
      }}
    >
      <OrdersWrapper>
        <ReactJoyride
          {...tourState}
          callback={handleJoyride}
          styles={{ options: { primaryColor: colors.DARK_PRIMARY_COLOR } }}
        />
        <S.Container>
          <OrdersHistoryFilterTabs
            tKey="orderStatus"
            filters={orderStatusFilters}
            onClick={selectOrderStatusFilter}
            value={statusFilter}
          />
          <ConditionalRender condition={hasPrecursorStatusFilter}>
            <OrdersHistoryFilterPrecursorTabs
              tKey="orderPrecursorStatus"
              filters={orderPrecursorStatusFilters}
              value={precursorStatusFilter}
              onClick={selectOrderPrecursorStatusFilter}
            />
          </ConditionalRender>
          <S.SearchContainer>
            <SearchInput
              data-testid="orders-tab-search"
              id="tour_orders_tab_search"
              placeholder={t('search')}
              onChange={handleInputChange}
              value={search}
              onClear={handleInputClear}
            />
          </S.SearchContainer>
          <S.DataContainer>
            {loading ? (
              <OrdersHistoryCardLoader />
            ) : data.length > 0 ? (
              data.map((order) => (
                <OrdersHistoryCard
                  data-testid={`card-order-${order.id}`}
                  key={order.id}
                  filter={statusFilter}
                  onClick={() => openOrderDetailsModal(order.id)}
                  order={order}
                />
              ))
            ) : (
              <OrdersNoDataDisplay />
            )}
          </S.DataContainer>
        </S.Container>
      </OrdersWrapper>
      <OrdersHistoryFilters
        isCalendarActive={hasFilteredDateRange}
        isFilterActive={hasFilterDistributor}
        toggleCalendarModal={toggleCalendarModal}
        toggleFiltersModal={toggleFiltersModal}
      />
      <OrdersModal open={isOrdersOpen} onClose={closeOrdersModal} />
      <OrdersCalendarModal
        open={isCalendarOpen}
        onClose={closeCalendarModal}
        initialValue={dateRange}
        onSave={applyDateRangeFilter}
      />
      <OrdersDistributorFilterModal
        open={isFiltersOpen}
        onClose={closeFiltersModal}
        distributorFilter={distributorFilter}
      />
    </InfiniteScroll>
  );
};

export const OrdersHistory = translate('orders')(OrdersHistoryComponent);
