import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, ButtonBase, Divider } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { Card as SharedCard } from 'components/common';
import * as colors from 'components/styles/colors';
import ScrollView from 'components/views/ScrollView';
import { OrderPrecursorStatus } from 'services';

const PrekursorHeaderColorMapping = {
  [OrderPrecursorStatus.DOCUMENT_REQUIRED]: '#ff7f30',
  [OrderPrecursorStatus.DOCUMENT_RESUBMITTED]: '#ff7f30',
  [OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL]: '#ff7f30',
  [OrderPrecursorStatus.DOCUMENT_REJECTED]: '#ee3245',
  [OrderPrecursorStatus.DOCUMENT_CANCELLED]: '#ee3245',
  [OrderPrecursorStatus.DOCUMENT_APPROVED]: '#14C47D',
};

interface PrekursorHeaderProps {
  status: OrderPrecursorStatus;
}

const ArrowBackIcon = () => (
  <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5467 14.12L4.44002 8L10.5467 1.88L8.66669 0L0.666687 8L8.66669 16L10.5467 14.12Z" fill="black" />
  </svg>
);

const Wrapper = styled(ScrollView)`
  height: 100vh;
  background-color: ${colors.SNOW};
`;

const Header = styled(Grid)`
  && {
    padding: 22px 15px 15px 15px;
    background-color: ${colors.WHITE};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #e8eaed;
  }
`;

const ButtonBack = styled(ButtonBase)`
  && {
    padding-right: 10px;
  }
`;
const BackIcon = styled(ArrowBackIcon)`
  && {
    padding-right: 10px;
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    color: ${colors.BLACK};
  }
`;

const OrderedProductsList = styled.div`
  padding: 12px 15px 0 15px;
`;

const PrekursorContainer = styled.div`
  padding: 12px 15px 0 15px;
`;

const ProductItemsContainer = styled.div`
  .product__items > div:not(:last-child) {
    margin-bottom: 20px;
  }
  .product__pagination {
    padding: 20px 16px 0;
  }
`;
const ProductItemsLoadMoreTitle = styled(Typography)`
  && {
    color: ${colors.REBRAND_GREEN};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
`;
const ProductItemsLoadMoreIcon = styled(ExpandMore)`
  && {
    color: ${colors.REBRAND_GREEN};
    padding-left: 6px;
  }
`;
const ProductItemsLoadLessIcon = styled(ExpandLess)`
  && {
    color: ${colors.REBRAND_GREEN};
    padding-left: 6px;
  }
`;

const PrekursorHeader = styled(Grid).attrs((props: any) => ({
  container: true,
  direction: 'row',
  alignItems: 'center',
  status: props.status,
}))`
  && {
    padding: 8px 16px;
    background: ${(props) => (props.status ? PrekursorHeaderColorMapping[props.status] : '#ff7f30')};
    border-radius: 10px 10px 0px 0px;
  }
`;
const PrekusorTitle = styled(Typography)`
  && {
    padding-left: 12px;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.WHITE};
  }
`;

const ReturnWrapper = styled.div`
  && {
    padding: 20px 15px 0 15px;
    margin-bottom: 10px;
  }
`;

const ReturnReasonTitle = styled(Typography)`
  && {
    font-size: 12px;
    text-transform: uppercase;
    color: ${colors.BLUISH_GRAY_LIGHT};
    letter-spacing: 0.16px;
    line-height: 20px;
    font-weight: 700;
  }
`;

const ReturnReason = styled(Typography)`
  && {
    font-size: 12px;
    color: ${colors.BLUISH_GRAY_LIGHT};
  }
`;

const Card = styled(SharedCard)`
  padding: 0 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
`;

const Row = styled(Grid).attrs({ container: true, justifyContent: 'flex-start' })`
  padding: 14px 0;
  &.no-border {
    border-bottom: none;
  }
`;

const Label = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: ${colors.BLUISH_GRAY_LIGHT};
    text-transform: uppercase;
  }
`;

const PrecursorRejectedWrapper = styled.div`
  && {
    padding: 20px 15px 0 15px;
    margin-bottom: 10px;
  }
`;

const PrecursorRejectedTitle = styled(Typography)`
  && {
    font-size: 12px;
    text-transform: uppercase;
    color: ${colors.BLUISH_GRAY_LIGHT};
    letter-spacing: 0.16px;
    line-height: 20px;
    font-weight: 700;
  }
`;

const PrecursorRejectedReason = styled(Typography)`
  && {
    font-size: 12px;
    color: ${colors.BLUISH_GRAY_LIGHT};
  }
`;

const DividerLine = styled(Divider)`
  && {
    color: #e8e8e8;
    margin: 0 16px;
  }
`;

export const S = {
  BackIcon,
  ButtonBack,
  Card,
  Header,
  Label,
  OrderedProductsList,
  PrekursorHeader,
  PrekusorTitle,
  ProductItemsContainer,
  ProductItemsLoadLessIcon,
  ProductItemsLoadMoreIcon,
  ProductItemsLoadMoreTitle,
  ReturnWrapper,
  ReturnReasonTitle,
  ReturnReason,
  Row,
  Title,
  Wrapper,
  PrekursorContainer,
  PrecursorRejectedWrapper,
  PrecursorRejectedTitle,
  PrecursorRejectedReason,
  DividerLine,
};
