import * as Redux from 'redux';
import { Actions } from './actions';
import constants from './constants';

export interface State {
  isOpen: boolean;
  data?: string[];
}

const InitialState: State = {
  isOpen: false,
  data: [],
};

const LoyaltyPointDescriptionReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SHOW_LOYALTY_POINT_DIALOG:
      return {
        // ...state,
        isOpen: true,
        data: action.data,
      };
    case constants.HIDE_LOYALTY_POINT_DIALOG:
      return {
        isOpen: false,
      };
    default:
      return state;
  }
};

export default LoyaltyPointDescriptionReducer;
