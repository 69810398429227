import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { GetComponentProps } from 'types/GetComponentTypes.type';
import { Product } from 'store/reducers/Product';
import { RecommendationWidget, trackAddFavoriteProduct, trackRemoveFavoriteProduct } from 'utils/Analytics/Segment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { State as StoreState } from 'store';
import { TranslateProps } from 'utils/Localization';
import { useMarketing } from 'hooks/use-marketing';
import { useMarketingAnalytics } from 'hooks/useMarketingAnalytics';
import { addToFavoriteProduct, removeFromFavoriteProduct } from 'store/reducers/FavoriteProduct/actions';
import { setIsProductDetailUpdated } from 'store/reducers/ProductDetail/actions';

import { trackAddFavoriteItem } from 'utils/Analytics/CausalFoundry';
import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { syncRecentlyViewedSectionProductProps } from 'store/reducers/SearchSuggestions/actions';
import FavoriteButton from './favorite-button.component';

interface FavoriteButtonAnalyticsWrapperProps extends GetComponentProps<typeof FavoriteButton> {
  product: Product;
  marketingId?: number;
}

interface ILocationState {
  search_section?: string;
  query?: string;
}

export const FavoriteButtonAnalyticsWrapper: React.FC<
  React.PropsWithChildren<Omit<FavoriteButtonAnalyticsWrapperProps, 'favorited' | 'onClick'> & TranslateProps>
> = (props) => {
  const { product, t, marketingId, ...restProps } = props;
  const location = useLocation<ILocationState>();
  const dispatch = useDispatch();
  const { marketing } = useMarketing();

  const [favoritedData, setFavoritedData] = useState<{ isFavorited: boolean; favoriteId?: number }>({
    isFavorited: !!product.favorited,
    favoriteId: product.favorite_product_id,
  });

  const {
    favorites: { delayedFavoriteReq, delayedRemoveFavoriteReq },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const search_section = location?.state?.search_section;

  const isRequestDelayed = delayedFavoriteReq.find((prod) => prod.productId === product.id);
  const isDeleteRequestDelayed = delayedRemoveFavoriteReq.find((prod) => prod.productId === product.id);

  useEffect(() => {
    if (isRequestDelayed) {
      setFavoritedData({
        isFavorited: true,
        favoriteId: isRequestDelayed.favoriteId,
      });
    } else if (isDeleteRequestDelayed) {
      setFavoritedData({
        isFavorited: false,
        favoriteId: isDeleteRequestDelayed.favoriteId,
      });
    } else {
      setFavoritedData({
        isFavorited: !!product.favorited,
        favoriteId: product.favorite_product_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.favorited, product.favorite_product_id, isRequestDelayed, isDeleteRequestDelayed]);

  const toggleFavoriteButton = (favoritedProductId?: number): string => {
    if (favoritedData.isFavorited) {
      setFavoritedData({
        isFavorited: false,
        favoriteId: undefined,
      });

      dispatch(syncRecentlyViewedSectionProductProps(product.id, { favorited: false, favorite_product_id: undefined }));

      return t('removedFromFavoriteProduct');
    }

    setFavoritedData({
      isFavorited: true,
      favoriteId: favoritedProductId,
    });
    dispatch(
      syncRecentlyViewedSectionProductProps(product.id, { favorited: true, favorite_product_id: favoritedProductId }),
    );
    return t('addedToFavoriteProduct');
  };

  const handleFavoriteClicked = (): void => {
    if (favoritedData.isFavorited && favoritedData.favoriteId) {
      dispatch(removeFromFavoriteProduct(favoritedData.favoriteId, product.id, toggleFavoriteButton));
    } else {
      dispatch(addToFavoriteProduct(product, toggleFavoriteButton));
    }
  };

  const { query, category_ids } = qs.parse(location.search, BRACKET_QS_OPTIONS) || '';
  const marketingValues = {
    query,
    marketing_id: marketing?.id,
    marketing_name: marketing?.name,
    category_ids,
  };

  const { marketing_id, marketing_name } = marketingValues;

  const productMarketingAnalytics = useMarketingAnalytics(marketing_id, marketing_name);

  // SPT-15418 for all widget
  const recommendationWidgetProp = product.referrer_sku_code
    ? { recommendation_widget: RecommendationWidget.SIMILAR_PRODUCT }
    : {};

  const trackOnClick = (): void => {
    if (favoritedData.isFavorited && favoritedData.favoriteId) {
      trackAddFavoriteItem(product, {
        isRemove: true,
        promo_id: productMarketingAnalytics.marketing_id?.toString() ?? marketingId?.toString(),
      });
      trackRemoveFavoriteProduct({
        ...product,
        product_unfavorited_from: productMarketingAnalytics.from_page,
      });
    } else {
      trackAddFavoriteItem(product, {
        promo_id: productMarketingAnalytics.marketing_id?.toString() ?? marketingId?.toString(),
      });
      trackAddFavoriteProduct({
        ...product,
        product_favorited_from: productMarketingAnalytics.from_page,
        ...(search_section ? { search_section } : {}),
        ...recommendationWidgetProp,
      });
    }

    if (handleFavoriteClicked) {
      handleFavoriteClicked();
      dispatch(setIsProductDetailUpdated(true));
    }
  };

  return <FavoriteButton onClick={trackOnClick} favorited={favoritedData.isFavorited} {...restProps} />;
};
