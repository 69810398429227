import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import * as colors from 'components/styles/colors';

const Wrapper = styled.div`
  padding: 10px 10px 16px;
  background: #fff;
`;
const SequenceContainer = styled.div`
  display: flex;
`;

const SequenceIconContainer = styled.div`
  display: flex;
  margin: 8px 0;

  &:not(:first-child)::before {
    content: '';
    width: 10px;
    height: 0;
    border: 1px solid #d4d4d4;
    background-color: #d4d4d4;
    margin-top: 18px;
  }

  &:not(.order-cancelled).active {
    &::before {
      background-color: ${colors.PRIMARY_COLOR};
      border: 1px solid ${colors.PRIMARY_COLOR};
    }
  }
`;

const StatusText = styled(Typography)<any>`
  && {
    color: ${(props) => colors.getOrderStatusColor(props, 'secondary')};
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
  }
`;

const MiniLabel = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: normal;
    color: #717786;
  }
`;

const StatusDate = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 16px;
    color: #717786;
  }
`;

export const S = {
  SequenceIconContainer,
  SequenceContainer,
  Wrapper,
  MiniLabel,
  StatusText,
  StatusDate,
};
