import styled from 'styled-components';
import { Grid, Typography, ButtonBase } from '@material-ui/core';

import * as colors from 'components/styles/colors';

const Wrapper = styled(Grid)`
  && {
    padding: 30px 30px;
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 800;
    padding-bottom: 15px;
    text-align: center;
  }
`;

const Description = styled(Typography)`
  && {
    font-size: 12px;
    text-align: center;
  }
`;

const BtnText = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 600;
  }
`;

const Button = styled(ButtonBase)`
  && {
    width: 100%;
    padding: 12px;
    background-color: ${colors.SUBTLE_GRAY};
  }
`;

export const S = { Wrapper, Title, Description, BtnText, Button };
