/* eslint-disable react/require-default-props */
import React from 'react';

import { ProductCardLoader } from 'components/common/ProductCard/components/product-card-loader/ProductCardLoader';

export const ProductShimmer: React.FC = (): JSX.Element => (
  <>
    {new Array(5).fill({ loading: true }).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ProductCardLoader key={i} />
    ))}
  </>
);
