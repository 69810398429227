import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { recordException } from 'utils/Reporting/Sentry';

import { showError } from '../store/reducers/Global/actions';
import { v3Client } from '../utils/Client/v3';

export interface Param {
  url: string;
  page: number;
  pageSize: number;
  params?: any;
}

export interface Pagination<T> {
  data: T[];
  totalCount: number;
  resultsCount?: number;
  hasMore: boolean;
}

export interface PaginationHook<T> {
  data: T[];
  totalCount: number;
  resultsCount?: number;
  hasMore: boolean;
  loading: boolean;
  loadMore: () => void;
  setParameters: any;
}

export const usePagination = <T>(
  params: Param,
  initialState: Pagination<T>,
  preventFetching?: boolean,
): PaginationHook<T> => {
  const { url, pageSize } = params;
  const [parameters, setParameters] = useState(params.params);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(params.page);
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (preventFetching) return;
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      try {
        const result: any = await v3Client.get(url, {
          ...parameters,
          page,
          page_size: pageSize,
        });

        const { meta, data } = result;
        const rows = page === 1 ? data : [...state.data, ...data];

        setState({
          data: rows,
          totalCount: rows.length,
          resultsCount: meta.total_count,
          hasMore: page * pageSize < meta.total_count,
        });
      } catch (error) {
        recordException(error, 'usePagination', { state });
        dispatch(showError(error.message));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, page, pageSize, parameters]);

  const loadMore = (): void => setPage((page) => page + 1);

  return {
    ...state,
    loading,
    loadMore,
    setParameters,
  };
};
