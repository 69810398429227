import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

const Container = styled(Grid)<any>`
  && {
    border-radius: ${(props) => (props.rounded ? '0px 0px 20px 20px' : '2px')};
    padding: 8px 14px;
    background-color: #fef0f0;
    text-align: center;
    width: ${(props) => (props.gutter ? 'calc(100% - 64px)' : 'auto')};
    border: ${(props) => (props.bordered ? '1px dashed #FF5B5B' : '0')};
  }
`;

const Message = styled(Typography)<any>`
  && {
    font-weight: 600;
    color: #ff5b5b;
    font-size: 10px;
    padding: ${(props) => (props.gutter ? '0' : '0 30px')};
  }
`;

const Timer = styled.span`
  && {
    color: #ff5b5b;
  }
`;

export const S = {
  Container,
  Message,
  Timer,
};
