import { Action } from 'redux';
import { ThunkActionCreator } from 'types/thunk';
import * as constants from './constants';

export interface ISetCampaignDetail extends Action {
  utmSource: string;
  utmMedium: string;
}

export type Actions = ISetCampaignDetail;

export const setCampaignDetail: ThunkActionCreator<Actions> = (utmSource, utmMedium) => async (dispatch) => {
  dispatch({
    type: constants.SET_CAMPAIGN_DETAIL,
    utmSource,
    utmMedium,
  });
};
export const clearCampaignDetail = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_CAMPAIGN_DETAIL,
  });
};
