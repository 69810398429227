import styled from 'styled-components';
import { IconButton, Typography } from '@material-ui/core';
import { ReactComponent as SimilarProductsIconSVG } from 'images/similar-products-icon.svg';
import * as colors from 'components/styles/colors';

export const SimilarProductsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SimilarProductsIcon = styled(SimilarProductsIconSVG)`
  font-size: 24px;
  color: ${colors.REBRAND_GREEN};
`;

export const SimilarProductsText = styled(Typography)`
  && {
    text-transform: uppercase;
    text-align: center;
    font-size: 8px;
    color: ${colors.REBRAND_GREEN};
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    width: 40px;
    height: 40px;
    padding: 0;
  }
`;
