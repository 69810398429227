import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { breakpoints } from 'components/styles/breakpoints';
import { Typography } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import { BaseCard } from '../BaseCard';

interface WrapperProps extends Omit<PaperProps, 'variant'> {
  variant: string;
}

const BaseText = styled(Typography)`
  display: block;
`;

const Wrapper = styled(BaseCard)`
  && {
    background: ${({ variant }: WrapperProps) => variant};
    box-shadow: none;
    padding: 25px;

    a {
      color: ${({ variant }: WrapperProps) => darken(0.6, variant)};
    }
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      padding: 15px;
    }
  }
` as React.ComponentType<React.PropsWithChildren<WrapperProps>>;

const Title = styled(BaseText)`
  && {
    font-weight: 800;
    text-transform: uppercase;
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 12px;
    }
  }
`;

/* stylelint-disable */
const Message = styled(BaseText)`
  a {
    color: darkgreen;
    font-weight: 800;
    text-decoration: underline;
  }
`;
/* stylelint-enable */

export const S = {
  Wrapper,
  Title,
  Message,
};
