export enum ActivityStatus {
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  DELIVERED = 'delivered',
  PENDING = 'pending',
  DELAYED = 'delayed',
  PROCESSING = 'processing',
  DISPATCHED = 'dispatched',
  DELIVERY_PENDING = 'delivery_pending',
  DELIVERY_ONGOING = 'delivery_ongoing',
  DELIVERY_DELAYED = 'delivery_delayed',
  DELIVERY_CANCELED = 'delivery_canceled',
  DELIVERY_FAILED = 'delivery_failed',
  FULFILLED = 'fulfilled',
  COMPLETED = 'completed',
  ORDER_CHANGED = 'order_changed',
  RETURNED = 'returned',
  PREKURSOR_REQUIRED = 'precursor_required',
  PREKURSOR_APPROVED = 'precursor_approved',
  PREKURSOR_CANCELLED = 'precursor_cancelled',
  PREKURSOR_REJECTED = 'precursor_rejected',
  PREKURSOR_SUBMITTED = 'precursor_submitted',
  PREKURSOR_RESUBMITTED = 'precursor_resubmitted',
}
