import React from 'react';
import { Trans, translate } from 'react-i18next';
import { TranslateProps } from 'utils/Localization';
import { S } from './AvailabilityTimer.styles';

interface Props extends TranslateProps {
  show: boolean;
  isMonthly: boolean;
  bordered?: boolean;
  rounded?: boolean;
  gutter?: boolean;
}

const AvailabilityTimerBase: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { t, show, isMonthly, ...restProps } = props;

  if (!show) return null;

  return (
    <>
      <S.Container {...restProps}>
        <S.Message {...restProps}>
          {isMonthly ? (
            <Trans t={t} i18nKey="timer.maxPurchaseMonthly">
              <b>&nbsp;</b>
            </Trans>
          ) : (
            <Trans t={t} i18nKey="timer.maxPurchaseDaily">
              <b>&nbsp;</b>
            </Trans>
          )}
        </S.Message>
      </S.Container>
    </>
  );
};

AvailabilityTimerBase.defaultProps = {
  bordered: false,
  rounded: false,
  gutter: false,
};
export const AvailabilityTimer = translate('availability')(AvailabilityTimerBase);
