import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const getColor = (active: boolean) => {
  if (active) {
    return '#181D29';
  }

  return '#717786';
};

const Container = styled.div`
  border: 1px solid #b3b8c8;
  box-sizing: border-box;
  border-radius: 7px;
  height: 56px;
  width: 48%;
  padding: 8px 12px;
`;

const Title = styled(Typography)`
  && {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #9399a8;
    margin: 0;
  }
`;

const DateField = styled(Typography)<any>`
  && {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${({ active }) => getColor(active)};
  }
`;

export const S = { Container, Title, DateField };
