export const GET_TOURS = 'GET_TOURS';
export type GET_TOURS = typeof GET_TOURS;

export const UPDATE_TOURS = 'UPDATE_TOURS';
export type UPDATE_TOURS = typeof UPDATE_TOURS;

export const TRIGGER_TOURS = 'TRIGGER_TOURS';
export type TRIGGER_TOURS = typeof TRIGGER_TOURS;

export const SET_TOURS_LOADING = 'SET_TOURS_LOADING';
export type SET_TOURS_LOADING = typeof SET_TOURS_LOADING;
