import axios, { CancelTokenSource } from 'axios';

import { getMarketId } from 'utils/MarketConfig';
import { registerRefreshTokenInterceptor } from './refreshToken/refreshToken.interceptor';

let cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

const client = axios.create({
  baseURL: process.env.REACT_APP_PH_API_VTHREE,
  timeout: 5000 * 10,
});

client.interceptors.request.use((config) => {
  const newConfig = { ...config };

  newConfig.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  newConfig.headers['x-warp-api-key'] = process.env.REACT_APP_PH_API_KEY;

  const marketId = getMarketId();
  if (newConfig.baseURL?.split('/').pop() !== marketId) {
    newConfig.baseURL += `/${marketId}`;
  }

  if (config?.headers?.canCancelRequest) {
    newConfig.cancelToken = cancelTokenSource.token;
  }
  return newConfig;
});

registerRefreshTokenInterceptor(client);

const request = {
  get: async <D = any, P = any>(url: string, params?: P, canCancelRequest?: boolean): Promise<D | undefined> => {
    try {
      const { data } = await client.get<D>(url, {
        params,
        headers: {
          canCancelRequest,
        },
      });
      return data;
    } catch (e) {
      console.error('@requestClient.post', e.message);
      throw e;
    }
  },

  put: async <D = any, P = any>(url: string, payload?: D, params?: P): Promise<D | undefined> => {
    try {
      const { data } = await client.put<D>(url, payload, { params });

      return data;
    } catch (e) {
      console.error('@requestClient.post', e.message);
      throw e;
    }
  },

  post: async <D = any, P = any>(
    url: string,
    payload?: D,
    params?: P,
  ): Promise<{
    data: any;
    status: number;
  }> => {
    try {
      const { data } = await client.post(url, payload, { params });
      return data;
    } catch (e) {
      console.error('@requestClient.post', e.message);
      throw e;
    }
  },

  uploadFile: async <P = any>(
    url: string,
    file: File,
    onUploadProgress?: (progressEvent: any) => void,
  ): Promise<{
    data: any;
    status: number;
  }> => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await client.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });

      console.log('@requestClient.upload::response', response);
      return response;
    } catch (e) {
      console.error('@requestClient.upload', e.message);
      throw e;
    }
  },

  patch: async <D = any, P = any>(url: string, payload?: D, params?: P): Promise<D | undefined> => {
    try {
      const { data } = await client.patch<D>(url, payload, { params });

      return data;
    } catch (e) {
      console.error('@requestClient.post', e.message);
      throw e;
    }
  },

  delete: async <P = any>(url: string, params?: P): Promise<boolean> => {
    const { status: statusCode } = await client.delete(url, { params });
    return statusCode >= 200 && statusCode <= 299;
  },

  cancel: () => {
    cancelTokenSource.cancel();
    cancelTokenSource = axios.CancelToken.source();
  },
};

const cancelPendingRequest = (): void => {
  request.cancel();
};

export { request as v3Client, cancelPendingRequest };
