import styled, { css } from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

import * as colors from 'components/styles/colors';
import { Row as SharedRow, Card as SharedCard } from 'components/common';

const BaseText = css`
  font-size: 14px;
  color: ${colors.TEXT_LIGHT};
`;

const BaseOld = css`
  font-size: 14px;
  color: #9399a8;
  text-decoration: line-through;
  font-weight: normal;
`;

const TextBackground = css`
  display: block;
  font-size: 12px;
  padding: 3px;
  border-radius: 5px;
`;

const ProductName = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.TEXT_LIGHT};
  }
`;

const Package = styled(Typography)`
  && {
    font-size: 14px;
    color: ${colors.TEXT_LIGHT};
    font-weight: normal;
    text-transform: uppercase;
  }
`;

const Text = styled(Typography)`
  && {
    ${BaseText};
    text-transform: capitalize;

    &.light-text {
      color: ${colors.BLUISH_GRAY_LIGHT};
      font-size: 12px;
      font-weight: 400;
      &.small {
        font-size: 10px;
        font-weight: bold;
      }
      &&.old {
        font-size: 12px;
      }
    }
    &.green-text {
      color: #009d7c;
    }
    &.old {
      ${BaseOld};
      padding-right: 5px;
    }
    &.bold {
      font-weight: bold;
    }

    & > span.unit {
      color: ${colors.BLUISH_GRAY_LIGHT};
      text-transform: lowercase;
    }
  }
`;

const Discount = styled(Typography)`
  && {
    ${TextBackground};
    margin-right: 18px;
    font-weight: 800;
    color: ${colors.WHITE};
    background-color: ${colors.BLUE};
    font-size: 12px;
    padding: 1px 4px;

    &.old {
      ${BaseOld};
      background-color: rgba(113, 119, 134, 0.15);
      font-weight: 600;
    }
    &.zero {
      color: ${colors.DANGER_DARK};
      background-color: ${colors.DANGER_LIGHT};
      font-weight: 600;
    }
  }
`;

const DiscountedPrice = styled(Typography)`
  && {
    ${BaseText};
    color: ${colors.ORANGE};
    font-weight: 800;
    &.old {
      ${BaseOld};
      font-weight: normal;
    }
    &.zero {
      color: ${colors.DANGER_DARK};
    }
  }
`;

const StrongText = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 800;
  }
`;

const Quantity = styled(Typography)`
  && {
    ${TextBackground};
    align-self: flex-start;
    color: ${colors.BLUISH_GRAY_LIGHT};
    background-color: ${colors.SUBTLE_GRAY};
  }
`;

const Row = styled(SharedRow)`
  padding: 10px 12px;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.SUBTLE_GRAY};
  }
`;

const ProductDetail = styled(Grid).attrs({ container: true, direction: 'column' })`
  background: rgba(236, 238, 241, 0.39);
  padding: 0 12px;

  &.no-bottom {
    padding-bottom: 0;
  }

  & > div {
    width: 100%;
  }
`;

const ProductNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const Card = styled(SharedCard)`
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(99, 106, 122, 0.12), 0px 2px 10px rgba(58, 62, 71, 0.22);
  border-radius: 5px;
`;

const Tag = styled(Typography)<any>`
  && {
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => (props.variant === 'danger' ? colors.DANGER_DARK : colors.DARK_PRIMARY_COLOR)};
    background-color: ${(props) => (props.variant === 'danger' ? colors.DANGER_LIGHT : colors.LIGHT_PRIMARY_COLOR)};
    margin-right: 5px;
    padding: 2px 5px;
  }
`;
const ProductChanged = styled(Grid).attrs({ container: true, direction: 'column' })`
  background-color: ${colors.ORANGE_LIGHT};
  padding: 10px;
`;
const ChangedTitle = styled(SharedRow)`
  font-size: 12px;
  font-weight: bold;
  color: #6211f8;
  text-transform: uppercase;
  padding-bottom: 5px;
`;

const OutOfStock = styled.div`
  width: fit-content;
  font-size: 14px;
  color: ${colors.DANGER_DARK};
  border: 2px solid ${colors.DANGER_DARK};
  font-weight: bold;
  padding: 5px;
  margin-top: 5px;
`;

const ProductAttr = styled(SharedRow)`
  padding-bottom: 6px;
`;

export const S = {
  ProductName,
  Package,
  Text,
  Row,
  Card,
  Quantity,
  StrongText,
  Discount,
  DiscountedPrice,
  ProductDetail,
  ProductNameContainer,
  Tag,
  ProductChanged,
  ChangedTitle,
  OutOfStock,
  ProductAttr,
};
