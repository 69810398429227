import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { S } from './SectionCardSearchBar.style';

interface Props {
  color: string;
  dark?: boolean;
  onClose: () => void;
  onSearch: (query: string) => void;
}

export const SectionCardSearchBar: React.FC<React.PropsWithChildren<Props>> = ({
  color,
  dark,
  onClose,
  onSearch,
}) => {
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onSearch(e.currentTarget.value);
  };

  return (
    <S.Wrapper color={color} dark={dark}>
      <ChevronLeftIcon fontSize="large" onClick={onClose} />
      <TextField
        fullWidth
        className="search-bar"
        placeholder="Search"
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </S.Wrapper>
  );
};
