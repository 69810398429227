import * as React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { HelpDialogContainer, HelpDialogContent } from './DialogPage.styles';
import { Props } from './DialogPage.interface';

export const DialogPage: React.FC<React.PropsWithChildren<Props>> = ({ open, close, link }) => (
  <HelpDialogContainer fullScreen open={open}>
    <HelpDialogContent>
      <Grid container direction="column">
        <Grid item>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title  */}
          <iframe className="help" src={link} />
        </Grid>
        <Grid item container>
          <Button className="close-button" fullWidth onClick={close}>
            Close
          </Button>
        </Grid>
      </Grid>
    </HelpDialogContent>
  </HelpDialogContainer>
);
