import { Box, Divider } from '@material-ui/core';

import ShowIf from 'components/views/ShowIf';

import React from 'react';
import { IBaseSection } from '../interface';

import { S } from './search-sections.style';

interface ISectionHeader extends Omit<IBaseSection, 'id' | 'data'> {
  toggle: () => void;
  isExpanded: boolean;
  headerIcon?: JSX.Element;
  sectionSeeAllAction?: (params?: any) => void;
  onSeeAllButton?: () => void;
}

export const SectionHeader: React.FC<React.PropsWithChildren<ISectionHeader>> = ({
  t,
  title,
  subTitle,
  expandable,
  toggle,
  isExpanded,
  headerIcon,
  sectionSeeAllAction,
  onSeeAllButton,
}) => (
  <Box position="relative" display="flex" flexDirection="column" onClick={onSeeAllButton}>
    <S.SectionTitle>{t(title)}</S.SectionTitle>
    <ShowIf condition={!!subTitle}>
      <S.SectionSubtitle>{subTitle}</S.SectionSubtitle>
      {onSeeAllButton && headerIcon}
    </ShowIf>

    <ShowIf condition={!!expandable}>
      <S.ActionText onClick={toggle}>{isExpanded ? t('close') : t('seeAllAction')}</S.ActionText>
    </ShowIf>

    <ShowIf condition={!!sectionSeeAllAction && !expandable}>
      <S.ActionText onClick={sectionSeeAllAction}>{t('seeAllAction')}</S.ActionText>
    </ShowIf>
    <Divider style={{ margin: '0 16px' }} />
  </Box>
);

SectionHeader.defaultProps = {
  headerIcon: undefined,
  sectionSeeAllAction: undefined,
  onSeeAllButton: undefined,
};
