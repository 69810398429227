import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import * as colors from 'components/styles/colors';

const Card = styled(Grid)`
  background-color: ${colors.WHITE};
  box-shadow: 0px 2px 3px rgba(58, 62, 71, 0.24),
    0px 0px 1px rgba(99, 106, 122, 0.35);
`;

export const S = { Card };
