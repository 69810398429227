import React, { useEffect } from 'react';
import { Modal } from '@material-ui/core';
import qs from 'query-string';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { State as StoreState } from 'store';
import {
  fetchProductDetail,
  setDetailPageModalToggle,
  setDetailPageProductId,
  setProductDetail,
  setDetailPagePrevProductId,
  setHasSimilarProducts,
} from 'store/reducers/ProductDetail/actions';
import { addOrUpdateProductQuantity } from 'store/reducers/Counter/actions';
import { setRecentlyViewedProducts } from 'store/reducers/Search/actions';
import { setCloseSnackbarCart } from 'store/reducers/Utils/actions';
import { clearGlobalSuggestions, setRecentlyViewedSectionProducts } from 'store/reducers/SearchSuggestions/actions';

import { TranslateProps } from 'utils/Localization';
import { FiltersSortsHolder } from 'components/common/FilterToolbar/interface';
import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { ILocationProps } from 'components/common/SearchMenuSuggestions/searchMenuSuggestion.interface';
import * as colors from '../../styles/colors';
import { ProductDetailPage } from './component';
import { DispatchModalProps, DispatchProps, StateModalProps, StateProps } from './interface';
import { DetailPageDisplayType } from './utils';

const backdropStyle: React.CSSProperties = {
  backgroundColor: colors.WHITE,
};

const ProductDetailPageModal: React.FC<React.PropsWithChildren<StateProps & DispatchProps & StateModalProps & DispatchModalProps & TranslateProps>> = (props) => {
  const { isModalOpen, setProductId, setModalToggle, setPrevProductId, clearSuggestions, ...restProps } = props;
  const { productId } = restProps;
  const match = useRouteMatch();
  const history = useHistory();

  const { search: locationSearch, state: locationState } = useLocation();

  const { isModalOpen: isModalOpenQs, product_id: productIdQs } = qs.parse(locationSearch, BRACKET_QS_OPTIONS) as {
    isModalOpen?: boolean;
    product_id?: string;
  };

  const currentProductId = Number(productIdQs);

  const baseCloseModal = (): void => {
    if (productId) {
      setPrevProductId(productId as number);
    }
    setProductId(null);
    setModalToggle(false);
  };

  const closeModal = (): void => {
    baseCloseModal();
    clearSuggestions();
    const currentQuery = qs.parse(locationSearch, BRACKET_QS_OPTIONS) as unknown as FiltersSortsHolder & ILocationProps;

    const modalQueryString = qs.stringify(
      {
        query: currentQuery.query,
        goBackTo: currentQuery.goBackTo,
        category_ids: currentQuery.category_ids,
        filters: currentQuery.filters,
        sorts: currentQuery.sorts,
      },
      BRACKET_QS_OPTIONS,
    );

    history.replace({
      pathname: match.url,
      search: modalQueryString,
      state: { ...((locationState as unknown as any) || {}), isCloseModalAction: true, isOpenModalAction: false },
    });
  };

  useEffect(() => {
    // Auto open modal by query & handle go back from similar product
    if (isModalOpenQs && currentProductId) {
      setModalToggle(true);
      setProductId(currentProductId);
      clearSuggestions();
    } else if (isModalOpen && !isModalOpenQs) {
      baseCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpenQs]);

  return (
    <Modal open={!!isModalOpen} BackdropProps={{ style: backdropStyle }}>
      <ProductDetailPage
        closeModal={closeModal}
        baseCloseModal={baseCloseModal}
        displayType={DetailPageDisplayType.MODAL}
        {...restProps}
      />
    </Modal>
  );
};

const mapStateToProps = ({ productDetail, counter, utils }: StoreState): StateProps & StateModalProps => ({
  isLoading: productDetail.isLoading,
  productDetail: productDetail.product,
  isProductUpdated: productDetail.isProductUpdated,
  productId: productDetail.productId,
  isModalOpen: productDetail.isModalOpen,
  hasSimilarProducts: productDetail.hasSimilarProducts,
  snackbarCart: utils.snackbarCart,

  cart: {
    total: counter.totalItem,
    items: counter.counterList,
  },
});

const mapDispatchToProps = {
  fetchProductDetail,
  setProductId: setDetailPageProductId,
  setModalToggle: setDetailPageModalToggle,
  setProductDetail,
  setPrevProductId: setDetailPagePrevProductId,
  setHasSimilarProducts,
  addOrUpdateProductQuantity,
  clearSuggestions: clearGlobalSuggestions,
  setRecentlyViewedProducts,
  setCloseSnackbarCart,
  setRecentlyViewedSectionProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('productDetailPage')(ProductDetailPageModal));
