import * as Redux from 'redux';

import { ProductListingParam } from 'components/common';
import { FilterType, SortType } from 'components/common/FilterToolbar/constants';
import * as constants from './constants';
import { Actions, FilterMenuItem } from './interface';

export interface FilterToolbarRequest {
  selectedFilters?: string[];
  selectedSorts: string[];
  toRemoveFilters: FilterType[];
  toRemoveSorts: SortType[];
  allowSearch: boolean;
}

export interface State {
  allowSearch: boolean;
  retrigger?: symbol;
  toolbarFilterMenu: FilterMenuItem[];
  selectedFilters: string[];
  selectedSorts: string[];
  searchParameters: ProductListingParam;
  filterUrl?: string;
  resetted: boolean;
  reinitialize: boolean;

  // NOTE: need to plan to move state sort to backed in order to remove this

  // lvl1SortBy - product.id, state_sort, random, this is for the old sort types
  filterSort?: SortType;
  // this is for the modal sort
  lvl2SortBy: SortType[];
  showFilterSortModal?: boolean;
}

const InitialState: State = {
  allowSearch: false,
  retrigger: undefined,
  filterUrl: undefined,
  toolbarFilterMenu: [],
  selectedFilters: [],
  selectedSorts: [],
  searchParameters: {
    category_ids: [],
    query: undefined,
    filters: [],
    sorts: [],
  },
  filterSort: undefined,
  lvl2SortBy: [],
  showFilterSortModal: false,
  resetted: false,
  reinitialize: true,
};

const FilterToolbarReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SETUP_FILTER:
      return {
        ...state,
        ...InitialState,
        toolbarFilterMenu: action.toolbarFilterMenu,
      };
    case constants.SELECT_FILTER:
      return {
        ...state,
        loading: false,
        selectedFilters: action.selectedFilters,
        selectedSorts: action.selectedSorts,
        searchParameters: action.searchParameters,
        filterUrl: action.filterUrl,
        filterSort: action.filterSort,
        lvl2SortBy: action.lvl2SortBy,
        resetted: false,
        allowSearch: action.allowSearch,
      };
    case constants.CLEAR_FILTERS:
      return {
        ...state,
        ...InitialState,
        resetted: true,
      };
    case constants.ERROR_ON_FILTER:
      return {
        ...state,
        error: action.error,
      };
    case constants.SET_SHOW_FILTER_SORT_MODAL:
      return {
        ...state,
        showFilterSortModal: action.show,
      };
    case constants.SET_REINITIALIZE_FILTERS:
      return {
        ...state,
        reinitialize: action.reinitialize,
      };
    default:
      return state;
  }
};

export default FilterToolbarReducer;
