import React from 'react';
import * as colors from 'components/styles/colors';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

export const ProductIconFilled: React.FC<React.PropsWithChildren<Props>> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.89 4H15.36L16.07 2.03C16.31 1.38 15.97 0.660001 15.32 0.430001C14.67 0.190001 13.95 0.530001 13.71 1.18L12.69 4H2.1C0.730002 4 -0.229998 5.34 0.200002 6.63L2 12L0.210002 17.37C-0.229998 18.66 0.740002 20 2.1 20H15.88C17.24 20 18.21 18.66 17.78 17.37L16 12L17.78 6.63C18.21 5.34 17.25 4 15.89 4ZM12 13H10V15C10 15.55 9.55 16 9 16C8.45 16 8 15.55 8 15V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H8V9C8 8.45 8.45 8 9 8C9.55 8 10 8.45 10 9V11H12C12.55 11 13 11.45 13 12C13 12.55 12.55 13 12 13Z" />
  </svg>
);

ProductIconFilled.defaultProps = {
  fill: colors.PRIMARY_COLOR,
  width: '20px',
  height: '20px'
};
