import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, ButtonBase } from '@material-ui/core';

import * as colors from 'components/styles/colors';
import { breakpoints } from 'components/styles/breakpoints';
import { NotificationsActive } from '@material-ui/icons';

const DetailsContainer = styled(Grid)`
  && {
    width: 100%;
    padding: 10px 12px 10px 0px;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const DefaultText = styled(Typography)`
  && {
    font-size: 12px;
    text-transform: uppercase;
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 10px;
    }
  }
`;

const ProductName = styled(({ ...props }) => <DefaultText {...props} />)`
  && {
    color: ${colors.TEXT_LIGHT};
    font-weight: 800;
    opacity: ${({ stockout }) => (stockout ? 0.5 : 1)};
    margin-top: ${({ isUnpurchasable }) => isUnpurchasable && '25px'};
    word-break: break-word;
  }
`;

const MaxQty = styled.span`
  color: ${colors.PURPLE_DARK};
  text-transform: none;
`;

const Packaging = styled(({ ...props }) => <DefaultText {...props} />)`
  && {
    color: ${colors.BLUISH_GRAY_LIGHT};
    opacity: ${({ stockout }) => (stockout ? 0.5 : 1)};
    word-break: break-word;
  }
`;

const StockOut = styled(DefaultText)`
  && {
    color: ${colors.DANGER};
    font-weight: 800;
    text-transform: uppercase;
  }
`;

const Notify = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    color: ${colors.WHITE};
  }
`;

const BellIcon = styled(NotificationsActive)`
  font-size: 16px;
  color: ${colors.WHITE};
  margin-right: 8px;
  margin-bottom: auto;
`;

const NotifyBtn = styled(ButtonBase)`
  && {
    border-radius: 16px;
    padding: 6px 14px;
    background-color: ${colors.REBRAND_GREEN};
  }
`;

const PricingContainer = styled(Grid)`
  && {
    width: 100%;
    padding-top: 10px;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const SellingPrice = styled(DefaultText)`
  && {
    font-size: 12px;
    color: ${colors.BLUISH_GRAY_LIGHT};
    text-decoration: line-through;
    text-transform: capitalize;
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 11px;
    }
  }
`;

const NetPrice = styled(({ ...props }) => <Typography {...props} />)`
  && {
    font-size: 14px;
    font-weight: 800;
    color: ${colors.TEXT_LIGHT};
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 12px;
    }
  }
`;

const ReminderMessage = styled.span`
  padding: 5px 0;
  font-size: 11px;
  font-style: italic;
`;

export const S = {
  DetailsContainer,
  ProductName,
  MaxQty,
  Packaging,
  PricingContainer,
  SellingPrice,
  NetPrice,
  StockOut,
  NotifyBtn,
  Notify,
  ReminderMessage,
  BellIcon,
};
