import React from 'react';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import { PharmacyOrderStatus } from 'services';

import { OrdersHistory } from '../index';
import { OrdersActivityContainer } from '../orders-activity/orders-activity.container';

const OrderHistoryTabWrapper = (): JSX.Element => {
  const { status = PharmacyOrderStatus.ALL_ORDERS } = useParams<{ status: PharmacyOrderStatus }>();

  return <OrdersHistory status={status} />;
};

export const OrdersTabs: React.FC<React.PropsWithChildren<unknown>> = () => {
  const match = useRouteMatch();
  const { id } = useParams<{ id: string }>();

  switch (id) {
    case 'activity':
      return <OrdersActivityContainer />;
    case 'history':
      return <Route path={`${match.path}/:status?/:orderId?`} component={OrderHistoryTabWrapper} />;
    default:
      return null;
  }
};
