import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Product } from 'store/reducers/Product';
import { deleteProductReminder, productAvailabilityRequest } from 'store/reducers/ProductReminder/actions';
import { ProductSimpleSearchLocationProps, trackRemoveProductFromRemindersPage } from 'utils/Analytics/Segment';
import { trackAddReminderProduct } from 'utils/Analytics/Segment/SegmentProduct';
import { State as StoreState } from 'store';
import { setIsProductDetailUpdated } from 'store/reducers/ProductDetail/actions';

import { trackStockOutReminderItem as causalFoundryTrackStockOutReminderItem } from 'utils/Analytics/CausalFoundry';
import { useMarketingAnalytics } from './useMarketingAnalytics';
import { useToggle } from './useToggle';

interface StokOutHook {
  isOpen: boolean;
  isStockOut: boolean;
  availabilityRequested: boolean;

  toggle: () => void;
  requestAvailability: () => void;
  removeAvailabilityRequest: () => void;
}

export const useStockOut = (product: Product): StokOutHook => {
  const {
    id: productId,
    distributor: { id: distributorId },
    remaining_quantity,
    quantity_threshold: threshold,
    available,
    availability_request_id: availabilityRequestId,
  } = product;

  const dispatch = useDispatch();

  const {
    productReminder: { delayedAvailabilityReq, delayedDeleteAvailabilityReq },
    productDetail: { isModalOpen },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const [availabilityRequested, setAvailabilityRequestStatus] = useState(product.availability_requested);
  const { isOpen, toggle } = useToggle();
  const location = useLocation<ProductSimpleSearchLocationProps>();
  const search_section = location?.state?.search_section;

  const isBothZero = remaining_quantity === 0 && threshold === 0;
  const isStockOut = remaining_quantity <= threshold || !available || isBothZero;

  const marketingAnalytics = useMarketingAnalytics();

  const isRequestDelayed = delayedAvailabilityReq.find((prod) => prod.productId === productId);
  const isDeleteRequestDelayed = delayedDeleteAvailabilityReq.find((prod) => prod.productId === productId);

  useEffect(() => {
    if (isRequestDelayed) {
      setAvailabilityRequestStatus(true);
    } else if (isDeleteRequestDelayed) {
      setAvailabilityRequestStatus(false);
    } else {
      setAvailabilityRequestStatus(product.availability_requested);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.availability_requested, productId, delayedAvailabilityReq, delayedDeleteAvailabilityReq, isModalOpen]);

  const requestAvailability = (): void => {
    toggle();

    trackAddReminderProduct({
      product_id: productId,
      sku_code: product.sku_code,
      page: marketingAnalytics.from_page,
      search_section,
    });
    dispatch(productAvailabilityRequest(productId, distributorId));
    setAvailabilityRequestStatus(true);
    causalFoundryTrackStockOutReminderItem(product, { promo_id: marketingAnalytics.marketing_id?.toString() });
    dispatch(setIsProductDetailUpdated(true));
  };

  const removeAvailabilityRequest = (): void => {
    dispatch(
      deleteProductReminder({
        productId,
        availabilityRequestId: isRequestDelayed ? isRequestDelayed.availabilityRequestId : availabilityRequestId,
        distributorId,
      }),
    );
    trackRemoveProductFromRemindersPage({
      product_id: product.id,
      sku_code: product.sku_code,
      page: marketingAnalytics.from_page,
    });
    causalFoundryTrackStockOutReminderItem(product, {
      isRemove: true,
      promo_id: marketingAnalytics.marketing_id?.toString(),
    });

    setAvailabilityRequestStatus(false);
    dispatch(setIsProductDetailUpdated(true));
  };

  return {
    isOpen,
    toggle,
    requestAvailability,
    removeAvailabilityRequest,
    isStockOut,
    availabilityRequested,
  };
};
