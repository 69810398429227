import React from 'react';
import { GridProps } from '@material-ui/core/Grid';
import { S } from './header.styles';

interface Props extends GridProps {}

export const Header: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => (
  <S.Header container alignItems="center" {...props}>
    {children}
  </S.Header>
);
