import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { TranslateProps } from 'utils/Localization/types';
import { S } from './ConfirmationDialog.styles';

export interface Props {
  showDialog: boolean;
  closeDialog: () => void;
  handleYes: () => void;
}

export const ConfirmationDialog: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ closeDialog, t, handleYes, showDialog }) => (
  <Dialog open={showDialog} maxWidth="xs">
    <DialogContent>
      <S.DialogText align="center">{t('removeItemConfirmation')}</S.DialogText>
    </DialogContent>
    <Divider />
    <S.DialogActions>
      <Button onClick={handleYes} color="primary" size="medium" data-testid="button-yes">
        Yes
      </Button>
      <Button onClick={closeDialog} color="primary" size="medium" data-testid="button-no">
        No
      </Button>
    </S.DialogActions>
  </Dialog>
);
