import styled from 'styled-components';

import { Grid } from '@material-ui/core';

const Wrapper: typeof Grid = styled(Grid)`
  && {
    background: #fa5253;
    font-weight: 600;
    padding: 16px;

    &&, svg {
      color: #fff;
    }
  }
`;

export const S = {
  Wrapper,
};
