import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { trackHomesquarePage } from 'utils/Analytics/Segment';
import { HelpDialog } from 'components/common/HelpDialog';

import { useLinkHandler } from 'hooks/useLinkHandler';
import { getNumberEnvWithDefault } from 'utils/envs/env-number-workaround';
import { IconContainer, NameContainer, PaperContent, SlickContainer, Img, SlickSliderStyled } from './style';

export interface IHomesquareIcons {
  id: number;
  title: string;
  icon_url: string;
  display_priority: number;
  listing_link: string;
}
interface HomesquareCarousel {
  homesquare: IHomesquareIcons[];
  loading: boolean;
}

const HomesquareSkeleton = () => (
  <div>
    <Skeleton variant="rect" width="100%" height="24vw" />
    <Skeleton variant="text" width="100%" />
  </div>
);

const DoubleRow: React.FC<React.PropsWithChildren<{ items: IHomesquareIcons[]; onClick: (link: string) => void }>> = ({
  items,
  onClick,
}): JSX.Element => (
  <>
    {items?.map((item, i) => (
      <SingleRow item={item} onClick={onClick} key={item.id || i} last={i === items.length - 1} />
    ))}
  </>
);

const SingleRow: React.FC<
  React.PropsWithChildren<{ item: IHomesquareIcons; onClick: (link: string) => void; last?: boolean }>
> = ({ item, onClick, last = false }): JSX.Element => {
  const { id, title, listing_link, icon_url } = item;

  const onClickLinkHandler = () => {
    trackHomesquarePage({ homesquare_id: id, homesquare_name: title, listing_link });

    onClick(listing_link);
  };

  return (
    <IconContainer key={`${title}-${id}`} data-testid={`btn-homesquare-${title}`} last={last}>
      <PaperContent onClick={onClickLinkHandler}>
        <Img alt={title} src={icon_url} />
      </PaperContent>
      <NameContainer className="text">{title}</NameContainer>
    </IconContainer>
  );
};

SingleRow.defaultProps = {
  last: false,
};

export const HomesquareCarousel: React.FC<React.PropsWithChildren<HomesquareCarousel>> = ({
  homesquare = [],
  loading,
}) => {
  const { onClick: handleLinkClick, showHelpDialog, helpDialogURL, toggleHelpDialog } = useLinkHandler();

  if (loading && (!homesquare || !homesquare.length)) {
    const renderShimmer = new Array(4).fill({ loading: true });

    return (
      <div
        style={{
          display: 'grid',
          width: '100&',
          margin: '16px 8px',
          gap: '0.5rem',
          gridTemplateColumns: 'repeat(4, 1fr)',
        }}
      >
        {renderShimmer.map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <HomesquareSkeleton key={i} />
        ))}
      </div>
    );
  }

  if ((!homesquare || !homesquare.length) && !loading) return <></>;

  const slidesToScroll = Math.floor(getNumberEnvWithDefault(process.env.REACT_APP_HOMESQUARE_SLIDES_TO_SHOW, 4.5));

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: getNumberEnvWithDefault(process.env.REACT_APP_HOMESQUARE_SLIDES_TO_SHOW, 4.5), // tested values 3.5,4.5,5.5
    slidesToScroll,
    draggable: true,
    swipe: true,
    appendDots: (dots) => {
      if (dots.every((dot) => !dot.props.className.includes('slick-active'))) {
        dots[dots.length - 1] = React.cloneElement(dots[dots.length - 1], {
          className: `${dots[dots.length - 1].props.className} slick-active`,
        });
      }

      return <ul>{dots}</ul>;
    },
    customPaging: (i) => (
      <div className="ft-slick__dots--custom" key={i}>
        <br />
      </div>
    ),
    overflow: true,
  };

  const mockUpValues: any[] = [];

  const shouldTwoRows = homesquare.length >= 8;
  const currentValue = shouldTwoRows ? mockUpValues : homesquare;

  const chunkSize = 2;
  if (shouldTwoRows) {
    for (let i = 0; i < homesquare.length; i += chunkSize) {
      const chunk: IHomesquareIcons[] = homesquare.slice(i, i + chunkSize);
      mockUpValues.push(chunk);
    }
  }

  return (
    <SlickContainer>
      <HelpDialog open={showHelpDialog} close={toggleHelpDialog} link={helpDialogURL} />
      <SlickSliderStyled {...settings}>
        {currentValue.map((item, i): JSX.Element => {
          if (item instanceof Array) {
            return <DoubleRow items={item} key={item.map((itd) => itd.d).join('-')} onClick={handleLinkClick} />;
          }
          return <SingleRow item={item} key={item.id || i} onClick={handleLinkClick} />;
        })}
      </SlickSliderStyled>
    </SlickContainer>
  );
};
