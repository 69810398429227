import React from 'react';

import { S } from './card.styles';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const Card: React.FC<React.PropsWithChildren<Props>> = ({ children, className, style }) => (
  <S.Card className={className} style={{ ...style }}>
    {children}
  </S.Card>
);
