import { v3Client } from 'utils/Client/v3';
import { Dispatch } from 'react';
import { PaymentMethods } from 'utils/constants';
import { recordException } from 'utils/Reporting/Sentry';
import { swipeRxPt } from 'services';
import { ILogisticsArea } from 'services/swipe-rx-pt/resources/logistics-pharmacy-area/interfaces/LogisticsPharmacyArea.interface';
import {
  PharmacyActionTypes,
  SetPharmacyMarketingSegment,
  SetPharmacyServingDistributors,
  SetPharmacyLoading,
  SetPharmacyError,
  SetPharmacyPaymentMethod,
  SetPharmacyLogisticAreas,
} from './pharmacy-actions.type';
import ClientRequest from '../../../utils/Client';
import { ServingDistributor, MarketingSegment } from './pharmacy.interface';
import { PtCoreUser } from '../Auth';

// REDUX SET STATE
export const setPharmacyServingDistributors = (data: ServingDistributor[]): SetPharmacyServingDistributors => ({
  type: PharmacyActionTypes.SET_PHARMACY_SERVING_DISTRIBUTORS,
  payload: { data },
});

export const setPharmacyMarketingSegment = (data: MarketingSegment): SetPharmacyMarketingSegment => ({
  type: PharmacyActionTypes.SET_PHARMACY_MARKETING_SEGMENT,
  payload: { data },
});

export const setPharmacyLoading = (loading: boolean): SetPharmacyLoading => ({
  type: PharmacyActionTypes.SET_PHARMACY_LOADING,
  payload: { loading },
});

export const setPharmacyError = (error: string): SetPharmacyError => ({
  type: PharmacyActionTypes.SET_PHARMACY_ERROR,
  payload: { error },
});

export const setPharmacyPaymentMethod = (paymentMethod: PaymentMethods): SetPharmacyPaymentMethod => ({
  type: PharmacyActionTypes.SET_PHARMACY_PAYMENT_METHOD,
  payload: paymentMethod,
});

export const setPharmacyLogisticAreas = (areas: ILogisticsArea[]): SetPharmacyLogisticAreas => ({
  type: PharmacyActionTypes.SET_PHARMACY_LOGISTIC_AREAS,
  payload: areas,
});

// DATA FETCHING LOGIC
export const fetchPharmacyServingDistributors = (id: number) => async (dispatch) => {
  try {
    dispatch(setPharmacyLoading(true));
    const res = await v3Client.get(`/organizations/${id}/serving-distributors`);

    dispatch(setPharmacyServingDistributors(res.data));
  } catch (error) {
    recordException(error, 'fetchPharmacyServingDistributors', { id });
    dispatch(setPharmacyError(error.message));
  } finally {
    dispatch(setPharmacyLoading(false));
  }
};

export const fetchPharmacyMarketingSegment = (id: number) => async (dispatch) => {
  try {
    dispatch(setPharmacyLoading(true));
    const { data } = await ClientRequest.fetch(`/${id}/organization-details`, {
      params: { name: 'marketing_segment' },
      warp_token: localStorage.getItem('session_token'),
    });

    dispatch(setPharmacyMarketingSegment(data?.value));
  } catch (error) {
    recordException(error, 'fetchPharmacyMarketingSegment', { id });
    dispatch(setPharmacyError(error.message));
  } finally {
    dispatch(setPharmacyLoading(false));
  }
};

export const fetchOrganizationPaymentMethod =
  () =>
  async (dispatch: Dispatch<SetPharmacyPaymentMethod | SetPharmacyError>, getState): Promise<void> => {
    try {
      const {
        auth: { coreUser },
      } = getState();

      const pharmacyId = (coreUser as PtCoreUser).organization_id;
      const res = await v3Client.get(`/organizations/${pharmacyId}/payment-method`);

      dispatch(setPharmacyPaymentMethod(res.data.payment_method));
    } catch (error) {
      dispatch(setPharmacyError(error.message));
    }
  };

export const getPharmacyLogisticAreas =
  () => async (dispatch: Dispatch<SetPharmacyLogisticAreas | SetPharmacyError>, getState) => {
    try {
      const {
        auth: {
          coreUser: { organization_id: pharmacyId },
        },
      } = getState();

      const results: any = await swipeRxPt.logisticsPharmacyArea.pharmacyAreas(pharmacyId);

      dispatch(setPharmacyLogisticAreas(results.map((pharmacyArea) => pharmacyArea.logistics_area)));
    } catch (error) {
      dispatch(setPharmacyError(error.message));
    }
  };
