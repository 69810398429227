/* eslint-disable react/require-default-props */
import React from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import LogoSwipeRX from 'images/logo_swiperx_s.svg';
import LoyaltyPoint from 'images/ic_loyalty_point.svg';
import ShowIf from 'components/views/ShowIf';
import { Modal } from 'components/common';
import { TranslateProps } from 'utils/Localization/types';
import { IVoucher } from 'services/swipe-rx-pt/resources/voucher/interface/voucher.interface';
import { swipeRxPt } from 'services';

import * as colors from '../../../../../styles/colors';

interface StateProps {}

interface DispatchProps {}

interface Props extends StateProps, DispatchProps, TranslateProps {
  voucher?: IVoucher;
  isApplied?: boolean;
  onClose: () => void;
  onApplyVoucher?: () => void;
  onRemoveVoucher?: () => void;
  isVoucherLoading?: boolean;
}

const VoucherDetailBase: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { voucher, isApplied = false, t } = props;
  const { onClose, onApplyVoucher, onRemoveVoucher, isVoucherLoading } = props;

  if (!voucher) return null;

  const showBadge =
    (!!voucher.available_voucher && voucher.available_voucher > 1) || !!voucher.voucher_type.active_end_date;

  const handleApplyVoucher = (): void => {
    if (isApplied) {
      // eslint-disable-next-line no-unused-expressions
      onRemoveVoucher && onRemoveVoucher();

      swipeRxPt.voucherTracking.trackVoucherCancel({
        voucherCode: voucher.code,
        voucherId: voucher.id,
        voucherUsageId: voucher.voucher_usage_id,
      });
    } else {
      // eslint-disable-next-line no-unused-expressions
      onApplyVoucher && onApplyVoucher();
    }
  };

  return (
    <Modal open={!!voucher} onClose={onClose} style={{ zIndex: 1201 }} BackdropProps={{ style: { bottom: 56 } }}>
      <div
        style={{
          background: colors.WHITE,
          height: 400,
          marginTop: `calc(100vh - 400px)`,
          width: '100%',
          borderRadius: '40px 40px 0px 0px',
          boxShadow: '0px -1px 20px 0px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Grid style={{ paddingTop: 20 }} container alignContent="center">
          <Grid item xs={10}>
            <Typography
              style={{
                fontWeight: 700,
                marginLeft: 30,
                marginBottom: 20,
                width: `calc(100% - 60px)`,
                fontSize: 18,
              }}
            >
              {voucher?.voucher_type.title}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              data-testid="close-btn"
              onClick={onClose}
              style={{ width: 36, height: 36, background: colors.WEAK_GRAY }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Typography
          style={{
            fontSize: 15,
            fontWeight: 400,
            marginLeft: 30,
            width: `calc(100% - 60px)`,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} alignItems="center">
              <div style={{ flex: 1, display: 'flex' }}>
                <ShowIf condition={voucher.voucher_type.price_type === 'OPEN_LOYALTY_POINT'}>
                  <img
                    src={LoyaltyPoint}
                    alt="voucher brand logo"
                    style={{ width: 17, height: 17, objectFit: 'fill', marginRight: 6 }}
                  />
                </ShowIf>

                <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                  {voucher.voucher_type.price_type === 'OPEN_LOYALTY_POINT' ? 'Point' : 'Cash'}
                </Typography>
              </div>

              <Typography style={{ fontSize: 14, marginTop: 5 }}>
                {voucher.voucher_type.price <= 0 ? t('free') : voucher.voucher_type.price}
              </Typography>
            </Grid>

            <Divider orientation="vertical" flexItem style={{ marginLeft: 18, marginRight: 18 }} />
            <Grid item xs={6} justify="flex-end">
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                {t('validDate')}
              </Typography>
              <br />
              <Typography style={{ fontSize: 14, color: '#828282', marginTop: -15 }}>
                {voucher.voucher_type.active_end_date
                  ? moment(voucher.voucher_type.active_end_date).format('DD-MMM-YYYY')
                  : 'No time limit'}
              </Typography>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <Grid container spacing={1}>
            <Grid container item xs={6} alignItems="center">
              <img
                src={voucher.voucher_type.photo || LogoSwipeRX}
                alt="voucher brand logo"
                style={{ width: 17, height: 17, objectFit: 'fill', marginRight: 6 }}
              />
              <Typography style={{ fontSize: 10 }}>{voucher.voucher_type.brand || 'SwipeRX'}</Typography>
            </Grid>
            <Grid container item xs={6} justify="flex-end">
              <ShowIf condition={showBadge}>
                <Typography
                  style={{
                    background: colors.PURPLE,
                    color: colors.WHITE,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 2,
                    fontSize: 10,
                    borderRadius: 3,
                  }}
                >
                  {voucher.available_voucher && voucher.available_voucher > 1
                    ? `${voucher.available_voucher}x`
                    : voucher.voucher_type.active_end_date
                    ? t('limitedOffers')
                    : null}
                </Typography>{' '}
              </ShowIf>
            </Grid>
          </Grid>

          <Typography
            style={{
              fontSize: 14,
              maxWidth: '100%',
              marginTop: 20,
              lineHeight: 1.5,
              color: 'black',
              height: 135,
              overflowY: 'scroll',
            }}
          >
            {voucher.voucher_type.description}
          </Typography>
        </Typography>

        <Button
          data-testid="apply-voucher-btn"
          variant="contained"
          disabled={!!isVoucherLoading}
          onClick={handleApplyVoucher}
          style={{
            background: isApplied ? colors.RED_HEART : colors.REBRAND_GREEN,
            color: colors.WHITE,
            height: 40,
            boxShadow: 'none',
            borderRadius: 20,
            fontSize: 12,
            fontWeight: 700,
            marginLeft: 30,
            width: `calc(100% - 60px)`,
            marginBottom: 15,
            position: 'absolute',
            bottom: 0,
          }}
        >
          <ShowIf condition={!!isVoucherLoading}>
            <CircularProgress size={30} style={{ color: colors.WHITE }} />
          </ShowIf>
          <ShowIf condition={!isVoucherLoading}>{t(isApplied ? 'nextTime' : 'applyNow')}</ShowIf>
        </Button>
      </div>
    </Modal>
  );
};

export const VoucherDetail = translate('voucher')(VoucherDetailBase);
