import { OrderRequest } from 'store/reducers/Orders';

export const formatOrderCreatePayload = (request: OrderRequest): any => {
  const { distributors } = request;

  const distributorsArray = Object.keys(distributors).map((distributorId) => {
    const productsArray = Object.keys(distributors[distributorId].products).map((productId) => {
      const product = {
        id: productId,
        ...distributors[distributorId].products[productId],
      };
      delete product.flag;
      delete product.stock_status;
      delete product.marketing_id;
      return product;
    });

    return {
      id: distributorId,
      products: productsArray,
      name: distributors[distributorId].name,
      net: distributors[distributorId].net,
    };
  });

  return {
    distributors: distributorsArray,
  };
};
