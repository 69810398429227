/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import keyBy from 'lodash/keyBy';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { Product } from 'store/reducers/Product';
import { useWindowSize } from 'hooks/useWindowSize';

import { ImgWrapper, Img } from './style';

interface Props {
  product: Product;
}

export const ProductImage: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { width } = useWindowSize();
  const { product } = props;
  const { name, images: imgColumn } = product;
  const { images } = imgColumn || {};
  const { display_photo, thumbnail_photo } = product;
  const { original, display, thumb } = keyBy(images, ({ version_name }) => version_name);

  const displayImage = display?.url || display_photo || original?.url || thumb?.url || thumbnail_photo || undefined;

  const [grayed, setGrayed] = useState<boolean>(!displayImage);

  const imgWidth = width ? `${width}px` : '300px';

  const handleFallback = (): void => {
    setGrayed(true);
  };

  useEffect(() => {
    setGrayed(!displayImage);
  }, [displayImage]);

  return (
    <ImgWrapper width={imgWidth} grayed={grayed} key={name}>
      <TransformWrapper
        defaultScale={0.9}
        options={{
          limitToBounds: true,
          limitToWrapper: true,
        }}
        wheel={{
          wheelEnabled: false,
        }}
      >
        <TransformComponent>
          <Img grayed={grayed} width={imgWidth} src={displayImage} onError={handleFallback} />
        </TransformComponent>
      </TransformWrapper>
    </ImgWrapper>
  );
};
