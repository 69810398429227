import { ListMeta, LoyaltyHistoryGroup } from 'services';
import {
  LoyaltyProgramInfo,
  LoyaltyReward,
  LoyaltyRewardType,
  LoyaltyRegistration,
  LoyaltyPotentialProductPoints,
  LoyaltyRewardPreviewType,
  LoyaltyRewardsQueryParams,
  LoyaltyRewardCategoryItem,
  LoyaltyPoints,
  LoyaltyPointsInProgress,
  LoyaltyMissions,
  LoyaltyMissionInfo,
  LoyaltyMissionCompleted,
} from './loyalty-program.inteface';

export enum LoyaltyProgramActionTypes {
  SET_LOYALTY_PROGRAM_LOADING = '@@loyalty-program/SET_LOYALTY_PROGRAM_LOADING',
  SET_LOYALTY_PROGRAM_INFO = '@@loyalty-program/SET_LOYALTY_PROGRAM_INFO',
  SET_LOYALTY_PROGRAM_ERROR = '@@loyalty-program/SET_LOYALTY_PROGRAM_ERROR',
  SET_LOYALTY_HISTORY_LOADING = '@@loyalty-program/SET_LOYALTY_HISTORY_LOADING',
  SET_LOYALTY_HISTORY_LIST = '@@loyalty-program/SET_LOYALTY_HISTORY_LIST',
  RESET_LOYALTY_HISTORY_LIST = '@@loyalty-program/RESET_LOYALTY_HISTORY_LIST',
  SET_LOYALTY_REWARDS_LOADING = '@@loyalty-program/SET_LOYALTY_REWARDS_LOADING',
  SET_LOYALTY_REWARDS_QUERIES = '@@loyalty-program/SET_LOYALTY_REWARDS_QUERIES',
  SET_LOYALTY_REWARDS_LIST = '@@loyalty-program/SET_LOYALTY_REWARDS',
  RESET_LOYALTY_REWARDS_LIST = '@@loyalty-program/RESET_LOYALTY_REWARDS_LIST',
  SET_LOYALTY_REWARD_INFO = '@@loyalty-program/SET_LOYALTY_REWARD',
  SET_LOYALTY_REWARD_INFO_LOADING = '@@loyalty-program/SET_LOYALTY_REWARD_INFO_LOADING',
  SET_LOYALTY_REWARD_REDEEM = '@@loyalty-program/SET_LOYALTY_REWARD_REDEEM',
  SET_LOYALTY_PROGRAM_CALCULATING_POINTS = '@@loyalty-program/SET_LOYALTY_PROGRAM_CALCULATING_POINTS',
  SET_LOYALTY_PROGRAM_DEDUCTED_POINTS = '@@loyalty-program/SET_LOYALTY_PROGRAM_DEDUCTED_POINTS',
  SET_LOYALTY_PROGRAM_REDEEMED_POINTS = '@@loyalty-program/SET_LOYALTY_PROGRAM_REDEEMED_POINTS',
  SET_LOYALTY_PROGRAM_EXPIRING_POINTS = '@@loyalty-program/SET_LOYALTY_PROGRAM_EXPIRING_POINTS',
  SET_LOYALTY_PROGRAM_BONUS_POINTS = '@@loyalty-program/SET_LOYALTY_PROGRAM_BONUS_POINTS',
  SET_LOYALTY_BANNER_DATA = '@@loyalty-program/SET_LOYALTY_BANNER_DATA',
  SET_LOYALTY_REGISTRATION_DATA = '@@loyalty-program/SET_LOYALTY_REGISTRATION_DATA',
  SET_LOYALTY_POTENTIAL_POINTS = '@@loyalty-program/SET_LOYALTY_POTENTIAL_POINTS',
  SET_LOYALTY_REDEEM_FAILED = '@@loyalty-program/SET_LOYALTY_REDEEM_FAILED',
  SET_LOYALTY_REDEEM_LOADING = '@@loyalty-program/SET_LOYALTY_REDEEM_LOADING',
  SET_LOYALTY_REWARD_CATEGORIES = '@@loyalty-program/SET_LOYALTY_REWARD_CATEGORIES',
  SET_LOYALTY_REWARD_CATEGORY = '@@loyalty-program/SET_LOYALTY_REWARD_CATEGORY',
  SET_LOYALTY_REWARD_CATEGORY_LOADING = '@@loyalty-program/SET_LOYALTY_REWARD_CATEGORY_LOADING',
  SET_LOYALTY_PREVIEW_REWARDS_LOADING = '@@loyalty-program/SET_LOYALTY_PREVIEW_REWARDS_LOADING',
  SET_LOYALTY_PREVIEW_REWARDS_LIST = '@@loyalty-program/SET_LOYALTY_PREVIEW_REWARDS_LIST',
  SET_LOYALTY_PREVIEW_REWARDS_ERROR = '@@loyalty-program/SET_LOYALTY_PREVIEW_REWARDS_ERROR',
  SET_LOYALTY_PREVIEW_MY_REWARDS_LOADING = '@@loyalty-program/SET_LOYALTY_PREVIEW_MY_REWARDS_LOADING',
  SET_LOYALTY_PREVIEW_MY_REWARDS_LIST = '@@loyalty-program/SET_LOYALTY_PREVIEW_MY_REWARDS_LIST',
  SET_LOYALTY_PREVIEW_MY_REWARDS_ERROR = '@@loyalty-program/SET_LOYALTY_PREVIEW_MY_REWARDS_ERROR',
  SET_LOYALTY_POINTS_TO_MAINTAIN = '@@loyalty-program/SET_LOYALTY_POINTS_TO_MAINTAIN',
  SET_LOYALTY_POINTS_TO_UPGRADE = '@@loyalty-program/SET_LOYALTY_POINTS_TO_UPGRADE',
  SET_LOYALTY_POINTS_IN_PROGRESS = '@@loyalty-program/SET_LOYALTY_POINTS_IN_PROGRESS',
  SET_LOYALTY_MISSIONS_LIST = '@@loyalty-program/SET_LOYALTY_MISSIONS_LIST',
  SET_LOYALTY_MISSION_INFO = '@@loyalty-program/SET_LOYALTY_MISSION_INFO',
  SET_LOYALTY_MISSIONS_BANNER = '@@loyalty-program/SET_LOYALTY_MISSIONS_BANNER',
  SET_LOYALTY_TOTAL_ACTIVE_MISSIONS = '@@loyalty-program/SET_LOYALTY_TOTAL_ACTIVE_MISSIONS',
  SET_LOYALTY_CONFIG = '@@loyalty-program/SET_LOYALTY_CONFIG',
  SET_LOYALTY_COMPLETED_MISSIONS = '@@loyalty-program/SET_LOYALTY_COMPLETED_MISSIONS',
}

export interface SetLoyaltyProgramLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_LOADING;
  payload: { loading: boolean };
}

export interface SetLoyaltyProgramError {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_ERROR;
  payload: { error: string | null };
}

export interface SetLoyaltyHistoryLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_HISTORY_LOADING;
  payload: { loading: boolean };
}

export interface SetLoyaltyHistoryList {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_HISTORY_LIST;
  payload: { data: LoyaltyHistoryGroup[]; meta: ListMeta };
}

export interface ResetLoyaltyHistoryList {
  type: LoyaltyProgramActionTypes.RESET_LOYALTY_HISTORY_LIST;
}

export interface SetLoyaltyProgramInfo {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_INFO;
  payload: { data: LoyaltyProgramInfo };
}

export interface SetLoyaltyRewardsLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_LOADING;
  payload: { loading: boolean };
}

export interface SetLoyaltyRewardsQueries {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_QUERIES;
  payload: { queries: LoyaltyRewardsQueryParams };
}

export interface SetLoyaltyRewardsList {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_LIST;
  payload: { data: LoyaltyReward[]; meta: ListMeta; type: LoyaltyRewardType };
}

export interface ResetLoyaltyRewardsList {
  type: LoyaltyProgramActionTypes.RESET_LOYALTY_REWARDS_LIST;
  payload: { all?: boolean | undefined };
}

export interface SetLoyaltyRewardInfo {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_INFO;
  payload: { data: LoyaltyReward };
}
export interface SetLoyaltyRewardInfoLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_INFO_LOADING;
  payload: { loading: boolean };
}

export interface SetLoyaltyRewardRedeem {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_REDEEM;
  payload: { data: LoyaltyReward };
}

export interface SetLoyaltyInfoCalculatingPoints {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_CALCULATING_POINTS;
  payload: { calculating: boolean; key?: string | undefined };
}

export interface SetLoyaltyInfoDeductedPoints {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_DEDUCTED_POINTS;
  payload: { points: number };
}
export interface SetLoyaltyInfoRedeemedPoints {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_REDEEMED_POINTS;
  payload: { points: number };
}
export interface SetLoyaltyInfoExpiringPoints {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_EXPIRING_POINTS;
  payload: { points: number };
}

export interface SetLoyaltyInfoBonusPoints {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_BONUS_POINTS;
  payload: { points: number };
}

export interface SetLoyaltyBannerData {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_BANNER_DATA;
  payload: {
    registered: boolean;
    enabled: boolean;
    tier: string;
    points: number;
    pendingPoints: number;
    displayPendingPoint: boolean;
    displayMission: boolean;
    displayWelcomeMission: boolean;
    totalActiveMissions: number;
    cycle: number;
  };
}

export interface SetLoyaltyRegistrationData {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REGISTRATION_DATA;
  payload: {
    registered: boolean;
    registration: LoyaltyRegistration;
    termOfUseURL?: string | undefined;
    privacyPolicyURL?: string | undefined;
  };
}

export interface SetLoyaltyPotentialPoints {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_POTENTIAL_POINTS;
  payload: {
    potentialPoints: number;
    potentialPointsDisabled: boolean;
    potentialProductPoints?: LoyaltyPotentialProductPoints | undefined;
  };
}

export interface SetLoyaltyRedeemFailed {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REDEEM_FAILED;
  payload: { redeemFailed: string | null };
}

export interface SetLoyaltyRedeemLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REDEEM_LOADING;
  payload: { loading: boolean };
}

export interface SetLoyaltyRewardCategoryLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORY_LOADING;
  payload: { loading: boolean };
}
export interface SetLoyaltyRewardCategories {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORIES;
  payload: { data: LoyaltyRewardCategoryItem[] };
}
export interface SetLoyaltyRewardCategory {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORY;
  payload: { selected: LoyaltyRewardCategoryItem | null };
}

export interface SetLoadingLoyaltyPreviewPopularRewards {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_LOADING;
  payload: { loading: boolean };
}

export interface SetLoyaltyPreviewRewardsLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_LOADING;
  payload: { type: LoyaltyRewardPreviewType; loading: boolean };
}
export interface SetLoyaltyPreviewRewardsList {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_LIST;
  payload: { type: LoyaltyRewardPreviewType; data: LoyaltyReward[] };
}
export interface SetLoyaltyPreviewRewardsError {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_ERROR;
  payload: { type: LoyaltyRewardPreviewType; error: string };
}

export interface SetLoyaltyPreviewMyRewardsLoading {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_MY_REWARDS_LOADING;
  payload: { loading: boolean };
}
export interface SetLoyaltyPreviewMyRewardsList {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_MY_REWARDS_LIST;
  payload: { data: LoyaltyReward[] };
}
export interface SetLoyaltyPreviewMyRewardsError {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_MY_REWARDS_ERROR;
  payload: { error: string };
}

export interface SetLoyaltyPointsToMaintain {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_TO_MAINTAIN;
  payload: LoyaltyPoints;
}
export interface SetLoyaltyPointsToUpgrade {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_TO_UPGRADE;
  payload: LoyaltyPoints;
}

export interface SetLoyaltyPointsInProgress {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_IN_PROGRESS;
  payload: LoyaltyPointsInProgress;
}
export interface SetLoyaltyMissionsList {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_LIST;
  payload: LoyaltyMissions;
}
export interface SetLoyaltyMissionInfo {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSION_INFO;
  payload: { loading: boolean; data?: LoyaltyMissionInfo; error?: string };
}
export interface SetLoyaltyMissionsBanner {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_BANNER;
  payload: string[];
}
export interface SetLoyaltyTotalActiveMissions {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_TOTAL_ACTIVE_MISSIONS;
  payload: number;
}
export interface SetLoyaltyConfig {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_CONFIG;
  payload: {
    displayWelcomeMission: boolean;
  };
}
export interface SetLoyaltyCompletedMissions {
  type: LoyaltyProgramActionTypes.SET_LOYALTY_COMPLETED_MISSIONS;
  payload: LoyaltyMissionCompleted[];
}

export type LoyaltyProgramActions =
  | SetLoyaltyProgramLoading
  | SetLoyaltyProgramError
  | SetLoyaltyHistoryLoading
  | SetLoyaltyHistoryList
  | ResetLoyaltyHistoryList
  | SetLoyaltyProgramInfo
  | SetLoyaltyRewardsList
  | ResetLoyaltyRewardsList
  | SetLoyaltyRewardInfo
  | SetLoyaltyRewardInfoLoading
  | SetLoyaltyRewardRedeem
  | SetLoyaltyInfoCalculatingPoints
  | SetLoyaltyInfoDeductedPoints
  | SetLoyaltyInfoRedeemedPoints
  | SetLoyaltyInfoBonusPoints
  | SetLoyaltyInfoExpiringPoints
  | SetLoyaltyBannerData
  | SetLoyaltyRegistrationData
  | SetLoyaltyPotentialPoints
  | SetLoyaltyRedeemFailed
  | SetLoyaltyRedeemLoading
  | SetLoyaltyRewardCategoryLoading
  | SetLoyaltyRewardCategories
  | SetLoyaltyRewardCategory
  | SetLoyaltyPreviewRewardsLoading
  | SetLoyaltyPreviewRewardsList
  | SetLoyaltyPreviewRewardsError
  | SetLoyaltyPreviewMyRewardsLoading
  | SetLoyaltyPreviewMyRewardsList
  | SetLoyaltyPreviewMyRewardsError
  | SetLoyaltyRewardsLoading
  | SetLoyaltyRewardsQueries
  | SetLoyaltyPointsToMaintain
  | SetLoyaltyPointsToUpgrade
  | SetLoyaltyPointsInProgress
  | SetLoyaltyMissionsList
  | SetLoyaltyMissionInfo
  | SetLoyaltyMissionsBanner
  | SetLoyaltyTotalActiveMissions
  | SetLoyaltyConfig
  | SetLoyaltyCompletedMissions;
