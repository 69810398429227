export const getNumberEnvWithDefault = (envValue: string | undefined, defaultValue: number): number => {
  let value = defaultValue;

  if (typeof envValue === 'string' && envValue.length > 0) {
    // FIXME: SPT-18260: simplify after this ticket is fixed
    const parsedValue = Number.parseFloat(envValue);
    if (parsedValue > 0) {
      value = parsedValue;
    }
  } else if (typeof envValue === 'number' && envValue > 0) {
    value = envValue;
  }
  return value;
};
