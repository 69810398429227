import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  height: auto;
  padding: 8px;
`;
