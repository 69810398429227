import { IMarketing } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { useDispatch } from 'react-redux';
import { fetchCampaignSearchMarketingData } from 'store/reducers/CampaignSearchMarketing/actions';
import { useEffect } from 'react';
import { PharmacySearchSquareResDto } from 'store/reducers/CampaignSearchMarketing/types';
import {
  LOCAL_CAMPAIGN_SEARCH_MARKETING_DATA_KEY,
  LOCAL_CAMPAIGN_SEARCH_MARKETING_KEY,
} from 'store/reducers/CampaignSearchMarketing/constants';
import { useReadLocalStorage } from './usehooks-ts';

export const useCampaignSearchMarketing = () => {
  const marketing = useReadLocalStorage<IMarketing | undefined>(LOCAL_CAMPAIGN_SEARCH_MARKETING_KEY);

  const data = useReadLocalStorage<PharmacySearchSquareResDto | undefined>(LOCAL_CAMPAIGN_SEARCH_MARKETING_DATA_KEY);

  const dispatch = useDispatch();

  useEffect(() => {
    if (marketing && marketing.is_published) {
      dispatch(fetchCampaignSearchMarketingData(marketing.id));
    }
    // prevent multiple reload since data from `useReadLocalStorage` causes re-fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketing?.is_published, marketing?.id]);

  // TODO: make marketing data load very fast at the start of the app
  if (marketing && marketing.is_published) {
    return { marketing, data };
  }

  return { marketing: null, data: undefined };
};
