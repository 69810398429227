import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography } from '@material-ui/core';
import * as colors from 'components/styles/colors';

const Edit = styled(Card)`
  background: rgba(0, 0, 0, 0.8) !important;
  min-height: 100vh;
`;

const Media = styled(CardMedia)`
  height: 80vh;
  margin: 12px;
  background-size: contain !important;
  background-position: top !important;
`;

const Action = styled.div`
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  height: 30px;
  width: 106px;
  padding: 6px 10px;
  display: flex;

  & span {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
  }
`;

const DetailTitle = styled(Typography)`
  && {
    margin: 12px;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.WEAK_GRAY};
  }
`;

const DetailContent = styled(Typography)`
  && {
    margin: 12px;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.WEAK_GRAY};
  }
`;

export const S = {
  Edit,
  Media,
  Action,
  DetailTitle,
  DetailContent,
};
