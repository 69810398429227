import {
  ActivityStatus,
  DOCUMENT_REQUIREMENT_TYPE_ALKES,
  DOCUMENT_REQUIREMENT_TYPE_PREKURSOR,
  DOCUMENT_REQUIREMENT_TYPE_REGULAR,
  LogisticDeliveryStatus,
  OrderStatus,
  PharmacyOrderStatus,
} from 'services';

export const PRIMARY_COLOR = '#14C47D';
export const LIGHT_PRIMARY_COLOR = '#DEF3EF';
export const DARK_PRIMARY_COLOR = '#44BA8B';
export const DARKER_PRIMARY_COLOR = '#2D9B6F';
export const SECONDARY_COLOR = '#FFCC66';
export const STAR_COLOR = '#FDD836';
export const DARK_SECONDARY_COLOR = '#71592D';
export const WHITE = '#FFFFFF';
export const OFF_WHITE = '#f9f9f9';
export const LIGHT_GRAY = '#999';
export const WARNING_GRAY = '#A1A7B6';
export const MEDIUM_GRAY = '#555';
export const REGULAR_GRAY = '#717785';
export const GRAY = '#666666';
export const DIMMED_WHITE = '#ececec';
export const DARK_GRAY = '#212121';
export const GREEN_CYAN = '#53CE9C';
export const GREEN = '#4CB318';
export const PINK_RED = '#E35A68';
export const RED = '#E80810';
export const RED_HEART = '#EB5254';
export const MIDNIGHT_BLUE = '#4F5B74';
export const BLUE = '#1733E8';
export const MEDIUM_LIGHT_BLUE = '#5D70EF';
export const TEXT = '#888888';
export const DARK_TEXT = '#333333';
export const TEXT_LIGHT = '#181D29';
export const LIGHT_RED = '#FFEEEF';
export const LIGHT_BLUE = '#ECF4F7';
export const JUICY_BLUE = '#1e74e9';
export const TRANSPARENT = 'rgba(0,0,0,0)';
export const WHITE_SMOKE = '#F8F8F8';
export const GRAY90 = '#E5E5E5';
export const GRAY615 = '#E4E7ED';
export const GRAY50 = '#CCCCCC';
export const GRAY31 = '#4F4F4F';
export const CYAN = '#6FCFB8';
export const SNOW = '#f9f9f9';
export const BLUISH_GRAY_LIGHTER = '#8C96AD';
export const BLUISH_GRAY = '#8792A8';
export const BLUISH_GRAY_LIGHT = '#717786';
export const BLUISH_GRAY_LIGHTEST = '#F3F6FE';
export const RIBBON_TEXT = '#FFF';
export const DANGER_DARKER = '#EE3245';
export const DANGER_DARK = '#FC4747';
export const DANGER = '#EB5757';
export const DANGER_LIGHT = '#FFEFEF';
export const REDISH_GRAY = '#FEF0F0';
export const SUBTLE_GRAY = '#ECEEF1';
export const WEAK_GRAY = '#F3F3F3';
export const LIGHTER_GRAY = '#F5F5F5';
export const ORANGE = '#FF7F30';
export const ORANGE_LIGHT = '#FFF8E1';
export const REBRAND_GREEN = '#14C47D';
export const REBRAND_DARK_BLUE = '#031B4E';
export const BLACK = '#000000';
export const PURPLE = '#C259B7';
export const PURPLE_DARK = '#CC59C1';
export const PURPLE_LIGHT = '#CC59C133';
export const MAGENTA = '#DB8BD4';
export const BLACK_25 = 'rgba(0, 0, 0, 0.25)';
export const TINT_GRAY = '#c2c2c2';
export const LIGHT_CHARCOAL = '#9f9f9f';

export const NORMAL_CARD = {
  lightest: '#F0FAF8',
  light: '#C2E9E0',
  selected: '#44BA8B',
  normal: '#44BA8B',
  dark: '#14C47D',
  ribbon: '#1733E8',
  secondary: {
    lightest: '#F4F5FC',
    light: '#FFF',
    selected: '#14C47D',
    normal: '#181D29',
    dark: '#1733E8',
    ribbon: 'rgba(23, 51, 232, 0.9)',
  },
};

export const PROMO_CARD = {
  lightest: '#F4F5FC',
  light: '#C2E9E0',
  selected: '#44BA8B',
  normal: '#44BA8B',
  dark: '#FF7F30',
  ribbon: '#1733E8',
  secondary: {
    lightest: '#FFF0E7',
    light: '#FF7F30',
    selected: '#14C47D',
    normal: '#181D29',
    dark: '#FF7F30',
    ribbon: '#FF9655',
  },
};

export const LOYALTY_POINT = {
  light: '#FFF8E1',
  dark: '#9B7508',
  secondary: {
    light: '#FFECE1',
    dark: '#9B2C08',
  },
};

export const getOrderStatusColor = (
  props: {
    status: OrderStatus | PharmacyOrderStatus;
    deliverystatus?: LogisticDeliveryStatus | null;
    documentType?: string;
  },
  type: 'primary' | 'secondary' | 'default' = 'default',
): string => {
  const onProcessing = {
    primary: '#DB8BD4',
    secondary: '#CC59C1',
  };
  const onDeliveryColor = {
    primary: '#5D70EF',
    secondary: '#1733E8',
  };
  const deliveredColor = {
    primary: '#53CE9C',
    secondary: '#40AE96',
  };
  const failedColor = {
    primary: '#E35A68',
    secondary: '#AE2836',
  };

  const colors = {
    [OrderStatus.PENDING]: {
      primary: '#FFA56E',
      secondary: '#FF7F30',
    },
    [OrderStatus.ACCEPTED]: onProcessing,
    [OrderStatus.PROCESSING]: onProcessing,
    [PharmacyOrderStatus.ORDER_RECEIVED]: {
      primary: '#FFA56E',
      secondary: '#FF7F30',
    },
    [PharmacyOrderStatus.PAID]: {
      primary: '#FFA56E',
      secondary: '#FF7F30',
    },
    [PharmacyOrderStatus.UNPAID]: {
      primary: '#FFA56E',
      secondary: '#FF7F30',
    },
    [PharmacyOrderStatus.WAITING_DOCUMENT]: {
      primary: '#FFA56E',
      secondary: '#FF7F30',
    },
    [PharmacyOrderStatus.PREPARING_GOODS]: {
      primary: '#DB8BD4',
      secondary: '#CC59C1',
    },
    [PharmacyOrderStatus.OUT_OF_DELIVERY]: onDeliveryColor,
    [PharmacyOrderStatus.DELIVERED]: deliveredColor,
    [PharmacyOrderStatus.COMPLETED]: deliveredColor,
    [PharmacyOrderStatus.CANCELLED]: failedColor,
    [PharmacyOrderStatus.DELAYED]: {
      primary: '#DB8BD4',
      secondary: '#CC59C1',
    },
    [PharmacyOrderStatus.CANCELLED_FULL_RETURN]: failedColor,
    [OrderStatus.DISPATCHED]: onDeliveryColor,
    [LogisticDeliveryStatus.DELIVERY_COURIER_ASSIGNED]: onDeliveryColor,
    [LogisticDeliveryStatus.DELIVERY_COURIER_DEPARTED]: onDeliveryColor,
    [LogisticDeliveryStatus.DELIVERY_PICKED_UP]: onDeliveryColor,
    [LogisticDeliveryStatus.DELIVERY_ONGOING]: onDeliveryColor,
    [LogisticDeliveryStatus.DELIVERY_COURIER_ARRIVED]: onDeliveryColor,
    [LogisticDeliveryStatus.DELIVERY_DELIVERED]: deliveredColor,
    [LogisticDeliveryStatus.DELIVERY_DELAYED]: onDeliveryColor,
    [LogisticDeliveryStatus.DELIVERY_FAILED]: failedColor,
    [LogisticDeliveryStatus.DELIVERY_CANCELED]: failedColor,
    default: TEXT_LIGHT,
  };

  const selectedStatus =
    props.status === PharmacyOrderStatus.OUT_OF_DELIVERY && !!props.deliverystatus
      ? props.deliverystatus
      : props.status;

  if (!!props.documentType && !!!props.deliverystatus && type === 'secondary') {
    const documentTypeColors = {
      [DOCUMENT_REQUIREMENT_TYPE_ALKES]: '#717785',
      [DOCUMENT_REQUIREMENT_TYPE_PREKURSOR]: '#FF7F30',
      [DOCUMENT_REQUIREMENT_TYPE_REGULAR]: '#717785',
    };
    return documentTypeColors[props.documentType];
  }
  return colors?.[selectedStatus]?.[type] ?? colors.default;
};

export const getOrderActivityColor = (activity: ActivityStatus): string => {
  const colors = {
    [ActivityStatus.PENDING]: '#FF7F30',
    [ActivityStatus.ACCEPTED]: '#CC59C1',
    [ActivityStatus.PROCESSING]: '#CC59C1',
    [ActivityStatus.DELAYED]: '#CC59C1',
    [ActivityStatus.ORDER_CHANGED]: '#CC59C1',
    [ActivityStatus.DISPATCHED]: '#1733E8',
    [ActivityStatus.DELIVERED]: '#40AE96',
    [ActivityStatus.CANCELLED]: '#AE2836',
    default: '#B3B8C8',
  };
  return colors?.[activity] ?? colors.default;
};

export const backgrounds = {
  LIGHT_PRIMARY_COLOR_BG_10: 'rgba(20, 196, 109, 0.1)', // #14C46D 10%
};
