import React from 'react';
import * as colors from 'components/styles/colors';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

export const HomeIconFilled: React.FC<React.PropsWithChildren<Props>> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 17"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z" />
  </svg>
);

HomeIconFilled.defaultProps = {
  fill: colors.PRIMARY_COLOR,
  width: '21px',
  height: '21px'
};
