export enum VoucherRuleError {
  USAGE_LIMIT_ERROR = 'USAGE_LIMIT_ERROR',
  LIMIT_PER_CUSTOMER_ERROR = 'LIMIT_PER_CUSTOMER_ERROR',
  QUANTITY_RANGE_ERROR = 'QUANTITY_RANGE_ERROR',
  AMOUNT_RANGE_ERROR = 'AMOUNT_RANGE_ERROR',
  SELECTED_PHARMACIES_ERROR = 'SELECTED_PHARMACIES_ERROR',
  SELECTED_DISTRIBUTORS_ERROR = 'SELECTED_DISTRIBUTORS_ERROR',
  SELECTED_DISTRIBUTOR_PRODUCTS_ERROR = 'SELECTED_DISTRIBUTOR_PRODUCTS_ERROR',
  SELECTED_PRODUCTS_ERROR = 'SELECTED_PRODUCTS_ERROR',
  PAYMENT_METHOD_ERROR = 'PAYMENT_METHOD_ERROR',
  OPEN_LOYALTY_ENROLLED_ERROR = 'OPEN_LOYALTY_ENROLLED_ERROR',
  OPEN_LOYALTY_POINT_SUFFICIENT_ERROR = 'OPEN_LOYALTY_POINT_SUFFICIENT_ERROR',
  OPEN_LOYALTY_VOUCHER_REGISTERED_ERROR = 'OPEN_LOYALTY_VOUCHER_REGISTERED_ERROR',
  INVALID_VOUCHER_ERROR = 'INVALID_VOUCHER_ERROR',
  ANY_PENDING_PAYMENT_ERROR = 'ANY_PENDING_PAYMENT_ERROR',
  INVALID_VOUCHER_RULES_ERROR = 'INVALID_VOUCHER_RULES_ERROR',
  VOUCHER_NOT_FOUND_ERROR = 'VOUCHER_NOT_FOUND_ERROR',
}
