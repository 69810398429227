import * as React from 'react';
import styled from 'styled-components';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';

import * as colors from '../../styles/colors';
import ShowIf from '../../views/ShowIf';
import { TranslateProps } from '../../../utils/Localization/types';

export interface Props {
  message?: string;
  variant?: 'success' | 'error';
  onClose?: () => void;
  onRetry?: () => void;
  vertical?: SnackbarOrigin['vertical'];
  horizontal?: SnackbarOrigin['horizontal'];
}

const NotifWrapper = styled(Snackbar)`
  .retry-button {
    color: ${colors.WHITE};
  }

  .close-button {
    color: ${colors.WHITE};
  }
`;

const NotifContent = styled(SnackbarContent)`
  && {
    background: ${colors.DARK_GRAY};

    &.success {
      background: ${colors.GREEN};
    }

    &.error {
      background: ${colors.DARK_GRAY};
    }

    &.red-error {
      background: ${colors.RED};
    }

    &.warning {
      background: ${colors.ORANGE};
    }
  }
`;

export const Notification: React.FunctionComponent<React.PropsWithChildren<Props & TranslateProps>> = (props) => {
  const { message, variant, onClose, onRetry, t, vertical = 'bottom', horizontal = 'center' } = props;

  return (
    <NotifWrapper anchorOrigin={{ vertical, horizontal }} open={typeof message !== 'undefined'} onClose={onClose}>
      <NotifContent
        data-testid="notification-message"
        className={variant || ''}
        message={<span>{message}</span>}
        action={
          <>
            <ShowIf condition={typeof onRetry !== 'undefined'}>
              <Button key="retry" className="retry-button" onClick={onRetry}>
                {t('notification.retry')}
              </Button>
            </ShowIf>
            <ShowIf condition={typeof onClose !== 'undefined'}>
              <Button key="retry" className="retry-button" onClick={onClose}>
                {t('notification.close')}
              </Button>
            </ShowIf>
          </>
        }
      />
    </NotifWrapper>
  );
};
