import React from 'react';
import {
  Container,
  ItemContainer,
  LabelContainer,
  DiscountLabel,
  Label,
  TagIcon,
} from './style';

interface ItemProps {
  discount: string;
  minimum: string;
}

interface CarouselProps {
  promotions: Array<ItemProps>;
}

const PromoItem: React.FC<React.PropsWithChildren<ItemProps>> = (props) => {
  const { discount, minimum } = props;
  return (
    <ItemContainer>
      <TagIcon />
      <LabelContainer>
        <DiscountLabel>{discount}</DiscountLabel>
        <Label>{minimum}</Label>
      </LabelContainer>
    </ItemContainer>
  );
}

export const PromoCarousel: React.FC<React.PropsWithChildren<CarouselProps>> = (props) => {
  const carouselSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    centerMode: false,
    swipeToSlide: true,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { promotions } = props; 

  return (
    <Container {...carouselSettings}>
      {promotions.map(p => (
        <PromoItem key={p.minimum} {...p} />
      ))}
    </Container>
  );
}
