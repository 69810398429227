import * as Redux from 'redux';
import { Actions } from './actions';
import { DepositRequestActionTypes } from './constants';
import { DepositRequest, IDepositRequestLatestProcessed } from './types';

export interface State {
  loading: boolean;
  active: DepositRequest | null;
  latestProcessed: IDepositRequestLatestProcessed | null;
  error: string | null;
  errorCode: string | null;
}

const InitialState: State = {
  loading: false,
  active: null,
  latestProcessed: null,
  error: null,
  errorCode: null,
};

export const DepositRequestReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case DepositRequestActionTypes.FETCH_ACTIVE_DEPOSIT_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
        errorCode: null,
        active: action.payload,
      };

    case DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case DepositRequestActionTypes.CLEAR_ACTIVE_DEPOSIT_REQUEST:
      return {
        ...state,
        error: null,
        errorCode: null,
        active: null,
      };

    case DepositRequestActionTypes.SET_ACTIVE_DEPOSIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
        active: null,
      };

    case DepositRequestActionTypes.FETCH_LATEST_PROCESSED_DEPOSIT_REQUEST:
      return {
        ...state,
        latestProcessed: action.payload,
      };

    default:
      return state;
  }
};

export default DepositRequestReducer;
