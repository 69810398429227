import React, { useEffect, useState } from 'react';

import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Trans, translate } from 'react-i18next';
import { connect } from 'react-redux';

import { Modal } from 'components/common';
import ShowIf from 'components/views/ShowIf';
import { InvoiceStatus, swipeRxPt } from 'services';
import { DepositTypeEnum, IReturn } from 'services/swipe-rx-pt/resources';
import { State as StoreState } from 'store';
import { fetchInvoiceAllocations } from 'store/reducers/Payment/actions';
import { toCurrency } from 'utils/Format';
import { getCallCenterNumber } from 'utils/MarketConfig';

import { S } from './DepositDetailModal.styles';
import { Props, StateProps } from './interface';

const DepositDetailModalBase: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { open, onClose, t, selectedDeposit, auth, fetchInvoiceAllocations, data, handleOpenOrderDetailsModal } = props;
  const [returns, setReturns] = useState<IReturn | null>(null);

  const user: any = auth.coreUser;
  const pharmacy_id = user.organization_id;
  const callCenterNumber = getCallCenterNumber();

  const fetchReturns = async (): Promise<void> => {
    try {
      const result = await swipeRxPt.returns.getByDepositId(selectedDeposit?.id as number);

      setReturns(result.data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    if (selectedDeposit?.id && data.length > 0) {
      fetchInvoiceAllocations(
        selectedDeposit.id,
        {
          pharmacy_id,
          page: 1,
        },
        false,
      );
    }
    if (selectedDeposit?.id && data.length > 0 && selectedDeposit.type === DepositTypeEnum.REFUND) {
      fetchReturns();
    } else {
      setReturns(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeposit?.id, data]);

  useEffect(
    () => () => {
      setReturns(null);
    },
    [],
  );

  const invoiceAllocations = data.find((deposit) => deposit.id === selectedDeposit?.id)?.allocations.invoices || [];

  return (
    <Modal
      open={
        open && !!selectedDeposit && [DepositTypeEnum.DEPOSIT, DepositTypeEnum.REFUND].includes(selectedDeposit.type)
      }
      onClose={onClose}
      style={{ zIndex: 100 }}
    >
      <S.Wrapper>
        <S.Header>
          <Grid container alignItems="center" justify="flex-start">
            <Grid item xs={1}>
              <S.BackIcon onClick={onClose} />
            </Grid>
            <Grid item xs>
              <S.Title>{t(`${selectedDeposit?.type}Information`)}</S.Title>
            </Grid>
          </Grid>
        </S.Header>
        <S.Content>
          <S.Container>
            <S.TotalContainer>
              <Grid item xs>
                <ShowIf condition={selectedDeposit?.type === DepositTypeEnum.DEPOSIT}>
                  <S.Label>{t(`totalDeposit`)}</S.Label>
                </ShowIf>
                <ShowIf condition={selectedDeposit?.type === DepositTypeEnum.REFUND}>
                  <S.Label>{t(`totalRefund`)}</S.Label>
                </ShowIf>
              </Grid>
              <Grid item xs>
                <S.Total>{toCurrency(selectedDeposit?.deposited_amount as number)}</S.Total>
              </Grid>
            </S.TotalContainer>

            <ShowIf condition={!!returns}>
              <Grid container style={{ padding: '0px 17px', marginBottom: 12, marginTop: 15 }}>
                <Grid item xs={1}>
                  <S.DetailIcon />
                </Grid>
                <Grid item xs>
                  <S.Label>{t(`creditMemoInformation`)}</S.Label>
                </Grid>
              </Grid>
              <S.DetailContainer>
                <Grid container>
                  <Grid item xs={4}>
                    <S.SubLabel>{t(`creditNoteNumber`)}</S.SubLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <S.Value>
                      <S.Copy text={returns?.credit_memo?.credit_memo_no} message={t(`creditMemoNumberCopied`)} />
                      {returns?.credit_memo?.credit_memo_no}
                    </S.Value>
                  </Grid>
                </Grid>
                <S.Divider />
                <Grid container>
                  <Grid item xs={4}>
                    <S.SubLabel>{t(`invoiceNo`)}</S.SubLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <Box flex={1} textAlign="right">
                      <S.Value>
                        <S.Copy text={returns?.invoice?.invoice_number} message={t(`invoiceNumberCopied`)} />
                        {returns?.invoice?.invoice_number}
                      </S.Value>

                      <S.GhostButton
                        onClick={() => {
                          handleOpenOrderDetailsModal(returns?.invoice?.purchase_order_id as number);
                        }}
                        endIcon={<ChevronRight />}
                      >
                        {t(`see`)}
                      </S.GhostButton>
                    </Box>
                  </Grid>
                </Grid>

                <S.Divider />
                <Grid container justifyContent="center">
                  <Grid item xs>
                    <S.SubLabel>{t(`creditMemoDocument`)}</S.SubLabel>
                  </Grid>
                  <Grid item xs style={{ textAlign: 'right' }}>
                    <S.GhostButton
                      disabled={!returns?.credit_memo?.attachment_url}
                      endIcon={<DownloadIcon />}
                      href={returns?.credit_memo?.attachment_url}
                    >
                      {t(`download`)}
                    </S.GhostButton>
                  </Grid>
                </Grid>
                <ShowIf condition={!returns?.credit_memo?.attachment_url}>
                  <S.MemoCard>
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={1}>
                        <S.AlertIcon />
                      </Grid>
                      <Grid item xs>
                        {t(`creditMemoDocumentInProgress`)}
                      </Grid>
                    </Grid>
                  </S.MemoCard>
                </ShowIf>
              </S.DetailContainer>
            </ShowIf>

            <Grid container style={{ padding: '0px 17px', marginBottom: 12, marginTop: 15 }}>
              <Grid item xs={1}>
                <S.DetailIcon />
              </Grid>
              <Grid item xs>
                <S.Label>{t(`${selectedDeposit?.type}AllocatedTo`)}</S.Label>
              </Grid>
            </Grid>

            <S.DetailContainer>
              <ShowIf condition={invoiceAllocations.length === 0}>
                <S.EmptyStateContainer>
                  <S.EmptyStateImage />
                  <S.EmptyStateTitle> {t('noInvoiceToDisplay')}</S.EmptyStateTitle>
                  <S.EmptyStateSubTitle> {t(`${selectedDeposit?.type}WillBeAllocated`)}</S.EmptyStateSubTitle>
                  <S.GoBack onClick={onClose}>{t('goBack')}</S.GoBack>
                </S.EmptyStateContainer>
              </ShowIf>

              {invoiceAllocations.map((invoice, index) => (
                <>
                  <Grid container>
                    <Grid item xs>
                      <S.Label>{t(`${invoice.status}`)}</S.Label>
                    </Grid>
                    <Grid item xs>
                      <S.Value
                        onClick={() => {
                          handleOpenOrderDetailsModal(invoice.orderId);
                        }}
                      >
                        {toCurrency(invoice.expected_amount)}
                        <S.PurchaseOrderDetail />
                      </S.Value>
                    </Grid>
                  </Grid>
                  <ShowIf condition={invoice.status === InvoiceStatus.PARTIAL}>
                    <S.Divider />
                    <Grid container alignContent="space-between">
                      <Grid item xs>
                        <S.SubLabel> {t(`outstandingAmount`)}</S.SubLabel>
                      </Grid>
                      <Grid item xs>
                        <S.Value>{toCurrency(invoice.total_amount)}</S.Value>
                      </Grid>
                    </Grid>
                    <Grid container alignContent="space-between">
                      <Grid item xs>
                        <S.SubLabel> {t(`totalAmount`)}</S.SubLabel>
                      </Grid>
                      <Grid item xs>
                        <S.Value>{toCurrency(invoice.invoiced_amount)}</S.Value>
                      </Grid>
                    </Grid>
                  </ShowIf>
                  <S.Divider />
                  <Grid container alignContent="space-between">
                    <Grid item xs={4}>
                      <S.SubLabel> {t(`invoiceNo`)}</S.SubLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <S.Value>{invoice.invoice_number}</S.Value>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4}>
                      <S.SubLabel> {t(`orderNo`)}</S.SubLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <S.Value>{invoice.po_number}</S.Value>
                    </Grid>
                  </Grid>

                  <ShowIf condition={index < invoiceAllocations.length - 1}>
                    <Divider
                      style={{
                        width: '100vw',
                        height: '7px',
                        padding: '0px',
                        transform: 'translateX(-15px)',
                        backgroundColor: '#F8F8F8',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    />
                  </ShowIf>
                </>
              ))}
            </S.DetailContainer>

            <S.HelpContainer>
              <S.Label>{t(`needHelp`)}</S.Label>
              <S.ContactUs>
                <Trans t={t} i18nKey="contactCustomerService" values={{ customerServiceNumber: callCenterNumber }}>
                  <S.ContactNumber>&nbsp;</S.ContactNumber>
                </Trans>
              </S.ContactUs>
            </S.HelpContainer>
          </S.Container>
        </S.Content>
      </S.Wrapper>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  payment: {
    paid: { data, loading },
  },
}: StoreState): StateProps => ({ auth, data, loading });

const mapDispatchToProps = {
  fetchInvoiceAllocations,
};

export const DepositDetailModal = translate('depositDetail')(
  connect(mapStateToProps, mapDispatchToProps)(DepositDetailModalBase),
);
