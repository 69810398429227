import { Action } from 'redux';

import { recordException } from 'utils/Reporting/Sentry';

import { Actions as GlobalActions, showError, showSuccess } from '../Global/actions';
import { ThunkActionCreator } from '../../../types/thunk';
import * as constants from './constants';
import { State } from '../../index';
import { v3Client } from '../../../utils/Client/v3';
import { DelayedFavoriteRequest, FavoriteRequest, PharmacyProduct } from '.';
import { Product } from '../Product';
import { generateMaxPurchase } from '../ProductListing/actions';

export interface GetFavoritesAction extends Action {
  type: constants.GET_FAVORITES;
  products: PharmacyProduct[];
  limit: number;
  nextPage: number;
  hasMore: boolean;
  initialProductLength: number | null;
}

export interface AddToFavoriteProductAction extends Action {
  type: constants.ADD_TO_FAVORITE_PRODUCT;
  product_id: number;
  favorite_product_id: number;
}

export interface RemoveFromFavoriteProductAction extends Action {
  type: constants.REMOVE_FROM_FAVORITE_PRODUCT;
  products: PharmacyProduct[];
}

export interface ClearFavoriteProducts extends Action {
  type: constants.CLEAR_FAVORITES;
}
export interface AddDelayedFavoriteRequest extends Action {
  type: constants.ADD_DELAYED_FAVOTITE_REQUEST;
  delayedFavoriteReq: DelayedFavoriteRequest[];
}
export interface AddDelayedRemoveFavoriteRequestAction extends Action {
  type: constants.ADD_DELAYED_REMOVE_FAVORITE_REQUEST;
  delayedRemoveFavoriteReq: DelayedFavoriteRequest[];
}

export interface ClearDelayedFavoriteRequestAction extends Action {
  type: constants.CLEAR_DELAYED_FAVORITE_REQUEST;
}

export type Actions =
  | GlobalActions
  | GetFavoritesAction
  | AddToFavoriteProductAction
  | RemoveFromFavoriteProductAction
  | ClearFavoriteProducts
  | AddDelayedFavoriteRequest
  | AddDelayedRemoveFavoriteRequestAction
  | ClearDelayedFavoriteRequestAction;

function distinctById(previous: PharmacyProduct[], current: PharmacyProduct[]): PharmacyProduct[] {
  // makes sure ids are unique
  const mergedSet = [...previous, ...current];
  const merge = mergedSet.reduce((accumulator: PharmacyProduct[], favorite: PharmacyProduct) => {
    const index = accumulator.findIndex(({ id }) => id === favorite.id);
    if (index === -1) {
      accumulator.push(favorite);
    }

    return accumulator;
  }, []);

  return merge;
}

export const addDelayedFavoriteRequest: ThunkActionCreator<Actions, State> =
  (params: DelayedFavoriteRequest) => async (dispatch, getState) => {
    const { productId } = params;
    try {
      const {
        favorites: { delayedFavoriteReq, delayedRemoveFavoriteReq },
      } = getState();

      const isDeleteRequestExist = delayedRemoveFavoriteReq.find((delayed) => delayed.productId === productId);

      if (isDeleteRequestExist) {
        const reqIndex = delayedRemoveFavoriteReq.findIndex((delayed) => delayed.productId === productId);
        delayedRemoveFavoriteReq.splice(reqIndex, 1);
        dispatch({
          type: constants.ADD_DELAYED_REMOVE_FAVORITE_REQUEST,
          delayedRemoveFavoriteReq,
        });
      }

      delayedFavoriteReq.push(params);

      dispatch({ type: constants.ADD_DELAYED_FAVOTITE_REQUEST, delayedFavoriteReq });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

export const addDelayedRemoveFavoriteRequest: ThunkActionCreator<Actions, State> =
  (params: DelayedFavoriteRequest) => async (dispatch, getState) => {
    const { productId } = params;
    try {
      const {
        favorites: { delayedFavoriteReq, delayedRemoveFavoriteReq },
      } = getState();

      const isRequestExist = delayedFavoriteReq.find((delayed) => delayed.productId === productId);

      if (isRequestExist) {
        const reqIndex = delayedFavoriteReq.findIndex((delayed) => delayed.productId === productId);
        delayedFavoriteReq.splice(reqIndex, 1);
        dispatch({
          type: constants.ADD_DELAYED_FAVOTITE_REQUEST,
          delayedFavoriteReq,
        });
      }
      delayedRemoveFavoriteReq.push(params);
      dispatch({
        type: constants.ADD_DELAYED_REMOVE_FAVORITE_REQUEST,
        delayedRemoveFavoriteReq,
      });

      dispatch({ type: constants.ADD_DELAYED_REMOVE_FAVORITE_REQUEST, delayedRemoveFavoriteReq });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

export const clearDelayedFavoriteRequest: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  try {
    dispatch({ type: constants.CLEAR_DELAYED_FAVORITE_REQUEST });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const fetchFavoriteProducts: ThunkActionCreator<Actions, State> =
  (params: FavoriteRequest, callback?: () => void) => async (dispatch, getState) => {
    try {
      const { page, limit: page_size, product_ids } = params;
      // get product list state
      const state = getState().favorites;

      // state for nextPage of infinite scroll
      const nextPage = page ? page + 1 : 1;
      const getResult: any = await v3Client.get('products/search', {
        page,
        page_size,
        product_ids,
        query: '',
        is_favorited: true,
      });
      const favoriteProductList = getResult.data;
      const hasMore = page < getResult.meta.page_count;

      // Merge newly fetched with stored data
      const isValid = nextPage === undefined || nextPage === 1;
      const productData = isValid ? favoriteProductList : distinctById(state.products, favoriteProductList);
      dispatch(generateMaxPurchase(favoriteProductList.filter((product) => product.max_qty_enabled)));

      const initialProductLength = page === 1 ? productData.length : state.initialProductLength;

      dispatch({
        type: constants.GET_FAVORITES,
        products: productData,
        limit: page_size,
        nextPage,
        hasMore,
        initialProductLength,
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      if (error instanceof Error) {
        console.error(error);
        recordException(error, 'fetchFavoriteProducts', { params });
        dispatch(showError(error.message));
      }
    }
  };

export const removeFromFavoriteProduct: ThunkActionCreator<Actions, State> =
  (removedFavoriteProductId: number, productId: number, onSuccess: () => string) => async (dispatch, getState) => {
    try {
      await v3Client.delete(`products/favorites/${removedFavoriteProductId}`);
      const { products: favoriteProductList, limit, hasMore } = getState().favorites;

      // If there are still favorites not yet loaded,
      // and currently shown list is less than the limit,
      // try to repopulate it by fetching more favorites
      if (hasMore && favoriteProductList.length < limit) {
        dispatch(fetchFavoriteProducts(1, limit));
      } else {
        // else if doesn't have any more unloaded data
        // we return new store state
        const filteredProductList = favoriteProductList.filter(
          (product) => product.favorite_product_id !== removedFavoriteProductId,
        );

        dispatch({
          type: constants.REMOVE_FROM_FAVORITE_PRODUCT,
          products: filteredProductList,
        });
      }

      dispatch(
        addDelayedRemoveFavoriteRequest({
          productId,
          favoriteId: removedFavoriteProductId,
        }),
      );
      if (onSuccess) {
        const message = onSuccess();
        dispatch(showSuccess(message));
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        recordException(error, 'removeFromFavoriteProduct', { removedFavoriteProductId });
        dispatch(showError(error.message));
      }
    }
  };

export const addToFavoriteProduct: ThunkActionCreator<Actions, State> =
  (product: Product, onSuccess: (favoritedProductId: number) => string) => async (dispatch, getState) => {
    try {
      const state = getState();
      const currentFavorites = state.favorites;

      const { data: favoritedProduct } = await v3Client.post('products/favorites', {
        product_id: product.id,
      });

      const populate = currentFavorites.initialProductLength !== null;

      if (populate) {
        const productData = [
          {
            ...product,
            favorited: true,
            favorite_product_id: favoritedProduct.id,
          },
        ] as unknown as PharmacyProduct[];
        const uniqueData = distinctById(currentFavorites.products, productData);

        dispatch({
          type: constants.GET_FAVORITES,
          ...currentFavorites,
          products: uniqueData,
        });
      }

      const message = onSuccess(favoritedProduct.id);
      dispatch(showSuccess(message));
      dispatch(
        addDelayedFavoriteRequest({
          favoriteId: favoritedProduct.id,
          productId: favoritedProduct.product_id,
        }),
      );
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        recordException(error, 'addToFavoriteProduct', { product });
        dispatch(showError(error.message));
      }
    }
  };

export const clearFavoriteProducts: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  try {
    dispatch({ type: constants.CLEAR_FAVORITES });
  } catch (error) {
    console.error(error);
  }
};
