import React from 'react';
import { IconButton } from '@material-ui/core';
import { routeKey } from 'utils/route.utils';
import { S } from './basket.styles';

interface Props {
  onClick: (x: string) => any;
  cartCount: number | undefined;
}

export const Basket: React.FC<React.PropsWithChildren<Props>> = ({ onClick, cartCount }) => (
  <IconButton
    onClick={onClick(routeKey('prices'))}
    style={{
      padding: 0,
    }}
    id="tour__basket"
  >
    {cartCount === undefined ? (
      <S.BasketContainer>
        <S.BasketIcon />
      </S.BasketContainer>
    ) : (
      <S.BasketContainer>
        <S.Badge badgeContent={cartCount} color="primary">
          <S.BasketIcon />
        </S.Badge>
      </S.BasketContainer>
    )}
  </IconButton>
);
