import styled from 'styled-components';
import { Card, Button, createMuiTheme, Divider, Typography } from '@material-ui/core';

import * as colors from 'components/styles/colors';
import { ReactComponent as CoinIcon } from 'images/lp_coin.svg';

const getColor = (disabled: boolean) => {
  if (!disabled) {
    return colors.PRIMARY_COLOR;
  }

  return '#8C96AD80';
};

const Container = styled(Card)`
  && {
    padding: 0 14px 30px 14px;
    border-radius: 0;
    background: ${colors.WHITE};
  }
`;

const ActionButton = styled(Button)<{ disabled: boolean }>`
  && {
    font-family: 'Nunito Sans', 'Roboto', sans-serif;
    width: 100%;
    color: ${colors.WHITE};
    background-color: ${({ disabled }) => getColor(disabled)};
    border-radius: 40px;
    font-weight: bold;

    :hover {
      background-color: ${colors.DARK_PRIMARY_COLOR};
    }
  }
`;

const IconLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 16px 0;
  color: ${colors.WHITE};
  font-size: 12px;

  & > *:nth-child(1) {
    margin-right: 12px;
  }

  & > svg {
    fill: #ff7f30;
  }
`;

const Separator = styled(Divider)`
  && {
    background-color: ${colors.WHITE};
    margin-left: -14px;
    margin-right: -14px;
  }
`;

const VoucherAction = styled.div`
  margin-left: auto;
  font-size: 12px;

  & > svg {
    fill: #dadada;
    width: 20px;
  }
`;

const ItemCount = styled.div`
  margin-left: 10px;
  font-size: 12px;
  height: 12px;
  display: inline-block;
  color: ${colors.WHITE};
`;

export const redTheme = createMuiTheme({
  palette: { primary: { main: colors.SECONDARY_COLOR } },
});

export const OrderActionStyles = {
  Container,
  ActionButton,
  IconLabel,
  ItemCount,
  Separator,
  VoucherAction,
};

export const LoyaltyIcon = styled(CoinIcon)`
  height: 16px;
  width: 16px;
  margin-left: 4px;
  transform: translateY(2px);
`;

export const LoyaltyPoints = styled(Typography)`
  && {
    margin-left: 8;
    color: ${colors.BLACK};
    font-size: 11pt;
    align-items: center;
  }
`;
