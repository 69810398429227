import styled from 'styled-components';

import ButtonBase from '@material-ui/core/ButtonBase';

const Button = styled(ButtonBase)`
  && {
    border-radius: 10px;
    color: #fff;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    padding: 50px;
    width: 100%;
  }
`;

export const S = {
  Button,
};
