import * as Redux from 'redux';
import * as constants from './constants';
import { Actions } from './actions';

export interface State {
  utmSource: string;
  utmMedium: string;
}

const InitialState: State = {
  utmSource: '',
  utmMedium: '',
};

const CampaignReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SET_CAMPAIGN_DETAIL:
      return {
        ...state,
        utmSource: action.utmSource,
        utmMedium: action.utmMedium,
      };
    case constants.CLEAR_CAMPAIGN_DETAIL:
      return {
        ...state,
        utmSource: '',
        utmMedium: '',
      };

    default:
      return state;
  }
};

export default CampaignReducer;
