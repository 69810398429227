import sumBy from 'lodash/sumBy';

/* eslint-disable max-len */
import { useMemo } from 'react';
import {
  OrderStatus,
  PharmacyOrderStatus,
  OrderReturnType,
  OrderItem,
  OrderFulfillmentStatus,
  Order,
  OrderPrecursorStatus,
  DOCUMENT_REQUIREMENT_TYPE_PREKURSOR,
  DOCUMENT_REQUIREMENT_TYPE_REGULAR,
} from 'services';
import { LogisticDeliveryStatus } from 'services/swipe-rx-pt/resources/logistics-delivery/types';
import { PaymentMethods } from 'utils/constants';
import { mapOrderStatus } from 'utils/helpers';
import { DataObject } from 'types';

interface UseOrderStatus {
  order: Order | DataObject;
  status: PharmacyOrderStatus | OrderStatus;
  values: string[];
  showPrekursorLabel: boolean;
  showAlkesLabel: boolean;
  showRegularLabel: boolean;
  showOrderChangesLabel: boolean;
  showDeliveryDelayedLabel: boolean;
  showDeliveryFailedLabel: boolean;
  showDeliveredPartialReturnLabel: boolean;
  showCancelledFullReturnLabel: boolean;
  showSecondaryStatusLabel: boolean;
  hasPrekursor?: boolean;
  hasOrderChanges?: boolean;
  showPendingUploadPrekursorLabel: boolean;
  documentType?: string;
}

export const useOrderStatus = (order: Order | DataObject, t: any): UseOrderStatus => {
  const isDocumentPending = useMemo(
    () =>
      order.precursor_status &&
      order.precursor_status.status !== OrderPrecursorStatus.DOCUMENT_APPROVED &&
      order.precursor_status.status !== OrderPrecursorStatus.DOCUMENT_CANCELLED,
    [order.precursor_status],
  );

  let status = mapOrderStatus({
    status: order.status,
    fulfillmentStatus: order.fulfillment_status,
    delayed: order.is_delayed,
    returnType: order.return_type,
    isDirectPayment: order.payment_method === PaymentMethods.DIRECT_PAYMENT,
    invoicePaymentStatus: order.invoice?.payment_status,
    isDocumentPending,
  });

  let values: string[] = [t(`orderStatus.${status}`)];
  const showPrekursorLabel = useMemo(
    (): boolean => (order.status === 'pending' && order.precursor_status?.status) || null,
    [order],
  );

  const showPendingUploadPrekursorLabel = useMemo(
    (): boolean =>
      order.status === 'pending' &&
      [OrderPrecursorStatus.DOCUMENT_REQUIRED, OrderPrecursorStatus.DOCUMENT_REJECTED].includes(
        order.precursor_status?.status,
      ),
    [order],
  );

  const orderChanges = useMemo((): OrderItem[] => {
    const orderChanges = order.items?.reduce((acc, item) => {
      if (
        item.previous_discount_rate ||
        item.previous_quantity ||
        item.previous_selling_price ||
        item.is_out_of_stock
      ) {
        acc.push(item);
      }
      return acc;
    }, []);
    return orderChanges;
  }, [order]);

  const showDeliveryDelayedLabel = useMemo((): boolean => {
    const isDispatched =
      order.status === OrderStatus.ACCEPTED && order.fulfillment_status === OrderFulfillmentStatus.DISPATCHED;
    const lastDeliveryStatus = order.logistics_delivery_status?.status;
    return isDispatched && lastDeliveryStatus === LogisticDeliveryStatus.DELIVERY_DELAYED;
  }, [order]);
  if (showDeliveryDelayedLabel) {
    values.push(t(`orderStatusHistory.${LogisticDeliveryStatus.DELIVERY_DELAYED}`));
  }

  const showDeliveryFailedLabel = useMemo((): boolean => {
    const isDispatched =
      order.status === OrderStatus.ACCEPTED && order.fulfillment_status === OrderFulfillmentStatus.DISPATCHED;
    const lastDeliveryStatus = order.logistics_delivery_status?.status;
    const deliveryFailedStatuses = [LogisticDeliveryStatus.DELIVERY_FAILED, LogisticDeliveryStatus.DELIVERY_CANCELED];
    return isDispatched && lastDeliveryStatus && deliveryFailedStatuses.includes(lastDeliveryStatus);
  }, [order]);
  if (showDeliveryFailedLabel) {
    values = [t(`orderStatusHistory.${LogisticDeliveryStatus.DELIVERY_FAILED}`)];
  }

  const hasOrderChanges = orderChanges?.length > 0;

  let showOrderChangesLabel = false;
  let showDeliveredPartialReturnLabel = false;
  let showCancelledFullReturnLabel = false;

  switch (status) {
    case PharmacyOrderStatus.PREPARING_GOODS:
      if (hasOrderChanges) {
        const translateKey = orderChanges.length > 1 ? 'itemsChanged' : 'itemChanged';
        values.push(`${orderChanges.length} ${t(translateKey)}`);
        showOrderChangesLabel = true;
      }
      break;

    case PharmacyOrderStatus.DELAYED:
      if (order.fulfillment_status === OrderFulfillmentStatus.DISPATCHED) {
        values = [t(`orderStatus.${PharmacyOrderStatus.OUT_OF_DELIVERY}`)];
        if (showDeliveryDelayedLabel) {
          values.push(t(`orderStatus.${LogisticDeliveryStatus.DELIVERY_DELAYED}`));
        }
        status = PharmacyOrderStatus.OUT_OF_DELIVERY;
      } else {
        values = [t(`orderStatus.${PharmacyOrderStatus.PREPARING_GOODS}`), t(`orderStatus.${status}`)];
      }
      break;

    case PharmacyOrderStatus.DELIVERED:
      if (order?.return_type === OrderReturnType.PARTIAL) {
        values.push(t('orderStatus.deliveredPartialReturn'));
        showDeliveredPartialReturnLabel = true;
      }

      if (order.invoice?.return) {
        const orderItemCount = sumBy(order.items, 'quantity');
        const returnedItemCount = order.invoice.return.reduce((count, returned) => {
          const totalReturned = sumBy(returned.return_items, 'quantity');

          return count + totalReturned;
        }, 0);

        if (returnedItemCount > 0 && orderItemCount > returnedItemCount) {
          values = [t(`orderStatus.${status}`), t('orderStatus.deliveredPartialReturn')];
          showDeliveredPartialReturnLabel = true;
        }

        if (returnedItemCount > 0 && orderItemCount <= returnedItemCount) {
          values = [t(`orderStatus.${status}`), t(`orderStatus.${PharmacyOrderStatus.CANCELLED_FULL_RETURN}`)];
          showCancelledFullReturnLabel = true;
        }
      }

      break;

    case PharmacyOrderStatus.CANCELLED_FULL_RETURN:
      values = [t(`orderStatus.${PharmacyOrderStatus.CANCELLED}`), t(`orderStatus.${status}`)];
      showCancelledFullReturnLabel = true;
      break;
  }

  if (showPrekursorLabel) {
    values.push(t(`prekursor.${showPrekursorLabel}`));
  }

  const documentType = useMemo((): any => {
    // if secondary label filled by another information, don't need to display document type
    if (values[1]) return undefined;

    return order.requirements && order.requirements[0] && order.requirements[0].requirement_type
      ? order.requirements[0].requirement_type
      : order.precursor_status?.status
      ? DOCUMENT_REQUIREMENT_TYPE_PREKURSOR
      : DOCUMENT_REQUIREMENT_TYPE_REGULAR;
  }, [order]);

  const showAlkesLabel = useMemo((): any => {
    return order.requirements && order.requirements[0] && order.requirements[0].requirement_type === 'alkes';
  }, [order]);

  if (showAlkesLabel) {
    values.push(t(`alkes.title`));
  }

  let showRegularLabel = false;
  const hasPrekursor = order?.precursor_status;
  if (!showAlkesLabel && !hasPrekursor) {
    values.push(t(`regular.title`));
    showRegularLabel = true;
  }

  const showSecondaryStatusLabel =
    (showOrderChangesLabel ||
      showDeliveryDelayedLabel ||
      showDeliveredPartialReturnLabel ||
      showCancelledFullReturnLabel ||
      showAlkesLabel ||
      showPrekursorLabel ||
      showRegularLabel) &&
    !!values[1];

  return {
    order,
    status,
    values,
    showPrekursorLabel,
    showAlkesLabel,
    showRegularLabel,
    showOrderChangesLabel,
    showDeliveryDelayedLabel,
    showDeliveryFailedLabel,
    showDeliveredPartialReturnLabel,
    showCancelledFullReturnLabel,
    showSecondaryStatusLabel,
    hasPrekursor,
    hasOrderChanges,
    showPendingUploadPrekursorLabel,
    documentType,
  };
};
