import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { swipeRxPt } from 'services';

import { State as StoreState } from 'store';
import { showError } from 'store/reducers/Global/actions';
import { closeOrderRating, toggleOrderModal, openOrderRatingThankYou } from 'store/reducers/Orders/actions';
import { TFunction } from 'utils/Localization';

import { S } from './orders-rating.styles';

export interface SubmitSectionProps {
  t: TFunction;
}

export const SubmitSection: React.FC<React.PropsWithChildren<SubmitSectionProps>> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { t } = props;

  const {
    purchaseOrder: { orderDetail },
    orders: {
      orderRating: { feedbacks, checkedOptions, rate },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const isEnabled = !!rate && (checkedOptions.length > 0 || [3, 4, 5].includes(rate));

  const handleSubmit = async (): Promise<void> => {
    const promise = async (): Promise<void> => {
      try {
        if (rate) {
          await swipeRxPt.rating.create({
            feedbacks: checkedOptions,
            open_feedback: feedbacks,
            rate,
            purchase_order_id: orderDetail.id,
            ordered_at: orderDetail.ordered_at,
            purchase_order_number: orderDetail.po_number,
          });
        }
      } catch (error) {
        if (error instanceof Error) dispatch(showError(error.message));
      }

      // TODO: remove this and navigate to thanks page
      dispatch(closeOrderRating());
      dispatch(toggleOrderModal(false));
    };

    setIsLoading(true);
    await promise();
    setIsLoading(false);
    dispatch(closeOrderRating());
    dispatch(openOrderRatingThankYou());
  };

  return (
    <S.SubmitSectionCard>
      <S.SubmitSectionButton disabled={!isEnabled || isLoading} onClick={handleSubmit}>
        {isLoading ? <S.CircularProgress /> : t('submit')}
      </S.SubmitSectionButton>
    </S.SubmitSectionCard>
  );
};
