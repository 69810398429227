import { Action } from 'redux';

import { recordException } from 'utils/Reporting/Sentry';

import { DEFAULT_MARKET_MINIMUM_INVOICE_AMOUNT } from 'utils/constants';
import { trackCartQuantityEvent as causalFoundryTrackCartQuantityEvent } from 'utils/Analytics/CausalFoundry';
import { swipeRxPt } from 'services/swipe-rx-pt';
import { ThunkActionCreator } from 'types/thunk';

import { State } from '../../index';

import * as cartConstants from '../Cart/constants';
import { Actions as GlobalActions, showError } from '../Global/actions';
import { CartProducts, DistributorCarts, OrderSummary } from '../Cart';
import { Product, Distributor } from '../Product';

import * as constants from './constants';
import { ProductCartLimit, ProductPurchaseLimit } from '.';

export interface CartProduct {
  [key: string]: {
    [key: number]: {
      package: string;
      quantity: number;
      net_price: number;
      selling_price: number;
      discount_rate: number;
      display_photo: string;
      thumbnail_photo: string;
      distributor: {
        id: number;
        name: string;
        minimum_invoice_amount: number;
        is_minimum_invoice_meet?: boolean;
      };
      stock: {
        remaining_quantity: number;
        quantity_threshold: number;
        low_stock_threshold: number;
        is_tier_pricing_enabled: boolean;
      };
      cartLimit: ProductCartLimit;
      discounts: {
        default: {
          discount_rate: number;
          net_price: number;
        };
        tier_discount: {
          id: number;
          available: boolean;
          discount_rate: number;
          distributor: {
            id: number;
            is_minimum_invoice_meet: boolean;
            minimum_invoice_amount: number;
            name: string;
          };
          distributor_product_id: number;
          low_stock_threshold: number;
          max: number;
          min: number;
          price: number;
          quantity_threshold: number;
          remaining_quantity: number;
          selling_price: number;
        };
        base_discount: {
          selling_price: number;
          discount_rate: number;
          net_price: number;
        };
      };
      flag: string;
      marketing_id?: number | string;
      update_status?: {
        change_type: string | null;
        price_from: number;
        change_distributor: boolean;
      };
    };
  };
}

export enum UpdateCartItemType {
  CREATE_OR_UPDATE = 'CREATE_OR_UPDATE',
  UPDATE = 'UPDATE',
}

export interface UpdateProductQuantity extends Action {
  type: constants.UPDATE_PRODUCT_QUANTITY;
  counterList: { [id: number]: { count: number } };
  totalItem: number;
  totalAmount: number;
  cartItems: CartProduct;
}

export interface FailCartAction extends Action {
  type: constants.FAIL_CART;
  error: string;
}

export interface UpdateCartItemRequest extends Action {
  type: constants.UPDATE_CART_ITEM;
  cartItems: CartProduct;
  counterList: any;
  totalAmount: number;
  totalItem: number;
  unavailableProducts: any;
  alternative: any;
  loading: boolean;
}

export interface ResetCartCounterRequest extends Action {
  type: constants.RESET_CART_COUNTER;
  cartItems: CartProduct;
  counterList: any;
  totalAmount: number;
  totalItem: number;
  purchaseOrderBatch: any;
}

export interface UpdateCartRequest extends Action {
  type: constants.CART_UPDATED;
  items: any;
}

export interface EmptyCartRequest extends Action {
  type: constants.EMPTY_CART;
  items: any;
}

export interface GetCartAction extends Action {
  type: constants.GET_CART;
  items: CartProducts;
  distributors: Array<DistributorCarts>;
  order_summary: OrderSummary;
}

export interface UpdatePurchaseOrderRatingRequest extends Action {
  type: constants.UPDATE_PURCHASE_ORDER_RATING;
  purchaseOrderBatch: any;
}

export interface GettinCart extends Action {
  type: constants.GETTING_CART;
  loading: boolean;
}

export interface UpdateProductMaxQuantity {
  type: constants.MAX_QUANTITY_CART_ITEM;
  cartMaxPurchaseLimit: any;
}

export interface AddProductMaxQuantity {
  type: constants.MAX_QUANTITY_CART_ITEM_ADD;
  items: Array<ProductPurchaseLimit>;
}

export type Actions =
  | GettinCart
  | GetCartAction
  | EmptyCartRequest
  | FailCartAction
  | GlobalActions
  | UpdateCartItemRequest
  | ResetCartCounterRequest
  | UpdateCartRequest
  | UpdatePurchaseOrderRatingRequest
  | UpdateProductQuantity
  | UpdateProductMaxQuantity
  | AddProductMaxQuantity;

const whereMinimumInvoiceMet = (distributorId: number, grandTotal: number) => (entry: DistributorCarts) => {
  if (Number(entry.id) !== distributorId) return entry;

  return {
    ...entry,
    is_minimum_invoice_meet: grandTotal >= entry.minimum_invoice_amount,
  };
};

export const getCart: ThunkActionCreator<Actions> =
  (options = { flag: 'product' }) =>
  async (dispatch) => {
    try {
      dispatch({ type: constants.GETTING_CART, loading: true });

      // Fetch cart items
      const results: any = await swipeRxPt.cart.getAll({
        flag: options.flag,
        distributor: options.distributor,
      });
      const cartItems = results.carts;
      const { unavailableProducts } = results;
      const getCounterList = {};
      let totalComputedAmount = 0;
      let totalItemCount = 0;
      Object.entries<any>(cartItems).forEach(([productName, productIds]) => {
        Object.entries<any>(productIds).forEach(([id, item]) => {
          const itemCount = item.quantity;
          const netPrice = item.net_price;
          getCounterList[id] = {
            count: itemCount,
            cartItems: {
              [productName]: {
                [id]: item,
              },
            },
            productName,
          };
          totalComputedAmount += itemCount * netPrice;
          totalItemCount += itemCount;
        });
      });

      // sort counterList
      const orderCounterList = {};
      Object.keys(getCounterList)
        .sort()
        .forEach((key) => {
          orderCounterList[key] = getCounterList[key];
        });

      // sort cartItems
      const orderCartItems = {};
      Object.keys(cartItems)
        .sort()
        .forEach((key) => {
          orderCartItems[key] = cartItems[key];
        });

      // extract max purchase limit
      const transformedCarts = {};
      Object.entries(results.carts).forEach(([key1, value1]) => {
        if (typeof value1 === 'object' && value1 !== null) {
          transformedCarts[key1] = {};
          Object.entries(value1).forEach(([key2, value2]) => {
            if (typeof value2 === 'object' && value2 !== null) {
              transformedCarts[key1][key2] = {};
              transformedCarts[key1][key2].cartLimit = value2.cartLimit;
            }
          });
        }
      });

      // Dispatch the items
      dispatch({
        type: constants.GET_CART,
        items: results.carts,
        distributors: results.distributors,
        order_summary: results.order_summary,
        alternative: results.alternative,
        loading: false,
      });
      dispatch({
        type: constants.UPDATE_CART_ITEM,
        cartItems: orderCartItems,
        counterList: orderCounterList,
        totalAmount: totalComputedAmount,
        totalItem: totalItemCount,
        unavailableProducts,
        alternative: results.alternative,
        loading: false,
      });
      dispatch({
        type: constants.MAX_QUANTITY_CART_ITEM,
        cartMaxPurchaseLimit: transformedCarts,
      });

      return {
        distributors: results.distributors,
        carts: results.carts,
        total: results.order_summary.total_net_price_before_tax,
      };
    } catch (error) {
      // Dispatch the error
      if (error instanceof Error) {
        recordException(error, 'getCart', { options });
        dispatch({ type: constants.FAIL_CART, error: error.message });
        dispatch(showError(error.message));
      }
      return {};
    }
  };

interface GetUpdatedCartItems {
  product: Product;
  quantity: number;
  discount: number;
  discountedPrice: number;
  distributor: Distributor;
  cartItems: CartProduct;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getUpdatedCartItems = ({
  product,
  quantity,
  discount,
  discountedPrice,
  distributor,
  cartItems,
}: GetUpdatedCartItems) => {
  const { id, name } = product;

  const updatedProduct = {
    [name]: {
      [id]: {
        package: product.packaging,
        quantity,
        net_price: discountedPrice,
        selling_price: product.selling_price,
        discount_rate: discount,
        distributor,
        display_photo: product.display_photo,
        thumbnail_photo: product.thumbnail_photo,
        discounts: {
          default: {
            discount_rate: product.discount_rate,
            net_price: product.net_price,
          },
          tier_discount: product.tier_discount,
        },
        stock: {
          remaining_quantity: product.remaining_quantity,
          quantity_threshold: product.quantity_threshold,
          low_stock_threshold: product.low_stock_threshold,
          is_tier_pricing_enabled: product.tier_discount && product.tier_discount.length > 0,
        },
        sku_code: product.sku_code,
        flag: product.flag,
        marketing_id: product.marketing_id,
      },
    },
  };

  const updatedCartItem = [...Object.keys(cartItems), ...Object.keys(updatedProduct)].reduce((newCart, key) => {
    newCart[key] = { ...cartItems[key], ...updatedProduct[key] };
    return newCart;
  }, {});

  if (updatedCartItem[name][id].quantity > 0) {
    return updatedCartItem;
  }

  if (Object.keys(updatedCartItem[name]).length > 1 && updatedCartItem[name][id].quantity === 0) {
    delete updatedCartItem[name][id];
    return updatedCartItem;
  }

  delete updatedCartItem[name];
  return updatedCartItem;
};

interface GetUpdatedCounterListParams {
  product: Product;
  quantity: number;
  sellingPrice: number;
  discount: number;
  discountedPrice: number;
  count: number;
  previousCount: number;
  counterList: any;
  distributor: Distributor;
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getUpdatedCounterList = ({
  product,
  quantity,
  count,
  sellingPrice,
  discount,
  discountedPrice,
  distributor,
  previousCount,
  counterList,
}: GetUpdatedCounterListParams) => {
  const {
    id,
    name,
    packaging,
    selling_price,
    discount_rate,
    net_price: defaultNetPrice,
    tier_discount,
    remaining_quantity,
    quantity_threshold,
    low_stock_threshold,
    sku_code,
    flag,
    thumbnail_photo,
    display_photo,
  } = product;

  const updatedProductToCounterList = {
    [id]: {
      previousCount,
      count,
      productName: name,
      cartItems: {
        [name]: {
          [id]: {
            package: packaging,
            quantity,
            net_price: discountedPrice,
            selling_price: sellingPrice || selling_price,
            discount_rate: discount,
            distributor,
            display_photo,
            thumbnail_photo,
            discounts: {
              default: {
                discount_rate,
                net_price: defaultNetPrice,
              },
              tier_discount,
            },
            stock: {
              remaining_quantity,
              quantity_threshold,
              low_stock_threshold,
              is_tier_pricing_enabled: tier_discount && tier_discount.length > 0,
            },
            sku_code,
            flag,
          },
        },
      },
    },
  };

  const updatedCounterList = [...Object.keys(counterList), ...Object.keys(updatedProductToCounterList)].reduce(
    (newCounterList, key) => {
      newCounterList[key] = {
        ...counterList[key],
        ...updatedProductToCounterList[key],
      };
      return newCounterList;
    },
    {},
  );

  // removed item counter list if quantity count is zero
  if (updatedCounterList[id].count === 0) {
    delete updatedCounterList[id];
    return updatedCounterList;
  }
  return updatedCounterList;
};

const getDistributorAndDiscount = (
  product: Product,
  itemCount: number,
): {
  discount: number;
  distributor: Distributor;
  discountedPrice: number;
  sellingPrice: number;
} => {
  const isTierDiscountAvailable = product.tier_discount !== undefined && product.tier_discount.length !== 0;
  const productDiscount = product.discount_rate || 0;
  const preNegotiatedPrice = product.selling_price - product.selling_price * productDiscount;
  const tierLastIndex = isTierDiscountAvailable ? product.tier_discount.length - 1 : 0;

  let activeSellingPrice: number = product.selling_price;
  let activeDistributor: Distributor = product.distributor;
  let activeDiscount: number = productDiscount;
  let activeDiscountedPrice: number = preNegotiatedPrice;

  if (!isTierDiscountAvailable) {
    return {
      discount: activeDiscount,
      distributor: activeDistributor,
      discountedPrice: activeDiscountedPrice,
      sellingPrice: activeSellingPrice,
    };
  }

  product.tier_discount.forEach((promo, index) => {
    if (product.tier_discount[tierLastIndex].min <= itemCount) {
      activeSellingPrice = product.tier_discount[tierLastIndex].selling_price;
      activeDiscount = product.tier_discount[tierLastIndex].discount_rate;
      activeDistributor = product.tier_discount[tierLastIndex].distributor;
      activeDiscountedPrice = product.tier_discount[tierLastIndex].price;
      return;
    }

    if (product.tier_discount[0].min > itemCount) {
      activeSellingPrice = product.selling_price;
      activeDiscount = product.discount_rate;
      activeDistributor = product.distributor;
      activeDiscountedPrice = preNegotiatedPrice;
      return;
    }

    if (promo.min <= itemCount && itemCount < product.tier_discount[index + 1].min) {
      activeSellingPrice = promo.selling_price;
      activeDiscount = promo.discount_rate;
      activeDistributor = promo.distributor;
      activeDiscountedPrice = promo.price;
      // return;
    }
  });

  return {
    sellingPrice: activeSellingPrice,
    discount: activeDiscount,
    distributor: activeDistributor,
    discountedPrice: activeDiscountedPrice,
  };
};

export const updateCartItem = (cartProduct: CartProduct, type: UpdateCartItemType) => async (dispatch, getState) => {
  const {
    cart: { items: cartItems },
    config,
  } = getState();

  const marketMinimumInvoiceAmount =
    config.market.orders?.minimum_invoice_amount || DEFAULT_MARKET_MINIMUM_INVOICE_AMOUNT;

  try {
    const orderCartItems: CartProduct = {};
    Object.keys(cartProduct)
      .sort()
      .forEach((key) => {
        orderCartItems[key] = cartProduct[key];
      });
    let result = orderCartItems;

    // Dispatch before api response
    dispatch({
      type: constants.CART_UPDATED,
      items: orderCartItems,
      market_minimum_invoice_amount: marketMinimumInvoiceAmount,
    });

    switch (type) {
      case UpdateCartItemType.CREATE_OR_UPDATE: {
        if (Object.keys(cartItems).length === 0) {
          // if cartItems is empty call create carts
          result = await swipeRxPt.cart.create({ items: cartProduct });
        } else {
          // if cartItems is not empty call update carts
          result = await swipeRxPt.cart.update({ items: cartProduct });
        }
        break;
      }
      case UpdateCartItemType.UPDATE: {
        result = await swipeRxPt.cart.update({ items: cartProduct });
        break;
      }
    }

    // TODO: redundant, Should be refactored to be a common service that transforms cart results
    const transformedCarts = {};
    Object.entries(result.carts).forEach(([key1, value1]) => {
      if (typeof value1 === 'object' && value1 !== null) {
        transformedCarts[key1] = {};
        Object.entries(value1).forEach(([key2, value2]) => {
          if (typeof value2 === 'object' && value2 !== null) {
            transformedCarts[key1][key2] = {};
            transformedCarts[key1][key2].cartLimit = (value2 as any)?.cartLimit;
          }
        });
      }
    });

    dispatch({
      type: cartConstants.GET_MINIMUM_INVOICE_AMOUNT,
      distributors: result.distributors || [],
    });
    dispatch({
      type: constants.CART_UPDATED,
      items: result.carts,
      market_minimum_invoice_amount: marketMinimumInvoiceAmount,
    });
    dispatch({
      type: constants.MAX_QUANTITY_CART_ITEM,
      cartMaxPurchaseLimit: transformedCarts,
    });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'updateCartItem', { cartProduct, type });
      dispatch({ type: constants.FAIL_CART, error: error.message });
      dispatch(showError(error.message));
    }
  }
};

const updateCounterList =
  (
    updatedCartItem: CartProduct,
    updatedCounterList: CartProduct,
    totalItem: number,
    totalAmount: number,
    distributors: DistributorCarts[],
  ) =>
  (dispatch) => {
    const orderCartItems = {};
    Object.keys(updatedCartItem)
      .sort()
      .forEach((key) => {
        orderCartItems[key] = updatedCartItem[key];
      });

    const orderCounterList = {};
    Object.keys(updatedCounterList)
      .sort()
      .forEach((key) => {
        orderCounterList[key] = updatedCounterList[key];
      });

    dispatch({
      type: cartConstants.GET_MINIMUM_INVOICE_AMOUNT,
      distributors,
    });

    dispatch({
      type: constants.UPDATE_PRODUCT_QUANTITY,
      counterList: orderCounterList,
      totalItem,
      totalAmount,
      cartItems: orderCartItems,
    });
  };

export const incrementProductQuantity: ThunkActionCreator<Actions, State> =
  (product: Product) => (dispatch, getState) => {
    const {
      counter: { cartItems, counterList, totalItem, totalAmount },
      cart: { distributors },
    } = getState();

    const { id, name } = product;

    const quantity = cartItems[name] && cartItems[name][id] ? cartItems[name][id].quantity + 1 : 1;
    const baseDiscount = cartItems[name] && cartItems[name][id] ? cartItems[name][id].discounts.base_discount : null;
    if (baseDiscount) {
      product.discount_rate = baseDiscount.discount_rate;
      product.selling_price = baseDiscount.selling_price;
    }
    const { distributor, discount, discountedPrice, sellingPrice } = getDistributorAndDiscount(product, quantity);

    const previousCount = counterList[id] ? counterList[id].count : 0;
    const count = counterList[id] ? counterList[id].count + 1 : 1;
    const { discountedPrice: prevDiscountedPrice } = getDistributorAndDiscount(product, previousCount);
    const currentPrice = discountedPrice;
    const prevPrice = prevDiscountedPrice;
    const totalAmountWithPrevDiscount = previousCount * prevPrice;
    const totalAmountWithCurrentDiscount = count * currentPrice;
    const totalCount = Math.max(0, totalItem + 1);
    const grandTotal = Math.max(totalAmount + totalAmountWithCurrentDiscount - totalAmountWithPrevDiscount, 0);

    const isMinimumInvoiceMeet = grandTotal >= distributor.minimum_invoice_amount;
    distributor.is_minimum_invoice_meet = isMinimumInvoiceMeet;
    const updatedCartItem = getUpdatedCartItems({
      product,
      quantity,
      discount,
      discountedPrice,
      distributor,
      cartItems,
    });
    const updatedCounterList = getUpdatedCounterList({
      product,
      quantity,
      discount,
      discountedPrice,
      count,
      previousCount,
      distributor,
      counterList,
      sellingPrice,
    });

    dispatch(
      updateCounterList(
        updatedCartItem,
        updatedCounterList,
        totalCount,
        grandTotal,
        distributors.map(whereMinimumInvoiceMet(distributor.id, grandTotal)),
      ),
    );

    causalFoundryTrackCartQuantityEvent(
      true,
      grandTotal,
      product.id,
      totalAmountWithCurrentDiscount,
      count,
      totalItem === 0,
      product,
    );

    return updatedCartItem;
  };

export const decrementProductQuantity: ThunkActionCreator<Actions, State> =
  (product: Product) => (dispatch, getState) => {
    const {
      counter: { cartItems, counterList, totalItem, totalAmount },
      cart: { distributors },
    } = getState();
    const { id, name } = product;
    const quantity = cartItems[name] && cartItems[name][id] ? cartItems[name][id].quantity - 1 : 0;
    const baseDiscount = cartItems[name] && cartItems[name][id] ? cartItems[name][id].discounts.base_discount : null;
    if (baseDiscount) {
      product.discount_rate = baseDiscount.discount_rate;
      product.selling_price = baseDiscount.selling_price;
    }
    const { distributor, discount, discountedPrice, sellingPrice } = getDistributorAndDiscount(product, quantity);

    const previousCount = counterList[id].count;
    const count = Math.max(0, counterList[id].count - 1);
    const { discountedPrice: prevDiscountedPrice } = getDistributorAndDiscount(product, previousCount);
    const currentPrice = discountedPrice;
    const prevPrice = prevDiscountedPrice;
    const totalAmountWithPrevDiscount = previousCount * prevPrice;
    const totalAmountWithCurrentDiscount = count * currentPrice;
    const totalCount = Math.max(0, totalItem - 1);
    const grandTotal = Math.max(totalAmount + totalAmountWithCurrentDiscount - totalAmountWithPrevDiscount, 0);

    const isMinimumInvoiceMeet = grandTotal >= distributor.minimum_invoice_amount;
    distributor.is_minimum_invoice_meet = isMinimumInvoiceMeet;
    const updatedCartItem = getUpdatedCartItems({
      product,
      quantity,
      discount,
      discountedPrice,
      cartItems,
      distributor,
    });
    const updatedCounterList = getUpdatedCounterList({
      product,
      quantity,
      discount,
      discountedPrice,
      count,
      previousCount,
      counterList,
      distributor,
      sellingPrice,
    });

    dispatch(
      updateCounterList(
        updatedCartItem,
        updatedCounterList,
        totalCount,
        grandTotal,
        distributors.map(whereMinimumInvoiceMet(distributor.id, grandTotal)),
      ),
    );

    causalFoundryTrackCartQuantityEvent(
      false,
      grandTotal,
      product.id,
      totalAmountWithCurrentDiscount,
      count,
      totalItem === 0,
      product,
    );

    return updatedCartItem;
  };

export const addOrUpdateProductQuantity: ThunkActionCreator<Actions, State> =
  (product: Product, quantity: number) => (dispatch, getState) => {
    const {
      counter: { cartItems, counterList, totalItem, totalAmount },
      cart: { distributors },
    } = getState();
    const { id } = product;

    const { distributor, discount, discountedPrice, sellingPrice } = getDistributorAndDiscount(product, quantity);
    const previousCount = counterList[id] ? counterList[id].count : 0;
    const count = Math.max(0, quantity);
    const { discountedPrice: prevDiscountedPrice } = getDistributorAndDiscount(product, previousCount);
    const currentPrice = discountedPrice;
    const prevPrice = prevDiscountedPrice;
    const totalAmountWithPrevDiscount = previousCount * prevPrice;
    const totalAmountWithCurrentDiscount = count * currentPrice;
    const subTotalCount = Math.max(totalItem + quantity, 0);
    const totalCount = Math.max(subTotalCount - previousCount, 0);
    const grandTotal = Math.max(totalAmount + totalAmountWithCurrentDiscount - totalAmountWithPrevDiscount, 0);

    const updatedCartItem = getUpdatedCartItems({
      product,
      quantity,
      discount,
      discountedPrice,
      cartItems,
      distributor,
    });
    const updatedCounterList = getUpdatedCounterList({
      product,
      quantity,
      discount,
      discountedPrice,
      count,
      previousCount,
      counterList,
      distributor,
      sellingPrice,
    });

    dispatch(
      updateCounterList(
        updatedCartItem,
        updatedCounterList,
        totalCount,
        grandTotal,
        distributors.map(whereMinimumInvoiceMet(distributor.id, grandTotal)),
      ),
    );
    dispatch(updateCartItem(updatedCartItem, UpdateCartItemType.CREATE_OR_UPDATE));

    if (count !== previousCount) {
      causalFoundryTrackCartQuantityEvent(
        count > previousCount,
        grandTotal,
        product.id,
        totalAmountWithCurrentDiscount,
        count,
        totalItem === 0,
        product,
      );
    }
  };

export const updateProductQuantity: ThunkActionCreator<Actions, State> =
  (product: Product, quantity: number) => (dispatch, getState) => {
    const {
      counter: { cartItems, counterList, totalItem, totalAmount },
      cart: { distributors },
    } = getState();
    const { id } = product;
    const { distributor, discount, discountedPrice, sellingPrice } = getDistributorAndDiscount(product, quantity);
    const previousCount = counterList[id] ? counterList[id].count : 0;
    const count = Math.max(0, quantity);
    const { discountedPrice: prevDiscountedPrice } = getDistributorAndDiscount(product, previousCount);
    const currentPrice = discountedPrice;
    const prevPrice = prevDiscountedPrice;
    const totalAmountWithPrevDiscount = previousCount * prevPrice;
    const totalAmountWithCurrentDiscount = count * currentPrice;
    const subTotalCount = Math.max(totalItem + quantity, 0);
    const totalCount = Math.max(subTotalCount - previousCount, 0);
    const grandTotal = Math.max(totalAmount + totalAmountWithCurrentDiscount - totalAmountWithPrevDiscount, 0);

    const updatedCartItem = getUpdatedCartItems({
      product,
      quantity,
      discount,
      discountedPrice,
      cartItems,
      distributor,
    });
    const updatedCounterList = getUpdatedCounterList({
      product,
      quantity,
      discount,
      discountedPrice,
      count,
      previousCount,
      counterList,
      distributor,
      sellingPrice,
    });

    dispatch(
      updateCounterList(
        updatedCartItem,
        updatedCounterList,
        totalCount,
        grandTotal,
        distributors.map(whereMinimumInvoiceMet(distributor.id, grandTotal)),
      ),
    );
    dispatch(updateCartItem(updatedCartItem, UpdateCartItemType.UPDATE));

    if (count !== previousCount) {
      causalFoundryTrackCartQuantityEvent(
        count > previousCount,
        grandTotal,
        product.id,
        totalAmountWithCurrentDiscount,
        count,
        totalItem === 0,
        product,
      );
    }
  };

export const removeProducts: ThunkActionCreator<Actions, State> =
  (products: any, cartItems: any) => async (dispatch, getState) => {
    const { config } = getState();

    const marketMinimumInvoiceAmount =
      config.market.orders?.minimum_invoice_amount || DEFAULT_MARKET_MINIMUM_INVOICE_AMOUNT;

    try {
      // store sku code of product that will be deleted
      const skuToRemove = products.map((product) => product.sku_code.trim());

      // generate new cart items
      const newCartItems = Object.keys(cartItems).reduce((acc, productName) => {
        // remove sku from cart
        const currentItems = cartItems[productName];
        const newItems: Record<string, any> = {};

        Object.keys(currentItems).forEach((productId) => {
          if (!skuToRemove.includes(currentItems[productId].sku_code.trim())) {
            newItems[productId] = currentItems[productId];
          }
        });

        if (Object.keys(newItems).length > 0) {
          acc[productName] = newItems;
        }

        return acc;
      }, {});

      const orderCartItems = {};
      let counterList = {};
      let totalItem = 0;
      let totalAmount = 0;
      Object.keys(newCartItems)
        .sort()
        .forEach((cartName) => {
          const cartItem = newCartItems[cartName];
          Object.keys(cartItem).forEach((key) => {
            const id = Number(key);
            const value = cartItem[key];
            if (value) {
              const { quantity, net_price } = value;
              const item = {
                [id]: {
                  ...value,
                  count: quantity,
                },
              };
              counterList = {
                ...counterList,
                ...item,
              };
              totalItem += quantity;
              totalAmount += quantity * net_price;
            }
          });
          orderCartItems[cartName] = newCartItems[cartName];
        });

      dispatch({
        type: constants.UPDATE_PRODUCT_QUANTITY,
        counterList,
        totalItem,
        totalAmount,
        cartItems: orderCartItems,
      });
      // Update cart
      dispatch({
        type: constants.CART_UPDATED,
        items: orderCartItems,
        market_minimum_invoice_amount: marketMinimumInvoiceAmount,
      });

      const results = await swipeRxPt.cart.update({ items: orderCartItems });

      dispatch({
        type: constants.GET_CART,
        items: results.carts,
        distributors: results.distributors,
        order_summary: results.order_summary,
        alternative: results.alternative,
        loading: false,
      });
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'removeProducts', { products, cartItems });
        dispatch({ type: constants.FAIL_CART, error: error.message });
        dispatch(showError(error.message));
      }
    }
  };

export const resetCartCounter: ThunkActionCreator<Actions> =
  (appCacheOnly = false) =>
  async (dispatch) => {
    try {
      // Dispatch the items
      dispatch({
        type: constants.RESET_CART_COUNTER,
        cartItems: {},
        counterList: {},
        totalAmount: 0,
        totalItem: 0,
        purchaseOrderBatch: { rating: 0 },
      });

      // Update cart
      dispatch({ type: constants.EMPTY_CART, items: {} });
      if (!appCacheOnly) {
        await swipeRxPt.cart.update({ items: {} });
      }
    } catch (error) {
      // Dispatch the error
      if (error instanceof Error) {
        recordException(error, 'resetCartCounter');
        dispatch({ type: constants.FAIL_CART, error: error.message });
        dispatch(showError(error.message));
      }
    }
  };

export const updateCartAndTransfer: ThunkActionCreator<Actions> = (data) => async (dispatch) => {
  try {
    const results = await swipeRxPt.cart.update({
      transfer_products_from: data.distributor,
      items: data.items,
    });

    const cartItems = results.carts;
    const { unavailableProducts } = results;
    const getCounterList = {};
    let totalComputedAmount = 0;
    let totalItemCount = 0;
    Object.entries<any>(cartItems).forEach(([productName, productIds]) => {
      Object.entries<any>(productIds).forEach(([id, item]) => {
        const itemCount = item.quantity;
        const netPrice = item.net_price;
        getCounterList[id] = {
          count: itemCount,
          cartItems: item,
          productName,
        };

        totalComputedAmount += itemCount * netPrice;
        totalItemCount += itemCount;
      });
    });

    // sort counterList
    const orderCounterList = {};
    Object.keys(getCounterList)
      .sort()
      .forEach((key) => {
        orderCounterList[key] = getCounterList[key];
      });

    // sort cartItems
    const orderCartItems = {};
    Object.keys(cartItems)
      .sort()
      .forEach((key) => {
        orderCartItems[key] = cartItems[key];
      });

    // Dispatch the items
    dispatch({
      type: constants.GET_CART,
      items: results.carts,
      distributors: results.distributors,
      order_summary: results.order_summary,
      alternative: results.alternative,
      loading: false,
    });
    dispatch({
      type: constants.UPDATE_CART_ITEM,
      cartItems: orderCartItems,
      counterList: orderCounterList,
      totalAmount: totalComputedAmount,
      totalItem: totalItemCount,
      unavailableProducts,
      alternative: results.alternative,
      loading: false,
    });
  } catch (error) {
    // Dispatch the error
    if (error instanceof Error) {
      recordException(error, 'updateCartAndTransfer', { data });
      dispatch({ type: constants.FAIL_CART, error: error.message });
      dispatch(showError(error.message));
    }
  }
};

export const adjustProductsQty: ThunkActionCreator<Actions, State> =
  (carts: Record<string, any>) => async (dispatch, getState) => {
    const { config } = getState();

    try {
      const marketMinimumInvoiceAmount =
        config.market.orders?.minimum_invoice_amount || DEFAULT_MARKET_MINIMUM_INVOICE_AMOUNT;

      const orderCartItems = {};

      let counterList = {};
      let totalItem = 0;
      let totalAmount = 0;

      Object.keys(carts)
        .sort()
        .forEach((productName) => {
          const cartItem = carts[productName];
          Object.keys(cartItem).forEach((key) => {
            const id = Number(key);
            const product = cartItem[key];

            const remainingStock = product.stock.remaining_quantity - product.stock.quantity_threshold;

            const purchasedQuantity = product.cartLimit?.purchase_history_qty;
            const maxQuantityEnabled =
              (product.cartLimit?.max_qty_enabled && product.cartLimit?.max_qty && product.cartLimit.max_qty > 0) ||
              false;
            const maxPurchaseQuantity = product.cartLimit?.max_qty;
            const purchaseableQuantity =
              maxQuantityEnabled && maxPurchaseQuantity ? maxPurchaseQuantity - purchasedQuantity : remainingStock;
            const productLimitReached =
              maxQuantityEnabled && maxPurchaseQuantity ? purchaseableQuantity < (product?.quantity || 0) : false;

            const purchasedMonthlyQuantity = product.cartLimit?.purchase_history_qty_monthly;
            const maxMonthlyQuantityEnabled =
              (product.cartLimit?.max_qty_enabled &&
                product.cartLimit?.monthly_max_qty &&
                product.cartLimit.monthly_max_qty > 0) ||
              false;
            const maxPurchaseMonthlyQuantity = product.cartLimit?.monthly_max_qty;
            const purchaseableMonthlyQuantity =
              maxMonthlyQuantityEnabled && maxPurchaseMonthlyQuantity
                ? maxPurchaseMonthlyQuantity - purchasedMonthlyQuantity
                : remainingStock;
            const productMonthlyLimitReached =
              maxMonthlyQuantityEnabled && maxPurchaseMonthlyQuantity
                ? purchaseableMonthlyQuantity < (product?.quantity || 0)
                : false;

            if (product) {
              const { quantity, net_price } = product;
              let adjustedQty = quantity;

              if (maxQuantityEnabled && productLimitReached && purchaseableQuantity > 0) {
                adjustedQty = purchaseableQuantity;
              }

              if (maxMonthlyQuantityEnabled && productMonthlyLimitReached && purchaseableMonthlyQuantity > 0) {
                adjustedQty = purchaseableMonthlyQuantity;
              }

              const item = {
                [id]: {
                  ...product,
                  count: adjustedQty,
                },
              };
              counterList = {
                ...counterList,
                ...item,
              };
              totalItem += adjustedQty;
              totalAmount += adjustedQty * net_price;

              orderCartItems[productName] = {
                [id]: {
                  ...carts[productName][id],
                  quantity: adjustedQty,
                },
              };
            }
          });
        });

      dispatch({
        type: constants.UPDATE_PRODUCT_QUANTITY,
        counterList,
        totalItem,
        totalAmount,
        cartItems: orderCartItems,
      });
      // Update cart
      dispatch({
        type: constants.CART_UPDATED,
        items: orderCartItems,
        market_minimum_invoice_amount: marketMinimumInvoiceAmount,
      });

      const results = await swipeRxPt.cart.update({ items: orderCartItems });

      dispatch({
        type: constants.GET_CART,
        items: results.carts,
        distributors: results.distributors,
        order_summary: results.order_summary,
        alternative: results.alternative,
        loading: false,
      });
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'adjustProductsQty', { carts });
        dispatch({ type: constants.FAIL_CART, error: error.message });
        dispatch(showError(error.message));
      }
    }
  };
