import React from 'react';
import moment from 'moment';

import { S } from './date-time.styles';

interface Props {
  dateTime: Date | string;
}

export const DateTime: React.FC<React.PropsWithChildren<Props>> = ({ dateTime }) => (
  <>
    <S.DateTime data-testid="date-time-component-date">{moment(dateTime).format('DD MMMM YYYY')} &bull;</S.DateTime>
    <S.DateTime data-testid="date-time-component-time">{moment(dateTime).format('HH:mm')}</S.DateTime>
  </>
);
