import * as Redux from 'redux';
import * as constants from './constants';
import { Actions } from './actions';

export interface State {
  tours: Array<string>;
  trigger_render: boolean;
  loading: boolean;
}

const InitialState: State = {
  tours: [],
  trigger_render: false,
  loading: true,
};

const TourReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SET_TOURS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case constants.GET_TOURS:
      return {
        ...state,
        loading: true,
        tours: action.tour,
      };
    case constants.UPDATE_TOURS:
      return {
        ...state,
        loading: false,
        tours: action.tour,
      };
    case constants.TRIGGER_TOURS:
      return {
        ...state,
        trigger_render: action.trigger,
      };
    default:
      return state;
  }
};

export default TourReducer;
