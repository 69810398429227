import styled from 'styled-components';

import ButtonBase from '@material-ui/core/ButtonBase';
import TypographyBase from '@material-ui/core/Typography';
import DividerBase from '@material-ui/core/Divider';

import { REBRAND_GREEN, WHITE, BLACK, BLACK_25, REBRAND_DARK_BLUE } from 'components/styles/colors';

const BUTTON_HORIZONTAL_MARGIN = 7;

const WRAPPER_HORIZONTAL_PADDING = 13;

const WRAPPER_HORIZONTAL_BIG_PADDING = 17;

interface NoAvailableProductsStyleProp {
  isHorizontalLayout?: boolean;
}

const Wrapper = styled.div<NoAvailableProductsStyleProp>`
  padding: 20px ${(props) => (props.isHorizontalLayout ? WRAPPER_HORIZONTAL_PADDING : WRAPPER_HORIZONTAL_BIG_PADDING)}px
    0px ${(props) => (props.isHorizontalLayout ? WRAPPER_HORIZONTAL_PADDING : WRAPPER_HORIZONTAL_BIG_PADDING)}px;
  display: flex;
  flex-direction: column;
`;

const ImageAndTextWrapper = styled.div<NoAvailableProductsStyleProp>`
  display: flex;
  flex-direction: ${(props) => (props.isHorizontalLayout ? 'row' : 'column')};
  align-items: center;
  margin-bottom: ${(props) => (props.isHorizontalLayout ? '8px' : '13px')};
`;

const Image = styled.img<NoAvailableProductsStyleProp>`
  && {
    display: 'block';
    height: ${(props) => (props.isHorizontalLayout ? '73px' : '140px')};
    width: ${(props) => (props.isHorizontalLayout ? '73px' : '140px')};
  }
`;

const TextWrapper = styled.div<NoAvailableProductsStyleProp>`
  display: flex;
  flex-direction: column;
  margin: 0px ${(props) => (props.isHorizontalLayout ? '0px' : '4px')} 0px
    ${(props) => (props.isHorizontalLayout ? '15px' : '4px')};
`;

const Title = styled(TypographyBase)<NoAvailableProductsStyleProp>`
  && {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: ${(props) => (props.isHorizontalLayout ? '600' : '700')};
    font-size: ${(props) => (props.isHorizontalLayout ? '14px' : '16px')};
    line-height: ${(props) => (props.isHorizontalLayout ? '19.1px' : '21.82px')};
    letter-spacing: 0.16px;
    color: ${BLACK};
    text-align: ${(props) => (props.isHorizontalLayout ? 'left' : 'center')};
    margin-bottom: ${(props) => (props.isHorizontalLayout ? '7px' : '8px')};
  }
`;

const Subtitle = styled(TypographyBase)<NoAvailableProductsStyleProp>`
  && {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    font-size: ${(props) => (props.isHorizontalLayout ? '13px' : '14px')};
    line-height: ${(props) => (props.isHorizontalLayout ? '17.73px' : '19.1px')};
    letter-spacing: 0.16px;
    text-align: ${(props) => (props.isHorizontalLayout ? 'left' : 'center')};
    color: ${BLACK};
  }
`;

const Button = styled(ButtonBase).attrs({ elevation: 0, variant: 'contained' })`
  && {
    background: ${REBRAND_GREEN};
    color: ${WHITE};
    height: 27px;
    box-shadow: none;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.37px;
    letter-spacing: 0.16px;
    margin: 0px ${BUTTON_HORIZONTAL_MARGIN}px 28px ${BUTTON_HORIZONTAL_MARGIN}px;
    width: calc(100% - ${BUTTON_HORIZONTAL_MARGIN * 2}px);
    padding: 5px 16px 5px 16px;
    border-radius: 20px;
  }
`;

const Divider = styled(DividerBase)<NoAvailableProductsStyleProp>`
  && {
    background-color: ${BLACK_25};
    margin: 0px ${(props) => (props.isHorizontalLayout ? '7px' : `-${WRAPPER_HORIZONTAL_BIG_PADDING}px`)} 16px
      ${(props) => (props.isHorizontalLayout ? '7px' : `-${WRAPPER_HORIZONTAL_BIG_PADDING}px`)};
  }
`;

const RelatesTitle = styled(TypographyBase)`
  && {
    margin: 12px 3px 16px 3px;
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.16px;
    color: ${REBRAND_DARK_BLUE};
  }
`;

export const NoAvailableProductsStyle = {
  Wrapper,
  ImageAndTextWrapper,
  Image,
  TextWrapper,
  Title,
  Subtitle,
  Button,
  Divider,
  RelatesTitle,
};
