import * as Redux from 'redux';
import * as constants from './constants';
import { Actions } from './actions';
import { AUTH_SESSION_ERRORS } from './constants';

export enum OrgMembershipTypeEnum {
  verified = 'verified',
  unverified = 'unverified',
  sipa = 'sipa',
  sipnap = 'sipnap',
}
export interface PtCoreUser {
  email: string;
  external_id: string;
  is_homepage: false;
  membership_status?: OrgMembershipTypeEnum[];
  organization_city: string;
  organization_id: number;
  organization_name: string;
  organization_region: string;
  organization_zipcode: string;
  user_id: string;
  user_name: string;
}

export type State = {
  authenticated: boolean;
  activated: string | null;
  agreed: boolean;
  tours: string[];
  error?: string;
  errorCode?: AUTH_SESSION_ERRORS;
  access_token?: string;
  refresh_token?: string;
  coreUser?: PtCoreUser;
};

const InitialState: State = {
  authenticated: false,
  activated: null,
  agreed: false,
  tours: [],
  errorCode: undefined,
  coreUser: undefined,
};

const AuthReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.AGREE_TO_TERMS:
      return {
        ...state,
        agreed: action.agreed,
        error: undefined,
      };

    case constants.FAIL_AUTH:
      return {
        ...state,
        authenticated: false,
        activated: null,
        agreed: false,
        user: undefined,
        error: action.error,
      };
    case constants.FAIL_AUTH_CODED:
      return {
        ...state,
        authenticated: false,
        activated: null,
        agreed: false,
        user: undefined,
        error: action.error,
        errorCode: action.errorCode as AUTH_SESSION_ERRORS,
      };
    case constants.SUCCEED_AUTHENTICATE:
      return {
        ...state,
        access_token: action.access_token,
        refresh_token: action.refresh_token,
        authenticated: true,
        activated: action?.activated || null,
        agreed: action.agreed,
        permission: action.permission,
        error: undefined,
        tours: action.tours,
        coreUser: action.coreUser,
      };
    default:
      return state;
  }
};

export default AuthReducer;
