import { useMemo } from 'react';

import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import sumBy from 'lodash/sumBy';

import { State as StoreState } from 'store';
import { Order, OrderStatus, PharmacyOrderStatus } from 'services';
import { ImageDimension, OrderStatusSequence } from 'utils/helpers';
import { LogisticDeliveryStatus } from 'services/swipe-rx-pt/resources/logistics-delivery/types';

export type OrderStatusHistory = {
  id: string;
  status: OrderStatus | LogisticDeliveryStatus;
  date: string;
  status_detail?: string;
  description?: string;
  photos?: string[];
  photosWithDimension?: ImageDimension[];
  received_by?: string;
};

interface IUserOrderStatusHistory {
  orderStatusHistories: OrderStatusHistory[];
  getPrimaryStatusHistoryTranslate: (orderStatusHistory: OrderStatusHistory) => string;
  getSecondaryStatusHistoryTranslate: (orderStatusHistory: OrderStatusHistory) => string;
  getStatusHistoryDate: (date: string, format: string) => string;
  shouldShowOnce: (status: OrderStatus) => boolean;
  hasDeliveryPhoto: (orderStatusHistory: OrderStatusHistory) => boolean;
}

export const useOrderStatusHistory = (t: any): IUserOrderStatusHistory => {
  const {
    purchaseOrder: { orderDetail, deliveries },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const { status_history, ordered_at, invoice, items = [] } = orderDetail as Order;
  const { return: returnHistories = [] } = invoice || {};

  const orderItemCount = sumBy(items, 'quantity');

  const orderStatusHistories = useMemo((): OrderStatusHistory[] => {
    const data: OrderStatusHistory[] = [];

    if (!status_history) return data;

    let dispatchedId: string | undefined;
    // eslint-disable-next-line max-len
    const logisticDeliveredIndex = deliveries.findIndex(
      (item) => item.status === LogisticDeliveryStatus.DELIVERY_DELIVERED,
    );
    // order.status_history
    Object.keys(status_history).forEach((key) => {
      const status = key as OrderStatus;
      const skipDelivered = status === OrderStatus.DELIVERED && logisticDeliveredIndex > -1;
      if (OrderStatusSequence.includes(status) && !skipDelivered) {
        const { date, status_detail } = status_history[status];

        const id = OrderStatusSequence.indexOf(status).toString();
        const history: OrderStatusHistory = {
          id,
          status,
          date,
          status_detail,
        };

        if (status === OrderStatus.PENDING) {
          history.date = ordered_at;
        } else if (typeof date === 'object' && date?.date) {
          history.date = status_history[status].date?.date;
        } else if (status === OrderStatus.DISPATCHED) {
          dispatchedId = id;
        }

        data.push({
          id,
          status,
          date,
          status_detail,
        });
      }
    });

    // logistic_delivery_status
    if (dispatchedId && deliveries.length) {
      deliveries.forEach(({ id, status, date, ...attrs }) => {
        data.push({
          ...attrs,
          id: `${dispatchedId}${id}`,
          status,
          date,
        });
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const returnHistory of returnHistories) {
      const returnedItemCount = sumBy(returnHistory.return_items, 'quantity');

      let status_detail = '';
      if (returnedItemCount > 0 && orderItemCount > returnedItemCount) {
        status_detail = t('orderStatus.deliveredPartialReturn');
      }

      if (returnedItemCount > 0 && orderItemCount <= returnedItemCount) {
        status_detail = t(`orderStatus.${PharmacyOrderStatus.CANCELLED_FULL_RETURN}`);
      }

      const id = OrderStatusSequence.indexOf(OrderStatus.RETURNED).toString();
      data.push({
        id,
        status: OrderStatus.RETURNED,
        date: moment(returnHistory.created_at).subtract(7, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        status_detail,
      });
    }

    // sorting by id and dated in lexicographical order
    data.sort((first, second) => {
      let sortResult = 0;
      const firstDate = moment.utc(first.date);
      const secondDate = moment.utc(second.date);

      if (firstDate.isSame(secondDate)) {
        sortResult = second.id > first.id ? 1 : -1;
      } else if (firstDate.isBefore(secondDate)) {
        sortResult = 1;
      } else if (firstDate.isAfter(secondDate)) {
        sortResult = -1;
      }

      return sortResult;
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status_history, ordered_at, deliveries]);

  const getPrimaryStatusHistoryTranslate = (orderStatusHistory: OrderStatusHistory): string => {
    const prefix = 'orderStatusHistory';
    const { status } = orderStatusHistory;
    return t([`${prefix}.${status}`, `${prefix}.DEFAULT`]);
  };

  const getSecondaryStatusHistoryTranslate = (orderStatusHistory: OrderStatusHistory): string => {
    const prefix = 'orderStatusDetailHistory';
    const { status, status_detail, description, received_by } = orderStatusHistory;

    const replacements: Record<string, string> = {};
    let tStatus = `${status}`;
    let tFallback = 'DEFAULT';

    switch (status) {
      case LogisticDeliveryStatus.DELIVERY_FAILED:
        if (status_detail) {
          tStatus += `_${status_detail}`;
          tFallback = description ? `${status}_description` : status;
        } else if (description) {
          tStatus += '_description';
          replacements.description = description.replace(/\|/g, '<br/>');
        }
        break;

      case OrderStatus.DELIVERED:
      case LogisticDeliveryStatus.DELIVERY_DELIVERED:
        if (received_by) {
          tStatus += '_received_by';
          replacements.received_by = received_by;
        }
        break;
    }

    return status === OrderStatus.RETURNED
      ? status_detail
      : t([`${prefix}.${tStatus}`, `${prefix}.${tFallback}`], replacements);
  };

  const getStatusHistoryDate = (date: string, format: string): string => moment.utc(date).local().format(format);

  const shouldShowOnce = (status: OrderStatus): boolean =>
    !(status === OrderStatus.PROCESSING && OrderStatus.ACCEPTED in status_history);

  const hasDeliveryPhoto = (orderStatusHistory: OrderStatusHistory): boolean => {
    const { status, photos } = orderStatusHistory;
    return (status as string) === LogisticDeliveryStatus.DELIVERY_DELIVERED && !!photos && photos.length > 0;
  };

  return {
    orderStatusHistories,
    getPrimaryStatusHistoryTranslate,
    getSecondaryStatusHistoryTranslate,
    getStatusHistoryDate,
    shouldShowOnce,
    hasDeliveryPhoto,
  };
};
