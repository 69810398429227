export enum AnalyticsActionTypes {
  CAUSAL_FOUNDRY_SET_CFLOG = '@@analytics/CAUSAL_FOUNDRY_SET_CFLOG',
  CAUSAL_FOUNDRY_SET_SEARCHID = '@@analytics/CAUSAL_FOUNDRY_SET_SEARCHID',
  CAUSAL_FOUNDRY_SET_CARTID = '@@analytics/CAUSAL_FOUNDRY_SET_CARTID',
}

export interface CausalFoundrySetCfLogAction {
  type: AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_CFLOG;
  cfLog: any;
}

export interface CausalFoundrySSetSearchIdAction {
  type: AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_SEARCHID;
  searchId: string;
}

export interface CausalFoundrySSetCartIdAction {
  type: AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_CARTID;
  cartId: string;
}

export type Actions = CausalFoundrySetCfLogAction | CausalFoundrySSetSearchIdAction | CausalFoundrySSetCartIdAction;
