import Redux from 'redux';

import { Product } from '../Product';
import { Actions } from './actions';
import { ActionType } from './constants';

export interface State {
  isLoading: boolean;
  isModalOpen: boolean;
  isProductUpdated: boolean;
  hasSimilarProducts: boolean;
  productId?: number | null;
  prevProductId?: number | null;
  product?: Product;
}

const initialState: State = {
  isLoading: false,
  isModalOpen: false,
  isProductUpdated: false,
  hasSimilarProducts: false,
  productId: null,
  prevProductId: null,
  product: undefined,
};

export const ProductDetailReducer: Redux.Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SetProduct:
      return {
        ...state,
        isLoading: false,
        product: action.product,
        productId: action.productId,
      };
    case ActionType.SetLoading:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionType.SetIsProductUpdated:
      return {
        ...state,
        isProductUpdated: action.isProductUpdated,
      };
    case ActionType.SetHasSimilarProducts:
      return {
        ...state,
        hasSimilarProducts: action.hasSimilarProducts,
      };
    case ActionType.SetModalToggle:
      return {
        ...state,
        isModalOpen: action.isModalOpen,
      };
    case ActionType.SetProductId:
      return {
        ...state,
        productId: action.productId,
      };
    case ActionType.SetPrevProductId:
      return {
        ...state,
        prevProductId: action.prevProductId,
      };
    case ActionType.ClearProductDetail:
      return {
        ...state,
        prevProductId: state.productId,
        product: undefined,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ProductDetailReducer;
