import React from 'react';

import { ButtonBase, Card, Grid, TextField, Typography } from '@material-ui/core';
import styled from 'styled-components';
import CircularProgressBase from '@material-ui/core/CircularProgress';

import { Card as SharedCard } from 'components/common';
import * as colors from 'components/styles/colors';
import ScrollView from 'components/views/ScrollView';
import isNil from 'lodash/isNil';

const ArrowBackIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5467 14.12L4.44002 8L10.5467 1.88L8.66669 0L0.666687 8L8.66669 16L10.5467 14.12Z" fill="black" />
  </svg>
);

const Wrapper = styled(ScrollView)`
  height: 100vh;
  background-color: ${colors.SNOW};
`;

const Header = styled(Grid)`
  && {
    padding: 22px 15px 15px 15px;
    background-color: ${colors.WHITE};
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #e8eaed;
  }
`;

const ButtonBack = styled(ButtonBase)`
  && {
    padding-right: 10px;
  }
`;
const BackIcon = styled(ArrowBackIcon)`
  && {
    padding-right: 10px;
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    color: ${colors.BLACK};
  }
`;

const OrderSectionCard = styled(Card).attrs({ elevation: 0 })`
  && {
    padding: 10px 15px 15px 15px;
  }
`;

const SubmitSectionCard = styled(Card).attrs({ elevation: 0 })`
  && {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    padding: 15px;
  }
`;

const RatingSectionCard = styled(Card).attrs({ elevation: 0 })`
  && {
    padding: 10px 15px 15px 15px;
    text-align: center;
  }
`;

const FeedbackSectionCard = styled(Card).attrs({ elevation: 0 })`
  && {
    padding: 10px 15px 15px 15px;
  }
`;

const OrderSectionTitle = styled(Typography)`
  && {
    font-size: 12pt;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    color: ${colors.BLACK};
  }
`;

const FeedbackSectionTitle = styled(Typography)`
  && {
    font-size: 12pt;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    color: ${colors.BLACK};
    padding-left: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`;

export interface FeedbackSectionSubTitleProps {
  marginTop?: number;
}

const FeedbackSectionSubTitle = styled(Typography)<FeedbackSectionSubTitleProps>`
  && {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    margin-top: ${(props) => (!isNil(props.marginTop) ? props.marginTop : 20)}px;
    margin-bottom: 10px;
    color: ${colors.PRIMARY_COLOR};
  }
`;

const FeedbackSectionTextarea = styled(TextField).attrs({
  multiline: true,
  rows: 3,
  fullWidth: true,
  variant: 'outlined',
})``;

const OrderSectionInfo = styled(Typography)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 10pt;
    line-height: 150%;
    letter-spacing: 0.16px;
    color: ${colors.LIGHT_GRAY};
  }
`;

const OrderSectionSubTitle = styled(Typography)`
  && {
    font-size: 12pt;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
  }
`;

const OrderSectionImage = styled.img`
  width: 100%;
  max-width: 70px;
  max-height: 70px;
`;

const RatingSectionImage = styled.img`
  width: 100%;
  max-width: 125px;
  max-height: 125px;
`;

const EntrypointWrapper = styled(SharedCard)`
  padding: 22px;
  background: ${colors.TRANSPARENT};
  box-shadow: none;
`;

const LetsRateYourOrder = styled(Typography)`
  && {
    margin-top: 6px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1599999964237213px;
    text-align: center;
    color: ${colors.BLACK};
  }
`;

const HowDidYouLikeYourOrder = styled(Typography)`
  && {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.1599999964237213px;
    line-height: 20px;
    text-align: center;
    color: ${colors.TEXT_LIGHT};
    padding-left: 36px;
    padding-right: 36px;
  }
`;

const TapTheStarToRate = styled(Typography)`
  && {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1599999964237213px;
    text-align: center;
    color: ${colors.LIGHT_GRAY};
  }
`;

const Rate = styled.div`
  && {
    font-style: normal;
    font-weight: 700;
    font-size: 16pt;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.16px;
    margin-bottom: 10px;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
`;

const CircularProgress = styled(CircularProgressBase).attrs({ size: 18 })`
  && {
    color: ${colors.WHITE};
  }
`;

const SubmitSectionButton = styled(ButtonBase).attrs({ variant: 'contained' })`
  && {
    border-radius: 40px;
    width: calc(100% - 30px);
    color: ${colors.WHITE};
    background: ${colors.PRIMARY_COLOR};
    padding: 14px 0px;
    font-size: 12pt;
    font-weight: 600;
    text-transform: uppercase;
  }

  &.Mui-disabled {
    background: ${colors.BLUISH_GRAY};
  }
`;

export const S = {
  BackIcon,
  ButtonBack,
  CircularProgress,
  EntrypointWrapper,
  FeedbackSectionCard,
  FeedbackSectionSubTitle,
  FeedbackSectionTextarea,
  FeedbackSectionTitle,
  Header,
  HowDidYouLikeYourOrder,
  LetsRateYourOrder,
  OrderSectionCard,
  OrderSectionImage,
  OrderSectionInfo,
  OrderSectionSubTitle,
  OrderSectionTitle,
  Rate,
  RatingSectionCard,
  RatingSectionImage,
  SubmitSectionButton,
  SubmitSectionCard,
  TapTheStarToRate,
  Title,
  Wrapper,
};
