import styled from 'styled-components';
import { GRAY615 } from 'components/styles/colors';

export const Img = styled.img<any>`
  border-bottom: 1px solid ${GRAY615};
  display: block;
  outline: none;
  border: none;
  width: 100%;
  max-height: 530px;
  object-fit: contain;
`;

export const ImgWrapper = styled.div<any>`
  background-color: ${(props) => (!props.grayed ? 'none' : `${GRAY615}`)};
  border-bottom: 1px solid ${GRAY615};
  height: ${(props) => props.width};
  width: 100%;
  max-height: 530px;
  padding-top: 10px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
`;
