import { State as StoreState } from 'store';

import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { Product } from 'store/reducers/Product';
import { convertToHex } from 'utils/converters/HexChar.converter';
import { pageTracker } from 'utils/Analytics/Segment';
import { WidgetListLoaded } from 'store/reducers/Global/actions';
import { DEFAULT_MARKETING_LIST_PAGE_SIZE } from 'utils/constants';
import { getNumberEnvWithDefault } from 'utils/envs/env-number-workaround';
import { GENERIC_PRODUCT_WIDGET } from './widget.constant';
import { WidgetParams } from './widget.component';

const getMarketingListPageSize = (): number => {
  const marketingPageSize = getNumberEnvWithDefault(
    process.env.REACT_APP_MARKETING_LIST_PAGE_SIZE,
    DEFAULT_MARKETING_LIST_PAGE_SIZE,
  );

  return marketingPageSize;
};

export const MARKETING_LIST_PAGE_SIZE = getMarketingListPageSize();

export const prepareContentForCss = (content: string): string => {
  const invalidCharsForContent = ["'"];
  let cleanedContent = content;
  invalidCharsForContent.forEach((invalidChar: string) => {
    cleanedContent = cleanedContent.replace(invalidChar, `\\${convertToHex(invalidChar)}`);
  });
  return cleanedContent;
};

export const getWidgetParamByMarketingType = (
  marketingType: MarketingType,
  product: Product,
  excludedProductIds?: number[],
): { params?: WidgetParams } => {
  let widgetParams = {};

  if (marketingType === MarketingType.SIMILAR_PRODUCT) {
    widgetParams = { params: { sku_code: product.sku_code, excluded_product_ids: excludedProductIds } };
  }

  return widgetParams;
};

interface LoadingStatus {
  isProductLoading: boolean;
  isBannerLoading: boolean;
  isHomesquareLoading: boolean;
}

export const getIsWidgetLoading = (marketingType: MarketingType, loadingStatus: LoadingStatus): boolean => {
  let isWidgetLoading = false;
  if (GENERIC_PRODUCT_WIDGET.includes(marketingType)) {
    isWidgetLoading = loadingStatus.isProductLoading;
  } else if (marketingType === MarketingType.BANNER) {
    isWidgetLoading = loadingStatus.isBannerLoading;
  } else if (marketingType === MarketingType.HOMESQUARE) {
    isWidgetLoading = loadingStatus.isHomesquareLoading;
  }
  return isWidgetLoading;
};

export const getPriorityLoadWidgetCount = () => {
  const priorityWidgetCount = Math.max(Number.parseInt(process.env.REACT_APP_PRIORITY_WIDGET_COUNT || '0', 10) || 0, 0);
  const safePriorityWidget = Math.min(priorityWidgetCount, MARKETING_LIST_PAGE_SIZE);
  return safePriorityWidget;
};

export const getIsPriorityWidgetsFinishLoad = (state: StoreState) => {
  const priorityLoadWidgetCount = getPriorityLoadWidgetCount();
  const isNeedWaitPriorityLoadWidgets =
    (pageTracker.isLanding() || pageTracker.isHomepage()) && priorityLoadWidgetCount > 0;

  const isPriorityWidgetFinishLoad = isNeedWaitPriorityLoadWidgets
    ? state.global?.widgetListLoaded
        .slice(0, priorityLoadWidgetCount)
        .every((widgetLoaded: WidgetListLoaded) => widgetLoaded?.finishLoaded === true)
    : true;

  return isPriorityWidgetFinishLoad;
};

export const randomizeWidgets = <T>(widgets: T[]): T[] => {
  const randomizedWidgets = widgets
    .map((value) => ({
      value,
      random: Math.floor(Math.random() * 100),
    }))
    .sort((a, b) => a.random - b.random)
    .map(({ value }) => value);

  return randomizedWidgets;
};
