import { ThunkActionCreator } from 'types/thunk';
import { State } from 'store';
import { BasePageTracker, ImpressionType } from 'utils/Analytics/Segment';

import { Actions } from './interface';
import * as constants from './constants';

export const setProductImpression: ThunkActionCreator<Actions, State> =
  (payload: { displayType: ImpressionType; productIds: number[]; marketingId?: number; page?: BasePageTracker }) =>
  async (dispatch, getState) => {
    const { displayType, productIds, marketingId, page } = payload || {};
    const state = getState();
    const currentImpression = state.productImpression || {};
    const { carousel = {}, listing, productDetailCarousel = {} } = currentImpression || {};

    try {
      if (!displayType) {
        throw new Error('display type is required');
      }

      if (!productIds?.length) {
        throw new Error('Product ID is required');
      }

      if (displayType === ImpressionType.CAROUSEL && !marketingId) {
        throw new Error('Marketing ID is required if display type is carousel');
      }

      let newListing = [...listing];
      let newCarousel = { ...carousel };
      let newpPoductDetailCarousel = { ...productDetailCarousel };
      if (displayType === ImpressionType.LISTING) {
        newListing = [...newListing, ...productIds];
      }

      if (displayType === ImpressionType.CAROUSEL && marketingId) {
        if (page === BasePageTracker.PRODUCT_DETAIL) {
          newpPoductDetailCarousel = {
            ...newpPoductDetailCarousel,
            [marketingId]: [
              ...(productDetailCarousel?.[marketingId] ? [...productDetailCarousel?.[marketingId]] : []),
              ...productIds,
            ],
          };
        } else {
          newCarousel = {
            ...carousel,
            [marketingId]: [...(carousel?.[marketingId] ? [...carousel?.[marketingId]] : []), ...productIds],
          };
        }
      }

      dispatch({
        type: constants.SET_PRODUCT_IMPRESSION_STATE,
        carousel: newCarousel,
        listing: newListing,
        productDetailCarousel: newpPoductDetailCarousel,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

export const clearProductImpression: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_PRODUCT_IMPRESSION,
  });
};

export const clearProductDetailImpression: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_PRODUCT_DETAIL_IMPRESSION,
  });
};
