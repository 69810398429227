/* eslint-disable react/require-default-props */
import React from 'react';
import { translate } from 'react-i18next';

import { OrderStatus } from 'services/swipe-rx-pt/resources/orders/types';

import { LogisticDeliveryStatus, Order } from 'services';
import { DataObject } from 'types';
import { useOrderStatus } from 'hooks';
import {
  OrderConfirmIcon,
  OrderProcessIcon,
  OrderOnDeliveryIcon,
  OrderDeliveredIcon,
  OrderCancelledIcon,
} from './orders-status-modal-sequence-icon.component';
import { S } from './orders-status-modal-sequence.styles';
import { useOrderStatusHistory } from './use-order-status-history.hook';

type SequenceType = 'orderReceived' | 'preparingGoods' | 'outToDeliver' | 'delivered' | 'cancelled';

interface Props {
  t: any;
  order: Order | DataObject;
  delivery: Order['logistics_delivery_status'];
}

const OrderStatusSequence: React.FC<React.PropsWithChildren<Props>> = ({ order, t, delivery = null }) => {
  const { status, fulfillment_status, status_history } = order;

  const { orderStatusHistories, getStatusHistoryDate } = useOrderStatusHistory(t);

  const sequenceMap: { [x in SequenceType]: string[] } = {
    delivered: ['completed', 'delivered', 'fulfilled'],
    outToDeliver: ['completed', 'delivered', 'dispatched', 'fulfilled', 'cancelled'],
    preparingGoods: ['completed', 'delivered', 'processing', 'dispatched', 'accepted', 'fulfilled', 'cancelled'],
    orderReceived: [
      'pending',
      'processing',
      'accepted',
      'dispatched',
      'completed',
      'delivered',
      'fulfilled',
      'cancelled',
    ],
    cancelled: ['cancelled', 'returned'],
  };

  const isCancelled = status === OrderStatus.CANCELLED || delivery?.status === LogisticDeliveryStatus.DELIVERY_FAILED;
  const isProcessed = !isCancelled || (isCancelled && !!status_history.processing);
  const isDispatched = !isCancelled || (isCancelled && !!status_history.dispatched);
  const isDelivered = !isCancelled;

  const IsSequenceActive = (sequence: SequenceType): boolean => {
    if (isCancelled) return false;
    const isDelivery = ['outToDeliver', 'delivered'].includes(sequence);
    const seqStatus = isDelivery ? fulfillment_status : status;

    return sequenceMap[sequence].includes(seqStatus);
  };

  const {
    status: mappedStatus,
    values,
    hasPrekursor,
    hasOrderChanges,
    showDeliveryDelayedLabel,
  } = useOrderStatus(order, t);

  return (
    <S.Wrapper>
      <S.MiniLabel>Status</S.MiniLabel>
      <S.SequenceContainer>
        <S.SequenceIconContainer className={IsSequenceActive('orderReceived') ? 'active' : ''}>
          <OrderConfirmIcon
            active={IsSequenceActive('orderReceived')}
            variant={hasPrekursor ? 'secondary' : 'primary'}
          />
        </S.SequenceIconContainer>
        {isProcessed && (
          <S.SequenceIconContainer className={IsSequenceActive('preparingGoods') ? 'active' : ''}>
            <OrderProcessIcon
              active={IsSequenceActive('preparingGoods')}
              variant={hasOrderChanges ? 'secondary' : 'primary'}
            />
          </S.SequenceIconContainer>
        )}
        {isDispatched && (
          <S.SequenceIconContainer className={IsSequenceActive('outToDeliver') ? 'active' : ''}>
            <OrderOnDeliveryIcon
              active={IsSequenceActive('outToDeliver')}
              variant={showDeliveryDelayedLabel ? 'secondary' : 'primary'}
            />
          </S.SequenceIconContainer>
        )}
        {isDelivered && (
          <S.SequenceIconContainer className={IsSequenceActive('delivered') ? 'active' : ''}>
            <OrderDeliveredIcon active={IsSequenceActive('delivered')} />
          </S.SequenceIconContainer>
        )}
        {isCancelled && (
          <S.SequenceIconContainer className="order-cancelled active">
            <OrderCancelledIcon active />
          </S.SequenceIconContainer>
        )}
      </S.SequenceContainer>
      <S.StatusText status={mappedStatus} deliverystatus={delivery?.status}>
        {values.join(' - ')}
      </S.StatusText>
      <S.StatusDate>{getStatusHistoryDate(orderStatusHistories[0].date, 'DD MMMM YYYY | HH:mm')}</S.StatusDate>
    </S.Wrapper>
  );
};

export const OrderStatusModalSequence = translate('orders')(OrderStatusSequence);
