import { Order, OrderItem, OrderStatus } from 'services';
import { IReturnItemExtended } from 'store/reducers/Return';

export const useOrderItemReturn = (order: Order): [OrderItem[], OrderItem[], IReturnItemExtended[]] => {
  if (!order.items) return [[], [], []];

  const undeliveredStatusMap = [
    OrderStatus.PENDING,
    OrderStatus.PROCESSING,
    OrderStatus.ACCEPTED,
    OrderStatus.DISPATCHED,
    OrderStatus.CANCELLED,
  ];

  const returnedItem: IReturnItemExtended[] = [];
  const deliveredItems = undeliveredStatusMap.includes(order.status) ? [] : order.items;
  const orderedItems = undeliveredStatusMap.includes(order.status) ? order.items : [];

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < order.items.length; index++) {
    const item = order.items[index];

    const orderItemReturned = item.return_reason && item.return_reason.length > 0;

    if (orderItemReturned) {
      const quantity = item.previous_quantity ? item.previous_quantity - item.quantity : item.quantity;
      const discount = item.previous_discount_rate ? item.previous_discount_rate - item.quantity : item.discount_rate;
      const amount = item.net_price * quantity;

      returnedItem.push({
        id: 0,
        order_item_id: item.id,
        quantity,
        price: item.net_price,
        discount,
        net: amount,
        order_item: {
          ...item,
          previous_quantity: null,
          quantity,
        },
        reason: item.return_reason,
      });
    }
  }

  if (order.invoice?.return && order.invoice?.return.length > 0) {
    returnedItem.push(
      ...order.invoice.return
        .map((returned) => returned.return_items)
        .flat()
        .reduce((summary, item) => {
          const index = summary.findIndex((product) => product.order_item_id === item.order_item_id);

          if (index >= 0) {
            const orderItem = summary[index].order_item;

            summary[index].order_item = {
              ...orderItem,
              quantity: orderItem.quantity + item.quantity,
            } as OrderItem;
          } else {
            const orderItem = order.items?.find((i) => i.id === item.order_item_id);

            summary.push({
              ...item,
              order_item: {
                ...orderItem,
                quantity: item.quantity,
              } as OrderItem,
            });
          }

          return summary;
        }, [] as IReturnItemExtended[]),
    );
  }

  return [orderedItems, deliveredItems, returnedItem];
};
