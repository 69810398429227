import React, { useEffect } from 'react';
import { translate } from 'react-i18next';
import { store } from 'store';

import { Box, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useTierPricing } from 'hooks/useTierPricing';
import { TranslateProps } from 'utils/Localization';
import { toPercent } from 'utils/Format';
import { addOrUpdateProductQuantity } from 'store/reducers/Counter/actions';

import { useDispatch } from 'react-redux';
import { useAddProductAnalytics } from 'hooks/useAddProductAnalytics';
import { trackViewTierPricingItem as causalFoundryTrackViewTierPricingItem } from 'utils/Analytics/CausalFoundry';
import {
  BuyButton,
  CloseButton,
  LockBuyButton,
  TierButton,
  TierContainer,
  TierPricingDrawer,
} from './TierPricingSheet.style';
import { TierPricingSheetProps } from './TierPricingSheet.interface';
import { useUnverifiedInfoHook } from '../snackbar';

const Component: React.FC<React.PropsWithChildren<TierPricingSheetProps & TranslateProps>> = ({
  open,
  product,
  closeHandler,
  marketingQuery,
  t,
}) => {
  const dispatch = useDispatch();
  const { marketing_id, marketing_name } = marketingQuery || {};

  const { auth, filterToolbar } = store.getState();
  const { trackProductWithMarketing } = useAddProductAnalytics(
    marketing_id,
    marketing_name,
    filterToolbar.selectedFilters,
  );

  const { itemCount } = useTierPricing(product, false);

  const { showMessage } = useUnverifiedInfoHook(auth, product);

  const handleClick = (quantity: number): void => {
    dispatch(addOrUpdateProductQuantity(product, quantity));
  };

  useEffect(() => {
    causalFoundryTrackViewTierPricingItem(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tiers = product.tier_discount.map((promo, index) => {
    const max = product.tier_discount[index + 1] ? product.tier_discount[index + 1].min - 1 : +Infinity;
    return (
      <TierButton
        key={promo.id}
        isActive={itemCount >= promo.min && itemCount <= max}
        onClick={() => handleClick(promo.min)}
        disabled={product.is_unpurchasable}
      >
        {t('getDiscount', { discount: toPercent(promo.discount_rate, 2) })}
        &nbsp;
        {t('buyMinimum', { quantity: promo.min })}
      </TierButton>
    );
  });

  return (
    <TierPricingDrawer anchor="bottom" open={open} onClose={closeHandler}>
      <Box display="flex" alignItems="baseline">
        <Typography variant="subtitle1" gutterBottom style={{ flex: 1, fontSize: 18 }}>
          <strong>
            {t('getUpTo', {
              discount: toPercent(product.tier_discount[product.tier_discount.length - 1].discount_rate, 2),
            })}
          </strong>
        </Typography>
        <CloseButton size="small" onClick={closeHandler}>
          <Close fontSize="small" />
        </CloseButton>
      </Box>
      <TierContainer>{tiers}</TierContainer>
      {product.is_unpurchasable ? (
        <LockBuyButton onClick={showMessage}>{t('buy')}</LockBuyButton>
      ) : (
        <BuyButton
          color="primary"
          variant="contained"
          onClick={() => {
            closeHandler();
            trackProductWithMarketing(product);
          }}
        >
          {t('buy')}
        </BuyButton>
      )}
    </TierPricingDrawer>
  );
};

export const TierPricingSheet = translate('tierDiscountSheet')(Component);
