import React from 'react';
import throttle from 'lodash/throttle';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Badge from '@material-ui/core/Badge';
import styled from 'styled-components';

import { shallowEqual, useSelector } from 'react-redux';
import { State } from 'store';

import { TranslateProps } from 'utils/Localization/types';
import { isDirectPaymentEnabled, getPaymentMethod } from 'utils/MarketConfig';
import { AllZIndexes, PaymentMethods } from 'utils/constants';
import { routeKey } from 'utils/route.utils';
import { PharmacyOrderStatus } from 'services';
import * as colors from '../../styles/colors';
import { S } from './BottomNav.styles';
import {
  HomeIconOutlined,
  HomeIconFilled,
  ProductIconFilled,
  ProductIconOutlined,
  PaymentsIconFilled,
  PaymentsIconOutlined,
  OrdersIconFilled,
  OrdersIconOutlined,
  AccountsIconFilled,
  AccountsIconOutlined,
} from './nav-icons';
import { TABNAME_INDEX } from './constants';
import { ConditionalRender } from '../ConditionalRender';

export interface Props {
  value?: string;
  history;
  cartBadge?: number;
  onNavigate: (page: string) => () => void;
}

export interface ActionProps {
  icon: JSX.Element;
  activeIcon: JSX.Element;
  text: string;
  value: string;
  badge?: string | number;
  hasNotif?: boolean;
}

const ActionBadge = styled(Badge)`
  span {
    top: 50%;
    left: 50%;
    transform: translate(15%, -80%);
    border: 2px solid ${colors.PRIMARY_COLOR};
  }
`;

const ActionLabel: React.FC<React.PropsWithChildren<ActionProps>> = (props) => {
  const { icon, activeIcon, text, badge, value, hasNotif } = props;

  const location = useLocation();
  const pathname = location.pathname.split('/')[TABNAME_INDEX];

  const isActive = pathname === value;

  const label = (
    <S.Label className="bottomNav__label" container direction="column" alignItems="center">
      <ConditionalRender condition={isActive && !!hasNotif}>
        <S.Dot />
      </ConditionalRender>
      <Grid style={{ paddingBottom: 1 }}>{isActive ? activeIcon : icon}</Grid>
      <span className={isActive ? 'bottomNav__label__active' : ''}>{text}</span>
    </S.Label>
  );

  if (typeof badge !== 'undefined')
    return (
      <ActionBadge color="secondary" badgeContent={badge}>
        {label}
      </ActionBadge>
    );
  return label;
};

const BottomNav: React.FC<React.PropsWithChildren<Props & TranslateProps>> = (props) => {
  const { value, onNavigate, t } = props;

  const {
    activities: { unread },
  } = useSelector((state: State) => state.purchaseOrder, shallowEqual);

  const paymentPath =
    isDirectPaymentEnabled() && getPaymentMethod() === PaymentMethods.DIRECT_PAYMENT ? 'unpaid' : 'due';

  return (
    <BottomNavigation value={value} showLabels id="tour__bottomNav" style={{ zIndex: AllZIndexes.BottomNav }}>
      <S.BottomNavAction
        id="tour__home"
        label={
          <ActionLabel
            icon={<HomeIconOutlined fill={colors.BLUISH_GRAY_LIGHTER} />}
            activeIcon={<HomeIconFilled fill={colors.PRIMARY_COLOR} />}
            value="home"
            text={t('bottomNav.home')}
          />
        }
        value="home"
        onClick={throttle(onNavigate(routeKey('main_home')), 200)}
      />
      <S.BottomNavAction
        id="tour__products"
        label={
          <ActionLabel
            icon={<ProductIconOutlined fill={colors.BLUISH_GRAY_LIGHTER} />}
            activeIcon={<ProductIconFilled fill={colors.PRIMARY_COLOR} />}
            value="products"
            text={t('bottomNav.products')}
          />
        }
        value="products"
        onClick={throttle(onNavigate(routeKey('main_products')), 200)}
      />
      <S.BottomNavAction
        id="tour__payments"
        label={
          <ActionLabel
            icon={<PaymentsIconOutlined fill={colors.BLUISH_GRAY_LIGHTER} />}
            activeIcon={<PaymentsIconFilled fill={colors.PRIMARY_COLOR} />}
            value="payments"
            text={t('bottomNav.payments')}
          />
        }
        value="payments"
        onClick={throttle(onNavigate(`${routeKey('main_payments')}/${paymentPath}`), 200)}
      />
      <S.BottomNavAction
        id="tour__order"
        label={
          <ActionLabel
            icon={<OrdersIconOutlined fill={colors.BLUISH_GRAY_LIGHTER} />}
            activeIcon={<OrdersIconFilled fill={colors.PRIMARY_COLOR} />}
            value="orders"
            text={t('bottomNav.orders')}
            hasNotif={!!unread}
          />
        }
        value="orders"
        onClick={throttle(onNavigate(`${routeKey('main_orders_history')}/${PharmacyOrderStatus.ALL_ORDERS}`), 200)}
      />
      <S.BottomNavAction
        id="tour__accounts"
        label={
          <ActionLabel
            icon={<AccountsIconOutlined fill={colors.BLUISH_GRAY_LIGHTER} />}
            activeIcon={<AccountsIconFilled fill={colors.PRIMARY_COLOR} />}
            value="accounts"
            text={t('bottomNav.account')}
          />
        }
        value="accounts"
        onClick={throttle(onNavigate(routeKey('main_accounts')), 200)}
      />
    </BottomNavigation>
  );
};

export default BottomNav;
