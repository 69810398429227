// TODO: SPT-16172: confirm if can be deleted
export const marketingSearchPlaceHolder = {
  '2022-04-08': 'Mylanta Ekstra Diskon',
  '2022-04-09': 'Mylanta Ekstra Diskon',
  '2022-04-10': 'Mylanta Ekstra Diskon',
  '2022-04-11': 'Mylanta Ekstra Diskon',
  '2022-04-12': 'Lanzoprazole Diskon 45%',
  '2022-04-13': 'Gastrucid Diskon 31%',
  '2022-04-14': 'Tempra Ekstra Diskon',
  '2022-04-15': 'Special Weekend Deals',
  '2022-04-16': 'Neuralgin Ekstra Diskon',
  '2022-04-17': 'Fasidol Diskon 20%',
  '2022-04-18': 'Belanja Obat Pereda Nyeri Otot',
  '2022-04-19': 'Belanja Obat Pereda Nyeri Otot',
  '2022-04-20': 'Belanja Obat Pereda Nyeri Otot',
  '2022-04-21': 'Belanja Obat Pereda Nyeri Otot',
  '2022-04-22': 'Belanja Obat Pereda Nyeri Otot',
  '2022-04-23': 'Belanja Obat Pereda Nyeri Otot',
  '2022-04-24': 'Belanja Obat Pereda Nyeri Otot',
  '2022-04-25': 'Daily Deals',
  '2022-04-26': 'Flash Sale',
  '2022-04-27': 'Clearance Sale',
  '2022-04-28': 'Daily Deals',
  '2022-04-29': 'Flash Sale',
  '2022-04-30': 'Clearance Sale',
};

export default marketingSearchPlaceHolder;
