import {
  AnalyticsActionTypes,
  CausalFoundrySetCfLogAction,
  CausalFoundrySSetCartIdAction,
  CausalFoundrySSetSearchIdAction,
} from './analytics.type';

export const setCausalFoundryCfLog = (cfLog: any): CausalFoundrySetCfLogAction => ({
  type: AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_CFLOG,
  cfLog,
});

export const setCausalFoundrySearchId = (searchId: string): CausalFoundrySSetSearchIdAction => ({
  type: AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_SEARCHID,
  searchId,
});

export const setCausalFoundryCartId = (cartId: string): CausalFoundrySSetCartIdAction => ({
  type: AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_CARTID,
  cartId,
});
