import * as Redux from 'redux';

import { IDeposit } from 'services/swipe-rx-pt/resources/deposit/interfaces/deposit.interface';
import { InvoiceStatus, InvoiceSummary } from 'services';

import { PaymentActionTypes } from './constants';
import { Actions } from './actions';

export interface BankAccount {
  account_name: string;
  account_number: string;
  bank_name: string;
  bank_code: string;
}

export interface Deposit extends IDeposit {
  allocations: {
    invoices: Payments[];
    meta: Meta;
  };
}

export interface DPDBlockStatus {
  blocked: boolean;
  daysPastDue: number;
  pastDueInvoiceCount: number;
}

export interface Meta {
  total_count: number;
  page: number;
  page_size: number;
  page_count: number;
}

export interface Payments {
  id: number;
  orderId: number;
  status: InvoiceStatus;
  po_number: string;
  invoice_number: string;
  distributor: string;
  invoiced_amount: number;
  paid_amount: number;
  total_amount: number;
  paid_at: Date;
  ordered_at: Date;
  invoiced_at: Date;
  due_date: Date;
  payment_status: string;
  expected_amount: number;
}

interface PaymentsData {
  loading: boolean;
  data: Payments[];
  meta: Meta;
  total_amount?: number;
}

export interface PaymentInformation {
  hidden: boolean;
  showDepositRequestWarning: boolean;
  loading: boolean;
}

export enum PaymentOptionChoice {
  PAY_ANY_AMOUNT = 'PAY_ANY_AMOUNT',
  TOTAL_DUE_INVOICE = 'TOTAL_DUE_INVOICE',
  SELECTED_INVOICE = 'SELECTED_INVOICE',
  VOUCHER = 'VOUCHER',
}
export interface PaymentOptionData {
  loading: boolean;
  hidden: boolean;
  selectedOption: PaymentOptionChoice;
}

export interface State {
  bankAccount?: BankAccount;
  dpdBlockStatus: DPDBlockStatus;
  paymentsTotal: {
    allDue: number;
    dueToday: number;
    dueWeek: number;
    notDue: number;
  };
  allDuePayments: PaymentsData;
  dueTodayPayments: PaymentsData;
  dueWithinWeekPayments: PaymentsData;
  notYetDuePayments: PaymentsData;
  paymentHistory: PaymentsData;
  paymentSelection: PaymentsData;
  paymentOptionData: PaymentOptionData;
  paymentInformation: PaymentInformation;
  paid: {
    loading: boolean;
    total: number;
    data: Deposit[];
    meta: Meta;
  };
  error?: string;
  downloadInvoiceSummary: {
    loading: boolean;
    recipientEmail: string | null;
    isDownloadSuccess: boolean;
    showFailedMessage: boolean;
  };
  invoiceSummary: InvoiceSummary;
}

export const initialMeta = {
  page: 0,
  total_count: 0,
  page_count: 0,
  page_size: 0,
};

const initialPayments = {
  loading: false,
  data: [],
  meta: {
    ...initialMeta,
  },
};

const initialPaymentsTotal = {
  allDue: 0,
  dueToday: 0,
  dueWeek: 0,
  notDue: 0,
};

const InitialState: State = {
  bankAccount: undefined,
  dpdBlockStatus: {
    blocked: false,
    daysPastDue: 0,
    pastDueInvoiceCount: 0,
  },
  paymentsTotal: {
    allDue: 0,
    dueToday: 0,
    dueWeek: 0,
    notDue: 0,
  },
  allDuePayments: {
    ...initialPayments,
  },
  dueTodayPayments: {
    ...initialPayments,
  },
  dueWithinWeekPayments: {
    ...initialPayments,
  },
  notYetDuePayments: {
    ...initialPayments,
  },
  paymentHistory: {
    ...initialPayments,
  },
  paymentSelection: {
    ...initialPayments,
    total_amount: 0,
  },
  paymentOptionData: {
    hidden: true,
    loading: false,
    selectedOption: PaymentOptionChoice.PAY_ANY_AMOUNT,
  },
  paymentInformation: {
    loading: false,
    hidden: true,
    showDepositRequestWarning: false,
  },
  paid: {
    loading: false,
    total: 0,
    data: [],
    meta: { ...initialMeta },
  },
  downloadInvoiceSummary: {
    loading: false,
    recipientEmail: null,
    isDownloadSuccess: false,
    showFailedMessage: false,
  },
  invoiceSummary: {
    due_invoices: 0,
    non_due_invoices: 0,
    paid_invoices: 0,
    pending_orders: 0,
    pre_taxed: 0,
    total: 0,
  },
};

const PaymentReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    // BANK_ACCOUNT
    case PaymentActionTypes.UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: action.bankAccount,
        error: undefined,
      };

    case PaymentActionTypes.FAIL_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: undefined,
        error: action.error,
      };

    // ALL_DUE
    case PaymentActionTypes.FETCH_ALL_DUE_PAYMENTS:
      return {
        ...state,
        allDuePayments: {
          ...state.allDuePayments,
          data: action.payload.invoices,
          meta: action.payload.meta,
        },
        paymentsTotal: {
          ...state.paymentsTotal,
          allDue: action.payload.total_amount,
        },
      };

    case PaymentActionTypes.PAGINATE_ALL_DUE_PAYMENTS:
      return {
        ...state,
        allDuePayments: {
          ...state.allDuePayments,
          data: state.allDuePayments.data.concat(action.payload.invoices),
          meta: action.payload.meta,
        },
      };

    case PaymentActionTypes.CLEAR_ALL_DUE_PAYMENTS:
      return {
        ...state,
        allDuePayments: {
          ...initialPayments,
        },
        paymentsTotal: { ...state.paymentsTotal, allDue: 0 },
      };

    case PaymentActionTypes.SET_ALL_DUE_LOADING:
      return {
        ...state,
        allDuePayments: {
          ...state.allDuePayments,
          loading: action.payload.loading,
        },
      };

    // DUE_TODAY
    case PaymentActionTypes.FETCH_DUE_TODAY_PAYMENTS:
      return {
        ...state,
        dueTodayPayments: {
          ...state.dueTodayPayments,
          data: action.payload.invoices,
          meta: action.payload.meta,
        },
        paymentsTotal: {
          ...state.paymentsTotal,
          dueToday: action.payload.total_amount,
        },
      };

    case PaymentActionTypes.PAGINATE_DUE_TODAY_PAYMENTS:
      return {
        ...state,
        dueTodayPayments: {
          ...state.dueTodayPayments,
          data: state.dueTodayPayments.data.concat(action.payload.invoices),
          meta: action.payload.meta,
        },
      };

    case PaymentActionTypes.CLEAR_DUE_TODAY_PAYMENTS:
      return {
        ...state,
        dueTodayPayments: {
          ...initialPayments,
        },
        paymentsTotal: { ...initialPaymentsTotal },
      };

    case PaymentActionTypes.SET_DUE_TODAY_LOADING:
      return {
        ...state,
        dueTodayPayments: {
          ...state.dueTodayPayments,
          loading: action.payload.loading,
        },
      };

    // DUE WITHIN WEEK
    case PaymentActionTypes.FETCH_DUE_WITHIN_WEEK_PAYMENTS:
      return {
        ...state,
        dueWithinWeekPayments: {
          ...state.dueWithinWeekPayments,
          data: action.payload.invoices,
          meta: action.payload.meta,
        },
        paymentsTotal: {
          ...state.paymentsTotal,
          dueWeek: action.payload.total_amount,
        },
      };

    case PaymentActionTypes.PAGINATE_DUE_WITHIN_WEEK_PAYMENTS:
      return {
        ...state,
        dueWithinWeekPayments: {
          ...state.dueWithinWeekPayments,
          data: state.dueWithinWeekPayments.data.concat(action.payload.invoices),
          meta: action.payload.meta,
        },
      };

    case PaymentActionTypes.CLEAR_DUE_WITHIN_WEEK_PAYMENTS:
      return {
        ...state,
        dueWithinWeekPayments: {
          ...initialPayments,
        },
        paymentsTotal: { ...initialPaymentsTotal },
      };

    case PaymentActionTypes.SET_DUE_WITHIN_WEEK_LOADING:
      return {
        ...state,
        dueWithinWeekPayments: {
          ...state.dueWithinWeekPayments,
          loading: action.payload.loading,
        },
      };

    // NOT DUE
    case PaymentActionTypes.FETCH_NOT_YET_DUE_PAYMENTS:
      return {
        ...state,
        notYetDuePayments: {
          ...state.notYetDuePayments,
          data: action.payload.invoices,
          meta: action.payload.meta,
        },
        paymentsTotal: {
          ...state.paymentsTotal,
          notDue: action.payload.total_amount,
        },
      };

    case PaymentActionTypes.PAGINATE_NOT_YET_DUE_PAYMENTS:
      return {
        ...state,
        notYetDuePayments: {
          ...state.notYetDuePayments,
          data: state.notYetDuePayments.data.concat(action.payload.invoices),
          meta: action.payload.meta,
        },
      };

    case PaymentActionTypes.CLEAR_NOT_YET_DUE_PAYMENTS:
      return {
        ...state,
        notYetDuePayments: {
          ...initialPayments,
        },
        paymentsTotal: { ...initialPaymentsTotal },
      };

    case PaymentActionTypes.SET_NOT_DUE_LOADING:
      return {
        ...state,
        notYetDuePayments: {
          ...state.notYetDuePayments,
          loading: action.payload.loading,
        },
      };

    // HISTORY
    case PaymentActionTypes.FETCH_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          data: action.payload.invoices,
          meta: action.payload.meta,
        },
      };

    case PaymentActionTypes.PAGINATE_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          data: state.paymentHistory.data.concat(action.payload.invoices),
          meta: action.payload.meta,
        },
      };

    case PaymentActionTypes.CLEAR_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: {
          ...initialPayments,
        },
      };

    case PaymentActionTypes.SET_PAYMENT_HISTORY_LOADING:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          loading: action.payload.loading,
        },
      };

    // DEPOSITS
    case PaymentActionTypes.FETCH_DEPOSITS:
      return {
        ...state,
        paid: {
          loading: state.paid.loading,
          total: action.payload.total,
          data: action.payload.data,
          meta: action.payload.meta,
        },
      };

    case PaymentActionTypes.CLEAR_DEPOSITS:
      return {
        ...state,
        paid: {
          loading: false,
          total: 0,
          data: [],
          meta: {
            ...initialMeta,
          },
        },
      };

    case PaymentActionTypes.SET_DEPOSITS_LOADING:
      return {
        ...state,
        paid: {
          ...state.paid,
          loading: action.payload.loading,
        },
      };

    // ALLOCATIONS
    case PaymentActionTypes.FETCH_INVOICE_ALLOCATIONS:
      return {
        ...state,
        paid: {
          ...state.paid,
          data: action.payload,
        },
      };

    // DPD BLOCK STATUS
    case PaymentActionTypes.SET_DPD_BLOCK_STATUS:
      return {
        ...state,
        dpdBlockStatus: action.payload,
      };

    case PaymentActionTypes.FETCH_DPD_BLOCK_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // INVOICE SUMMARY
    case PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_LOADING:
      return {
        ...state,
        downloadInvoiceSummary: {
          ...state.downloadInvoiceSummary,
          loading: action.payload.loading,
        },
      };

    case PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_SUCCESS:
      return {
        ...state,
        downloadInvoiceSummary: {
          ...state.downloadInvoiceSummary,
          recipientEmail: action.payload.recipientEmail,
          isDownloadSuccess: action.payload.isDownloadSuccess,
          showFailedMessage: action.payload.showFailedMessage,
        },
      };

    case PaymentActionTypes.SET_DOWNLOAD_INVOICE_SUMMARY_FAILED:
      return {
        ...state,
        downloadInvoiceSummary: {
          ...state.downloadInvoiceSummary,
          showFailedMessage: true,
        },
      };

    // PAYMENT SELECTION
    case PaymentActionTypes.PAYMENT_SELECTION_SET:
      return {
        ...state,
        paymentSelection: {
          ...state.paymentSelection,
          ...action.payload,
          data: action.payload.invoices,
        },
      };

    // PAYMENT OPTION
    case PaymentActionTypes.PAYMENT_OPTION_SET:
      return {
        ...state,
        paymentOptionData: {
          ...state.paymentOptionData,
          ...action.payload,
        },
      };

    // PAYMENT INFORMATION
    case PaymentActionTypes.PAYMENT_INFORMATION_SET:
      return {
        ...state,
        paymentInformation: {
          ...state.paymentInformation,
          ...action.payload,
        },
      };

    case PaymentActionTypes.FETCH_INVOICE_SUMMARY:
      return {
        ...state,
        invoiceSummary: action.payload,
      };
    default:
      return state;
  }
};

export default PaymentReducer;
