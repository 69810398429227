import React from 'react';

interface Props {
  condition: boolean;
  id?: string;
}

export const ConditionalRender: React.FC<React.PropsWithChildren<Props>> = ({ condition, children, id }) => {
  if (!condition) {
    return null;
  }

  return <div id={id}>{children}</div>;
};
