import * as Redux from 'redux';
import { ISearchItem } from 'components/common/SearchMenuSuggestions/components/interface';
import { IMarketing, MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import * as constants from './constants';
import { Actions } from './actions';

import { Product } from '../Product';

export interface SearchPackage {
  name: string;
}

export interface SearchPackages {
  [id: string]: SearchPackage;
}

export interface SearchProducts {
  [product: string]: {
    [id: string]: SearchPackage;
  };
}

export interface MostPopularProduct {
  id: number;
  name: string;
  times_bought: number;
  total_quantity: number;
  score: number;
}

export interface State {
  recent: SearchProducts;
  previousResultId: number[] | string[] | any[];
  allProducts: SearchProducts;
  searching: boolean;
  previousQuery: string;
  previousCategories?: string[];
  suggestionQuery: string;
  totalProductCount: number;
  recentPurchase: Product[];
  suggestions: any;
  mostPopularProducts: MostPopularProduct[];
  recentSearchProducts: ISearchItem[];
  recentlyViewedProducts: number[];
  categories: Array<{
    id: number;
    name: string;
    icon_url?: string | null;
  }>;
  respondAt: Date | null;
  respondTime: number;
  controlledSearch: string;
  specialMarketings?: { [k in MarketingType]?: IMarketing };
}

const InitialState: State = {
  recent: {},
  previousResultId: [],
  allProducts: {},
  searching: false,
  previousQuery: '',
  suggestionQuery: '',
  totalProductCount: 0,
  recentPurchase: [],
  suggestions: [],
  mostPopularProducts: [],
  recentSearchProducts: [],
  recentlyViewedProducts: JSON.parse(localStorage.getItem(constants.CACHE_KEY_RECENTLY_VIEWED_PRODUCTS) || '[]'),
  categories: [],
  respondAt: null,
  respondTime: 0,
  controlledSearch: '',
};

const SearchReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.UPDATE_RECENT:
      return {
        ...state,
        recent: action.recent,
      };
    case constants.GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: { ...state.allProducts, ...action.allProducts },
      };
    case constants.GET_SECTION_PRODUCTS:
      return {
        ...state,
        allProducts: action.allProducts,
      };
    case constants.FAIL_SEARCH:
      return {
        ...state,
        error: action.error,
      };
    case constants.FETCH_RECENT_SEARCH:
      return {
        ...state,
        recentSearchProducts: action.recentSearchProducts,
      };
    case constants.FETCH_SEARCH_SUGGESTION:
      return {
        ...state,
        suggestionQuery: action.query,
        suggestions: action.suggestions,
        respondAt: action.respondAt,
        respondTime: action.respondTime,
      };
    case constants.SET_MOST_POPULAR_PRODUCTS:
      return {
        ...state,
        mostPopularProducts: action.payload,
      };
    case constants.SET_RECENTLY_VIEWED_PRODUCT:
      return {
        ...state,
        recentlyViewedProducts: action.recentlyViewedProducts,
      };
    case constants.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case constants.SET_SPECIAL_MARKETING_CACHE:
      return {
        ...state,
        specialMarketings: action.specialMarketings,
      };
    case constants.SET_PREVIOUS_RESULT_ID:
      return {
        ...state,
        previousResultId: [...state.previousResultId, ...action.previousResultId],
      };
    default:
      return state;
  }
};

export default SearchReducer;
