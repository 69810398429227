import React, { useState } from 'react';
import { Grid, MuiThemeProvider, CircularProgress, Modal } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { Delete } from '@material-ui/icons';

import { TranslateProps } from 'utils/Localization/types';
import { toCurrency } from 'utils/Format';
import { ReactComponent as VoucherIcon } from 'images/ic_voucher.svg';
import { ReactComponent as VoucherAppliedIcon } from 'images/ic_voucher_applied.svg';
import ShowIf from 'components/views/ShowIf';

import { LoyaltyIcon, LoyaltyPoints, OrderActionStyles, redTheme } from './OrderAction.styles';
import { ClearCartDialog } from './components/ClearCartDialog';
import { Props } from './interface';
import { ItemSummary } from './components/ItemSummary';

export const OrderAction: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({
  id,
  item,
  amount,
  onButtonClick: handleClick,
  enableRemoveVoucher = true,
  confirmingPayment = false,
  disabled = false,
  potentialPoints = 0,
  appliedVoucher,
  onSelectVoucher,
  confirmRemoveVoucher,
  children,
  t,
}) => {
  const isVoucherApplied = Boolean(appliedVoucher);
  const showVoucherSelection = false; // NOTE: direct payment voucher selection is not supported now
  const [open, setOpen] = useState(false);

  const handleClearCancel = (): void => setOpen(false);

  return (
    <OrderActionStyles.Container id={id}>
      <Grid container direction="row" alignItems="center">
        <Grid container item direction="column">
          <ShowIf condition={showVoucherSelection}>
            <Grid>
              <ShowIf condition={!isVoucherApplied}>
                <OrderActionStyles.IconLabel onClick={onSelectVoucher}>
                  <VoucherIcon />
                  <span>{t('voucher.chooseVoucher')}</span>
                </OrderActionStyles.IconLabel>
              </ShowIf>
              <ShowIf condition={isVoucherApplied}>
                <OrderActionStyles.IconLabel onClick={confirmRemoveVoucher}>
                  <VoucherAppliedIcon />
                  <span style={{ fontSize: 12 }}>
                    {t('voucher.voucherApplied', { amount: toCurrency(appliedVoucher?.voucher_type.value || 0) })}
                  </span>
                  <ShowIf condition={enableRemoveVoucher && !confirmingPayment}>
                    <OrderActionStyles.VoucherAction>
                      <Delete />
                    </OrderActionStyles.VoucherAction>
                  </ShowIf>
                </OrderActionStyles.IconLabel>
              </ShowIf>
            </Grid>
          </ShowIf>

          <OrderActionStyles.Separator variant="fullWidth" />

          <Grid container item justify="space-between" style={{ paddingBottom: 10, marginTop: 10 }}>
            <ItemSummary t={t} item={item} amount={amount} />
          </Grid>
          <Grid item>
            <MuiThemeProvider theme={redTheme}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <ShowIf condition={potentialPoints > 0}>
                    <LoyaltyPoints>
                      <Trans t={t} i18nKey="earnPoints" values={{ points: toCurrency(potentialPoints) }}>
                        <strong>&nbsp;</strong>
                      </Trans>
                      <LoyaltyIcon />
                    </LoyaltyPoints>
                  </ShowIf>
                </Grid>

                <Grid item xs={6}>
                  <OrderActionStyles.ActionButton
                    color="primary"
                    onClick={handleClick}
                    data-testid="btn-checkout"
                    disabled={disabled}
                  >
                    {children}
                    <ShowIf condition={confirmingPayment}>
                      <CircularProgress size="1rem" style={{ marginLeft: 8, color: '#FFFFFF' }} />
                    </ShowIf>
                  </OrderActionStyles.ActionButton>
                </Grid>
              </Grid>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={open}>
        <ClearCartDialog open={open} itemCount={item} onCancel={handleClearCancel} t={t} />
      </Modal>
    </OrderActionStyles.Container>
  );
};
