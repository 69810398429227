import styled from 'styled-components';
import { breakpoints } from 'components/styles/breakpoints';
import { Typography } from '@material-ui/core';

import { PRIMARY_COLOR } from 'components/styles/colors';
import { BaseCard } from '../BaseCard';

const BaseText = styled(Typography)`
  color: #000000;
  display: block;
`;

const Wrapper = styled(BaseCard)`
  && {
    box-shadow: none;
    padding: 20px;
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      padding: 15px;
    }
  }
`;

const Title = styled(BaseText)`
  && {
    font-size: 16px;
    font-weight: 800;
    text-transform: capitalize;
    padding-bottom: 10px;
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 12px;
    }
  }
`;

const Message = styled(BaseText)`
  .text-primary {
    font-size: 14px;
    color: ${PRIMARY_COLOR};
  }
`;

export const S = {
  Wrapper,
  Title,
  Message,
};
