import { SwipeRxPt } from '../../SwipeRxPt.class';
import { ILogisticsPharmacyArea } from './interfaces/LogisticsPharmacyArea.interface';

export class SwipeRxPtLogisticsPharmacyArea {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async pharmacyAreas(pharmacyId: number): Promise<ILogisticsPharmacyArea> {
    const { data } = await this.base.getV2(`/logistics-sla/pharmacy/${pharmacyId}`);

    return data;
  }
}
