import styled, { css } from 'styled-components';
import * as colors from 'components/styles/colors';

export const WidgetsContainerExtra = styled.div<{ display?: boolean }>`
  padding-top: 1rem;
  background-color: ${colors.WEAK_GRAY};
  ${(props) =>
    props?.display === false &&
    css`
      display: none;
    `}
`;
