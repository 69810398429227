import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showError } from 'store/reducers/Global/actions';

import { swipeRxPt } from '../../../services';

export interface UseGetBillDetails {
  billReference: string;
  isLoading: boolean;
  qrCodeUrl: string;
  message: string;
}

export const usePayNow = (paymentDirectId: number, initializePayNow: boolean, t: any): UseGetBillDetails => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [billReference, setBillReference] = React.useState('');
  const [qrCodeUrl, setQrCodeUrl] = React.useState('');
  const [message, setMessage] = React.useState('');

  const updateBill = async (): Promise<void> => {
    const bill = await swipeRxPt.paymentDirectBills.payNow(paymentDirectId);

    if (bill) {
      const { qr_code, bill_reference } = bill;

      setBillReference(bill_reference || '');
      setQrCodeUrl(qr_code || '');
    }

    setMessage('');
  };

  const startPayNow = async (retry = 1): Promise<void> => {
    const maxRetry = 3;
    setIsLoading(true);
    setMessage('Your bill number and QR code is being generated.');

    try {
      await updateBill();
    } catch (error) {
      if (retry < maxRetry) {
        dispatch(showError(t('billCreateErrorRetry')));
        await startPayNow(retry + 1);
        return;
      }

      // eslint-disable-next-line no-console
      console.error('usePayNow error', error);

      setMessage(t('billCreateError'));
      dispatch(showError(t('billCreateError')));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentDirectId > 0 && initializePayNow) {
      startPayNow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDirectId, initializePayNow]);

  return {
    isLoading,
    billReference,
    qrCodeUrl,
    message,
  };
};
