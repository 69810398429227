import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { translate } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ConditionalRender } from 'components/common';
import { swipeRxPt } from 'services';
import { State as StoreState } from 'store';
import { openOrderStatus, setCurrentOrderRating, setOrderRatingLoading } from 'store/reducers/Orders/actions';
import { TFunction } from 'utils/Localization';
import { isExpectedDeliveryDateShown } from 'utils/MarketConfig';

import { DeliveryDateWarningComponent } from './orders-modal-delivery-date-warning.component';
import { OrdersModalSummaryCopyValue } from './orders-modal-summary-copy-value.component';
import { OrdersModalSummaryStatus } from './orders-modal-summary-status.component';
import { S } from './orders-modal-summary.styles';
import { OrderModalViewPhoto } from './orders-modal-view-photo.component';

const OrdersModalSummaryOrdersComponent: React.FC<React.PropsWithChildren<{ t: TFunction }>> = ({ t }) => {
  const dispatch = useDispatch();

  const {
    purchaseOrder: { orderDetail },
  } = useSelector((state: StoreState) => state, shallowEqual);
  const invoice = !isEmpty(orderDetail) ? orderDetail.invoice : {};
  const [currentPreview, setCurrentPreview] = useState(null);
  const [currentTextPreview, setCurrentTextPreview] = useState<string>('');

  const handleViewInvoicePhoto = (): void => {
    setCurrentPreview(invoice.invoice_photo);
    setCurrentTextPreview('Invoice');
  };

  const handleViewDeliveryProof = (): void => {
    setCurrentPreview(invoice.proof_photo);
    setCurrentTextPreview('Delivery Proof');
  };

  const handleViewTaxPhoto = (): void => {
    setCurrentPreview(invoice.tax_photo);
    setCurrentTextPreview('Tax');
  };

  const handleClosePreview = (): void => {
    setCurrentPreview(null);
  };

  const handleViewStatus = (): void => {
    dispatch(openOrderStatus());
  };

  const fetchOrderRating = async () => {
    if (orderDetail.id) {
      dispatch(setOrderRatingLoading(true));

      const orderRating = await swipeRxPt.orders.getRating(orderDetail.id);

      dispatch(setCurrentOrderRating(orderRating));
      dispatch(setOrderRatingLoading(false));
    }
  };

  useEffect(() => {
    fetchOrderRating();

    return () => {
      dispatch(setCurrentOrderRating());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  return (
    <S.Card>
      <S.Row container direction="column" alignItems="flex-start">
        <Grid container item xs={12}>
          <S.Label>{t('status')}</S.Label>
        </Grid>
        {!!orderDetail.id && <OrdersModalSummaryStatus order={orderDetail} onClick={handleViewStatus} t={t} />}
      </S.Row>
      <S.Row>
        <S.Label>{t('distributor')}</S.Label>
        <S.Value className="bold">{orderDetail.distributor?.name}</S.Value>
      </S.Row>
      <S.Row>
        <Grid item xs={4}>
          <S.Label>{t('poNumber')}</S.Label>
        </Grid>
        <Grid item xs={8}>
          <OrdersModalSummaryCopyValue value={orderDetail.po_number} message={t('poNumberCopy')} />
        </Grid>
      </S.Row>
      <S.Row>
        <S.Label>{t('purchasedDate')}</S.Label>
        <S.Value>{moment(orderDetail.ordered_at).format('DD MMM YYYY')}</S.Value>
      </S.Row>
      <ConditionalRender condition={isExpectedDeliveryDateShown()}>
        <S.Row>
          <S.Label>{t('expectedDeliveryDate')}</S.Label>
          <S.Value>
            {orderDetail.logistics_po_delivery
              ? moment(orderDetail.logistics_po_delivery.expected_delivery).utcOffset(7).format('DD MMM YYYY')
              : t('toBeDetermined')}
          </S.Value>
        </S.Row>
        <DeliveryDateWarningComponent order={orderDetail} />
      </ConditionalRender>
      <ConditionalRender condition={!isEmpty(invoice)} id="tour_orders_tab_documents">
        <S.Row container alignItems="flex-start">
          <Grid item xs={4}>
            <S.Label>{t('invoiceNumber')}</S.Label>
          </Grid>
          <Grid item container xs={8} direction="column" alignItems="flex-end" spacing={1}>
            <OrdersModalSummaryCopyValue value={invoice?.invoice_number} message={t('invoiceCopy')} />
            <ConditionalRender condition={!isEmpty(invoice?.invoice_photo)}>
              <S.ViewButton onClick={handleViewInvoicePhoto}>
                {t('viewInvoice')} <ArrowForward />
              </S.ViewButton>
            </ConditionalRender>
          </Grid>
        </S.Row>
        <ConditionalRender condition={!isEmpty(invoice?.tax_photo)}>
          <S.Row>
            <Grid item xs={4}>
              <S.Label>{t('taxInvoice')}</S.Label>
            </Grid>
            <Grid item xs={8} container direction="column" alignItems="flex-end" spacing={1}>
              <S.ViewButton onClick={handleViewTaxPhoto}>
                {t('viewTax')} <ArrowForward />
              </S.ViewButton>
            </Grid>
          </S.Row>
        </ConditionalRender>
        <ConditionalRender condition={!isEmpty(invoice?.proof_photo)}>
          <S.Row>
            <Grid item xs={4}>
              <S.Label>{t('deliveryProof')}</S.Label>
            </Grid>
            <Grid item xs={8} container direction="column" alignItems="flex-end" spacing={1}>
              <S.ViewButton onClick={handleViewDeliveryProof}>
                {t('viewProof')} <ArrowForward />
              </S.ViewButton>
            </Grid>
          </S.Row>
        </ConditionalRender>
      </ConditionalRender>
      <OrderModalViewPhoto titleText={currentTextPreview} url={currentPreview} onClose={handleClosePreview} />
    </S.Card>
  );
};

export const OrdersModalSummaryOrders = translate('orders')(OrdersModalSummaryOrdersComponent);
