import React, { Suspense } from 'react';
import find from 'lodash/find';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import { Route, Switch } from 'react-router-dom';

import LoadingPage from 'components/pages/LoadingPage';
import { store } from 'store';
import routes from 'routes';

export const addMarketIdPrefix = (routePath) => {
  const state = store.getState();
  const { marketId } = state.config;

  return `/${marketId}${routePath}`;
};

const findRouteByKey = (routeKey) => {
  let hasElem = true;
  let subjectArray = [...routes];

  while (hasElem) {
    const elem = find(subjectArray, { key: routeKey });

    if (elem) {
      return elem;
    }
    subjectArray = compact(flatten(map(subjectArray, 'routes')));

    if (!subjectArray.length) {
      hasElem = false;
    }
  }

  return null;
};

export const routeKey = (key, params = {}) => {
  const keys = Object.keys(params);
  let path = findRouteByKey(key)?.path || '';

  if (keys.length) {
    keys.forEach((key) => {
      path = path.replace(`:${key}`, params[key]);
    });
  }

  return addMarketIdPrefix(path);
};

export const removeExtraSlash = (url: string): string => url.replace(/(https?:\/\/)|(\/){2,}/g, '$1$2');

export const RenderRoutes = ({ routeKey, match }) => {
  const route = findRouteByKey(routeKey);
  const routeElems: JSX.Element[] = [];
  const isPathNotEqualToMatch = route?.path !== match.path && addMarketIdPrefix(route?.path) !== match.path;

  if (isPathNotEqualToMatch) {
    return null;
  }

  if (route && route.routes) {
    route.routes
      .filter((route) => !!route.component)
      .forEach((sub_route) => {
        const routeElem = (
          <Route
            key={sub_route.key}
            path={addMarketIdPrefix(sub_route.path)}
            exact={sub_route.exact || false}
            component={sub_route.component}
          />
        );
        routeElems.push(routeElem);
      });
  }

  return (
    <Suspense fallback={<LoadingPage inverse />}>
      <Switch>
        {routeElems.map((route) => route)}
        <Route render={() => <div>Not found.</div>} />
      </Switch>
    </Suspense>
  );
};
