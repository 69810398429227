import './InfiniteLoader.css';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const InfiniteLoader: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Wrapper>
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </Wrapper>
);
