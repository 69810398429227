/* eslint-disable no-console */
import {
  CfLog,
  ContentBlock,
  IdentityAction,
  Navigation,
  ECommerce,
  ItemType,
  CartAction,
  Country,
  Language,
  StockStatus,
  CfLogEvents,
  CfLogEventType,
  ItemProperties,
  ItemAction,
  Loyalty,
  PromoProperties,
  PromoAction,
  PromoItem,
  PromoItemType,
  PromoType,
} from '@causalfoundry/js-sdk';
import { v4 as uuidv4 } from 'uuid';
import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG, DOCUMENT_REQUIREMENT_ALKES_FLAG } from 'services';

import { store } from 'store';
import {
  setCausalFoundryCfLog,
  setCausalFoundryCartId,
  setCausalFoundrySearchId,
} from 'store/reducers/Analytics/analytics.action';
import { OrderRequest } from 'store/reducers/Orders';
import { Product } from 'store/reducers/Product';

import { getCausalFoundryConfig, getCausalFoundryIsEnabled, getCurrency, getMarketId } from 'utils/MarketConfig';
import { getProductCategory, getProductSubCategories } from 'components/pages/ProductDetailPage/utils';

import { getProductStockStatus } from 'utils/helpers/get-product-stock-status';
import { PtCoreUser } from 'store/reducers/Auth';
import { CTAResource } from 'core/repositories/nudges/typings';
import { addToCartFromCausalFoundryNudge } from 'store/reducers/Cart/actions';
import { AnalyticsEvent, makeAnalyticsRequest } from './Segment';
import {
  ICfCartProperties,
  ICfCheckoutProperties,
  ICfDrugProperties,
  ICfItemDetail,
  ICfSearchProperties,
  ICfUserProperties,
  IMarketingCart,
} from './interfaces/causal-foundry.interface';

const isDebug = process.env.NODE_ENV === 'development' && process.env.REACT_APP_ANALYTICS_DEBUG === '1';

function isCausalFoundryEnabled(): boolean {
  const isEnabled = getCausalFoundryIsEnabled();
  if (!isEnabled) {
    if (isDebug) console.info(`[causal foundry] active logging disabled`);
  }
  return isEnabled;
}

export function init(token: string, history: any, coreUser: PtCoreUser): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] init`, token);

  const cfConfig = getCausalFoundryConfig();

  try {
    const options = {
      activateNudgeMechanism: true,
      defaultBlock: ContentBlock.ECommerce,
      selfManagedNudges: cfConfig.selfManagedNudgesEnabled,
    };
    const cfLog = CfLog.createSDKInstance(token, options);
    if (!cfLog) {
      throw new Error('Failed to init Causal Foundry');
    }

    cfLog.on(CfLogEvents.NudgeAction, (type: CfLogEventType, resource: CTAResource) => {
      if (type === CfLogEventType.Redirect && resource.id) {
        makeAnalyticsRequest('pt_causal_foundry_in_apps_notif_open', AnalyticsEvent.Track, {
          pharmacyId: coreUser.organization_id,
        });
        history.push(`/${getMarketId()}/product/${resource.id}`);
      } else if (type === CfLogEventType.AddToCart && resource) {
        store.dispatch(addToCartFromCausalFoundryNudge(resource.id) as any);
        history.push(`/${getMarketId()}/prices`);
      }
    });

    store.dispatch(setCausalFoundryCfLog(cfLog));
    if (isDebug) console.info(`[causal foundry] init success`, cfLog);
  } catch (error) {
    if (isDebug) console.error(`[causal foundry] init error`, error);
  }
}

function identify(id: string, payload: ICfUserProperties): void {
  if (!isCausalFoundryEnabled()) return;

  const state = store.getState();
  const { cfLog } = state.analytics.causalFoundry;
  if (cfLog) {
    try {
      cfLog.identify(IdentityAction.Login, id, payload);
    } catch (error) {
      if (isDebug) console.error(`[causal foundry] identify error`, error);
    }
  }
  if (isDebug) console.info(`[causal foundry] identify`, { id, payload });
}

export function identifyUser(coreUser: PtCoreUser, marketId: string): void {
  const { external_id, organization_region, organization_city, organization_zipcode } = coreUser;
  const country = getMarketId() === 'id' ? Country.Indonesia : getMarketId() === 'th' ? Country.Thailand : undefined;
  identify(`${external_id}`, {
    id: `${external_id}`,
    name: ``,
    country,
    region_state: `${organization_region}`,
    city: `${organization_city}`,
    zipcode: `${organization_zipcode}`,
    profession: 'Pharmacist',
    workplace: 'Pharmacy',
    language: marketId === 'id' ? Language.Indonesian : Language.English,
    // experience: '',
    // education_level: '',
    organization_id: `${coreUser.organization_id}`,
    organization_name: `${coreUser.organization_name}`,
  });
}

// search_items_ecommerce
export function trackSearchEvent(params: ICfSearchProperties, isNewSearch = false): void {
  if (!isCausalFoundryEnabled()) return;

  if (isDebug) console.info(`[causal foundry] track search`, { params, isNewSearch });
  const { results_list, ...restParams } = params;
  try {
    const newParams = {
      results_list: results_list.map((id: string) => ({ id, type: ItemType.Drug })),
      ...restParams,
    };
    if (isDebug) console.info(`[causal foundry] new params`, { newParams });
    const bsSearchId = Navigation.logSearchEvent(newParams, isNewSearch);
    if (isDebug) console.info(`[causal foundry] track search result`, bsSearchId);
    if (bsSearchId) {
      store.dispatch(setCausalFoundrySearchId(bsSearchId));
    }
  } catch (error) {
    if (isDebug) console.error(`[causal foundry] track search event error`, error);
  }
}

function trackCartEvent(params: ICfCartProperties, isNewCart = false): void {
  if (!isCausalFoundryEnabled()) return;

  const {
    analytics: {
      causalFoundry: { cartId },
    },
  } = store.getState();

  let newId = cartId;
  if (isNewCart || !newId) {
    newId = uuidv4();
    store.dispatch(setCausalFoundryCartId(newId));
  }

  const newParams: ICfCartProperties = { ...params, id: newId };
  if (isDebug) console.info(`[causal foundry] track cart event`, newParams);
  try {
    ECommerce.logCartEvent(newParams);
  } catch (error) {
    if (isDebug) console.error(`[causal foundry] track cart event error`, error);
  }
}

export function trackTierPricingItem(product: Product, quantity: number): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track tier pricing / promo item event`, { product });

  const promoSelected = product?.tier_discount?.filter((t) => t.min <= quantity)[product?.tier_discount?.length - 1];
  if (!promoSelected) return;

  const item: PromoItem = {
    id: `${product?.id}`,
    type: PromoItemType.Drug,
  };

  const promoProperties: PromoProperties = {
    id: `${promoSelected.id}`,
    action: PromoAction.Apply,
    items: [item],
    title: `Buy Minimum of ${promoSelected.min} to get ${promoSelected.discount_rate * 100}% discount`,
    type: PromoType.AddToCart,
  };

  Loyalty.logPromoEvent(promoProperties);
}

export function trackViewTierPricingItem(product: Product): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track tier pricin / promo item event`, { product });

  const item: PromoItem = {
    id: `${product?.id}`,
    type: PromoItemType.Drug,
  };

  product.tier_discount.forEach((t) => {
    const promoProperties = {
      id: t.id.toString(),
      action: PromoAction.View,
      items: [item],
      title: `Buy Minimum of ${t.min} to get ${t.discount_rate * 100}% discount`,
      type: PromoType.AddToCart,
    };
    Loyalty.logPromoEvent(promoProperties);
  });
}

export function trackCartQuantityEvent(
  isIncrement: boolean,
  totalPrice: number,
  productId: number | string,
  price: number,
  quantity: number,
  isNewSearch = false,
  product?: Product,
): void {
  if (!isCausalFoundryEnabled()) return;

  let productStockStatus: StockStatus | undefined;
  let promo_id = '';
  if (product) {
    const { remaining_quantity, low_stock_threshold, quantity_threshold, marketing_id } = product;
    productStockStatus = getProductStockStatus(remaining_quantity, quantity_threshold, low_stock_threshold);
    promo_id = marketing_id?.toString() ?? '';
    if (product.tier_discount.length > 0) {
      trackTierPricingItem(product, quantity);
    }
  }
  const item: ICfItemDetail = {
    id: `${productId}`,
    price,
    quantity,
    type: ItemType.Drug,
    currency: getCurrency(),
    stock_status: productStockStatus,
    promo_id,
  };
  const cart: ICfCartProperties = {
    action: isIncrement ? CartAction.AddItem : CartAction.RemoveItem,
    cart_price: totalPrice,
    currency: getCurrency(),
    item,
  };
  if (isDebug) console.info(`[causal foundry] track cart quantity event`, cart);
  trackCartEvent(cart, isNewSearch);
}

function trackCheckoutEvent(params: ICfCheckoutProperties): void {
  if (!isCausalFoundryEnabled()) return;

  const {
    analytics: {
      causalFoundry: { cartId },
    },
  } = store.getState();

  let newCartId = cartId;
  if (!newCartId) {
    newCartId = uuidv4();
    store.dispatch(setCausalFoundryCartId(newCartId));
  }
  const newId = params.id || newCartId;

  const newParams: ICfCheckoutProperties = { ...params, cart_id: newCartId, id: newId };
  if (isDebug) console.info(`[causal foundry] track checkout event`, newParams);
  try {
    ECommerce.logCheckoutEvent(newParams);
  } catch (error) {
    if (isDebug) console.error(`[causal foundry] track checkout event error`, error);
  }
}

export function trackCartCheckoutEvent(carts: any, isSuccess: boolean): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track cart checkout event`, { carts, isSuccess });

  const { distributors, order_summary } = carts;

  const distProducts = Object.values(distributors).reduce((result: any[], dist: any): ICfItemDetail[] => {
    result.push(
      ...Object.keys(dist.products).map((key): ICfItemDetail => {
        const { id, quantity, net_price, stock } = dist.products[key];
        const price = net_price * quantity;
        return {
          id,
          quantity,
          price,
          currency: getCurrency(),
          type: ItemType.Drug,
          stock_status: getProductStockStatus(
            stock.remaining_quantity,
            stock.quantity_threshold,
            stock.low_stock_threshold,
          ),
        };
      }),
    );
    return result;
  }, []);
  const params: ICfCheckoutProperties = {
    is_successful: isSuccess,
    cart_price: order_summary.total_net_price_before_tax,
    currency: getCurrency(),
    id: '',
    items: distProducts as ICfItemDetail[],
  };

  trackCheckoutEvent(params);
}

export function trackOrderCheckoutEvent(
  request: OrderRequest,
  orders: any[],
  isSuccess: boolean,
  marketingCart?: IMarketingCart,
): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track order checkout event`, { request, orders, isSuccess });

  const { distributors } = request;

  const distProducts = Object.values(distributors).reduce<Record<string, ICfItemDetail[]>>((result, dist) => {
    result[dist.name] = Object.keys(dist.products).map((key): ICfItemDetail => {
      const { quantity, net_price, flag, stock_status } = dist.products[key];
      return {
        id: key,
        quantity,
        price: net_price * quantity,
        currency: getCurrency(),
        type: ItemType.Drug,
        flag,
        stock_status,
        promo_id: marketingCart ? marketingCart[key]?.marketing_id?.toString() ?? '' : '',
      };
    });
    return result;
  }, {});

  if (orders && orders.length > 0 && isSuccess) {
    orders.forEach((order) => {
      const { po_number, distributor_name, net_amount, is_prekursor, is_alkes, items } = order;
      const productIds: string[] = items.map((item) => `${item.id}`);
      let products: ICfItemDetail[];
      if (is_prekursor) {
        products = distProducts[distributor_name]
          .filter((v: ICfItemDetail) => v.flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG)
          .filter((v: ICfItemDetail) => productIds.includes(v.id));
      } else if (is_alkes) {
        products = distProducts[distributor_name]
          .filter((v: ICfItemDetail) => v.flag === DOCUMENT_REQUIREMENT_ALKES_FLAG)
          .filter((v: ICfItemDetail) => productIds.includes(v.id));
      } else {
        products = distProducts[distributor_name]
          .filter(
            (v: ICfItemDetail) =>
              v.flag !== DOCUMENT_REQUIREMENT_PREKURSOR_FLAG && v.flag !== DOCUMENT_REQUIREMENT_ALKES_FLAG,
          )
          .filter((v: ICfItemDetail) => productIds.includes(v.id));
      }

      const params: ICfCheckoutProperties = {
        id: po_number,
        cart_price: net_amount,
        currency: getCurrency(),
        is_successful: isSuccess,
        items: products,
      };
      trackCheckoutEvent(params);
    });
  } else {
    Object.values(distProducts).forEach((items: any) => {
      const params: ICfCheckoutProperties = {
        id: '',
        cart_price: items.reduce((price, item) => price + item.quantity * item.price, 0),
        currency: getCurrency(),
        is_successful: false,
        items,
      };
      trackCheckoutEvent(params);
    });
  }
}

function trackItemEvent(item: ItemProperties, detail?: ICfDrugProperties): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track item event`, { item, detail });

  try {
    ECommerce.logItemEvent(item, detail);
  } catch (error) {
    if (isDebug) console.error(`[causal foundry] track item event error`, error);
  }
}

export function trackViewItem(product: Product, { search_id = '', promo_id = '' }): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track view item event`, { product, search_id });
  const stockStatus: StockStatus = getProductStockStatus(
    product.remaining_quantity,
    product.quantity_threshold,
    product.low_stock_threshold,
  );

  const item: ItemProperties = {
    action: ItemAction.View,
    item: {
      currency: getCurrency(),
      id: `${product.id}`,
      price: product.net_price,
      quantity: 1,
      type: ItemType.Drug,
      stock_status: stockStatus,
      promo_id,
    },
    search_id,
  };

  const drug: ICfDrugProperties = {
    market_id: getMarketId(),
    id: `${product.id}`,
    name: product.name,
    description: product.description ?? '',
    supplier_name: product.distributor.name,
    supplier_id: `${product.distributor.id}`,
    producer: product.manufacturer,
    packaging: product.packaging,
    active_ingredients: product.molecule?.split(',').map((mol) => mol.trim()) ?? [],
    otc_or_ethical: getProductCategory(product.categories),
    atc_anatomical_group: getProductSubCategories(product.categories),
    drug_form: '',
    drug_strength: '',
  };

  trackItemEvent(item, drug);
}

export function trackStockOutReminderItem(product: Product, { isRemove = false, search_id = '', promo_id = '' }): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track stock out reminder item event`, { product, search_id });
  const stockStatus: StockStatus = getProductStockStatus(
    product.remaining_quantity,
    product.quantity_threshold,
    product.low_stock_threshold,
  );

  const item: ItemProperties = {
    action: isRemove ? ItemAction.RemoveReminder : ItemAction.AddReminder,
    item: {
      currency: getCurrency(),
      id: `${product.id}`,
      price: product.net_price,
      quantity: 1,
      type: ItemType.Drug,
      stock_status: stockStatus,
      promo_id,
    },
    search_id,
  };

  const drug: ICfDrugProperties = {
    market_id: getMarketId(),
    id: `${product.id}`,
    name: product.name,
    description: product.description ?? '',
    supplier_name: product.distributor.name,
    supplier_id: `${product.distributor.id}`,
    producer: product.manufacturer,
    packaging: product.packaging,
    active_ingredients: product.molecule?.split(',').map((mol) => mol.trim()) ?? [],
    otc_or_ethical: getProductCategory(product.categories),
    atc_anatomical_group: getProductSubCategories(product.categories),
    drug_form: '',
    drug_strength: '',
  };

  trackItemEvent(item, drug);
}

export function trackAddFavoriteItem(product: Product, { isRemove = false, search_id = '', promo_id = '' }): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track stock out reminder item event`, { product, search_id });
  const stockStatus: StockStatus = getProductStockStatus(
    product.remaining_quantity,
    product.quantity_threshold,
    product.low_stock_threshold,
  );

  const item: ItemProperties = {
    action: isRemove ? ItemAction.RemoveFavorite : ItemAction.AddFavorite,
    item: {
      currency: getCurrency(),
      id: `${product.id}`,
      price: product.net_price,
      quantity: 1,
      type: ItemType.Drug,
      stock_status: stockStatus,
      promo_id,
    },
    search_id,
  };

  const drug: ICfDrugProperties = {
    market_id: getMarketId(),
    id: `${product.id}`,
    name: product.name,
    description: product.description ?? '',
    supplier_name: product.distributor.name,
    supplier_id: `${product.distributor.id}`,
    producer: product.manufacturer,
    packaging: product.packaging,
    active_ingredients: product.molecule?.split(',').map((mol) => mol.trim()) ?? [],
    otc_or_ethical: getProductCategory(product.categories),
    atc_anatomical_group: getProductSubCategories(product.categories),
    drug_form: '',
    drug_strength: '',
  };

  trackItemEvent(item, drug);
}

export function trackViewItemDetail(product: Product, { search_id = '', promo_id = '' }): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] track view item detail event`, { product, search_id });

  const stockStatus: StockStatus = getProductStockStatus(
    product.remaining_quantity,
    product.quantity_threshold,
    product.low_stock_threshold,
  );

  const item: ItemProperties = {
    action: ItemAction.Detail,
    item: {
      currency: getCurrency(),
      id: `${product.id}`,
      price: product.net_price,
      quantity: 1,
      type: ItemType.Drug,
      stock_status: stockStatus,
      promo_id,
    },
    search_id,
  };

  const drug: ICfDrugProperties = {
    market_id: getMarketId(),
    id: `${product.id}`,
    name: product.name,
    description: product.description ?? '',
    supplier_name: product.distributor.name,
    supplier_id: `${product.distributor.id}`,
    producer: product.manufacturer,
    packaging: product.packaging,
    active_ingredients: product.molecule?.split(',').map((mol) => mol.trim()) ?? [],
    otc_or_ethical: getProductCategory(product.categories),
    atc_anatomical_group: getProductSubCategories(product.categories),
    drug_form: '',
    drug_strength: '',
  };

  trackItemEvent(item, drug);
}

export function startTrackImpression(containerClassName: string, itemClassName: string, fromSearch = false): void {
  if (!isCausalFoundryEnabled()) return;

  const {
    analytics: {
      causalFoundry: { searchId },
    },
  } = store.getState();

  if (isDebug)
    console.info(`[causal foundry] start track impression`, {
      containerClassName,
      itemClassName,
      fromSearch,
      searchId,
    });

  try {
    ECommerce.startTrackingImpressions(containerClassName, itemClassName, fromSearch ? searchId : undefined);
  } catch (error) {
    if (isDebug) console.error(`[causal foundry] start track impression error`, error);
  }
}

export function stopTrackImpression(containerClassName: string): void {
  if (!isCausalFoundryEnabled()) return;
  if (isDebug) console.info(`[causal foundry] stop track impression`);

  try {
    ECommerce.stopTrackingImpressions(containerClassName);
  } catch (error) {
    if (isDebug) console.error(`[causal foundry] stop track impression error`, error);
  }
}

export function restartTrackImpression(containerClassName: string, forceRestart = false): void {
  if (!isCausalFoundryEnabled()) return;

  const {
    analytics: {
      causalFoundry: { searchId },
    },
  } = store.getState();

  if (searchId || forceRestart) {
    if (isDebug) console.info(`[causal foundry] restart track impression`, { searchId });
    try {
      ECommerce.restartTrackingImpressions(containerClassName, searchId);
    } catch (error) {
      if (isDebug) console.error(`[causal foundry] restart track impression error`, error);
    }
  }
}
