import React, { useEffect } from 'react';

import { BasePageTracker } from 'utils/Analytics/Segment/Helper';
import {
  startTrackImpression as causalFoundryStartTrackImpression,
  stopTrackImpression as causalFoundryStopTrackImpression,
} from 'utils/Analytics/CausalFoundry';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';

import { HorizontalScrollView } from '../HorizontalScrollView';
import { ProductCarouselCard } from '../ProductCarouselCard';

import { Container, EndingPad } from './product-carousel.style';
import { ProductCarouselCardSkeleton } from '../ProductCarouselCard/skeleton';

export interface ProductCarouselProps {
  data?: Array<any>;
  marketingId?: number | string;
  marketingName?: string;
  productImpressionPage?: BasePageTracker;
  analyticsDataLog?: {
    containerName: string;
    itemName: string;
  };
  detailPageDisplayType?: DetailPageDisplayType;
  loading?: boolean;
  loadingFiller?: number;
}

export const ProductCarousel: React.FC<React.PropsWithChildren<ProductCarouselProps>> = ({
  data,
  marketingId,
  marketingName,
  productImpressionPage,
  analyticsDataLog,
  detailPageDisplayType,
  loading,
  loadingFiller = 0,
}) => {
  useEffect(() => {
    if (analyticsDataLog) {
      causalFoundryStartTrackImpression(analyticsDataLog.containerName, analyticsDataLog.itemName);
    }
    return () => {
      if (analyticsDataLog) {
        causalFoundryStopTrackImpression(analyticsDataLog.containerName);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, data);

  if (data && data.length === 0 && !loading) return <></>;

  return (
    <Container>
      <div className={analyticsDataLog ? analyticsDataLog.containerName : undefined}>
        <HorizontalScrollView>
          {loading &&
            // eslint-disable-next-line react/no-array-index-key
            Array.from({ length: loadingFiller }).map((_, i) => <ProductCarouselCardSkeleton key={i} />)}
          {!loading &&
            data &&
            data.length > 0 &&
            data.map((product, i) => (
              <ProductCarouselCard
                key={product.id}
                product={{
                  ...product,
                  stock: {
                    remaining_quantity: product.remaining_quantity,
                    quantity_threshold: product.quantity_threshold,
                    low_stock_threshold: product.low_stock_threshold,
                    is_tier_pricing_enabled: product.tier_discount && product.tier_discount.length > 0,
                  },
                }}
                marketingQuery={{
                  marketing_id: marketingId,
                  marketing_name: marketingName,
                }}
                indexNumber={i + 1}
                productImpressionPage={productImpressionPage}
                singleImpression
                analyticsItemName={analyticsDataLog ? analyticsDataLog.itemName : undefined}
                detailPageDisplayType={detailPageDisplayType}
              />
            ))}
          <EndingPad />
        </HorizontalScrollView>
      </div>
    </Container>
  );
};
