const getMarketId = (): string => {
  const parsedData = window.location.pathname.split('/');
  return parsedData.length > 1 ? parsedData[1] : '';
};

const marketId = getMarketId();

export const market = {
  marketId,
};
