import React from 'react';

import { IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Img } from 'components/pages/ProductDetailPage/components/ProductImage/style';

import { SkeletonLoader } from 'components/common/skeleton-loader';
import { S } from './search-sections.style';
import { SectionHeader } from './SectionHeader.component';

interface Props {
  t: (key: any, options?: any) => string | any;
  title: string;
  subTitle?: string;
}

export const ListSectionLoader: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { t, title, subTitle } = props;

  const defaultOnClick = (): void => {
    console.log(`no functionality for ${title} loader function`);
  };

  return (
    <S.Section id={`tour__search__${title}`}>
      <SectionHeader
        sectionSeeAllAction={defaultOnClick}
        onSeeAllButton={defaultOnClick}
        toggle={defaultOnClick}
        subTitle={subTitle}
        isExpanded={false}
        expandable={false}
        title={title}
        t={t}
        headerIcon={
          <IconButton style={{ position: 'absolute', top: 4, right: 0 }}>
            <ChevronRight />
          </IconButton>
        }
      />
      {new Array(3).fill({ loading: true }).map(
        (_): JSX.Element => (
          <div style={{ position: 'relative' }}>
            <S.Item customPadding="4rem" marginRight="42px">
              <S.ImageWrapper isEmpty="">
                <Img alt="" />
              </S.ImageWrapper>

              <SkeletonLoader type="heading" variant="lg" width={350} height={35} />
            </S.Item>
          </div>
        ),
      )}
    </S.Section>
  );
};

ListSectionLoader.defaultProps = {
  subTitle: undefined,
};
