import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import * as colors from 'components/styles/colors';

const Container = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    padding: 10px 10px;
    background-color: ${colors.WHITE};
    border-radius: 20px;
    border: 1px solid #e0dcf4;
  }
`;

const Icon = styled(SearchIcon)`
  && {
    padding-right: 5px;
    fill: #6a6a6a;
    width: 16px;
    height: 16px;
  }
`;

const Clear = styled(CloseIcon)<any>`
  && {
    padding-right: 5px;
    fill: ${colors.BLUISH_GRAY_LIGHT};
    width: 16px;
    height: 16px;
  }
`;

const Input = styled.input`
  outline: none;
  width: 100%;
  font-size: 12px;
  color: #6a6a6a;
  background: transparent;
  border: none;

  /* stylelint-disable property-no-vendor-prefix */
  &&::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const S = { Container, Input, Icon, Clear };
