import { match } from 'react-router-dom';
import qs from 'query-string';

import { LocationSearchProps } from 'hooks/useAddProductAnalytics';
import { getMarketId } from 'utils/MarketConfig';
import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { FilterType } from 'components/common/FilterToolbar/constants';
import { SearchSection } from './segment.types';

export enum BasePageTracker {
  HOME = 'marketing_playlist_homepage',
  ALPHABETICAL = 'alphabet_listing',
  CART = 'cart',
  LOYALTY = 'loyalty',
  MARKETING_LISTING = 'marketing_playlist_listing',
  MARKETING_WIDGET = 'marketing_playlist_homepage',
  GENERAL_SEARCH = 'general_search',
  CATEGORY_LISTING = 'category_listing',
  MARKETING_LISTING_SEARCH = 'marketing_playlist_listing_search',
  RECENTLY_VIEWED_SEARCH = 'recently_viewed_listing_search',
  RECENTLY_VIEWED = 'recently_viewed_listing',
  RECENTLY_SEARCHED_SEARCH = 'recently_searched_listing_search',
  RECENTLY_SEARCHED = 'recently_searched_listing',
  LAST_PURCHASED_SEARCH = 'recently_purchased_listing_search',
  LAST_PURCHASED = 'recently_purchased_listing',
  SIMILAR_PRODUCTS = 'similar_product_recommendation',
  MOST_POPULAR_SEARCH = 'most_popular_listing_search',
  MOST_POPULAR = 'most_popular_listing',
  CAMPAIGN_BASED_SEARCH = 'campaign_based_listing_search',
  WISHLIST = 'wishlist',
  FAVORITES = 'favorites_listing',
  REMINDERS = 'reminders_listing',
  PRODUCT_LISTING = 'product-listing',
  PRODUCT_DETAIL = 'product_detail_page',
  BLANK = '',
}

export const RECENTLY_VIEWED_PATH_NAME = 'recent-products';
export const LAST_PURCHASED_PATH_NAME = 'recently-purchased';
export const SIMILAR_PRODUCT_PATH_NAME = 'similar-products';

export interface Params {
  tab?: string;
}

export interface Match<T extends { [K in keyof T]?: string | undefined }> extends match<T> {}

const getPageMap = (): Record<string, BasePageTracker> => {
  const marketId = getMarketId();

  return {
    [`/${marketId}/main/home`]: BasePageTracker.HOME,
    [`/${marketId}/main/products`]: BasePageTracker.ALPHABETICAL,
    [`/${marketId}/marketing/:id/product-listing`]: BasePageTracker.MARKETING_LISTING,
    [`/${marketId}/wishlist/:id`]: BasePageTracker.WISHLIST,
    [`/${marketId}/wishlist/favorites`]: BasePageTracker.FAVORITES,
    [`/${marketId}/wishlist/reminders`]: BasePageTracker.REMINDERS,
    [`/${marketId}/product/:id`]: BasePageTracker.PRODUCT_DETAIL,
    [`/${marketId}/${RECENTLY_VIEWED_PATH_NAME}`]: BasePageTracker.RECENTLY_VIEWED,
    [`/${marketId}/marketing/:id/product-listing/search`]: BasePageTracker.MARKETING_LISTING_SEARCH,
    [`/${marketId}/${RECENTLY_VIEWED_PATH_NAME}/search`]: BasePageTracker.RECENTLY_VIEWED_SEARCH,
    [`/${marketId}/search`]: BasePageTracker.GENERAL_SEARCH,
    [`/${marketId}/prices`]: BasePageTracker.CART,
    [`/${marketId}/similar-products/:sku_code`]: BasePageTracker.SIMILAR_PRODUCTS,
    [`/${marketId}/${LAST_PURCHASED_PATH_NAME}`]: BasePageTracker.LAST_PURCHASED,
    [`/${marketId}/${LAST_PURCHASED_PATH_NAME}/search`]: BasePageTracker.LAST_PURCHASED_SEARCH,
  };
};

const getEventPageMap = (): Record<string, BasePageTracker> => {
  const marketId = getMarketId();
  const pageMap = getPageMap();
  const basePageEvent = {
    ...pageMap,
    [`/${marketId}/marketing/:id/product-listing/search`]: BasePageTracker.MARKETING_LISTING,
    [`/${marketId}/${RECENTLY_VIEWED_PATH_NAME}/search`]: BasePageTracker.RECENTLY_VIEWED,
  };
  return basePageEvent;
};

export const getBasePageByPath = (path: string): string | undefined => {
  const pages = getPageMap();
  const pageKey = Object.keys(pages).find((key) => key.includes(path));
  return pageKey ? pages[pageKey] : undefined;
};

export const getEventPageIdentity = (match: Match<Params>, locationSearch = ''): BasePageTracker => {
  const pageMap = getEventPageMap();

  let matchPath = match?.path;
  if (!matchPath) {
    return BasePageTracker.BLANK;
  }
  if (match.params?.tab) {
    matchPath = matchPath.replace(':tab', match.params.tab);
  }

  let currentPage = pageMap[matchPath] ?? BasePageTracker.PRODUCT_LISTING;

  const {
    filters,
    goBackTo,
    isModalOpen: isProductDetailModalOpen,
  }: LocationSearchProps = qs.parse(locationSearch, BRACKET_QS_OPTIONS) || {};
  const categoryIds = filters?.filter((f) => f.startsWith(FilterType.CATEGORY)) || [];
  if (categoryIds?.length > 0 && matchPath.includes('search')) {
    currentPage = BasePageTracker.CATEGORY_LISTING;
  } else if (
    currentPage === BasePageTracker.MARKETING_LISTING &&
    decodeURIComponent(goBackTo || '').includes('/id/loyalty/missions')
  ) {
    currentPage = BasePageTracker.LOYALTY;
  }

  if (isProductDetailModalOpen) {
    currentPage = BasePageTracker.PRODUCT_DETAIL;
  }

  return currentPage;
};

export const getEventPageIdentityImpression = (
  match: Match<Params>,
  locationSearch = '',
  overridePage?: BasePageTracker,
): BasePageTracker => {
  let basePage = BasePageTracker.BLANK;
  const { isModalOpen: isProductDetailModalOpen }: LocationSearchProps =
    qs.parse(locationSearch, BRACKET_QS_OPTIONS) || {};
  basePage = getEventPageIdentity(match, locationSearch);

  if (basePage === BasePageTracker.WISHLIST) {
    const pageMap = getPageMap();
    basePage = pageMap[match.url] || BasePageTracker.BLANK;
  }

  return isProductDetailModalOpen ? overridePage || BasePageTracker.BLANK : basePage;
};

export const MARKETING_BASE_PAGES = [
  BasePageTracker.MARKETING_LISTING,
  BasePageTracker.MARKETING_LISTING_SEARCH,
  BasePageTracker.LOYALTY,
  BasePageTracker.LAST_PURCHASED,
  BasePageTracker.LAST_PURCHASED_SEARCH,
  BasePageTracker.RECENTLY_VIEWED,
  BasePageTracker.RECENTLY_SEARCHED_SEARCH,
  BasePageTracker.SIMILAR_PRODUCTS,
];

export const getIsMarketingListing = (basePage: BasePageTracker | string): boolean =>
  basePage === BasePageTracker.MARKETING_LISTING ||
  basePage === BasePageTracker.MARKETING_LISTING_SEARCH ||
  basePage === BasePageTracker.LOYALTY;

export const getIsSimilarProductListing = (basePage: BasePageTracker): boolean =>
  basePage === BasePageTracker.SIMILAR_PRODUCTS;

export const getIsSearchCampaignMarketing = (
  search_section?: SearchSection,
  marketingId?: string | number,
  basePage?: BasePageTracker,
): boolean =>
  search_section === SearchSection.CAMPAIGN_LISTING_SEARCH &&
  (basePage ? MARKETING_BASE_PAGES.includes(basePage) : false || !!marketingId);
