import React from 'react';

import { Row } from 'components/common';
import { ServingDistributor } from 'store/reducers';

import { S } from './orders-filter-card.styles';

interface Props {
  filters: ServingDistributor[];
  currentFilters: number[];
  onAddFilter: (y: number) => void;
  onRemoveFilter: (z: number) => void;
}

export const OrdersFilterCard: React.FC<React.PropsWithChildren<Props>> = ({ filters, currentFilters, onAddFilter, onRemoveFilter }) => (
  <S.Card>
    {filters.map(({ id, name }) => {
      const isSelected = currentFilters.includes(id);

      const onChangeDistributorFilters = (): void => {
        if (!isSelected) {
          onAddFilter(id);
          return;
        }
        onRemoveFilter(id);
      };

      return (
        <Row onClick={onChangeDistributorFilters}>
          <S.Text>{name}</S.Text>
          <S.Checkbox color="primary" value={id} checked={!!isSelected} data-testid={`checkbox-distirbutor-${id}`} />
        </Row>
      );
    })}
  </S.Card>
);
