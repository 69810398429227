import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { translate } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import ShowIf from 'components/views/ShowIf';
import { VoucherMeta } from 'store/reducers/Voucher';
import { IVoucher } from 'services/swipe-rx-pt/resources/voucher/interface/voucher.interface';
import { swipeRxPt } from 'services';
import { getAvailableVouchers } from 'store/reducers/Voucher/actions';

import * as colors from '../../../../styles/colors';

import { Container, Content, Header, EmptyVoucherImage, VoucherCard, VoucherDetail } from './components';

interface Props {
  t: any;
  open: boolean;
  options: IVoucher[];
  // eslint-disable-next-line react/require-default-props
  applied?: IVoucher;
  // eslint-disable-next-line react/require-default-props
  showDetail?: boolean;
  onClose: () => void;
  onApply: (voucher: IVoucher) => void;
  onRemove: () => void;
  // eslint-disable-next-line react/require-default-props
  getVouchers: (data: { voucherCode?: string; page?: number }) => void;
  isVoucherLoading: boolean;
  meta: VoucherMeta;
}

const VoucherBase: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const dispatch = useDispatch();
  const { t, open, options, applied, isVoucherLoading, meta, showDetail = false } = props;
  const { onClose, onApply, onRemove } = props;

  const [voucherCode, setVoucherCode] = useState<string | null>(null);
  const [selectedVoucher, setSelectedVoucher] = useState<IVoucher | null>(null);
  const isApplied = Boolean(applied);

  useEffect(() => {
    if (open) {
      dispatch(getAvailableVouchers({ refreshVoucher: true }));
      if (applied && showDetail) setSelectedVoucher(applied);
      swipeRxPt.voucherTracking.trackListView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleApplyVoucher = (voucher: IVoucher) => () => {
    setSelectedVoucher(null);
    onApply(voucher);

    swipeRxPt.voucherTracking.trackVoucherApplied({
      voucherCode: voucher.code,
      voucherId: voucher.id,
      voucherUsageId: voucher.voucher_usage_id,
    });
  };

  const applySelectedVoucher = (): void => {
    if (selectedVoucher) {
      onApply(selectedVoucher);
      setSelectedVoucher(null);

      swipeRxPt.voucherTracking.trackVoucherApplied({
        voucherCode: selectedVoucher.code,
        voucherId: selectedVoucher.id,
        voucherUsageId: selectedVoucher.voucher_usage_id,
      });
    }
  };

  const removeAppliedVoucher = (): void => {
    setSelectedVoucher(null);
    if (applied) onRemove();
  };

  const showVoucherDetail = (voucher: IVoucher) => () => {
    setSelectedVoucher(voucher);
  };

  const closeVoucherDetail = (): void => {
    setSelectedVoucher(null);
    if (showDetail) onClose();
  };

  const onVoucherCodeChange = (event): void => {
    event.preventDefault();

    const { target } = event;

    setVoucherCode(target.value);
  };

  const findVoucherCode = (): void => {
    if (voucherCode) swipeRxPt.voucherTracking.trackSearchVoucher({ searchText: voucherCode });
    dispatch(getAvailableVouchers({ voucherCode }));
  };

  const loadMoreVoucher = (): void => {
    if (meta && meta.page_count > meta.page) dispatch(getAvailableVouchers({ page: meta.page + 1 }));
  };

  return (
    <>
      <Container hidden={!open || showDetail}>
        <Header title={t('vouchers')} onBack={onClose} />
        <Content>
          <TextField
            data-testid="search-voucher-field"
            value={voucherCode}
            onChange={onVoucherCodeChange}
            fullWidth
            variant="outlined"
            placeholder={t('enterYourVoucherCodeHere')}
            style={{ background: 'white' }}
          />
          <Button
            data-testid="search-btn"
            onClick={findVoucherCode}
            disabled={isVoucherLoading}
            style={{
              marginTop: 25,
              marginBottom: 50,
              borderRadius: 20,
              background: colors.PRIMARY_COLOR,
              color: colors.WHITE,
              width: '100%',
              height: 40,
              fontWeight: 700,
              fontSize: 14,
            }}
          >
            {isVoucherLoading ? <CircularProgress size={16} style={{ color: colors.WHITE }} /> : t('search')}
          </Button>

          <ShowIf condition={!isVoucherLoading && options.length <= 0}>
            <EmptyVoucherImage />
            <Typography gutterBottom style={{ fontSize: 18, fontWeight: 700 }}>
              Stay tuned!
            </Typography>
            <Typography gutterBottom style={{ fontSize: 12, fontWeight: 400 }}>
              New voucher is coming soon
            </Typography>
          </ShowIf>

          <ShowIf condition={options.length > 0}>
            {options.map((voucher, index) => (
              <>
                <VoucherCard
                  data-testid={`voucher-card-id-${voucher.voucher_type.id}`}
                  key={voucher.voucher_usage_id}
                  voucher={voucher}
                  onClick={showVoucherDetail(voucher)}
                  onApplyVoucher={handleApplyVoucher(voucher)}
                  isVoucherLoading={isVoucherLoading}
                />
                {index === options.length - 1 && <br />}
              </>
            ))}

            <ShowIf condition={meta && meta.page_count > meta.page}>
              <Button
                data-testid="load-more-btn"
                onClick={loadMoreVoucher}
                disabled={isVoucherLoading}
                style={{
                  borderRadius: 20,
                  background: colors.PRIMARY_COLOR,
                  color: colors.WHITE,
                  height: 32,
                  fontWeight: 700,
                  fontSize: 12,
                  marginTop: -40,
                }}
              >
                {isVoucherLoading ? <CircularProgress size={12} style={{ color: colors.WHITE }} /> : t('loadMore')}
              </Button>
            </ShowIf>
          </ShowIf>
        </Content>
      </Container>

      <VoucherDetail
        voucher={selectedVoucher}
        isApplied={isApplied}
        onClose={closeVoucherDetail}
        onApplyVoucher={applySelectedVoucher}
        onRemoveVoucher={removeAppliedVoucher}
        isVoucherLoading={isVoucherLoading}
      />
    </>
  );
};

export const Voucher = translate('voucher')(VoucherBase);
