import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as colors from 'components/styles/colors';

import InfoIcon from '@material-ui/icons/Info';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

const UploadInstructionInfoIcon = styled(InfoIcon)`
  && {
    color: ${colors.REBRAND_GREEN};
    font-size: 12px;
    margin-right: 12px;
  }
`;
const UploadInstructionText = styled(Typography)`
  && {
    color: ${colors.REBRAND_GREEN};
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
const UploadInstructionArrowIcon = styled(ArrowForwardIcon)`
  && {
    color: ${colors.REBRAND_GREEN};
    font-size: 12px;
    float: right;
  }
`;

const DownloadButton = styled(Button)`
  box-sizing: border-box !important;

  height: 30px !important;
  width: 80vw;

  /* Dark Color Scheme/White */

  background: #ffffff;
  /* Primary SwipeRx Green */

  border: 1px solid #14c47d !important;
  border-radius: 20px !important;

  font-family: Nunito Sans, sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.16px !important;
  text-transform: uppercase !important;
  color: #14c47d !important;
  margin-top: 10px !important;
`;

const DownloadIconStyle = styled.div`
  position: relative;
  padding: 5px;
`;

const CardHeader = styled(Grid)`
  border-bottom: 1px solid ${colors.SUBTLE_GRAY};
  width: 100%;
  padding-bottom: 5px;
`;

export const S = {
  UploadInstructionInfoIcon,
  UploadInstructionText,
  UploadInstructionArrowIcon,
  DownloadButton,
  DownloadIconStyle,
  CardHeader,
};
