import { ActivityStatus } from 'services/swipe-rx-pt/resources/orders/types/order-activity-status.type';

export const OrderActivityStatusHistory: ActivityStatus[] = [
  ActivityStatus.PENDING,
  ActivityStatus.PROCESSING,
  ActivityStatus.ACCEPTED,
  ActivityStatus.ORDER_CHANGED,
  ActivityStatus.DISPATCHED,
  ActivityStatus.DELIVERY_DELAYED,
  ActivityStatus.DELIVERED,
  ActivityStatus.CANCELLED,
];
