import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, Divider, Button } from '@material-ui/core';

import * as colors from 'components/styles/colors';

const ChevronRightIcon = (): JSX.Element => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.243489 11.9895C0.56703 12.3088 1.08866 12.3088 1.4122 11.9895L6.8992 6.57395C7.15671 6.31979 7.15671 5.90922 6.8992 5.65506L1.4122 0.239497C1.08866 -0.0798323 0.56703 -0.0798323 0.243489 0.239497C-0.0800527 0.558826 -0.0800527 1.07366 0.243489 1.39299L5.02398 6.11776L0.236886 10.8425C-0.0800527 11.1553 -0.0800527 11.6767 0.243489 11.9895Z"
      fill="#545454"
    />
  </svg>
);

const Card = styled(Grid)`
  background-color: ${colors.WHITE};
  box-shadow: 0px 2px 10px rgba(58, 62, 71, 0.22);
  margin-bottom: 16px;
  border-radius: 10px;
  position: relative;
  padding-top: 30px;
`;

const CardStatusHeader = styled(Grid)<{ color?: string }>`
  && {
    position: absolute;
    top: 0;
    left: 0;

    .primary {
      padding: 8px 16px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      background-color: ${(props) => props.color || colors.ORANGE};
      z-index: 9;
    }

    .secondary {
      padding: 8px 16px;
      border-bottom-right-radius: 10px;
      background-color: ${(props) => props.color || colors.ORANGE_LIGHT};
      margin-left: -8px;
      padding-left: 24px;
      z-index: 8;
    }
  }
`;

const CardContainer = styled(Grid)`
  padding: 10px 15px 15px 15px;
`;

const CardHeader = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const Row = styled(Grid)<any>`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .align-right {
      text-align: right;
    }
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 800;
    word-break: break-all;
    color: ${colors.TEXT_LIGHT};
  }
`;

const Subtitle = styled(Typography)`
  && {
    font-size: 11px;
    font-weight: 700;
    word-break: break-all;
    color: ${colors.TEXT_LIGHT};
  }
`;

const Label = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: ${colors.BLUISH_GRAY_LIGHT};
    word-break: break-all;
  }
`;

const Status = styled(Typography)`
  && {
    font-size: 10px;
    color: ${colors.WHITE};
    font-weight: 700;
    text-transform: capitalize;
    line-height: 14px;
  }
`;

const Value = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: ${colors.TEXT_LIGHT};
    word-break: break-all;

    &&.text-gray {
      color: ${colors.BLUISH_GRAY_LIGHT};
    }
  }
`;

const Currency = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 800;
    color: ${colors.RED_HEART};
    &&.text-light {
      color: ${colors.TEXT_LIGHT};
    }
    &&.text-gray {
      color: ${colors.BLUISH_GRAY_LIGHT};
    }
  }
`;

const ViewDetailText = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
    color: #545454;
    line-height: 19px;
    text-transform: capitalize;
  }
`;

const ViewDetailIcon = styled(ChevronRightIcon)`
  && {
    padding-top: 6px;
  }
`;

const DividerLine = styled(Divider)`
  && {
    color: ${colors.WEAK_GRAY};
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const DownloadButton = styled(Button)`
  box-sizing: border-box !important;

  height: 30px !important;
  width: 80vw;

  /* Dark Color Scheme/White */

  background: #ffffff;
  /* Primary SwipeRx Green */

  font-family: Nunito Sans, sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.16px !important;
  text-transform: uppercase !important;
  color: #14c47d !important;
  margin-top: 10px !important;
`;

const DownloadIconStyle = styled.div`
  position: relative;
  padding: 5px;
`;

export const S = {
  Card,
  CardStatusHeader,
  CardContainer,
  CardHeader,
  DividerLine,
  Row,
  Status,
  Title,
  Subtitle,
  Currency,
  Label,
  Value,
  ViewDetailText,
  ViewDetailIcon,
  DownloadButton,
  DownloadIconStyle,
};
