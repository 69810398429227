import { Analytics, AnalyticsProvider } from '../interfaces';

export class SegmentIO implements AnalyticsProvider {
  init = (token: string): void => {
    window.analytics.load(token);
  };

  identify = (id: string, payload: Record<string, string>, analytics: Analytics): void => {
    // eslint-disable-next-line no-console
    if (analytics.isDebug()) console.info(`[segment.io] identify`, { id, payload });
    window.analytics.identify(id, payload);
  };

  track = (event: string, payload: Record<string, any>, analytics: Analytics): void => {
    // eslint-disable-next-line no-console
    if (analytics.isDebug()) console.info(`[segment.io] track`, { event, payload });
    window.analytics.track(event, payload);
  };

  page = (event: string, payload: Record<string, any>, analytics: Analytics): void => {
    // eslint-disable-next-line no-console
    if (analytics.isDebug()) console.info(`[segment.io] page`, { event, payload });
    window.analytics.page(event, payload);
  };
}
