import firebase from 'firebase/app';
import 'firebase/database';

const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(clientCredentials);
}

export type Locale = 'id' | 'th' | 'en';
export type LocaleMessage = {
  title: string;
  description: string;
};
export interface MaintenanceConfig {
  enabled: boolean;
  message: Record<Locale, LocaleMessage>;
  reopen: boolean | undefined;
}

export const getMaintenanceConfigDB = (): firebase.database.Reference | null => {
  let database;

  try {
    database = firebase.database().ref(process.env.REACT_APP_FIREBASE_MAINTENANCE_DATABASE_REF);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    database = null;
  }

  return database;
};

export default firebase;
