/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from 'reselect';
import { State as StoreState } from 'store';

import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';
import { State as MarketingFeaturesState } from '.';

const selectorMarketingFeatures = (state: StoreState): MarketingFeaturesState => state.marketingFeatures;

export const marketingfeaturesSelector = (featureType: MarketingFeaturesType, limit?: number) =>
  createSelector(selectorMarketingFeatures, (state) => {
    if (!featureType) return [];

    let data = state[featureType]?.data || [];

    if (limit && data.length && limit < data.length) {
      data = data.slice(0, limit);
    }

    return data;
  });
export const marketingfeaturesLoadingSelector = (featureType: MarketingFeaturesType) =>
  createSelector(selectorMarketingFeatures, (state) => {
    if (!featureType) return true;

    return state[featureType]?.loading;
  });
