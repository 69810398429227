import { PaymentProviders, PaymentProvidersID } from 'store/reducers/Account/constants';

export const getPaymentProvider = (paymentProviderId: number): PaymentProviders => {
  switch (paymentProviderId) {
    case PaymentProvidersID.DOKU:
      return PaymentProviders.DOKU;
    case PaymentProvidersID.XENDIT:
      return PaymentProviders.XENDIT;
    default:
      throw new Error('Invalid payment provider ID');
  }
};
