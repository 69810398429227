import React from 'react';
import { Grid as MUIGrid, Card as MUICard, CardContent, Typography, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TranslateProps } from 'utils/Localization/types';
import { Done } from '@material-ui/icons';
import { usePagination } from '../../../hooks/usePagination';
import { BinocularComponent } from '../../common/Binocular/component';

const Card = styled(MUICard)`
  && {
    border-radius: 0;
  }
`;

const Grid = styled(MUIGrid)`
  && {
    overflow-y: scroll;
    flex-direction: column;
    height: calc(100% - 9rem);
    position: absolute;
  }
`;

const PageEnd = styled(Typography)`
  color: #7c7c7c;
  text-align: center;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  svg {
    color: #44a340;
    font-size: 40px;
    margin: 0 auto 4px;
    display: block;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
`;

interface ProductSuggestion {
  id: number;
  product_name: string;
}

function DataComponent(data: Readonly<ProductSuggestion>[]): JSX.Element[] {
  return data.map((e) => (
    <Card key={e.id}>
      <CardContent>
        <Typography>{e.product_name}</Typography>
      </CardContent>
    </Card>
  ));
}

function EndMessageComponent(
  t: (key: any, options?: any) => string,
  collectionLength: number,
): JSX.Element | undefined {
  if (collectionLength <= 0) return undefined;
  return (
    <>
      <PageEnd>
        <Done />
        {t('pageEnd')}
      </PageEnd>
    </>
  );
}

export const Page: React.FC<React.PropsWithChildren<TranslateProps>> = (props: TranslateProps) => {
  const { t } = props;

  const pagination = usePagination<ProductSuggestion>(
    {
      url: 'products/suggestions',
      page: 1,
      pageSize: 20,
    },
    {
      data: [],
      totalCount: 0,
      hasMore: false,
    },
  );

  const content = pagination.totalCount <= 0 ? <BinocularComponent t={t} /> : DataComponent(pagination.data);

  return (
    <Grid container direction="column" data-testid="coming-soon-div">
      <InfiniteScroll
        height="auto"
        loader={
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        }
        dataLength={pagination.totalCount}
        hasMore={pagination.hasMore}
        next={pagination.loadMore}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          overflow: 'scroll',
        }}
        endMessage={EndMessageComponent(t, pagination.totalCount)}
      >
        {content}
      </InfiniteScroll>
    </Grid>
  );
};
