import React from 'react';
import { translate } from 'react-i18next';

import { toPercent } from 'utils/Format';
import { TFunction } from 'utils/Localization';
import { getCustomerSuccessSupport, getWhatsappSupportLink } from 'utils/MarketConfig';
import { Color } from '../../ProductCard.interface';
import { S } from './ProductCardRibbon.styles';

interface Props {
  color: Color;
  discount: number;
  isStockOut: boolean;
  isUnpurchasable?: boolean;
  position?: {
    left: string | number;
    top: string | number;
  };
  t: TFunction;
}

const ProductCardRibbonBase: React.FC<React.PropsWithChildren<Props>> = ({
  discount,
  color,
  isStockOut,
  isUnpurchasable,
  position = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  t,
}) => {
  if (isUnpurchasable) {
    position.left = '150px';
  } else {
    position.left = 0;
  }

  const customerSuccessSupport = getCustomerSuccessSupport();
  const whatsappSupportLink = getWhatsappSupportLink(t(`message`));

  return (
    <>
      {isUnpurchasable && (
        <S.VerifyAccount
          position={position}
          onClick={() => {
            if (whatsappSupportLink) {
              window.open(whatsappSupportLink);
            } else {
              window.open(customerSuccessSupport?.link, customerSuccessSupport?.target);
            }
          }}
        >
          {t('verifyAccount')}
        </S.VerifyAccount>
      )}
      {discount === 0 || isStockOut ? null : (
        <S.DiscountRibbon customcolor={color} position={position}>
          <S.DiscountText>-{toPercent(discount, 2)}%</S.DiscountText>
        </S.DiscountRibbon>
      )}
    </>
  );
};

ProductCardRibbonBase.defaultProps = {
  isUnpurchasable: undefined,
  position: {
    left: 0,
    top: 0,
  },
};

export const ProductCardRibbon = translate('unverifiedPharmacy')(ProductCardRibbonBase);
