import React from 'react';
import * as colors from 'components/styles/colors';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

export const PaymentsIconFilled: React.FC<React.PropsWithChildren<Props>> = ({
  height,
  width,
  fill
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 15V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H16C17.1 0 18 0.9 18 2V3H9C7.89 3 7 3.9 7 5V13C7 14.1 7.89 15 9 15H18ZM9 13H19V5H9V13ZM13 10.5C12.17 10.5 11.5 9.83 11.5 9C11.5 8.17 12.17 7.5 13 7.5C13.83 7.5 14.5 8.17 14.5 9C14.5 9.83 13.83 10.5 13 10.5Z" />
  </svg>
);

PaymentsIconFilled.defaultProps = {
  fill: colors.PRIMARY_COLOR,
  width: '20px',
  height: '20px'
};
