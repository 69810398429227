import * as Redux from 'redux';

import { AnalyticsStore } from './analytics.interface';
import { Actions, AnalyticsActionTypes } from './analytics.type';

const InitialStore: AnalyticsStore = {
  causalFoundry: {
    cfLog: null,
    searchId: '',
    cartId: '',
  },
};

export const AnalyticsReducer: Redux.Reducer<AnalyticsStore, Actions> = (state = InitialStore, action) => {
  switch (action.type) {
    case AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_CFLOG:
      return { ...state, causalFoundry: { ...state.causalFoundry, cfLog: action.cfLog } };
    case AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_SEARCHID:
      return { ...state, causalFoundry: { ...state.causalFoundry, searchId: action.searchId } };
    case AnalyticsActionTypes.CAUSAL_FOUNDRY_SET_CARTID:
      return { ...state, causalFoundry: { ...state.causalFoundry, cartId: action.cartId } };
    default:
      return state;
  }
};
