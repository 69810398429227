import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import MainPage, { StateProps } from './component';
import { State as StoreState } from '../../../store';
import { agreeToTerms } from '../../../store/reducers/Auth/actions';
import { saveTours, TriggerRenderTours, fetchTours } from '../../../store/reducers/Tour/actions';

// Map store state to props
const mapStateToProps = (state: StoreState): StateProps => ({
  cartItems: state.counter.totalItem,
  agreed: state.auth.agreed,
  tours: state.tours.tours,
  triggerRender: state.tours.trigger_render,
  config: state.config,
  isSearchMenuOpen: state.searchSuggestions.menuDrawerOpen,
});

// Map dispatch to props
const mapDispatchToProps = {
  agreeToTerms,
  saveTours,
  TriggerRenderTours,
  fetchTours,
};

export default translate('app')(connect(mapStateToProps, mapDispatchToProps)(MainPage));
