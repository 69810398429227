import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import * as colors from 'components/styles/colors';
import ScrollView from 'components/views/ScrollView';

const Wrapper = styled(ScrollView)`
  height: 100vh;
  background-color: ${colors.SNOW};
`;

const Header = styled(Grid)`
  && {
    padding: 18px 0;
    background-color: ${colors.WHITE};
    box-shadow: 0px 0px 1px #b5b9c2;
    width: 100%;
    position: relative;
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }
`;

const CancelBtn = styled(CancelIcon)`
  && {
    width: 30px;
    height: 30px;
    fill: #9399a8;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
  }
`;

const Text = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: normal;
    color: ${colors.TEXT_LIGHT};
  }
`;

const NeedAssistanceContainer = styled.div`
  margin: 15px 10px 24px;
`;

const NeedAssistanceBtn = styled.button`
  border: 1px solid #b3b8c8;
  border-radius: 5px;
  padding: 13px 0;
  font-size: 16px;
  color: ${colors.BLUISH_GRAY_LIGHT};
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    margin-right: 10px;
  }
`;

const NeedAssistanceText = styled(Text)`
  && {
    padding: 0 21px 11px;
    text-align: center;
    line-height: 19px;
  }
`;

export const S = {
  Wrapper,
  Header,
  Title,
  CancelBtn,
  Text,
  NeedAssistanceContainer,
  NeedAssistanceBtn,
  NeedAssistanceText,
};
