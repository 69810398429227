import React from 'react';
import { useParams } from 'react-router-dom';
import FavoritesProductPage from 'components/pages/FavoritesProductPage';
import ProductRemindersPage from 'components/pages/ProductRemindersPage';
import { ProductSuggestionPage } from 'components/pages/ProductSuggestionPage';

export const WishlistContents: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams();

  switch (id) {
    case 'favorites':
      return <FavoritesProductPage />;
    case 'reminders':
      return <ProductRemindersPage />;
    case 'suggestions':
      return <ProductSuggestionPage />;
    default:
      return <FavoritesProductPage />;
  }
};
