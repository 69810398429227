import styled from 'styled-components';
import { translate } from 'react-i18next';

import { FilterTabs } from 'components/common/filter-tabs';
import { BLUE, WHITE } from 'components/styles/colors';

const OrdersHistoryFiltersTabsBase = styled(FilterTabs)`
  && {
    & button {
      background-color: rgba(23, 51, 232, 0.1);
      & p {
        color: #545454;
        font-size: 12px;
        font-weight: 700;
      }
    }

    & button.active {
      background-color: ${BLUE};
      border-color: ${BLUE};
      box-shadow: none;
      & p {
        color: ${WHITE};
      }
    }
  }
`;

export const OrdersHistoryFilterTabs = translate('orders')(OrdersHistoryFiltersTabsBase);
