export const UPDATE_RECENT = 'UPDATE_RECENT';
export type UPDATE_RECENT = typeof UPDATE_RECENT;

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export type GET_ALL_PRODUCTS = typeof GET_ALL_PRODUCTS;

export const GET_SECTION_PRODUCTS = 'GET_SECTION_PRODUCTS';
export type GET_SECTION_PRODUCTS = typeof GET_SECTION_PRODUCTS;

export const START_SEARCH = 'START_SEARCH';
export type START_SEARCH = typeof START_SEARCH;

export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export type UPDATE_SEARCH = typeof UPDATE_SEARCH;

export const FAIL_SEARCH = 'FAIL_SEARCH';
export type FAIL_SEARCH = typeof FAIL_SEARCH;

export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export type CLEAR_SEARCH = typeof CLEAR_SEARCH;

export const FETCH_RECENT_SEARCH = 'FETCH_RECENT_SEARCH';
export type FETCH_RECENT_SEARCH = typeof FETCH_RECENT_SEARCH;

export const FETCH_SEARCH_SUGGESTION = 'FETCH_SEARCH_SUGGESTION';
export type FETCH_SEARCH_SUGGESTION = typeof FETCH_SEARCH_SUGGESTION;

export const SET_MOST_POPULAR_PRODUCTS = 'SET_MOST_POPULAR_PRODUCTS';
export type SET_MOST_POPULAR_PRODUCTS = typeof SET_MOST_POPULAR_PRODUCTS;

export const FETCH_RECENTLY_VIEWED_PRODUCT = 'FETCH_RECENTLY_VIEWED_PRODUCT';
export type FETCH_RECENTLY_VIEWED_PRODUCT = typeof FETCH_RECENTLY_VIEWED_PRODUCT;

export const SET_RECENTLY_VIEWED_PRODUCT = 'SET_RECENTLY_VIEWED_PRODUCT';
export type SET_RECENTLY_VIEWED_PRODUCT = typeof SET_RECENTLY_VIEWED_PRODUCT;

export const SET_CATEGORIES = 'SET_CATEGORIES';
export type SET_CATEGORIES = typeof SET_CATEGORIES;

export const SET_SPECIAL_MARKETING_CACHE = 'SET_SPECIAL_MARKETING_CACHE';
export type SET_SPECIAL_MARKETING_CACHE = typeof SET_SPECIAL_MARKETING_CACHE;

export const SET_PREVIOUS_RESULT_ID = 'SET_PREVIOUS_RESULT_ID';
export type SET_PREVIOUS_RESULT_ID = typeof SET_PREVIOUS_RESULT_ID;

export const CACHE_KEY_RECENTLY_SEARCHED_PRODUCTS = 'recently_searched_products';
export const CACHE_KEY_RECENTLY_VIEWED_PRODUCTS = 'recently_viewed_products';
export const CACHE_KEY_MOST_POPULAR_PRODUCTS = 'most_popular_products';
