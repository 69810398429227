import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import { translate } from 'react-i18next';

import { ReactComponent as WarningOutlined } from 'images/ic_warning_outlined.svg';

import { State } from 'store';
import { Product } from 'store/reducers/Product';
import { TranslateProps } from 'utils/Localization';
import { useProductLimit } from '../product-card-quantity-box/hooks/use-product-limit.hook';

interface Props {
  product: Product;
}

const MaxQuantityReachedBox = styled(Grid)`
  width: 100%;
  height: 38px;
  background: #ffefef;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 2px 0px;

  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16px;

  color: #dd3535;

  .notification-icon {
    font-size: 24px;
    margin: 0px 10px;
  }
`;

const BaseProductCardMaxQuantityInfo: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ t, product }) => {
  const { quantity_threshold, remaining_quantity } = product;
  const stockLeft = remaining_quantity - quantity_threshold;
  const { counterList } = useSelector((state: State) => state.counter, shallowEqual);

  const { productLimitExceeded, maxPurchasedReached, maxPurchaseMonthlyReached, productMonthlyLimitExceeded } =
    useProductLimit(product as unknown as Product, counterList);

  if (
    stockLeft > 0 &&
    ((!maxPurchaseMonthlyReached && (maxPurchasedReached || productLimitExceeded)) ||
      (!maxPurchasedReached && (maxPurchaseMonthlyReached || productMonthlyLimitExceeded)))
  ) {
    return (
      <MaxQuantityReachedBox>
        <WarningOutlined className="notification-icon" />
        {t('maxQtyReached')}
      </MaxQuantityReachedBox>
    );
  }
  return null;
};

export const ProductCardMaxQuantityInfo = translate('stockOut')(BaseProductCardMaxQuantityInfo);
