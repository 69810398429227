/* eslint-disable import/no-named-default */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import { PRIMARY_COLOR } from 'components/styles/colors';
import { ConfirmationDialog } from '../ProductCard/components/confirmation-dialog';

export interface Props {
  open: boolean;
  count: number;
  onClose: () => void;
  onUpdate: (value: number) => void;
  t?: any;
}
const EditQuanityBoxContainer = styled.div`
  .edit-title {
    font-size: 16px;
  }

  .quantity-title {
    font-size: 12px;
    color: #00000054;
  }

  .btn-edit {
    background-color: #2fbca1;
    font-size: 10px;
    color: #fff;
  }

  .edit-dialog-title {
    font-size: 16px;
    color: #333333;
  }

  .grid-edit-quantity-box {
    position: absolute;
    top: 65px;

    .edit-button {
      background-color: #2fbca1;
      font-size: 10px;
      color: #fff;
      min-width: 68px;
      height: 47px;
      border-radius: 6px;
      text-align: center;
      margin-left: -20px;
    }
  }
`;

export default class EditQuantityBox extends React.Component<Props> {
  state = {
    quantityValue: this.props.count || 0,
    isUserInputValue: false,
    isShowRemoveQuantityDialog: false,
  };

  handleChange = (event): void => {
    event.preventDefault();

    const {
      target: { value },
    } = event;
    const re = /^\d*$/;
    if (re.test(value)) {
      this.setState(() => ({
        quantityValue: value ? parseInt(value, 10) : '',
        isUserInputValue: true,
      }));
    }
  };

  updateCounter = (): void => {
    if (this.state.quantityValue <= 0) {
      this.setState({ isShowRemoveQuantityDialog: true });
      return;
    }

    this.updateQuantity();

    if (!this.state.isUserInputValue) {
      this.props.onClose();
      this.setState(() => ({ isUserInputValue: false }));
    }
  };

  closeRemoveQuantityDialog = (): void => {
    this.setState(() => ({ isShowRemoveQuantityDialog: false }));
  };

  updateQuantity = (): void => {
    const { onUpdate } = this.props;
    if (typeof onUpdate !== 'undefined' && this.state.isUserInputValue) {
      onUpdate(this.state.quantityValue ? this.state.quantityValue : 0);
      this.setState(() => ({ isUserInputValue: false }));
    }
  };

  render(): React.ReactNode {
    const { open, onClose, t } = this.props;
    return (
      <EditQuanityBoxContainer>
        <Dialog open={open} maxWidth="xs">
          <DialogContent style={{ padding: '18px' }}>
            <Grid container direction="column" justifyContent="space-between" alignItems="center">
              <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                <Grid item style={{ marginBottom: '14px' }}>
                  <Typography style={{ fontSize: '16px', fontWeight: 'normal' }}> Edit Quantity </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={onClose} style={{ padding: '0px' }} data-testid="button-close">
                    <CloseIcon style={{ color: '#000000', paddingBottom: '12px' }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <TextField
                    id="edit-quantity-box"
                    autoFocus
                    autoComplete="off"
                    inputProps={{
                      min: '0',
                      max: '10',
                      step: '1',
                      style: {
                        border: '1px',
                        backgroundColor: '#8080801c',
                        width: '105px',
                        height: '39px',
                        borderRadius: '6px',
                        textIndent: '15px',
                        fontSize: '16px',
                        padding: '0px',
                      },
                    }}
                    InputProps={{ disableUnderline: true }}
                    onChange={this.handleChange}
                    value={this.state.quantityValue}
                  />
                </Grid>
                <Grid item>
                  <Button
                    id="edit-quantity-button"
                    style={{
                      backgroundColor: PRIMARY_COLOR,
                      fontSize: '14px',
                      color: '#fff',
                      width: '65px',
                      height: '39px',
                      borderRadius: '4px',
                      textAlign: 'center',
                      marginLeft: '6px',
                    }}
                    onClick={this.updateCounter}
                  >
                    EDIT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <ConfirmationDialog
          key="remove-cart"
          showDialog={this.state.isShowRemoveQuantityDialog}
          closeDialog={this.closeRemoveQuantityDialog}
          handleYes={this.updateQuantity}
          t={t}
        />
      </EditQuanityBoxContainer>
    );
  }
}
