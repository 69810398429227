import React from 'react';
import AddToBasket from 'images/shopping-basket-add.svg';

import { IconButton } from '@material-ui/core';
import { Img } from 'components/pages/ProductDetailPage/components/ProductImage/style';

import { Product } from 'store/reducers/Product';
import { ISearchItem } from '../interface';

export const AddToCartIconButton: React.FC<React.PropsWithChildren<{
  onClickCartIcon: ((product?: Product) => void) | undefined;
  product: ISearchItem;
}>> = (props) => {
  const { onClickCartIcon, product } = props;
  return (
    <IconButton
      onClick={onClickCartIcon ? () => onClickCartIcon(product) : undefined}
      style={{
        display: 'block',
        position: 'absolute',
        margin: 'auto',
        right: '16px',
        top: 0,
      }}
    >
      <Img
        src={AddToBasket}
        alt="product-thumbnail"
        style={{
          width: '24px',
        }}
      />
    </IconButton>
  );
};
