import React from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';

import { LogisticDeliveryStatus, Order, OrderStatus, PharmacyOrderStatus } from 'services';
import { useOrderStatus } from 'hooks';
import { DataObject } from 'types';

import {
  OrderConfirmIcon,
  OrderProcessIcon,
  OrderOnDeliveryIcon,
  OrderDeliveredIcon,
  OrderCancelledIcon,
} from '../orders-status-modal';
import { S } from './orders-modal-summary.styles';

export const OrdersModalSummaryStatusIcon: React.FC<React.PropsWithChildren<{
  status: OrderStatus | PharmacyOrderStatus;
  variant: string;
  deliverystatus: LogisticDeliveryStatus | null;
}>> = ({ status, variant, deliverystatus = null }) => {
  const props = {
    style: { width: '18px', height: '18px' },
    variant,
  };

  switch (status) {
    case PharmacyOrderStatus.ORDER_RECEIVED:
    case PharmacyOrderStatus.WAITING_DOCUMENT:
      return <OrderConfirmIcon {...props} />;
    case PharmacyOrderStatus.PREPARING_GOODS:
    case PharmacyOrderStatus.DELAYED:
      return <OrderProcessIcon {...props} />;
    case PharmacyOrderStatus.OUT_OF_DELIVERY:
      if (deliverystatus === LogisticDeliveryStatus.DELIVERY_FAILED) {
        return <OrderCancelledIcon {...props} />;
      }
      return <OrderOnDeliveryIcon {...props} />;
    case PharmacyOrderStatus.DELIVERED:
      return <OrderDeliveredIcon {...props} />;
    case PharmacyOrderStatus.CANCELLED:
    case PharmacyOrderStatus.CANCELLED_FULL_RETURN:
      return <OrderCancelledIcon {...props} />;
    default:
      return null;
  }
};

export const OrdersModalSummaryStatus: React.FC<React.PropsWithChildren<{
  order: Order | DataObject;
  onClick: () => void;
  t: any;
}>> = ({ order, onClick, t }) => {
  const { logistics_delivery_status: deliveryStatus } = order;
  const { status, values, showSecondaryStatusLabel } = useOrderStatus(order, t);

  const variant = showSecondaryStatusLabel ? 'secondary' : 'primary';

  return (
    <Grid item container direction="column" style={{ paddingTop: '5px' }}>
      <Grid item container spacing={1}>
        <Grid item>
          <OrdersModalSummaryStatusIcon
            status={status}
            deliverystatus={deliveryStatus?.status ?? null}
            variant={variant}
          />
        </Grid>
        <Grid item>
          <S.Value
            status={status}
            deliverystatus={deliveryStatus?.status}
            className="bold"
            data-testid="order-details-status"
          >
            {values.join(' - ')}
          </S.Value>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-end" spacing={1}>
        <S.ViewButton id="tour_orders_tab_status" data-testid="order-activity-view-status" onClick={onClick}>
          {t('viewStatus')} <ArrowForward />
        </S.ViewButton>
      </Grid>
    </Grid>
  );
};
