import { SwipeRxPt } from '../../SwipeRxPt.class';

import { PaymentDirectBillRetrieveParams } from './interfaces';
import { PaymentDirectBill } from './interfaces/payment-direct-bill.interface';

export class SwipeRxPtDirectPaymentBills {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async retrieve(params: PaymentDirectBillRetrieveParams): Promise<PaymentDirectBill> {
    const { data } = await this.base.get('/payment/bill', params);

    return data;
  }

  async payNow(paymentDirectId: number): Promise<PaymentDirectBill> {
    const resp = await this.base.patch(`/payment/${paymentDirectId}/pay-now`);

    return resp.data;
  }
}
