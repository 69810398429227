import { Action } from 'redux';
import { recordException } from 'utils/Reporting/Sentry';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { Actions as GlobalActions, showError } from '../Global/actions';
import { v3Client } from '../../../utils/Client/v3';

/**
 * Action Types
 */

export interface GetToursAction extends Action {
  type: constants.GET_TOURS;
  tour: string[];
}

export interface UpdateToursAction extends Action {
  type: constants.UPDATE_TOURS;
  tour: string[];
}

export interface TriggerToursAction extends Action {
  type: constants.TRIGGER_TOURS;
  trigger: boolean;
}

export interface SetToursLoading extends Action {
  type: constants.SET_TOURS_LOADING;
  loading: boolean;
}

export type Actions = GetToursAction | UpdateToursAction | GlobalActions | TriggerToursAction | SetToursLoading;

/**
 * Actions
 */
const setToursLoading = (loading: boolean): SetToursLoading => ({
  type: constants.SET_TOURS_LOADING,
  loading,
});

export const fetchTours: ThunkActionCreator<Actions> = () => async (dispatch) => {
  try {
    dispatch(setToursLoading(true));
    const response: any = await v3Client.get('me/tours');

    const tour = response && response.data.tours ? response.data.tours : [];

    // Dispatch the results
    dispatch({ type: constants.GET_TOURS, tour });
    dispatch(setToursLoading(false));

    return tour;
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
      recordException(error, 'fetchTours');

      // Dispatch the error
      dispatch(showError(error.message));
      dispatch(setToursLoading(false));
    }
    return null;
  }
};

export const saveTours: ThunkActionCreator<Actions> = (tours) => async (dispatch) => {
  try {
    const result: any = await v3Client.put('me/tours', {
      tours,
    });

    // Dispatch the results
    dispatch({
      type: constants.UPDATE_TOURS,
      tour: result.data.tours,
    });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'saveTours', { tours });
      // eslint-disable-next-line no-console
      console.error(error.message);

      // Dispatch the error
      dispatch(showError(error.message));
    }
  }
};

export const TriggerRenderTours: ThunkActionCreator<Actions> = (value: boolean) => async (dispatch) => {
  // Dispatch the results
  dispatch({
    type: constants.TRIGGER_TOURS,
    trigger: value,
  });
};
