import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { DistributorCartProduct } from 'store/reducers/Cart';
import { requestProductAvailability } from 'store/reducers/Product/actions';
import { deleteProductReminder } from 'store/reducers/ProductReminder/actions';
import { ProductSimpleSearchLocationProps } from 'utils/Analytics/Segment';
import { trackAddReminderProduct, trackRemoveProductFromRemindersPage } from 'utils/Analytics/Segment/SegmentProduct';
import { useMarketingAnalytics } from './useMarketingAnalytics';
import { useToggle } from './useToggle';

export const useStockOutCart = (product: DistributorCartProduct) => {
  const {
    id: productId,
    distributor: { id: distributorId },
    stock: { remaining_quantity, quantity_threshold: threshold },
  } = product;

  const dispatch = useDispatch();

  const [availabilityRequested, setAvailabilityRequestStatus] = useState(false);

  const { isOpen, toggle } = useToggle();
  const location = useLocation<ProductSimpleSearchLocationProps>();
  const search_section = location?.state?.search_section;

  const isBothZero = remaining_quantity === 0 && threshold === 0;
  const isStockOut = remaining_quantity <= threshold || isBothZero;

  const marketingAnalytics = useMarketingAnalytics();

  const requestAvailability = (): void => {
    toggle();
    trackAddReminderProduct({
      product_id: productId,
      sku_code: product.sku_code,
      page: marketingAnalytics.from_page,
      search_section,
    });
    requestProductAvailability(productId, distributorId);
    setAvailabilityRequestStatus(true);
  };

  const removeRequestAvailability = (availabilityRequestId: number): void => {
    dispatch(
      deleteProductReminder({
        productId,
        availabilityRequestId,
        distributorId,
      }),
    );
    trackRemoveProductFromRemindersPage({
      product_id: product.id,
      sku_code: product.sku_code,
      page: marketingAnalytics.from_page,
    });
    setAvailabilityRequestStatus(false);
  };

  return {
    isOpen,
    toggle,
    requestAvailability,
    removeRequestAvailability,
    isStockOut,
    setAvailabilityRequestStatus,
    availabilityRequested,
  };
};
