import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

const Container = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 300px);
`;

const Text = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const S = {
  Container,
  Text,
};
