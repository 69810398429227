import React from 'react';
import { store } from 'store';
import { useDebouncedCallback } from 'use-debounce';

import VisibilitySensor from 'react-visibility-sensor';
import {
  BasePageTracker,
  ImpressionType,
  ProductAnalyticsProps,
  ProductListPageAnalyticsProps,
  SearchSectionState,
  SegmentPropsBuilder,
  trackImpression,
} from 'utils/Analytics/Segment';
import { isProductImpressionSelector } from 'store/reducers/ProductImpression/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setProductImpression } from 'store/reducers/ProductImpression/actions';
import { IPropsCollector } from './ImpressionHelper';

interface IVisibilitySensorConfig {
  scrollCheck?: boolean;
  scrollThrottle?: number;
  resizeCheck?: boolean;
  resizeThrottle?: number;
  delayedCall?: boolean;
}

interface IPropsImpresion {
  orderNumber?: number;
  impressionCollector?: (payload: IPropsCollector) => void;
  product: ProductAnalyticsProps;
  marketingQuery?: ProductListPageAnalyticsProps;
  customDelayedTime?: number;
  searchQuery?: string | string[] | any;
  productImpressionPage?: BasePageTracker;
  visibilitySensorConfig?: IVisibilitySensorConfig;
  singleImpression?: boolean;
  locationState?: SearchSectionState;
  impressionType?: ImpressionType;
}

export const WithImpression: React.FC<React.PropsWithChildren<IPropsImpresion>> = React.memo((props) => {
  const {
    children,
    orderNumber,
    impressionCollector,
    product,
    marketingQuery,
    customDelayedTime,
    visibilitySensorConfig,
    searchQuery,
    productImpressionPage = BasePageTracker.BLANK,
    singleImpression,
    locationState,
    impressionType,
  } = props;
  const dispatch = useDispatch();
  const { config } = store.getState();
  const {
    market: { featureConfigs },
  } = config;
  const { product_impression = {} } = featureConfigs || {};

  const isImpression = useSelector(
    isProductImpressionSelector(
      impressionType as ImpressionType,
      product.id,
      marketingQuery?.marketing_id as number | undefined,
      productImpressionPage,
    ),
  );

  const { resizeCheck, resizeThrottle, scrollCheck, scrollThrottle, delayedCall } = visibilitySensorConfig || {};

  const isImpressionEnable = product_impression[productImpressionPage]?.listing || false;
  const scrollingTime = product_impression[productImpressionPage]?.listing_scrolling_time || 3000;
  const handleImpression = useDebouncedCallback(
    (isVisible: boolean): void => {
      if (!isImpression && isVisible) {
        if (impressionCollector && !singleImpression) {
          const productImpression = SegmentPropsBuilder.buildProductPayloadMapping(product, orderNumber);
          impressionCollector({
            impressionFrom: productImpressionPage,
            campaignValue: marketingQuery,
            customImpressionTime: !customDelayedTime ? scrollingTime : customDelayedTime,
            productImpression,
            query: searchQuery,
            locationState,
            impressionType,
          });
        } else {
          const payloadProductImpression = SegmentPropsBuilder.buildProductPayloadMapping(product, orderNumber);
          const payloadImpression = SegmentPropsBuilder.buildImpressionTrackingProps(
            [payloadProductImpression],
            searchQuery,
            !customDelayedTime ? scrollingTime : customDelayedTime,
            productImpressionPage,
            impressionType,
            marketingQuery,
            locationState,
          );
          trackImpression(payloadImpression);
          dispatch(
            setProductImpression({
              displayType: impressionType,
              productIds: payloadImpression.product_ids,
              marketingId: payloadImpression?.marketing_id,
              page: productImpressionPage,
            }),
          );
        }
      }
    },
    !customDelayedTime ? scrollingTime : customDelayedTime,
  );

  return (
    <VisibilitySensor
      key={product.id}
      active={isImpressionEnable && !isImpression}
      onChange={(isVisible) => handleImpression(isVisible)}
      scrollCheck={scrollCheck}
      resizeCheck={resizeCheck}
      scrollThrottle={scrollThrottle}
      resizeThrottle={resizeThrottle}
      delayedCall={delayedCall}
    >
      {children}
    </VisibilitySensor>
  );
});

WithImpression.defaultProps = {
  orderNumber: undefined,
  impressionCollector: undefined,
  marketingQuery: undefined,
  customDelayedTime: undefined,
  searchQuery: null,
  productImpressionPage: BasePageTracker.BLANK,
  visibilitySensorConfig: {
    resizeCheck: true,
    resizeThrottle: 0,
    scrollCheck: true,
    scrollThrottle: 0,
    delayedCall: false,
  },
};
