import { Action } from 'redux';
import * as constants from './constants';

export interface ISnackbar {
  open: boolean;
  message: string;
  params?: Record<string, string | number>;
  titleAction?: string;
  contentProps?: any;
  actionPath?: string;
}

export interface ISnackbarCart {
  open: boolean;
  productId: number;
  itemCount: number;
  maxPurchaseLimit: {
    purchaseableQuantity: number;
    hasPurchased: boolean;
  };
}

export interface SetOpenSnackbarAction extends Action {
  type: constants.SET_OPEN_SNACKBAR;
  snackbar: ISnackbar;
}

export interface SetCloseSnackbarAction extends Action {
  type: constants.SET_CLOSE_SNACKBAR;
  snackbar: ISnackbar;
}

export interface SetOpenSnackbarCartAction extends Action {
  type: constants.SET_OPEN_SNACKBAR_CART;
  snackbarCart: ISnackbarCart;
}

export interface SetCloseSnackbarCartAction extends Action {
  type: constants.SET_CLOSE_SNACKBAR_CART;
  snackbarCart: ISnackbarCart;
}

export type Actions =
  | SetOpenSnackbarAction
  | SetCloseSnackbarAction
  | SetOpenSnackbarCartAction
  | SetCloseSnackbarCartAction;

export const setOpenSnackbar = (snackbar: ISnackbar): SetOpenSnackbarAction => ({
  type: constants.SET_OPEN_SNACKBAR,
  snackbar,
});

export const setCloseSnackbar = (): SetCloseSnackbarAction => ({
  type: constants.SET_CLOSE_SNACKBAR,
  snackbar: { open: false, message: '' },
});

export const setOpenSnackbarCart = (snackbarCart: ISnackbarCart): SetOpenSnackbarCartAction => ({
  type: constants.SET_OPEN_SNACKBAR_CART,
  snackbarCart,
});

export const setCloseSnackbarCart = (): SetCloseSnackbarCartAction => ({
  type: constants.SET_CLOSE_SNACKBAR_CART,
  snackbarCart: {
    open: false,
    productId: 0,
    itemCount: 0,
    maxPurchaseLimit: { purchaseableQuantity: 0, hasPurchased: false },
  },
});
