import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import TabsBase from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

export const Divider = styled.div`
  && {
    height: 0.2rem;
    margin-top: -0.2rem;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const StyledNavLink = styled(NavLink)`
  && {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;

    margin-top: 6px;
    margin-left: 6px;
    margin-right: 6px;
    height: 35;

    text-decoration: none;

    &:link {
      text-decoration: none;
    }

    &:active {
      color: inherit;
      background: rgb(0, 0, 0, 0);
      text-decoration: none;
    }
  }
  &&.fullWidth {
    width: 100%;
  }
`;

export const StyledTab = withStyles({
  root: {
    color: colors.BLACK,
    fontWeight: 800,
    paddingTop: 2,
    fontSize: 11,
    paddingBottom: 2,
    textAlign: 'center',
    background: colors.BLUISH_GRAY_LIGHTEST,
    borderRadius: 30,
    minHeight: 35,
    minWidth: '25vw',
  },
  selected: {
    color: colors.BLUE,
    background: colors.WHITE,
  },
})(Tab);

export const Tabs = withStyles({
  root: {
    borderRadius: '30px 30px 0 0',
    height: 77,
  },
  indicator: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  scroller: {
    background: colors.BLUISH_GRAY_LIGHTEST,
    marginTop: 15,
    height: 47,
    borderRadius: 100,
    maxWidth: '95vw',
    flex: 'unset',
  },
  fixed: {
    width: 'unset',
  },
  flexContainer: {
    justifyContent: 'space-between',
  },
})(TabsBase);
