/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useEffect } from 'react';

import uniq from 'lodash/uniq';
import ShowIf from 'components/views/ShowIf';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { State } from 'store';
import { saveTours } from 'store/reducers/Tour/actions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { TooltipParams } from './type';

export interface TooltipTourParams {
  name: string;
  children: React.ReactNode;
  tooltipParams: Omit<TooltipParams, 'setTriggerRef'>;
  visible: boolean | undefined | null | string;
  arrowOffsetX?: number;
  arrowOffsetY?: number;
  offsetY?: number;
  offsetX?: number;
  width?: string | number;
  zIndex?: number;
  unsetOverflowY?: string;
}

export const TooltipTour: React.FC<React.PropsWithChildren<TooltipTourParams>> = (props) => {
  const {
    name,
    children,
    tooltipParams: { setTooltipRef, getTooltipProps, getArrowProps },
    visible,
    arrowOffsetX = 0,
    arrowOffsetY = 0,
    offsetY = 0,
    offsetX = 0,
    width = '90%',
    zIndex = 9999,
  } = props;

  const dispatch = useDispatch();
  const [showTour, setShowTour] = useState(false);

  useEffect(() => {
    setShowTour(!!visible);
  }, [visible]);

  const { tours } = useSelector((state: State) => state.tours, shallowEqual);

  const handleDismissTour = (): void => {
    const currentTours: string[] = tours || [];
    dispatch(saveTours(uniq([...currentTours, name])));
    setShowTour(false);
  };

  const tooltipProps = getTooltipProps({
    className: 'tooltip-container',
    style: { maxWidth: width, pointerEvents: 'visible' },
  });
  tooltipProps.style.transform = `${tooltipProps.style.transform} translateX(${offsetX}px) translateY(${offsetY}px)`;
  tooltipProps.style.zIndex = zIndex;

  const arrowProps = getArrowProps({ className: 'tooltip-arrow' });
  arrowProps.style.transform = `${arrowProps.style.transform} translateX(${arrowOffsetX}px) translateY(${arrowOffsetY}px)`;
  arrowProps.style.zIndex = zIndex;

  return (
    <ShowIf condition={showTour}>
      <div ref={setTooltipRef} {...tooltipProps} onClick={handleDismissTour}>
        <Grid container direction="row">
          <Grid item>{children}</Grid>
          <Grid item>
            <IconButton style={{ padding: 0 }} onClick={handleDismissTour} data-testid="close-tooltip">
              <Close style={{ fontSize: 14 }} />
            </IconButton>
          </Grid>
        </Grid>
        <div {...arrowProps} />
      </div>
    </ShowIf>
  );
};
