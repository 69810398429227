import React from 'react';
import { Grid } from '@material-ui/core';
import { toCurrency } from 'utils/Format';
import { translate } from 'react-i18next';
import { PaymentDetailsCard } from './PaymentDetailsCard';

interface Props {
  t: any;
  amount: number;
  paid: boolean;
  billCode?: string;
  billReference?: string;
}

export const PaymentDetailsComponent: React.FC<React.PropsWithChildren<Props>> = ({ t, amount, paid, billCode, billReference }) => (
  <Grid item container alignItems="flex-start" justify="center" xs={12}>
    <Grid container alignItems="center">
      <PaymentDetailsCard
        label={t('totalAmount')}
        value={toCurrency(amount)}
        width="100vw"
        variant="horizontal"
        paid={paid}
        billCode={billCode}
        billReference={billReference}
      />
    </Grid>
  </Grid>
);

export const PaymentDetails = translate('directPayment')(PaymentDetailsComponent);
