import { Typography } from '@material-ui/core';
import { PRIMARY_COLOR } from 'components/styles/colors';
import styled from 'styled-components';
import Slider from 'react-slick';

export const SlickContainer = styled.div<{ overflow?: boolean }>`
  margin: 0px;
  padding: 0px;

  .slick-list {
    overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
    position: relative;

    display: block;
    margin: 0;
    padding: 0;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-right: auto;
  }

  .slick-slider {
    margin-bottom: 8px;

    position: relative;

    display: block;
    box-sizing: border-box;

    user-select: none;

    touch-action: pan-y;
  }

  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: flex;
    height: clamp(8vh, 68px, 10vh);
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;

    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev {
    left: -25px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: white;
  }

  .slick-prev:before {
    content: '←';
  }

  .slick-next {
    right: -25px;
  }

  .slick-next:before {
    content: '→';
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }

  [dir='rtl'] .slick-prev:before {
    content: '→';
  }

  [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
  }

  [dir='rtl'] .slick-next:before {
    content: '←';
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 4px;

    list-style: none;

    text-align: center;
    bottom: -28px;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 10px;
    padding: 0;
    cursor: pointer;
    width: 14px;
    margin: 0 2px;
    transition: height 0.3s ease-in-out;
    vertical-align: middle;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 10px;
    height: 10px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  .slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 10px;
    height: 10px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: black;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }

  /* Custom Slick Dots */

  .ft-slick__dots--custom {
    height: 2px;
    width: 16px;
    background-color: #e5e7e9;
    border-radius: 4px;
    position: relative;
  }

  .slick-dots .slick-active {
    width: 56px;
    transition: height 0.3s ease-in-out;
    bottom: 1px;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 56px;
    top: 0px;
    overflow: hidden;
    background-color: ${PRIMARY_COLOR};
    height: 3px;
  }
`;

export const IconContainer = styled.div<{ last?: boolean }>`
  && {
    display: flex;
    height: 100%;
    max-width: 88px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: ${(props) => (props.last ? '6px' : '12px')};
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export const PaperContent = styled.div`
  && {
    width: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: red;
    border-width: 2px;
  }
`;

export const Img = styled.img`
  && {
    width: 60px;
    height: 60px;
  }
`;

export const NameContainer = styled(Typography)`
  && {
    text-align: center;
    padding-top: 8%;
    height: clamp(2vh, 4vh, 8vh);
    line-height: 18px;
    width: 90%;
    font-size: 12px;
  }
`;

export const SlickSliderStyled = styled(Slider)`
  && {
    overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
  }
`;
