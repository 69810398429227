import * as Redux from 'redux';
import { Optional } from 'utility-types';
import { DEFAULT_MAX_SEARCH_INPUT_LENGTH } from 'utils/constants';
import * as constants from './constants';
import { Actions } from './actions';
import { IConfig } from './config.type';

export interface ConfigState extends Optional<IConfig, 'marketId'> {
  marketId?: string;
}

const initialState: ConfigState = {
  marketId: undefined,
  market: {
    locale: 'en',
    language: 'en',
    activations: [],
    prekursorPageUrl: '',
    customerSuccessSupport: {
      target: '_self',
      link: '#',
    },
    thirdPartyConfig: {
      sentry: {
        enabled: true,
      },
    },
    featureConfigs: {
      product_impression: {
        general_search: {
          listing: false,
          listing_scrolling_time: 5000,
        },
        alphabet_listing: {
          listing: false,
          listing_scrolling_time: 5000,
        },
        loyalty: {
          listing: false,
          listing_scrolling_time: 5000,
        },
        marketing_playlist_homepage: {
          listing: false,
          listing_scrolling_time: 5000,
        },
        marketing_playlist_listing: {
          listing: false,
          listing_scrolling_time: 5000,
        },
        recently_viewed_listing: {
          listing: false,
          listing_scrolling_time: 5000,
        },
      },
      maxSearchInputLength: DEFAULT_MAX_SEARCH_INPUT_LENGTH,
    },
    precursorIosDownload: false,
  },
  paymentMethod: {
    credit: {
      active: false,
      provider: null,
    },
    directPayment: {
      active: false,
      provider: null,
      billExpiration: null,
    },
  },
};

const MarketConfigReducer: Redux.Reducer<ConfigState, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_MARKET_ID:
      return {
        ...state,
        marketId: action.payload,
      };
    case constants.FETCH_MARKET_CONFIG:
      return { ...state, ...action.payload };
    case constants.SET_MARKET_LANGUAGE:
      return {
        ...state,
        market: { ...state.market, language: action.payload },
      };
    case constants.SET_MARKET_LOCALE:
      return {
        ...state,
        market: { ...state.market, locale: action.payload },
      };
    default:
      return state;
  }
};

export default MarketConfigReducer;
