import React from 'react';
import { translate } from 'react-i18next';
import styled from 'styled-components';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { TFunction } from 'utils/Localization';
import { LoyaltyProductEarnPoint } from 'store/reducers/loyalty-program';

import { ReactComponent as ProductCoinIcon } from 'images/ic_coin.svg';
import { ReactComponent as ProductCoinRewardIcon } from 'images/ic_coin_reward.svg';

import { LOYALTY_POINT } from 'components/styles/colors';
import { toCurrency } from 'utils/Format';

const Container = styled(Grid)`
  && {
    background-color: ${LOYALTY_POINT.light};
    border-radius: 10px;
    padding: 5px 10px;

    & p {
      font-size: 12px;
      font-weight: 400;
      font-style: italic;

      & .bold {
        color: ${LOYALTY_POINT.dark};
        font-weight: 800;
      }
    }
  }
`;

interface Props {
  t: TFunction;
  // eslint-disable-next-line react/require-default-props
  point?: LoyaltyProductEarnPoint;
}

const BaseProductLoyaltyPoint: React.FC<React.PropsWithChildren<Props>> = ({ t, point }) => {
  if (!point || point.points === 0) return null;

  const { points, rewards } = point;

  return (
    <Container container alignItems="flex-start" spacing={1}>
      <Grid container item xs={1} justify="center">
        {rewards.length > 0 ? <ProductCoinRewardIcon /> : <ProductCoinIcon />}
      </Grid>
      <Grid item xs={11}>
        <Typography>
          {t('loyaltyPointTitle')} <span className="bold">{toCurrency(points)}</span>
        </Typography>
      </Grid>
    </Container>
  );
};

export const ProductLoyaltyPoint = translate('productDetailPage')(BaseProductLoyaltyPoint);
