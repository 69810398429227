import React from 'react';
import { IBanner } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { Banner } from 'components/common/Banner';
import { store } from 'store';
import { Container } from './banner-carousel.style';

import { SkeletonLoaderProps } from '../skeleton-loader';
import { SlickSliderStyled } from '../HomesquareCarousel/style';

export interface BannerLazyImage {
  height: number;
  width?: number;
  skeletonLoader?: SkeletonLoaderProps;
}

export interface BannerCarouselProps {
  data: Array<IBanner>;
  dotNavigationHidden?: boolean;
  onBannerClick: (banner: IBanner) => void;
  bannerLazyImage?: BannerLazyImage | undefined;
}

export const BannerCarousel: React.FC<React.PropsWithChildren<BannerCarouselProps>> = ({
  data,
  dotNavigationHidden = false,
  bannerLazyImage,
  onBannerClick,
}) => {
  const { config } = store.getState();
  const settings = {
    dots: !dotNavigationHidden,
    infinite: true,
    speed: 300,
    autoplay: true,
    draggable: true,
    autoplaySpeed: (config?.market?.banners?.banner_delay as number) || 3000,
    swipe: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    adaptiveHeight: false,
    pauseOnHover: true,
    arrows: false,
  };

  if (!data) return <></>;

  return (
    <Container>
      <SlickSliderStyled {...settings}>
        {data.map((b) => (
          <Banner
            key={b.id}
            src={b.banner_url}
            link={b.banner_link}
            lazyImage={bannerLazyImage}
            onClick={() => onBannerClick(b)}
            data-testid={`banner-key-${b.id}`}
          />
        ))}
      </SlickSliderStyled>
    </Container>
  );
};
