import React from 'react';

import { translate } from 'react-i18next';

import styled from 'styled-components';

import CardBase from '@material-ui/core/Card';
import CardContentBase from '@material-ui/core/CardContent';
import TypographyBase from '@material-ui/core/Typography';

import CheckCircleBase from '@material-ui/icons/CheckCircle';

import * as colors from '../../../styles/colors';

interface CopiedProps {
  t: any;
  hidden?: boolean;
  onDismiss?: () => void;
}

const Container = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  z-index: 10;
  ${(props) => (props.hidden ? 'display:none;' : null)};
`;

const Card = styled(CardBase).attrs({ disableElevation: true })`
  && {
    transform: translateY(45vh) translateX(calc((100vw - 200px) / 2));
    max-width: 200px;
    border-radius: 20px;
  }
`;

const CardContent = styled(CardContentBase)`
  && {
    text-align: center;
  }
`;

const Title = styled(TypographyBase)`
  && {
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
  }
`;

const Subtitle = styled(TypographyBase)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
`;

const CheckCircle = styled(CheckCircleBase)`
  && {
    color: ${colors.REBRAND_GREEN};
    width: 30px;
    height: 30px;
  }
`;

const CopiedBase: React.FC<React.PropsWithChildren<CopiedProps>> = (props) => {
  const { t, hidden, onDismiss } = props;

  return (
    <Container hidden={hidden} onClick={onDismiss}>
      <Card>
        <CardContent>
          <Title variant="h5">
            <CheckCircle />
          </Title>
          <Subtitle variant="body2">{t('copied')}</Subtitle>
        </CardContent>
      </Card>
    </Container>
  );
};

export const Copied = translate('directPayment')(CopiedBase);
