export enum ActionType {
  FetchProduct = 'PRODUCT_DETAIL_FETCH',
  SetProduct = 'PRODUCT_DETAIL_SET_PRODUCT',
  SetLoading = 'PRODUCT_DETAIL_SET_LOADING',
  SetIsProductUpdated = 'PRODUCT_DETAIL_SET_IS_PRODUCT_UPDATED',
  SetHasSimilarProducts = 'PRODUCT_DETAIL_SET_HAS_SIMILAR_PRODUCTS',
  SetModalToggle = ' PRODUCT_DETAIL_SET_MODAL',
  SetProductId = 'PRODUCT_DETAIL_PRODUCT_ID',
  SetPrevProductId = 'PRODUCT_DETAIL_PREV_PRODUCT_ID',
  ClearProductDetail = 'CLEAR_PRODUCT_DETAIL',
}
