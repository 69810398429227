import { store } from 'store';
import { calculateAmountAfterTax } from 'utils/Format';

export default class Tax {
  static get rate(): number {
    const { config } = store.getState();

    return config.paymentMethod?.monetary?.taxRate || 0;
  }

  static appliedTo(amount: number, rate: number = this.rate): number {
    return calculateAmountAfterTax(amount, rate);
  }
}
