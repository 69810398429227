import React from 'react';
import { translate } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { DialogBox } from 'components/common/DialogBox';
import { S } from './StockOutDialog.styles';

interface Props {
  t: any;
  isOpen: boolean;
  toggle: () => void;
}

const StockOutDialogBase: React.FC<React.PropsWithChildren<Props>> = ({ t, isOpen, toggle }) => (
  <DialogBox isOpen={isOpen}>
    <Grid container alignItems="center" direction="column">
      <S.Wrapper container alignItems="center" direction="column">
        <S.Title>{t('dialogTitle')}</S.Title>
        <S.Description>{t('dialogDescription')}</S.Description>
      </S.Wrapper>
      <S.Button onClick={toggle}>
        <S.BtnText>{t('okay')}</S.BtnText>
      </S.Button>
    </Grid>
  </DialogBox>
);

export const StockOutDialog = translate('stockOut')(StockOutDialogBase);
