import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import qs from 'query-string';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ProductDetailPageModal from 'components/pages/ProductDetailPage/ProductDetailPageModal';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';
import { ERROR_CODE } from 'components/pages/ProductListingPage/constants';
import { usePrevious } from 'hooks/usePrevious';
import { State as StoreState } from 'store';

import {
  clearListingPageReferer,
  clearProductListing,
  clearProductListingError,
} from 'store/reducers/ProductListing/actions';
import { BasePageTracker, IanalyticsDataLog, SearchDataAnalyticsProps, trackSearch } from 'utils/Analytics/Segment';

import { routeKey } from 'utils/route.utils';

import { fetchOrderedProductQuantityHistory } from 'store/reducers/Orders/actions';
import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { GenericProductListWithWidget } from './generic-product-list.component';
import { getMarketingFeaturesConfig } from './utils/get-marketing-features-config';

export interface ProductListingParam {
  query?: string;
  category_ids: string[];
  filters: string[];
  sorts: string[];
}

export interface ProductListingProps {
  t: any;
  analyticsDataLog?: IanalyticsDataLog;
  searchDataAnalyticsProps?: SearchDataAnalyticsProps;
  config?: {
    style?: any;
    defaultElementWhenEmpty?: JSX.Element;
    defaultElementWhenEnd?: JSX.Element;
    withSimilarWidget?: boolean;
    withMarketingFeatureWidget?: boolean;
    listingPage?: BasePageTracker;
  };
  productImpressionPage?: BasePageTracker;
  singleImpression?: boolean;
  searchStateSorting?: boolean;
  fetchMoreData: (caller: string) => void;
}

export const ProductListing: React.FC<React.PropsWithChildren<ProductListingProps>> = (props) => {
  const listingState = useSelector((state: StoreState) => state.productListing, shallowEqual);
  const toolbarState = useSelector((state: StoreState) => state.filterToolbar, shallowEqual);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [hasBeenCleared, setHasBeenCleared] = useState<boolean>(false);
  const history = useHistory();

  const { isModalOpen, query = '' } = qs.parse(history.location.search, BRACKET_QS_OPTIONS);

  const isForbiddenMarketingSegment = listingState.error === ERROR_CODE.FORBIDDEN_MARKETING_SEGMENTS_MISMATCH;

  const prevProductLength = usePrevious(listingState.products.length) || 0;

  const {
    t,
    analyticsDataLog,
    config,
    searchDataAnalyticsProps,
    singleImpression,
    productImpressionPage,
    searchStateSorting = true,
  } = props;
  const dispatch = useDispatch();

  const cleanupFunction = (): void => {
    dispatch(clearProductListing());
  };

  useEffect(() => {
    cleanupFunction();
    setHasBeenCleared(true);
    dispatch(fetchOrderedProductQuantityHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /*
      // TODO: SPT-12337: Fix Infinite Scroll does not auto refetch
      Manual Refetching below will happen for the following:
      1. Total Products is less than 6 despite of search state
      2. Last Response is empty
    */
    const totalResultsLessThan6 = listingState.products.length < 6;
    const lastResponseIsEmpty = listingState.products.length === prevProductLength;
    const InfiniteScrollLimit = lastResponseIsEmpty || totalResultsLessThan6;

    const isFirstLoad = listingState.nextPage === 1;
    const isLastLoad = !listingState.hasMore;
    const shouldFetchMoreData = InfiniteScrollLimit && !isFirstLoad && !isLastLoad;

    if (searchStateSorting && shouldFetchMoreData && !listingState.preventRefresh) {
      props.fetchMoreData('effect listing');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingState.products.length]);

  useEffect(() => {
    if (toolbarState.allowSearch) {
      props.fetchMoreData('effect toolbar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    toolbarState.selectedSorts,
    toolbarState.selectedFilters,
    // toolbarState.allowSearch,
    toolbarState.retrigger,
  ]);

  const onClose = (): void => {
    setOpenToast(false);
    dispatch(clearProductListingError());
  };

  useEffect(() => {
    if (!listingState.error) {
      return;
    }
    if (isForbiddenMarketingSegment) {
      history.push(routeKey('main_home'));
      return;
    }
    setOpenToast(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingState.error]);

  const hasProducts = listingState.products.length > 0;
  const isEmpty = !listingState.products.length && !listingState.hasMore && !listingState.loading;

  useEffect(() => {
    if (searchDataAnalyticsProps && (hasProducts || isEmpty) && hasBeenCleared && !isModalOpen) {
      if (listingState.listingPageReferer) {
        dispatch(clearListingPageReferer());
      } else {
        trackSearch(searchDataAnalyticsProps);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasProducts, isEmpty]);

  const featuresConfig = getMarketingFeaturesConfig(
    config?.listingPage || BasePageTracker.BLANK,
    listingState,
    cleanupFunction,
  );
  return (
    <>
      <GenericProductListWithWidget
        data={{
          products: listingState.products,
          limit: 20,
          nextPage: listingState.nextPage,
          loading: listingState.loading,
          hasMore: listingState.hasMore,
        }}
        emptyMessage={config?.defaultElementWhenEmpty || t('noProducts')}
        endMessage={config?.defaultElementWhenEnd || undefined}
        fetchProducts={props.fetchMoreData}
        analyticsDataLog={analyticsDataLog}
        style={config?.style}
        singleImpression={singleImpression}
        productImpressionPage={isModalOpen ? undefined : productImpressionPage}
        withSimilarWidget={config?.withSimilarWidget}
        withMarketingFeatureWidget={config?.withMarketingFeatureWidget}
        t={t}
        detailPageDisplayType={DetailPageDisplayType.MODAL}
        query={query as string}
        {...featuresConfig}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openToast}
        onClose={onClose}
        message={listingState.error}
        autoHideDuration={2000}
      />
      <ProductDetailPageModal />
    </>
  );
};
