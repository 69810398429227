import styled from 'styled-components';

export const Content = styled.div`
  max-width: 100vw;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px;
  margin-top: 9px;
  text-align: center;
`;
