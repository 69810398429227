import { Order, ListMeta, LoyaltyHistoryStatus, PurchaseOrderDocumentRequirement } from 'services';
import { LogisticsDeliveryStatus } from 'services/swipe-rx-pt/resources/logistics-delivery/types/logistics-delivery-status-history.type';

import { OrderActivityData } from './purchase-order.interface';

export enum PurchaseOrderActionTypes {
  SET_PURCHASE_ORDERS_LOADING = '@@purchase-order/SET_PURCHASE_ORDERS_LOADING',
  SET_PURCHASE_ORDER_LIST = '@@purchase-order/SET_PURCHASE_ORDER_LIST',
  SET_INITIAL_PURCHASE_ORDER_LIST = '@@purchase-order/SET_INITIAL_PURCHASE_ORDER_LIST',
  SET_PURCHASE_ORDER_ERROR = '@@purchase-order/SET_PURCHASE_ORDER_ERROR',
  SET_PURCHASE_ORDER_DETAILS = '@@purchase-order/SET_PURCHASE_ORDER_DETAILS',
  SET_PURCHASE_ORDER_ACTIVITY = '@@purchase-order/SET_PURCHASE_ORDER_ACTIVITY',
  RESET_PURCHASE_ORDER_DETAILS = '@@purchase-order/RESET_PURCHASE_ORDER_DETAILS',
  RESET_PURCHASE_ORDER_LIST = '@@purchase-order/RESET_PURCHASE_ORDER_LIST',
  UPDATE_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT = '@@purchase-order/UPDATE_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT',
  SET_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT = '@@purchase-order/SET_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT',
  SET_PURCHASE_ORDER_DOCUMENT_REQUIREMENT = '@@purchase-order/SET_PURCHASE_ORDER_DOCUMENT_REQUIREMENT',
  SET_PURCHASE_ORDER_LOGISTICS_DELIVERY = '@@purchase-order/SET_PURCHASE_ORDER_LOGISTICS_DELIVERY',
}

export interface SetPurchaseOrderLoading {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDERS_LOADING;
  payload: { loading: boolean };
}

export interface SetPuchaseOrderActivity {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ACTIVITY;
  payload: { data: OrderActivityData[]; unread: number };
}

export interface SetPurchaseOrderList {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_LIST;
  payload: { data: Order[]; meta: ListMeta; isInitial: boolean };
}

export interface SetInitialPurchaseOrderList {
  type: PurchaseOrderActionTypes.SET_INITIAL_PURCHASE_ORDER_LIST;
  payload: { data: Order[]; meta: ListMeta };
}

export interface SetPurchaseOrderError {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ERROR;
  payload: { error: string };
}

export interface SetPurchaseOrderDetails {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_DETAILS;
  payload: { data: Order; loyaltyStatus?: LoyaltyHistoryStatus };
}

export interface ResetPurchaseOrderDetails {
  type: PurchaseOrderActionTypes.RESET_PURCHASE_ORDER_DETAILS;
}

export interface UpdatePuchaseOrderActivityUnreadCount {
  type: PurchaseOrderActionTypes.UPDATE_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT;
}

export interface SetPuchaseOrderActivityUnreadCount {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT;
  payload: { unread: number };
}

export interface SetPuchaseOrderDocumentReqirement {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_DOCUMENT_REQUIREMENT;
  payload: PurchaseOrderDocumentRequirement[];
}

export interface ResetPurchaseOrderList {
  type: PurchaseOrderActionTypes.RESET_PURCHASE_ORDER_LIST;
}

export interface SetPuchaseOrderDeliveries {
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_LOGISTICS_DELIVERY;
  payload: LogisticsDeliveryStatus[];
}

export type PurchaseOrderActions =
  | SetPurchaseOrderLoading
  | SetPurchaseOrderList
  | SetInitialPurchaseOrderList
  | SetPurchaseOrderError
  | SetPurchaseOrderDetails
  | ResetPurchaseOrderDetails
  | ResetPurchaseOrderList
  | SetPuchaseOrderActivity
  | UpdatePuchaseOrderActivityUnreadCount
  | SetPuchaseOrderActivityUnreadCount
  | SetPuchaseOrderDocumentReqirement
  | SetPuchaseOrderDeliveries;
