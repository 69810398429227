import React from 'react';
import { Button, Drawer, IconButton, withStyles } from '@material-ui/core';
import { CheckCircleRounded, LockOutlined, RadioButtonUncheckedRounded } from '@material-ui/icons';
import * as colors from 'components/styles/colors';
import styled from 'styled-components';
import { breakpoints } from 'components/styles/breakpoints';
import { TierButtonProps } from './TierPricingSheet.interface';

export const TierPricingDrawer = withStyles({
  paper: {
    maxHeight: '45vh',
    borderRadius: '40px 40px 0 0',
    padding: '16px 30px',
  },
})(Drawer);

export const CloseButton = withStyles({
  root: {
    background: 'rgba(0, 0, 0, 0.1)',
    position: 'relative',
    top: 4,
  },
})(IconButton);

export const BuyButton = withStyles({
  root: {
    height: 48,
    minHeight: 48,
    color: colors.WHITE,
  },
})(Button);

export const LockBuyButton = styled(Button).attrs({
  fullWidth: false,
  startIcon: <LockOutlined />,
})`
  && {
    font-weight: 800;
    color: #fff;
    background: #c5cad5;
    text-transform: capitalize;
    font-size: 12px;
    height: 48px;
    min-height: 48px;
    text-align: center;

    :focus {
      background: #c5cad5;
    }
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 11px;
      padding: 1px 20px;
    }
  }
`;

export const TierContainer = styled.div`
  display: contents;
`;

export const TierButton = styled(Button).attrs(({ isActive = false }: TierButtonProps) => ({
  variant: 'outlined',
  color: isActive ? 'primary' : 'inherit',
  startIcon: isActive ? <CheckCircleRounded /> : <RadioButtonUncheckedRounded />,
}))<TierButtonProps>`
  && {
    height: 48px;
    min-height: 48px;
    border-color: ${({ isActive }) => (isActive ? 'inherit' : '#DADADA')};
    justify-content: flex-start;
    text-align: left;
    text-transform: none;
    margin-bottom: 12px;

    &:first-child {
      margin-top: 12px;
    }
  }
`;
