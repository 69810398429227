import { ThunkActionCreator } from 'types/thunk';
import { recordException } from 'utils/Reporting';
import { State } from 'store';

import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';
import { v3Client } from 'utils/Client/v3';
import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { randomizeWidgets } from 'components/common/Widget/widget.util';
import { Actions } from './interface';
import * as constants from './constants';

export interface GetMarketingFeaturesParams {
  featureType: MarketingFeaturesType;
  fields?: string[];
  includesSimilarWidget?: boolean;
  overridePageSize?: number;
  order_by?: string;
}

const expiredTime = Number(process.env.REACT_APP_MARKETING_FEATURES_TTL_IN_SECOND || 60) * 1000;

const maxPageSizeMapping = {
  [MarketingFeaturesType.GENERAL]: process.env.REACT_APP_MARKETING_GENEREAL_SEARCH_SUGGESTION_MAX,
  [MarketingFeaturesType.CART]: process.env.REACT_APP_MARKETING_CART_SUGGESTION_MAX,
  [MarketingFeaturesType.TRACK_ORDER]: process.env.REACT_APP_MARKETING_TRACK_ORDER_SUGGESTION_MAX,
  [MarketingFeaturesType.ORDER_CONFIRMATION]: process.env.REACT_APP_MARKETING_ORDER_CONFIRMATION_SUGGESTION_MAX,
  [MarketingFeaturesType.FAVORITES]: process.env.REACT_APP_MARKETING_FAVORITES_SUGGESTION_MAX,
  [MarketingFeaturesType.REMINDERS]: process.env.REACT_APP_MARKETING_REMINDERS_SUGGESTION_MAX,
};

const globalDefaultLimit = 10;
const RANDOM = 'random';

const transformEnvValue = (value?: string | number): number => {
  if (typeof value === 'number' && value >= 0) {
    return value;
  }

  if (typeof value === 'string') {
    const parsedValue = Number.parseFloat(value.trim());
    if (parsedValue >= 0) {
      return parsedValue;
    }
  }

  return NaN;
};
const getPageHandler = (featureType: MarketingFeaturesType, overridePageSize?: number): number => {
  const pageHandlerString = maxPageSizeMapping[featureType];

  let pageHandler = transformEnvValue(pageHandlerString);

  if (overridePageSize) {
    pageHandler = overridePageSize;
  } else if (Number.isNaN(pageHandler)) {
    pageHandler = globalDefaultLimit;
  }

  return pageHandler;
};

export const setMarketingFeaturesLoading: ThunkActionCreator<Actions, State> =
  (payload: { featureType: MarketingFeaturesType; loading: boolean }) => async (dispatch) => {
    dispatch({
      type: constants.SET_MARKETING_FEATURES_LOADING,
      loading: payload.loading,
      featureType: payload.featureType,
    });
  };

export const getMarketingFeatures: ThunkActionCreator<Actions, State> =
  (payload: GetMarketingFeaturesParams) => async (dispatch, getState) => {
    const {
      featureType = MarketingFeaturesType.GENERAL,
      fields = ['id', 'name', 'type', 'config', 'position'],
      includesSimilarWidget = false,
      overridePageSize,
      order_by = RANDOM,
    } = payload;

    const pageHandler = getPageHandler(featureType, overridePageSize);

    if (pageHandler === 0) {
      return;
    }

    const currentData = getState().marketingFeatures?.[featureType] || {};
    const expiration = currentData?.expired ?? 0;
    const now = Date.now();

    try {
      if (currentData?.data && expiration > now) {
        if (order_by === RANDOM) {
          const randomized = randomizeWidgets(currentData.data);
          dispatch({
            type: constants.GET_MARKETING_FEATURES,
            data: randomized,
            expired: expiration,
            featureType,
          });
        }
        return;
      }
      dispatch(setMarketingFeaturesLoading({ featureType, loading: true }));

      const data =
        (await v3Client.get('marketing-features', {
          is_published: true,
          fields,
          page: 1,
          page_size: pageHandler,
          order_by,
          feature_types: [featureType],
        })) || [];

      const marketingFeatures = !includesSimilarWidget
        ? data?.data.filter(
            (feature) => feature?.marketing && feature?.marketing.type !== MarketingType.SIMILAR_PRODUCT,
          )
        : data?.data;

      dispatch({
        type: constants.GET_MARKETING_FEATURES,
        data: marketingFeatures,
        expired: now + expiredTime,
        featureType,
      });
    } catch (error) {
      recordException(error as Error, 'searchQuery', { payload });
      const errMsg = (error as Error).message;
      // eslint-disable-next-line no-console
      console.error(errMsg);
    } finally {
      dispatch(setMarketingFeaturesLoading({ featureType, loading: false }));
    }
  };
