import React, { useState } from 'react';
import { Drawer, withStyles } from '@material-ui/core';
import { DrawerProps } from '@material-ui/core/Drawer';
import DayPicker, { DateUtils, RangeModifier, Modifiers } from 'react-day-picker';
import { S } from './orders-calendar-modal.styles';
import { DateCard } from './components';

import 'react-day-picker/lib/style.css';
import './orders-calendar-modal.css';

interface Props extends DrawerProps {
  open: boolean;
  initialValue: RangeModifier;
  onClose: () => void;
  onSave: (range: RangeModifier) => void;
}

const styles = {
  paper: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
};

const OrdersCalendarModalBase: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  classes,
  initialValue,
  onSave,
}) => {
  const today: Date = new Date();

  const [range, setRange] = useState<RangeModifier>(initialValue);

  const { from, to } = range;
  const modifiers: Partial<Modifiers> = { start: from || undefined, end: to || undefined };

  const handleDayClick = (pickedDay: Date): void => {
    const newDay = pickedDay > today ? today : pickedDay;
    const newRange = DateUtils.addDayToRange(newDay, range);

    setRange(newRange);
  };

  const clearForm = (property): void => {
    const newRange: RangeModifier = {
      ...range,
      [property]: undefined,
    };

    setRange(newRange);
  };

  const handleFromFormClick = (): void => clearForm('from');

  const handleToFormClick = (): void => clearForm('to');

  const handleCancelClick = (): void => {
    onClose();
  };

  const handleSaveClick = (): void => {
    if (range) {
      onSave(range);
    }

    onClose();
  };

  return (
    <Drawer anchor="bottom" classes={classes} onClose={onClose} open={open}>
      <S.Title>Select Date</S.Title>
      <DayPicker
        className="Selectable"
        modifiers={modifiers}
        showOutsideDays
        disabledDays={{ after: today }}
        selectedDays={[range]}
        onDayClick={handleDayClick}
        fixedWeeks
      />
      <S.Container>
        <DateCard title="from" date={from || null} onClick={handleFromFormClick} />
        <DateCard title="to" date={to || null} onClick={handleToFormClick} />
      </S.Container>
      <S.ControlsContainer>
        <S.CancelButton onClick={handleCancelClick} data-testid="btn-cancel-calendar-filter">
          Cancel
        </S.CancelButton>
        <S.SaveButton onClick={handleSaveClick} disabled={!(from && to)} data-testid="btn-save-calendar-filter">
          Save
        </S.SaveButton>
      </S.ControlsContainer>
    </Drawer>
  );
};

export const OrdersCalendarModal = withStyles(styles)(OrdersCalendarModalBase);
