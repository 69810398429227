import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { SnackbarComponent } from 'components/common/snackbar';
import { State as utilsState } from 'store/reducers/Utils';
import { getSwipeRxToken, storeSwipeRxToken } from 'utils/Client/swipeRxToken.util';
import { init as causalFoundryInit, identifyUser as causalFoundryIdentifyUser } from 'utils/Analytics/CausalFoundry';
import { identifyUser, pageTracker } from 'utils/Analytics/Segment';
import { RenderRoutes, routeKey } from 'utils/route.utils';
import { applyMarketConfig } from 'utils/MarketConfig';
import { Locale, Language, setLocale, setDefaultConfig } from 'utils/Localization';

import { PtLocationState } from 'routes';
import { StaticContext } from 'react-router';
import { browserBackStackListener } from 'utils/listeners/browserBackStackListener';
import { getPriorityLoadWidgetCount } from 'components/common/Widget/widget.util';
import ErrorBoundary from '../common/ErrorBoundary';
import Notification from '../common/Notification';
import ErrorPage from '../pages/ErrorPage';
import MaintenancePage from '../pages/MaintenancePage';
import * as colors from '../styles/colors';
import Layout from '../views/Layout';
import ShowIf from '../views/ShowIf';

/**
 * Typings
 */
export interface StateProps {
  authenticated: boolean;
  activated: string | null;
  authError?: string;
  fetchError?: string;
  globalError?: string;
  globalSuccess?: string;
  coreUser?: any;
  organization_id?: number;
  utils?: utilsState;
  menuDrawerOpen: boolean;
  isPriorityWidgetsFinishLoad: boolean;
}

export interface DispatchProps {
  authentication: (sessionToken: string) => any;
  getCart: (payload?: { flag?: string }) => any;
  fetchDPDBlockStatus: () => any;
  fetchCredit: () => any;
  clearCreditError: () => any;
  hideError: () => any;
  hideSuccess: () => any;
  startAppLoading: () => void;
  endAppLoading: () => void;
  setMarketId: (marketId: string) => void;
  fetchMarketConfig: () => void;
  fetchOrganizationPaymentMethod: () => void;
  fetchAllCategories: () => void;
  fetchSpecialMarketing: () => void;
  fetchRecentlySearchedProducts: () => any;
  setMarketLanguage: (marketLanguage: Language) => void;
  setMarketLocale: (marketLocale: Locale) => void;
  setCloseSnackbar: () => void;
  fetchOrderedProductQuantityHistory: () => void;
  fetchRecentlyViewedSectionProducts: () => void;
}

export interface OwnProps extends RouteComponentProps<any, StaticContext, PtLocationState> {}

export interface State {
  retries: number;
  identifiedUser: boolean;
  isPasswordChange: boolean;
  showErrorDialog: boolean;
  hasStartPrefetch: boolean;
}
type Props = StateProps &
  DispatchProps &
  OwnProps & {
    isOnMaintenance: boolean;
  };

class App extends React.Component<Props, State> {
  state: State = {
    retries: 0,
    identifiedUser: false,
    isPasswordChange: false,
    showErrorDialog: false,
    hasStartPrefetch: false,
  };

  unlisten!: () => void;

  constructor(props: Props) {
    super(props);
    storeSwipeRxToken(props);
  }

  async componentDidMount(): Promise<void> {
    const { setMarketId, fetchMarketConfig, startAppLoading, location, isOnMaintenance } = this.props;
    const pathName = location.pathname.split('/')[2];

    if (isOnMaintenance) return;

    setMarketId(this.marketId);
    setDefaultConfig(this.marketId);

    try {
      await fetchMarketConfig();
      await startAppLoading();
      applyMarketConfig();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Market config for ${this.marketId} failed to load. Loading default`, err);
      setDefaultConfig(this.marketId);
      this.setState({ showErrorDialog: true });
      return;
    }

    startAppLoading();

    // by pass if pathname consists these strings
    if (pathName !== 'password-confirmation' && pathName !== 'how-to-download-sp-document') {
      const {
        // authenticateSession,
        authentication,
        getCart,
      } = this.props;

      startAppLoading();

      if (this.isMaintenancePage && this.marketId) {
        setLocale(this.marketId as Locale);
      }

      const swiperxToken = getSwipeRxToken();
      if (!swiperxToken) {
        return;
      }

      authentication(swiperxToken).then((activated: boolean) => {
        if (!activated) {
          return;
        }
        getCart({ flag: 'includeAvailabilityCheck' });
        if (this.isZeroPriorityWidget) {
          this.prefetch();
        }
        // setupChat();
      });
    } else {
      this.setState({
        isPasswordChange: true,
      });
    }
    this.unlisten = browserBackStackListener(this.props.history);
  }

  componentDidUpdate(_prevProps: Props, prevState: State): void {
    const { hasStartPrefetch } = this.state;
    const { isPriorityWidgetsFinishLoad } = this.props;
    if (!this.isZeroPriorityWidget) {
      if (isPriorityWidgetsFinishLoad && !prevState.hasStartPrefetch && !hasStartPrefetch) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          hasStartPrefetch: true,
        });
        this.prefetch();
      }
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  get queryString(): string {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('guestLandingUrl')) {
      queryParams.delete('guestLandingUrl');
    }

    return queryParams.toString();
  }

  get marketId(): string {
    const { location } = this.props;
    const path = location.pathname.split('/');
    return path.length > 1 ? path[1] : '';
  }

  get transitionKey(): string {
    const { location } = this.props;
    const path = location.pathname.split('/');
    return path.length > 1 ? path[1] : '';
  }

  get isHomePage(): boolean {
    const {
      location: { pathname },
      authenticated,
      activated,
    } = this.props;
    const isLanding = `/${this.marketId}/` === pathname || `/${this.marketId}` === pathname;

    return isLanding && authenticated && !!activated;
  }

  get isMaintenancePage(): boolean {
    const {
      location: { pathname },
    } = this.props;

    return pathname.includes('/maintenance');
  }

  get shouldShowActivation(): boolean {
    const { authenticated, activated } = this.props;
    return authenticated && !activated;
  }

  get shouldShowContent(): boolean {
    const { authenticated } = this.props;
    return authenticated;
  }

  get shouldShowAuthError(): boolean {
    const { authError } = this.props;
    return typeof authError === 'string';
  }

  get shouldShowNetworkError(): boolean {
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.showErrorDialog;
  }

  get shouldRetry(): boolean {
    const { retries } = this.state;
    return retries < 3;
  }

  get isLoading(): boolean {
    return !this.shouldShowContent && !this.shouldShowAuthError;
  }

  get notifError(): string | undefined {
    const { fetchError } = this.props;
    if (typeof fetchError === 'string') {
      if (this.shouldRetry) {
        return fetchError;
      }
      return 'Sorry, we could not resolve the problem. Please try again later.';
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  get isZeroPriorityWidget(): boolean {
    return getPriorityLoadWidgetCount() === 0;
  }

  endAppLoading = (): void => {
    const { endAppLoading } = this.props;
    endAppLoading();
  };

  increaseRetries = (): void => {
    this.setState((state) => ({ retries: state.retries + 1 }));
  };

  retryNotification = (): void => {
    const { increaseRetries } = this;
    const { clearCreditError, fetchCredit } = this.props;

    // Clear credit error then retry
    clearCreditError().then(fetchCredit).then(increaseRetries);
  };

  closeNotification = (): void => {
    const { clearCreditError } = this.props;

    // Clear credit error
    clearCreditError();
  };

  closeErrorNotification = (): void => {
    const { hideError } = this.props;
    hideError();
  };

  closeSuccessNotification = (): void => {
    const { hideSuccess } = this.props;
    hideSuccess();
  };

  goTo(path: string): () => void {
    const { history } = this.props;

    return () => history.push(`${path}/${this.queryString}`);
  }

  prefetch(): void {
    const {
      fetchCredit,
      fetchDPDBlockStatus,
      fetchOrganizationPaymentMethod,
      fetchRecentlySearchedProducts,
      fetchAllCategories,
      fetchSpecialMarketing,
      fetchOrderedProductQuantityHistory,
      fetchRecentlyViewedSectionProducts,
    } = this.props;

    fetchRecentlySearchedProducts()
      .then(fetchSpecialMarketing)
      .then(() => {
        if (!pageTracker.isHomepage()) {
          fetchOrderedProductQuantityHistory();
        }
      })
      .then(fetchAllCategories)
      .then(fetchRecentlyViewedSectionProducts)
      .then(fetchOrganizationPaymentMethod)
      .then(fetchCredit)
      .then(fetchDPDBlockStatus)
      .then(this.endAppLoading);
  }

  render(): JSX.Element {
    const { identifiedUser, isPasswordChange } = this.state;
    const {
      history,
      authError,
      location,
      globalError,
      globalSuccess,
      coreUser,
      match,
      utils,
      setCloseSnackbar,
      menuDrawerOpen,
    } = this.props;

    browserBackStackListener(history);

    // subject for refactor
    if (coreUser && Object.keys(coreUser).length) {
      if (!identifiedUser) {
        identifyUser(coreUser);

        const causalFoundryToken = process.env.REACT_APP_CAUSAL_FOUNDRY_TOKEN as string;
        if (causalFoundryToken) {
          causalFoundryInit(causalFoundryToken, history, coreUser);
          causalFoundryIdentifyUser(coreUser, this.marketId);
        }

        this.setState({
          identifiedUser: true,
        });
      }

      if (!menuDrawerOpen) {
        pageTracker.trackPageView(location, coreUser);
      }
    }

    return (
      <Layout full background={colors.TRANSPARENT}>
        <ShowIf condition={this.shouldShowNetworkError}>
          <ErrorPage message="Network Problem" />
        </ShowIf>
        <ShowIf condition={this.isMaintenancePage}>
          <MaintenancePage />
        </ShowIf>
        <ShowIf condition={!this.isMaintenancePage && !this.shouldShowNetworkError}>
          <ShowIf condition={isPasswordChange}>
            <RenderRoutes routeKey="public" match={match} />
          </ShowIf>
          <ShowIf condition={this.isHomePage}>
            <Redirect to={`${routeKey('main_home')}/${this.queryString}`} />
          </ShowIf>
          <ShowIf condition={this.shouldShowActivation}>
            <Redirect to={`${routeKey('activation')}/${this.queryString}`} />
          </ShowIf>
          <ShowIf condition={this.shouldShowContent}>
            <TransitionGroup>
              <CSSTransition key={this.transitionKey} classNames="fade" timeout={200}>
                <ErrorBoundary>
                  <RenderRoutes routeKey="landing" match={match} />
                  <Notification message={globalError} variant="red-error" onClose={this.closeErrorNotification} />
                  <Notification message={globalSuccess} variant="success" onClose={this.closeSuccessNotification} />
                </ErrorBoundary>
              </CSSTransition>
            </TransitionGroup>
            <Notification
              variant="error"
              message={this.notifError}
              onRetry={this.shouldRetry ? this.retryNotification : undefined}
              onClose={!this.shouldRetry ? this.closeNotification : undefined}
            />
          </ShowIf>
          <ShowIf condition={this.shouldShowAuthError}>
            <ErrorPage message={authError} />
          </ShowIf>
        </ShowIf>

        {utils?.snackbar.open && (
          <SnackbarComponent
            open={!!utils?.snackbar.open}
            contentProps={utils?.snackbar.contentProps}
            onClose={setCloseSnackbar}
            message={utils?.snackbar.message || ''}
            params={utils?.snackbar.params}
            titleAction={utils?.snackbar.titleAction}
            onActionPath={utils?.snackbar.actionPath}
          />
        )}
      </Layout>
    );
  }
}

export default App;
