import React from 'react';
import { useDispatch } from 'react-redux';
import { LoyaltyProductEarnPoint } from 'store/reducers/loyalty-program';
import { ReactComponent as ProductCoinIcon } from 'images/ic_coin.svg';
import { ReactComponent as ProductCoinRewardIcon } from 'images/ic_coin_reward.svg';
import { showDialog } from 'store/reducers/LoyaltyPointDescription/actions';
import { S } from './ProductCardLoyaltyPoint.styles';

interface Props {
  point?: LoyaltyProductEarnPoint;
  position?: {
    left: string | number;
    bottom: string | number;
  };
}

export const ProductCardLoyaltyPoint: React.FC<React.PropsWithChildren<Props>> = ({
  point,
  position = {
    left: 0,
    bottom: 0,
  },
}) => {
  const dispatch = useDispatch();

  if (!point || point.points === 0) return null;

  const { points, rewards } = point;

  const hasBonusRewards = rewards.length > 0;

  const handleShowDialog = (): void => {
    dispatch(showDialog());
  };

  return (
    <S.LoyaltyPointRibbon
      container
      alignItems="flex-end"
      multiple={hasBonusRewards}
      position={position}
      onClick={handleShowDialog}
    >
      <S.LoyaltyPointText>{points}</S.LoyaltyPointText>
      {hasBonusRewards ? <ProductCoinRewardIcon /> : <ProductCoinIcon />}
    </S.LoyaltyPointRibbon>
  );
};
