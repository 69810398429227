import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface Props {
  color: string;
  dark: boolean;
  end: boolean;
  expanded: boolean;
  gutter: boolean;
  hasSearch: boolean;
  noBorderRadius: boolean;
}

const headerCSS = css`
  font-style: normal;
  font-weight: 800;
`;

const Wrapper = styled.div<any>`
  transition: z-index 0.5s ease;

  &.section-card {
    background: ${({ color }: Props) => color};
    border-top-left-radius: ${({ noBorderRadius }: Props) => (noBorderRadius ? undefined : '40px')};
    margin-bottom: -40px;
    padding: 10px 40px ${({ end }: Props) => (end ? 20 : 60)}px 40px;
    z-index: 1;
  }

  &.expanded {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    padding-bottom: ${({ hasSearch }: Props) => (hasSearch ? 115 : 70)}px;
  }

  h1 {
    color: ${({ dark }: Props) => (dark ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)')};
  }

  h2 {
    color: ${({ dark }: Props) => (dark ? 'rgba(0, 0, 0, 1)' : '#fff')};
  }

  button.see-more {
    background: ${({ color }: Props) => shade(0.1, color)} !important;
    color: ${({ dark }: Props) => (dark ? '#818181' : 'rgba(255, 255, 255, 0.7)')} !important;
  }
`;

const Title = styled.h1<any>`
  ${headerCSS}
  font-size: 12px;
  text-transform: uppercase;
  margin: ${({ gutter }: Props) => (gutter ? undefined : 0)};
`;

const Description = styled.h2`
  ${headerCSS}
  font-size: 16px;
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 10px;
  word-wrap: break-word;
  /* width: 80%; */
`;

const Content = styled.div<any>`
  border-bottom: ${({ dark, end }: Props) =>
    !end ? `1px solid ${dark ? 'rgba(12, 12, 12, 0.06)' : '#fff'}` : undefined};
  margin-bottom: ${({ end }: Props) => (!end ? '20px' : 'initial')};
  margin-top: ${({ expanded }: Props) => (expanded ? '50px' : 'initial')};
  padding-bottom: ${({ end }: Props) => (!end ? '25px' : 'initial')};
`;

export const S = {
  Wrapper,
  Title,
  Description,
  Content,
};
