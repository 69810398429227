import React from 'react';
import { Grid } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';

import { InfiniteLoader } from 'components/common';
import { ChatBubble } from 'components/pages/MainPage/components/chat-bubble';
import * as colors from 'components/styles/colors';
import { trackDownloadInvoiceSummary } from 'utils/Analytics/Segment';

import ShowIf from 'components/views/ShowIf';
import { S } from './header-content.styles';

interface Props extends GridProps {
  goBack?: () => void;
  title: string;
  color?: string;
  showContent?: boolean;
  invoiceSummaryDownload?: {
    downloadLabel: string;
    useDownloadIcon?: boolean;
    downloadIconColor?: string;
    chatBubbleIconColor?: string;
    isDownloading: boolean;
    onInvoiceSummaryDownload: () => any;
  };
  styles?: {
    title?: Record<string, string>;
  };
}

export const HeaderContent: React.FC<React.PropsWithChildren<Props>> = ({
  goBack,
  title,
  color,
  showContent = true,
  children,
  invoiceSummaryDownload,
  styles,
}) => {
  const {
    onInvoiceSummaryDownload,
    downloadLabel,
    useDownloadIcon,
    downloadIconColor,
    isDownloading,
    chatBubbleIconColor,
  } = invoiceSummaryDownload || {
    onInvoiceSummaryDownload: null,
    downloadLabel: null,
    useDownloadIcon: false,
    isDownloading: null,
  };

  const handleInvoiceDownloadClick = (): void => {
    trackDownloadInvoiceSummary();
    if (onInvoiceSummaryDownload) {
      onInvoiceSummaryDownload();
    }
  };

  const hasEndContents: boolean = showContent || !!onInvoiceSummaryDownload;
  const endGridContainer: JSX.Element = (
    <Grid container justifyContent="flex-end" alignItems="center">
      {onInvoiceSummaryDownload ? (
        isDownloading ? (
          <InfiniteLoader />
        ) : (
          <S.DownloadSummaryLink
            data-testid="header-download-invoice-summary-button"
            onClick={handleInvoiceDownloadClick}
          >
            <ShowIf condition={!!useDownloadIcon}>
              <S.Receipt iconColor={downloadIconColor} />
            </ShowIf>
            <ShowIf condition={!useDownloadIcon}>{downloadLabel}</ShowIf>
          </S.DownloadSummaryLink>
        )
      ) : null}
      <ShowIf condition={showContent}>
        {children || <ChatBubble color={chatBubbleIconColor || colors.BLUISH_GRAY} />}
      </ShowIf>
    </Grid>
  );

  return (
    <Grid container alignItems="center">
      <Grid container>
        {!!goBack && (
          <S.IconButton onClick={goBack} data-testid="btn-back-header-content">
            <S.ArrowBackIcon iconColor={color} />
          </S.IconButton>
        )}
        <S.Title style={styles?.title}>{title}</S.Title>
      </Grid>
      {hasEndContents && endGridContainer}
    </Grid>
  );
};

HeaderContent.defaultProps = {
  goBack: undefined,
  color: undefined,
  showContent: false,
  invoiceSummaryDownload: undefined,
  styles: undefined,
};
