import * as Redux from 'redux';
import { Product } from '../Product';

import * as constants from './constants';
import { Actions } from './interface';

import { State as FilterToolbarState } from '../FilterToolbar';

export interface ProductListingRequest {
  caller: string;
  url: string;
  page: number;
  limit: number;
  query?: string;
  search?: number;
  showInStock?: boolean;
  showStockout?: boolean;
  showUnpurchaseable?: boolean;
  showPurchaseable?: boolean;
  marketingId: number;
  organization_id: number;
  params: any;
  segmentationChecking?: boolean;
  disableSearchState?: boolean;
  hasSearchEnded?: boolean;
  searchStateSorting?: boolean;
}

export interface State {
  products: Product[];
  totalProducts: number;
  loading: boolean;
  previousQuery: string;
  previousMarketingId: number;
  hasMore: boolean;
  nextPage: number;
  hasSearchEnded?: boolean;
  limit: number;
  respondTime: number;
  respondAt: Date | null;
  error?: string;
  totalCount?: number | undefined;
  listingPageReferer?: string;
  previousCategories?: string;
  previousFilters: FilterToolbarState['selectedFilters'];
  previousSorts: FilterToolbarState['selectedSorts'];
  preventRefresh?: boolean;
  isApproximate?: boolean;
  sku_code?: string;
}

const InitialState: State = {
  products: [],
  totalProducts: 0,
  loading: true,
  previousQuery: '',
  previousMarketingId: 0,
  hasMore: false,
  nextPage: 1,
  limit: 20,
  respondTime: 0,
  respondAt: null,
  totalCount: 0,
  listingPageReferer: '',
  previousCategories: '',
  previousFilters: [],
  previousSorts: [],
  preventRefresh: false,
  isApproximate: false,
  sku_code: '',
};

const ProductListingReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_PRODUCTS:
      return {
        ...state,
        loading: action.loading,
        nextPage: action.nextPage,
        previousQuery: action.previousQuery,
        previousMarketingId: action.previousMarketingId,
        products: action.products,
        previousFilters: action.filters,
        previousSorts: action.sorts,
        totalProducts: action.totalProducts,
        hasMore: action.hasMore,
        totalCount: action.totalCount,
        hasSearchEnded: action.hasSearchEnded,
        previousCategories: action.previousCategories,
        isApproximate: action.isApproximate,
        sku_code: action.sku_code,
      };
    case constants.CLEAR_PRODUCTS:
      // Why we clear previousQuery? -> This clear action is triggered upon searching/select filters
      return {
        ...state,
        ...InitialState,
        listingPageReferer: state.listingPageReferer,
        previousCategories: '',
        preventRefresh: action.preventRefresh,
      };
    case constants.START_SEARCH:
      return {
        ...state,
        loading: true,
      };
    case constants.END_SEARCH:
      return {
        ...state,
        loading: false,
      };
    case constants.FAIL_GET_PRODUCTS:
      return {
        ...state,
        error: action.error,
      };
    case constants.CLEAR_PRODUCT_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case constants.SET_LISTING_PAGE_REFERER:
      return {
        ...state,
        listingPageReferer: action.referer,
      };
    case constants.CLEAR_LISTING_PAGE_REFERER:
      return {
        ...state,
        listingPageReferer: InitialState.listingPageReferer,
      };
    default:
      return state;
  }
};

export default ProductListingReducer;
