import * as Redux from 'redux';

import { PharmacyProduct } from '../FavoriteProduct';
import * as constants from './constants';
import { Actions } from './actions';

export interface IDelayedInfoProducts {
  productId: number;
  availabilityRequestId: number;
  distributorId: number;
}
export interface State {
  products: PharmacyProduct[];
  delayedAvailabilityReq: IDelayedInfoProducts[];
  delayedDeleteAvailabilityReq: IDelayedInfoProducts[];
  limit: number;
  nextPage: number;
  loading: boolean;
  hasMore: boolean;
  initialProductLength: number | null;
}

const InitialState: State = {
  products: [],
  delayedAvailabilityReq: [],
  delayedDeleteAvailabilityReq: [],
  limit: 20,
  nextPage: 0,
  loading: true,
  hasMore: false,
  initialProductLength: null,
};

const ProductReminderReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SET_PRODUCT_REMINDERS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };

    case constants.CLEAR_PRODUCT_REMINDERS:
      return {
        ...state,
        ...InitialState,
        delayedAvailabilityReq: state.delayedAvailabilityReq,
        delayedDeleteAvailabilityReq: state.delayedDeleteAvailabilityReq,
      };
    // case constants.REMOVE_FROM_FAVORITE_PRODUCT:
    //   return {
    //     ...state,
    //     products: action.products,
    //   };

    case constants.SET_DELAYED_AVAILABILITY_REQUEST:
      return {
        ...state,
        delayedAvailabilityReq: action.delayedAvailabilityReq,
      };

    case constants.SET_DELAYED_DELETE_AVAILABILITY_REQUEST:
      return {
        ...state,
        delayedDeleteAvailabilityReq: action.delayedDeleteAvailabilityReq,
      };
    case constants.CLEAR_DELAYED_AVAILABILITY_REQUEST:
      return {
        ...state,
        delayedAvailabilityReq: [],
        delayedDeleteAvailabilityReq: [],
      };

    default:
      return state;
  }
};

export default ProductReminderReducer;
