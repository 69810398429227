import React from 'react';
import { IconButton } from '@material-ui/core';
import { routeKey } from 'utils/route.utils';
import { S } from './basket.styles';

interface Props {
  onClick: (x: string) => void;
  isEmpty: boolean;
  cartCount: number | undefined;
}

export const BasketIcon: React.FC<React.PropsWithChildren<Props>> = ({ onClick, isEmpty, cartCount }) => (
  <IconButton
    onClick={() => onClick(routeKey('prices'))}
    style={{
      padding: 0,
      marginTop: !isEmpty ? 0 : -3,
    }}
    id="tour__basket"
    data-testid="button-basket"
  >
    <S.Container>
      {!isEmpty ? (
        <S.BasketIcon />
      ) : (
        <S.Badge badgeContent={cartCount} color="primary">
          <S.BasketIcon style={{ marginTop: -3 }} />
        </S.Badge>
      )}
    </S.Container>
  </IconButton>
);
