import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Wrapper = styled(Button)`
  border-radius: 30px !important;
  border: 0;
  font-weight: 800 !important;
  height: 48px;
  padding: 0 30px;
  margin-top: 12px !important;
  width: 100%;
`;

export const S = { Wrapper };
