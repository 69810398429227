import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG } from 'services';
import styled from 'styled-components';
import { getMarketProductRequirements } from 'utils/MarketConfig';
import PKSIcon from 'images/prekursor-icon.svg';

export interface DocumentIconProps {
  flag: string;
}

export const DocumentIcon = styled.img.attrs((props: DocumentIconProps) => {
  let icon: React.ReactNode = null;
  let style: Record<string, any> = {};
  const productRequirements = getMarketProductRequirements();

  if (productRequirements.includes(DOCUMENT_REQUIREMENT_PREKURSOR_FLAG)) {
    if (props.flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG) {
      icon = PKSIcon;
      style = { ...style, width: '20px', height: '23px' };
    }
  } else {
    style = { ...style, display: 'none' };
  }

  return { src: icon, style };
})<DocumentIconProps>`
  bottom: 10px;
  position: absolute;
  left: 10px;

  &&.doc-middle {
    bottom: 35px;
  }
`;
