import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const SectionRowGrid = styled(Grid)`
  padding: 0.3rem 0.7rem 0.5rem 0.7rem;
  border-bottom: 1px solid #9292921a;
`;

const ItemNameGrid = styled(Grid)`
  overflow-wrap: break-word;
  font-weight: 400;
  cursor: pointer;
  color: #696969;
`;

const ItemDeleteGrid = styled(Grid)`
  align-self: center;
  cursor: pointer;
  text-align: right;
`;

export const S = { SectionRowGrid, ItemNameGrid, ItemDeleteGrid };
