import React from 'react';
import styled from 'styled-components';
import { Grid, Button, Typography, Divider } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import * as colors from 'components/styles/colors';
import { breakpoints } from 'components/styles/breakpoints';
import { LockOutlined } from '@material-ui/icons';

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px, 15px;
`;

const IncrementBtn = styled((props) => <AddCircleIcon {...props} />)`
  && {
    cursor: pointer;
    font-size: 22px;
    padding: 0px 2px;
    fill: ${(props) => props.colour};
    opacity: ${(props) => props.opacity};
  }

  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 20px;
    }
  }
`;

const DecrementBtn = styled((props) => <RemoveCircleIcon {...props} />)`
  && {
    cursor: pointer;
    font-size: 22px;
    padding: 0px 2px;
    fill: ${(props) => props.colour};
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 20px;
    }
  }
`;

const ItemCountBtn = styled(Button)`
  && {
    cursor: 'pointer';
    min-width: 0;
    padding: 0px 6px;
    background-color: rgba(238, 239, 242, 0.9);
  }
`;

const ItemCount = styled(Typography)`
  && {
    padding: 0px 5px;
    font-size: 14px;
    font-weight: 800;
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 12px;
    }
  }
`;

const CartDivider = styled(Divider)`
  && {
    color: ${colors.WEAK_GRAY};
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const AddToCartButton = styled(({ ...props }) => <Button {...props} />)`
  && {
    margin-left: auto;
    color: ${(props) => (props.disabled ? colors.DARK_GRAY : colors.WHITE)};
    background-color: ${(props) => (props.disabled ? colors.GRAY90 : props.colour.dark)};
    text-transform: uppercase;
    font-size: 12px;
    padding: 4px 15px;
    font-weight: 800;
    border-radius: 20px;
    &:hover {
      background-color: ${(props) => props.colour.normal};
    }
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 11px;
      padding: 1px 20px;
    }
  }
`;

export const LockAddToCartButton = styled(Button).attrs({
  startIcon: <LockOutlined />,
})`
  && {
    font-weight: 800;
    color: #fff;
    background: #c5cad5;
    text-transform: uppercase;
    font-size: 12px;
    padding: 4px 15px;
    justify-content: left;
    border-radius: 20px;
    place-content: center;
    :focus {
      background: #c5cad5;
    }
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 11px;
      padding: 1px 20px;
    }
  }
`;

export const S = {
  Wrapper,
  IncrementBtn,
  DecrementBtn,
  ItemCountBtn,
  ItemCount,
  CartDivider,
  AddToCartButton,
  LockAddToCartButton,
};
