import React from 'react';
import { translate } from 'react-i18next';

import { ChatBubble } from 'components/pages/MainPage/components/chat-bubble';
import * as colors from 'components/styles/colors';

import { S } from './orders-header.styles';

interface Props {
  t: any;
}

const OrdersHeaderBase: React.FC<React.PropsWithChildren<Props>> = ({ t }) => (
  <S.Header container>
    <S.HeaderContent title={t('title')} showContent>
      <ChatBubble color={colors.WHITE} />
    </S.HeaderContent>
  </S.Header>
);

export const OrdersHeader = translate('orders')(OrdersHeaderBase);
