import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, withStyles } from '@material-ui/core';
import { DrawerProps } from '@material-ui/core/Drawer';

import { State } from 'store';
import { fetchPharmacyServingDistributors } from 'store/reducers/pharmacy';
import { useGetCurrentUser } from 'hooks';

import { OrdersFilterCard } from './orders-filter-card.component';

import { S } from './orders-filter-modal.styles';
import { UseOrderFilters } from './hooks';

interface Props extends DrawerProps {
  open: boolean;
  onClose: () => void;
  distributorFilter: UseOrderFilters;
}

const styles = {
  paper: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
};

const OrdersDistributorFilterModalBase: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  classes,
  distributorFilter: {
    addDistributorFilterHandler,
    onCancelHandler,
    onSaveHandler,
    uiFilters,
    removeDistributorFilterHandler,
    resetFilterHandler,
  },
}) => {
  const {
    coreUser: { organization_id },
  } = useGetCurrentUser();

  const { servingDistributors } = useSelector(({ pharmacy }: State) => pharmacy);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPharmacyServingDistributors(organization_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFilterHandler = (): void => {
    onSaveHandler();
    onClose();
  };

  const cancelFilterHandler = (): void => {
    onCancelHandler();
    onClose();
  };

  const handleResetClick = (): void => resetFilterHandler();

  return (
    <Drawer anchor="bottom" classes={classes} onClose={cancelFilterHandler} open={open}>
      <S.Title>Filter</S.Title>
      <S.HeaderControlsContainer>
        <S.FilterGroup>distributors</S.FilterGroup>
        <S.ToggleButton onClick={handleResetClick}>Reset</S.ToggleButton>
      </S.HeaderControlsContainer>
      <S.Container>
        <OrdersFilterCard
          onAddFilter={addDistributorFilterHandler}
          onRemoveFilter={removeDistributorFilterHandler}
          filters={servingDistributors}
          currentFilters={uiFilters.distributors}
        />
      </S.Container>
      <S.ControlsContainer>
        <S.CancelButton onClick={cancelFilterHandler} data-testid="btn-cancel-distributor-filter">
          Cancel
        </S.CancelButton>
        <S.SaveButton onClick={saveFilterHandler} data-testid="btn-save-distributor-filter">
          Save
        </S.SaveButton>
      </S.ControlsContainer>
    </Drawer>
  );
};

export const OrdersDistributorFilterModal = withStyles(styles)(OrdersDistributorFilterModalBase);
