import { AxiosInstance } from 'axios';

import { searchProfilerClient } from 'utils/Client/search-profiler.client';

import { IClickThrough } from '../interfaces/click-through.interface';

import { ProfilerEndpoints } from '../profiler.constant';

class SearchProfileService {
  private readonly searchProfilerClient: AxiosInstance;

  constructor(searchProfilerClient: AxiosInstance) {
    this.searchProfilerClient = searchProfilerClient;
  }

  private async track(payload: IClickThrough): Promise<IClickThrough | null> {
    try {
      const { data } = await this.searchProfilerClient.post<IClickThrough>(ProfilerEndpoints.ClickThrough, payload);

      return data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Failed to track click through: ${e.toString()}`);
      return null;
    }
  }

  async clickThrough(payload: IClickThrough): Promise<IClickThrough | null> {
    const clickThroughPayload = payload;
    return this.track(clickThroughPayload);
  }

  async clickThroughSuggestion(
    suggestions: Array<any>,
    query: string,
    selectedId: number,
  ): Promise<IClickThrough | null> {
    let index = -1;

    if (suggestions[0] && suggestions[0]?.products?.length > 0) {
      suggestions[0].products.forEach((product, i) => {
        if (product.id === selectedId) {
          index = i;
        }
      });
    }

    if (index === -1) {
      return null;
    }
    const payload = {
      keyword: query,
      row_number: index + 1,
      product_id: selectedId,
      clicked_at: Date.now(),
    };
    const clickThroughPayload = payload;
    return this.track(clickThroughPayload);
  }

  async trackSRP(payload: any): Promise<void> {
    try {
      await this.searchProfilerClient.post('search-impressions/search', {
        pharmacy_id: payload.pharmacyId,
        market_id: payload.marketId,
        keyword: payload.keyword,
        respond_time: payload.durationTime,
        respond_at: payload.startTime,
        products: payload.products,
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export const searchProfileService = new SearchProfileService(searchProfilerClient);
