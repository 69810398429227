import { createContext, useContext } from 'react';

import {
  IMarketing,
  MarketingType,
  ISpecialMarketing,
  IMarketingConfig,
} from 'services/swipe-rx-pt/resources/marketings/interfaces';

export interface MarketingHook {
  marketing: IMarketing | ISpecialMarketing | undefined;
}

export enum MarketingActions {
  SET_DATA = 'SET_DATA',
  CLEAR_DATA = 'CLEAR_DATA',
}

export interface MarketingState {
  marketing_id?: number;
  marketing_name: string;
  marketing_type?: MarketingType;
  marketing_config?: IMarketingConfig;
}

export type MarketingAction = {
  type: MarketingActions;
  marketing?: MarketingState;
};

export type DispatchMarketing = (action: MarketingAction) => void;

export const MarketingStateContext = createContext<MarketingState | undefined>(undefined);
export const MarketingDispatchContext = createContext<DispatchMarketing | undefined>(undefined);

export const initialState: MarketingState = {
  marketing_id: undefined,
  marketing_name: '',
  marketing_type: undefined,
  marketing_config: undefined,
};

export const marketingReducer = (state: MarketingState, action: MarketingAction): MarketingState => {
  switch (action.type) {
    case MarketingActions.SET_DATA:
      return {
        ...state,
        ...action.marketing,
      };
    case MarketingActions.CLEAR_DATA:
      return {
        ...state,
        ...initialState,
      };
    default:
      return initialState;
  }
};

export const useMarketingState = (): MarketingState => {
  const context = useContext(MarketingStateContext);

  if (context === undefined) {
    throw new Error('useMarketingState must be used within marketing component');
  }

  return context;
};

export const useMarketingDispatch = (): DispatchMarketing => {
  const context = useContext(MarketingDispatchContext);

  if (context === undefined) {
    throw new Error('useMarketingDispatch must be used within a marketing component');
  }

  return context;
};
