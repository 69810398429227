export type TFunction = (key: string, options?) => string;

export interface TranslateProps {
  t: TFunction;
}

// Market locale list
export type Locale = 'id' | 'th' | 'en';
export const ID_LOCALE: Locale = 'id';
export const TH_LOCALE: Locale = 'th';
export const EN_LOCALE: Locale = 'en';

export const DEFAULT_LOCALE: Locale = EN_LOCALE;
export const FALLBACK_LOCALE: Locale = EN_LOCALE;

export const SupportedLocaleList: Locale[] = [ID_LOCALE, TH_LOCALE, EN_LOCALE];

// Market language list
export type Language = 'id' | 'th' | 'en';
export const ID_LANG: Language = 'id';
export const TH_LANG: Language = 'th';
export const EN_LANG: Language = 'en';

export const DEFAULT_LANG: Language = EN_LANG;
export const FALLBACK_LANG: Language = EN_LANG;

export const SupportedLanguageList: Locale[] = [ID_LANG, TH_LANG, EN_LANG];
