import { StockStatus } from '@causalfoundry/js-sdk';

export const getProductStockStatus = (
  remaining_quantity: number,
  quantity_threshold: number,
  low_stock_threshold: number,
): StockStatus =>
  remaining_quantity <= quantity_threshold
    ? StockStatus.OutOfStock
    : remaining_quantity <= low_stock_threshold
    ? StockStatus.LowStock
    : StockStatus.InStock;
