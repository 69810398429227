interface ProductCardTours {
  favoriteId: string;
  cardId: string;
  imageId: string;
  contentId: string;
  addBtn: string;
  quantityBtn: string;
}

export const getProductCardTours = (i: any): ProductCardTours => ({
  favoriteId: `tour__productFavorite__${i}`,
  cardId: `tour__productCard__${i}`,
  imageId: `tour__productImg__${i}`,
  contentId: `tour__content__${i}`,
  addBtn: `tour__addBtn__${i}`,
  quantityBtn: `tour__quantityBtn__${i}`,
});
