import { Reducer } from 'redux';
import { IMarketingFeatures } from 'services/swipe-rx-pt/resources/marketings/interfaces';

import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';
import { Actions } from './interface';
import * as constants from './constants';

export type State = {
  [key in MarketingFeaturesType | string]?: StateObj;
};

export type StateObj = {
  data?: IMarketingFeatures[];
  loading?: boolean;
  expired?: number | null;
  hasMore?: boolean;
  currentPage?: number;
};

const initialState: State = {};

const MarketingFeaturesReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_MARKETING_FEATURES:
      return {
        ...state,
        [action.featureType]: {
          ...state[action.featureType],
          data: action.data,
          expired: action.expired,
          loading: action.loading,
          hasMore: false,
          currentPage: 1,
        },
      };

    case constants.SET_MARKETING_FEATURES_LOADING:
      return {
        ...state,
        [action.featureType]: {
          ...state[action.featureType],
          loading: action.loading,
        },
      };

    default:
      return state;
  }
};

export default MarketingFeaturesReducer;
