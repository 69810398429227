import { recordException } from 'utils/Reporting/Sentry';

import { ERROR_CODE } from 'components/pages/ProductListingPage/constants';
import { SortType } from 'components/common/FilterToolbar/constants';
import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';
import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { cancelPendingRequest, v3Client } from '../../../utils/Client/v3';
import { State } from '../../index';
import { Actions } from './interface';
import { ProductListingRequest } from '.';
import * as counterConstants from '../Counter/constants';
import { LABEL_VALUE_SEPARATOR } from '../FilterToolbar/constants';
import { getMarketingFeatures } from '../MarketingFeatures/actions';
import { clearSimilarProductCarousel } from '../ProductCarousel/actions';
import { SimilarProductLocation } from '../ProductCarousel/interface';
import { clearProductImpression } from '../ProductImpression/actions';

export const fetchProductListing: ThunkActionCreator<Actions, State> =
  (payload: ProductListingRequest) => async (dispatch, getState) => {
    // console.log('[fetchProductListing]', payload.caller, { payload });
    try {
      cancelPendingRequest();
      const { url, limit, marketingId, params, segmentationChecking = false } = payload;

      const isSimilarProduct = url.includes(MarketingType.SIMILAR_PRODUCT);
      const currentQuery = params.query || '';
      const state = getState();

      const {
        products: productStateList = [],
        previousQuery,
        previousMarketingId,
        isApproximate,
        sku_code,
      } = state.productListing;

      const { lvl2SortBy, filterSort } = state.filterToolbar;

      const startSimilarProduct = isSimilarProduct && params?.sku_code !== sku_code;
      // TODO: Should now be using query instead of tab
      const isQueryChanged =
        previousQuery !== currentQuery || previousMarketingId !== marketingId || startSimilarProduct;
      const currPage = isQueryChanged ? 1 : state.productListing.nextPage;

      const queryAt = new Date();

      if (startSimilarProduct) {
        dispatch({
          type: constants.CLEAR_PRODUCTS,
        });
      }
      dispatch({
        type: constants.START_SEARCH,
      });

      const currentLvl2SortBy = lvl2SortBy ?? [];

      const [order_by, sort_by] = currentLvl2SortBy[0]
        ? currentLvl2SortBy[0].split(LABEL_VALUE_SEPARATOR)
        : [SortType.DEFAULT, 'asc'];

      const queryParams = {
        query: currentQuery,
        page: currPage,
        page_size: limit,
        ...(segmentationChecking ? { segmentationChecking } : {}),
        ...params,
        filters: state.filterToolbar.selectedFilters,

        // NOTE: workaround to avoid to many change on FE, this is exclusive for ES only filters, sort_by and sort_order will be handled in BE properly
        ...(isSimilarProduct ? {} : { sort_order: filterSort }), // TODO: merge to sort_by or wait for filter-sort purchasability state to be moved to backed
        sort_by,
        order_by,
      };

      delete queryParams.marketingType;
      const canCancelRequest = true;
      const result = await v3Client.get(
        url,
        {
          query: currentQuery,
          page: currPage,
          page_size: limit,
          ...(segmentationChecking ? { segmentationChecking } : {}),
          ...(isApproximate ? { is_fuzzy: true } : {}),
          ...queryParams,
        },
        canCancelRequest,
      );

      if (!result) {
        return;
      }
      const respondAt = new Date();
      const respondTime = respondAt.getTime() - queryAt.getTime();

      const { data } = result;

      const products = isQueryChanged ? data : [...productStateList, ...data];

      const { total_count, is_approximate } = result.meta;

      const hasMore = currPage * limit < result.meta.total_count;
      const previousCategories = params?.category_ids?.join(',') || '';

      dispatch({
        type: constants.GET_PRODUCTS,
        products,
        totalProducts: total_count,
        nextPage: currPage + 1,
        hasMore,
        previousMarketingId: marketingId,
        previousQuery: currentQuery,
        loading: hasMore && !products.length,
        respondAt,
        respondTime,
        hasSearchEnded: true,
        previousCategories,
        filters: state.filterToolbar.selectedFilters,
        sorts: state.filterToolbar.lvl2SortBy,
        isApproximate: is_approximate,
        sku_code: params?.sku_code || '',
      });
      if (products?.length < 8 && !state.marketingFeatures?.marketings) {
        dispatch(
          getMarketingFeatures({
            featureType: MarketingFeaturesType.GENERAL,
          }),
        );
      }
      // eslint-disable-next-line no-use-before-define
      dispatch(generateMaxPurchase(data.filter((product) => product.max_qty_enabled)));
    } catch (error) {
      let errorCode = '';
      if ((error as any).response) {
        const { data } = (error as any).response;
        errorCode = data.errorCode;
      }
      const isForbiddenMarketingSegment = errorCode === ERROR_CODE.FORBIDDEN_MARKETING_SEGMENTS_MISMATCH;
      if (error instanceof Error) {
        recordException(error, 'fetchProductListing', { payload });
        const errMsg = error.message;
        // eslint-disable-next-line no-console
        console.error(errMsg);
        dispatch({ type: constants.FAIL_GET_PRODUCTS, error: isForbiddenMarketingSegment ? errorCode : errMsg });
      }
    }
  };

export const startSearch: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.START_SEARCH,
  });
};

export const endSearch: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.END_SEARCH,
  });
};

export const clearProductListing: ThunkActionCreator<Actions, State> =
  (preventRefresh?: boolean) => async (dispatch) => {
    dispatch({
      type: constants.CLEAR_PRODUCTS,
      preventRefresh,
    });

    dispatch(clearSimilarProductCarousel(SimilarProductLocation.SEARCH_PAGE));
    dispatch(clearProductImpression());
  };

export const clearProductListingError: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_PRODUCT_ERROR,
  });
};

export const setListingPageReferer: ThunkActionCreator<Actions, State> = (referer: string) => async (dispatch) => {
  dispatch({
    type: constants.SET_LISTING_PAGE_REFERER,
    referer,
  });
};

export const clearListingPageReferer: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_LISTING_PAGE_REFERER,
  });
};

export const generateMaxPurchase: ThunkActionCreator<Actions, State> = (items) => async (dispatch, getState) => {
  const { orders } = getState();

  dispatch({
    type: counterConstants.MAX_QUANTITY_CART_ITEM_ADD,
    items: items.map((product) => {
      const history = orders.orderItemQuantityHistory.find(
        (ordered) => ordered.distributor_product_id === product.distributor_product_id,
      );

      return {
        distributor_product_id: product.distributor_product_id,
        max_qty: product.max_qty,
        max_monthly_qty: product.monthly_max_qty,
        max_qty_enabled: (product.max_qty_enabled && product?.max_qty && product.max_qty > 0) || false,
        max_qty_monthly_enabled:
          (product.max_qty_enabled && product?.monthly_max_qty && product.monthly_max_qty > 0) || false,
        purchase_history_qty: history?.purchase_history_qty || 0,
        purchase_history_monthly_qty: history?.purchase_history_monthly_qty || 0,
      };
    }),
  });
};
