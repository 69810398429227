import produce, { Draft } from 'immer';
import uniqBy from 'lodash/uniqBy';

import { PurchaseOrderStore } from './purchase-order.interface';
import { PurchaseOrderActionTypes, PurchaseOrderActions } from './purchase-order-actions.type';

const orderState: PurchaseOrderStore = {
  loading: false,
  orders: {
    data: [],
    meta: {
      page: 0,
      page_size: 0,
      page_count: 0,
      total_count: 0,
    },
  },
  activities: {
    data: [],
    unread: 0,
  },
  orderDetail: {},
  error: '',
  loyaltyStatus: null,
  requirements: [],
  deliveries: [],
};

export const purchaseOrderReducer = (
  state: PurchaseOrderStore = orderState,
  action: PurchaseOrderActions,
): PurchaseOrderStore =>
  produce(state, (draft: Draft<PurchaseOrderStore>) => {
    switch (action.type) {
      case PurchaseOrderActionTypes.SET_PURCHASE_ORDERS_LOADING:
        draft.loading = action.payload.loading;
        break;

      case PurchaseOrderActionTypes.SET_INITIAL_PURCHASE_ORDER_LIST:
        draft.orders = {
          data: action.payload.data,
          meta: { ...action.payload.meta },
        };
        break;

      case PurchaseOrderActionTypes.SET_PURCHASE_ORDER_LIST:
        // this check to avoid the search results to be tripled or doubled when typing fast
        draft.orders = {
          data: uniqBy([...draft.orders.data, ...action.payload.data], 'id'),
          meta: { ...action.payload.meta },
        };
        break;

      case PurchaseOrderActionTypes.SET_PURCHASE_ORDER_DETAILS:
        draft.orderDetail = action.payload.data;
        draft.loyaltyStatus = action.payload.loyaltyStatus || null;
        break;

      case PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ERROR:
        draft.error = action.payload.error;
        break;

      case PurchaseOrderActionTypes.RESET_PURCHASE_ORDER_DETAILS:
        draft.orderDetail = {};
        draft.loyaltyStatus = null;
        break;

      case PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ACTIVITY:
        draft.activities = { data: action.payload.data, unread: action.payload.unread };
        break;

      case PurchaseOrderActionTypes.UPDATE_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT:
        draft.activities = { ...draft.activities, unread: draft.activities.unread - 1 };
        break;

      case PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT:
        draft.activities = { ...draft.activities, unread: action.payload.unread };
        break;

      case PurchaseOrderActionTypes.RESET_PURCHASE_ORDER_LIST:
        draft.orders = {
          data: [],
          meta: {
            page: 0,
            page_size: 0,
            page_count: 0,
            total_count: 0,
          },
        };
        break;

      case PurchaseOrderActionTypes.SET_PURCHASE_ORDER_DOCUMENT_REQUIREMENT:
        draft.requirements = [...draft.requirements, ...action.payload];
        break;

      case PurchaseOrderActionTypes.SET_PURCHASE_ORDER_LOGISTICS_DELIVERY:
        draft.deliveries = action.payload;
        draft.orderDetail = {
          ...draft.orderDetail,
          logistics_delivery_status: draft.deliveries[draft.deliveries.length - 1] ?? null,
        };
        break;
    }
  });
