/* eslint-disable max-len */
import { ListMeta, LoyaltyHistoryGroup, LoyaltyHistoryStatus, LoyaltyHistoryTab } from 'services';

import moment from 'moment-timezone';

import { recordException } from 'utils/Reporting/Sentry';
import { cancelPendingRequest, v3Client } from 'utils/Client/v3';
import { DistributorCarts } from 'store/reducers/Cart';
import isNull from 'lodash/isNull';
import { hideError } from '../Global/actions';

import {
  LoyaltyProgramActionTypes,
  SetLoyaltyProgramError,
  SetLoyaltyProgramLoading,
  SetLoyaltyHistoryList,
  ResetLoyaltyHistoryList,
  SetLoyaltyProgramInfo,
  SetLoyaltyRewardInfo,
  ResetLoyaltyRewardsList,
  SetLoyaltyRewardsList,
  SetLoyaltyRewardRedeem,
  SetLoyaltyHistoryLoading,
  SetLoyaltyInfoCalculatingPoints,
  SetLoyaltyInfoDeductedPoints,
  SetLoyaltyInfoRedeemedPoints,
  SetLoyaltyInfoExpiringPoints,
  SetLoyaltyBannerData,
  SetLoyaltyRegistrationData,
  SetLoyaltyPotentialPoints,
  SetLoyaltyRedeemFailed,
  SetLoyaltyRedeemLoading,
  SetLoyaltyRewardCategories,
  SetLoyaltyRewardCategory,
  SetLoyaltyPreviewRewardsLoading,
  SetLoyaltyPreviewRewardsError,
  SetLoyaltyRewardsLoading,
  SetLoyaltyRewardsQueries,
  SetLoyaltyRewardCategoryLoading,
  SetLoyaltyPreviewRewardsList,
  SetLoyaltyPreviewMyRewardsLoading,
  SetLoyaltyPreviewMyRewardsList,
  SetLoyaltyPreviewMyRewardsError,
  SetLoyaltyRewardInfoLoading,
  SetLoyaltyPointsToMaintain,
  SetLoyaltyPointsToUpgrade,
} from './loyalty-program-actions.type';
import {
  LoyaltyProgramInfo,
  LoyaltyReward,
  LoyaltyRewardType,
  LoyaltyRegistration,
  LoyaltyPotentialProductPoints,
  LoyaltyPotentialPoints,
  LoyaltyPotentialPointsPayload,
  LoyaltyRewardPreviewType,
  LoyaltyRewardsQueryParams,
  LoyaltyRewardsVendor,
  LoyaltyRewardCategoryItem,
  LoyaltyProgramStore,
  LoyaltyPoints,
  LoyaltyPointsResponse,
  LoyaltyTierName,
  LoyaltyMissionStatus,
  LoyaltyMission,
  LoyaltyMissionInfo,
  LoyaltyMissionCompleted,
} from './loyalty-program.inteface';

type PointsQuery = {
  point_group: LoyaltyHistoryTab;
  point_type: LoyaltyHistoryStatus;
  page?: number;
  point_cursor_date?: string;
};
type loyaltyHistoryParams = PointsQuery & {
  organization_id: number;
  last_data?: LoyaltyHistoryGroup;
};

interface loyaltyRewardsListParams {
  organization_id: number;
  type: LoyaltyRewardType;
  page?: number;
  reload?: boolean;
  sort_by?: string;
  order_by?: string;
}
interface loyaltyRewardDetailsParams {
  organization_id: number;
  reward_id: number;
}

interface loyaltyRewardRedeemParams {
  organization_id: number;
  reward_id: number;
  vendor_redeem?: LoyaltyRewardVendorRedeem;
}

interface loyaltyRewardsPreviewParams {
  organization_id: number;
  previewType: LoyaltyRewardPreviewType;
  random?: boolean;
}

interface loyaltyMyRewardsPreviewParams {
  organization_id: number;
  type: LoyaltyRewardPreviewType;
  random?: boolean;
}

interface fetchLoyaltyRewardsListParams extends LoyaltyRewardsQueryParams {
  organization_id: number;
  type: string;
  category_id?: string | undefined;
  sort_by?: string;
  order_by?: string;
}

export interface LoyaltyRewardVendorRedeem {
  vendor: string;
  redeem_info?: Array<{ key: string; value: string }>;
}

// REDUX SET STATE
export const setLoyaltyProgramLoading = (loading: boolean): SetLoyaltyProgramLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_LOADING,
  payload: { loading },
});

export const setLoyaltyProgramError = (error: string | null): SetLoyaltyProgramError => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_ERROR,
  payload: { error },
});

export const setLoyaltyHistoryLoading = (loading: boolean): SetLoyaltyHistoryLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_HISTORY_LOADING,
  payload: { loading },
});

export const setLoyaltyHistoryList = (data: LoyaltyHistoryGroup[], meta: ListMeta): SetLoyaltyHistoryList => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_HISTORY_LIST,
  payload: { data, meta },
});

export const resetLoyaltyHistoryList = (): ResetLoyaltyHistoryList => ({
  type: LoyaltyProgramActionTypes.RESET_LOYALTY_HISTORY_LIST,
});

export const setLoyaltyProgramInfo = (data: LoyaltyProgramInfo): SetLoyaltyProgramInfo => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_INFO,
  payload: { data },
});

export const setLoyaltyRewardsList = (
  data: LoyaltyReward[],
  meta: ListMeta,
  type: LoyaltyRewardType,
): SetLoyaltyRewardsList => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_LIST,
  payload: { data, meta, type },
});

export const resetLoyaltyRewardsList = (all?: boolean): ResetLoyaltyRewardsList => ({
  type: LoyaltyProgramActionTypes.RESET_LOYALTY_REWARDS_LIST,
  payload: { all },
});

export const setLoyaltyRewardInfo = (data: LoyaltyReward): SetLoyaltyRewardInfo => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_INFO,
  payload: { data },
});
export const setLoyaltyRewardInfoLoading = (loading: boolean): SetLoyaltyRewardInfoLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_INFO_LOADING,
  payload: { loading },
});

export const setLoyaltyRewardRedeem = (data: LoyaltyReward): SetLoyaltyRewardRedeem => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_REDEEM,
  payload: { data },
});

const setLoyaltyProgramCalculatingPoints = (calculating: boolean, key?: string): SetLoyaltyInfoCalculatingPoints => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_CALCULATING_POINTS,
  payload: { calculating, key },
});

const setLoyaltyInfoDeductedPoints = (points: number): SetLoyaltyInfoDeductedPoints => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_DEDUCTED_POINTS,
  payload: { points },
});
const setLoyaltyInfoRedeemedPoints = (points: number): SetLoyaltyInfoRedeemedPoints => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_REDEEMED_POINTS,
  payload: { points },
});
const setLoyaltyInfoExpiringPoints = (points: number): SetLoyaltyInfoExpiringPoints => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PROGRAM_EXPIRING_POINTS,
  payload: { points },
});

const setLoyaltyBannerData = (
  enabled: boolean,
  registered: boolean,
  tier: string,
  points: number,
  pendingPoints: number,
  displayPendingPoint: boolean,
  displayMission: boolean,
  displayWelcomeMission: boolean,
  totalActiveMissions: number,
  cycle = 1,
): SetLoyaltyBannerData => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_BANNER_DATA,
  payload: {
    enabled,
    registered,
    tier,
    points,
    pendingPoints,
    displayPendingPoint,
    displayMission,
    displayWelcomeMission,
    totalActiveMissions,
    cycle,
  },
});

const setLoyaltyRegistrationData = (
  registered: boolean,
  registrationData: LoyaltyRegistration,
  termOfUseURL?: string,
  privacyPolicyURL?: string,
): SetLoyaltyRegistrationData => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REGISTRATION_DATA,
  payload: {
    registered,
    registration: registrationData,
    termOfUseURL,
    privacyPolicyURL,
  },
});

const setLoyaltyPotentialPoints = (
  potentialPointsDisabled: boolean,
  potentialPoints: number,
  potentialProductPoints?: LoyaltyPotentialProductPoints,
): SetLoyaltyPotentialPoints => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_POTENTIAL_POINTS,
  payload: {
    potentialPointsDisabled,
    potentialPoints,
    potentialProductPoints,
  },
});

export const setLoyaltyRedeemFailed = (redeemFailed: string | null): SetLoyaltyRedeemFailed => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REDEEM_FAILED,
  payload: { redeemFailed },
});

export const setLoyaltyRedeemLoading = (loading: boolean): SetLoyaltyRedeemLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REDEEM_LOADING,
  payload: { loading },
});

export const setLoyaltyRewardCategoryLoading = (loading: boolean): SetLoyaltyRewardCategoryLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORY_LOADING,
  payload: { loading },
});
export const setLoyaltyRewardCategories = (data: LoyaltyRewardCategoryItem[]): SetLoyaltyRewardCategories => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORIES,
  payload: { data },
});
export const setLoyaltyRewardCategory = (selected: LoyaltyRewardCategoryItem | null): SetLoyaltyRewardCategory => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARD_CATEGORY,
  payload: { selected },
});

export const setLoyaltyRewardsLoading = (loading: boolean): SetLoyaltyRewardsLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_LOADING,
  payload: { loading },
});

export const setLoyaltyPreviewRewardsLoading = (
  type: LoyaltyRewardPreviewType,
  loading: boolean,
): SetLoyaltyPreviewRewardsLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_LOADING,
  payload: { type, loading },
});
export const setLoyaltyPreviewRewardsList = (
  type: LoyaltyRewardPreviewType,
  data: LoyaltyReward[],
): SetLoyaltyPreviewRewardsList => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_LIST,
  payload: { type, data },
});
export const setLoyaltyPreviewRewardsError = (
  type: LoyaltyRewardPreviewType,
  error: string,
): SetLoyaltyPreviewRewardsError => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_REWARDS_ERROR,
  payload: { type, error },
});

export const setLoyaltyPreviewMyRewardsLoading = (loading: boolean): SetLoyaltyPreviewMyRewardsLoading => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_MY_REWARDS_LOADING,
  payload: { loading },
});

export const setLoyaltyPreviewMyRewardsError = (error: string): SetLoyaltyPreviewMyRewardsError => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_MY_REWARDS_ERROR,
  payload: { error },
});

export const setLoyaltyPreviewMyRewardsList = (data: LoyaltyReward[]): SetLoyaltyPreviewMyRewardsList => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_PREVIEW_MY_REWARDS_LIST,
  payload: { data },
});

export const setLoyaltyPointsToMaintain = (payload: LoyaltyPoints): SetLoyaltyPointsToMaintain => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_TO_MAINTAIN,
  payload,
});
export const setLoyaltyPointsToUpgrade = (payload: LoyaltyPoints): SetLoyaltyPointsToUpgrade => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_TO_UPGRADE,
  payload,
});

const buildPointsQuery = (query: PointsQuery, lastData?: LoyaltyHistoryGroup): PointsQuery => {
  const { page = 1, point_group, point_type } = query;
  if (page <= 1) return query;
  if (point_group !== LoyaltyHistoryTab.RECEIVED) return query;
  if (point_type !== LoyaltyHistoryStatus.ALL) return query;
  if (!lastData?.points) return query;

  const lastPoints = lastData.points;
  const cursorDate = lastPoints[lastPoints.length - 1].created_at;
  return { ...query, point_cursor_date: cursorDate };
};

export const getCountLoyaltyHistoryGroup = (data: LoyaltyHistoryGroup[]): number => {
  if (!data.length) return 0;
  return data.flatMap((item) => item.points).length;
};

export const fetchInitialLoyaltyHistoryList = (options: loyaltyHistoryParams) => async (dispatch) => {
  try {
    cancelPendingRequest();

    dispatch(hideError());
    dispatch(setLoyaltyProgramError(null));
    dispatch(setLoyaltyHistoryLoading(true));

    const { organization_id, last_data, ...query } = options;
    const initialQuery = buildPointsQuery(query, last_data);

    const getLoyaltyPointHistory = async (query: PointsQuery, total: number): Promise<void> => {
      const result = await v3Client.get<{ data: LoyaltyHistoryGroup[]; meta: ListMeta }>(
        `loyalty/${organization_id}/points`,
        query,
        true,
      );
      if (!result) return;
      const { data, meta } = result;

      dispatch(setLoyaltyHistoryList(data, meta));

      const updatedTotal = total + getCountLoyaltyHistoryGroup(data);
      const updatedQuery = {
        ...query,
        page: (query.page || 1) + 1,
      };

      dispatch(setLoyaltyHistoryLoading(false));
      if (updatedTotal < 10 && meta.page < meta.page_count) {
        const lastData = data[data.length - 1];
        await getLoyaltyPointHistory(buildPointsQuery(updatedQuery, lastData), updatedTotal);
      }
    };

    await getLoyaltyPointHistory(initialQuery, 0);

    dispatch(setLoyaltyHistoryLoading(false));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchInitialLoyaltyHistoryList', { options });
    if (error.message) {
      dispatch(setLoyaltyProgramError(error.message));
      dispatch(setLoyaltyHistoryLoading(false));
    }
  }
};

export const fetchLoyaltyHistoryList = (options: loyaltyHistoryParams) => async (dispatch) => {
  try {
    dispatch(setLoyaltyProgramError(null));

    cancelPendingRequest();

    const { organization_id, last_data, ...query } = options;
    const initialQuery = buildPointsQuery(query, last_data);

    const getLoyaltyPointHistory = async (query: PointsQuery, total: number): Promise<void> => {
      const result = await v3Client.get<{ data: LoyaltyHistoryGroup[]; meta: ListMeta }>(
        `loyalty/${organization_id}/points`,
        query,
        true,
      );
      if (!result) return;

      const { data, meta } = result;

      dispatch(setLoyaltyHistoryList(data, meta));

      const updatedTotal = total + getCountLoyaltyHistoryGroup(data);
      const updatedQuery = {
        ...query,
        page: (query.page || 1) + 1,
      };

      if (updatedTotal < 10 && meta.page < meta.page_count) {
        const lastData = data[data.length - 1];
        await getLoyaltyPointHistory(buildPointsQuery(updatedQuery, lastData), updatedTotal);
      }
    };

    await getLoyaltyPointHistory(initialQuery, 0);
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchLoyaltyHistoryList', { options });
    if (error.message) {
      dispatch(setLoyaltyProgramError(error.message));
    }
  }
};

export const fetchLoyaltyProgramInfo =
  (id: number, useCalculation = true, getPointType = ['redeemed', 'deducted', 'expiring']) =>
  async (dispatch, getState) => {
    try {
      const {
        loyalty: { info },
      } = getState();

      const useLoading = !info.lastFetchedAt;
      dispatch(setLoyaltyProgramLoading(useLoading));
      dispatch(setLoyaltyProgramCalculatingPoints(useLoading, 'redeemedPoints'));
      dispatch(setLoyaltyProgramCalculatingPoints(useLoading, 'deductedPoints'));
      dispatch(setLoyaltyProgramCalculatingPoints(useLoading, 'expiringPoints'));

      // loyalty info
      const result = await v3Client.get(`loyalty/${id}/info`);
      const { data } = result;
      dispatch(setLoyaltyProgramInfo(data));

      if (!useCalculation) return;

      const getPoints = ({ pointGroup, pointType, setterFn }): void => {
        if (!getPointType.includes(pointType)) return;
        v3Client
          .get(`loyalty/${id}/point-calculation?point_group=${pointGroup}&point_type=${pointType}`)
          .then((result) => dispatch(setterFn(result?.data?.points || 0)))
          .catch(() => {
            dispatch(setterFn(0));
          });
      };

      // let ui display loading for each points, no need to wait the calculation points
      // deducted points

      getPoints({
        pointGroup: 'used',
        pointType: 'deducted',
        setterFn: setLoyaltyInfoDeductedPoints,
      });

      // redeemed points
      getPoints({
        pointGroup: 'used',
        pointType: 'redeemed',
        setterFn: setLoyaltyInfoRedeemedPoints,
      });

      // expiring points
      getPoints({
        pointGroup: 'received',
        pointType: 'expiring',
        setterFn: setLoyaltyInfoExpiringPoints,
      });
    } catch (e) {
      const error = e as Error;
      recordException(error, 'fetchLoyaltyProgramInfo', { id, useCalculation, getPointType });
      dispatch(setLoyaltyInfoDeductedPoints(0));
      dispatch(setLoyaltyInfoRedeemedPoints(0));
      dispatch(setLoyaltyInfoExpiringPoints(0));
      dispatch(setLoyaltyProgramCalculatingPoints(false));
    } finally {
      dispatch(setLoyaltyProgramLoading(false));
    }
  };

const fetchLoyaltyRewardsListAsync = async (
  query: fetchLoyaltyRewardsListParams,
  page = 1,
): Promise<{ data: LoyaltyReward[]; meta: ListMeta }> => {
  const { organization_id, type, random = false, category_id, order_by, sort_by, ...queryParams } = query;

  const typeMapping = {
    [LoyaltyRewardType.HOT_REWARDS]: 'available',
    [LoyaltyRewardType.MY_REWARDS]: 'bought',
  };

  // default sort & order
  const defaultOrderBy = type === LoyaltyRewardType.HOT_REWARDS ? 'points' : 'purchasedAt';
  const defaultSortBy = type === LoyaltyRewardType.HOT_REWARDS ? 'asc' : 'desc';

  const params: Record<string, any> = {
    type: typeMapping[type],
    random,
    page_size: 10,
    page,
    sort_by: sort_by || defaultSortBy,
    order_by: order_by || defaultOrderBy,
    ...queryParams,
  };

  if (category_id && category_id !== '0') {
    params.category_id = category_id;
  }

  const canCancelRequest = type === LoyaltyRewardType.MY_REWARDS;
  const result = await v3Client.get(`loyalty/${organization_id}/rewards`, params, canCancelRequest);

  return result;
};

export const setLoyaltyRewardsFilters = (queries: LoyaltyRewardsQueryParams): SetLoyaltyRewardsQueries => ({
  type: LoyaltyProgramActionTypes.SET_LOYALTY_REWARDS_QUERIES,
  payload: { queries },
});

export const fetchInitLoyaltyRewardsList = (options: loyaltyRewardsListParams) => async (dispatch, getState) => {
  try {
    const { organization_id, type, reload, sort_by, order_by } = options;
    const {
      loyalty: {
        rewards: { type: rewardType },
      },
    } = getState();

    if (rewardType === type && !reload) return;
    cancelPendingRequest();
    dispatch(setLoyaltyProgramError(null));
    dispatch(resetLoyaltyRewardsList(rewardType !== type));
    dispatch(setLoyaltyRewardsLoading(true));

    const { loyalty } = getState();
    const {
      rewards: { queries },
      rewardCategory,
    } = loyalty as LoyaltyProgramStore;

    let additionalQuery = {};

    if (sort_by) additionalQuery = { sort_by };
    if (order_by) additionalQuery = { ...additionalQuery, order_by };

    const { data, meta } = await fetchLoyaltyRewardsListAsync({
      type,
      organization_id,
      category_id: rewardCategory.selected?.id,
      ...additionalQuery,
      ...queries,
    });

    dispatch(setLoyaltyRewardsList(data, meta, type));
    dispatch(setLoyaltyRewardsLoading(false));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchInitLoyaltyRewardsList', { options });
    if (error.message) {
      dispatch(setLoyaltyProgramError(error.message));
      dispatch(setLoyaltyRewardsLoading(false));
    }
  }
};

export const fetchLoyaltyRewardsList = (options: loyaltyRewardsListParams) => async (dispatch, getState) => {
  try {
    dispatch(setLoyaltyProgramError(null));

    const { organization_id, type, page = 1 } = options;

    const { loyalty } = getState();
    const {
      rewards: { queries },
      rewardCategory,
    } = loyalty as LoyaltyProgramStore;

    const { data, meta } = await fetchLoyaltyRewardsListAsync(
      {
        type,
        organization_id,
        category_id: rewardCategory.selected?.id,
        ...queries,
      },
      page,
    );

    dispatch(setLoyaltyRewardsList(data, meta, type));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchLoyaltyRewardsList', { options });
    dispatch(setLoyaltyProgramError(error.message));
  }
};

export const fetchLoyaltyReward = (options: loyaltyRewardDetailsParams) => async (dispatch) => {
  try {
    dispatch(setLoyaltyProgramError(null));
    dispatch(setLoyaltyRewardInfoLoading(true));
    const { organization_id, reward_id } = options;
    const result = await v3Client.get(`loyalty/${organization_id}/reward/info`, { reward_id });
    const { data } = result;
    if (data) {
      dispatch(setLoyaltyRewardInfo(data));
    }
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchLoyaltyReward', { options });
    dispatch(setLoyaltyProgramError(error.message));
  } finally {
    dispatch(setLoyaltyRewardInfoLoading(false));
  }
};

export const postRedeemLoyaltyReward = (options: loyaltyRewardRedeemParams) => async (dispatch, getState) => {
  const { organization_id, ...payload } = options;
  const { loyalty } = getState();
  const rewardRedeemed = { ...loyalty.rewardInfo, isRedeemed: true };
  try {
    dispatch(setLoyaltyRedeemFailed(null));
    dispatch(setLoyaltyRedeemLoading(true));

    await v3Client.post(`loyalty/${organization_id}/rewards`, payload);

    dispatch(setLoyaltyRewardInfo(rewardRedeemed));
    dispatch(setLoyaltyRewardRedeem(rewardRedeemed));
  } catch (e) {
    const error = e as any;
    recordException(error, 'postRedeemLoyaltyReward', { options });
    dispatch(setLoyaltyRedeemFailed(error.response?.data?.error ?? error.message));
  } finally {
    dispatch(setLoyaltyRedeemLoading(false));
  }
};

export const fetchLoyaltyBannerData = (pharmacyId: number) => async (dispatch) => {
  try {
    dispatch(setLoyaltyProgramLoading(true));
    const result = await v3Client.get(`loyalty/${pharmacyId}`);

    if (result && result.data) {
      const { data } = result;
      const {
        isLoyaltyEnabledInMarket,
        tier,
        point_active: points,
        point_pending: pendingPoints,
        display_pending_point: displayPendingPoint = true,
        display_mission: displayMission = true,
        display_welcome_mission: displayWelcomeMission = true,
        total_active_mission: totalActiveMissions = 0,
        cycle,
      } = data;

      if (isLoyaltyEnabledInMarket === false) {
        // loyalty is disabled
        dispatch(setLoyaltyBannerData(false, false, '', 0, 0, false, false, false, totalActiveMissions, cycle));
        // console.log('@fetchLoyaltyBannerData :: loyalty is disabled')
      } else if (tier) {
        // loyalty is enabled and already registered
        dispatch(
          setLoyaltyBannerData(
            true,
            true,
            tier,
            points,
            pendingPoints,
            displayPendingPoint,
            displayMission,
            displayWelcomeMission,
            totalActiveMissions,
            cycle,
          ),
        );
        // console.log('@fetchLoyaltyBannerData :: loyalty is enabled and already registered')
      } else {
        // loyalty enabled and not registered
        dispatch(
          setLoyaltyBannerData(
            true,
            false,
            '',
            0,
            0,
            displayPendingPoint,
            displayMission,
            false,
            totalActiveMissions,
            cycle,
          ),
        );
        // console.log('@fetchLoyaltyBannerData :: loyalty is enabled but not registered')
      }
    }
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchLoyaltyBannerData', { pharmacyId });
    dispatch(setLoyaltyBannerData(false, false, '', 0, 0, false, false, true, 0));
    // console.error('@fetchLoyaltyBannerData :: fetch loyalty banner data error', error)
  } finally {
    dispatch(setLoyaltyProgramLoading(false));
  }
};

export const fetchLoyaltyRegistration = (pharmacyId: number) => async (dispatch) => {
  try {
    dispatch(setLoyaltyProgramLoading(true));
    const result = await v3Client.get(`loyalty/${pharmacyId}/pre-register`);
    if (result && result.data) {
      const { data } = result;
      const {
        pharmacy_name: pharmacyName,
        phone_number: phoneNumber,
        email_address: emailAddress,
        base_url: baseUrl,
        registered,
      } = data;
      const { term_of_use: termOfUseURL, privacy_policy: privacyPolicyURL } = baseUrl;
      dispatch(
        setLoyaltyRegistrationData(
          registered,
          {
            pharmacyName,
            phoneNumber,
            emailAddress,
          },
          termOfUseURL,
          privacyPolicyURL,
        ),
      );
      dispatch(setLoyaltyProgramLoading(false));
      return {
        registered,
        pharmacyName,
        phoneNumber,
        emailAddress,
        baseUrl: { termOfUseURL, privacyPolicyURL },
      };
    }
    dispatch(setLoyaltyRegistrationData(true, null, '', ''));
    dispatch(setLoyaltyProgramLoading(false));
    return { registered: true };
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchLoyaltyRegistration', { pharmacyId });
    dispatch(setLoyaltyProgramError(error.message));
    dispatch(setLoyaltyProgramLoading(false));
    return { registered: true };
  }
};

export const registerLoyalty =
  (pharmacyId: number, phoneNumber: string, pharmacyName: string, emailAddress: string) => async (dispatch) => {
    try {
      dispatch(setLoyaltyProgramError(null));
      dispatch(setLoyaltyProgramLoading(true));
      const result = await v3Client.post(`loyalty/${pharmacyId}/register`, {
        email: emailAddress,
        mobileNumber: phoneNumber,
      });
      if (result && result.data) {
        const { data } = result;
        const { tier, point_active, point_pending, total_active_mission } = data;
        dispatch(
          setLoyaltyBannerData(true, true, tier, point_active, point_pending, true, true, true, total_active_mission),
        );
        dispatch(setLoyaltyRegistrationData(true, { emailAddress, pharmacyName, phoneNumber }));
        dispatch(setLoyaltyProgramLoading(false));
        return true;
      }
      dispatch(setLoyaltyProgramLoading(false));
      return false;
    } catch (e) {
      const error = e as any;
      recordException(error, 'registerLoyalty', { pharmacyId, phoneNumber, pharmacyName, emailAddress });
      console.error('@registerLoyalty :: ', error.response);
      dispatch(setLoyaltyProgramError(error.response?.data?.error ?? 'Registration failed'));
      dispatch(setLoyaltyProgramLoading(false));
      return false;
    }
  };

export const fetchPotentialPointsBase = async (
  pharmacyId: number,
  distributorCarts: Array<DistributorCarts>,
): Promise<LoyaltyPotentialPoints | null> => {
  if (distributorCarts.length === 0) return null;

  const taxRate = Number(process.env.REACT_APP_TAX_RATE);

  const payload = distributorCarts.reduce<LoyaltyPotentialPointsPayload>(
    (data, distributor) => {
      const { products = [], total_net_price_before_tax: amount } = distributor;
      const items = products.map((product) => ({
        id: product.id,
        sku: {
          code: product.sku_code || `sku-${product.id}`,
        },
        name: product.name,
        category: product.package,
        quantity: product.quantity,
        grossValue: product.net_price * product.quantity,
      }));
      data.amount += amount;
      data.items = [...data.items, ...items];
      return data;
    },
    { amount: 0, items: [], tax_rate: taxRate },
  );

  try {
    const potentialPoints = await v3Client.post(`loyalty/${pharmacyId}/pre-checkout`, payload);

    return potentialPoints.data as LoyaltyPotentialPoints;
  } catch {
    return null;
  }
};

export const fetchPotentialPoints = (pharmacyId: number) => async (dispatch, getState) => {
  try {
    cancelPendingRequest();

    const {
      cart: { distributors },
    } = getState();

    const potentialPoints = await fetchPotentialPointsBase(pharmacyId, distributors);

    if (!potentialPoints) return;

    const { is_disabled: isPointsDisabled, points, details } = potentialPoints;

    dispatch(setLoyaltyPotentialPoints(isPointsDisabled, points, details));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchPotentialPoints', { pharmacyId });
    console.error('@fetchPotentialPoints :: ', error.message);
  }
};

export const fetchLoyaltyRewardCategories = (organization_id: number) => async (dispatch) => {
  try {
    dispatch(setLoyaltyProgramError(null));
    dispatch(setLoyaltyRewardCategoryLoading(true));
    const result = await v3Client.get(`loyalty/${organization_id}/reward/categories`);
    dispatch(setLoyaltyRewardCategories(result.data));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchLoyaltyRewardCategories', { organization_id });
    dispatch(setLoyaltyProgramError(error.message));
  } finally {
    dispatch(setLoyaltyRewardCategoryLoading(false));
  }
};

export const fetchLoyaltyPreviewRewards = (options: loyaltyRewardsPreviewParams) => async (dispatch, getState) => {
  const { organization_id, previewType } = options;

  try {
    const {
      loyalty: { rewardCategory },
    } = getState();

    dispatch(setLoyaltyPreviewRewardsLoading(previewType, true));

    const params: fetchLoyaltyRewardsListParams = {
      organization_id,
      type: LoyaltyRewardType.HOT_REWARDS,
    };

    const categoryId = !isNull(rewardCategory.selected) ? rewardCategory.selected.id : undefined;

    switch (previewType) {
      case LoyaltyRewardPreviewType.ALL:
        params.category_id = categoryId;
        params.random = true;
        break;
      case LoyaltyRewardPreviewType.MOST_POPULAR:
        /** most popular rewards params here */
        params.order_by = 'claims';
        params.sort_by = 'desc';
        break;
      case LoyaltyRewardPreviewType.LATEST:
        /** latest rewards params here */
        params.order_by = 'date';
        params.sort_by = 'desc';
        break;
      case LoyaltyRewardPreviewType.MY_REWARDS:
        /** my rewards params here */
        params.type = LoyaltyRewardType.MY_REWARDS;
        break;
    }

    const { data } = await fetchLoyaltyRewardsListAsync(params, 1);

    dispatch(setLoyaltyPreviewRewardsList(previewType, data));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchLoyaltyPreviewRewards', { options });
    dispatch(setLoyaltyPreviewRewardsError(previewType, error.message));
  }
};

export const fetchLoyaltyRewardVendors =
  (q: string, callback?: (data: LoyaltyRewardsVendor[]) => void) =>
  async (dispatch, getState): Promise<LoyaltyRewardsVendor[]> => {
    let data: LoyaltyRewardsVendor[] = [];

    try {
      dispatch(setLoyaltyProgramError(null));

      const {
        auth: {
          coreUser: { organization_id },
        },
      } = getState();
      const result = await v3Client.get<{ data: { data: LoyaltyRewardsVendor[]; total: number } }>(
        `loyalty/${organization_id}/reward/vendors`,
        {
          q,
          page_size: 5,
        },
      );
      data = result ? result.data.data : [];
    } catch (e) {
      const error = e as Error;
      recordException(error, 'fetchLoyaltyRewardVendors', { q });
      dispatch(setLoyaltyProgramError(error.message));
    }

    if (callback) {
      callback(data);
    }

    return data;
  };

export const fetchLoyaltyPointsToMaintain = () => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
    loyalty: { pointsToMaintain },
  } = getState();

  try {
    const endPoint = `loyalty/${organization_id}/points-to-maintain`;
    dispatch(
      setLoyaltyPointsToMaintain({
        ...pointsToMaintain,
        loading: isNull(pointsToMaintain.data.totalPoints),
        requesting: true,
        error: undefined,
      }),
    );

    const response = await v3Client.get<{ data: LoyaltyPointsResponse }>(endPoint);
    if (response?.data) {
      const { deadlineDate, ...data } = response.data;
      dispatch(
        setLoyaltyPointsToMaintain({
          error: undefined,
          loading: false,
          requesting: false,
          data: {
            ...data,
            endOfCycleDate: moment(deadlineDate).toDate(),
          },
        }),
      );
    } else {
      setLoyaltyPointsToMaintain({
        ...pointsToMaintain,
        loading: false,
        requesting: false,
        error: undefined,
      });
    }
  } catch (e) {
    const error = e as Error;
    dispatch(
      setLoyaltyPointsToMaintain({
        ...pointsToMaintain,
        error: error.message,
        loading: false,
        requesting: false,
      }),
    );
  }
};

/* temporary disabled SPT-18029
export const fetchLoyaltyPointsToUpgrade = (level: LoyaltyTierName) => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
    loyalty: { pointsToUpgrade },
  } = getState();

  try {
    dispatch(
      setLoyaltyPointsToUpgrade({
        ...pointsToUpgrade,
        loading: isNull(pointsToUpgrade.data.totalPoints),
        requesting: true,
        error: undefined,
      }),
    );

    const endPoint = level === LoyaltyTierName.TITANIUM ? 'points-to-maintain' : 'points-to-upgrade';

    const response = await v3Client.get<{ data: LoyaltyPointsResponse }>(`loyalty/${organization_id}/${endPoint}`);
    if (response?.data) {
      const { deadlineDate, ...data } = response.data;
      dispatch(
        setLoyaltyPointsToUpgrade({
          error: undefined,
          loading: false,
          requesting: false,
          data: {
            ...data,
            endOfCycleDate: moment(deadlineDate).toDate(),
          },
        }),
      );
    } else {
      setLoyaltyPointsToUpgrade({
        ...pointsToUpgrade,
        loading: false,
        requesting: false,
        error: undefined,
      });
    }
  } catch (e) {
    const error = e as Error;
    dispatch(
      setLoyaltyPointsToUpgrade({
        ...pointsToUpgrade,
        error: error.message,
        loading: false,
        requesting: false,
      }),
    );
  }
};
*/

export const fetchLoyaltyPointsInProgress = (level: LoyaltyTierName) => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
    loyalty: {
      info: {
        cycle: { cycleNumber },
      },
      pointsToMaintain,
      pointsToUpgrade,
      pointsInProgress,
    },
  } = getState();

  try {
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_IN_PROGRESS,
      payload: {
        ...pointsInProgress,
        loading: isNull(pointsInProgress.data.totalPoints),
        requesting: true,
        error: undefined,
        data: {
          ...pointsInProgress.data,
          cycle: cycleNumber,
        },
      },
    });

    const buildData = ({ deadlineDate, ...data }: LoyaltyPointsResponse) => ({
      ...data,
      endOfCycleDate: moment(deadlineDate).toDate(),
    });

    const dispatchInProgress = (type: 'maintain' | 'upgrade', data: LoyaltyPointsResponse, isMaxTier = false) => {
      const pointsData = buildData(data);
      dispatch({
        type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_IN_PROGRESS,
        payload: {
          ...pointsInProgress,
          type,
          isMaxTier,
          error: undefined,
          loading: false,
          data: pointsData,
        },
      });
      if (type === 'maintain') {
        dispatch(
          setLoyaltyPointsToMaintain({
            ...pointsToMaintain,
            error: undefined,
            loading: false,
            requesting: false,
            data: {
              ...pointsToMaintain.data,
              ...pointsData,
            },
          }),
        );
      } else {
        dispatch(
          setLoyaltyPointsToUpgrade({
            ...pointsToUpgrade,
            error: undefined,
            loading: false,
            requesting: false,
            data: {
              ...pointsToUpgrade.data,
              ...pointsData,
            },
          }),
        );
      }
    };

    const getPoints = async (type: 'maintain' | 'upgrade'): Promise<LoyaltyPointsResponse> => {
      const result = await v3Client.get<{ data: LoyaltyPointsResponse }>(
        `loyalty/${organization_id}/points-to-${type}`,
      );
      if (!result) throw new Error(`result points-to-${type} is undefined`);
      return result.data;
    };

    if (level === LoyaltyTierName.TITANIUM) {
      const maintainData = !isNull(pointsToMaintain.data.totalPoints)
        ? pointsToMaintain.data
        : await getPoints('maintain');
      dispatchInProgress('maintain', maintainData, true);
    } else if (cycleNumber <= 1) {
      dispatchInProgress('upgrade', await getPoints('upgrade'));
    } else {
      const maintainData = !isNull(pointsToMaintain.data.totalPoints)
        ? pointsToMaintain.data
        : await getPoints('maintain');
      dispatch(
        setLoyaltyPointsToMaintain({
          ...pointsToMaintain,
          error: undefined,
          loading: false,
          requesting: false,
          data: buildData(maintainData),
        }),
      );
      if (maintainData.pointsLeft <= 0) {
        dispatchInProgress('upgrade', await getPoints('upgrade'));
      } else {
        dispatchInProgress('maintain', maintainData);
      }
    }
  } catch (e) {
    const error = e as Error;
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_POINTS_IN_PROGRESS,
      payload: {
        ...pointsInProgress,
        error: error.message,
        loading: false,
        requesting: false,
      },
    });
  }
};

export const fetchLoyaltyMissionsBanner = (pharmacyId) => async (dispatch) => {
  try {
    const result = await v3Client.get<{ data: string[] }>(`loyalty/${pharmacyId}/missions/banner`);
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_BANNER,
      payload: result?.data ?? [],
    });
  } catch (e) {
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_BANNER,
      payload: [],
    });
  }
};
export const getTotalLoyaltyActiveMissions = (pharmacyId?: number) => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
  } = getState();

  let total = 0;
  try {
    pharmacyId = pharmacyId ?? organization_id;
    const result = await v3Client.get<{ data: number }>(`loyalty/${pharmacyId}/missions/total`);
    total = result?.data ?? 0;
  } finally {
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_TOTAL_ACTIVE_MISSIONS,
      payload: total,
    });
  }
};

interface loyaltyMissionsListParams {
  status: LoyaltyMissionStatus;
  reload?: boolean;
  page?: number;
  page_size?: number;
  sort_by?: string;
  order_by?: string;
  isFeatured?: boolean;
  getTotalActiveMissions?: boolean;
}

export const fetchLoyaltyMissionsList = (params: loyaltyMissionsListParams) => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
    loyalty: { missions },
  } = getState();

  try {
    const { status, page = 1, isFeatured, page_size = 10, order_by = 'created_at', sort_by = 'desc' } = params;

    const isInit = page === 1;

    if (!isFeatured) {
      dispatch(fetchLoyaltyMissionsBanner(organization_id));
    }

    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_LIST,
      payload: {
        ...missions,
        error: undefined,
        loading: !!isInit,
      },
    });

    const result = await v3Client.get(`loyalty/${organization_id}/missions`, {
      status,
      page,
      sort_by,
      order_by,
      page_size: isFeatured ? 3 : page_size,
      is_featured: isFeatured,
      _timestamp: new Date().getTime(),
    });

    if (result) {
      const data = isInit ? result.data : [...missions.data, ...result.data];
      dispatch({
        type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_LIST,
        payload: {
          ...missions,
          data,
          loading: false,
          error: undefined,
          meta: result.meta,
          status: params.status,
        },
      });
    } else {
      dispatch({
        type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_LIST,
        payload: {
          ...missions,
          loading: false,
          error: undefined,
          status: params.status,
        },
      });
    }
  } catch (e) {
    const error = e as Error;
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSIONS_LIST,
      payload: {
        ...missions,
        error: error.message,
        loading: false,
        status: params.status,
      },
    });
  }
};

export const selectLoyaltyMission = (data: LoyaltyMission) => async (dispatch) => {
  dispatch({
    type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSION_INFO,
    payload: {
      error: undefined,
      loading: true,
      data: {
        ...data,
        tnc: [],
        levels: [],
      },
    },
  });
};

export const getLoyaltyMissionInfo = (id: number) => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
    loyalty: { missionInfo },
  } = getState();

  try {
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSION_INFO,
      payload: {
        ...missionInfo,
        error: undefined,
        loading: true,
      },
    });

    const result = await v3Client.get<{ data: LoyaltyMissionInfo }>(`loyalty/${organization_id}/missions/${id}`);
    if (!result?.data) {
      throw new Error('Mission does not exist');
    }

    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSION_INFO,
      payload: {
        data: result.data,
        loading: false,
      },
    });
  } catch (e) {
    const error = e as any;
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_MISSION_INFO,
      payload: {
        data: undefined,
        error: error.response?.data.error ?? error.message,
        loading: false,
      },
    });
  }
};

export const getLoyaltyCompletedMissions = () => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
    loyalty: { completedMissions },
  } = getState();

  try {
    if (completedMissions.length) return;

    const result = await v3Client.get<{ data: LoyaltyMissionCompleted[] }>(
      `loyalty/${organization_id}/missions/completed`,
      {
        page_size: 3,
        order_by: 'completed_at',
        sort_by: 'asc',
      },
    );
    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_COMPLETED_MISSIONS,
      payload: result?.data ?? [],
    });
  } catch (e) {
    // error get completed missions
  }
};

export const notifyLoyaltyCompletedMission = (trackIds: number[]) => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
    loyalty: { completedMissions },
  } = getState();

  try {
    await v3Client.put(`loyalty/${organization_id}/missions/completed`, {
      trackIds,
    });

    const missions = [...completedMissions];
    for (let i = 0; i < trackIds.length; i += 1) {
      const index = missions.findIndex((item) => item.trackId === trackIds[i]);
      if (index > -1) {
        missions.splice(index, 1);
      }
    }

    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_COMPLETED_MISSIONS,
      payload: missions,
    });
  } catch (e) {
    // error notify completed missions
  }
};

export const setLoyaltyWelcomeMission = () => async (dispatch, getState) => {
  const {
    auth: {
      coreUser: { organization_id },
    },
  } = getState();

  try {
    await v3Client.put(`loyalty/${organization_id}/missions/notified`);

    dispatch({
      type: LoyaltyProgramActionTypes.SET_LOYALTY_CONFIG,
      payload: {
        displayWelcomeMission: false,
      },
    });
  } catch (e) {
    // error notify mission
  }
};
