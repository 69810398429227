import * as React from 'react';
import styled from 'styled-components';
import Grid, { GridProps } from '@material-ui/core/Grid';

export interface Props {
  className?: string;
}

const CenteredGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

const CenteredItem: React.FC<React.PropsWithChildren<GridProps>> = ({ children, className }) => (
  <CenteredGrid item className={className}>
    {children}
  </CenteredGrid>
);

export default CenteredItem;
