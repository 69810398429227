import React from 'react';

export enum SuggestionBoldStyling {
  BOLD_QUERY = 'bold_query',
  BOLD_NONQUERY = 'bold_nonquery',
}

export const getStyledProductName = (
  productName: string,
  inputValue: string,
  isSectionGeneral: boolean,
  boldType = SuggestionBoldStyling.BOLD_QUERY,
): React.ReactElement => {
  let styledProductName = <>{productName}</>;
  const startIndex = productName.toUpperCase().indexOf(inputValue.toUpperCase());

  if (isSectionGeneral && startIndex !== -1) {
    const endIndex = startIndex + inputValue.length;
    const preQuery = productName.slice(0, startIndex);
    const actualQuery = productName.slice(startIndex, endIndex);
    const postQuery = productName.slice(endIndex, productName.length);

    switch (boldType) {
      case SuggestionBoldStyling.BOLD_NONQUERY: {
        styledProductName = (
          <>
            <b>{preQuery}</b>
            {actualQuery}
            <b>{postQuery}</b>
          </>
        );
        break;
      }
      case SuggestionBoldStyling.BOLD_QUERY:
      default: {
        styledProductName = (
          <>
            {preQuery}
            <b>{actualQuery}</b>
            {postQuery}
          </>
        );
      }
    }
  }
  return styledProductName;
};
