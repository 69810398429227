import { Action } from 'redux';

import { recordException } from 'utils/Reporting/Sentry';

import { swipeRxPt } from 'services';
import { trackClearCart } from 'utils/Analytics/Segment';
import { DEFAULT_MARKET_MINIMUM_INVOICE_AMOUNT } from 'utils/constants';
import { v3Client } from 'utils/Client/v3';
import { ProductAnalyticProps } from 'hooks/useAddProductAnalytics';
import SwipeRxProcurement from '../../../utils/Warp/SwipeRxProcurement';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { State } from '../../index';
import { CartProducts, DistributorCarts, OrderSummary, ServingDistributorProduct } from '.';
import { Actions as GlobalActions, showError } from '../Global/actions';
import { logEvent } from '../../../utils/Analytics';
import { incrementProductQuantity, updateCartItem, UpdateCartItemType } from '../Counter/actions';

/**
 * Action Types
 */
export interface UpdateCartAction extends Action {
  type: constants.UPDATE_CART;
  items: CartProducts;
  distributors?: any;
  order_summary?: any;
}

export interface FailCartAction extends Action {
  type: constants.FAIL_CART;
  error: string;
}

export interface GetCartAction extends Action {
  type: constants.GET_CART;
  items: CartProducts;
  distributors: Array<DistributorCarts>;
  order_summary: OrderSummary;
  alternative: any;
  loading: boolean;
}

export interface GetMinimumInvoiceAmount extends Action {
  type: constants.GET_MINIMUM_INVOICE_AMOUNT;
  distributors: Array<DistributorCarts>;
}

export interface EmptyCartAction extends Action {
  type: constants.EMPTY_BASKET_CART;
  items: Record<string, unknown>;
  distributors?: Array<Record<string, unknown>>;
  order_summary?: Array<Record<string, unknown>>;
}

export interface CartUpdatedAction extends Action {
  type: constants.CART_UPDATED;
  items?: CartProducts;
  market_minimum_invoice_amount: number;
}

export interface GettingCartAction extends Action {
  type: constants.GETTING_CART;
  loading: boolean;
}

export interface UpdateServingDistributor extends Action {
  type: constants.UPDATE_SERVING_DISTRIBUTOR;
  payload: { distributors: any[]; order_summary: any };
}

export interface UpdateProductsExceedingLimit extends Action {
  type: constants.UPDATE_PRODUCTS_EXCEEDING_LIMIT;
  payload: { productsExceedingLimit: string[] };
}

export type Actions =
  | GettingCartAction
  | UpdateCartAction
  | EmptyCartAction
  | FailCartAction
  | GetCartAction
  | CartUpdatedAction
  | GlobalActions
  | GetMinimumInvoiceAmount
  | UpdateServingDistributor
  | UpdateProductsExceedingLimit;

/**
 * Actions
 */

export const fetchCart: ThunkActionCreator<Actions> = () => async (dispatch) => {
  try {
    // Fetch cart items
    const items: CartProducts = await swipeRxPt.cart.getAll();
    // Dispatch the items
    dispatch({ type: constants.UPDATE_CART, items });
  } catch (error) {
    recordException(error, 'fetchCart');
    console.error(error.message);
    // Dispatch the error
    dispatch({ type: constants.FAIL_CART, error: error.message });
    dispatch(showError(error.message));
  }
};

export const addToCart: ThunkActionCreator<Actions, State> = (items: CartProducts) => async (dispatch, getState) => {
  try {
    // Get state
    const state = getState();

    // Extend cart items
    const cartItems = { ...state.cart.items, ...items };

    // Remove items with no quantity
    /* eslint-disable no-restricted-syntax */
    for (const [productName, packages] of Object.entries<any>(cartItems)) {
      // Get cart product
      const product = { ...cartItems[productName] };

      // Iterate through each package
      for (const [id, $package] of Object.entries<any>(packages)) {
        // Delete package if quantity is zero
        if ($package.quantity === 0) delete product[id];
      }

      // If product doesn't have packages, delete it
      // Otherwise, update the cart items
      if (Object.keys(product).length === 0) delete cartItems[productName];
      else cartItems[productName] = product;
    }

    // Dispatch the updated items
    dispatch({ type: constants.UPDATE_CART, items: cartItems });

    for (const [productName, packages] of Object.entries(items)) {
      for (const [id, $package] of Object.entries(packages)) {
        // Firebase log
        logEvent('add_to_cart', {
          quantity: $package.quantity,
          item_name: productName,
          item_id: id,
          item_location_id: 1,
        });
      }
    }

    // Update cart
    await SwipeRxProcurement.Function.run('update-cart', {
      items: cartItems,
    });
  } catch (error) {
    recordException(error, 'addToCart', { items });
    console.error(error.message);
    // Dispatch the error
    dispatch({ type: constants.FAIL_CART, error: error.message });
    dispatch(showError(error.message));
  }
};

export const updateServingDistributor = (product: ServingDistributorProduct) => (dispatch, getState) => {
  const { distributorId, productId, netPrice, quantity } = product;

  const {
    cart: { distributors, order_summary },
  } = getState();

  const updatedDistributors = [...distributors];
  const updatedOrderSummary = { ...order_summary };

  const distributorIndex = updatedDistributors.findIndex((distributor) => distributor.id === distributorId);

  if (distributorIndex === -1) return;

  const productIndex = updatedDistributors[distributorIndex].products.findIndex((product) => product.id === productId);

  if (productIndex === -1) return;

  const OrderIndex = updatedOrderSummary.products.findIndex((product) => product.id === productId);

  // update serving distributor
  const servingDistributor = updatedDistributors[distributorIndex];
  const totalAmount = netPrice * quantity;

  servingDistributor.total_net_price_before_tax -= totalAmount;
  servingDistributor.products.splice(productIndex, 1);
  updatedOrderSummary.products.splice(OrderIndex, 1);
  updatedOrderSummary.total_net_price_before_tax -= totalAmount;

  if (servingDistributor.products.length === 0) {
    delete updatedDistributors[distributorIndex];
  }

  dispatch({
    type: constants.UPDATE_SERVING_DISTRIBUTOR,
    payload: {
      distributors: updatedDistributors,
      order_summary: updatedOrderSummary,
    },
  });
};

export const getMinimumInvoiceAmount: ThunkActionCreator<Actions, State> =
  (options = { flag: 'product' }) =>
  async (dispatch) => {
    try {
      const results: any = await swipeRxPt.cart.getAll({
        flag: options.flag,
        distributor: options.distributor,
      });

      // Dispatch the items
      dispatch({
        type: constants.GET_CART,
        items: results.carts,
        distributors: results.distributors,
        order_summary: results.order_summary,
        alternative: results.alternative,
        loading: false,
      });
    } catch (error) {
      recordException(error, 'getMinimumInvoiceAmount', { options });
      dispatch({ type: constants.FAIL_CART, error: error.message });
      dispatch(showError(error.message));
    }
  };

export const updateCart: ThunkActionCreator<Actions, State> = (items: CartProducts) => async (dispatch, getState) => {
  try {
    const state = getState();

    // Extend cart items
    const cartItems = { ...state.cart.items, ...items };

    // Remove items with no quantity
    for (const [productName, packages] of Object.entries<any>(cartItems)) {
      // Get cart product
      const product = { ...cartItems[productName] };

      // Iterate through each package
      for (const [id, $package] of Object.entries<any>(packages)) {
        // Delete package if quantity is zero
        if ($package.quantity === 0) delete product[id];
      }

      // If product doesn't have packages, delete it
      // Otherwise, update the cart items
      if (Object.keys(product).length === 0) delete cartItems[productName];
      else cartItems[productName] = product;
    }

    // Dispatch the updated items
    dispatch({ type: constants.UPDATE_CART, items: cartItems });
    // Update cart
    await SwipeRxProcurement.Function.run('update-cart', {
      items: cartItems,
    });
  } catch (error) {
    // Dispatch the error
    recordException(error, 'updateCart', { items });
    dispatch({ type: constants.FAIL_CART, error: error.message });
  }
};

export const emptyCart: ThunkActionCreator<Actions> = (id: number) => async (dispatch) => {
  try {
    await swipeRxPt.cart.clear();

    dispatch({ type: constants.EMPTY_BASKET_CART, items: {} });

    const params = { pharmacy_id: id };

    trackClearCart(params);
  } catch (error) {
    recordException(error, 'emptyCart');
    // Dispatch the error
    dispatch({ type: constants.FAIL_CART, error: error.message });
  }
};

export const removeFromCart: ThunkActionCreator<Actions, State> =
  (items: CartProducts) => async (dispatch, getState) => {
    try {
      const state = getState();
      const marketMinimumInvoiceAmount =
        state.config.market.orders?.minimum_invoice_amount || DEFAULT_MARKET_MINIMUM_INVOICE_AMOUNT;

      // Extend cart items
      const cartItems = { ...state.cart.items };

      for (const [productName, packages] of Object.entries(items)) {
        for (const [id, $package] of Object.entries(packages)) {
          // Firebase log
          logEvent('remove_from_cart', {
            quantity: $package.quantity,
            item_name: productName,
            item_id: id,
          });
        }
      }

      // Remove items with no quantity
      for (const [productName, packages] of Object.entries(items)) {
        // Get cart product
        const product = { ...cartItems[productName] };

        // Iterate through each package
        for (const id of Object.keys(packages)) {
          // Delete package
          delete product[id];
        }

        // If product doesn't have packages, delete it
        if (Object.keys(product).length === 0) delete cartItems[productName];
      }

      // Dispatch the updated items
      dispatch({ type: constants.UPDATE_CART, items: cartItems });
      dispatch({
        type: constants.CART_UPDATED,
        items: cartItems,
        market_minimum_invoice_amount: marketMinimumInvoiceAmount,
      });

      // Update cart
      await SwipeRxProcurement.Function.run('update-cart', {
        items: cartItems,
      });
    } catch (error) {
      recordException(error, 'removeFromCart', { items });
      console.error(error.message);
      // Dispatch the error
      dispatch({ type: constants.FAIL_CART, error: error.message });
    }
  };

export const trackItemMarketingProps: ThunkActionCreator<Actions, State> =
  (item: ProductAnalyticProps) => async (_dispatch, getState) => {
    const {
      auth: { coreUser },
    } = getState();

    const productWithMarketingData = {
      id: item.id,
      name: item.name,
      packaging: item.packaging,
      molecule: item.molecule,
      sku_code: item.sku_code,
      selling_price: item.selling_price,
      distributor_product_id: item.distributor_product_id,
      marketing_id: item.marketing_id ?? undefined,
      marketing_name: item.marketing_name ?? undefined,
      utm_source: item.utm_source ?? undefined,
      utm_medium: item.utm_medium ?? undefined,
    };

    if (!coreUser) {
      throw new Error('No core user');
    }

    try {
      await swipeRxPt.cart.trackItem(coreUser.organization_id, productWithMarketingData);
    } catch (error) {
      recordException(error, 'trackItemMarketingProps');
    }
  };

export const removeItemFromCartMarketing: ThunkActionCreator<Actions, State> =
  (productId: number) => async (_dispatch, getState) => {
    const {
      auth: { coreUser },
    } = getState();

    if (!coreUser) {
      throw new Error('No core user');
    }

    try {
      await swipeRxPt.cart.removeItemTracking(coreUser.organization_id, productId);
    } catch (error) {
      recordException(error, 'removeItemFromCartMarketing');
    }
  };

export const addToCartFromCausalFoundryNudge: ThunkActionCreator<Actions, State> =
  (productId: string) => async (dispatch) => {
    // get product based on id
    const result = await v3Client.get('products/search', {
      product_ids: productId,
    });
    if (!result || result.data.length === 0) {
      // console.info('[causalfoundry.addtocart] no data found for id', productId);
      return;
    }
    // type set to any, because otherwise linter will give error because it should be void
    // although the dispatch will return CartProduct object.
    const product: any = dispatch(incrementProductQuantity(result.data[0]));

    // update cart item
    await dispatch(updateCartItem(product, UpdateCartItemType.CREATE_OR_UPDATE));
  };

export const updateProductsExceedingLimit: ThunkActionCreator<Action> =
  (productNames: string[]) => async (dispatch) => {
    dispatch({
      type: constants.UPDATE_PRODUCTS_EXCEEDING_LIMIT,
      payload: { productsExceedingLimit: productNames },
    });
  };
