import * as Redux from 'redux';
import { CreditLimitRequest } from 'services/swipe-rx-pt/resources/credit-request/interfaces/credit-limit-request.interface';
import { Actions } from './actions';
import * as constants from './constants';

export interface State {
  request?: CreditLimitRequest | null;
  loading: boolean;
}

const InitialState: State = {
  request: undefined,
  loading: false,
};

export const CreditRequestReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_CREDIT_REQUEST:
    case constants.RESET_CREDIT_REQUEST:
    case constants.READ_CREDIT_REQUEST:
    case constants.CREATE_CREDIT_REQUEST:
      return {
        ...state,
        loading: false,
        request: action.request,
      };
    case constants.SET_CREDIT_REQUEST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default CreditRequestReducer;
