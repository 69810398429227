import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { State as StoreState } from 'store';

import {
  getEventPageIdentity,
  LocationAnalyticState,
  SearchDataAnalyticsInfoType,
  SegmentPropsBuilder,
  trackSearch,
} from 'utils/Analytics/Segment';

interface Props {
  trackSearchSuggestion: (
    inputValue?: string,
    category_ids?: string[],
    isGeneralSearch?: boolean,
    search_section?: string,
  ) => void;
}

export const useTrackSuggestionAnalytic = (): Props => {
  const location = useLocation();
  const match = useRouteMatch();

  const {
    searchSuggestions,
    auth,
    config,
    search: { categories = [] },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const { coreUser } = auth || {};
  const { marketId } = config || {};

  const locationState = location?.state as LocationAnalyticState;

  const eventPageIdentity = getEventPageIdentity(match, location?.search);

  const trackSearchSuggestion = (
    inputValue?: string,
    category_ids?: string[],
    isGeneralSearch = false,
    search_section?: string,
  ): void => {
    // TODO: supporting SPT-16792: for now using old behavior - sends all categories
    const handleCategories = isGeneralSearch
      ? categories
          ?.filter((category) => (category_ids?.length ? category_ids.includes(String(category?.id || '')) : category))
          .map((category) => category.name)
      : [];

    const modifiedLocation = {
      ...location,
      state: {
        ...locationState,
        initial_page: eventPageIdentity,
        search_section,
      },
    };
    let currentSuggestion = searchSuggestions?.suggestions;

    if (search_section) {
      currentSuggestion = searchSuggestions?.suggestions.filter((data) => data.id === search_section);
    }

    const trackSearchPayload = SegmentPropsBuilder.buildSearchAnalyticsProps(
      modifiedLocation,
      handleCategories,
      currentSuggestion?.[0],
      inputValue as string,
      coreUser?.organization_id as number,
      marketId,
      SearchDataAnalyticsInfoType.SEARCH_SUGGESTIONS,
      null,
    );
    if (trackSearchPayload) {
      trackSearch(trackSearchPayload);
    }
  };
  return {
    trackSearchSuggestion,
  };
};
