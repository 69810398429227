import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Close } from '@material-ui/icons';

import * as colors from '../../styles/colors';
import { TranslateProps } from '../../../utils/Localization/types';
import Layout from '../../views/Layout';

const ActivationContainer = styled.div`
  height: 100%;
`;

export interface StateProps {
  coreUser: any;
}

class ActivationPage extends React.Component<StateProps & TranslateProps> {
  close = (): void => {
    window.location.href = 'swrx://exit';
  };

  render(): React.ReactNode {
    return (
      <Layout full background={colors.WHITE}>
        <ActivationContainer>
          <Grid container direction="column" justify="center" alignItems="center" style={{ height: '100%' }}>
            <div style={{ background: colors.PRIMARY_COLOR, width: '100%', padding: '8px', textAlign: 'right' }}>
              <Button onClick={this.close} style={{ color: '#fff', justifyContent: 'center' }}>
                <Close style={{ color: 'fff', marginTop: 0 }} />
              </Button>
            </div>
            <iframe
              title="activation-form-iframe"
              // eslint-disable-next-line react/destructuring-assignment
              src={`${process.env.REACT_APP_ACTIVATIONFORM}?uid=${this.props.coreUser.user_id}`}
              style={{
                flex: 1,
                width: '100%',
                border: 'none',
                position: 'relative',
                overflow: 'hidden',
                top: 0,
                bottom: 0,
              }}
            />
          </Grid>
        </ActivationContainer>
      </Layout>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = ({ auth }) => ({
  coreUser: auth.coreUser,
});

export default connect(mapStateToProps)(ActivationPage);
