import { useState } from 'react';
import { flushSync } from 'react-dom';
import { useDispatch } from 'react-redux';
import { setProductImpression } from 'store/reducers/ProductImpression/actions';

import { useDebouncedCallback } from 'use-debounce';
import {
  BasePageTracker,
  IProductPayloadTrimmed,
  ImpressionType,
  ProductListPageAnalyticsProps,
  SearchSectionState,
  SegmentPropsBuilder,
  trackImpression,
} from 'utils/Analytics/Segment';

export interface IPropsCollector {
  productImpression?: IProductPayloadTrimmed;
  query?: string | string[] | any;
  impressionFrom: BasePageTracker;
  campaignValue?: ProductListPageAnalyticsProps;
  customImpressionTime?: number;
  locationState?: SearchSectionState;
  impressionType?: ImpressionType;
}

interface IUseBatchImpression {
  impressionCollector: (params: IPropsCollector) => void;
}

export const useBatchImpression = (): IUseBatchImpression => {
  const [productImpressions, setProductImpressions] = useState<IProductPayloadTrimmed[] | any[]>([]);
  const dispatch = useDispatch();

  const impressionSender = useDebouncedCallback((impressionLength, params: Partial<IPropsCollector>): void => {
    const { campaignValue, customImpressionTime, impressionFrom, query, locationState, impressionType } = params || {};

    if (impressionLength === productImpressions.length) {
      const payloadImpression = SegmentPropsBuilder.buildImpressionTrackingProps(
        productImpressions,
        query,
        customImpressionTime,
        impressionFrom,
        impressionType,
        campaignValue,
        locationState,
      );
      trackImpression(payloadImpression);
      setProductImpressions([]);
      dispatch(
        setProductImpression({
          displayType: impressionType,
          productIds: payloadImpression.product_ids,
          marketingId: payloadImpression?.marketing_id,
        }),
      );
    }
  }, 500);

  const impressionCollector = (params: IPropsCollector): void => {
    const { productImpression, ...restParams } = params || {};
    const combinedImpression = [...productImpressions, productImpression];
    flushSync(() => {
      setProductImpressions((prev) => [...prev, productImpression]);
    });
    impressionSender(combinedImpression.length, restParams);
  };

  return {
    impressionCollector,
  };
};
