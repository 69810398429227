import React, { useState } from 'react';
import ShowIf from 'components/views/ShowIf';

import { Chip } from '@material-ui/core';
import { ReactComponent as RemoveIcon } from 'images/ic_remove.svg';

import { SectionHeader } from './SectionHeader.component';
import { S } from './search-sections.style';
import { IBaseSection, IItemConfig } from '../interface';
import { ChipSectionExpanded } from '../expanded-section/ChipSectionExpanded.component';

interface IChipSection extends IBaseSection {
  sectionId: string;
  onDelete?: (param?: any) => void;
  onSelect: (selectedItem) => void;
  items: IItemConfig;
  highlightedIndex: number;
}

export const ChipSection: React.FC<React.PropsWithChildren<IChipSection>> = (props) => {
  const {
    onDelete,
    sectionId,
    expanded = false,
    data = [],
    title,
    subTitle: subtitle,
    t,
    additionalExpandFunction,
    expandable,
    limit = 5,
    expandSection = '',
    items,
    highlightedIndex,
    onExpand,
    onSelect,
  } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const dataDisplay = isExpanded ? data : data.slice(0, limit);

  const chipWrapperProps = {
    flexWrap: 'wrap !important',
    lineHeight: '2em',
    height: '4.3em',
    overflow: 'hidden',
  };

  const toggle = (): void => {
    const newExpandedValue = !isExpanded;
    setIsExpanded(newExpandedValue);
    if (onExpand && newExpandedValue) {
      onExpand();
    }

    if (additionalExpandFunction) {
      const titleExpand = isExpanded ? '' : title;
      additionalExpandFunction(titleExpand);
    }
  };

  return (
    <ShowIf condition={!expandSection || expandSection === title}>
      <S.Section id={`tour__search__${title}`}>
        <SectionHeader
          t={t}
          isExpanded={isExpanded}
          title={title}
          toggle={toggle}
          subTitle={subtitle}
          expandable={expandable}
        />

        <S.ChipsWrapper container {...(!isExpanded ? { ...chipWrapperProps } : { width: '100%' })}>
          <ShowIf condition={!isExpanded}>
            {dataDisplay.map((product) => {
              items.itemIndex += 1;
              const index = items.itemIndex;

              const handleOnClick = (): void => {
                onSelect({
                  ...product,
                  sectionId,
                  isActive: highlightedIndex === index,
                });
              };

              return (
                <>
                  <Chip
                    onClick={handleOnClick}
                    data-testid={`searched-product-${product?.id}`}
                    style={{ margin: '4px 8px 4px 4px' }}
                    deleteIcon={<RemoveIcon style={{ width: 20, height: 20 }} color="#7676768A" paintOrder="stroke" />}
                    onDelete={onDelete ? () => onDelete(product) : undefined}
                    label={product.name}
                  />
                </>
              );
            })}
          </ShowIf>
          <ShowIf condition={isExpanded}>
            <ChipSectionExpanded
              title={title}
              onDelete={onDelete}
              dataDisplay={dataDisplay}
              sectionId={sectionId}
              highlightedIndex={highlightedIndex}
              items={items}
              additionalExpandFunction={additionalExpandFunction}
              onSelect={onSelect}
            />
          </ShowIf>
        </S.ChipsWrapper>
      </S.Section>
    </ShowIf>
  );
};

ChipSection.defaultProps = {
  onDelete: undefined,
};
