import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BasePageTracker, pageTracker } from 'utils/Analytics/Segment';
import { usePageIdentity } from 'hooks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State as StoreState } from 'store';
import { PtLocationState } from 'routes';
import { setMenuDrawer } from 'store/reducers/SearchSuggestions/actions';
import { getMarketingFeatures } from 'store/reducers/MarketingFeatures/actions';
import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';

interface IOptions {
  trackEventPage?: boolean;
  callMarketingFeatures?: boolean;
}
export interface IProps {
  isMenuOpen: boolean;
  setMenuOpen: (openMenu: boolean, options?: IOptions) => void;
}

export const useSearchMenuHandler = (initialState: boolean): IProps => {
  const location = useLocation<PtLocationState>();

  const { base_page } = usePageIdentity();
  const dispatch = useDispatch();

  const { coreUser, menuDrawerOpen: isMenuOpen } = useSelector(
    (state: StoreState) => ({ ...state.auth, ...state.searchSuggestions }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(setMenuDrawer(initialState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const trackOpenDrawer = [BasePageTracker.ALPHABETICAL, BasePageTracker.HOME, BasePageTracker.PRODUCT_DETAIL].includes(
    base_page,
  );

  const setMenuOpen = (openMenu: boolean, options?: IOptions): void => {
    if (isMenuOpen === openMenu) return;
    const { trackEventPage = false, callMarketingFeatures = false } = options || {};

    dispatch(setMenuDrawer(openMenu));
    if (openMenu && !!callMarketingFeatures) {
      dispatch(
        getMarketingFeatures({
          featureType: MarketingFeaturesType.GENERAL,
        }),
      );
    }
    if (!trackOpenDrawer) return;
    if (!trackEventPage) return;
    if (!coreUser) return;

    pageTracker.trackPageView(location, coreUser, openMenu ? '/search' : undefined);
  };

  return {
    isMenuOpen,
    setMenuOpen,
  };
};
