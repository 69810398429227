import React from 'react';
import { CircularProgress, Box, Typography } from '@material-ui/core';

import { translate } from 'react-i18next';

import * as colors from 'components/styles/colors';
import Tax from 'utils/Tax';
import { TranslateProps } from 'utils/Localization';
import { toCurrency, calculateAmountAfterTax } from 'utils/Format';

interface Props extends TranslateProps {
  amount: number;
  item: number;
}

const ItemSummaryBase: React.FC<React.PropsWithChildren<Props>> = ({ t, item, amount }) => (
  <div style={{ width: '100%' }}>
    <Box display="flex" style={{ width: '100%' }} alignItems="flex-start">
      <Box flexGrow={1}>
        <Box flexDirection="column">
          <Typography style={{ fontSize: '14px' }}>{t('orderTotal')}</Typography>
          <Typography style={{ fontSize: '10px', color: colors.BLUISH_GRAY_LIGHT }}>
            {t('includesVat', { taxRate: `${Math.round(Tax.rate * 100)}%` })} ({toCurrency(amount * Tax.rate)})
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography style={{ fontSize: '15px', color: colors.BLUE }}>
          {item > 0 ? (
            <strong>{toCurrency(calculateAmountAfterTax(amount, Tax.rate))}</strong>
          ) : (
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress size="1rem" style={{ color: colors.BLUE }} />
            </div>
          )}
        </Typography>
      </Box>
    </Box>
  </div>
);

export const ItemSummary = translate('pricesPage')(ItemSummaryBase);
