import * as React from 'react';
import styled from 'styled-components';
import { Grid, Paper, IconButton, LinearProgress } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelButton } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Input from '@material-ui/core/Input';
import { DEFAULT_MAX_SEARCH_INPUT_LENGTH } from 'utils/constants';
import * as colors from '../../styles/colors';
import ShowIf from '../../views/ShowIf';
import CenteredItem from '../../views/CenteredItem';
import { SEARCH_INPUT_CLASS } from './constant';

export interface MapStateProps {
  maxSearchInputLength?: number;
}

export interface Props {
  full?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onClick?: () => void;
  onBack?: () => void;
  loading?: boolean;
  onCancel?: () => void;
  autoPrefill?: string;
  getInputProps?: (e: any) => any;
  onFocus?: () => void;
  onKeyDown?: (e: any) => void;
  clearSelection?: () => void;
  autoFocus?: boolean;
  hideBackButton?: boolean;
  value?: string;
  inputRef?: any;
}

export const SEARCH_INPUT_CANCEL_BUTTON_ID = 'search-input-cancel-button';

const SearchContainer = styled(Grid)`
  z-index: 3;

  && {
    flex: 1;
    padding: 0;
    display: inline-flex;
    width: 100%;
  }

  .box {
    flex: 1;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    border-radius: 20px;
    border: 1px solid #e0dcf4;
    background-color: ${colors.WHITE};
    box-shadow: none;

    .back-button {
      color: #000;
      padding: 0;
      font-size: 12px;
    }

    .content {
      flex: 1;

      .text {
        font-size: 12px;
        color: ${colors.TEXT_LIGHT};
        padding: 4px;
      }

      .field {
        input {
          font-size: 14px;
          color: ${colors.TEXT_LIGHT};
          padding: 2px;
        }
      }
    }

    .search-icon {
      font-size: 18px;
      padding-left: 2px;
      padding-right: 4px;
      color: ${colors.TEXT_LIGHT};
    }

    .cancel-button {
      color: #ffffff6e;
      padding: 0;
    }
  }

  &&.full {
    padding: 0;

    .box {
      .content {
        .text,
        .field {
          font-size: 12px;
        }
      }

      .search-icon {
        font-size: 18px;
        padding-left: 2px;
        padding-right: 4px;
        color: ${colors.TEXT_LIGHT};
      }
    }
  }
`;

class Search extends React.Component<Props & MapStateProps> {
  shouldBeFull = (): boolean => {
    const { full } = this.props;
    return typeof full === 'undefined' ? false : full;
  };

  shouldBeReadOnly = (): boolean => {
    const { readOnly } = this.props;
    return typeof readOnly === 'undefined' ? false : readOnly;
  };

  shouldBeEditable = (): boolean => !this.shouldBeReadOnly();

  shouldShowBackButton = (): boolean => {
    const { readOnly = false, onBack, hideBackButton } = this.props;
    if (hideBackButton) return false;
    if (!readOnly || !!onBack) return true;
    return false;
  };

  shouldShowLoading = (): boolean => {
    const { loading } = this.props;
    return !!loading;
  };

  handleCancel = (): void => {
    const { onCancel, clearSelection } = this.props;
    if (!!onCancel && !!clearSelection) {
      onCancel();
      clearSelection();
    }
  };

  render(): JSX.Element {
    const {
      onBack,
      onClick,
      placeholder,
      onFocus,
      getInputProps,
      onKeyDown,
      autoFocus = false,
      maxSearchInputLength,
      value,
      inputRef,
    } = this.props;
    const inputP = getInputProps
      ? getInputProps({
          placeholder,
          onKeyDown,
          ...(value === undefined ? {} : { value }),
        })
      : [];
    const hasInputValue = !!value || !!inputP?.value;

    return (
      <>
        <SearchContainer id="tour__searchbox" className={this.shouldBeFull() ? 'full' : ''} item>
          <ShowIf condition={this.shouldShowBackButton()}>
            <IconButton
              data-testid="btn-searchbox-back"
              onClick={onBack}
              style={{
                paddingTop: '0',
                paddingBottom: '0',
                paddingLeft: '0',
                paddingRight: '4px',
              }}
            >
              <ArrowBackIosIcon style={{ fill: colors.TEXT_LIGHT, height: 16, width: 16 }} />
            </IconButton>
          </ShowIf>
          <Paper className="box" square onClick={onClick} id="demo__searchbox">
            <Grid container direction="row" alignItems="center">
              <SearchIcon className="search-icon" />
              <CenteredItem className="content">
                <ShowIf condition={this.shouldBeReadOnly()}>
                  <Input
                    className={`field ${SEARCH_INPUT_CLASS}`}
                    fullWidth
                    placeholder={placeholder}
                    disableUnderline
                    disabled
                    data-testid="search-input"
                  />
                </ShowIf>
                <ShowIf condition={this.shouldBeEditable()}>
                  <Input
                    inputRef={inputRef}
                    className={`field ${SEARCH_INPUT_CLASS}`}
                    autoFocus={autoFocus}
                    onFocus={onFocus}
                    {...inputP}
                    inputProps={{ maxLength: maxSearchInputLength || DEFAULT_MAX_SEARCH_INPUT_LENGTH }}
                    onBlur={(e) => {
                      // NOTE: this should prevent ios/web query param to be deleted
                      e.preventDefault();
                      e.stopPropagation();
                      inputP.onBlur(e);
                    }}
                    style={{ width: '100%' }}
                    disableUnderline
                    // ref={inputRef}
                  />
                  <ShowIf condition={hasInputValue}>
                    <IconButton
                      style={{ color: colors.BLUISH_GRAY_LIGHT }}
                      className="cancel-button"
                      onClick={this.handleCancel}
                      data-testid="cancel-btn"
                      id={SEARCH_INPUT_CANCEL_BUTTON_ID}
                    >
                      <CancelButton />
                    </IconButton>
                  </ShowIf>
                </ShowIf>
              </CenteredItem>
            </Grid>
          </Paper>
        </SearchContainer>
        <ShowIf condition={this.shouldShowLoading()}>
          <LinearProgress style={{ height: '2px' }} variant="indeterminate" />
        </ShowIf>
      </>
    );
  }
}

export default Search;
