import { Grid, BottomNavigationAction } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

import * as colors from 'components/styles/colors';

const Label = styled(Grid)`
  position: relative;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.BLUISH_GRAY_LIGHTER};

  &.bottomNav__label {
    .bottomNav__label__active {
      color: ${colors.PRIMARY_COLOR};
    }
  }
`;

const BottomNavAction = styled(BottomNavigationAction)`
  && {
    max-width: 100%;
    margin: 0px;
    padding: 6px;
    min-width: 60px;
  }
`;

const pulsate = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }`;

const Dot = styled.div`
  position: absolute;
  top: 0;
  right: 13px;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: ${colors.DANGER};
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: ${pulsate} 2s infinite;
`;

export const S = {
  Label,
  BottomNavAction,
  Dot,
};
