import { store } from 'store';
import { PaymentMethods } from 'utils/constants';
import { isCreditLineEnabled } from 'utils/MarketConfig';

export const shouldShowCreditInfo = (): boolean => {
  const { pharmacy } = store.getState();

  const marketHasCreditLineActivated = isCreditLineEnabled();
  const organizationHasCreditFacility = pharmacy.paymentMethod === PaymentMethods.CREDIT_LINE;

  return marketHasCreditLineActivated && organizationHasCreditFacility;
};
