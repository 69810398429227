import React from 'react';

import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Info } from '@material-ui/icons';

import { translate } from 'react-i18next';

import LogoSwipeRX from 'images/logo_swiperx_s.svg';
import VoucherLeftNotch from 'images/voucher-left-notch.svg';
import VoucherRightNotch from 'images/voucher-right-notch.svg';

import ShowIf from 'components/views/ShowIf';

import { IVoucher } from 'services/swipe-rx-pt/resources/voucher/interface/voucher.interface';

import { TranslateProps } from 'utils/Localization/types';
import * as colors from '../../../../../styles/colors';

interface VoucherCardProps extends TranslateProps {
  voucher: IVoucher;
  onClick: () => void;
  onApplyVoucher?: () => void;
  isVoucherLoading: boolean;
}

const VoucherCardBase: React.FC<React.PropsWithChildren<VoucherCardProps>> = (props) => {
  const { voucher, onClick, t, onApplyVoucher, isVoucherLoading } = props;

  const showBadge =
    (!!voucher.available_voucher && voucher.available_voucher > 1) || !!voucher.voucher_type.active_end_date;

  return (
    <>
      <ShowIf condition={!!voucher.available_voucher && voucher.available_voucher > 1}>
        <Card
          elevation={3}
          style={{
            minHeight: 160,
            borderRadius: 20,
            marginBottom: -175,
            marginTop: -15,
            marginLeft: 5,
            marginRight: 5,
          }}
        />
      </ShowIf>
      <Card
        elevation={3}
        onClick={onClick}
        style={{ marginTop: -30, minHeight: 150, borderRadius: 20, paddingLeft: 20, paddingRight: 20 }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid container item xs={6} alignItems="center">
              <img
                src={voucher.voucher_type.photo || LogoSwipeRX}
                alt="voucher brand logo"
                style={{ width: 17, height: 17, objectFit: 'fill', marginRight: 6 }}
              />
              <Typography style={{ fontSize: 10 }}>{voucher.voucher_type.brand || 'SwipeRX'}</Typography>
            </Grid>
            <Grid container item xs={6} justify="flex-end">
              <ShowIf condition={showBadge}>
                <Typography
                  style={{
                    background: colors.PURPLE,
                    color: colors.WHITE,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 2,
                    fontSize: 10,
                    borderRadius: 3,
                  }}
                >
                  {voucher.available_voucher && voucher.available_voucher > 1
                    ? `${voucher.available_voucher}x`
                    : voucher.voucher_type.active_end_date
                    ? t('limitedOffers')
                    : null}
                </Typography>
              </ShowIf>
            </Grid>
          </Grid>

          <Typography
            style={{
              fontSize: 16,
              fontWeight: 700,
              textAlign: 'left',
              marginTop: 10,
              color: 'black',
              alignItems: 'center',
            }}
          >
            {voucher.voucher_type.title}
            <Info style={{ fontSize: 14, color: colors.LIGHT_GRAY, marginLeft: 4 }} />
          </Typography>

          <Typography
            style={{
              textAlign: 'left',
              fontSize: 11,
              maxWidth: '100%',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginTop: 6,
              color: 'black',
              minHeight: 32,
            }}
          >
            {voucher.voucher_type.description}
          </Typography>

          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
              width: 500,
              borderTop: '1px dashed #828282',
              opacity: 0.4,
              marginLeft: -50,
            }}
          />

          <Grid container spacing={1}>
            <Grid container item xs={6} alignItems="center" justify="flex-start">
              <ShowIf condition={!!voucher.voucher_type.active_end_date}>
                <Typography variant="body2" style={{ fontSize: 11 }}>
                  {moment(voucher.voucher_type.active_end_date).diff(moment(), 'days') < 3 ? (
                    <span>
                      Ending soon{' '}
                      <span style={{ color: colors.ORANGE, fontWeight: 700 }}>
                        {moment(voucher.voucher_type.active_end_date).format('DD MMMM')}
                      </span>
                    </span>
                  ) : (
                    <span>
                      Ending on{' '}
                      <span style={{ color: colors.PURPLE, fontWeight: 700 }}>
                        {moment(voucher.voucher_type.active_end_date).format('DD MMM YYYY')}
                      </span>
                    </span>
                  )}
                </Typography>
              </ShowIf>
            </Grid>
            <Grid container item xs={6} justify="flex-end">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (onApplyVoucher) onApplyVoucher();
                }}
                disabled={isVoucherLoading}
                style={{
                  borderRadius: 20,
                  background: colors.PRIMARY_COLOR,
                  color: colors.WHITE,
                  height: 25,
                  fontSize: 10,
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  minWidth: 100,
                }}
              >
                {t('apply')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <>
        <img
          src={VoucherLeftNotch}
          alt="voucher-left-notch"
          style={{ position: 'relative', top: -110, left: '-38vw', width: 45 }}
        />
        <img
          src={VoucherRightNotch}
          alt="voucher-right-notch"
          style={{ position: 'relative', top: -110, right: '-36vw', width: 50 }}
        />
      </>
    </>
  );
};

export const VoucherCard = translate('voucher')(VoucherCardBase);
