/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Grid } from '@material-ui/core';

import { SkeletonLoader } from 'components/common';

import { S } from './orders-history-card.styles';

export const OrdersHistoryCardLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const loaderCount = Array.from({ length: 4 });

  return (
    <>
      {loaderCount.map((_, index) => (
        <S.Card key={index}>
          <S.CardStatusHeader>
            <Grid item className="loading">
              <SkeletonLoader type="sub" />
            </Grid>
          </S.CardStatusHeader>
          <S.CardContainer>
            <S.Row>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={6}>
                  <SkeletonLoader type="text" />
                </Grid>
                <Grid item xs={6} className="align-right">
                  <SkeletonLoader type="text" />
                </Grid>
              </Grid>
            </S.Row>
            <S.CardHeader>
              <SkeletonLoader type="title" width={220} />
            </S.CardHeader>
            <S.Row>
              <Grid item xs={8}>
                <SkeletonLoader type="sub" />
                <SkeletonLoader type="sub" variant="xs" />
              </Grid>
              <Grid item xs={8} className="align-right">
                <SkeletonLoader type="sub" />
                <SkeletonLoader type="sub" variant="xs" />
              </Grid>
            </S.Row>
          </S.CardContainer>
        </S.Card>
      ))}
    </>
  );
};
