import styled from 'styled-components';
import { Grid } from '@material-ui/core';

import { breakpoints } from 'components/styles/breakpoints';

const ImageContainer = styled(Grid)<any>`
  && {
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const Image = styled.img<any>`
  display: block;
  outline: none;
  border: none;
  width: 117px;
  height: 117px;
  background-color: ${(props) => (props.isEmpty ? 'none' : '#E4E7ED')};
  opacity: ${(props) => (props.isStockOut ? 0.5 : 1)};
  margin-top: ${(props) => props.isUnpurchasable && '28px'};

  @media (max-width: ${breakpoints.phoneSmall}) {
    width: 90px;
    height: 90px;
  }
`;

export const S = {
  Image,
  ImageContainer,
};
