import { ItemType, StockStatus } from '@causalfoundry/js-sdk';
import { getProductCategory, getProductSubCategories } from 'components/pages/ProductDetailPage/utils';
import * as React from 'react';
import { Product } from 'store/reducers/Product';
import { getCurrency, getMarketId } from 'utils/MarketConfig';
import { ICfDrugProperties } from '../interfaces';

interface Props {
  product: Product;
  className: string;
  promoId?: number;
}

export const CausalFoundryTrackImpressionDataLog: React.FC<React.PropsWithChildren<Props>> = ({
  product,
  promoId = undefined,
  className,
}) => {
  const { id, net_price, selling_price, remaining_quantity, low_stock_threshold, quantity_threshold } = product;
  const stockStatus =
    remaining_quantity <= quantity_threshold
      ? StockStatus.OutOfStock
      : remaining_quantity <= low_stock_threshold
      ? StockStatus.LowStock
      : StockStatus.InStock;
  const drug: ICfDrugProperties = {
    market_id: getMarketId(),
    id: `${product.id}`,
    name: product.name,
    description: product.description ?? '',
    supplier_name: product.distributor.name,
    supplier_id: `${product.distributor.id}`,
    producer: product.manufacturer,
    packaging: product.packaging,
    active_ingredients: product?.molecule?.split(',')?.map((mol) => mol.trim()),
    otc_or_ethical: getProductCategory(product.categories),
    atc_anatomical_group: getProductSubCategories(product.categories),
  };
  return (
    <>
      <div
        data-log-id={id}
        data-log-quantity={1}
        data-log-promo-id={promoId ?? ''}
        data-log-price={net_price ?? selling_price}
        data-log-currency={getCurrency()} // SDK provides enum/constants CurrencyCode
        data-log-stock-status={stockStatus}
        className={className} // id/class name/container name for the card layout in your code
        data-log-type={ItemType.Drug}
        data-log-drug-catalog-object={JSON.stringify(drug)}
      />
    </>
  );
};

CausalFoundryTrackImpressionDataLog.defaultProps = {
  promoId: undefined,
};
