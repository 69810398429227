import styled from 'styled-components';
import { Typography, IconButton as MuiIconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReceiptBase from '@material-ui/icons/Receipt';
import { TEXT_LIGHT, PRIMARY_COLOR, BLUISH_GRAY } from 'components/styles/colors';
import { breakpoints } from 'components/styles/breakpoints';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Title = styled(Typography).attrs({ className: 'header-content-title' })`
  && {
    font-size: 16px;
    color: ${TEXT_LIGHT};
    font-weight: 800;
    white-space: nowrap;
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 14px;
    }
  }
`;

interface ArrowBackIconProps {
  iconColor?: string;
}
const ArrowBackIcon = styled(ArrowBackIosIcon)<any>`
  && {
    fill: ${({ iconColor }: ArrowBackIconProps) => iconColor || TEXT_LIGHT};
    width: 16px;
    height: 16px;
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      width: 14px;
      height: 14px;
    }
  }
`;

const IconButton = styled(MuiIconButton)`
  && {
    padding: 0 5px 0 0;
  }
`;

const DownloadSummaryLink = styled(Typography)`
  && {
    font-size: 10px;
    color: ${PRIMARY_COLOR};
    font-weight: 800;
    margin-right: 10px;
    text-transform: uppercase;
    z-index: 1;
  }
`;

interface ReceiptProps extends SvgIconProps {
  iconColor?: string;
}
const Receipt = styled(ReceiptBase).attrs({ className: 'header-download-icon' })`
  color: ${({ iconColor }: ReceiptProps) => iconColor || BLUISH_GRAY};
`;

export const S = {
  Title,
  ArrowBackIcon,
  IconButton,
  DownloadSummaryLink,
  Receipt,
};
