import { Action, Dispatch } from 'redux';

import { v3Client } from 'utils/Client/v3';

import * as constants from './constants';
import { IConfig } from './config.type';

export interface FetchMarketConfig extends Action {
  type: constants.FETCH_MARKET_CONFIG;
  payload: IConfig;
}

export interface SetMarketId extends Action {
  type: constants.SET_MARKET_ID;
  payload: string;
}

export interface SetMarketLanguage extends Action {
  type: constants.SET_MARKET_LANGUAGE;
  payload: IConfig['market']['language'];
}

export interface SetMarketLocale extends Action {
  type: constants.SET_MARKET_LOCALE;
  payload: IConfig['market']['locale'];
}

export type Actions = FetchMarketConfig | SetMarketId | SetMarketLanguage | SetMarketLocale;

export const fetchMarketConfig =
  () =>
  async (dispatch: Dispatch<FetchMarketConfig>): Promise<boolean> => {
    const resp = await v3Client.get<{ data: IConfig }>('configuration/scope/public');

    if (!resp || !resp.data) {
      throw new Error(`Market configuration not found`);
    }

    dispatch({ type: constants.FETCH_MARKET_CONFIG, payload: resp.data });

    return true;
  };

export const setMarketId = (marketId: string) => async (dispatch: Dispatch<SetMarketId>) => {
  dispatch({
    type: constants.SET_MARKET_ID,
    payload: marketId,
  });
};

export const setMarketLanguage =
  (marketLanguage: IConfig['market']['language']) => async (dispatch: Dispatch<SetMarketLanguage>) => {
    dispatch({ type: constants.SET_MARKET_LANGUAGE, payload: marketLanguage });
  };

export const setMarketLocale =
  (marketLocale: IConfig['market']['locale']) => async (dispatch: Dispatch<SetMarketLocale>) => {
    dispatch({ type: constants.SET_MARKET_LOCALE, payload: marketLocale });
  };
