import React from 'react';
import { translate } from 'react-i18next';
import { S } from './ProductCardStockOut.styles';

interface Props {
  t: any;
}

const ProductCardStockOutBase: React.FC<React.PropsWithChildren<Props>> = ({ t }) => (
  <S.Wrapper>
    <S.BaseContainer>
      <S.TextContainer>
        <S.StockOutText>{t('cardTitle')} </S.StockOutText>
        <S.NotifyText>{t('cardDescription')}</S.NotifyText>
      </S.TextContainer>
    </S.BaseContainer>
  </S.Wrapper>
);

export const ProductCardStockOut = translate('stockOut')(ProductCardStockOutBase);
