import { store } from 'store';
import { setLocale, setLanguage } from '../Localization';

export const applyMarketConfig = (): void => {
  const {
    config: { market },
  } = store.getState();
  const { language, locale } = market;
  if (locale) {
    setLocale(locale);
  }

  if (language) {
    setLanguage(language);
  }
};
