import React from 'react';

import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TranslateProps } from 'utils/Localization/types';
import { VoucherRuleError } from '../type';

export interface Props {
  validationError: VoucherRuleError | null;
  onClose: () => void;
}

export const VoucherErrorDialogBase: React.FC<React.PropsWithChildren<Props & TranslateProps>> = (props) => {
  const { t, validationError, onClose } = props;

  if (!validationError) return null;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={!!validationError}
      PaperProps={{ style: { margin: 10, zIndex: 99 } }}
      disableBackdropClick
    >
      <DialogContent>
        <Grid className="content" container direction="column" style={{ padding: 10 }}>
          <Grid container item direction="row" justify="space-between">
            <Grid item>
              <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{t('oops')}</Typography>
            </Grid>
          </Grid>
          <Grid container item direction="row-reverse" justify="space-between">
            <Grid container item direction="column" justify="space-between">
              <Grid item>
                <Typography style={{ marginTop: 11, marginBottom: 11 }}>
                  {Object.values(VoucherRuleError).includes(validationError)
                    ? t(validationError)
                    : t(VoucherRuleError.INVALID_VOUCHER_ERROR)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item direction="row-reverse" alignItems="center">
            <Grid item style={{ paddingTop: '8px', width: '100%' }}>
              <Button
                data-testid="close-error-btn"
                onClick={onClose}
                style={{ background: '#14C47D', color: '#FFFFFF', fontSize: '12px', borderRadius: 20, width: '100%' }}
              >
                {t('close')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const VoucherErrorDialog = translate('voucher')(VoucherErrorDialogBase);
