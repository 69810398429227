import styled from 'styled-components';
import * as colors from 'components/styles/colors';
import ScrollView from 'components/views/ScrollView';
import { Grid, Button } from '@material-ui/core';

const ThankYouWrapper = styled(ScrollView)`
  && {
    height: 100vh;
    background-color: ${colors.SNOW};
  }
`;

const Header = styled.h1`
  && {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.16px;
    margin-top: 3.125rem;
  }
`;

const Body = styled.p`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.16px;
    margin: 1.5rem 0px;
    padding: 0px 1.5rem;

    color: #000000;
  }
`;

const ActionsContainer = styled(Grid)`
  && {
    background: ${colors.WHITE};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    height: 12%;
    bottom: 0;
    width: 100%;
  }
`;

const StyledImg = styled.img`
  && {
    display: block;
    margin-left: auto;
    width: auto;
    max-width: 90%;
    height: auto;
    min-height: 43%;
  }
`;

const DoneButton = styled(Button)`
  && {
    font-weight: 700;
    margin-top: 15px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 12px;
    font-size: 16px;
    text-transform: uppercase;
    width: calc(100% - 32px);
    color: ${colors.WHITE};
    background: ${colors.REBRAND_GREEN};
    border-radius: 40px;
  }
`;

export const S = { ThankYouWrapper, Header, Body, ActionsContainer, StyledImg, DoneButton };
