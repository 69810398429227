import { SwipeRxPt } from '../../SwipeRxPt.class';
import { LogisticsDeliveryStatus } from './types/logistics-delivery-status-history.type';

export class SwipeRxPtLogisticsDelivery {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async getOrderDeliveries(orderId: number): Promise<LogisticsDeliveryStatus[]> {
    const { data } = await this.base.get(`/logistics-delivery/order/${orderId}/history`);
    return data as LogisticsDeliveryStatus[];
  }
}
