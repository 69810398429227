import { Placement } from 'react-joyride';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useSelector, shallowEqual } from 'react-redux';
import { State } from 'store';
import { UseTooltipTourReturn } from './type';

export const useTooltipTour = (name: string, placement?: Placement): UseTooltipTourReturn => {
  const { tours, loading } = useSelector((state: State) => state.tours, shallowEqual);
  const tooltipParams = usePopperTooltip({
    placement,
  });

  return { tooltipParams, visible: !loading && !tours.includes(name) };
};
