/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import moment from 'moment-timezone';
import {
  swipeRxPt,
  Order,
  OrderParams,
  PaginationParams,
  ListMeta,
  LoyaltyHistoryStatus,
  PurchaseOrderDocumentRequirement,
  DocumentRequirementType,
  OrderExpand,
} from 'services';
import { LogisticsDeliveryStatus } from 'services/swipe-rx-pt/resources/logistics-delivery/types/logistics-delivery-status-history.type';

import { recordException } from 'utils/Reporting/Sentry';
import { getOrderDeliveries } from '../logistics-delivery';

import {
  PurchaseOrderActionTypes,
  SetPurchaseOrderLoading,
  SetPurchaseOrderList,
  SetPurchaseOrderError,
  SetPurchaseOrderDetails,
  ResetPurchaseOrderList,
  ResetPurchaseOrderDetails,
  SetInitialPurchaseOrderList,
  SetPuchaseOrderActivity,
  UpdatePuchaseOrderActivityUnreadCount,
  SetPuchaseOrderActivityUnreadCount,
  SetPuchaseOrderDocumentReqirement,
  SetPuchaseOrderDeliveries,
} from './purchase-order-actions.type';
import { OrderActivityData } from './purchase-order.interface';

// REDUX SET STATE
export const setPurchaseOrderLoading = (loading: boolean): SetPurchaseOrderLoading => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDERS_LOADING,
  payload: { loading },
});

export const setPurchaseOrderList = (data: Order[], meta: ListMeta, isInitial: boolean): SetPurchaseOrderList => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_LIST,
  payload: { data, meta, isInitial },
});

export const setPurchaseOrderActivity = (data: OrderActivityData[], unread: number): SetPuchaseOrderActivity => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ACTIVITY,
  payload: { data, unread },
});

export const setInitialPurchaseOrderList = (data: Order[], meta: ListMeta): SetInitialPurchaseOrderList => ({
  type: PurchaseOrderActionTypes.SET_INITIAL_PURCHASE_ORDER_LIST,
  payload: { data, meta },
});

export const setPurchaseOrderError = (error: string): SetPurchaseOrderError => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ERROR,
  payload: { error },
});

export const setPurchaseOrderDetails = (
  data: Order,
  loyaltyStatus?: LoyaltyHistoryStatus,
): SetPurchaseOrderDetails => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_DETAILS,
  payload: { data, loyaltyStatus },
});

export const updatePuchaseOrderActivityUnreadCount = (): UpdatePuchaseOrderActivityUnreadCount => ({
  type: PurchaseOrderActionTypes.UPDATE_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT,
});

export const setPuchaseOrderActivityUnreadCount = (unread: number): SetPuchaseOrderActivityUnreadCount => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_ACTIVITY_UNREAD_COUNT,
  payload: { unread },
});

export const setPuchaseOrderDocumentRequirement = (
  payload: PurchaseOrderDocumentRequirement[],
): SetPuchaseOrderDocumentReqirement => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_DOCUMENT_REQUIREMENT,
  payload,
});

export const resetPurchaseOrderDetails = (): ResetPurchaseOrderDetails => ({
  type: PurchaseOrderActionTypes.RESET_PURCHASE_ORDER_DETAILS,
});

export const resetPurchaseOrderList = (): ResetPurchaseOrderList => ({
  type: PurchaseOrderActionTypes.RESET_PURCHASE_ORDER_LIST,
});

export const setPuchaseOrderDeliveries = (payload: LogisticsDeliveryStatus[]): SetPuchaseOrderDeliveries => ({
  type: PurchaseOrderActionTypes.SET_PURCHASE_ORDER_LOGISTICS_DELIVERY,
  payload,
});

// DATA FETCHING LOGIC

const getPurchaseOrderList = async (
  id: number,
  params: PaginationParams & OrderParams = {},
): Promise<{
  data: Order[];
  meta: ListMeta;
}> => {
  const expand: OrderExpand = [
    'invoice',
    'distributor',
    'items',
    'items.product',
    'requirements',
    'invoice.return',
    'invoice.return.return_items',
    'precursor_status',
  ];

  const { data, meta } = await swipeRxPt.orders.listV2({
    pharmacy_id: id,
    expand,
    no_count: true,
    order_by: 'ordered_at',
    sort_by: 'desc',
    ...params,
  });
  const hasSearch = params?.search_number ? params.search_number?.length > 0 : false;
  const isEnd = data.length < meta.page_size;
  const newMeta: ListMeta = {
    ...meta,
    end: hasSearch ? data.length === 0 : isEnd,
  };

  return {
    data,
    meta: newMeta,
  };
};

export const fetchPurchaseOrderList =
  (id: number, params: PaginationParams & OrderParams = {}) =>
  async (dispatch) => {
    try {
      const { data, meta } = await getPurchaseOrderList(id, params);
      dispatch(setPurchaseOrderList(data, meta, false));
    } catch (e) {
      const error = e as Error;
      recordException(error, 'fetchPurchaseOrderList', { id, params });
      dispatch(setPurchaseOrderError(error.message));
    }
  };

export const fetchInitialPurchaseOrderList =
  (id: number, params: PaginationParams & OrderParams = {}) =>
  async (dispatch) => {
    try {
      dispatch(resetPurchaseOrderList());
      dispatch(setPurchaseOrderLoading(true));
      const { data, meta } = await getPurchaseOrderList(id, { ...params, row: 0 });
      dispatch(setPurchaseOrderList(data, meta, true));
    } catch (e) {
      const error = e as Error;
      recordException(error, 'fetchInitialPurchaseOrderList', { id, params });
      dispatch(setPurchaseOrderError(error.message));
    } finally {
      dispatch(setPurchaseOrderLoading(false));
    }
  };

export const fetchPurchaseOrderDetails = (id: number, loyaltyStatus?: LoyaltyHistoryStatus) => async (dispatch) => {
  try {
    dispatch(setPurchaseOrderLoading(true));
    dispatch(setPuchaseOrderDeliveries([]));

    const expand: OrderExpand = [
      'invoice',
      'distributor',
      'items',
      'items.product',
      'loyalty_point',
      'payment_direct',
      'logistics_po_delivery',
      'invoice.return',
      'invoice.return.credit_memo',
      'invoice.return.return_items',
      'invoice.return.deposit',
      'precursor_status',
      'requirements',
    ];
    const selectedOrder = await swipeRxPt.orders.retrieve(id, { expand });

    if (!selectedOrder) throw new Error('Order not found.');
    if (selectedOrder?.status_history?.dispatched) {
      dispatch(fetchPurchaseOrderDeliveries(id));
    }

    dispatch(setPurchaseOrderDetails(selectedOrder, loyaltyStatus));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchPurchaseOrderDetails', { id, loyaltyStatus });
    dispatch(setPurchaseOrderError(error.message));
  } finally {
    dispatch(setPurchaseOrderLoading(false));
  }
};

export const fetchPurchaseOrderActivity =
  (id: number, params: PaginationParams & OrderParams = {}) =>
  async (dispatch) => {
    try {
      dispatch(resetPurchaseOrderList());
      dispatch(setPurchaseOrderLoading(true));
      const { data: orderData, meta } = await swipeRxPt.orders.listV2({
        pharmacy_id: id,
        row: 0,
        no_count: true,
        expand: ['invoice', 'distributor', 'items', 'precursor_status'],
        order_by: 'ordered_at',
        sort_by: 'desc',
        ...params,
      });

      const { data, unread } = sortOrdersActivity(orderData);

      dispatch(setPurchaseOrderList(orderData, meta, true));
      dispatch(setPurchaseOrderActivity(data, unread));
    } catch (e) {
      const error = e as Error;
      recordException(error, 'fetchPurchaseOrderActivity', { id, params });
      dispatch(setPurchaseOrderError(error.message));
    } finally {
      dispatch(setPurchaseOrderLoading(false));
    }
  };

export const fetchPurchaseOrderActivityUnreadCount = (id: number) => async (dispatch) => {
  try {
    dispatch(setPurchaseOrderLoading(true));

    const { unreadActivities } = await swipeRxPt.orders.getUnreadActivityCount();

    dispatch(setPuchaseOrderActivityUnreadCount(unreadActivities));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchPurchaseOrderActivityUnreadCount', { id });
    dispatch(setPurchaseOrderError(error.message));
  } finally {
    dispatch(setPurchaseOrderLoading(false));
  }
};

export const updatePurchaseOrderActivityOnRead = (id: number, status: string) => (dispatch) => {
  swipeRxPt.orders.update(id, { read: status });

  dispatch(updatePuchaseOrderActivityUnreadCount());
};

export const fetchPendingDocumentRequirementCount = async (
  pharmacyId: number,
  requirementType: DocumentRequirementType,
): Promise<any> => {
  let result = {};
  try {
    const res = await swipeRxPt.getV2(`/orders/requirement-summary/${pharmacyId}?requirement_type=${requirementType}`);

    result = res.data;
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchPendingDocumentRequirementCount', { pharmacyId, requirementType });
    // eslint-disable-next-line no-console
    console.error(error.message);
  }

  return result;
};

// helpers
const getTime = (date: Date | string): number => new Date(date).getTime();

const separateAndSortReadandUnreadActivity = (
  payload: OrderActivityData[],
): { data: OrderActivityData[]; unread: number } => {
  const readActivities: OrderActivityData[] = [];
  const unreadActivities: OrderActivityData[] = [];

  for (const data of payload) {
    if (data.read) {
      readActivities.push(data);
    } else {
      unreadActivities.push(data);
    }
  }

  const sortedUnreadActivity = unreadActivities.sort((a, b) => getTime(b.dateTime) - getTime(a.dateTime));

  const sortedReadActivity = readActivities.sort((a, b) => getTime(b.dateTime) - getTime(a.dateTime));

  return { data: sortedUnreadActivity.concat(sortedReadActivity), unread: unreadActivities.length };
};

const sortOrdersActivity = (orders: Order[]): { data: OrderActivityData[]; unread: number } => {
  const ordersActivity: OrderActivityData[] = [];

  for (const order of orders) {
    const { id, po_number, status: orderStatus, fulfillment_status, status_history } = order;

    const statuses = Object.keys(status_history);

    const latestStatus = statuses.reduce((acc, status) => {
      if (!Object.keys(acc).length) {
        acc = { ...status_history[status], status };
        return acc;
      }

      if (moment.utc(status_history[status].date).valueOf() > moment.utc(acc.date).valueOf()) {
        acc = { ...status_history[status], status };
        return acc;
      }

      return acc;
    }, {} as { date: string; read?: Date | string; status: string });

    ordersActivity.push({
      id,
      po_number,
      orderStatus,
      fulfillmentStatus: fulfillment_status,
      activityStatus: latestStatus.status,
      dateTime: moment.utc(latestStatus.date).toDate(),
      read: latestStatus.read,
    });
  }

  return separateAndSortReadandUnreadActivity(ordersActivity);
};

export const fetchPurchaseOrderDeliveries = (orderId: number) => async (dispatch) => {
  try {
    const deliveries = await getOrderDeliveries(orderId);
    dispatch(setPuchaseOrderDeliveries(deliveries));
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchPurchaseOrderDeliveries', { orderId });
  }
};
