import { Product } from 'store/reducers/Product';

export const isProductAvailable = ({ remaining_quantity, quantity_threshold, available }: Product): boolean =>
  remaining_quantity > quantity_threshold && available;

export const isUnpurchasable = ({ is_unpurchasable }: Product): boolean => is_unpurchasable as boolean;

export const findIndexOfDifference = (arr: Product[]): number => {
  if (!arr || !Array.isArray(arr) || arr.length === 0) {
    return -1;
  }

  let lastIndex = -1;
  for (let index = 0; index < arr.length - 1; index += 1) {
    const isAvailable1 = isProductAvailable(arr[index]);
    const isAvailable2 = isProductAvailable(arr[index + 1]);

    const isUnpurchasable1 = isUnpurchasable(arr[index]);
    const isUnpurchasable2 = isUnpurchasable(arr[index + 1]);

    if (isAvailable1 !== isAvailable2 || isUnpurchasable1 !== isUnpurchasable2) {
      lastIndex = index;
      break;
    }
  }

  return lastIndex;
};

export const getMaxPurchaseLimitsFromProduct = (product: Product) => {
  const productMaxLimitEnabled = (product?.max_qty_enabled && product?.max_qty && product.max_qty > 0) || false;
  const productMaxMonthlyLimitEnabled =
    (product?.max_qty_enabled && product?.monthly_max_qty && product.monthly_max_qty > 0) || false;
  const productMaxDailyLimit = product?.max_qty ?? 0;
  const productMaxMonthlyLimit = product?.monthly_max_qty ?? 0;
  return { productMaxLimitEnabled, productMaxMonthlyLimitEnabled, productMaxDailyLimit, productMaxMonthlyLimit };
};
