import {
  OrderStatus,
  InvoicePaymentStatus,
  OrderFulfillmentStatus,
  PharmacyOrderStatus,
  OrderReturnType,
  DOCUMENT_REQUIREMENT_PREKURSOR_FLAG,
} from 'services';
import { getMarketProductRequirements } from 'utils/MarketConfig';

export const mapOrderStatus = (payload: {
  status: OrderStatus;
  fulfillmentStatus: OrderFulfillmentStatus | null;
  delayed?: boolean | null;
  returnType?: OrderReturnType | null;
  isDirectPayment?: boolean;
  invoicePaymentStatus?: InvoicePaymentStatus;
  isDocumentPending?: boolean;
}): PharmacyOrderStatus | OrderStatus => {
  const { status, fulfillmentStatus, returnType, isDirectPayment, invoicePaymentStatus, isDocumentPending } = payload;

  const productRequirements = getMarketProductRequirements();

  if (status === OrderStatus.CANCELLED) {
    return returnType === OrderReturnType.FULL
      ? PharmacyOrderStatus.CANCELLED_FULL_RETURN
      : PharmacyOrderStatus.CANCELLED;
  }

  if (isDocumentPending && productRequirements.includes(DOCUMENT_REQUIREMENT_PREKURSOR_FLAG)) {
    return PharmacyOrderStatus.ORDER_RECEIVED;
  }

  if (status === OrderStatus.PENDING) {
    if (!isDirectPayment) {
      return PharmacyOrderStatus.ORDER_RECEIVED;
    }

    if (invoicePaymentStatus === InvoicePaymentStatus.PAID) {
      return PharmacyOrderStatus.PAID;
    }

    return PharmacyOrderStatus.UNPAID;
  }

  if (status === OrderStatus.PROCESSING) {
    return PharmacyOrderStatus.PREPARING_GOODS;
  }

  if (status === OrderStatus.ACCEPTED && fulfillmentStatus === OrderFulfillmentStatus.PENDING) {
    return PharmacyOrderStatus.PREPARING_GOODS;
  }

  if (fulfillmentStatus === OrderFulfillmentStatus.DISPATCHED) {
    return PharmacyOrderStatus.OUT_OF_DELIVERY;
  }

  if (fulfillmentStatus === OrderFulfillmentStatus.DELIVERED || status === OrderStatus.FULFILLED) {
    return PharmacyOrderStatus.DELIVERED;
  }

  if (status === OrderStatus.COMPLETED) {
    return PharmacyOrderStatus.DELIVERED;
  }

  return status;
};
