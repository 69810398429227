import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

import OrderConfirmed from 'images/order-confirmed.svg';
import { TranslateProps } from 'utils/Localization/types';

import { PrepSignedPurchaseOrderContainer } from './PrepSignedPurchaseOrder.styles';

const PrepSignedPurchaseOrder: React.FC<React.PropsWithChildren<TranslateProps>> = (props) => {
  const { t } = props;

  return (
    <PrepSignedPurchaseOrderContainer container direction="column" alignItems="center">
      <Card className="expand-to-screen">
        <CardContent className="card-content">
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" className="container-wrapper">
            <Grid container direction="column" item className="payment-border-header" xs={8}>
              <Grid item>
                <Typography className="po-title">{t('prepareSignedPurchaseOrder')}</Typography>
              </Grid>
              <Grid item>
                <Typography className="po-details">{t('fulfillOrders')}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={4} justify="flex-end">
              <img className="icon" alt="post-checkout" src={OrderConfirmed} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </PrepSignedPurchaseOrderContainer>
  );
};

export default PrepSignedPurchaseOrder;
