import { TCampaignState } from '../Segment';

// SPT-14197: to be removed: fix branch io data attributes
export const getTemporaryUtmDetails = (utmParam?: string): TCampaignState | null => {
  if (!utmParam) return null;
  const sourceMediumSeparator = '~';
  const includesSourceAndMedium = utmParam.includes(sourceMediumSeparator);
  if (includesSourceAndMedium) {
    const [utmSource, utmMedium] = utmParam.split(sourceMediumSeparator);
    return {
      utmMedium,
      utmSource,
    };
  }
  return null;
};
