import { SwipeRxPt } from '../../SwipeRxPt.class';
import { DepositList } from './interfaces/deposit-list.interface';
import { DepositListParam } from './interfaces/deposit-list-param.interface';

export class SwipeRxPtDeposit {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async list(params?: DepositListParam): Promise<DepositList> {
    const response = await this.base.getV2('/deposit', params);

    return response;
  }

  async total(pharmacyId: number): Promise<{ total_payments: number }> {
    const { data } = await this.base.getV2(`/deposit/${pharmacyId}/total`);

    return data;
  }
}
