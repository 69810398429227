import * as React from 'react';
import styled from 'styled-components';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from '../../../views/Layout';
import { TranslateProps } from '../../../../utils/Localization/types';

export interface Props extends DialogProps {
  onAgree: () => void;
  config: any;
  onClose?: ((event?: any) => void) | undefined;
}

const TermsDialogContainer = styled(Dialog)`
  && {
    .agree-button,
    .cancel-button {
      margin: 8px 0;
    }
  }
`;

const TermsDialogContent = styled(DialogContent)`
  && {
    padding: 0;

    .content {
      height: 100%;

      .agreement-box {
        width: 100%;
        height: 400px;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        .agreement-scroll {
          overflow-y: scroll !important;
          -webkit-overflow-scrolling: touch;

          .agreement {
            width: 100%;
            height: 100%;
            border: 0;
            background: transparent;
          }
        }
      }
    }

    &:first-child {
      padding: 0;
    }
  }
`;

const TermsDialog: React.FC<React.PropsWithChildren<Props & TranslateProps>> = (props) => {
  const { onClose, open, onAgree, t, config } = props;

  return (
    <TermsDialogContainer fullWidth open={open}>
      <TermsDialogContent>
        <Grid className="content" container direction="column">
          <div className="agreement-box">
            <Layout centered>
              <CircularProgress />
            </Layout>
            <Layout full className="agreement-scroll">
              <iframe className="agreement" src={config.market.userAgreementURL} title="user-agreement" />
            </Layout>
          </div>
          <Grid container direction="row" justify="space-between">
            <Button color="primary" className="agree-button" fullWidth onClick={onAgree} data-testid="btn-agree">
              {t('agree')}
            </Button>
            <Button className="cancel-button" fullWidth onClick={onClose} data-testid="btn-cancel">
              {t('decline')}
            </Button>
          </Grid>
        </Grid>
      </TermsDialogContent>
    </TermsDialogContainer>
  );
};

export default TermsDialog;
