import { Action } from 'redux';
import { ThunkActionCreator } from 'types/thunk';
import { v3Client } from 'utils/Client/v3';
import { Product } from '../Product';
import { ActionType } from './constants';
import { State } from '../..';
import { generateMaxPurchase } from '../ProductListing/actions';

export type Actions =
  | FetchProductAction
  | SetProductAction
  | SetLoadingAction
  | SetIsUpdatedAction
  | SetHasSimilarProductsAction
  | SetModalToggleAction
  | SetProductIdAction
  | SetPrevProductIdAction
  | ClearProductAction;

interface FetchProductAction extends Action {
  type: ActionType.FetchProduct;
  id: number;
}

interface SetProductAction extends Action {
  type: ActionType.SetProduct;
  product: Product;
  productId: number | null;
}

interface SetLoadingAction extends Action {
  type: ActionType.SetLoading;
  isLoading: boolean;
}

interface SetIsUpdatedAction extends Action {
  type: ActionType.SetIsProductUpdated;
  isProductUpdated: boolean;
}

interface SetHasSimilarProductsAction extends Action {
  type: ActionType.SetHasSimilarProducts;
  hasSimilarProducts: boolean;
}
interface SetModalToggleAction extends Action {
  type: ActionType.SetModalToggle;
  isModalOpen: boolean;
}

interface SetProductIdAction extends Action {
  type: ActionType.SetProductId;
  productId: number | null;
}
interface SetPrevProductIdAction extends Action {
  type: ActionType.SetPrevProductId;
  prevProductId: number | null;
}

interface ClearProductAction extends Action {
  type: ActionType.ClearProductDetail;
}

export const fetchProductDetail: ThunkActionCreator<Actions, State> =
  (productId: number) => async (dispatch, getState) => {
    const {
      productDetail: { isProductUpdated },
    } = getState();

    dispatch({
      type: ActionType.ClearProductDetail,
    });
    dispatch({
      type: ActionType.SetLoading,
      isLoading: true,
    });
    const result = await v3Client.get('products/search', {
      product_ids: [productId],
      page: 1,
      page_size: 1,
    });

    const { data } = result;
    const [product] = data;

    dispatch(generateMaxPurchase(data.filter((product) => product.max_qty_enabled)));
    if (isProductUpdated) {
      dispatch({
        type: ActionType.SetIsProductUpdated,
        isProductUpdated: false,
      });
    }
    dispatch({
      type: ActionType.SetProduct,
      product,
      productId: product?.id || null,
    });
  };

export const setProductDetail: ThunkActionCreator<Actions> = (product: Product) => async (dispatch) => {
  dispatch({
    type: ActionType.SetProduct,
    product,
    productId: product?.id || null,
  });
};

export const setDetailPageModalToggle: ThunkActionCreator<Actions> = (isOpen: boolean) => async (dispatch) => {
  dispatch({
    type: ActionType.SetModalToggle,
    isModalOpen: isOpen,
  });
};

export const setDetailPageProductId: ThunkActionCreator<Actions> = (id: number | null) => async (dispatch) => {
  dispatch({
    type: ActionType.SetProductId,
    productId: id,
  });
};

export const setDetailPagePrevProductId: ThunkActionCreator<Actions> = (id: number | null) => async (dispatch) => {
  dispatch({
    type: ActionType.SetPrevProductId,
    prevProductId: id,
  });
};

export const setIsProductDetailUpdated: ThunkActionCreator<Actions> = (updated: boolean) => async (dispatch) => {
  dispatch({
    type: ActionType.SetIsProductUpdated,
    isProductUpdated: updated,
  });
};

export const setHasSimilarProducts: ThunkActionCreator<Actions> = (hasSimilarProducts: boolean) => async (dispatch) => {
  dispatch({
    type: ActionType.SetHasSimilarProducts,
    hasSimilarProducts,
  });
};
