import styled from 'styled-components';

interface Props {
  border?: string;
  mmarginYy?: any;
  marginY?: any;
}

const Divider = styled.div<Props>`
  height: 1px;
  width: 100%;
  margin: ${(props) => `${props.mmarginYy ? props.marginY : 10}px 0px`};
  border-top: ${(props) => `1px ${props.border} ${props.color}`};
`;

export const S = {
  Divider,
};
