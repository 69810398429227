import React from 'react';
import numeral from 'numeral';
import { Typography, Grid, Card } from '@material-ui/core';
import { ShoppingBasket, KeyboardArrowRight } from '@material-ui/icons';

import { PRIMARY_COLOR, WHITE } from 'components/styles/colors';

import { toCurrency } from 'utils/Format';
import { TranslateProps } from 'utils/Localization/types';
import { ViewCartContainer } from './style';

interface Props extends TranslateProps {
  item: number;
  amount: number;
  isOverLimit: boolean;
  maxSKULimitExceeded: boolean;
}

export const ViewCart: React.FC<React.PropsWithChildren<Props>> = ({
  isOverLimit = false,
  maxSKULimitExceeded = false,
  item,
  amount,
  t,
}) => {
  const message = isOverLimit || maxSKULimitExceeded ? 'Over Limit' : t('cartModal.viewCart');
  const itemText = item > 1 ? t('cartModal.items') : t('cartModal.item');

  return (
    <ViewCartContainer id="tour__viewCartBtn" data-testid="viewCart-btn">
      <Card className="card" style={{ background: isOverLimit || maxSKULimitExceeded ? '#D34848' : PRIMARY_COLOR }}>
        <Grid container direction="row" alignItems="center">
          <ShoppingBasket className="basket-icon" style={{ color: WHITE }} />
          <div className="item-container">
            <Typography className="item" style={{ color: WHITE }}>
              <span style={{ textTransform: 'uppercase' }}>{numeral(item).format('0.[0a]')}</span>
              &nbsp;
              {itemText}
            </Typography>
            <Typography className="item-amount" style={{ color: WHITE }}>
              <span>{toCurrency(amount)}</span>
            </Typography>
          </div>
          <Grid item className="grid-view">
            <Typography className="cart-text" style={{ color: WHITE }}>
              {message}
            </Typography>
            <KeyboardArrowRight className="cart-arrow" style={{ color: WHITE }} />
          </Grid>
        </Grid>
      </Card>
    </ViewCartContainer>
  );
};
