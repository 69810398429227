import { Location } from 'history';
import { PtLocationState } from 'routes';
import { PtCoreUser } from 'store/reducers/Auth';
import { market } from 'utils/MarketConfig/market.utils';
import { FALLBACK_LOCALE } from 'utils/Localization/types';
import { AnalyticsEvent } from '../interfaces';
import { makeAnalyticsRequest } from './make-analytics-request';
import { TrackPageViewAnalyticsProps } from './segment.types';

const pageMap = {
  '/home': 'pt_view_home',
  '/products': 'pt_view_products',
  '/orders': 'pt_view_orders',
  '/accounts': 'pt_view_accounts',
  '/search': 'pt_view_search',
  '/prices': 'pt_view_cart',
  '/promoted-products': 'pt_view_promoted_products',
  '/order': 'pt_view_order',
  '/order-confirmed': 'pt_view_checkout',
  '/loyalty': 'pt_loyalty_dashboard_view',
  '/loyalty/rewards': 'pt_rewards_view',
  '/loyalty/membership': 'pt_loyalty_membership_page',
  '/loyalty/rewards/my-rewards': 'pt_loyalty_my_rewards_page',
  '/loyalty/rewards/my-rewards/how-to-use': 'pt_loyalty_reward_details_view',
  '/loyalty/rewards/hot-rewards/how-to-use': 'pt_loyalty_reward_details_view',
  '/loyalty/membership/how-to-use': 'pt_loyalty_reward_details_view',
  '/loyalty/my-rewards/how-to-use': 'pt_loyalty_reward_details_view',
  '/loyalty/how-to-use': 'pt_loyalty_reward_details_view',
  '/loyalty/rewards/hot-rewards': 'pt_loyalty_hot_rewards_page',
  '/loyalty/history': 'pt_loyalty_points_history_view',
  '/loyalty/history/points-used': 'pt_loyalty_history_deducted_page',
  '/loyalty/history/points-received': 'pt_loyalty_history_received_page',
  '/wishlist': 'pt_view_wishlist_home_page',
  '/favorites': 'pt_view_favorites_page',
  '/reminders': 'pt_view_reminders_page',
  '/suggestions': 'pt_view_suggestions_page',
  '/product': 'pt_view_product_detail_page',
  '/banner-listing': 'pt_view_banner_widgets',
  '/product-listing': 'pt_view_marketing_products',
  '/product-listing/loyalty': 'pt_view_loyalty_products',
  '/accounts/payment-channels': 'pt_view_how_to_pay_page',
  '/similar-products': 'pt_view_similar_product_recommendation',
};

class PageTracker {
  public static instance = new PageTracker();

  public currentPage = 'initial_page';

  trackPageView = (location: Location<PtLocationState>, coreUser: PtCoreUser, overridePathname?: string): void => {
    const supportingData: TrackPageViewAnalyticsProps = {};

    const isModalOpen = location.search.includes('isModalOpen');

    // some is identical with isHomepage
    let { pathname } = location;
    // Remove /th or /id
    pathname = pathname.slice(3);

    // from /main/home to /home
    if (pathname.indexOf('/main') > -1) {
      pathname = pathname.replace('/main', '');
    }

    if (pathname.indexOf('/wishlist') > -1) {
      pathname = pathname.replace('/wishlist', '');
    }

    if (pathname.includes('accounts/payment-channels')) {
      pathname = '/accounts/payment-channels';
    }

    if (pathname.includes('payments/due')) {
      this.currentPage = 'initial_page';
    }

    const isProductTabPage = pathname.indexOf('/products') > -1;
    const isProductDetailPage = pathname.indexOf('/product') > -1 && !isProductTabPage;
    if (isProductDetailPage) {
      // FIXME: this replace result will alwyas be initial_page since pathname = /{{productId}} (is this intended?)
      pathname = pathname.replace('/product', '');
    }

    if (pathname.indexOf('/banner-listing') > -1) {
      const [, , marketingId] = pathname.split('/');
      pathname = '/banner-listing';
      supportingData.campaign_id = marketingId;
      supportingData.campaign_name = new URLSearchParams(window.location.search).get('name');
    }

    if (location.pathname.indexOf('/product-listing') > -1) {
      if (decodeURIComponent(location.search || '').includes('/loyalty/missions')) {
        pathname = '/product-listing/loyalty';
      } else if (!(this.currentPage === pageMap['/banner-listing'])) {
        pathname = '/product-listing';
      }
    }
    if (location.pathname.includes('/product-listing/search')) {
      if (location?.state?.base_page === 'loyalty') pathname = '/product-listing/loyalty';
    }

    if (pathname.match(/^\/loyalty\/rewards\/hot-rewards\/.*\/how-to-use$/)) {
      pathname = '/loyalty/rewards/hot-rewards/how-to-use';
    }

    // from /order/12345 to /order
    if (!(pathname.includes('loyalty') || pathname.includes('accounts')) && pathname.indexOf('/', 2) > 0) {
      pathname = pathname.slice(0, pathname.indexOf('/', 2));
    }

    if (isModalOpen) {
      pathname = '/product';
    }

    if (overridePathname) {
      pathname = overridePathname;
    }
    // prevent double sending
    if (pageMap[pathname] && this.currentPage !== pageMap[pathname]) {
      if (this.currentPage) {
        supportingData.referrer = this.currentPage;
      }

      supportingData.pharmacyId = coreUser ? coreUser.organization_id : 0;

      const preventPtViewSearch =
        this.currentPage === pageMap['/product'] && pageMap[pathname] === pageMap['/search'] && !isModalOpen;
      const callCondition = !!overridePathname || !isModalOpen;

      const shouldMakeAnalyticsReq = callCondition && !preventPtViewSearch;

      if (shouldMakeAnalyticsReq) {
        makeAnalyticsRequest(pageMap[pathname], AnalyticsEvent.Page, supportingData);
      }

      this.currentPage = pageMap[pathname];
    }
  };

  isHomepage = (): boolean => {
    // some is identical with trackPageView
    let { pathname } = window.location;
    pathname = pathname.slice(3);
    if (pathname.indexOf('/main') > -1) {
      pathname = pathname.replace('/main', '');
    }
    const isHome = pathname.includes('home');
    return isHome;
  };

  // identical with App.component isLanding
  isLanding = (): boolean => {
    const marketId = market.marketId || FALLBACK_LOCALE;
    const { pathname } = window.location;
    const isLanding = `/${marketId}/` === pathname || `/${marketId}` === pathname;
    return isLanding;
  };

  isSimilarProductsPage = (): boolean => {
    let { pathname } = window.location;
    pathname = pathname.slice(3);

    const similarProducts = pathname.includes('similar-products');
    return similarProducts;
  };
}

const pageTrackerInstance = PageTracker.instance;

export default pageTrackerInstance;
