import React, { useEffect } from 'react';
import {
  PaymentDirectBillStatus,
  PaymentDirectPaymentStatus,
} from 'services/swipe-rx-pt/resources/payment-direct-bills/types';
import { IVoucherUsage } from 'services/swipe-rx-pt/resources/voucher/interface/voucher-usage.interface';

import { swipeRxPt } from '../../../services';

export interface UseGetBillDetails {
  paymentDirectId: number;
  billReference: string;
  isLoading: boolean;
  qrCodeUrl: string;
  amount: number;
  paymentStatus: string;
  voucherUsage?: IVoucherUsage;
  updateBillReference: (billReference: string) => void;
}

export const useGetBillDetails = (billCode: string): UseGetBillDetails => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [billReference, setBillReference] = React.useState('');
  const [billStatus, setBillStatus] = React.useState('');
  const [qrCodeUrl, setQrCodeUrl] = React.useState('');
  const [amount, setAmount] = React.useState(0);
  const [paymentDirectId, setPaymentDirectId] = React.useState(0);
  const [voucherUsage, setVoucherUsage] = React.useState<IVoucherUsage | undefined>(undefined);
  const [paymentStatus, setPaymentStatus] = React.useState<PaymentDirectPaymentStatus>(PaymentDirectPaymentStatus.NEW);

  const fetchBillDetails = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const bill = await swipeRxPt.paymentDirectBills.retrieve({
        bill_code: billCode,
      });

      if (bill) {
        const { total_amount, qr_code, bill_reference, bill_status, payment_status, id } = bill;

        setPaymentDirectId(id);
        setAmount(total_amount);
        setPaymentStatus(payment_status);

        setBillReference(bill_reference || '');
        setBillStatus(bill_status || '');
        setQrCodeUrl(qr_code || '');

        setVoucherUsage(bill.voucher_usage);
      }
    } catch (error) {
      console.error('useGetBillDetails error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPaymentStatus = async (): Promise<void> => {
    const bill = await swipeRxPt.paymentDirectBills.retrieve({
      bill_code: billCode,
    });

    if (bill) {
      setPaymentStatus(bill.payment_status);
      setVoucherUsage(bill.voucher_usage);
    }
  };

  const updateBillReference = (billReference) => {
    setBillReference(billReference);
  };

  useEffect(() => {
    fetchBillDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkIfPaid = setInterval(() => {
      if (
        (paymentStatus !== PaymentDirectPaymentStatus.PAID && billReference) ||
        (paymentStatus !== PaymentDirectPaymentStatus.PAID && billStatus === PaymentDirectBillStatus.NO_BILLER)
      ) {
        getPaymentStatus();
      }
    }, 3000);

    return () => clearInterval(checkIfPaid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus, billReference]);

  return {
    paymentDirectId,
    isLoading,
    billReference,
    qrCodeUrl,
    amount,
    paymentStatus,
    voucherUsage,
    updateBillReference,
  };
};
