import React from 'react';
import { Typography } from '@material-ui/core';

import BinocularIMG from '../../../images/binoculars.png';

interface Props {
  t?: (key: string, options?: any) => string;
  showLabel?: boolean;
}

export const BinocularComponent: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { t = null, showLabel = true } = props;
  return (
    <>
      <img
        src={BinocularIMG}
        alt="binoculars"
        style={{
          display: 'block',
          margin: 'auto',
          width: '30%',
        }}
      />
      <Typography
        style={{
          fontSize: '12pt',
          color: '#00000054',
          padding: '0 8px',
          textAlign: 'center',
        }}
      >
        {showLabel && t ? t('empty') : undefined}
      </Typography>
    </>
  );
};

BinocularComponent.defaultProps = {
  t: undefined,
  showLabel: true,
};
