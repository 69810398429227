/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { State } from 'store';
import { resetPurchaseOrderList, fetchPurchaseOrderActivity } from 'store/reducers';
import { useGetCurrentUser } from 'hooks';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOrdersActivity = () => {
  const dispatch = useDispatch();

  const { activities, loading } = useSelector((state: State) => state.purchaseOrder, shallowEqual);

  const {
    coreUser: { organization_id },
  } = useGetCurrentUser();

  useEffect(() => {
    dispatch(fetchPurchaseOrderActivity(organization_id as number, { row: 0, page_size: 15 }));
  }, [activities.unread]);

  useEffect(
    () => () => {
      dispatch(resetPurchaseOrderList());
    },
    [],
  );

  return {
    activities,
    loading,
  };
};
