/* eslint-disable no-console */
import { SwipeRxPt } from '../../SwipeRxPt.class';

import {
  TrackVoucherAppliedPayload,
  TrackVoucherCancelPayload,
  TrackSearchVoucherPayload,
} from './interface/voucher-tracking.interface';

export class SwiperxPtVoucherTracking {
  private readonly base: SwipeRxPt;

  private readonly endpoint: string;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.endpoint = 'pharmacy-voucher-tracking';
  }

  async trackListOpen(): Promise<void> {
    try {
      await this.base.post(`/${this.endpoint}/list-open`);
    } catch (error) {
      if (error instanceof Error) console.error(`Failed to track voucher list open: ${error.message}`);
    }
  }

  async trackListView(): Promise<void> {
    try {
      await this.base.post(`/${this.endpoint}/list-view`);
    } catch (error) {
      if (error instanceof Error) console.error(`Failed to track voucher list view: ${error.message}`);
    }
  }

  async trackSearchVoucher(payload: TrackSearchVoucherPayload): Promise<void> {
    try {
      await this.base.post(`/${this.endpoint}/search-voucher`, payload);
    } catch (error) {
      if (error instanceof Error) console.error(`Failed to track search voucher: ${error.message}`);
    }
  }

  async trackVoucherApplied(payload: TrackVoucherAppliedPayload): Promise<void> {
    try {
      await this.base.post(`/${this.endpoint}/voucher-applied`, payload);
    } catch (error) {
      if (error instanceof Error) console.error(`Failed to track voucher applied: ${error.message}`);
    }
  }

  async trackVoucherCancel(payload: TrackVoucherCancelPayload): Promise<void> {
    try {
      await this.base.post(`/${this.endpoint}/voucher-cancel`, payload);
    } catch (error) {
      if (error instanceof Error) console.error(`Failed to track voucher cancel: ${error.message}`);
    }
  }
}
