/* eslint-disable array-callback-return */
import React from 'react';
import { Typography, ButtonBase } from '@material-ui/core';

import { PRIMARY_COLOR } from 'components/styles/colors';

import { getCustomerSuccessSupport } from 'utils/MarketConfig';

const titleStyle: React.CSSProperties = { fontSize: '14px', fontWeight: 'bold' };
const contentStyle: React.CSSProperties = { fontSize: '14px', padding: '8px' };
const buttonStyle: React.CSSProperties = { color: PRIMARY_COLOR, textTransform: 'capitalize' };

export const toursTemplate: any = (steps, initialTour, t, type) => {
  const customerSuccessSupport = getCustomerSuccessSupport();

  const openChat = (): void => {
    window.open(customerSuccessSupport.link, customerSuccessSupport.target);
  };

  const data: any[] = [];

  if (initialTour) {
    data.push({
      content: (
        <div style={{ zIndex: 5000 }}>
          <img
            src="https://cdn-procurement.swiperxapp.com/onboarding.png"
            alt="Pharmacy"
            style={{
              fontSize: 14,
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <Typography style={titleStyle}>{initialTour?.title}</Typography>
          <Typography style={contentStyle}> {initialTour?.description} </Typography>
        </div>
      ),
      placement: 'center',
      textAlign: 'center',
      placementBeacon: 'top',
      target: 'body',
      showSkipButton: true,
    });
  }

  steps.map((step) => {
    data.push({
      content: (
        <Typography style={contentStyle}>
          {t(step.content)}{' '}
          {step.chatLink && (
            <ButtonBase style={buttonStyle} onClick={openChat}>
              {t(step.chatLink)}
            </ButtonBase>
          )}
        </Typography>
      ),
      textAlign: 'center',
      target: step.target === 'body' ? step.target : `#${step.target}`,
      placement: step.target !== 'body' ? `${step.placement}` : 'top',
      placementBeacon: step.target !== 'body' ? `${step.placementBeacon}` : 'top',
      showSkipButton: true,
      ...step.options,
      title: <Typography style={titleStyle}> {t(step.title)} </Typography>,
    });
  });

  const result = type === 'tab_updates' ? data.splice(1, 3) : data;
  return result;
};
