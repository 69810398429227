import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';
import { BasePageTracker } from 'utils/Analytics/Segment';
import { State } from 'store/reducers/ProductListing';

interface IParams {
  showCondition: boolean;
  featureType: MarketingFeaturesType;
  cleanupFunction?: () => void;
}

export const getMarketingFeaturesConfig = (
  page: BasePageTracker,
  listingState: State,
  callback?: () => void,
): IParams => {
  switch (page) {
    case BasePageTracker.GENERAL_SEARCH:
      return {
        showCondition: listingState.products.length < 8 && !listingState.loading,
        featureType: MarketingFeaturesType.GENERAL,
        cleanupFunction: callback,
      };
    default:
      return {
        showCondition: false,
        featureType: MarketingFeaturesType.GENERAL,
      };
  }
};
