import styled from 'styled-components';

export const Container = styled.div`
  .hs-container {
    position: relative;
    align-items: stretch;
  }
`;

export const EndingPad = styled.div`
  min-width: 1px;
  min-height: 1px;
`;
