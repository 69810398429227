import * as Redux from 'redux';

import * as constants from './constants';
import { Actions } from './actions';
import { State } from './interface';

const InitialState: State = {
  ordersDueBy: [],
  orders: [],
  selected: undefined,
  creating: false,
  purchaseOrderBatchId: 0,
  created: [],
  createdFailed: false,
  shouldShowCancelDialogOrder: false,
  orderRating: {
    rate: null,
    isOpen: false,
    checkedOptions: [],
    feedbackOptions: [],
    feedbacks: '',
    currentOrderRating: null,
    isLoading: false,
    showThankYou: false,
  },
  orderModal: {
    isOpen: false,
  },
  orderStatus: {
    isOpen: false,
  },
  spDocumentUrl: null,
  spLoading: false,
  orderItemQuantityHistory: [],
};

const OrdersReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_ORDERS_DUE_BY:
      return {
        ...state,
        ordersDueBy: action.ordersDueBy,
      };
    case constants.UPDATE_ORDER:
      return {
        ...state,
        selected: action.selected,
      };
    case constants.CREATE_ORDERS:
      return {
        ...state,
        creating: action.creating,
        created: action.created,
        orders: action.orders,
        purchaseOrderBatchId: action.purchaseOrderBatchId,
      };
    case constants.CREATE_ORDER_FAIL:
      return {
        ...state,
        createdFailed: true,
        creating: action.creating,
      };
    case constants.CLOSE_CART_ERROR:
      return {
        ...state,
        createdFailed: false,
      };
    case constants.FAIL_ORDERS:
      return {
        ...state,
        error: action.error,
      };
    case constants.RESET_LOADER:
    case constants.ORDER_CREATING:
      return {
        ...state,
        creating: action.creating,
      };
    case constants.OPEN_ORDER_STATUS:
      return {
        ...state,
        orderStatus: { ...state.orderStatus, isOpen: true },
      };
    case constants.CLOSE_ORDER_STATUS:
      return {
        ...state,
        orderStatus: { ...state.orderStatus, isOpen: false },
      };
    case constants.OPEN_ORDER_RATING:
      return {
        ...state,
        orderRating: { ...state.orderRating, isOpen: true },
      };
    case constants.CLOSE_ORDER_RATING:
      return {
        ...state,
        orderRating: { ...state.orderRating, isOpen: false },
      };
    case constants.SET_ORDER_RATING:
      return {
        ...state,
        orderRating: { ...state.orderRating, rate: action.value },
      };
    case constants.TOGGLE_CANCELLED_ORDER_DIALOG:
      return {
        ...state,
        shouldShowCancelDialogOrder: action.shouldShowCancelledOrderDialog,
      };
    case constants.SET_FEEDBACK_OPTIONS:
      return {
        ...state,
        orderRating: {
          ...state.orderRating,
          feedbackOptions: action.value,
        },
      };
    case constants.SET_CHECKED_OPTIONS:
      return {
        ...state,
        orderRating: {
          ...state.orderRating,
          checkedOptions: action.value,
        },
      };
    case constants.SET_FEEDBACKS:
      return {
        ...state,
        orderRating: {
          ...state.orderRating,
          feedbacks: action.value,
        },
      };
    case constants.SET_CURRENT_ORDER_RATING:
      return {
        ...state,
        orderRating: {
          ...state.orderRating,
          currentOrderRating: action.value,
        },
      };
    case constants.SET_ORDER_RATING_LOADING:
      return {
        ...state,
        orderRating: {
          ...state.orderRating,
          isLoading: action.value,
        },
      };
    case constants.TOGGLE_ORDER_MODAL:
      return {
        ...state,
        orderModal: {
          ...state.orderModal,
          isOpen: action.value,
        },
      };
    case constants.OPEN_ORDER_RATING_THANK_YOU:
      return {
        ...state,
        orderRating: { ...state.orderRating, showThankYou: true },
      };
    case constants.CLOSE_ORDER_RATING_THANK_YOU:
      return {
        ...state,
        orderRating: { ...state.orderRating, showThankYou: false },
      };
    case constants.SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT:
      return {
        ...state,
        spLoading: false,
        spDocumentUrl: action.payload,
      };
    case constants.SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT_ORDER_CONFIRMATION:
      return {
        ...state,
        spLoading: false,
        spDocumentUrlOrderConfirmation: action.payload,
      };
    case constants.SET_SP_DOCUMENT_LOADING:
      return {
        ...state,
        spLoading: true,
      };

    case constants.FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT:
      return {
        ...state,
        error: action.error,
        spLoading: false,
        spDocumentUrl: null,
      };

    case constants.ORDER_ITEM_QUANTITY_HISTORY_CREATE:
      return {
        ...state,
        orderItemQuantityHistory: action.items,
      };
    default:
      return state;
  }
};

export default OrdersReducer;
export * from './interface';
