export const checkOverSKULimit = (
  counterList: any,
  maxSKULimit: number | undefined,
  isForAddButton?: boolean,
): boolean => {
  const totalSKU = Object.values(counterList).length;
  const maxSKULimitExceeded = maxSKULimit
    ? (isForAddButton && totalSKU >= maxSKULimit) || totalSKU > maxSKULimit
    : false;
  return maxSKULimitExceeded;
};
