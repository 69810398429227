import React from 'react';
import styled from 'styled-components';
import { Grid, Typography, Button } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import * as colors from 'components/styles/colors';
import { breakpoints } from 'components/styles/breakpoints';

const DiscountRibbon = styled(({ ...props }) => <Grid {...props} />)`
  && {
    top: ${({ position }) => position.top};
    left: ${({ position }) => position.left};
    padding: 4px 15px;
    z-index: 5;
    position: absolute;
    border: 1px solid ${(props) => props.customcolor.ribbon};
    border-bottom-right-radius: 10px;
    background-color: ${(props) => props.customcolor.ribbon};
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      padding: 2px 10px;
    }
  }
`;

const DiscountText = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 900;
    color: ${colors.RIBBON_TEXT};
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      font-size: 10px;
    }
  }
`;

export const VerifyAccount = styled(({ ...props }) => <Button startIcon={<LockOutlined />} {...props} />)`
  && {
    font-size: 12px;
    font-weight: bold;
    max-height: 28px;
    color: #fff;
    background: #ff7e30;
    justify-content: left;
    width: 158px;
    z-index: 9;
    border-radius: 0 0 10px;
    position: absolute;
    top: ${(props) => props?.position?.top};

    :hover {
      background: #ff7e30;
    }

    :focus {
      background: #ff7e30;
      color: #fff;
    }

    .MuiButton-startIcon {
      margin-left: 2px;
      margin-right: 10px;

      & > *:first-child {
        font-size: 16px;
      }
    }
  }
`;

export const S = {
  DiscountRibbon,
  DiscountText,
  VerifyAccount,
};
