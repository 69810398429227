import styled from 'styled-components';

import { BaseCard } from '../BaseCard';

interface Props {
  bgImage?: string;
  color?: any;
}

const Wrapper = styled(BaseCard)<Props>`
  && {
    background: linear-gradient(${({ color: { start, end } }) => `137deg, ${start} 0%, ${end} 100%`});
    padding: 0;
    position: relative;
  }
`;

const BGIcon = styled.div`
  && {
    background: url(${({ bgImage }: Props) => bgImage}) no-repeat;
    background-size: 100%;
    filter: opacity(0.2);
    position: absolute;
  }
`;

export const S = {
  Wrapper,
  BGIcon,
};
