import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { StyledNavLink, StyledTab } from './Tab.styles';
import { A11yProps, TabPage } from './Tab.types';

export interface TabNavLinkBaseProps {
  tab: TabPage;
  id?: string;
  index: number;
  a11yProps: (x: number) => A11yProps;
  fullWidth?: boolean;
  className?: string;
  tabStyle?: React.CSSProperties;
  noBrowserHistory?: boolean;
  onClick?: (tab: TabPage) => void;
}

const TabNavLinkBase: React.FC<React.PropsWithChildren<TabNavLinkBaseProps>> = ({
  tab,
  index,
  a11yProps,
  id,
  fullWidth,
  className,
  tabStyle,
  onClick,
  noBrowserHistory,
}) => {
  const match = useRouteMatch();
  const location = useLocation();

  const [endpoint] = location.pathname.split('/').slice(-1);
  const isSelected = endpoint === tab.path;

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (!onClick) return;
    event.preventDefault();
    onClick(tab);
  };

  return (
    <StyledNavLink
      id={id}
      data-testid={`nav-${tab.path}`}
      className={fullWidth ? 'fullWidth' : className}
      to={`${match.url}/${tab.path}${location.search}`}
      key={tab.id}
      onClick={handleClick}
      replace={noBrowserHistory}
    >
      <StyledTab
        data-testid={`nav-${tab.label}`}
        label={tab.label}
        {...a11yProps(index)}
        selected={isSelected}
        disableRipple
        disableTouchRipple
        style={tabStyle}
      />
    </StyledNavLink>
  );
};

export const TabNavLink = TabNavLinkBase;
