import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { translate } from 'react-i18next';
import { Divider, Grid, List, ListItem } from '@material-ui/core';

import { State as StoreState } from 'store';

import { toCurrency } from 'utils/Format';

import { ReactComponent as LoyaltyPointIcon } from 'images/ic_loyalty_point.svg';
import { S } from './orders-modal-loyalty-point.styles';

const ModalLoyaltyPoint: React.FC<React.PropsWithChildren<{ t: any }>> = ({ t }) => {
  const {
    orderDetail: { loyalty_point: loyaltyPoint },
  } = useSelector((state: StoreState) => state.purchaseOrder, shallowEqual);

  if (!loyaltyPoint) return null;

  const geTotalRewardPoints = (): string => {
    const points = loyaltyPoint.point - Math.abs(loyaltyPoint.point_deduction);
    return toCurrency(points);
  };

  const isPending = ['pending', 'converting'].includes(loyaltyPoint.status);

  return (
    <S.Container container direction="column">
      <Grid item>
        <Grid container justifyContent="flex-start">
          <Grid item>
            <LoyaltyPointIcon />
          </Grid>
          <Grid item>
            <S.Title>{t('loyalty.rewardPass')}</S.Title>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <List component="div" style={{ width: '100%' }}>
          <ListItem style={{ justifyContent: 'space-between', padding: '0 0 8px 0' }}>
            <S.Info>{t('loyalty.rewardPointAdded')}</S.Info>
            <S.Point>{toCurrency(loyaltyPoint.point)}</S.Point>
          </ListItem>
          <ListItem style={{ justifyContent: 'space-between', padding: '0 0 8px 0' }}>
            <S.Info>{t('loyalty.rewardPointDeducted')}</S.Info>
            <S.Point>{toCurrency(-Math.abs(loyaltyPoint.point_deduction))}</S.Point>
          </ListItem>
          <Divider />
          <ListItem style={{ justifyContent: 'space-between', padding: '8px 0 0 0' }}>
            <S.Info>
              {t('loyalty.rewardPointTotal')} {isPending && t('loyalty.rewardPending')}
            </S.Info>
            <S.Point>{geTotalRewardPoints()}</S.Point>
          </ListItem>
        </List>
      </Grid>
    </S.Container>
  );
};

export const OrdersModalLoyaltyPoint = translate('orders')(ModalLoyaltyPoint);
