import { SwipeRxPt } from '../../SwipeRxPt.class';
import { CreditLimitRequest } from './interfaces/credit-limit-request.interface';

export class SwipeRxPtCreditRequest {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async get(): Promise<CreditLimitRequest> {
    const { data } = await this.base.getV2(`/credit/limit-requests/latest`);

    return data;
  }

  async read(id: number): Promise<CreditLimitRequest> {
    const { data } = await this.base.post(`/credit/limit-requests/${id}/read`);

    return data;
  }

  async create(newLimit: number): Promise<CreditLimitRequest> {
    const { data } = await this.base.post(`/credit/limit-requests`, { new_limit: newLimit, request_details: [] });

    return data;
  }
}
