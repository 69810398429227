export class MaxPurchaseLimitReachedError extends Error {
  constructor(name, products) {
    super(name);
    // Ensure the name of this error is the same as the class name
    this.name = 'MaxPurchaseLimitReachedError';
    this.message = products;
    // This clips the constructor invocation from the stack trace.
    // It's not absolutely essential, but it does make the stack trace a little nicer.
    //  @see Node.js reference (bottom)
    Error.captureStackTrace(this, this.constructor);
  }
}
