/* eslint-disable react/jsx-curly-newline */
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { translate } from 'react-i18next';
import { store } from 'store';

import { useUnverifiedInfoHook } from 'components/common/snackbar';
import { Product } from 'store/reducers/Product';
import { toPercent } from 'utils/Format';
import { useAddProductAnalytics } from 'hooks/useAddProductAnalytics';

import { setTrackTierPricing } from 'utils/Analytics/Segment/ProductTracker.utils';
import { Color } from '../../ProductCard.interface';
import { S } from './ProductCardTierDiscount.styles';
import { ProductContext } from '../../ProductCard.context';

interface Props {
  product: Product;
  color: Color;
  itemCount: number;
  activeDiscount: number;
  isPromoApplied: boolean;
  isTierDiscountAvailable: boolean;
  styles?: {
    container: React.CSSProperties;
  };
  t: any;
  autoExpand?: boolean;
  addOrUpdateProductQuantity: (product: any, itemCount: number) => any;
}

const Component: React.FC<React.PropsWithChildren<Props>> = ({
  product,
  color,
  itemCount,
  activeDiscount,
  isPromoApplied,
  isTierDiscountAvailable,
  autoExpand = false,
  addOrUpdateProductQuantity,
  styles,
  t,
}): JSX.Element | null => {
  const [isExpanded, setIsExpanded] = useState(autoExpand);
  const { indexNumber } = useContext(ProductContext);
  const { auth, filterToolbar } = store.getState();
  const { showMessage } = useUnverifiedInfoHook(auth, product);

  const { trackProductWithMarketing } = useAddProductAnalytics(
    undefined,
    undefined,
    filterToolbar.selectedFilters,
    indexNumber,
  ); // TODO: do we not fill the marketing here?

  const handleSummaryPanelExpand = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setIsExpanded((current) => !current);
  };

  const handleClick = (product: any, quantity: number): void => {
    if (product?.is_unpurchasable) {
      showMessage();
    } else {
      addOrUpdateProductQuantity(product, quantity);
      trackProductWithMarketing(product);
      setTrackTierPricing(product);
    }
  };

  useEffect(() => {
    setIsExpanded(autoExpand);
  }, [autoExpand]);

  if (!isTierDiscountAvailable) {
    return null;
  }

  if (product.tier_discount.length > 1) {
    return (
      <S.TierDiscountPanel
        id="tour__tierPricing"
        customcolor={color.secondary}
        style={styles?.container}
        defaultExpanded={autoExpand}
        expanded={isExpanded}
      >
        <S.TierDiscountPanelSummary
          onClick={handleSummaryPanelExpand}
          expandIcon={<S.Chevron customcolor="rgba(0,0,0,0.3)" id="tour__tierExpandBtn" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid style={{ paddingRight: 10 }} container alignItems="center">
            <S.StarIcon customcolor="#FCC812" />
            <S.PromoDetails style={{ color: color.secondary.dark }}>
              {isPromoApplied
                ? t('promoApplied', {
                    discount: toPercent(activeDiscount, 2),
                  })
                : t('getUpTo', {
                    discount: toPercent(product.tier_discount[product.tier_discount.length - 1].discount_rate, 2),
                  })}
            </S.PromoDetails>
          </Grid>
        </S.TierDiscountPanelSummary>
        {product.tier_discount.map((promo, index) => {
          const max = product.tier_discount[index + 1] ? product.tier_discount[index + 1].min - 1 : +Infinity;
          const isTierSelected: boolean = itemCount >= promo.min && itemCount <= max;
          return (
            <S.TierDiscountPanelDetails key={promo.id}>
              <S.TierDiscountButton
                onClick={() => handleClick(product, promo.min)}
                customcolor={color.secondary}
                selected={isTierSelected}
                id={isExpanded ? 'tour__tierDiscountBtn' : ''}
              >
                <S.MinimumPurchase customcolor={color.secondary} selected={isTierSelected}>
                  {t('buyMinimum', { quantity: promo.min })}
                </S.MinimumPurchase>
                <S.PromoText customcolor={color.secondary} selected={isTierSelected}>
                  {t('getDiscount', {
                    discount: toPercent(promo.discount_rate, 2),
                  })}
                </S.PromoText>

                <S.ChevronRight customcolor="rgba(0,0,0,0.3)" />
              </S.TierDiscountButton>
            </S.TierDiscountPanelDetails>
          );
        })}
      </S.TierDiscountPanel>
    );
  }

  return (
    <S.SingleTierDiscount id="tour__tierPricing" customcolor={color.secondary} style={styles?.container}>
      {product.tier_discount.map((promo, index) => {
        const max = product.tier_discount[index + 1] ? product.tier_discount[index + 1].min - 1 : +Infinity;
        return (
          <S.TierDiscountPanelDetails key={promo.id}>
            <S.TierDiscountButton
              onClick={() => handleClick(product, promo.min)}
              customcolor={color.secondary}
              selected={isPromoApplied}
              min={promo.min}
              max={max}
              count={itemCount}
              id="tour__tierDiscountBtn"
            >
              <S.MinimumPurchase customcolor={color.secondary} selected={isPromoApplied}>
                {t('buyMinimum', { quantity: promo.min })}
              </S.MinimumPurchase>
              <S.PromoText customcolor={color.secondary} selected={isPromoApplied}>
                {t('getDiscount', {
                  discount: toPercent(promo.discount_rate, 2),
                })}
              </S.PromoText>
              <S.ChevronRight customcolor="rgba(0,0,0,0.3)" />
            </S.TierDiscountButton>
          </S.TierDiscountPanelDetails>
        );
      })}
    </S.SingleTierDiscount>
  );
};

Component.defaultProps = {
  styles: undefined,
  autoExpand: undefined,
};

export const ProductCardTierDiscount = translate('tierDiscount')(Component);
