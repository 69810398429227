import { useHistory, useLocation } from 'react-router-dom';

interface UseNavigate {
  navigateTo(page: string): void;
}

export const useNavigate = (): UseNavigate => {
  const history = useHistory();
  const location = useLocation();

  const navigateTo = (page: string, state?: any): void => {
    history.push({
      pathname: page,
      search: location.search,
      state,
    });
  };

  return {
    navigateTo,
  };
};
