import React from 'react';
import { Button, Snackbar } from '@material-ui/core';
import { setCloseSnackbar } from 'store/reducers/Utils/actions';
import { useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import { TFunction } from 'utils/Localization';
import { useHistory } from 'react-router-dom';
import { pageTracker } from 'utils/Analytics/Segment';
import useStyles, { getSnackbarStyle } from './snackbar.styles';

interface Props {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line react/require-default-props
  onActionPath?: string;
  message: string;
  params: Record<string, string | number>;
  titleAction: string;
  // eslint-disable-next-line react/require-default-props
  contentProps?: any;
  t: TFunction;
}

const SnackbarBase: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  onActionPath,
  message,
  params,
  titleAction,
  contentProps = { background: '#1d27f1' },
  t,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { currentPage } = pageTracker;
  const style = getSnackbarStyle(currentPage, classes);

  const className = params?.maxUnit ? style : undefined;

  const handleClose = (): void => {
    dispatch(setCloseSnackbar());

    onClose();
  };

  const handleActionClicked = (): void => {
    dispatch(setCloseSnackbar());

    if (onActionPath) {
      history.push({
        pathname: onActionPath,
      });
    }
  };

  return (
    <Snackbar
      className={className}
      open={open}
      autoHideDuration={12000}
      ContentProps={{ style: contentProps }}
      message={t(message, params)}
      onClose={handleClose}
      action={
        <Button style={{ color: '#fff' }} onClick={handleActionClicked}>
          {t(titleAction)}
        </Button>
      }
    />
  );
};

export const SnackbarComponent = translate('snackbar')(SnackbarBase);
