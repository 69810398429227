import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { LoadingContainer } from '../product-list.styles';

export const LoaderComponent: React.FC = () => (
  <LoadingContainer>
    <CircularProgress />{' '}
  </LoadingContainer>
);
