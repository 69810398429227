import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { translate } from 'react-i18next';

import { routeKey } from 'utils/route.utils';
import { Button } from '../../Button.component';
import { TranslateProps } from '../../../../../utils/Localization/types';

type Props = RouteComponentProps & TranslateProps;

const HowToPayButtonBase: React.FC<React.PropsWithChildren<Props>> = ({ history, location, t }) => {
  const handleClick = (): void => {
    history.push({
      pathname: `${routeKey('main_accounts_payments_channels')}/${location.search}`,
      state: { from: location.pathname },
    });
  };

  return (
    <Button
      color="primary"
      style={{
        fontWeight: 600,
        fontSize: '14px',
        textTransform: 'capitalize',
        paddingTop: 0,
      }}
      onClick={handleClick}
    >
      {t('virtualAccount.learnHowToPay')}
    </Button>
  );
};

export const HowToPayButton = translate('accountPage')(withRouter(HowToPayButtonBase));
