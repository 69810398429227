import { store } from 'store';
import { LABEL_VALUE_SEPARATOR } from 'store/reducers/FilterToolbar/constants';
import { ConjuctionType, FilterMenuItem } from 'store/reducers/FilterToolbar/interface';
import { getFilterOptionValue } from 'store/reducers/FilterToolbar/utils';
import { FilterType, SortType } from './constants';

const getAlphabeticalOptions = (): FilterMenuItem[] => {
  const alphabet: string[] = Array.from(Array(26), (e, i) => String.fromCharCode(i + 65));
  return alphabet.map((letter) => ({
    type: FilterType.ALPHABETICAL,
    value: getFilterOptionValue(FilterType.ALPHABETICAL, letter),
    label: letter,
    conjunction: ConjuctionType.HORIZONTAL,
  }));
};

const getCategoryOptions = (): FilterMenuItem[] => {
  const { categories } = store.getState().search;

  return categories.map((category) => ({
    type: FilterType.CATEGORY,
    value: getFilterOptionValue(FilterType.CATEGORY, category.id.toString()),
    label: category.name,
    isMultiSelect: true,
  }));
};

export const getFilterMenuHorizontal = (): FilterMenuItem[] => [
  {
    type: FilterType.ALL,
    value: getFilterOptionValue(FilterType.ALL, 'all'),
    label: 'all',
  },
  {
    type: FilterType.LAST_PURCHASED,
    value: getFilterOptionValue(FilterType.LAST_PURCHASED, 'lastPurchased'),
    label: 'lastPurchased',
    conjunction: ConjuctionType.HORIZONTAL,
  },
  ...getCategoryOptions(),
  ...getAlphabeticalOptions(),
];

export const getFilterMenuModal = (): FilterMenuItem[] => [
  {
    type: FilterType.LOYALTY_EXTRA_POINTS,
    value: `${FilterType.LOYALTY_EXTRA_POINTS}${LABEL_VALUE_SEPARATOR}desc`, // TODO: to utilize in select options component
    label: '',
  },
  {
    type: FilterType.DISCOUNT,
    isRegex: true,
    value: `${FilterType.DISCOUNT}-min-(\\d+)-max-(\\d+)`,
    label: '',
  },
];

const getModalFiltersObject = () =>
  getFilterMenuModal().reduce((acc, v) => {
    acc[v.value] = v;
    return acc;
  }, {} as { [v: string]: FilterMenuItem });

const getHorizontalFiltersObject = () =>
  getFilterMenuHorizontal().reduce((acc, v) => {
    acc[v.value] = v;
    return acc;
  }, {} as { [v: string]: FilterMenuItem });

export const getJoinedFiltersObject = () => ({
  ...getModalFiltersObject(),
  ...getHorizontalFiltersObject(),
});

export const getHorizontalMenuFiltersTypes = (): FilterType[] => getFilterMenuHorizontal().map((menu) => menu.type);
export const getModalMenuFiltersTypes = (): FilterType[] => getFilterMenuModal().map((menu) => menu.type);
export const getModalMenuSortsTypes = (): SortType[] => Object.values(SortType);

export const getHorizontalMenuFiltersValues = (): FilterType[] => getFilterMenuHorizontal().map((menu) => menu.value);
