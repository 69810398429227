import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';

import ShowIf from 'components/views/ShowIf';
import DefaultItemImage from 'images/blank-image-product.svg';
import { Order } from 'services/swipe-rx-pt/resources/orders/interfaces';
import { TFunction } from 'utils/Localization';
import { S } from './orders-rating.styles';

export interface OrderSectionProps {
  t: TFunction;
  orderDetail?: Order;
}

export const OrderSection: React.FC<React.PropsWithChildren<OrderSectionProps>> = (props) => {
  const { orderDetail, t } = props;

  const firstItem = orderDetail?.items?.find((item) => item);
  const itemCount = (orderDetail?.items || []).length || 0;

  const [imageSrc, setImageSrc] = useState(
    firstItem?.product?.thumbnail_photo || firstItem?.product?.display_photo || DefaultItemImage,
  );
  const handleImageError = (): void => {
    setImageSrc(DefaultItemImage);
  };

  return (
    <S.OrderSectionCard>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs>
          <S.OrderSectionInfo>{moment(orderDetail?.ordered_at).format('D MMMM YYYY · HH:mm')}</S.OrderSectionInfo>
        </Grid>
        <Grid item xs>
          <S.OrderSectionInfo style={{ textAlign: 'right' }}>
            {`${t('invoiceNumber')} ${orderDetail?.invoice?.invoice_number || 'N/A'}`}
          </S.OrderSectionInfo>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 20 }} alignItems="center">
        <Grid item xs={3}>
          <S.OrderSectionImage alt={firstItem?.product.name} src={imageSrc} onError={handleImageError} />
        </Grid>
        <Grid item xs>
          <Grid container direction="column" justify="space-between" style={{ height: '100%', minHeight: 80 }}>
            <Grid item xs>
              <S.OrderSectionTitle>{firstItem?.product.name}</S.OrderSectionTitle>
            </Grid>
            <Grid item xs>
              <S.OrderSectionSubTitle>
                <ShowIf condition={itemCount > 1}>{t('moreProducts', { n: itemCount - 1 })}</ShowIf>
                <ShowIf condition={itemCount === 1}>{t('oneItem')}</ShowIf>
              </S.OrderSectionSubTitle>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </S.OrderSectionCard>
  );
};
