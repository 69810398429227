import { recordException } from 'utils/Reporting';
import { ISearchSection } from 'components/common/SearchMenuSuggestions/components/interface';

import { trackSearchEvent } from 'utils/Analytics/CausalFoundry';
import { cancelPendingRequest, v3Client } from 'utils/Client/v3';
import { BasePageTracker } from 'utils/Analytics/Segment';
import { SECTION_TITLE_TRANSLATION } from 'components/common/SearchMenuSuggestions/constant';
import { removeUnnecessarySearchCharacters } from 'utils/Search/search.util';
import { SuggestionBoldStyling } from 'components/common/SearchMenuSuggestions/components/search-menu-section/search-sections.util';
import { SortType } from 'components/common/FilterToolbar/constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { SuggestionRequest, Actions } from './interface';
import { State } from '../../index';
import * as constants from './constants';
import { CACHE_KEY_RECENTLY_VIEWED_PRODUCTS } from '../Search/constants';
import { Product } from '../Product';

export const searchGlobalSuggestions: ThunkActionCreator<Actions, State> =
  (payload: SuggestionRequest) => async (dispatch, getState) => {
    try {
      cancelPendingRequest();
      let results: any;
      let autocompleteResults: any;
      const currentState = getState().searchSuggestions || {};
      const prevQuery = currentState.query;

      const { query: rawQuery, url, params, isGeneralSearch } = payload;

      const query = removeUnnecessarySearchCharacters(rawQuery);

      if (query === prevQuery) {
        dispatch({
          type: constants.SET_SEARCH_SUGGESTION_LOADING,
          loading: false,
        });
        return;
      }

      const queryAt = new Date();

      if (isGeneralSearch) {
        const [searchSuggestionsResp, autocompletesResp] = await Promise.all([
          v3Client.get(url, { query, ...params }, true),
          v3Client.get('products/search/autocompletes', { query, ...params }, true),
        ]);
        results = searchSuggestionsResp;
        autocompleteResults = autocompletesResp;
      } else {
        results = await v3Client.get(url, { query, ...params }, true);
      }
      const respondAt = new Date();
      const respondTime = respondAt.getTime() - queryAt.getTime();
      const { data, meta } = results || {};

      const productData = data?.products ? data?.products : data ?? [];

      // [causal foundry] search tracker
      trackSearchEvent(
        {
          contentBlock: 'e-commerce',
          filter: {},
          page: 1,
          query,
          results_list: productData.map((item) => `${item.id}`),
        },
        true,
      );

      let suggestions: ISearchSection[] = [];

      const addSuggestionSection = (
        title,
        products,
        config?: {
          meta?: any;
          fuzzy_search?: boolean;
          boldStyling?: SuggestionBoldStyling;
          id?: string;
          showSearchIcon?: boolean;
        },
      ): ISearchSection[] | any =>
        products?.length
          ? suggestions.concat({
              ...config,
              id: isGeneralSearch ? `${BasePageTracker.GENERAL_SEARCH}-${title || config?.id}` : '',
              title,
              products,
            })
          : suggestions;

      if (isGeneralSearch) {
        const { data: autocompleteData } = autocompleteResults || {};

        const autocompleteSuggestions = autocompleteData.map((data) => ({
          id: data.product_name,
          name: data.product_name,
        }));
        const title = data?.is_approximate ? SECTION_TITLE_TRANSLATION.DID_YOU_MEAN : null;
        suggestions = addSuggestionSection(title, autocompleteSuggestions, {
          boldStyling: SuggestionBoldStyling.BOLD_NONQUERY,
          id: 'autocomplete_listing',
          showSearchIcon: true,
        });
      }

      const title = !data?.is_approximate
        ? SECTION_TITLE_TRANSLATION.PRODUCTS
        : SECTION_TITLE_TRANSLATION.RELATED_PRODUCTS;
      const isApprox = data?.is_approximate ? data?.is_approximate : false;
      suggestions = addSuggestionSection(title, productData, { meta, fuzzy_search: isApprox });

      if (data?.manufacturers) {
        suggestions = addSuggestionSection(
          'manufacturer',
          data.manufacturers.map((name) => ({ id: name, name })),
          { showSearchIcon: true },
        );
      }

      dispatch({
        type: constants.SEARCH_SUGGESTIONS,
        suggestions,
        query,
        respondAt,
        respondTime,
        loading: false,
      });
    } catch (error) {
      recordException(error as Error, 'searchQuery', { payload });
      const errMsg = (error as Error).message;
      // eslint-disable-next-line no-console
      console.error(errMsg);
    } finally {
      dispatch({
        type: constants.SET_SEARCH_SUGGESTION_LOADING,
        loading: false,
      });
    }
  };

export const clearGlobalSuggestions: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_SUGGESTIONS,
  });
};

export const setMenuDrawer: ThunkActionCreator<Actions> = (isOpen: boolean) => async (dispatch) => {
  dispatch({
    type: constants.SET_MENU_DRAWER,
    menuDrawerOpen: isOpen,
  });
};

const getRecentlyViewedIds = (): number[] => {
  const cache: number[] = JSON.parse(localStorage.getItem(CACHE_KEY_RECENTLY_VIEWED_PRODUCTS) || '[]');
  const productIds = cache.slice(0, 20);
  return productIds;
};

export const setRecentlyViewedSectionProducts: ThunkActionCreator<Actions, State> =
  (product: Product) => async (dispatch, getState) => {
    if (!product.in_stock) return;
    const recentProducts = getState().searchSuggestions.recentlyViewedSectionProducts;

    const recentlyViewedProducts = [product, ...recentProducts.filter((entry) => entry.id !== product.id)].slice(0, 20);

    dispatch({
      type: constants.SET_RECENTLY_VIEWED_SECTION_PRODUCTS,
      products: recentlyViewedProducts,
    });
  };

export const fetchRecentlyViewedSectionProducts: ThunkActionCreator<Actions, State> =
  () => async (dispatch, getState) => {
    try {
      const productIds = getRecentlyViewedIds();
      const recentProducts = getState().searchSuggestions.recentlyViewedSectionProducts;
      if (!productIds.length && !recentProducts.length) return;
      if (!productIds.length && recentProducts.length) {
        dispatch({
          type: constants.SET_RECENTLY_VIEWED_SECTION_PRODUCTS,
          products: [],
        });
        return;
      }

      const result = await v3Client.get<{ data: Product[] }>(`products/search`, {
        page: 1,
        page_size: 20,
        product_ids: productIds,
        show_instock: true,
        show_stockout: false,
        order_by: SortType.PRODUCT_ID,
      });

      dispatch({
        type: constants.SET_RECENTLY_VIEWED_SECTION_PRODUCTS,
        products: result?.data || [],
      });
    } catch (error) {
      recordException(error as Error, 'searchQuery: fetchRecentlyViewedSectionProducts');
      const errMsg = (error as Error).message;
      // eslint-disable-next-line no-console
      console.error(errMsg);
    }
  };

export const syncRecentlyViewedSectionProductProps: ThunkActionCreator<Actions, State> =
  (productId: number, product: Partial<Product>) => async (dispatch, getState) => {
    const recentProducts = getState().searchSuggestions.recentlyViewedSectionProducts;

    const indexToUpdate = recentProducts.findIndex((obj) => obj.id === productId);

    if (indexToUpdate < 0) return;
    const updatedProducts = [
      ...recentProducts.slice(0, indexToUpdate),
      { ...recentProducts[indexToUpdate], ...product },
      ...recentProducts.slice(indexToUpdate + 1),
    ];

    dispatch({
      type: constants.SET_RECENTLY_VIEWED_SECTION_PRODUCTS,
      products: updatedProducts,
    });
  };

export const setSearchSuggestionLoading: ThunkActionCreator<Actions, State> =
  (loading = false) =>
  (dispatch, getState) => {
    const currentState = getState().searchSuggestions.loading;
    if (loading === currentState) return;

    dispatch({
      type: constants.SET_SEARCH_SUGGESTION_LOADING,
      loading,
    });
  };
