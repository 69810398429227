import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';

import { Row as SharedRow, Card as SharedCard } from 'components/common';
import * as colors from 'components/styles/colors';

const BaseOld = css`
  font-size: 14px;
  color: #9399a8;
  text-decoration: line-through;
  font-weight: normal;
`;

const Label = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.BLUISH_GRAY_LIGHT};
    text-transform: capitalize;
  }
`;

const StrongText = styled(Typography)`
  && {
    color: ${colors.TEXT};
    font-size: 14px;
    font-weight: 800;
    text-transform: capitalize;
  }
`;

const Text = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.TEXT_LIGHT};
    text-transform: capitalize;
  }
`;

const Value = styled(Typography)<any>`
  && {
    color: ${(props) => colors.getOrderStatusColor(props, 'secondary')};
    font-size: 14px;
    text-transform: capitalize;
    &.bold {
      font-weight: 700;
    }
    &.old {
      ${BaseOld};
      padding-right: 5px;
    }
  }
`;

const ColoredValue = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 800;
    color: ${colors.DARK_PRIMARY_COLOR};
    text-transform: uppercase;
  }
`;

const Total = styled(ColoredValue)`
  && {
    font-size: 14px;
    &.old {
      ${BaseOld};
      padding-right: 5px;
    }
    &.amount {
      text-transform: capitalize;
      color: ${colors.RED_HEART};
    }
  }
`;

const Row = styled(SharedRow)`
  padding: 9px 0;
  border-bottom: 1px solid #e8eaed;
  &.no-border {
    border-bottom: none;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const InnerRow = styled(SharedRow)`
  margin: 10px 0;

  &:last-child {
    margin-bottom: 2px;
  }
`;

const RowHeader = styled(SharedRow)`
  margin: 2px 0 10px;
`;

const GridRow = styled(SharedRow)`
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
  }
`;

const HeaderLabel = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: bold;
    color: ${colors.BLUISH_GRAY_LIGHT};
  }
`;

const Card = styled(SharedCard)`
  padding: 0 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
`;

const MiniLabelRow = styled(Row)`
  && {
    margin-bottom: -10px;
    border: none;
    box-shadow: none;
    padding-bottom: 0;
  }
`;

const MiniLabel = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.BLUISH_GRAY_LIGHT};
  }
`;

const ViewButton = styled.button`
  border: none;
  background: none;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.DARK_PRIMARY_COLOR};
  display: flex;
  align-items: center;
  padding: 0;
  text-transform: uppercase;

  &:active,
  &:focus {
    outline: none;
  }

  & svg {
    height: 14px;
    width: 14px;
    margin-left: 9px;
  }
`;

export const S = {
  Label,
  Value,
  Row,
  ColoredValue,
  Card,
  StrongText,
  Total,
  MiniLabel,
  ViewButton,
  MiniLabelRow,
  RowHeader,
  HeaderLabel,
  InnerRow,
  GridRow,
  Text,
};
