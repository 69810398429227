/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

import { CSSProperties } from '@material-ui/core/styles/withStyles';

const INACTIVE_COLOR = '#D5D5D5';

interface OrderSequenceIconProps {
  active?: boolean;
  style?: CSSProperties;
  variant?: string;
}

export const OrderConfirmIcon: React.FC<React.PropsWithChildren<OrderSequenceIconProps>> = ({ active = true, variant = 'primary', style }) => {
  const stroke = active ? '#FFA56E' : INACTIVE_COLOR;
  const fill = active ? '#FF7F30' : INACTIVE_COLOR;

  if (variant === 'secondary') {
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <circle cx="19" cy="19" r="18.5" fill="white" stroke={stroke} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9572 29.5556C25.8592 29.4474 25.8019 29.3041 25.8019 29.1516C25.8019 29.1516 25.8019 27.7789 25.8019 26.9594C25.8019 26.6421 25.9268 26.3379 26.1484 26.1136C26.3705 25.8897 26.6716 25.7635 26.9857 25.7635C27.7981 25.7635 29.1605 25.7635 29.1605 25.7635C29.3094 25.7635 29.4489 25.8198 29.5556 25.916V19.3754C29.5556 19.1111 29.4517 18.8577 29.2668 18.6709C29.0818 18.4841 28.8309 18.3791 28.5694 18.3791C27.1038 18.3791 23.3146 18.3791 21.849 18.3791C21.3042 18.3791 20.8628 18.825 20.8628 19.3754V28.5593C20.8628 29.1097 21.3042 29.5556 21.849 29.5556H25.9572ZM29.3 26.5036L26.5341 29.2929C26.4776 29.35 26.3926 29.3668 26.3187 29.336C26.2452 29.3049 26.197 29.2322 26.197 29.1516C26.197 29.1516 26.197 27.7789 26.197 26.9594C26.197 26.7479 26.28 26.5455 26.4282 26.3962C26.5759 26.2465 26.7763 26.1627 26.9857 26.1627H29.1605C29.2403 26.1627 29.3126 26.2114 29.343 26.286C29.3734 26.3607 29.3565 26.4465 29.3 26.5036Z"
          fill={fill}
        />
        <path
          d="M22.9092 22.3824H27.5084C27.6095 22.3824 27.6924 22.2872 27.6924 22.1682C27.6924 22.0492 27.6095 21.954 27.5084 21.954H22.9092C22.808 21.954 22.7251 22.0492 22.7251 22.1682C22.7251 22.2872 22.808 22.3824 22.9092 22.3824Z"
          fill="white"
        />
        <path
          d="M22.9092 20.6702H27.5084C27.6095 20.6702 27.6924 20.575 27.6924 20.456C27.6924 20.337 27.6095 20.2418 27.5084 20.2418H22.9092C22.808 20.2418 22.7251 20.337 22.7251 20.456C22.7251 20.575 22.808 20.6702 22.9092 20.6702Z"
          fill="white"
        />
        <path
          d="M22.9092 25.6375H24.7486C24.8498 25.6375 24.9327 25.5423 24.9327 25.4233C24.9327 25.3043 24.8498 25.2091 24.7486 25.2091H22.9092C22.808 25.2091 22.7251 25.3043 22.7251 25.4233C22.7251 25.5423 22.808 25.6375 22.9092 25.6375Z"
          fill="white"
        />
        <path
          d="M22.9092 26.8793H24.7486C24.8498 26.8793 24.9327 26.7841 24.9327 26.6651C24.9327 26.5461 24.8498 26.4509 24.7486 26.4509H22.9092C22.808 26.4509 22.7251 26.5461 22.7251 26.6651C22.7251 26.7841 22.808 26.8793 22.9092 26.8793Z"
          fill="white"
        />
        <path
          d="M22.9092 28.1211H24.7486C24.8498 28.1211 24.9327 28.0259 24.9327 27.9069C24.9327 27.7879 24.8498 27.6927 24.7486 27.6927H22.9092C22.808 27.6927 22.7251 27.7879 22.7251 27.9069C22.7251 28.0259 22.808 28.1211 22.9092 28.1211Z"
          fill="white"
        />
        <path
          d="M27.5084 23.6676H22.9092C22.808 23.6676 22.7251 23.7628 22.7251 23.8818C22.7251 24.0008 22.808 24.096 22.9092 24.096H27.5084C27.6095 24.096 27.6924 24.0008 27.6924 23.8818C27.6924 23.7628 27.6095 23.6676 27.5084 23.6676Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6269 14.857H26.8178C27.299 14.857 27.6927 15.2685 27.6927 15.7714L27.6665 16.0183L27.2103 17.7582H21.2986C20.7148 17.7582 20.2419 18.2288 20.2419 18.8098V25.8301H12.3815C11.5766 25.8301 10.9029 25.2632 10.7016 24.4951L8.47933 16.0183C8.45308 15.936 8.44434 15.8537 8.44434 15.7714C8.44434 15.2685 8.83805 14.857 9.31926 14.857H13.4927L17.3336 8.84915C17.6748 8.30964 18.436 8.30964 18.786 8.84915L22.6269 14.857ZM20.5183 14.857L18.0598 11.0072L15.6012 14.857H20.5183Z"
          fill={fill}
        />
      </svg>
    );
  }
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="19" cy="19" r="18.5" fill="white" stroke={stroke} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7031 14.9332H26.9166C27.4004 14.9332 27.7962 15.3496 27.7962 15.8586L27.7698 16.1084L26.7258 20.1165C26.2302 19.9624 25.7033 19.8794 25.157 19.8794C22.2422 19.8794 19.8792 22.2424 19.8792 25.1572C19.8792 25.457 19.9042 25.7509 19.9522 26.037H12.4027C11.5934 26.037 10.9161 25.4634 10.7138 24.6861L8.47952 16.1084C8.45313 16.0251 8.44434 15.9418 8.44434 15.8586C8.44434 15.3496 8.84017 14.9332 9.32397 14.9332H13.5198L17.3814 8.85391C17.7244 8.30797 18.4897 8.30797 18.8416 8.85391L22.7031 14.9332ZM20.5832 14.9332L18.1115 11.0377L15.6397 14.9332H20.5832Z"
        fill={fill}
      />
      <path
        d="M25.1577 20.7593C22.7291 20.7593 20.7595 22.7289 20.7595 25.1574C20.7595 27.586 22.7291 29.5556 25.1577 29.5556C27.5862 29.5556 29.5558 27.586 29.5558 25.1574C29.5558 22.7289 27.5862 20.7593 25.1577 20.7593ZM27.873 24.6029L25.1959 27.28C25.0812 27.3947 24.9473 27.4521 24.7943 27.4521C24.6414 27.4521 24.4884 27.3947 24.3928 27.28L22.8247 25.712C22.5953 25.4825 22.5953 25.1192 22.8247 24.9088C23.0351 24.6985 23.4175 24.6985 23.6279 24.9088L24.7943 26.0753L27.0699 23.7997C27.2803 23.5894 27.6627 23.5894 27.873 23.7997C27.9878 23.9145 28.0451 24.0483 28.0451 24.2013C28.0451 24.3543 27.9687 24.5073 27.873 24.6029Z"
        fill={fill}
      />
    </svg>
  );
};

export const OrderProcessIcon: React.FC<React.PropsWithChildren<OrderSequenceIconProps>> = ({ active = true, variant = 'primary', style }) => {
  const stroke = active ? '#DB8BD4' : INACTIVE_COLOR;
  const fill = active ? '#CC59C1' : INACTIVE_COLOR;

  if (variant === 'secondary') {
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <circle cx="19" cy="19" r="18.5" fill="#FFFEFE" stroke={stroke} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5455 13.542H17.9769C17.4886 13.542 17.1064 13.9069 17.1064 14.3731V21.1839C17.1064 21.6501 17.4886 22.015 17.9769 22.015H25.1103C25.5986 22.015 25.9808 21.6501 25.9808 21.1839V17.5566C25.8426 17.5678 25.7028 17.5735 25.5617 17.5735C23.1045 17.5735 21.0504 15.8476 20.5455 13.542Z"
          fill={fill}
        />
        <path
          d="M26.8511 23.2719H15.8538L12.7541 10.9475C12.6692 10.5623 12.3083 10.2988 11.9049 10.2988H9.31478C8.82648 10.2988 8.44434 10.684 8.44434 11.1299C8.44434 11.5759 8.84771 11.961 9.31478 11.961H11.2043L14.3039 24.2854C14.3889 24.6705 14.7498 24.934 15.1532 24.934H26.8299C27.3182 24.934 27.7003 24.5489 27.7003 24.103C27.7216 23.657 27.3394 23.2719 26.8511 23.2719Z"
          fill={fill}
        />
        <path
          d="M16.6607 29.5555C17.7394 29.5555 18.6139 28.7206 18.6139 27.6906C18.6139 26.6607 17.7394 25.8257 16.6607 25.8257C15.582 25.8257 14.7075 26.6607 14.7075 27.6906C14.7075 28.7206 15.582 29.5555 16.6607 29.5555Z"
          fill={fill}
        />
        <path
          d="M24.8765 29.5555C25.9553 29.5555 26.8297 28.7206 26.8297 27.6906C26.8297 26.6607 25.9553 25.8257 24.8765 25.8257C23.7978 25.8257 22.9233 26.6607 22.9233 27.6906C22.9233 28.7206 23.7978 29.5555 24.8765 29.5555Z"
          fill={fill}
        />
        <path
          d="M25.5614 8.44446C23.3555 8.44446 21.5674 10.2326 21.5674 12.4385C21.5674 14.6443 23.3555 16.4324 25.5614 16.4324C27.7672 16.4324 29.5554 14.6443 29.5554 12.4385C29.5554 10.2326 27.7672 8.44446 25.5614 8.44446ZM25.615 13.5421H24.7007L24.9032 13.7446C25.0332 13.8746 25.0332 14.0852 24.9032 14.2152C24.8382 14.2802 24.753 14.3127 24.6679 14.3127C24.5827 14.3127 24.4975 14.2802 24.4325 14.2152L23.6621 13.4448C23.6313 13.4141 23.6069 13.3772 23.59 13.3364C23.5564 13.2551 23.5564 13.1635 23.59 13.0821C23.6069 13.0413 23.6313 13.0045 23.6621 12.9737L24.4325 12.2031C24.5626 12.0731 24.7732 12.0731 24.9032 12.2031C25.0332 12.333 25.0332 12.5438 24.9032 12.6738L24.7006 12.8764H25.615C25.799 12.8764 25.9478 13.0255 25.9478 13.2093C25.9478 13.3931 25.799 13.5421 25.615 13.5421ZM27.4606 11.9032L26.6902 12.6738C26.6252 12.7388 26.54 12.7713 26.4549 12.7713C26.3697 12.7713 26.2846 12.7388 26.2196 12.6738C26.0896 12.5439 26.0896 12.3331 26.2196 12.2031L26.4222 12.0005H25.5077C25.3238 12.0005 25.1749 11.8514 25.1749 11.6676C25.1749 11.4838 25.3238 11.3348 25.5077 11.3348H26.4221L26.2196 11.1323C26.0896 11.0023 26.0896 10.7917 26.2196 10.6617C26.3496 10.5317 26.5602 10.5317 26.6902 10.6617L27.4606 11.4321C27.4914 11.4628 27.5158 11.4997 27.5327 11.5405C27.5664 11.6218 27.5664 11.7134 27.5327 11.7947C27.5158 11.8356 27.4914 11.8724 27.4606 11.9032Z"
          fill={fill}
        />
      </svg>
    );
  }

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="19" cy="19" r="18.5" fill="white" stroke={stroke} />
      <path
        d="M18.8947 21.2889H26.715C27.2503 21.2889 27.6693 20.8889 27.6693 20.3778V12.9111C27.6693 12.4 27.2503 12 26.715 12H18.8947C18.3594 12 17.9404 12.4 17.9404 12.9111V20.3778C17.9404 20.8889 18.3594 21.2889 18.8947 21.2889Z"
        fill={fill}
      />
      <path
        d="M28.6235 22.6667H16.5672L13.1691 9.15557C13.076 8.73335 12.6803 8.44446 12.2381 8.44446H9.3986C8.86328 8.44446 8.44434 8.86668 8.44434 9.35557C8.44434 9.84446 8.88656 10.2667 9.3986 10.2667H11.4701L14.8682 23.7778C14.9613 24.2 15.3569 24.4889 15.7992 24.4889H28.6003C29.1356 24.4889 29.5545 24.0667 29.5545 23.5778C29.5778 23.0889 29.1589 22.6667 28.6235 22.6667Z"
        fill={fill}
      />
      <path
        d="M17.4518 29.5555C18.6344 29.5555 19.5931 28.6402 19.5931 27.5111C19.5931 26.3819 18.6344 25.4666 17.4518 25.4666C16.2692 25.4666 15.3105 26.3819 15.3105 27.5111C15.3105 28.6402 16.2692 29.5555 17.4518 29.5555Z"
        fill={fill}
      />
      <path
        d="M26.4591 29.5555C27.6417 29.5555 28.6004 28.6402 28.6004 27.5111C28.6004 26.3819 27.6417 25.4666 26.4591 25.4666C25.2766 25.4666 24.3179 26.3819 24.3179 27.5111C24.3179 28.6402 25.2766 29.5555 26.4591 29.5555Z"
        fill={fill}
      />
    </svg>
  );
};

export const OrderOnDeliveryIcon: React.FC<React.PropsWithChildren<OrderSequenceIconProps>> = ({
  active = true,
  variant = 'primary',
  style,
}) => {
  const stroke = active ? '#5D70EF' : INACTIVE_COLOR;
  const fill = active ? '#1733DE' : INACTIVE_COLOR;
  const fill2 = active ? '#1733E8' : INACTIVE_COLOR;

  if (variant === 'secondary') {
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <circle cx="19" cy="19" r="18.5" fill="white" stroke={stroke} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0276 20.7222L21.4705 22.3493C20.9248 26.2501 19.8707 26.9442 22.2857 26.4448C22.4635 25.8436 23.2651 22.5633 23.2266 21.9643C23.1508 20.8026 20.9723 20.1979 19.9341 19.7937L20.2919 17.1894C20.7561 17.3921 20.4911 17.2325 20.8761 17.5699C21.4094 18.0353 21.6845 18.6727 22.7216 18.4757C23.1247 18.3987 25.1424 17.6254 25.4583 17.3785C25.5466 15.4027 23.7871 16.8237 22.4363 17.0966C21.8294 16.3663 20.5409 14.5987 19.574 14.4176C17.4692 14.0247 17.7115 14.6157 17.3129 17.34C17.1499 18.4542 16.7876 19.7212 17.0276 20.7222ZM19.488 10.7569C17.4748 11.8156 19.0747 14.5025 20.8636 13.608C22.7579 12.6603 21.3584 9.77404 19.488 10.7569Z"
          fill={fill}
        />
        <path
          d="M15.2 14.1111H13.6293V17.2018C13.6293 17.2951 13.5813 17.3805 13.5013 17.4285C13.4587 17.4551 13.4107 17.4685 13.3627 17.4685C13.3227 17.4685 13.28 17.4578 13.2427 17.4391L12 16.8071L10.7573 17.4391C10.6747 17.4818 10.576 17.4765 10.4987 17.4285C10.4187 17.3805 10.3707 17.2951 10.3707 17.2018V14.1111H8.8C8.35999 14.1111 8 14.4711 8 14.9111V20.1751C8 20.6151 8.35999 20.9751 8.8 20.9751H15.2C15.64 20.9751 16 20.6151 16 20.1751V14.9111C16 14.4711 15.64 14.1111 15.2 14.1111Z"
          fill={fill}
        />
        <path
          d="M12.1198 16.2711L13.0958 16.7671V14.1111H10.9038V16.7671L11.8798 16.2711C11.9171 16.2525 11.9571 16.2418 11.9998 16.2418C12.0425 16.2418 12.0825 16.2525 12.1198 16.2711Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6207 18.4746L25.7428 22.0912C24.0569 23.6582 23.493 24.3886 22.7401 26.917L19.2493 26.9611L17.9371 23.9187C18.3809 23.6979 19.3286 23.0015 19.495 22.6777C18.878 21.5182 17.4479 21.8579 15.9239 21.8568C14.4543 21.8556 12.9812 21.85 11.5116 21.859C10.8436 22.8068 10.4778 23.7522 10.6364 25.2865C11.0281 25.0736 12.6721 22.5905 15.3703 23.947C16.7312 24.6309 17.5114 26.5286 18.5326 27.7968L23.6911 27.8024L23.8349 27.1174C24.1101 24.315 26.0655 23.0344 28.2382 23.5394C29.1893 23.7613 29.5018 24.4271 30.2616 23.6798C29.366 21.9077 27.5974 21.8602 27.1637 21.6133C26.7708 21.3891 26.8093 21.003 26.5603 20.5422C26.995 20.1504 27.705 20.036 27.5068 19.1359C27.3324 18.3421 26.4697 18.2855 25.9137 18.6241L25.5797 18.1032L24.6207 18.4746Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.6875 24.253C22.8446 25.443 24.7355 31.2007 28.5636 29.9077C29.8079 29.4864 30.9911 27.9284 30.3978 26.1258C29.9653 24.8169 28.4889 23.6948 26.6875 24.253ZM13.1266 24.1975C9.36752 25.2811 10.8576 30.9743 14.7287 29.9722C16.0693 29.6246 17.2276 28.1175 16.8403 26.3647C16.5346 24.9776 14.8555 23.6993 13.1266 24.1975Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5557 12.0065C30.5557 9.79488 28.7608 7.99998 26.5492 7.99998C24.3376 7.99998 22.5427 9.79488 22.5427 12.0065C22.5427 14.218 24.3376 16.0129 26.5492 16.0129C28.7608 16.0129 30.5557 14.218 30.5557 12.0065ZM24.0445 12.6295V12.3857C24.0445 12.1774 24.2147 12.0071 24.4231 12.0071H26.0484V9.88169C26.0484 9.67335 26.2186 9.50308 26.427 9.50308H26.6707C26.8791 9.50308 27.0493 9.67335 27.0493 9.88169L27.05 12.3731C27.05 12.3771 27.05 12.3817 27.05 12.3857V12.6295C27.05 12.8378 26.8797 13.0081 26.6714 13.0081H24.4231C24.2147 13.0081 24.0445 12.8378 24.0445 12.6295Z"
          fill={fill2}
        />
      </svg>
    );
  }

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="19" cy="19" r="18.5" fill="white" stroke={stroke} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0276 19.1666L21.4705 20.7937C20.9248 24.6945 19.8707 25.3885 22.2857 24.8892C22.4635 24.288 23.2651 21.0077 23.2266 20.4087C23.1508 19.247 20.9723 18.6423 19.9341 18.2381L20.2919 15.6338C20.7561 15.8365 20.4911 15.6769 20.8761 16.0143C21.4094 16.4797 21.6845 17.1171 22.7216 16.9201C23.1247 16.8431 25.1424 16.0698 25.4583 15.8229C25.5466 13.8471 23.7871 15.2681 22.4363 15.541C21.8294 14.8107 20.5409 13.0431 19.574 12.862C17.4692 12.4691 17.7115 13.0601 17.3129 15.7844C17.1499 16.8986 16.7876 18.1656 17.0276 19.1666ZM19.488 9.20127C17.4748 10.26 19.0747 12.9469 20.8636 12.0524C22.7579 11.1047 21.3584 8.21844 19.488 9.20127Z"
        fill={fill}
      />
      <path
        d="M15.2 12.5555H13.6293V15.6462C13.6293 15.7395 13.5813 15.8249 13.5013 15.8729C13.4587 15.8995 13.4107 15.9129 13.3627 15.9129C13.3227 15.9129 13.28 15.9022 13.2427 15.8835L12 15.2515L10.7573 15.8835C10.6747 15.9262 10.576 15.9209 10.4987 15.8729C10.4187 15.8249 10.3707 15.7395 10.3707 15.6462V12.5555H8.8C8.35999 12.5555 8 12.9155 8 13.3555V18.6195C8 19.0595 8.35999 19.4195 8.8 19.4195H15.2C15.64 19.4195 16 19.0595 16 18.6195V13.3555C16 12.9155 15.64 12.5555 15.2 12.5555Z"
        fill={fill}
      />
      <path
        d="M12.1198 14.7155L13.0958 15.2115V12.5555H10.9038V15.2115L11.8798 14.7155C11.9171 14.6969 11.9571 14.6862 11.9998 14.6862C12.0425 14.6862 12.0825 14.6969 12.1198 14.7155Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6207 16.919L25.7428 20.5355C24.0569 22.1026 23.493 22.833 22.7401 25.3614L19.2493 25.4055L17.9371 22.3631C18.3809 22.1423 19.3286 21.4459 19.495 21.1221C18.878 19.9626 17.4479 20.3023 15.9239 20.3012C14.4543 20.3 12.9812 20.2944 11.5116 20.3034C10.8436 21.2512 10.4778 22.1966 10.6364 23.7309C11.0281 23.518 12.6721 21.0349 15.3703 22.3914C16.7312 23.0753 17.5114 24.973 18.5326 26.2412L23.6911 26.2468L23.8349 25.5618C24.1101 22.7594 26.0655 21.4787 28.2382 21.9838C29.1893 22.2057 29.5018 22.8715 30.2616 22.1242C29.366 20.3521 27.5974 20.3046 27.1637 20.0577C26.7708 19.8335 26.8093 19.4474 26.5603 18.9866C26.995 18.5948 27.705 18.4804 27.5068 17.5803C27.3324 16.7865 26.4697 16.7299 25.9137 17.0685L25.5797 16.5476L24.6207 16.919Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6875 22.6974C22.8446 23.8874 24.7355 29.6451 28.5636 28.352C29.8079 27.9308 30.9911 26.3728 30.3978 24.5702C29.9653 23.2613 28.4889 22.1392 26.6875 22.6974ZM13.1266 22.6419C9.36752 23.7255 10.8576 29.4187 14.7287 28.4166C16.0693 28.069 17.2276 26.5619 16.8403 24.8091C16.5346 23.422 14.8555 22.1437 13.1266 22.6419Z"
        fill={fill}
      />
    </svg>
  );
};

export const OrderDeliveredIcon: React.FC<React.PropsWithChildren<OrderSequenceIconProps>> = ({ active = true, style }) => {
  const stroke = active ? '#53CE9C' : INACTIVE_COLOR;
  const fill = active ? '#14C47D' : INACTIVE_COLOR;

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="19" cy="19" r="18.5" fill="white" stroke={stroke} />
      <path
        d="M20.4559 15.0508H18.6872V18.5312C18.6872 18.6363 18.6332 18.7324 18.5431 18.7864C18.495 18.8164 18.441 18.8315 18.3869 18.8315C18.3419 18.8315 18.2938 18.8195 18.2518 18.7984L16.8525 18.0867L15.4531 18.7984C15.36 18.8465 15.2489 18.8405 15.1618 18.7864C15.0718 18.7324 15.0177 18.6363 15.0177 18.5312V15.0508H13.249C12.7535 15.0508 12.3481 15.4562 12.3481 15.9517V21.8794C12.3481 22.3748 12.7535 22.7803 13.249 22.7803H20.4559C20.9514 22.7803 21.3568 22.3748 21.3568 21.8794V15.9517C21.3568 15.4562 20.9514 15.0508 20.4559 15.0508Z"
        fill={fill}
      />
      <path
        d="M16.9877 17.4832L18.0868 18.0417V15.0508H15.6184V18.0417L16.7175 17.4832C16.7595 17.4621 16.8046 17.4501 16.8526 17.4501C16.9006 17.4501 16.9457 17.4621 16.9877 17.4832Z"
        fill={fill}
      />
      <path
        d="M16.4935 26.9939C16.4465 27.0228 16.3068 27.0624 16.2526 27.0624H14.7599L14.7506 27.0623H14.1485C13.9835 27.0623 13.8497 26.9282 13.8497 26.762C13.8497 26.5966 13.9843 26.4618 14.149 26.4618H15.4994C16.0798 26.4618 16.5523 25.9917 16.5523 25.4107C16.5523 24.8296 16.0839 24.3597 15.5039 24.3597H12.6494C12.2234 24.3597 11.6227 24.5015 11.2425 24.6916L8.78863 25.9185C8.7551 25.9344 8.72328 25.9532 8.69351 25.9747C8.64408 26.0103 8.60132 26.0526 8.56585 26.0996C8.48959 26.2003 8.44434 26.3258 8.44434 26.4618V28.8641C8.44434 29.1958 8.71322 29.4647 9.04491 29.4647H16.2527C16.6996 29.4647 17.3068 29.286 17.6843 29.0433L20.9273 26.9585C21.4609 26.6155 21.6086 25.907 21.2559 25.378C20.9109 24.8605 20.2121 24.7055 19.6818 25.0318L16.4935 26.9939Z"
        fill={fill}
      />
      <path
        d="M21.7474 10.8468C21.6931 10.8468 21.5534 10.8864 21.5064 10.9153L18.3181 12.8773C17.7878 13.2037 17.0891 13.0487 16.7441 12.5312C16.3914 12.0021 16.539 11.2937 17.0726 10.9506L20.3157 8.86584C20.6932 8.62316 21.3003 8.44446 21.7472 8.44446H28.955C29.2867 8.44446 29.5556 8.71334 29.5556 9.04503V11.4473C29.5556 11.5834 29.5103 11.7089 29.4341 11.8096C29.3986 11.8566 29.3558 11.8988 29.3064 11.9344C29.2766 11.9559 29.2448 11.9748 29.2113 11.9906L26.7574 13.2176C26.3772 13.4077 25.7765 13.5494 25.3506 13.5494H22.496C21.916 13.5494 21.4476 13.0796 21.4476 12.4984C21.4476 11.9175 21.9201 11.4474 22.5006 11.4474H23.8509C24.0156 11.4474 24.1502 11.3125 24.1502 11.1471C24.1502 10.9809 24.0164 10.8468 23.8514 10.8468H23.2494C23.2462 10.8468 23.2431 10.8468 23.24 10.8468H21.7474Z"
        fill={fill}
      />
    </svg>
  );
};

export const OrderCancelledIcon: React.FC<React.PropsWithChildren<OrderSequenceIconProps>> = ({ active = true, style }) => {
  const stroke = active ? '#E98E97' : INACTIVE_COLOR;
  const fill = active ? '#E35A68' : INACTIVE_COLOR;

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="19" cy="19" r="18.5" fill="white" stroke={stroke} />
      <path
        d="M21.5481 17.5222L22.6519 18.0832V15.0794H20.1729V18.0832L21.2766 17.5222C21.3189 17.5011 21.3641 17.4891 21.4124 17.4891C21.4606 17.4891 21.5059 17.5011 21.5481 17.5222Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2552 15.0793H25.0315C25.5291 15.0793 25.9363 15.4865 25.9363 15.9841V18.8268C24.1026 19.2813 22.7256 20.893 22.6249 22.8422H17.7934C17.2958 22.8422 16.8887 22.435 16.8887 21.9374V15.9841C16.8887 15.4865 17.2958 15.0793 17.7934 15.0793H19.5698V18.5747C19.5698 18.6803 19.6241 18.7768 19.7145 18.8311C19.802 18.8854 19.9136 18.8914 20.0071 18.8431L21.4125 18.1284L22.8179 18.8431C22.8601 18.8642 22.9083 18.8763 22.9536 18.8763C23.0079 18.8763 23.0621 18.8612 23.1104 18.8311C23.2009 18.7768 23.2552 18.6803 23.2552 18.5747V15.0793Z"
        fill={fill}
      />
      <path
        d="M26.992 19.3016C24.9186 19.3016 23.2222 20.9981 23.2222 23.0715C23.2222 25.1449 24.9186 26.8413 26.992 26.8413C29.0654 26.8413 30.7618 25.1449 30.7618 23.0715C30.7618 20.9981 29.0654 19.3016 26.992 19.3016ZM28.7638 24.3155C28.9146 24.4663 28.9146 24.6925 28.7638 24.8433C28.613 24.9941 28.3868 24.9941 28.236 24.8433L26.992 23.5992L25.748 24.8433C25.5972 24.9941 25.371 24.9941 25.2202 24.8433C25.0694 24.6925 25.0694 24.4663 25.2202 24.3155L26.4642 23.0715L25.2202 21.8274C25.0694 21.6766 25.0694 21.4504 25.2202 21.2996C25.371 21.1489 25.5972 21.1489 25.748 21.2996L26.992 22.5437L28.236 21.2996C28.3868 21.1489 28.613 21.1489 28.7638 21.2996C28.9146 21.4504 28.9146 21.6766 28.7638 21.8274L27.5198 23.0715L28.7638 24.3155Z"
        fill={fill}
      />
      <path
        d="M21.5625 10.9259C21.6097 10.8969 21.75 10.8571 21.8045 10.8571L23.3036 10.8571L23.313 10.8572H23.9176C24.0833 10.8572 24.2177 10.9919 24.2177 11.1588C24.2177 11.3249 24.0826 11.4604 23.9171 11.4604L22.561 11.4604C21.978 11.4604 21.5035 11.9325 21.5035 12.5159C21.5035 13.0996 21.9739 13.5715 22.5564 13.5715H25.4233C25.8511 13.5715 26.4544 13.4291 26.8362 13.2382L29.3007 12.006C29.3344 11.99 29.3663 11.9711 29.3962 11.9495C29.4459 11.9137 29.4888 11.8713 29.5244 11.8241C29.601 11.723 29.6465 11.5969 29.6465 11.4603V9.04761C29.6465 8.71448 29.3764 8.44443 29.0433 8.44443L21.8043 8.44443C21.3555 8.44443 20.7457 8.62391 20.3666 8.86764L17.1096 10.9615C16.5736 11.306 16.4253 12.0175 16.7796 12.5488C17.1261 13.0686 17.8278 13.2243 18.3604 12.8965L21.5625 10.9259Z"
        fill={fill}
      />
      <path
        d="M16.2863 27.1429C16.3408 27.1429 16.4812 27.103 16.5283 27.074L19.7304 25.1035C20.263 24.7757 20.9648 24.9314 21.3113 25.4511C21.6655 25.9825 21.5172 26.694 20.9813 27.0385L17.7242 29.1323C17.3451 29.3761 16.7353 29.5555 16.2865 29.5555H9.04751C8.71439 29.5555 8.44434 29.2855 8.44434 28.9524L8.44434 26.5397C8.44434 26.403 8.48979 26.277 8.56638 26.1759C8.602 26.1287 8.64495 26.0862 8.69459 26.0505C8.72449 26.0289 8.75645 26.0099 8.79012 25.994L11.2546 24.7618C11.6365 24.5708 12.2397 24.4285 12.6676 24.4285L15.5344 24.4285C16.117 24.4285 16.5874 24.9004 16.5874 25.4841C16.5874 26.0675 16.1128 26.5396 15.5299 26.5396H14.1737C14.0083 26.5396 13.8731 26.6751 13.8731 26.8412C13.8731 27.0081 14.0075 27.1428 14.1732 27.1428H14.7778C14.781 27.1428 14.7841 27.1428 14.7873 27.1429L16.2863 27.1429Z"
        fill={fill}
      />
    </svg>
  );
};
