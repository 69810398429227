import { CartProduct } from 'store/reducers/Counter/actions';

import { SwipeRxPt } from '../../SwipeRxPt.class';

export class SwipeRxPtCart {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async getAll(params?: { flag?: string; distributor?: any }): Promise<any> {
    const { data } = await this.base.getV2('/cart', params);

    return data;
  }

  async create(body: { items: CartProduct }): Promise<any> {
    const { data } = await this.base.post('/cart', body);

    return data;
  }

  async update(body: { items: CartProduct; transfer_products_from?: number }): Promise<any> {
    const { data } = await this.base.put('/cart', body);

    return data;
  }

  async clear(): Promise<any> {
    const { data } = await this.base.patch('/cart/clear');

    return data;
  }

  async trackItem(pharmacyId: number, item: any): Promise<any> {
    const { data } = await this.base.patch('/marketing-cart/add', { pharmacy_id: pharmacyId, item });

    return data;
  }

  async getMarketingCart(): Promise<any> {
    const { data } = await this.base.getV2(`/marketing-cart`);

    return data;
  }

  async removeItemTracking(pharmacyId: number, productId: number): Promise<any> {
    const { data } = await this.base.patch('/marketing-cart/remove', {
      pharmacy_id: pharmacyId,
      product_id: productId,
    });

    return data;
  }

  // TODO: correct return type
  async getDeliveryEstimation(): Promise<any> {
    const { data } = await this.base.getV2('/cart/delivery-estimation');

    return data;
  }
}
