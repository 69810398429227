import { shouldShowCreditInfo } from './show-credit-info';

export const checkOverCreditLimit = (totalAmount: number, remainingLimit: number): boolean => {
  if (shouldShowCreditInfo()) {
    return totalAmount >= remainingLimit;
  }

  /**
   * Always false for non-credit to hide all credit related errors
   */
  return false;
};
