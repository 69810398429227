import React from 'react';
import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Typography, Grid, Card } from '@material-ui/core';
import { ShoppingBasket } from '@material-ui/icons';

import { TFunction } from 'utils/Localization';
import { getMarketId } from 'utils/MarketConfig';

import { WHITE } from 'components/styles/colors';

import { CartNotificationContainer } from './style';

interface Props {
  t: TFunction;
  itemCount: number;
}

const Wrapper: React.FC<React.PropsWithChildren<Props>> = ({ itemCount, t }) => {
  const { push } = useHistory();
  const marketId = getMarketId();

  return (
    <CartNotificationContainer onClick={() => push(`/${marketId}/prices`)}>
      <Card className="card">
        <Grid container direction="row" alignItems="center" style={{ padding: '0px 2%' }}>
          <Grid item xs={1}>
            <ShoppingBasket className="basket-icon" />
          </Grid>
          <Grid item xs={9} style={{ textAlign: 'center' }} className="grid-view">
            <Typography style={{ color: WHITE }}>
              {itemCount} {t('addedToCart')}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'end' }} className="grid-view">
            <Typography className="cart-text">{t('seeCart')}</Typography>
          </Grid>
        </Grid>
      </Card>
    </CartNotificationContainer>
  );
};

export const CartNotification = translate('productDetailPage')(Wrapper);
