import { Grid, SvgIcon, Typography } from '@material-ui/core';
import React from 'react';

type SectionProps = {
  title: string;
  icon: React.FunctionComponent<React.PropsWithChildren<unknown>>;
};

export const Section: React.FC<React.PropsWithChildren<SectionProps>> = (props) => {
  const { title, icon } = props;

  return (
    <Grid container style={{ marginBottom: 16 }}>
      <SvgIcon component={icon} style={{ width: 20, height: 20, marginRight: 8 }} />
      <Typography
        style={{
          fontSize: 12,
          textTransform: 'uppercase',
          color: '#717786',
          letterSpacing: '0.16px',
          lineHeight: '20px',
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
};
