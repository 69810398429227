import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG } from 'services';
import { OrgMembershipTypeEnum } from 'store/reducers/Auth';

export const isMembershipVerifiedNoSipnap = (membership_status: OrgMembershipTypeEnum[] | undefined = []): boolean =>
  membership_status?.includes(OrgMembershipTypeEnum.verified) &&
  !membership_status?.includes(OrgMembershipTypeEnum.sipnap);

export const isVerifiedNoSipnapAndPrekursor = (
  membership_status: OrgMembershipTypeEnum[] | undefined = [],
  flag?: string | null,
): boolean => {
  const isVerifiedNoSipnap = isMembershipVerifiedNoSipnap(membership_status);
  const isPrekursor = flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG;
  return isVerifiedNoSipnap && isPrekursor;
};
