import React, { useRef, useEffect } from 'react';
import { ButtonBase } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Modal } from 'components/common';
import LoadingPage from 'components/pages/LoadingPage/component';
import { ReactComponent as BubbleIcon } from 'images/ic_bubble.svg';
import { State as StoreState } from 'store';
import { trackContactCustomerCare } from 'utils/Analytics/Segment';
import { getCustomerSuccessSupport, getWhatsappSupportLink } from 'utils/MarketConfig';
import { Order } from 'services/swipe-rx-pt/resources/orders/interfaces';
import { closeOrderRating, setOrderRating, setCheckedOptions, setFeedbacks } from 'store/reducers/Orders/actions';
import { TFunction } from 'utils/Localization';
import ShowIf from 'components/views/ShowIf';

import { ORDER_ID_INDEX } from '../../constants';

import { FeedbackSection } from './feedback-section';
import { OrderSection } from './order-section';
import { S } from './orders-rating.styles';
import { RatingSection } from './rating-section';
import { SubmitSection } from './submit-section';
import { OrdersRatingThankYou } from './orders-rating-thank-you.component';

interface Props {
  t: TFunction;
}

const OrdersRatingBase: React.FC<React.PropsWithChildren<Props>> = ({ t }) => {
  const location = useLocation();
  const history = useHistory();
  const orderId = Number(location.pathname.split('/')[ORDER_ID_INDEX] || 0);
  const dispatch = useDispatch();
  const feedbackRef = useRef<HTMLDivElement>(null);

  const customerSuccessSupport = getCustomerSuccessSupport();

  const {
    purchaseOrder: { orderDetail, loading },
    orders: {
      orderRating: { isOpen, rate, currentOrderRating },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const scrollToFeedback = (): void => {
    if (feedbackRef.current) {
      feedbackRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClose = (): void => {
    dispatch(closeOrderRating());

    if (orderId > 0) {
      history.push('.');
    }
  };

  const handleRateClick = (value: number): void => {
    if (!currentOrderRating) dispatch(setOrderRating(value));

    scrollToFeedback();
  };

  const whatsappSupportLink = getWhatsappSupportLink(
    t(`needAssistanceOrderViaWhatsapp`, { poNumber: orderDetail.po_number }),
  );

  const openChat = (): void => {
    trackContactCustomerCare(orderDetail.pharmacy_id);

    if (whatsappSupportLink) {
      window.open(whatsappSupportLink);
    } else {
      window.open(customerSuccessSupport.link, customerSuccessSupport.target);
    }
  };

  useEffect(
    () => () => {
      dispatch(setCheckedOptions([]));
      dispatch(setFeedbacks(''));
      dispatch(setOrderRating(null));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Modal open={isOpen} onLoad={scrollToFeedback} onClose={handleClose} style={{ zIndex: 100 }}>
        <S.Wrapper>
          <S.Header>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid container alignItems="center" justifyContent="flex-start">
                <S.ButtonBack onClick={handleClose} data-testid="back-btn">
                  <S.BackIcon />
                </S.ButtonBack>
                <S.Title align="left">{t('writeYourFeedback')}</S.Title>
              </Grid>
              <ButtonBase onClick={openChat} data-testid="order-rating-open-chat">
                <BubbleIcon />
              </ButtonBase>
            </Grid>
          </S.Header>

          <OrderSection t={t} orderDetail={orderDetail as Order} />
          <RatingSection
            t={t}
            orderDetail={orderDetail as Order}
            currentRate={rate || 0}
            handleRateClick={handleRateClick}
            currentOrderRating={currentOrderRating}
          />
          <ShowIf condition={(currentOrderRating?.rate || rate || 0) >= 1}>
            <FeedbackSection ref={feedbackRef} t={t} currentOrderRating={currentOrderRating} />
          </ShowIf>

          <ShowIf condition={!currentOrderRating}>
            <SubmitSection t={t} />
          </ShowIf>
        </S.Wrapper>

        {loading && <LoadingPage inverse />}
      </Modal>
      <OrdersRatingThankYou />
    </>
  );
};

export const OrdersRating = React.memo(translate('orders')(OrdersRatingBase));
