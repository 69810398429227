import React from 'react';
import DividerWithText from '../../DividerWithText/DividerWithText.component';

export const EndOfResultDivider: React.FC<{ end: boolean }> = ({ end }): JSX.Element | null => {
  if (end) {
    // FIXME:: SPT-18782 update with translation when available
    return <DividerWithText text="End of Result" />;
  }
  return null;
};
