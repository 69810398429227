import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { useDispatch } from 'react-redux';

import { CartProduct, UpdateCartItemType } from 'store/reducers/Counter/actions';
import { removeItemFromCartMarketing } from 'store/reducers/Cart/actions';

import { setOpenSnackbar, setOpenSnackbarCart, setCloseSnackbarCart } from 'store/reducers/Utils/actions';
import { useTierPricing } from 'hooks';
import { ConditionalRender } from 'components/common/ConditionalRender';
import { trackEditProductQty, trackRemoveProduct } from '../../../../../utils/Analytics/Segment';

import { Product } from '../../../../../store/reducers/Product';
import EditQuantityBox from '../../../EditQuantityBox';
import { Color } from '../../ProductCard.interface';
import { ConfirmationDialog } from '../confirmation-dialog';

import { S } from './ProductCardQuantityBox.styles';
import { useProductLimit } from './hooks/use-product-limit.hook';

interface Props {
  t: any;
  product: Product;
  color: Color;
  counterList: any;
  increment: (product: Product) => any;
  decrement: (product: Product) => any;
  updateCounter: (product: Product, quantity: number) => any;
  changeColor: (itemCount: number) => void;
  updateCartItem: (product: CartProduct, type: UpdateCartItemType) => void;
  isUnverifiedCartOpen?: boolean;
}

export const QuantityBox: React.FC<React.PropsWithChildren<Props>> = ({
  color,
  product,
  increment,
  decrement,
  counterList,
  updateCounter,
  updateCartItem,
  changeColor,
  isUnverifiedCartOpen,
  t,
}) => {
  const dispatch = useDispatch();
  const {
    maxQuantityEnabled,
    maxMonthlyQuantityEnabled,
    productLimitExceeded,
    productMonthlyLimitExceeded,
    stockLimitExceeded,
    remainingStock,
    purchaseableQuantity,
    purchaseableMonthlyQuantity,
    hasPurchased,
    maxPurchaseMonthlyLimit,
  } = useProductLimit(product, counterList);

  const { itemCount } = useTierPricing(product);

  const [showEditQuantityBox, setShowEditQuantityBox] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [onUpdateCounter, setOnUpdateCounter] = useState(false);

  const counterValue = counterList[product.id] ? counterList[product.id].count : null;
  const isMaxQtyReached = stockLimitExceeded || productLimitExceeded;
  const isMaxQtyMonthlyReached = stockLimitExceeded || productMonthlyLimitExceeded;

  useEffect(() => {
    changeColor(counterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterValue]);

  const showEditQuantity = (): void => {
    dispatch(setCloseSnackbarCart());
    setShowEditQuantityBox(true);
  };

  const closeEditQuantity = (): void => {
    setShowEditQuantityBox(false);
  };

  const closeConfirmationDialog = (): void => {
    setShowConfirmationDialog(false);
  };

  const setSnackbarCart = (value: number): void => {
    dispatch(
      setOpenSnackbarCart({
        open: true,
        productId: product.id,
        itemCount: value,
        maxPurchaseLimit: {
          purchaseableQuantity,
          hasPurchased,
        },
      }),
    );
  };

  const handleYes = (): void => {
    // Check if the counter value is from decrement button counter or in edit button counter.
    if (onUpdateCounter) {
      trackEditProductQty(product, 0);
      changeColor(0);
      updateCounter(product, 0);
    } else {
      const updatedProduct = decrement(product);
      updateCartItem(updatedProduct, UpdateCartItemType.UPDATE);
    }

    trackRemoveProduct(product);
    dispatch(removeItemFromCartMarketing(product.id));
    dispatch(setCloseSnackbarCart());

    setShowConfirmationDialog(false);
    setOnUpdateCounter(false);
  };

  const onIncrement = (): void => {
    if (!isMaxQtyReached && !isMaxQtyMonthlyReached) {
      const updatedProduct = increment(product);
      updateCartItem(updatedProduct, UpdateCartItemType.CREATE_OR_UPDATE);

      setSnackbarCart(itemCount + 1);
    }

    if ((maxQuantityEnabled && productLimitExceeded) || (maxMonthlyQuantityEnabled && productMonthlyLimitExceeded)) {
      const availableQty = productLimitExceeded
        ? purchaseableQuantity
        : productMonthlyLimitExceeded
        ? purchaseableMonthlyQuantity
        : counterValue;

      dispatch(setCloseSnackbarCart());
      dispatch(
        setOpenSnackbar({
          open: true,
          message: productMonthlyLimitExceeded
            ? 'maxQuantityLimit.maxPurchaseMonthly'
            : 'maxQuantityLimit.maxPurchaseDaily',
          params: {
            maxUnit: productMonthlyLimitExceeded ? maxPurchaseMonthlyLimit : availableQty,
          },
          contentProps: { background: '#EE3245' },
          titleAction: 'ok',
        }),
      );
    }
  };

  const onDecrement = (): void => {
    // Show confirmation dialog if value is 1.
    if (counterValue === 1) {
      setShowConfirmationDialog(() => true);
      return;
    }
    // Decrement counter of productId
    const updatedProduct = decrement(product);
    updateCartItem(updatedProduct, UpdateCartItemType.UPDATE);
    setSnackbarCart(itemCount - 1);
  };

  const handleUpdateQuantityValue = (value: number): void => {
    // show confirmation dialog if value is zero

    if (value === 0) {
      setShowEditQuantityBox(false);
      setShowConfirmationDialog(true);
      setOnUpdateCounter(true);
      updateCounter(product, 0);
      changeColor(0);
    } else {
      if (value > remainingStock) {
        value = remainingStock;
      }
      if (value > purchaseableQuantity) {
        value = purchaseableQuantity;
      }
      if (value > purchaseableMonthlyQuantity) {
        value = purchaseableMonthlyQuantity;
      }

      trackEditProductQty(product, value);
      // Update counter of productId
      updateCounter(product, value);
      setSnackbarCart(value);
      setShowEditQuantityBox(false);
    }
  };

  return (
    <S.Wrapper>
      <S.DecrementBtn
        data-testid={`decrement-product-btn-${product.id}`}
        onClick={() => {
          if (!isUnverifiedCartOpen) onDecrement();
        }}
        colour={isUnverifiedCartOpen ? color.light : color.dark}
      />
      <S.ItemCountBtn
        onClick={() => {
          if (!isUnverifiedCartOpen) showEditQuantity();
        }}
      >
        <S.ItemCount>{numeral(counterValue).format('0.[0a]')}</S.ItemCount>
      </S.ItemCountBtn>
      <S.IncrementBtn
        data-testid={`increment-product-btn-${product.id}`}
        onClick={() => {
          if (!isUnverifiedCartOpen) onIncrement();
        }}
        colour={isUnverifiedCartOpen ? color.light : color.dark}
        opacity={isMaxQtyReached || isMaxQtyMonthlyReached ? 0.3 : 1}
      />
      <ConditionalRender condition={showEditQuantityBox}>
        <EditQuantityBox
          data-testid={`edit-product-qty-box-${product.id}`}
          open={showEditQuantityBox}
          onClose={closeEditQuantity}
          count={counterValue}
          onUpdate={handleUpdateQuantityValue}
        />
      </ConditionalRender>
      <ConditionalRender condition={showConfirmationDialog}>
        <ConfirmationDialog
          showDialog={showConfirmationDialog}
          closeDialog={closeConfirmationDialog}
          handleYes={handleYes}
          t={t}
        />
      </ConditionalRender>
    </S.Wrapper>
  );
};

QuantityBox.defaultProps = {
  isUnverifiedCartOpen: undefined,
};
