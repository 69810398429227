import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Skeleton from 'react-loading-skeleton';

import { State as RootState } from 'store';
import { fetchVirtualAccounts } from 'store/reducers/Account/actions';
import { VirtualAccount, VAPaymentType } from 'store/reducers/Account/constants';

import BCALogo from 'images/logo_bca.svg';
import CIMBLogo from 'images/logo_cimb.svg';
import DanamonLogo from 'images/logo_danamon.svg';
import MandiriLogo from 'images/logo_mandiri.svg';
import MaybankLogo from 'images/logo_maybank.svg';
import PermataLogo from 'images/logo_permata.svg';
import BRILogo from 'images/logo_bri.svg';
import BNILogo from 'images/logo_bni46.svg';

import ShowIf from 'components/views/ShowIf';
import Grid from '@material-ui/core/Grid';

import { VirtualAccountCard, VirtualAccountCardProps } from '../VirtualAccountCard';
import { S } from './VirtualAccounts.style';

import { generateScrollRange } from './VirtualAccounts.helper';
import { HowToPayButton } from '../Button';

interface StateProps {
  pharmacyID?: string;
  virtualAccounts?: VirtualAccount[];
  loading: boolean;
}
interface DispatchProps {
  fetchVirtualAccounts: (pharmacyID: string) => any;
}

export interface VirtualAccountsProps {
  control?: VirtualAccountCardProps['control'];
  showPagination?: boolean;
  showHowToPay?: boolean;
  virtualAccount?: VirtualAccount;
  type?: VAPaymentType;
}

type Props = StateProps & DispatchProps & VirtualAccountsProps;

const VirtualAccountsBase: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  fetchVirtualAccounts,
  pharmacyID,
  virtualAccounts,
  virtualAccount,
  loading,
  showPagination,
  showHowToPay,
  type,
}) => {
  useEffect(() => {
    if (pharmacyID && !virtualAccounts && !virtualAccount && !loading) {
      fetchVirtualAccounts(pharmacyID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (virtualAccount) {
    virtualAccounts = [virtualAccount];
  }

  const [scrollIndex, setScrollIndex] = useState(0);
  const scrollRef = useRef<any>();

  const logoMap: Record<string, string> = {
    BCA: BCALogo,
    CIMB: CIMBLogo,
    DANAMON: DanamonLogo,
    MANDIRI: MandiriLogo,
    MAYBANK: MaybankLogo,
    PERMATA: PermataLogo,
    BNI: BNILogo,
    BRI: BRILogo,
  };

  // eslint-disable-next-line max-len
  const renderVirtualAccounts = (virtualAccounts: VirtualAccount[], type?: VAPaymentType): JSX.Element[] =>
    [...virtualAccounts]
      .filter((account) => !type || account.type === type)
      .sort((_a, b) => (b.is_default ? 1 : -1))
      .map((data) => <VirtualAccountCard control={control} data={data} key={data.id} logo={logoMap[data.bank]} />);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onScroll = (e: any) => {
    const boundingBox = scrollRef?.current?.getBoundingClientRect();
    const scrollWidth = e.target.scrollWidth - boundingBox.width;

    const currentPosition = e.target.scrollLeft / scrollWidth;
    const dotRange = generateScrollRange(0, 1, virtualAccounts?.length || 1);

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < dotRange.length; index++) {
      if (currentPosition >= dotRange[index][0] && currentPosition < dotRange[index][1]) {
        setScrollIndex(index);
        break;
      }
    }
  };

  return (
    <>
      <S.Wrapper ref={scrollRef} onScroll={onScroll}>
        <ShowIf condition={loading}>
          <S.SkeletonWrapper>
            <Skeleton height={159} width={325} />
            <Skeleton height={159} width={325} />
          </S.SkeletonWrapper>
        </ShowIf>
        <ShowIf condition={!loading}>{!!virtualAccounts && renderVirtualAccounts(virtualAccounts, type)}</ShowIf>
      </S.Wrapper>

      <ShowIf condition={!!showPagination || !!showHowToPay}>
        <S.VirtualAccountFooterContainer>
          <Grid item xs={6}>
            <ShowIf condition={!!showPagination}>
              <S.DotWrapper>
                {Array.from(Array(virtualAccounts?.length)).map((item, index) => (
                  <S.Dot key={item?.id || index} active={index === scrollIndex} />
                ))}
              </S.DotWrapper>
            </ShowIf>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <HowToPayButton />
          </Grid>
        </S.VirtualAccountFooterContainer>
      </ShowIf>
    </>
  );
};

const mapStateToProps = ({ auth: { coreUser }, account: { virtualAccounts, loading } }: RootState): StateProps => {
  const user: any = coreUser;

  return {
    pharmacyID: user.organization_id,
    virtualAccounts,
    loading,
  };
};

const dispatchProps: DispatchProps = {
  fetchVirtualAccounts,
};

VirtualAccountsBase.defaultProps = {
  pharmacyID: undefined,
  virtualAccounts: undefined,
};

export const VirtualAccounts = connect<StateProps, DispatchProps, VirtualAccountsProps, RootState>(
  mapStateToProps,
  dispatchProps,
)(VirtualAccountsBase);
