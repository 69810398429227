import qs from 'query-string';

import { ISearchItem, ISearchSection } from 'components/common/SearchMenuSuggestions/components/interface';
import { Product, ProductCategory } from 'store/reducers/Product';
import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { IMarketing, ISpecialMarketing } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { State as IProductListingState } from 'store/reducers/ProductListing';
import {
  CategoryPayload,
  ImpressionDataAnalyticProps,
  IProductPayloadTrimmed,
  ProductAnalyticsProps,
  TierDiscountPayload,
  ProductListPageAnalyticsProps,
  ProductSimpleAnalyticsProps,
  SearchDataAnalyticsProps,
  ProductDetailDataAnalyticsProps,
  IMappingProductDataAnalytic,
  ProductListPageSearchProps,
  LocationAnalyticProps,
  RecommendationWidget,
  SearchDataAnalyticsInfoType,
  SearchSection,
  ImpressionType,
  CommunityDataProps,
} from './segment.types';
import { BasePageTracker, getEventPageIdentity, getIsSearchCampaignMarketing, Match, Params } from './Helper';
import { storedPharmacyId } from './SegmentProduct';

export type TCampaignState = {
  utmSource?: string;
  utmMedium?: string;
};

export type SearchSectionState = {
  search_section?: SearchSection;
};

export type ProductSimpleSearchLocationProps = {
  search_section?: string;
  query?: string;
  utm?: string; // SPT-14197: to be removed: fix branch io data attributes
  marketing_type?: string;
  marketing_id?: number;
  marketing_name?: string;
} & ProductListPageAnalyticsProps;

type simpleAnalyticLocState = SearchSectionState & {
  fromSuggestion: boolean;
};

export class SegmentPropsBuilder {
  static buildSimpleAnalyticsProps = (
    productId: number,
    isStockOut: boolean,
    parsedLocationSearch: ProductSimpleSearchLocationProps,
    campaignState?: TCampaignState,
    locationState?: simpleAnalyticLocState | SearchSectionState,
    marketingId?: string | number,
  ): ProductSimpleAnalyticsProps => {
    const trackProductDetailPayload: ProductSimpleAnalyticsProps = {
      product_id: productId,
      product_availability: !isStockOut ? 'In stock' : 'Out of stock',
    };

    if (parsedLocationSearch.marketing_id) {
      trackProductDetailPayload.marketing_id = parsedLocationSearch.marketing_id;
    }
    if (parsedLocationSearch.marketing_name) {
      trackProductDetailPayload.marketing_name = parsedLocationSearch.marketing_name;
    }
    if (parsedLocationSearch.query) {
      trackProductDetailPayload.query = parsedLocationSearch.query;
    }
    if (campaignState?.utmSource || parsedLocationSearch.utm_source) {
      trackProductDetailPayload.utm_source = campaignState?.utmSource || parsedLocationSearch.utm_source;
    }
    if (campaignState?.utmMedium || parsedLocationSearch.utm_medium) {
      trackProductDetailPayload.utm_medium = campaignState?.utmMedium || parsedLocationSearch.utm_medium;
    }
    if (locationState?.search_section) {
      const isSearchCampaignMarketing = getIsSearchCampaignMarketing(locationState?.search_section, marketingId);
      const isSearchCampaingMarketingFromSuggestion =
        isSearchCampaignMarketing && !!(locationState as simpleAnalyticLocState)?.fromSuggestion;

      if (!isSearchCampaingMarketingFromSuggestion)
        trackProductDetailPayload.search_section = locationState?.search_section;
    }

    return trackProductDetailPayload;
  };

  static buildSearchAnalyticsProps(
    location: LocationAnalyticProps,
    filter: string[] = [],
    searchResults: Partial<ISearchSection & IProductListingState>,
    query: string,
    pharmacyId: number,
    marketId: string | undefined,
    type: SearchDataAnalyticsInfoType = SearchDataAnalyticsInfoType.GENERAL_SEARCH,
    buildConditions: boolean | null,
    marketing?: IMarketing | ISpecialMarketing | undefined,
  ): SearchDataAnalyticsProps | undefined {
    const handleBuildConditions = buildConditions === null ? true : buildConditions;

    const name: string = (qs.parse(location.search, BRACKET_QS_OPTIONS)?.name as string) || 'home';
    const { base_page, initial_page, typed_query, search_section } = (location.state as Record<string, string>) || {};
    let trackSearchPayload: SearchDataAnalyticsProps | undefined;
    const isSearchSuggestionSearchCampaign =
      type === SearchDataAnalyticsInfoType.SEARCH_SUGGESTIONS &&
      search_section === SearchSection.CAMPAIGN_LISTING_SEARCH;

    const isSearchCampaignMarketing = getIsSearchCampaignMarketing(search_section as SearchSection, marketing?.id);

    if (handleBuildConditions) {
      const { products = [], fuzzy_search, respondAt, respondTime, totalProducts = 0, meta } = searchResults || {};
      const { total_count = 0 } = meta || {};

      let trimmedProducts: Product[] | ISearchItem[] = [];
      if (type === SearchDataAnalyticsInfoType.SEARCH_SUGGESTIONS) {
        trimmedProducts = products as ISearchItem[];
      } else {
        trimmedProducts = products.map((product) => this.buildProductPayloadMapping(product)) as Product[];
      }

      trackSearchPayload = {
        query,
        typed_query,
        type: 'submit',
        search_section: !isSearchSuggestionSearchCampaign ? search_section : undefined,
        info: {
          pharmacy_id: pharmacyId,
          market_id: marketId,
          type,
          name,
          results_count: totalProducts || total_count,
          filter,
          fuzzy_search,
          products: trimmedProducts,
          respond_at: respondAt,
          respond_time: respondTime,
          from_page: initial_page || base_page,
          ...(isSearchCampaignMarketing ? { marketing_id: marketing?.id, marketing_name: marketing?.name } : {}),
        },
      } as SearchDataAnalyticsProps;
    }
    return trackSearchPayload;
  }

  static buildProductPayloadMapping(
    product: ProductAnalyticsProps,
    indexNumber?: number | undefined,
  ): IProductPayloadTrimmed {
    const mappingCategory = product?.categories?.map((category: ProductCategory): CategoryPayload => {
      const mappingCategoryResult = {
        category_id: category.category_id,
        name: category?.product_category.name,
      };
      return mappingCategoryResult;
    });

    const mappingTierDiscount = product?.tier_discount?.map((tierDiscount): TierDiscountPayload => {
      const mappingTierDiscountResult = {
        min: tierDiscount.min,
        price: tierDiscount.price,
      };
      return mappingTierDiscountResult;
    });

    const buildProductPayloadMappingResult: IProductPayloadTrimmed = {
      id: product.id,
      name: product.name,
      available: product.available,
      discount_rate: product.discount_rate,
      categories: mappingCategory,
      distributor: {
        id: product.distributor.id,
        name: product.distributor.name,
      },
      distributor_product_id: product.distributor_product_id,
      favorited: product.favorited,
      flag: product.flag,
      is_unpurchasable: product.is_unpurchasable || false,
      low_stock_threshold: product.low_stock_threshold,
      loyalty_point: product.loyalty_point || {},
      manufacturer: product.manufacturer,
      molecule: product.molecule,
      net_price: product.net_price,
      packaging: product.packaging,
      quantity_threshold: product.quantity_threshold,
      remaining_quantity: product.remaining_quantity,
      reminder_removal_date: product.reminder_removal_date,
      selling_price: product.selling_price,
      sku_code: product.sku_code,
      availability_requested: !!product.availability_requested,
      tier_discount: mappingTierDiscount,
    };

    if (indexNumber) {
      buildProductPayloadMappingResult.index_number = indexNumber;
    }

    return buildProductPayloadMappingResult;
  }

  static buildProductAddedMapping(
    product: ProductAnalyticsProps,
    match: Match<Params>,
    analytics?: ProductListPageAnalyticsProps,
    locationSearch?: string,
    searchProps?: ProductListPageSearchProps,
    indexNumber?: number,
  ): IMappingProductDataAnalytic {
    const trimmedProduct = this.buildProductPayloadMapping(product);
    const { id, name, ...restProduct } = trimmedProduct;

    const { query, ...restAnalytics } = analytics || {};
    const pageIdentity = getEventPageIdentity(match, locationSearch);

    // SPT-15418 for all widget
    const recommendationWidgetProp = product.referrer_sku_code
      ? { recommendation_widget: RecommendationWidget.SIMILAR_PRODUCT }
      : {};

    const productMapped: IMappingProductDataAnalytic = {
      product_id: id,
      product_name: name,
      product_added_from: pageIdentity,
      referrer_sku_code: product?.referrer_sku_code,
      search_reciprocal_rank: [
        BasePageTracker.GENERAL_SEARCH,
        BasePageTracker.PRODUCT_DETAIL,
        BasePageTracker.CATEGORY_LISTING,
      ].includes(pageIdentity)
        ? indexNumber
        : undefined,
      ...restProduct,
      ...restAnalytics,
      ...searchProps,
      ...recommendationWidgetProp,
    };

    if (query) {
      productMapped.query = query;
    }

    return productMapped;
  }

  static buildProductDetailMapping(
    product: ProductAnalyticsProps,
    isStockOut: boolean,
    parsedLocationSearch: ProductSimpleSearchLocationProps,
    search_section?: string,
    campaignState?: TCampaignState,
    indexNumber?: number,
    eventPageIdentity?: BasePageTracker,
    fromSuggestion?: boolean,
  ): ProductDetailDataAnalyticsProps {
    const simpleAnalyticsProps = SegmentPropsBuilder.buildSimpleAnalyticsProps(
      product.id,
      isStockOut,
      parsedLocationSearch,
      campaignState,
    );

    const isSearchCampaignMarketing = getIsSearchCampaignMarketing(
      search_section as SearchSection,
      undefined,
      eventPageIdentity,
    );

    const specificProctImpression = {
      sku_code: product.sku_code,
      discount_rate: product.discount_rate,
      favorited: product.favorited,
      is_unpurchasable: product.is_unpurchasable || false,
      low_stock_threshold: product.low_stock_threshold,
      loyalty_point: product.loyalty_point || {},
      manufacturer: product.manufacturer,
      molecule: product.molecule,
      name: product.name,
      net_price: product.net_price,
      packaging: product.packaging,
      quantity_threshold: product.quantity_threshold,
      remaining_quantity: product.remaining_quantity,
      reminder_removal_date: product.reminder_removal_date,
      selling_price: product.selling_price,
      search_section: isSearchCampaignMarketing && fromSuggestion ? undefined : search_section,
      search_reciprocal_rank: indexNumber,
      ...simpleAnalyticsProps,
    };

    return specificProctImpression;
  }

  static buildImpressionTrackingProps(
    productImpression: IProductPayloadTrimmed[],
    searchQuery: string | undefined,
    impressionTime: number | undefined,
    impressionFrom: BasePageTracker | undefined,
    impressionType?: ImpressionType,
    campaignValues?: Partial<ProductListPageAnalyticsProps>,
    locationState?: SearchSectionState,
  ): ImpressionDataAnalyticProps {
    const payloadProductImpression: ImpressionDataAnalyticProps = {
      impression_from: impressionFrom,
      impression_time: impressionTime,
      product_ids: productImpression.map((product) => product.id),
      sku_codes: productImpression.map((product) => product.sku_code),
      products: productImpression,
    };

    if (impressionType) {
      payloadProductImpression.impression_type = impressionType;
    }

    if (campaignValues?.marketing_id) {
      payloadProductImpression.marketing_id = campaignValues.marketing_id;
    }

    if (campaignValues?.marketing_name) {
      payloadProductImpression.marketing_name = campaignValues.marketing_name;
    }

    if (campaignValues?.utm_medium) {
      payloadProductImpression.utm_medium = campaignValues.utm_medium;
    }

    if (campaignValues?.utm_source) {
      payloadProductImpression.utm_source = campaignValues.utm_source;
    }

    if (searchQuery) {
      payloadProductImpression.query = searchQuery;
    }

    if (locationState?.search_section) {
      payloadProductImpression.search_section = locationState?.search_section;
    }

    return payloadProductImpression;
  }

  static buildCommunityDataProp(deeplink: string): CommunityDataProps {
    return {
      pharmacyId: Number(storedPharmacyId()),
      url: deeplink,
    };
  }
}
