import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export interface AccordionProps extends React.ComponentPropsWithoutRef<'div'> {}

const Container = styled(Grid)`
  padding: 10px 0;
  background-color: #fff;

  div + p {
    padding-top: 10px;
  }

  .icon {
    fill: rgba(0, 0, 0, 0.39);
    width: 24px;
    height: 24px;
  }

  .title {
    color: rgba(107, 124, 147, 1);
    font-size: 14px;
    font-weight: 700;
  }

  .content {
    color: rgba(79, 79, 79, 1);
    font-size: 14px;
    font-weight: 400;
  }
`;

export const S = { Container };
