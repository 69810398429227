/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */

import { Analytics, AnalyticsEvent, AnalyticsPayload, AnalyticsProvider } from './interfaces';

export class SwipeRxAnalytics implements Analytics {
  private _isDebug = false;

  private providers: AnalyticsProvider[] = [];

  private static instance: SwipeRxAnalytics;

  constructor() {
    this._isDebug = process.env.NODE_ENV === 'development' && process.env.REACT_APP_ANALYTICS_DEBUG === '1';
  }

  static getInstance(): SwipeRxAnalytics {
    if (!SwipeRxAnalytics.instance) {
      SwipeRxAnalytics.instance = new SwipeRxAnalytics();
    }
    return SwipeRxAnalytics.instance;
  }

  isDebug(): boolean {
    return this._isDebug;
  }

  attach(provider: AnalyticsProvider): void {
    const isExist = this.providers.includes(provider);
    if (!isExist) {
      this.providers.push(provider);
    }
  }

  detach(provider: AnalyticsProvider): void {
    const providerIndex = this.providers.indexOf(provider);
    if (providerIndex > -1) {
      this.providers.splice(providerIndex, 1);
    }
  }

  async request(type: AnalyticsEvent, event: string, payload: AnalyticsPayload): Promise<void> {
    const response = await Promise.allSettled(
      this.providers.map(
        (provider) =>
          new Promise((resolve, reject) => {
            try {
              switch (type) {
                case AnalyticsEvent.Identify:
                  resolve(provider.identify(event, payload, this));
                  break;
                case AnalyticsEvent.Track:
                  resolve(provider.track(event, payload || {}, this));
                  break;
                case AnalyticsEvent.Page:
                default:
                  resolve(provider.page(event, this.getAnalyticPagePayload(payload), this));
                  break;
              }
            } catch (e) {
              if (this.isDebug()) {
                console.log(`[SwipeRxAnalytics] request error:${type}`, { provider, event, payload });
              }
              reject(e);
            }
          }),
      ),
    );

    if (this.isDebug()) {
      console.log(`[SwipeRxAnalytics] notify:${type}`, response);
    }
  }

  private getAnalyticPagePayload(payload: AnalyticsPayload): AnalyticsPayload {
    const currentUrl = new URL(window.location.href);
    const { host, pathname, protocol } = currentUrl;
    const searchParams = new URLSearchParams(currentUrl.search.slice(1));

    // delete session token in query string
    searchParams.delete('s');

    const searchString = searchParams.toString();
    const search = searchString !== '' ? `?${searchString}` : '';
    const url = `${protocol}//${host}${pathname}${search}`;

    const newPayload = {
      ...payload,
      search,
      url,
    };

    return newPayload;
  }
}
