const defaultWidth = 100;
const defaultHeight = 12;

export const getWidth = (variant: string): number => {
  switch (variant) {
    case 'xs':
      return 60;

    case 'sm':
      return 80;

    case 'md':
      return 100;

    case 'lg':
      return 120;

    case 'xl':
      return 200;

    default:
      return defaultWidth;
  }
};

export const getHeight = (type: string): number => {
  switch (type) {
    case 'sub':
      return 10;

    case 'text':
      return 12;

    case 'title':
      return 14;

    case 'heading':
      return 16;

    default:
      return defaultHeight;
  }
};
