export const getQueryObject = (queryString: string): { [k: string]: string } => {
  // Get params

  const params = queryString
    .slice(1)
    .split('&')
    .reduce((map, param) => {
      const [key, value] = param.split('=');
      map[key] = value;
      return map;
    }, {});
  return params as any;
};

export const getQueryParameter = (queryString: string, name: string): string | void => {
  const params = getQueryObject(queryString);

  // Return query param
  return params[name];
};
