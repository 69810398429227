import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
  padding: 16px 10px;
  background: #fff;
  box-shadow: 0px 0px 1px #676f82;
`;

const MiniLabel = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: normal;
    color: #717786;
  }
`;

const NoteText = styled(Typography)`
  font-weight: normal;
  font-size: 16px;
  color: #181d29;
`;

export const S = {
  Wrapper,
  MiniLabel,
  NoteText,
};
