import { Reducer } from 'redux';
import { Product } from '../Product';

import * as constants from './constants';
import { Actions, SimilarProductLocation } from './interface';

export interface State {
  similarProduct: {
    [x: string]: {
      products: Product[];
      skuCode: string | null;
      loading: boolean;
      marketingId: number | null;
    };
  };
  similarProcessedIds: string[];
}

const baseSimilarProductInitialState = {
  products: [],
  skuCode: null,
  loading: false,
  marketingId: null,
};

const InitialState: State = {
  similarProduct: {
    [SimilarProductLocation.SEARCH_PAGE]: baseSimilarProductInitialState,
    [SimilarProductLocation.DETAIL_PAGE]: baseSimilarProductInitialState,
  },
  similarProcessedIds: [],
};

const ProductCarouselReducer: Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_SIMILAR_PRODUCT_CAROUSEL:
      return {
        ...state,
        similarProduct: {
          ...state.similarProduct,
          [action.location]: {
            ...state.similarProduct[action.location],
            products: action.products,
            skuCode: action.skuCode,
            marketingId: action.marketingId,
          },
        },
      };

    case constants.SET_SIMILAR_PRODUCT_CAROUSEL_LOADING:
      return {
        ...state,
        similarProduct: {
          ...state.similarProduct,
          [action.location]: {
            ...state.similarProduct[action.location],
            loading: action.loading,
          },
        },
      };

    case constants.CLEAR_SIMILAR_PRODUCT_CAROUSEL:
      return {
        ...state,
        similarProduct: {
          ...state.similarProduct,
          [action.location]: {
            ...baseSimilarProductInitialState,
          },
        },
      };

    case constants.SET_SIMILAR_PRODUCT_PROCESSED_IDS:
      return {
        ...state,
        similarProcessedIds: action.ids,
      };

    case constants.CLEAR_SIMILAR_PRODUCT_PROCESSED_IDS:
      return {
        ...state,
        similarProcessedIds: [],
      };

    default:
      return state;
  }
};

export default ProductCarouselReducer;
