import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withMaintenanceMode from 'components/pages/MaintenancePage/withMaintenanceMode';
import { setCloseSnackbar } from 'store/reducers/Utils/actions';
import withMarketing from 'components/pages/ProductListingPage/components/withMarketing';
import { fetchOrderedProductQuantityHistory } from 'store/reducers/Orders/actions';
import { State as StoreState } from '../../store';
import { authentication } from '../../store/reducers/Auth/actions';
import App, { StateProps } from './App.component';
import { fetchCart } from '../../store/reducers/Cart/actions';
import { getCart } from '../../store/reducers/Counter/actions';
import { fetchPassword } from '../../store/reducers/ChangePassword/actions';
import { fetchCredit, clearCreditError } from '../../store/reducers/Credit/actions';
import { hideError, hideSuccess, startAppLoading, endAppLoading } from '../../store/reducers/Global/actions';
import { fetchPromoted } from '../../store/reducers/Promoted/actions';
import { fetchRecentlyViewedSectionProducts } from '../../store/reducers/SearchSuggestions/actions';
import {
  fetchAllCategories,
  fetchSpecialMarketing,
  fetchRecentlySearchedProducts,
} from '../../store/reducers/Search/actions';
import {
  fetchMarketConfig,
  setMarketId,
  setMarketLanguage,
  setMarketLocale,
} from '../../store/reducers/MarketConfig/actions';
import { fetchOrganizationPaymentMethod } from '../../store/reducers/pharmacy';
import { fetchDPDBlockStatus } from '../../store/reducers/Payment/actions';
import 'moment/min/locales';
import '../styles/photoswipe-overrides.css';
import { getIsPriorityWidgetsFinishLoad } from 'components/common/Widget/widget.util';

// Map store state to props
const mapStateToProps = (state: StoreState): StateProps => {
  return {
    authenticated: state.auth.authenticated,
    activated: state.auth.activated,
    authError: state.auth.error,
    fetchError: state.credit.error,
    globalError: state.global.error,
    globalSuccess: state.global.success,
    coreUser: state.auth.coreUser,
    utils: state.utils,
    menuDrawerOpen: state.searchSuggestions.menuDrawerOpen,
    isPriorityWidgetsFinishLoad: getIsPriorityWidgetsFinishLoad(state),
  };
};

// Map dispatch to props
const mapDispatchToProps = {
  authentication,
  fetchCart,
  getCart,
  fetchDPDBlockStatus,
  fetchCredit,
  clearCreditError,
  hideError,
  hideSuccess,
  fetchPromoted,
  fetchAllCategories,
  fetchSpecialMarketing,
  fetchRecentlySearchedProducts,
  startAppLoading,
  endAppLoading,
  setMarketId,
  fetchMarketConfig,
  fetchOrganizationPaymentMethod,
  setMarketLanguage,
  setMarketLocale,
  fetchPassword,
  setCloseSnackbar,
  fetchOrderedProductQuantityHistory,
  fetchRecentlyViewedSectionProducts,
};

export default withRouter(
  withMarketing()(withMaintenanceMode('maintenance')(connect(mapStateToProps, mapDispatchToProps)(App))),
);
