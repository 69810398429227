import Grid from '@material-ui/core/Grid';
import { TabNavLink } from 'components/common/Tabs/TabNavLink.component';
import styled from 'styled-components';
import { WHITE } from 'components/styles/colors';

const Container = styled(Grid)`
  && {
    padding: 5px 15px 0px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;
    -ms-overflow-style: none;
    border-bottom: 1px solid #f1f1f1;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabNav = styled(TabNavLink)`
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  background-color: ${WHITE};
`;

export const S = {
  Container,
  TabNav,
};
