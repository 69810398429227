import styled from 'styled-components';
import { Typography, DialogActions as MuiDialogActions } from '@material-ui/core';

const DialogText = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 300;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`;

export const S = {
  DialogText,
  DialogActions,
};
