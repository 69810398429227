import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as CopyIcon } from 'images/ic_copy.svg';

import { Snackbar } from '@material-ui/core';
import { S } from './orders-modal-summary.styles';

interface Props {
  value?: string;
  message?: string;
}

export const OrdersModalSummaryCopyValue: React.FC<React.PropsWithChildren<Props>> = ({ value = '', message }) => {
  const [openToast, setOpenToast] = useState<boolean>(false);

  const onCopy = (): void => {
    if (!message) return;
    setOpenToast(true);
  };

  const onClose = (): void => {
    setOpenToast(false);
  };

  return (
    <>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <Grid container direction="row" alignItems="center" justify="flex-end">
          <CopyIcon />
          <S.Value noWrap style={{ paddingLeft: '9px' }}>
            {value}
          </S.Value>
        </Grid>
      </CopyToClipboard>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openToast}
        onClose={onClose}
        message={message}
        autoHideDuration={2000}
      />
    </>
  );
};
