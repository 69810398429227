import React, { useEffect } from 'react';

interface Props {
  link: string;
}

export const Frame: React.FC<React.PropsWithChildren<Props>> = ({ link }) => {
  useEffect(() => {
    const isDeeplink = link.startsWith('swrx://');

    if (isDeeplink) window.location.href = link;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable-next-line jsx-a11y/iframe-has-title */
  return <iframe className="help" src={link} />;
};
