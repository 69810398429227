import { SortType } from 'components/common/FilterToolbar/constants';
import { GENERIC_PRODUCT_WIDGET } from 'components/common/Widget/widget.constant';
import { useGetCurrentUser } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MarketingDisplayType, MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { Product } from 'store/reducers/Product';
import { generateMaxPurchase } from 'store/reducers/ProductListing/actions';

import { v3Client } from 'utils/Client/v3';

interface ProductCarouselParams {
  marketingId?: number;
  marketingType: MarketingType;
  productIds?: number[];
  fetchAllowed: boolean;
}
export interface ProductCarouselHook {
  data?: Array<Product>;
  isLoading: boolean;
  hide: boolean;
}

const MARKETING_RANDOM_ORDER = [MarketingType.PRODUCT, MarketingType.AUTO_PLAYLIST];

const MARKETING_PRODUCT_ID_ORDER = [MarketingType.RECENT, MarketingType.REPURCHASE];

export const useProductCarousel = ({
  marketingId,
  marketingType,
  productIds,
  fetchAllowed,
}: ProductCarouselParams): ProductCarouselHook => {
  const dispatch = useDispatch();
  const [productList, setProductList] = useState<Product[] | undefined>(undefined);
  const [hide, setHide] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    coreUser: { organization_id },
  } = useGetCurrentUser();

  const sorting = MARKETING_RANDOM_ORDER.includes(marketingType) ? SortType.RANDOM : SortType.DEFAULT;

  const orderBy = MARKETING_PRODUCT_ID_ORDER.includes(marketingType) ? SortType.PRODUCT_ID : SortType.RANDOM;

  const searchStatesParams =
    marketingType === MarketingType.RECENT
      ? { show_stockout: false, show_instock: true }
      : { show_stockout: false, show_instock: true, show_purchaseable: true, show_unpurchaseable: false };

  useEffect(() => {
    const action = async () => {
      if (!fetchAllowed) return;
      if (!organization_id) return;
      if (!marketingId || marketingId <= 0) return;
      if (!marketingType || !GENERIC_PRODUCT_WIDGET.includes(marketingType)) return;

      setIsLoading(true);
      try {
        const response = await v3Client.get(`marketing/${marketingId}/${marketingType}/${organization_id}`, {
          page: 1,
          page_size: 10,
          order_by: orderBy,
          sort_by: 'asc',
          product_ids: productIds,
          display_type: MarketingDisplayType.WIDGET,
          ...searchStatesParams,
        });

        const data = response.data;
        let newProductList = data;
        if (sorting === SortType.RANDOM) {
          newProductList = data
            .map((value) => ({
              value,
              random: Math.floor(Math.random() * 100),
            }))
            .sort((a, b) => a.random - b.random)
            .map(({ value }) => value);
        }
        dispatch(generateMaxPurchase(data.filter((product) => product.max_qty_enabled)));
        setProductList(newProductList);
        setHide(newProductList.length === 0);
      } catch (err) {
        console.error(`Carousel fetch error ${marketingId}:${marketingType}`, err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    };

    action();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization_id, marketingId, marketingType, fetchAllowed]);

  return {
    data: productList,
    isLoading,
    hide,
  };
};
