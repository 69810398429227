import React from 'react';

import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Order } from 'services/swipe-rx-pt/resources/orders/interfaces';
import { InvoicePaymentStatus } from 'services/swipe-rx-pt/resources/invoice/types/payment-status.type';
import { resetPurchaseOrderDetails } from 'store/reducers';
import { ConditionalRender, TimeLeft } from 'components/common';
import { TranslateProps } from 'utils/Localization';
import { getPaymentBillExpiration } from 'utils/MarketConfig';

import { OrderStatus } from 'services/swipe-rx-pt';
import { PaymentMethods } from 'utils/constants';
import { WarningCard } from './orders-modal-delivery-date-warning.style';

interface DeliveryDateWarningComponentProps extends TranslateProps {
  order: Order;
}

export const DeliveryDateWarningComponentBase: React.FC<React.PropsWithChildren<DeliveryDateWarningComponentProps>> = (
  props,
) => {
  const { order, t } = props;

  const { push } = useHistory();
  const dispatch = useDispatch();

  const getMarketId = (): string => order.market_id;
  const getPaymentMethod = (): string => order.payment_method;
  const shouldShowTimer = (): boolean => ['th'].includes(getMarketId());
  const shouldShowPrekursorWarning = (): boolean =>
    (order.precursor_status || false) && !order.precursor_status?.document_url;
  const shouldShowUnpaidWarning = (): boolean =>
    getPaymentMethod() === PaymentMethods.DIRECT_PAYMENT &&
    !(order.invoice?.payment_status === InvoicePaymentStatus.PAID);

  const goToPaymentPage = (): void => {
    dispatch(resetPurchaseOrderDetails());

    if (getPaymentMethod() === PaymentMethods.DIRECT_PAYMENT) push(`/${getMarketId()}/main/payments/unpaid`);
    else if (getPaymentMethod() === PaymentMethods.CREDIT_LINE) push(`/${getMarketId()}/main/payments/due`);
  };

  if (!shouldShowPrekursorWarning() && !shouldShowUnpaidWarning()) return null;
  if (!order || order.logistics_po_delivery?.expected_delivery) return null;
  if (![OrderStatus.PENDING, OrderStatus.PROCESSING, OrderStatus.ACCEPTED].includes(order.status)) return null;

  return (
    <div style={{ paddingBottom: 10 }}>
      <ConditionalRender condition={shouldShowPrekursorWarning()}>
        <WarningCard title={t('slaPrekursorWarningText')} />
      </ConditionalRender>
      <ConditionalRender condition={shouldShowUnpaidWarning()}>
        <WarningCard
          title={
            <>
              {t('slaUnpaidWarningText')}{' '}
              {shouldShowTimer() && (
                <TimeLeft
                  dateTime={order.ordered_at}
                  timeOutAfter={getPaymentBillExpiration()}
                  hideLabel
                  background="rgba(0, 0, 0, 0)"
                  inline
                />
              )}
            </>
          }
          actionTitle={t('slaUnpaidWarningAction')}
          onClick={goToPaymentPage}
        />
      </ConditionalRender>
    </div>
  );
};

export const DeliveryDateWarningComponent = translate('orders')(DeliveryDateWarningComponentBase);
