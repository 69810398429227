import { useHistory } from 'react-router-dom';

import { SearchSectionState } from 'utils/Analytics/Segment';
import { useHelpDialog } from './useHelpDialog';

export const useLinkHandler = (): {
  onClick: (link: string, callback?: () => void, state?: SearchSectionState) => void;
} & ReturnType<typeof useHelpDialog> => {
  const location = useHistory();
  const { showHelpDialog, helpDialogURL, toggleHelpDialog } = useHelpDialog();

  const onClick = (link: string, callback?: () => void, state?: SearchSectionState): void => {
    switch (true) {
      case link.startsWith('/'):
        location.push(link, state);
        if (callback) callback();
        break;
      case link.includes('swiperx.app.link'):
        window.location.assign(link);
        break;
      default:
        toggleHelpDialog(link);
        break;
    }
  };

  return { onClick, showHelpDialog, helpDialogURL, toggleHelpDialog };
};
