import React from 'react';
import { translate } from 'react-i18next';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import DownloadIcon from '@material-ui/icons/GetApp';
import { ReactComponent as CreditMemoIcon } from 'images/order-credit-memo.svg';

import { Grid, Link, List, ListItem } from '@material-ui/core';
import { IInvoiceReturn } from 'store/reducers/Return';
import ShowIf from 'components/views/ShowIf';
import WarningIcon from '@material-ui/icons/Warning';
import { DepositDetailModal } from 'components/pages/PaymentsPage/components/DepositDetailModal';
import { useToggle } from 'hooks';
import { S } from './orders-modal-refund-info.styles';
import { OrdersModalSummaryCopyValue } from './orders-modal-summary-copy-value.component';
import { Section } from './order-modal-section';

type CreditMemoProps = {
  refund: IInvoiceReturn;
  t: any;
};

const OrdersModalCreditMemoComponent: React.FC<React.PropsWithChildren<CreditMemoProps>> = (props) => {
  const {
    refund: { deposit, credit_memo },
    t,
  } = props;

  const { isOpen: isDepositDetailOpen, toggle: toggleDepositDetail } = useToggle(false);

  return (
    <Grid style={{ padding: '16px 0' }}>
      <Grid style={{ marginLeft: 16 }}>
        <Section title={t('creditMemo.title')} icon={CreditMemoIcon} />
      </Grid>

      <S.Row>
        <List component="div" style={{ width: '100%' }}>
          <ListItem style={{ justifyContent: 'space-between' }}>
            <S.Label>{t('creditMemo.no')}</S.Label>
            <Grid container direction="column" alignItems="flex-end">
              <OrdersModalSummaryCopyValue value={credit_memo.credit_memo_no} message="Credit Memo number copied" />
              <S.ViewButton onClick={toggleDepositDetail}>
                {t('creditMemo.see')}
                <ArrowForwardIcon />
              </S.ViewButton>
            </Grid>
          </ListItem>
          <ListItem style={{ justifyContent: 'space-between' }}>
            <S.Label>{t('creditMemo.document')}</S.Label>
            <Grid container direction="column" alignItems="flex-end">
              <S.ViewButton disabled={!credit_memo.attachment_url}>
                <Link color="inherit" underline="none" href={credit_memo.attachment_url} download target="_blank">
                  {t('creditMemo.download')}
                </Link>
                <DownloadIcon />
              </S.ViewButton>
            </Grid>
          </ListItem>
        </List>
      </S.Row>
      <ShowIf condition={!credit_memo.attachment_url}>
        <S.Warning container>
          <WarningIcon style={{ color: '#FF7F30', width: 22, height: 20, marginRight: 12 }} />
          {t('creditMemo.documentOnProgress')}
        </S.Warning>
      </ShowIf>

      <DepositDetailModal
        open={isDepositDetailOpen}
        onClose={toggleDepositDetail}
        selectedDeposit={deposit}
        handleOpenOrderDetailsModal={toggleDepositDetail}
      />
    </Grid>
  );
};

export const OrdersModalCreditMemo = translate('orders')(OrdersModalCreditMemoComponent);
