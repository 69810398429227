import React, { EffectCallback, useEffect, useReducer, useState } from 'react';
import { store } from 'store';
import {
  MaintenanceStateContext,
  MaintenanceDispatchContext,
  MaintenanceActions,
  maintenanceReducer,
  initialState,
} from './context';
import { getMaintenanceConfigDB } from './firebase';
import { emptyCacheStorage } from './utils/clearCacheStorage';

const withMaintenanceMode = (maintenancePath = 'maintenance') => (Component) => (props) => {
  const { history, location } = props;
  const [state, dispatch] = useReducer(maintenanceReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [cleared, setCleared] = useState(false);

  const ref = getMaintenanceConfigDB();

  // listen firebase maintenance config data
  useEffect((): ReturnType<EffectCallback> => {
    if (ref) {
      ref.on('value', (snapshot) => {
        const config = snapshot.val();
        dispatch({ type: MaintenanceActions.CONFIG_LOAD, config });
      });

      if (isLoading) {
        setIsLoading(false);
      }

      // unsubscribe firebase listener
      return (): void => {
        ref.off('value');
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMaintenanceMode = state.config?.enabled || false;
  const isMaintenancePage = location.pathname.includes(maintenancePath);
  const shouldClearCache = state.config?.reopen || false;

  useEffect(() => {
    const action = async (): Promise<void> => {
      if (isMaintenancePage && !isMaintenanceMode && shouldClearCache && !cleared) {
        try {
          setCleared(true);
          await emptyCacheStorage(false); // clear cache but do not trigger reload inside
        } finally {
          dispatch({ type: MaintenanceActions.ASK_REOPEN, askReopen: true });
        }
      }
    };
    action();
  }, [isMaintenanceMode, isMaintenancePage, shouldClearCache, setCleared, cleared]);

  if (isMaintenancePage && !isMaintenanceMode) {
    // maintenance page end strategy
    if (!shouldClearCache) {
      // no clear cache needed, redirect to app
      const { config } = store.getState();
      props.history.push({ pathname: `/${config?.marketId}` });
      return null;
    }

    // wait for clear to finish
    // modal will popup if still on maintenance page
  }

  if (!isMaintenancePage && isMaintenanceMode) {
    const { config } = store.getState();
    history.push({ pathname: `/${config.marketId}/${maintenancePath}` });

    return null;
  }

  return (
    <MaintenanceStateContext.Provider value={state}>
      <MaintenanceDispatchContext.Provider value={dispatch}>
        <Component {...props} isOnMaintenance={isMaintenanceMode} />
      </MaintenanceDispatchContext.Provider>
    </MaintenanceStateContext.Provider>
  );
};

export default withMaintenanceMode;
