import produce, { Draft } from 'immer';

import { PharmacyStore } from './pharmacy.interface';
import { PhamarcyActions, PharmacyActionTypes } from './pharmacy-actions.type';

const orderState: PharmacyStore = {
  loading: false,
  servingDistributors: [],
  marketingSegment: '',
  paymentMethod: null,
  error: '',
  logistic_areas: [],
};

export const pharmacyReducer = (state: PharmacyStore = orderState, action: PhamarcyActions): PharmacyStore =>
  produce(state, (draft: Draft<PharmacyStore>) => {
    switch (action.type) {
      case PharmacyActionTypes.SET_PHARMACY_LOADING:
        draft.loading = action.payload.loading;
        break;

      case PharmacyActionTypes.SET_PHARMACY_SERVING_DISTRIBUTORS:
        draft.servingDistributors = action.payload.data;
        break;

      case PharmacyActionTypes.SET_PHARMACY_MARKETING_SEGMENT:
        draft.marketingSegment = action.payload.data.marketing_segment || '';
        break;

      case PharmacyActionTypes.SET_PHARMACY_ERROR:
        draft.error = action.payload.error;
        break;

      case PharmacyActionTypes.SET_PHARMACY_PAYMENT_METHOD:
        draft.paymentMethod = action.payload;
        break;
      case PharmacyActionTypes.SET_PHARMACY_LOGISTIC_AREAS:
        draft.logistic_areas = action.payload;
        break;
    }
  });
