import { SwipeRxPt } from '../../SwipeRxPt.class';

export class SwipeRxPtOrderRequirement {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async retrieve(purchaseOrderId): Promise<any> {
    const { data } = await this.base.getV2(`/orders/requirements/${purchaseOrderId}`);

    return data;
  }

  async upload(id: number, file: File): Promise<any> {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await this.base.postMultipart(`/orders/requirements/${id}`, formData);

    return data;
  }
}
