import React from 'react';
import * as colors from 'components/styles/colors';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

export const ProductIconOutlined: React.FC<React.PropsWithChildren<Props>> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 4H15.36L16.5 0.86L14.15 0L12.69 4H0V6L2 12L0 18V20H18V18L16 12L18 6V4ZM14.1 12.63L15.89 18H2.11L3.9 12.63L4.11 12L3.9 11.37L2.11 6H15.89L14.1 11.37L13.89 12L14.1 12.63ZM10 8H8V11H5V13H8V16H10V13H13V11H10V8Z" />
  </svg>
);

ProductIconOutlined.defaultProps = {
  fill: colors.PRIMARY_COLOR,
  width: '20px',
  height: '20px'
};
