export const SUCCEED_AUTH = 'SUCCEED_AUTH';
export type SUCCEED_AUTH = typeof SUCCEED_AUTH;

export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';
export type AGREE_TO_TERMS = typeof AGREE_TO_TERMS;

export const TOUR = 'TOUR';
export type TOUR = typeof TOUR;

export const FAIL_AUTH = 'FAIL_AUTH';
export type FAIL_AUTH = typeof FAIL_AUTH;

export const FAIL_AUTH_CODED = 'FAIL_AUTH_CODED';
export type FAIL_AUTH_CODED = typeof FAIL_AUTH_CODED;

// Authenticate Session Error Code
export enum AUTH_SESSION_ERRORS {
  NO_ASSIGNED_ORGANIZATION = 'NO_ASSIGNED_ORGANIZATION',
  INVALID_SESSION_TOKEN = 'INVALID_SESSION_TOKEN',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  NO_MARKET_ACCESS = 'NO_MARKET_ACCESS',
  INVALID_USER_ACTIVATION = 'INVALID_USER_ACTIVATION',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}

export const SUCCEED_AUTHENTICATE = 'SUCCEED_AUTHENTICATE';
export type SUCCEED_AUTHENTICATE = typeof SUCCEED_AUTHENTICATE;
