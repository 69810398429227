import { Optional } from 'utility-types';
import { IMarketingFeatures } from './marketing-features.interface';

export enum MarketingType {
  BANNER = 'banner-listing',
  PRODUCT = 'product-listing',
  RECENT = 'recently-viewed-listing',
  CATEGORY = 'category-listing',
  SIMILAR_PRODUCT = 'similar-product-listing',
  HOMESQUARE = 'home-squares',
  REPURCHASE = 'repurchase-listing',
  AUTO_PLAYLIST = 'auto-playlist-listing',
  CAMPAIGN_SEARCH = 'campaign-search',
}

export enum MarketingDisplayType {
  WIDGET = 'widget',
  LISTING = 'listing',
}
export interface MarketingFilters {
  table: string;
  column: string;
  condition: string;
  value: string;
}

export interface IMarketingNavigation {
  hidden: boolean;
  caption: string;
  position: 'top' | 'bottom';
}

export interface IMarketingConfig {
  styling: {
    color: string;
    dot_navigation: Pick<IMarketingNavigation, 'hidden'>;
    see_all: IMarketingNavigation;
  };
  tours?: {
    id: string;
  };
  max_list?: number;
}

export interface IMarketing {
  id: number;
  name: string;
  type: MarketingType;
  config?: IMarketingConfig;
  is_published?: boolean;
  marketing_features?: IMarketingFeatures[];
}

export interface ISpecialMarketing extends Optional<IMarketing, 'id'> {}
