export const UPDATE_CREDIT = 'UPDATE_CREDIT';
export type UPDATE_CREDIT = typeof UPDATE_CREDIT;

export const FAIL_CREDIT = 'FAIL_CREDIT';
export type FAIL_CREDIT = typeof FAIL_CREDIT;

export const UPDATE_PRICES = 'UPDATE_PRICES';
export type UPDATE_PRICES = typeof UPDATE_PRICES;

// to check the suspended account
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export type UPDATE_SETTINGS = typeof UPDATE_SETTINGS;
