import { useEffect, useState } from 'react';
import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';

import { v3Client } from 'utils/Client/v3';

interface BannerCarouselParams {
  marketingId?: number;
  marketingType?: string;
  fetchAllowed: boolean;
}

export interface BannerCarouselHook {
  data: Array<any>;
  isLoading: boolean;
}

export const useBannerCarousel = ({
  marketingId,
  marketingType,
  fetchAllowed,
}: BannerCarouselParams): BannerCarouselHook => {
  const [bannerList, setBannerList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (!fetchAllowed) return;
    if (!marketingId || marketingId <= 0) return;
    if (!marketingType || marketingType !== MarketingType.BANNER) return;

    setIsLoading(true);
    v3Client
      .get('banners', {
        marketing_id: marketingId,
        active: true,
        targeted: true,
        page: 1,
        page_size: 10,
        fields: ['id', 'title', 'banner_url', 'banner_link', 'display_priority', 'visibility'].join(','),
      })
      .then(({ data }) => {
        setIsLoading(false);
        setBannerList(data);
      });
  }, [marketingId, marketingType, fetchAllowed]);

  return {
    data: bannerList,
    isLoading,
  };
};
