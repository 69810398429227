/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';

export const useHelpDialog = () => {
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [helpDialogURL, setHelpDialogURL] = useState('');

  const toggleHelpDialog = (url = '') => {
    setShowHelpDialog(!showHelpDialog);
    setHelpDialogURL(url);
  };

  return {
    showHelpDialog,
    helpDialogURL,
    toggleHelpDialog,
  };
};
