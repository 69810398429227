import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { ThunkActionCreator } from 'types/thunk';
import { v3Client } from 'utils/Client/v3';
import { State } from 'store';
import { recordException } from 'utils/Reporting';
import { Actions, SimilarProductLocation } from './interface';
import * as constants from './constants';
import { setHasSimilarProducts } from '../ProductDetail/actions';

export interface IFetchSimilarproductCarousel {
  marketingId: number;
  marketingType: MarketingType;
  params: { sku_code: string; excluded_product_ids?: number[] };
}

export const fetchSimilarProduct = async (
  payload: IFetchSimilarproductCarousel & { organizationId?: number },
): Promise<any> => {
  const { marketingId, marketingType, organizationId, params } = payload || {};

  const defaultReturn = [];
  try {
    if (!organizationId) return defaultReturn;
    if (!marketingId || marketingId <= 0) return defaultReturn;
    if (!marketingType || marketingType !== MarketingType.SIMILAR_PRODUCT) return defaultReturn;
    if (!params?.sku_code) return defaultReturn;

    const data = await v3Client.get(`marketing/${marketingId}/${MarketingType.SIMILAR_PRODUCT}/${organizationId}`, {
      page: 1,
      page_size: 5,
      order_by: 'random',
      sort_by: 'asc',
      show_stockout: false,
      show_instock: true,
      show_purchaseable: true,
      show_unpurchaseable: false,
      sku_code: params?.sku_code,
      excluded_product_ids: params?.excluded_product_ids,
    });

    const dataWithReferralSkuCode = data?.data?.map((similarProduct) => ({
      ...similarProduct,
      referrer_sku_code: params?.sku_code,
    }));

    return dataWithReferralSkuCode;
  } catch (error) {
    throw new Error(error as any);
  }
};

export const setSimilarProductCarouselLoading: ThunkActionCreator<Actions> =
  (location: SimilarProductLocation, loading: boolean) => (dispatch) => {
    dispatch({
      type: constants.SET_SIMILAR_PRODUCT_CAROUSEL_LOADING,
      loading,
      location,
    });
  };

export const clearSimilarProductProcessedIds: ThunkActionCreator<Actions> = () => (dispatch) => {
  dispatch({
    type: constants.CLEAR_SIMILAR_PRODUCT_PROCESSED_IDS,
  });
};

export const clearSimilarProductCarousel: ThunkActionCreator<Actions, State> =
  (location: SimilarProductLocation) => (dispatch, getState) => {
    const state = getState();
    const currentState = state.productCarousel.similarProduct[location];

    if (!currentState.skuCode) return;
    dispatch({
      type: constants.CLEAR_SIMILAR_PRODUCT_CAROUSEL,
      location,
    });

    dispatch(clearSimilarProductProcessedIds());
  };

export const getSimilarProductCarousel: ThunkActionCreator<Actions, State> =
  (payload: IFetchSimilarproductCarousel, location: SimilarProductLocation) => async (dispatch, getState) => {
    try {
      const state = getState();
      const currentState = state.productCarousel.similarProduct[location];
      if (currentState.skuCode === payload.params.sku_code && currentState.products.length) return;

      dispatch(setSimilarProductCarouselLoading(location, true));
      const organizationId = state.auth.coreUser?.organization_id;
      const data = await fetchSimilarProduct({ ...payload, organizationId });

      dispatch({
        type: constants.GET_SIMILAR_PRODUCT_CAROUSEL,
        location,
        products: data,
        skuCode: payload.params?.sku_code,
        marketingId: payload.marketingId,
      });

      dispatch(setHasSimilarProducts(!!data?.length));
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'fetchSimilarProductCarousel', { payload });
        const errMsg = error.message;
        // eslint-disable-next-line no-console
        console.error(errMsg);
      }
    } finally {
      dispatch(setSimilarProductCarouselLoading(location, false));
    }
  };

export const setSimilarProductProcessedId: ThunkActionCreator<Actions, State> =
  (id: number, location: SimilarProductLocation) => (dispatch, getState) => {
    const state = getState();
    const processedIds = state.productCarousel.similarProcessedIds;

    const cacheKey = `${id}-${location}`;

    const cachedIds = [...processedIds, cacheKey];

    dispatch({
      type: constants.SET_SIMILAR_PRODUCT_PROCESSED_IDS,
      ids: cachedIds,
    });
  };
