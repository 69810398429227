export const UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY';
export type UPDATE_PRODUCT_QUANTITY = typeof UPDATE_PRODUCT_QUANTITY;

export const FAIL_CART = 'FAIL_CART';
export type FAIL_CART = typeof FAIL_CART;

// reducer from Carts
export const GET_CART = 'GET_CART';
export type GET_CART = typeof GET_CART;

// reducer from Carts
export const GETTING_CART = 'GETTING_CART';
export type GETTING_CART = typeof GETTING_CART;

export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export type UPDATE_CART_ITEM = typeof UPDATE_CART_ITEM;

export const RESET_CART_COUNTER = 'RESET_CART_COUNTER';
export type RESET_CART_COUNTER = typeof RESET_CART_COUNTER;

export const CART_UPDATED = 'CART_UPDATED';
export type CART_UPDATED = typeof CART_UPDATED;

export const EMPTY_CART = 'EMPTY_CART';
export type EMPTY_CART = typeof EMPTY_CART;

export const UPDATE_PURCHASE_ORDER_RATING = 'UPDATE_PURCHASE_ORDER_RATING';
export type UPDATE_PURCHASE_ORDER_RATING = typeof UPDATE_PURCHASE_ORDER_RATING;

export const MAX_QUANTITY_CART_ITEM = 'MAX_QUANTITY_CART_ITEM';
export type MAX_QUANTITY_CART_ITEM = typeof MAX_QUANTITY_CART_ITEM;

export const MAX_QUANTITY_CART_ITEM_ADD = 'MAX_QUANTITY_CART_ITEM_ADD';
export type MAX_QUANTITY_CART_ITEM_ADD = typeof MAX_QUANTITY_CART_ITEM_ADD;
