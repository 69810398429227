import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import { TFunction } from 'utils/Localization';
import { getMarketId } from 'utils/MarketConfig';

import { Typography, Grid, Card } from '@material-ui/core';
import { ShoppingBasket } from '@material-ui/icons';
import ShowIf from 'components/views/ShowIf';
import { setCloseSnackbarCart } from 'store/reducers/Utils/actions';
import { SnackbarCartNotification, AddToCartText, MaxPurchaseText } from './snackbarcart.styles';

interface Props {
  t: TFunction;
  open: boolean;
  productId: number;
  itemCount: number;
  hasPurchased: boolean;
  purchaseableQuantity: number;
  shouldShowMaxPurchaseText?: boolean;
  style?: React.CSSProperties;
}

const SnackbarCartBase: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  productId,
  itemCount,
  hasPurchased,
  purchaseableQuantity,
  shouldShowMaxPurchaseText = false,
  style,
  t,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const marketId = getMarketId();

  const [isVisible, setIsVisible] = useState(true);
  const showMaxPurchaseText = shouldShowMaxPurchaseText && hasPurchased;

  useEffect(() => {
    let timeout;
    if (productId) {
      setIsVisible(true);
      timeout = setTimeout(() => {
        setIsVisible(false);
        dispatch(setCloseSnackbarCart());
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, itemCount]);

  return (
    <>
      {open && isVisible && (
        <SnackbarCartNotification onClick={() => push(`/${marketId}/prices`)} style={style}>
          <Card className="card">
            <Grid container direction="row" alignItems="center" style={{ padding: '0px 2%' }}>
              <Grid item xs={1}>
                <ShoppingBasket className="basket-icon" />
              </Grid>
              <Grid item xs={9} className="grid-view">
                <AddToCartText>
                  {itemCount} {t('addedToCart')}
                </AddToCartText>
                <ShowIf condition={showMaxPurchaseText}>
                  <MaxPurchaseText>
                    {t('addedToCartWithMaxQty', { maxPurchaseQty: purchaseableQuantity })}
                  </MaxPurchaseText>
                </ShowIf>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'end' }} className="grid-view">
                <Typography className="cart-text">{t('seeCart')}</Typography>
              </Grid>
            </Grid>
          </Card>
        </SnackbarCartNotification>
      )}
    </>
  );
};

SnackbarCartBase.defaultProps = {
  shouldShowMaxPurchaseText: false,
  style: {},
};

export const SnackbarCartComponent = translate('productDetailPage')(SnackbarCartBase);
