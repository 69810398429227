export const GET_FAVORITES = 'GET_FAVORITES';
export type GET_FAVORITES = typeof GET_FAVORITES;

export const CLEAR_FAVORITES = 'CLEAR_FAVORITES';
export type CLEAR_FAVORITES = typeof CLEAR_FAVORITES;

export const FAIL_GET_FAVORITES = 'FAIL_GET_FAVORITES';
export type FAIL_GET_FAVORITES = typeof FAIL_GET_FAVORITES;

export const ADD_TO_FAVORITE_PRODUCT = 'ADD_TO_FAVORITE_PRODUCT';
export type ADD_TO_FAVORITE_PRODUCT = typeof ADD_TO_FAVORITE_PRODUCT;

export const REMOVE_FROM_FAVORITE_PRODUCT = 'REMOVE_FROM_FAVORITE_PRODUCT';
export type REMOVE_FROM_FAVORITE_PRODUCT = typeof REMOVE_FROM_FAVORITE_PRODUCT;

export const ADD_DELAYED_FAVOTITE_REQUEST = 'ADD_DELAYED_FAVOTITE_REQUEST';
export type ADD_DELAYED_FAVOTITE_REQUEST = typeof ADD_DELAYED_FAVOTITE_REQUEST;

export const ADD_DELAYED_REMOVE_FAVORITE_REQUEST = 'ADD_DELAYED_REMOVE_FAVORITE_REQUEST';
export type ADD_DELAYED_REMOVE_FAVORITE_REQUEST = typeof ADD_DELAYED_REMOVE_FAVORITE_REQUEST;

export const CLEAR_DELAYED_FAVORITE_REQUEST = 'CLEAR_DELAYED_FAVORITE_REQUEST';
export type CLEAR_DELAYED_FAVORITE_REQUEST = typeof CLEAR_DELAYED_FAVORITE_REQUEST;
