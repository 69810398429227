import { useState } from 'react';

interface ToggleHook {
  isOpen: boolean;
  openHandler: () => void;
  closeHandler: () => void;
  toggle: () => void;
}

export const useToggle = (defaultOpen = false): ToggleHook => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggle = (): void => {
    setIsOpen((x) => !x);
  };

  const openHandler = (): void => {
    setIsOpen(true);
  };

  const closeHandler = (): void => {
    setIsOpen(false);
  };

  return {
    isOpen,
    openHandler,
    closeHandler,
    toggle,
  };
};
