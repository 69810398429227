import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as colors from '../../styles/colors';
import Layout from '../../views/Layout';

interface Props {
  inverse?: boolean;
}

const LoadingPage: React.FC<React.PropsWithChildren<Props>> = ({ inverse = false }) => {
  const backgroundColor = inverse ? colors.OFF_WHITE : colors.PRIMARY_COLOR;
  const loaderColor = inverse ? colors.PRIMARY_COLOR : colors.WHITE;

  return (
    <Layout full background={backgroundColor}>
      <Layout centered>
        <CircularProgress style={{ color: loaderColor }} variant="indeterminate" size={60} color="secondary" />
      </Layout>
    </Layout>
  );
};

export default LoadingPage;
