import * as React from 'react';
import { AxiosError } from 'axios';
import { MaintenanceConfig } from './firebase';

export enum MaintenanceActions {
  CONFIG_LOAD = 'CONFIG_LOAD',
  ASK_REOPEN = 'ASK_REOPEN',
  API_FAIL = 'API_FAIL',
  API_RESET_ERROR = 'API_RESET_ERROR',
}
export type MaintenanceAction = {
  type: MaintenanceActions;
  config?: MaintenanceConfig;
  error?: AxiosError;
  askReopen?: boolean;
};
export type DispatchMaintenance = (action: MaintenanceAction) => void;
export type MaintenanceState = {
  config?: MaintenanceConfig | null;
  error?: AxiosError | null;
  askReopen?: boolean | null;
};

export const MaintenanceStateContext = React.createContext<MaintenanceState | undefined>(undefined);
export const MaintenanceDispatchContext = React.createContext<DispatchMaintenance | undefined>(undefined);

export const initialState: MaintenanceState = {
  config: null,
  error: null,
  askReopen: false,
};

export const maintenanceReducer = (state: MaintenanceState, action: MaintenanceAction): MaintenanceState => {
  switch (action.type) {
    case MaintenanceActions.CONFIG_LOAD:
      return {
        ...state,
        config: action.config,
      };
    case MaintenanceActions.ASK_REOPEN:
      return {
        ...state,
        askReopen: action.askReopen,
      };

    case MaintenanceActions.API_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return initialState;
  }
};

export const useMaintenaceState = (): MaintenanceState => {
  const context = React.useContext(MaintenanceStateContext);

  if (context === undefined) {
    throw new Error('useMaintenaceState must be used within maintenance component');
  }

  return context;
};

export const useMaintenanceDispatch = (): DispatchMaintenance => {
  const context = React.useContext(MaintenanceDispatchContext);

  if (context === undefined) {
    throw new Error('useMaintenanceDispatch must be used within a maintenance component');
  }

  return context;
};
