import React from 'react';
import { GridProps } from '@material-ui/core/Grid';
import { S } from './row.styles';

interface Props extends GridProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const Row: React.FC<React.PropsWithChildren<Props>> = ({ children, className, style, ...otherProps }) => (
  <S.Row style={{ ...style }} className={className} {...otherProps}>
    {children}
  </S.Row>
);
