export enum PaymentDirectPaymentStatusEnum {
  NEW = 'NEW',
  PAID = 'PAID',
  DUPLICATE_PAID = 'DUPLICATE_PAID',
  UNPAID = 'UNPAID',
  REJECTED = 'REJECTED',
}

export enum PaymentDirectBillStatusEnum {
  NO_BILLER = 'NO_BILLER',
  NEW = 'NEW',
  ISSUE = 'ISSUE',
  INACTIVE = 'INACTIVE',
  PAID = 'PAID',
  UNMATCH = 'UNMATCH',
}
