import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { ProductRequestDialog as Dialog, StateProps } from './dialog.component';
import { ProductRequestLink as Link } from './link.component';

import { State as StoreState } from '../../../store';
import {
  showDialog,
  hideDialog,
  submitProductRequest,
} from '../../../store/reducers/ProductRequest/actions';

const mapStateToProps = (state: StoreState): StateProps => ({
  isOpen: state.productRequest.isOpen,
});

const mapDispatchToProps = {
  hideDialog,
  submitProductRequest,
};

export const ProductRequestDialog = translate('productRequest')(
  connect(mapStateToProps, mapDispatchToProps)(Dialog),
);

export const ProductRequestLink = translate('productRequest')(connect(null, { showDialog })(Link));
