import { Grid, Typography, Divider } from '@material-ui/core';
import styled from 'styled-components';
import { Lock, CalendarTodayRounded } from '@material-ui/icons';
import * as colors from 'components/styles/colors';

export const ScrollableGridContainer = styled(Grid).attrs({
  container: true,
  direction: 'column',
  spacing: 1,
})`
  height: calc(100% - 50px);
  overflow: scroll;
`;

export const RelativeGridItem = styled(Grid).attrs({
  item: true,
})<any>`
  && {
    flex: 1;
    padding: ${({ padding }) => padding || '1rem'} !important;
    position: relative;
  }
`;

export const StickyFooterGridItem = styled(RelativeGridItem)<any>`
  && {
    background: transparent;
    position: sticky;
    bottom: 0;
    padding-bottom: 6px !important;
    z-index: 6;

    .container {
      background: white;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const ProductName = styled(Typography)`
  && {
    color: black;
    font-weight: 700;
    font-size: 14px;
    max-width: calc(100% - 32px);
    word-break: break-all;
  }
`;

export const MaxQty = styled.span`
  color: ${colors.PURPLE_DARK};
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.16px;
`;

export const ProductPackaging = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    max-width: calc(100% - 32px);
  }
`;

export const ProductPriceContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const IconTitleContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
`;

export const ProductSellingPrice = styled(Typography)`
  && {
    color: var(--Text-Grey, #717786);
    font-family: Nunito Sans, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    text-decoration-line: line-through;
  }
`;

export const ProductDiscountedPrice = styled(Typography)`
  && {
    color: #000;
    font-family: Nunito Sans, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
  }
`;

export const BoldCaption = styled(Typography)`
  && {
    color: #000;
    font-family: Nunito Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
  }
`;

export const SubCaption = styled(Typography)`
  && {
    color: #000;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
    padding-top: 16px;
    padding-bottom: 8px;
  }
`;

export const PharmacyTierContainer = styled.div<any>`
  background: rgba(255, 127, 48, 0.1);
  border-radius: 4px;
  padding: 20px;
`;

export const UnverifiedLockIcon = styled(Lock)`
  && {
    color: ${colors.ORANGE};
    float: left;
    padding-right: 15px;
    font-size: 1.2em;
  }
`;

export const PharmacyTierLearnMore = styled(Typography)`
  && {
    text-transform: uppercase;
    cursor: pointer;
    text-align-last: right;
    color: ${colors.ORANGE};
  }
`;

export const TierContainer = styled.div<any>`
  max-height: 235px;
  overflow: ${({ tour }) => (tour ? 'initial' : 'scroll !important')};
`;

export const CartDivider = styled(Divider)`
  && {
    color: ${colors.WEAK_GRAY};
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const SectionDivider = styled(Divider)`
  && {
    color: ${colors.WEAK_GRAY};
  }
`;

export const ProductDetailIcon = styled.div`
  && {
    color: rgba(107, 124, 147, 1);
    width: 16px;
    height: 16px;
    font-size: inherit;
    line-height: 1;
  }
`;

export const ExpiryDateIcon = styled(CalendarTodayRounded)`
  && {
    width: 14px;
    height: 14px;
    padding-right: 4px;
    color: ${colors.ORANGE};
  }
`;

export const ProductDetailContainer = styled.div`
  && {
    padding-top: 8px;
  }
`;

export const ProductDetailGrid = styled.div`
  && {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ProductDetailItemTitle = styled(Typography)`
  && {
    color: #717786;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    padding-bottom: 8px;
  }
`;

export const ProductDetailItem = styled(Typography)`
  && {
    color: #000;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    padding-bottom: 8px;
  }
`;

export const ProductInfoBody = styled(Typography)`
  && {
    color: #717786;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
    padding-top: 8px;
  }
`;

export const tierStyles: {
  container: React.CSSProperties;
} = {
  container: {
    paddingTop: 0,
    marginTop: 8,
  },
};
