import * as Redux from 'redux';
import { Actions } from './actions';
import * as constants from './constants';

export interface State {
  loading: boolean;
  error: string | null;
  disabled: boolean;
  pendingUpload: number;
}

const InitialState: State = {
  loading: false,
  error: null,
  disabled: false,
  pendingUpload: 0,
};

export const PrekursorStatusReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SET_PREKURSOR_STATUS_DISABLED:
      return {
        ...state,
        loading: false,
        disabled: true,
      };

    case constants.SET_PREKURSOR_STATUS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case constants.FAIL_PATCH_PREKURSOR_STATUS:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case constants.SUCCESS_PATCH_PREKURSOR_STATUS:
      return {
        ...state,
        loading: false,
      };

    case constants.SUCCESS_GET_PENDING_UPLOAD_PREKURSOR:
      return {
        ...state,
        pendingUpload: action.payload,
      };
    case constants.FAIL_GET_PENDING_UPLOAD_PREKURSOR:
      return {
        ...state,
        pendingUpload: 0,
      };
    default:
      return state;
  }
};

export default PrekursorStatusReducer;
