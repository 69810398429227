import styled from 'styled-components';
import { Typography, Grid, Button } from '@material-ui/core';

import * as colors from 'components/styles/colors';

const Title = styled(Typography)`
  && {
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    padding: 17px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.62);
  }
`;

const Container = styled(Grid)`
  display: flex;
  padding: 0px 14px;
  justify-content: space-between;
`;

const CancelButton = styled(Button)`
  && {
    text-transform: none;
    background: #eceef1;
    border-radius: 5px;
    font-size: 16px;
    color: #717786;
    width: 24%;
  }
`;

const FilterGroup = styled(Typography)`
  && {
    display: block;
    padding: 15px 20px;
    font-size: 12px;
    font-weight: 800;
    color: ${colors.BLUISH_GRAY_LIGHT};
    text-transform: uppercase;
  }
`;

const SaveButton = styled(Button)`
  && {
    text-transform: none;
    background: #00ad8f;
    border-radius: 5px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.97);
    width: 70%;
  }
`;

const ControlsContainer = styled(Grid)`
  display: flex;
  padding: 32px 16px 14px 14px;
  justify-content: space-between;
`;

const HeaderControlsContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

const ToggleButton = styled(Button)`
  && {
    display: block;
    padding: 15px 20px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 15px;
    color: ${colors.DANGER_DARK};
    text-transform: none;
  }
`;

export const S = {
  Container,
  Title,
  FilterGroup,
  CancelButton,
  SaveButton,
  ControlsContainer,
  HeaderControlsContainer,
  ToggleButton,
};
