import { ProductListingParam } from 'components/common';
import { recordException } from 'utils/Reporting/Sentry';
import pick from 'lodash/pick';

import { FilterType, SortType } from 'components/common/FilterToolbar/constants';
import { getJoinedFiltersObject } from 'components/common/FilterToolbar/FilterToolbar.config';
import { ThunkActionCreator } from '../../../types/thunk';
import { State } from '../../index';

import * as constants from './constants';
import { Actions, FilterMenuItem } from './interface';
import { getFilterUrlBySelectedFilters, assignFiltersBasedOnConfig } from './utils';
import { FilterToolbarRequest } from '.';

const getWhiteListedFilters = (activeFilterValues: string[]): Partial<{ [v: string]: FilterMenuItem }> => {
  const exactMatchValues = pick<
    Partial<{
      [k in FilterType]: FilterMenuItem;
    }>
  >(getJoinedFiltersObject(), activeFilterValues);

  const regexMatchValues = Object.values(getJoinedFiltersObject())
    .filter((o) => o.isRegex)
    .filter((o) => activeFilterValues.some((ac) => new RegExp(o.value).test(ac)))
    .reduce((acc, v) => {
      const uiValue = activeFilterValues.find((fv) => {
        const [label] = fv.split(constants.LABEL_VALUE_SEPARATOR);
        return label === v.type;
      });
      acc[v.value] = { ...v, value: uiValue }; // take it from the UI option
      return acc;
    }, {} as { [v: string]: FilterMenuItem });
  const whitelistedActiveFilters = {
    ...exactMatchValues,
    ...regexMatchValues,
  };
  return whitelistedActiveFilters;
};

export const selectFilter: ThunkActionCreator<Actions, State> =
  (payload: FilterToolbarRequest) => async (dispatch, _getState) => {
    try {
      const initParam: ProductListingParam = {
        category_ids: [],
        query: undefined,
        filters: [],
        sorts: [],
      };
      const whitelistedActiveFilters = getWhiteListedFilters(payload.selectedFilters || []);
      const searchParameters = Object.values(whitelistedActiveFilters).reduce((acc, v) => {
        if (!v) {
          return acc;
        }
        acc = assignFiltersBasedOnConfig(acc, v);
        return acc;
      }, initParam);

      const mappedWhiteActiveFilters = Object.values(whitelistedActiveFilters).filter((f) => !!f) as FilterMenuItem[];
      const possibleFilterSorts = mappedWhiteActiveFilters
        .map((f) => f.filterSort)
        .filter((url) => !!url) as SortType[];
      // NOTE: possibly sort prioritization for filter sorts
      const filterSort: SortType = possibleFilterSorts[0] || SortType.STATE_SORT;
      const filterUrl = getFilterUrlBySelectedFilters(mappedWhiteActiveFilters);

      dispatch({
        type: constants.SELECT_FILTER,
        selectedFilters: payload.selectedFilters || [],
        selectedSorts: payload.selectedSorts || [],
        searchParameters,
        filterUrl,
        filterSort,
        lvl2SortBy: (payload.selectedSorts as SortType[]) || [],
        allowSearch: payload.allowSearch,
      });
    } catch (error) {
      recordException(error as Error, 'selectFilter', { payload });
      const errMsg = (error as Error).message;
      // eslint-disable-next-line no-console
      console.error(errMsg);
      dispatch({ type: constants.ERROR_ON_FILTER, error: errMsg });
    }
  };

export const setupFilter: ThunkActionCreator<Actions, State> =
  (payload: {
    toolbarFilterMenu: FilterMenuItem[];
    defaultSelection: string[];
    selectedFilters: string[];
    selectedSorts: string[];
  }) =>
  async (dispatch) => {
    const { toolbarFilterMenu = [], defaultSelection, selectedFilters, selectedSorts } = payload;
    try {
      dispatch({
        type: constants.SETUP_FILTER,
        toolbarFilterMenu,
      });
    } catch (error) {
      recordException(error as Error, 'setupFilter', { payload });
      const errMsg = (error as Error).message;
      // eslint-disable-next-line no-console
      console.error(errMsg);
      dispatch({ type: constants.ERROR_ON_FILTER, error: errMsg });
    } finally {
      dispatch(
        selectFilter({ selectedFilters: selectedFilters || defaultSelection, selectedSorts, allowSearch: false }),
      );
    }
  };

export const clearFilter: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  try {
    dispatch({
      type: constants.CLEAR_FILTERS,
    });
  } catch (error) {
    recordException(error as Error, 'clearFilter');
    const errMsg = (error as Error).message;
    // eslint-disable-next-line no-console
    console.error(errMsg);
    dispatch({ type: constants.ERROR_ON_FILTER, error: errMsg });
  }
};

export const setShowFilterSortModal: ThunkActionCreator<Actions, State> =
  (show: boolean) => async (dispatch, _getState) => {
    try {
      dispatch({
        type: constants.SET_SHOW_FILTER_SORT_MODAL,
        show,
      });
    } catch (error) {
      recordException(error as Error, 'setShowFilterSortModal');
      const errMsg = (error as Error).message;
      // eslint-disable-next-line no-console
      console.error(errMsg);
      dispatch({ type: constants.SET_SHOW_FILTER_SORT_MODAL, error: errMsg });
    }
  };

export const setReinitializeFilters: ThunkActionCreator<Actions> = (reinitialize: boolean) => async (dispatch) => {
  dispatch({
    type: constants.SET_REINITIALIZE_FILTERS,
    reinitialize,
  });
};
