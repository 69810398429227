import React, { CSSProperties, useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { translate } from 'react-i18next';

import { useBannerCarousel, useHomesquareCarousel, useProductCarousel, useWidgetTracker } from 'hooks';

import {
  IBanner,
  IMarketing,
  MarketingFilters,
  MarketingType,
} from 'services/swipe-rx-pt/resources/marketings/interfaces';

import { getMarketId } from 'utils/MarketConfig';
import { TFunction } from 'utils/Localization';

import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';

import {
  AnalyticsEvent,
  BasePageTracker,
  getEventPageIdentity,
  getEventPageIdentityImpression,
} from 'utils/Analytics/Segment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { State as StoreState } from 'store';
import { recentlyViewedPageTitle } from 'utils/Localization/lang/en';
import { clearProductListing } from 'store/reducers/ProductListing/actions';
import { setHomeWidgetFinishLoaded } from 'store/reducers/Global/actions';
import { Product } from 'store/reducers/Product';
import { BannerCarousel } from '../BannerCarousel';
import { SkeletonLoader } from '../skeleton-loader';
import { ProductCarousel } from '../ProductCarousel';
import { HomesquareCarousel } from '../HomesquareCarousel';
import { GENERIC_PRODUCT_WIDGET } from './widget.constant';
import { CarouselContainer } from './carousel.component';
import { getIsPriorityWidgetsFinishLoad, getIsWidgetLoading } from './widget.util';

interface CarouselContainerProps {
  hideTitle?: boolean;
  titleCaption: string;
  bgColorConfig: string;
  textColorConfig: string;
  seeAllCaption: string;
  seeAllStyling: CSSProperties;
  showDivider: boolean;
  showSeeAll: boolean;
  noPadding?: boolean;
  tours?: {
    id: string;
  };
  onSeeAll: () => void;
  loading?: boolean;
}

export interface WidgetParams {
  sku_code?: string;
  excluded_product_ids?: number[];
  selectedFilters?: MarketingFilters[];
}

export interface WidgetProps {
  t: TFunction;
  marketing: IMarketing;
  defaultStyling?: any;
  params?: WidgetParams;
  hideTitle?: boolean;
  onItemClick: (link: string) => void;
  config?: {
    onSimilarProductLoad: (param: any) => void;
  };
  detailPageDisplayType?: DetailPageDisplayType;
  isHomepage?: boolean;
  index?: number;
  isPriorityLoad?: boolean;
  productImpressionPage?: BasePageTracker;

  // TODO: in the Homepage Improvement phase 2 should use this
  data?: Product[]; // can add more type or adjust prop name in Homepage Improvement phase 2
  loading?: boolean;
}

const WidgetComponentBase: React.FC<React.PropsWithChildren<WidgetProps>> = ({
  t,
  marketing,
  hideTitle = false,
  defaultStyling,
  onItemClick,
  config,
  params,
  detailPageDisplayType,
  isHomepage,
  index,
  isPriorityLoad,
  data,
  loading,
  productImpressionPage,
}) => {
  const marketingNameMapping = {
    [MarketingType.RECENT]: recentlyViewedPageTitle,
  };
  const { push, location } = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { track } = useWidgetTracker({
    id: [MarketingType.RECENT, MarketingType.CATEGORY].includes(marketing.type) ? 1 : marketing.id,
    name: marketingNameMapping[marketing.type] ? marketingNameMapping[marketing.type] : marketing.name,
  });
  const recentlyViewedProducts = useSelector((state: StoreState) => state.search?.recentlyViewedProducts, shallowEqual);

  const isPriorityWidgetsFinishLoad = useSelector(
    (state: StoreState) => getIsPriorityWidgetsFinishLoad(state),
    shallowEqual,
  );
  const fetchAllowed = isHomepage ? !!(isPriorityWidgetsFinishLoad || isPriorityLoad) : true;

  const { data: bannerList, isLoading: isBannerLoading } = useBannerCarousel({
    marketingId: marketing.id,
    marketingType: marketing.type,
    fetchAllowed,
  });

  // const eventPageIdentity = getEventPageIdentity(match, location.search);

  const {
    data: productList,
    isLoading: isProductLoading,
    hide: hideProductWidget,
  } = useProductCarousel({
    marketingId: marketing.id,
    marketingType: marketing.type,
    productIds: marketing.type === MarketingType.RECENT ? recentlyViewedProducts : undefined,
    fetchAllowed,
  });

  const { data: homesquare, isLoading: isHomesquareLoading } = useHomesquareCarousel({
    marketingId: marketing.id,
    marketingType: marketing.type,
    fetchAllowed,
  });

  const isWidgetLoading = getIsWidgetLoading(marketing?.type, {
    isBannerLoading,
    isHomesquareLoading,
    isProductLoading,
  });
  const prevIsWidgetLoading = useRef<boolean>();

  useEffect(() => {
    if (isPriorityLoad && !!index) {
      // is fetch finish
      if (prevIsWidgetLoading?.current === true && isWidgetLoading === false) {
        dispatch(setHomeWidgetFinishLoaded(index));
      }
      prevIsWidgetLoading.current = isWidgetLoading;
    }
  }, [isPriorityLoad, index, isWidgetLoading]);

  const isProductListingLoading = isProductLoading && !productList;
  if (
    (isProductListingLoading && GENERIC_PRODUCT_WIDGET.includes(marketing.type) && !isHomepage) ||
    ((isBannerLoading || !fetchAllowed) && marketing.type === MarketingType.BANNER)
  ) {
    return <SkeletonLoader variant="xl" width="100%" height={200} />;
  }

  const titleCaption = marketing.name;
  const styling = marketing.config?.styling || defaultStyling;
  const dotNavHidden =
    marketing.type === MarketingType.PRODUCT || styling?.dot_navigation?.hidden || bannerList.length === 1 || false;

  const bgColorConfig = styling?.color || 'transparent';
  const textColorConfig = bgColorConfig === 'transparent' || bgColorConfig === 'white' ? 'initial' : 'white';
  const isUsingTopNav = styling?.see_all?.position === 'top';
  const showSeeAll = !styling?.see_all?.hidden;
  const seeAllCaption = styling?.see_all?.caption || t('seeAll');
  const seeAllStyling: CSSProperties = isUsingTopNav
    ? {
        position: 'absolute',
        top: 6,
        right: 12,
        color: textColorConfig !== 'initial' ? textColorConfig : undefined,
      }
    : {
        position: 'absolute',
        bottom: !dotNavHidden ? 10 : undefined,
        right: 12,
        color: textColorConfig !== 'initial' ? textColorConfig : undefined,
      };

  const isBanner = (item: IBanner | any): item is IBanner => !!(item as IBanner).banner_link;
  const marketId = getMarketId();

  const goToBannerList = (): void => {
    push(`/${marketId}/marketing/${marketing.id}/banner-listing?name=${marketing.name}`);
  };

  const goToProductList = (): void => {
    track('view_pt_product_listing', AnalyticsEvent.Track, {
      marketing_type: marketing.type,
      marketing_name: marketing.name,
    });
    push(`/${marketId}/marketing/${marketing.id}/product-listing`);
  };

  const goToRecentlyViewedList = (): void => {
    track('view_pt_product_listing', AnalyticsEvent.Track, {
      marketing_type: marketing.type,
      marketing_name: marketing.name,
    });
    push(`/${marketId}/recent-products`, { marketing_id: marketing.id });
  };

  const goToSimilarProductList = (): void => {
    dispatch(clearProductListing());
    push(`/${marketId}/similar-products/${params?.sku_code}`, {
      initial_page: getEventPageIdentity(match),
    });
  };

  const goToRepurchaseList = (): void => {
    track('view_pt_product_listing', AnalyticsEvent.Track, {
      marketing_type: marketing.type,
      marketing_name: marketing.name,
      from_page: getEventPageIdentity(match),
    });
    push(`/${marketId}/recently-purchased`, {
      marketing,
    });
  };

  const handleBannerClick = (item: IBanner): void => {
    track('pt_banner_widgets', AnalyticsEvent.Track, {
      banner_name: item.title,
      banner_link: item.banner_link,
    });

    switch (true) {
      case item.banner_link.startsWith('/'):
        push(item.banner_link);
        break;
      case item.banner_link.includes('swiperx.app.link'):
        window.location.assign(item.banner_link);
        break;
      default:
        onItemClick(item.banner_link);
        break;
    }
  };

  const handleItemClick = (item: IBanner | any): void => {
    if (isBanner(item)) {
      handleBannerClick(item);
    }
  };

  const handleSeeAllNavigation = (): void => {
    const goTo = {
      [MarketingType.BANNER]: goToBannerList,
      [MarketingType.PRODUCT]: goToProductList,
      [MarketingType.AUTO_PLAYLIST]: goToProductList,
      // TODO: SPT-16574: Standardize special marketing dynamic Route
      [MarketingType.RECENT]: goToRecentlyViewedList,
      [MarketingType.SIMILAR_PRODUCT]: goToSimilarProductList,
      [MarketingType.REPURCHASE]: goToRepurchaseList,
    };

    goTo[marketing.type]();
  };

  const carouselProps: CarouselContainerProps = {
    hideTitle,
    titleCaption,
    bgColorConfig,
    textColorConfig,
    seeAllCaption,
    seeAllStyling,
    showSeeAll,
    showDivider: dotNavHidden && showSeeAll && !isUsingTopNav,
    tours: marketing.config?.tours,
    onSeeAll: handleSeeAllNavigation,
  };

  if (marketing.type === MarketingType.BANNER) {
    return bannerList.length ? (
      <CarouselContainer {...carouselProps} show>
        <BannerCarousel
          data={bannerList}
          dotNavigationHidden={dotNavHidden}
          onBannerClick={handleItemClick}
          data-testid="carousel-product-banner"
        />
      </CarouselContainer>
    ) : null;
  }

  if (GENERIC_PRODUCT_WIDGET.includes(marketing.type)) {
    const analyticsDataLog = {
      containerName: `carousel-${marketing.type?.replace(/\s/g, '')}-product-${marketing.id}`,
      itemName: `carousel-${marketing.type?.replace(/\s/g, '')}-item-product-${marketing.id}`,
    };

    if (hideProductWidget) {
      return null;
    }

    const productFetchingAndWithData = productList === undefined || (productList && productList.length > 0);
    return (
      <>
        <CarouselContainer
          {...carouselProps}
          noPadding
          loading={isProductListingLoading || !fetchAllowed}
          show={productFetchingAndWithData}
        >
          <ProductCarousel
            data={productList}
            marketingId={marketing.id}
            marketingName={marketing.name}
            data-testid={`carousel-${marketing.type?.replace(/\s/g, '-')}`}
            productImpressionPage={
              isProductListingLoading
                ? undefined
                : getEventPageIdentityImpression(match, location.search, productImpressionPage)
            }
            analyticsDataLog={isProductListingLoading ? undefined : analyticsDataLog}
            loading={isProductListingLoading || !fetchAllowed}
            loadingFiller={2}
          />
        </CarouselContainer>
        {/* force show skeleton */}
        {/* <CarouselContainer {...carouselProps} noPadding loading>
          <ProductCarousel data={productList} loading loadingFiller={3} />
        </CarouselContainer> */}
      </>
    );
  }

  if (marketing.type === MarketingType.SIMILAR_PRODUCT) {
    const analyticsDataLog = {
      containerName: `product-carousel-listing-similar-product-${marketing.id}`,
      itemName: `product-carousel-listing-item-similar-product-${marketing.id}`,
    };

    if (hideProductWidget) {
      return null;
    }

    return (
      <>
        {config?.onSimilarProductLoad(data)}
        <CarouselContainer {...carouselProps} noPadding loading={loading ?? isHomepage} show={!!data?.length}>
          <ProductCarousel
            data={data}
            marketingId={marketing.id}
            marketingName={marketing.name}
            data-testid="carousel-similar-product"
            detailPageDisplayType={detailPageDisplayType}
            // SPT-15217: hide impression for now as it need to be adjusted
            // productImpressionPage={isProductListingLoading ? undefined : BasePageTracker.MARKETING_WIDGET}
            analyticsDataLog={loading ? undefined : analyticsDataLog}
            loading={loading && isHomepage}
            productImpressionPage={getEventPageIdentityImpression(match, location.search, productImpressionPage)}
            loadingFiller={2}
          />
        </CarouselContainer>
      </>
    );
  }

  if (marketing.type === MarketingType.HOMESQUARE) {
    return (
      <CarouselContainer {...carouselProps} hideTitle showSeeAll={false} noPadding show>
        <HomesquareCarousel homesquare={homesquare} loading={isHomesquareLoading || !fetchAllowed} />
      </CarouselContainer>
    );
  }

  return null;
};

export const Widget = translate('widget')(WidgetComponentBase);
