import qs from 'query-string';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { BasePageTracker, getEventPageIdentity } from 'utils/Analytics/Segment';
import { BRACKET_QS_OPTIONS } from 'utils/constants';

interface PageIdentityHook {
  base_page: BasePageTracker;
  goBackTo?: string;
  impressionPage: BasePageTracker;
}

export const usePageIdentity = (): PageIdentityHook => {
  const match = useRouteMatch();
  const location = useLocation<{ goBackTo: string }>();

  const parsedLocationSearch = qs.parse(location.search, BRACKET_QS_OPTIONS);
  const goBackTo = parsedLocationSearch?.goBackTo?.toString();
  const goBackToPath = goBackTo || location?.state?.goBackTo;

  const appendedLocationSearch = goBackToPath
    ? `${qs.stringify(
        {
          ...parsedLocationSearch,
          goBackTo: goBackToPath,
        },
        BRACKET_QS_OPTIONS,
      )}`
    : location.search;

  const pageIdentity = getEventPageIdentity(match, appendedLocationSearch);
  const impressionPage = (): BasePageTracker => {
    if (pageIdentity === BasePageTracker.CATEGORY_LISTING) return BasePageTracker.GENERAL_SEARCH;
    return pageIdentity;
  };

  return { base_page: pageIdentity, goBackTo: goBackToPath, impressionPage: impressionPage() };
};
