import React from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { Trans } from 'react-i18next';

import ShowIf from 'components/views/ShowIf';
import RatingImage from 'images/order-rating.svg';
import { Order } from 'services/swipe-rx-pt/resources/orders/interfaces';
import { CurrentOrderRating } from 'store/reducers/Orders';
import { TFunction } from 'utils/Localization';
import { S } from './orders-rating.styles';
import { Stars } from './stars';

export interface RatingSectionProps {
  t: TFunction;
  orderDetail?: Order;
  currentRate: number;
  handleRateClick: (value: number) => void;
  currentOrderRating?: CurrentOrderRating | null;
}

interface ConfettiProps {
  currentRate: number;
}

const Confetti = React.memo((props: ConfettiProps) => {
  const { currentRate } = props;

  return (
    <ShowIf condition={currentRate > 3}>
      <ConfettiExplosion />
    </ShowIf>
  );
});

const RatingSectionBase: React.FC<React.PropsWithChildren<RatingSectionProps>> = (props) => {
  const { t, orderDetail, currentRate, handleRateClick, currentOrderRating } = props;

  return (
    <S.RatingSectionCard style={{ marginTop: 15 }}>
      <Confetti currentRate={currentOrderRating?.rate || currentRate} />

      <S.RatingSectionImage alt="rating-section-image" src={RatingImage} />
      <S.LetsRateYourOrder>{t('letsRateYourOrder')}</S.LetsRateYourOrder>
      <S.HowDidYouLikeYourOrder>
        <Trans
          t={t}
          i18nKey="howDidYouLikeYourOrder"
          values={{ distributor: orderDetail?.distributor?.name || 'SwipeRx' }}
        >
          <strong>&nbsp;</strong>
        </Trans>
      </S.HowDidYouLikeYourOrder>
      <Stars t={t} handleClick={handleRateClick} currentOrderRating={currentOrderRating} currentRate={currentRate} />
    </S.RatingSectionCard>
  );
};

export const RatingSection = React.memo(RatingSectionBase);
