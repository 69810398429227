/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import qs from 'query-string';

import { State } from 'store';
import { fetchTours, saveTours, TriggerRenderTours } from 'store/reducers/Tour/actions';
import { TOURS } from 'components/common/Tours';
import { BRACKET_QS_OPTIONS } from 'utils/constants';

interface Args {
  tourName: keyof typeof TOURS;
  initialTour?: {
    title: string;
    description: string;
  };
  t: any; // translation
  template: any; // tour template
}

export const useTours = ({ tourName, t, initialTour, template }: Args) => {
  const [state, setState] = useState({
    run: false,
    stepIndex: 0,
    steps: [],
    automaticTourRender: false,
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const queryParams = qs.parse(location.search, BRACKET_QS_OPTIONS);

  const { tours, trigger_render, loading } = useSelector((state: State) => state.tours, shallowEqual);

  useEffect(() => {
    const showTour = queryParams.tour && queryParams.tour === 'true';

    if (loading || (tours.includes(tourName) && !showTour)) {
      return;
    }

    setState(() => ({
      ...state,
      run: true,
      automaticTourRender: true,
      steps: template(TOURS[tourName], initialTour, t, tourName),
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, tours]);

  useEffect(() => {
    if (tours.length > 0) return;

    dispatch(fetchTours());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTour = (type: 'automatic' | 'trigger') => {
    if (type === 'automatic') {
      const currentTours = tours || [];
      dispatch(saveTours([...currentTours, tourName]));
      setState(() => ({ ...state, automaticTourRender: false }));
    }
    dispatch(TriggerRenderTours(false));
  };

  const handleJoyride = (data) => {
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setState(() => ({ ...state, run: false, stepIndex: 0 }));

      if (state.automaticTourRender) {
        saveTour('automatic');
      } else {
        saveTour('trigger');
      }

      const query = new URLSearchParams(location.search);
      if (query.has('tour')) {
        query.delete('tour');
        history.replace({
          search: query.toString(),
        });
      }
    }

    if (type === EVENTS.STEP_AFTER) {
      if (index === 9) {
        setState(() => ({
          ...state,
          run: true,
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        }));
      }

      if (action === 'close' || action === 'stop') {
        setState(() => ({
          ...state,
          run: false,
          stepIndex: 0,
        }));

        if (state.automaticTourRender) {
          saveTour('automatic');
        } else {
          saveTour('trigger');
        }
      } else {
        setState(() => ({
          ...state,
          run: true,
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        }));
      }
    }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      setState(() => ({
        ...state,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      }));
    }
  };

  const tourState = !trigger_render ? { ...state } : { ...state, run: true };

  return {
    tourState,
    handleJoyride,
  };
};
