import { SwipeRxPt } from '../../SwipeRxPt.class';

export class SwiperxPtReturns {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async getByDepositId(depositID: number): Promise<any> {
    const response = await this.base.getV2(`/return/deposit/${depositID}`);

    return response;
  }
}
