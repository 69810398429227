import styled from 'styled-components';

import { Typography } from '@material-ui/core';

import AssignmentIcon from '@material-ui/icons/Assignment';
import * as colors from '../../../../styles/colors';

const Container = styled.div`
  width: 100vw;
  height: auto;
  background-color: ${colors.REBRAND_GREEN};
`;

const Wrapper = styled.div`
  margin: 20px 13px;
  color: ${colors.WHITE};
`;

const Title = styled(Typography)`
  && {
    color: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    margin-left: 7px;
  }
`;

const Content = styled(Typography)`
  && {
    color: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    margin: 3.76px 26px 0px 29px;
  }
`;

const Action = styled(Typography)`
  && {
    color: inherit;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;

const Icon = styled(AssignmentIcon)`
  && {
    margin-bottom: -5px;
  }
`;

export const S = {
  Container,
  Wrapper,
  Title,
  Content,
  Icon,
  Action,
};
