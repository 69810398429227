import * as Redux from 'redux';
import { Location } from 'history';
import { DEFAULT_MARKETING_LIST_PAGE_SIZE } from 'utils/constants';
import { getNumberEnvWithDefault } from 'utils/envs/env-number-workaround';
import constants from './constants';
import { Actions, WidgetListLoaded } from './actions';

const marketingPageSize = getNumberEnvWithDefault(
  process.env.REACT_APP_MARKETING_LIST_PAGE_SIZE,
  DEFAULT_MARKETING_LIST_PAGE_SIZE,
);

export interface State {
  error?: string;
  success?: string;
  isLoading: boolean;
  pendingRequests: number;
  backStackLength: number;
  backStack: Location[];
  widgetListLoaded: WidgetListLoaded[];
}

const InitialState: State = {
  isLoading: false,
  pendingRequests: 0,
  backStackLength: 0,
  backStack: [],
  widgetListLoaded: Array.from(
    // This one is intended, make sure same as getPriorityLoadWidgetCount()
    {
      length: Math.min(
        Math.max(Number.parseInt(process.env.REACT_APP_PRIORITY_WIDGET_COUNT || '0', 10) || 0, 0),
        marketingPageSize,
      ),
    },
    (_, i) => ({ index: i + 1, finishLoaded: false }),
  ),
};

const GlobalReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.START_APP_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constants.END_APP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case constants.SHOW_ERROR:
      return {
        ...state,
        error: action.message,
      };
    case constants.SHOW_SUCCESS:
      return {
        ...state,
        success: action.message,
      };
    case constants.HIDE_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case constants.HIDE_SUCCESS:
      return {
        ...state,
        success: undefined,
      };
    case constants.SET_BACK_STACK:
      return {
        ...state,
        backStack: action.backStack,
        backStackLength: action.backStackLength,
      };
    case constants.SET_HOME_WIDGET_FINISH_LOADED:
      return {
        ...state,
        widgetListLoaded: state.widgetListLoaded.map((widgetLoaded) => {
          if (widgetLoaded.index !== action.widgetIndex) {
            return widgetLoaded;
          }
          return {
            ...widgetLoaded,
            finishLoaded: true,
          };
        }),
      };
    case constants.RESET_HOME_WIDGETS_LOADED:
      return {
        ...state,
        widgetListLoaded: state.widgetListLoaded.map((widgetLoaded) => ({
          ...widgetLoaded,
          finishLoaded: false,
        })),
      };

    default:
      return state;
  }
};

export default GlobalReducer;
