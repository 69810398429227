import React from 'react';

import { Container } from './HorizontalScrollView.style';

export interface HorizontalScrollViewProps extends React.HTMLAttributes<HTMLDivElement> {
  styles?: {
    container: React.CSSProperties;
  };
}

export const HorizontalScrollView: React.FC<React.PropsWithChildren<HorizontalScrollViewProps>> = ({
  styles,
  children,
  ...props
}) => (
  <Container className="hs-container" style={styles?.container} id={props.id}>
    {children}
  </Container>
);
