/* eslint-disable @typescript-eslint/no-empty-function */
import * as Redux from 'redux';
import { Actions, ISnackbar, ISnackbarCart } from './actions';
import * as constants from './constants';

export interface State {
  snackbar: ISnackbar;
  snackbarCart: ISnackbarCart;
}

const InitialState: State = {
  snackbar: {
    open: false,
    message: '',
    titleAction: '',
    contentProps: {},
  },
  snackbarCart: {
    open: false,
    productId: 0,
    itemCount: 0,
    maxPurchaseLimit: { purchaseableQuantity: 0, hasPurchased: false },
  },
};

const UtilsReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SET_OPEN_SNACKBAR:
      return { ...state, snackbar: action.snackbar };
    case constants.SET_CLOSE_SNACKBAR:
      return InitialState;
    case constants.SET_OPEN_SNACKBAR_CART:
      return { ...state, snackbarCart: action.snackbarCart };
    case constants.SET_CLOSE_SNACKBAR_CART:
      return InitialState;
    default:
      return state;
  }
};

export default UtilsReducer;
