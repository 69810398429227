import React from 'react';
import { IconButton } from '@material-ui/core';
import { FavoriteLinkContainer, FavoriteLink } from './favorite-link.styles';

interface Props {
  id?: string;
  onClick: () => void;
}

export const FavoriteLinkIcon: React.FC<React.PropsWithChildren<Props>> = ({ id, onClick }) => (
  <IconButton id={id} style={{ padding: 0 }} onClick={onClick}>
    <FavoriteLinkContainer>
      <FavoriteLink />
    </FavoriteLinkContainer>
  </IconButton>
);
