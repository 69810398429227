import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const ReloadDialogContainer = styled(Dialog)`
  && {
    .close-button {
      background-color: #ffcc66;
      position: absolute;
      bottom: 0%;
      padding: 0px !important;
      height: 6%;
    }
  }
`;

export const ReloadContent = styled(DialogContent)`
  width: 100%;
  && {
    .help {
      position: absolute;
      width: 100%;
      height: 94vh;
      border: 0;
      background: transparent;
    }

    &:first-child {
      padding: 0;
    }
  }
`;

export const ReloadImageContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    border-radius: 20px;
  }
`;
