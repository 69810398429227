import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import * as colors from 'components/styles/colors';

interface PillProps {
  background?: string;
  inline?: boolean;
}

const Pill = styled(Typography)<PillProps>`
  && {
    width: 100%;
    padding: 3px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    background-color: ${(props) => (props.background ? props.background : colors.SUBTLE_GRAY)};
    display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  }
`;

const TimeLeftLabel = styled(Typography)`
  && {
    padding-right: 5px;
    color: ${colors.BLUISH_GRAY_LIGHT};
    text-transform: capitalize;
    display: inline;
    font-size: 12px;
  }
`;

const TimeLeft = styled(Typography)`
  && {
    font-size: 14px;
    color: ${colors.RED};
    text-transform: uppercase;
    display: inline;
  }
`;

export const S = { Pill, TimeLeft, TimeLeftLabel };
