import React from 'react';

import { SkeletonLoader } from '../../../common/skeleton-loader';

/**
 * default - color eee -> highlight f5f5f5
 * @param props
 * @returns
 */
export const DarkerSkeletonLoader = (props) => <SkeletonLoader {...props} highlightColor="#d4d4d4" />;
