export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export type UPDATE_ORDERS = typeof UPDATE_ORDERS;

export const REFRESH_HISTORY = 'REFRESH_HISTORY';
export type REFRESH_HISTORY = typeof REFRESH_HISTORY;

export const ORDER_HISTORY = 'ORDER_HISTORY';
export type ORDER_HISTORY = typeof ORDER_HISTORY;

export const GET_ORDERS_DUE_BY = 'GET_ORDERS_DUE_BY';
export type GET_ORDERS_DUE_BY = typeof GET_ORDERS_DUE_BY;

export const UPDATE_ORDER = 'UPDATE_ORDER';
export type UPDATE_ORDER = typeof UPDATE_ORDER;

export const CREATE_ORDERS = 'CREATE_ORDERS';
export type CREATE_ORDERS = typeof CREATE_ORDERS;

export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';
export type CREATE_ORDER_FAIL = typeof CREATE_ORDER_FAIL;

export const CLOSE_CART_ERROR = 'CLOSE_CART_ERROR';
export type CLOSE_CART_ERROR = typeof CLOSE_CART_ERROR;

export const RESET_LOADER = 'RESET_LOADER';
export type RESET_LOADER = typeof RESET_LOADER;

export const TOGGLE_CANCELLED_ORDER_DIALOG = 'TOGGLE_CANCELLED_ORDER_DIALOG';
export type TOGGLE_CANCELLED_ORDER_DIALOG = typeof TOGGLE_CANCELLED_ORDER_DIALOG;

export const ORDER_CREATING = 'ORDER_CREATING';
export type ORDER_CREATING = typeof ORDER_CREATING;

export const FAIL_ORDERS = 'FAIL_ORDERS';
export type FAIL_ORDERS = typeof FAIL_ORDERS;

export const OPEN_ORDER_STATUS = 'OPEN_ORDER_STATUS';
export type OPEN_ORDER_STATUS = typeof OPEN_ORDER_STATUS;

export const CLOSE_ORDER_STATUS = 'CLOSE_ORDER_STATUS';
export type CLOSE_ORDER_STATUS = typeof CLOSE_ORDER_STATUS;

export const OPEN_ORDER_RATING = 'OPEN_ORDER_RATING';
export type OPEN_ORDER_RATING = typeof OPEN_ORDER_RATING;

export const CLOSE_ORDER_RATING = 'CLOSE_ORDER_RATING';
export type CLOSE_ORDER_RATING = typeof CLOSE_ORDER_RATING;

export const SET_ORDER_RATING = 'SET_ORDER_RATING';
export type SET_ORDER_RATING = typeof SET_ORDER_RATING;

export const SET_FEEDBACK_OPTIONS = 'SET_FEEDBACK_OPTIONS';
export type SET_FEEDBACK_OPTIONS = typeof SET_FEEDBACK_OPTIONS;

export const SET_CHECKED_OPTIONS = 'SET_CHECKED_OPTIONS';
export type SET_CHECKED_OPTIONS = typeof SET_CHECKED_OPTIONS;

export const SET_FEEDBACKS = 'SET_FEEDBACKS';
export type SET_FEEDBACKS = typeof SET_FEEDBACKS;

export const SET_CURRENT_ORDER_RATING = 'SET_CURRENT_ORDER_RATING';
export type SET_CURRENT_ORDER_RATING = typeof SET_CURRENT_ORDER_RATING;

export const SET_ORDER_RATING_LOADING = 'SET_ORDER_RATING_LOADING';
export type SET_ORDER_RATING_LOADING = typeof SET_ORDER_RATING_LOADING;

export const TOGGLE_ORDER_MODAL = 'TOGGLE_ORDER_MODAL';
export type TOGGLE_ORDER_MODAL = typeof TOGGLE_ORDER_MODAL;
export const OPEN_ORDER_RATING_THANK_YOU = 'OPEN_ORDER_RATING_THANK_YOU';
export type OPEN_ORDER_RATING_THANK_YOU = typeof OPEN_ORDER_RATING_THANK_YOU;

export const CLOSE_ORDER_RATING_THANK_YOU = 'CLOSE_ORDER_RATING_THANK_YOU';
export type CLOSE_ORDER_RATING_THANK_YOU = typeof CLOSE_ORDER_RATING_THANK_YOU;

export const SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT = 'SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT';
export type SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT = typeof SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT;

export const SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT_ORDER_CONFIRMATION =
  'SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT_ORDER_CONFIRMATION';
export type SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT_ORDER_CONFIRMATION =
  typeof SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT_ORDER_CONFIRMATION;

export const SET_SP_DOCUMENT_LOADING = 'SET_SP_DOCUMENT_LOADING';
export type SET_SP_DOCUMENT_LOADING = typeof SET_SP_DOCUMENT_LOADING;

export const FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT = 'FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT';
export type FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT = typeof FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT;

export const ORDER_ITEM_QUANTITY_HISTORY_CREATE = 'ORDER_ITEM_QUANTITY_HISTORY_CREATE';
export type ORDER_ITEM_QUANTITY_HISTORY_CREATE = typeof ORDER_ITEM_QUANTITY_HISTORY_CREATE;

export enum SpDocumentType {
  PRECURSOR = 'precursor',
  REGULAR = 'regular',
}
