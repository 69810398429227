import styled from 'styled-components';

export const CartContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
`;

export const ViewCartContainer = styled.div`
  .card {
    padding: 10px;
    box-shadow: none;
    border-radius: 0px;

    .item-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-left: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .item {
        font-size: 14px;
        font-weight: 700;
      }

      .item-amount {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .grid-view {
    display: flex;
    align-items: center;
    justify-content: center;

    .cart-text {
      line-height: 0;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12pt;
    }
  }
`;
