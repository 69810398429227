import React from 'react';
import { Typography, Grid, ButtonBase } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useToggle } from 'hooks';

import { S, AccordionProps as AccordionPropsBase } from './Accordion.styles';

export interface AccordionProps extends AccordionPropsBase {
  title: string;
  content?: string | undefined;
  isDropdown?: boolean;
  isOpen?: boolean | undefined;
  advanceConfig?: {
    iconElement?: React.ReactElement | string;
    titleElement?: React.ReactElement | string;
    contentElement?: React.ReactElement | string;
  };
  onClick?: () => void;
  last?: boolean;
}

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  title,
  isDropdown = true,
  content,
  isOpen: defaultToggle,
  advanceConfig,
  onClick,
  last,
  ...props
}) => {
  const { isOpen, toggle } = useToggle(defaultToggle);
  const handleOnClick = (): void => {
    if (onClick) onClick();
    toggle();
  };

  const buttonBase = isDropdown ? (
    <Grid item xs={1} style={{ textAlign: 'end' }}>
      <ButtonBase>{isOpen ? <ExpandLess className="icon" /> : <ExpandMore className="icon" />}</ButtonBase>
    </Grid>
  ) : (
    <></>
  );

  const titleElement = advanceConfig?.titleElement || <Typography className="title">{title}</Typography>;

  const hasContent = !!advanceConfig?.contentElement || !!content;

  const contentElement = advanceConfig?.contentElement || <Typography className="content">{content}</Typography>;

  return (
    <S.Container {...props} onClick={handleOnClick} role="button">
      <Grid
        container
        alignItems="center"
        justify="space-between"
        style={{ borderBottom: last ? '0.5px solid rgba(140, 140, 140, 0.5)' : 'none' }}
      >
        <Grid item xs={11} style={{ textAlign: 'start' }}>
          {advanceConfig?.iconElement}
          {titleElement}
        </Grid>
        {hasContent ? buttonBase : <></>}
      </Grid>
      {!hasContent || (!isOpen && isDropdown) ? null : contentElement}
    </S.Container>
  );
};
