import React, { lazy } from 'react';
import MainPage from 'components/pages/MainPage';
import ActivationPage from 'components/pages/ActivationPage';
import HelpPage from 'components/pages/HelpPage';
import OrderConfirmed from 'components/pages/OrderConfirmed';
import { DirectPaymentPage } from 'components/pages/DirectPaymentPage';
import WishlistPage from 'components/pages/WishlistPage';
import { RouteComponentProps } from 'react-router-dom';
import { ChangePasswordPage } from 'components/pages/ChangePasswordPage';

const PricesPage = lazy(() => import('components/pages/PricesPage'));
const OrderPage = lazy(() => import('components/pages/OrderPage'));
const PromotedListPage = lazy(() => import('components/pages/PromotedPage/components/PromotedList'));
const HomePage = lazy(() => import('components/pages/HomePage'));
const CartPage = lazy(() => import('components/pages/CartPage'));
const OrdersPage = lazy(() => import('components/pages/orders-page'));
const AccountPage = lazy(() => import('components/pages/AccountPage'));
const AccountBenefitsPage = lazy(() => import('components/pages/AccountPage/children/AccountBenefitsPage'));
const AccountPaymentChannelsPage = lazy(() => import('components/pages/AccountPage/children/PaymentChannelsPage'));
const PaymentsPage = lazy(() => import('components/pages/PaymentsPage'));
const LoyaltyPage = lazy(() => import('components/pages/LoyaltyLandingPage'));
const LoyaltyMembershipPage = lazy(() => import('components/pages/LoyaltyMembershipPage'));
const LoyaltyHistoryPage = lazy(() => import('components/pages/LoyaltyHistoryPage'));
const LoyaltyRegistrationPage = lazy(() => import('components/pages/LoyaltyRegistrationPage'));
const LoyaltyRewardPage = lazy(() => import('components/pages/LoyaltyRewardPage'));
const LoyaltyMissionsCataloguePage = lazy(() => import('components/pages/LoyaltyMissionsCataloguePage'));
const LoyaltyMissionPage = lazy(() => import('components/pages/LoyaltyMissionPage'));
const ProductDetailPage = lazy(() => import('components/pages/ProductDetailPage'));
const BannerListPage = lazy(() => import('components/pages/BannerListPage'));
const ProductListingPage = lazy(() => import('components/pages/ProductListingPage'));

const RecentlyViewedListPage = lazy(() => import('components/pages/RecentlyViewedList'));
const CreditLimitRequestStatusPage = lazy(() => import('components/pages/CreditRequestStatusPage'));
const CreditLimitRequestPage = lazy(() => import('components/pages/CreditLimitRequestPage'));

const HowToDownloadSPDocument = lazy(() => import('components/pages/OrderPage/staticPages/howToDownloadSPDocument'));

export interface PtLocationState {
  source: string;
  base_page?: string;
}

interface Route {
  path: string;
  key: string;
  component?:
    | React.ComponentType<React.PropsWithChildren<RouteComponentProps<PtLocationState>>>
    | React.ComponentType<React.PropsWithChildren<any>>;
  routes?: Route[];
  exact?: boolean;
}

/**
 * TODO: improve
 */

const routes: Route[] = [
  {
    path: `/`,
    key: 'landing',
    routes: [
      {
        path: `/main/:tab`,
        key: 'main',
        component: MainPage,
        routes: [
          {
            path: `/main/home`,
            key: 'main_home',
            component: HomePage,
          },
          {
            path: `/main/products`,
            key: 'main_products',
            component: ProductListingPage,
            exact: true,
          },
          {
            path: `/main/cart`,
            key: 'main_cart',
            component: CartPage,
          },
          {
            path: `/main/orders`,
            key: 'main_orders',
            component: OrdersPage,
          },
          {
            path: `/main/orders/history`,
            key: 'main_orders_history',
          },
          {
            path: `/main/orders/activity`,
            key: 'main_orders_activity',
          },
          {
            path: `/main/payments`,
            key: 'main_payments',
            component: PaymentsPage,
          },
          {
            path: `/main/accounts`,
            key: 'main_accounts',
            exact: true,
            component: AccountPage,
          },
          {
            path: `/main/accounts/benefits`,
            key: 'main_accounts_benefits',
            exact: true,
            component: AccountBenefitsPage,
          },
          {
            path: `/main/accounts/payment-channels`,
            key: 'main_accounts_payments_channels',
            component: AccountPaymentChannelsPage,
          },
          {
            path: '/main/credit-limit-request/status',
            key: 'credit_limit_request_status',
            component: CreditLimitRequestStatusPage,
          },
          {
            path: '/main/credit-limit-request',
            key: 'credit_limit_request',
            component: CreditLimitRequestPage,
          },
        ],
      },
      {
        path: `/search`,
        key: 'search',
        component: ProductListingPage,
      },
      {
        path: `/activation`,
        key: 'activation',
        component: ActivationPage,
      },
      {
        path: `/help`,
        key: 'help',
        component: HelpPage,
      },
      {
        path: `/order-confirmed`,
        key: 'order_confirmed',
        component: OrderConfirmed,
      },
      {
        path: `/prices`,
        key: 'prices',
        component: PricesPage,
      },
      {
        path: `/loyalty/registration`,
        key: 'loyalty_registration',
        component: LoyaltyRegistrationPage,
      },
      {
        path: `/order/:id`,
        key: 'order',
        component: OrderPage,
      },
      {
        path: `/promoted-products`,
        key: 'promoted_products',
        component: PromotedListPage,
      },
      {
        path: `/wishlist`,
        key: 'wishlist',
        component: WishlistPage,
      },
      {
        path: `/wishlist/favorites`,
        key: 'wishlist_favorites',
        component: WishlistPage,
      },
      {
        path: `/wishlist/reminders`,
        key: 'wishlist_reminders',
        component: WishlistPage,
      },
      {
        path: `/loyalty/history`,
        key: 'loyalty_history',
        component: LoyaltyHistoryPage,
      },
      {
        path: `/loyalty/rewards`,
        key: 'loyalty_rewards',
        component: LoyaltyRewardPage,
      },
      {
        path: '/loyalty/membership',
        key: 'loyalty_membership',
        component: LoyaltyMembershipPage,
      },
      {
        path: '/loyalty/missions/catalogue',
        key: 'loyalty_missions_catalogue',
        component: LoyaltyMissionsCataloguePage,
      },
      {
        path: '/loyalty/missions/:id/info',
        key: 'loyalty_mission',
        component: LoyaltyMissionPage,
      },
      {
        path: `/loyalty`,
        key: 'loyalty',
        component: LoyaltyPage,
      },
      {
        path: `/direct-payment/:bill_code`,
        key: 'direct_payment',
        component: DirectPaymentPage,
      },
      {
        path: '/product/:id',
        key: 'product_detail',
        component: ProductDetailPage,
      },
      {
        path: '/recent-products',
        key: 'recent_products',
        component: RecentlyViewedListPage,
        exact: true,
      },
      {
        path: '/recent-products/search',
        key: 'recent_products_search',
        component: RecentlyViewedListPage,
        exact: true,
      },
      {
        path: '/marketing/:id/banner-listing',
        key: 'marketing_banners',
        component: BannerListPage,
      },
      {
        path: '/marketing/:id/product-listing',
        key: 'marketing_products',
        component: ProductListingPage,
        exact: true,
      },
      {
        path: '/marketing/:id/product-listing/search',
        key: 'marketing_products_search',
        component: ProductListingPage,
        exact: true,
      },
      {
        path: '/similar-products/:sku_code',
        key: 'similar_products',
        component: ProductListingPage,
      },
      {
        path: '/recently-purchased',
        key: 'recently_purchase',
        component: ProductListingPage,
        exact: true,
      },
      {
        path: '/recently-purchased/search',
        key: 'recently_purchase_search',
        component: ProductListingPage,
        exact: true,
      },
    ],
  },
  {
    path: `/`,
    key: 'public',
    routes: [
      {
        path: '/password-confirmation',
        key: 'password_confirmation',
        component: ChangePasswordPage,
      },
      {
        path: '/how-to-download-sp-document',
        key: 'sp_document',
        component: HowToDownloadSPDocument,
      },
    ],
  },
];

export default routes;
