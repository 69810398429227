import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

import * as colors from 'components/styles/colors';

const Container = styled(Grid)`
  padding: 10px;
  background: rgba(235, 237, 252, 0.69);
`;

const Title = styled(Typography)`
  && {
    padding-left: 10px;
    color: ${colors.BLUE};
    font-size: 16px;
    font-weight: 700;
  }
`;

const Info = styled(Typography)`
  && {
    padding-left: 8px;
    color: ${colors.BLUE};
    font-size: 14px;
  }
`;

const Point = styled(Typography)`
  && {
    color: ${colors.BLUE};
    font-size: 14px;
    font-weight: 700;
  }
`;

export const S = {
  Container,
  Title,
  Info,
  Point,
};
