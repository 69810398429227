export enum MarketingFeaturesType {
  GENERAL = 'general-search-suggestion',
  CART = 'cart-suggestion',
  TRACK_ORDER = 'track-order-suggestion',
  ORDER_CONFIRMATION = 'order-confirmation-suggestion',
  FAVORITES = 'favorites-suggestion',
  REMINDERS = 'reminders-suggestion',
}

export enum MarketingFeaturesDisplayType {
  CAROUSEL = 'carousel',
  LIST = 'list',
}

export enum MarketingFeaturesDisplayPosition {
  AFTER_LISTING = 'after_listing',
  BETWEEN_LISTING = 'between_listing',
}
