import React from 'react';
import { animated } from 'react-spring';

import { useFade } from 'hooks/useFade';

import { S } from './orders-wrapper.styles';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const AnimatedWrapper = animated(S.Wrapper);

export const OrdersWrapper: React.FC<React.PropsWithChildren<Props>> = ({ children, className }) => {
  const { fade } = useFade();

  return (
    <AnimatedWrapper style={fade} className={className}>
      {children}
    </AnimatedWrapper>
  );
};
