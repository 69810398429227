export const ACCOUNT_HELP_DEFAULT_HOMEPAGE = 'ACCOUNT_HELP_DEFAULT_HOMEPAGE';
export type ACCOUNT_HELP_DEFAULT_HOMEPAGE = typeof ACCOUNT_HELP_DEFAULT_HOMEPAGE;

export const FAIL_ACCOUNT_HELP_DEFAULT_HOMEPAGE = 'FAIL_ACCOUNT_HELP_DEFAULT_HOMEPAGE';
export type FAIL_ACCOUNT_HELP_DEFAULT_HOMEPAGE = typeof FAIL_ACCOUNT_HELP_DEFAULT_HOMEPAGE;

export const GET_HELP_DEFAULT_HOMEPAGE = 'GET_HELP_DEFAULT_HOMEPAGE';
export type GET_HELP_DEFAULT_HOMEPAGE = typeof GET_HELP_DEFAULT_HOMEPAGE;

export const FAIL_GET_HELP_DEFAULT_HOMEPAGE = 'FAIL_GET_HELP_DEFAULT_HOMEPAGE';
export type FAIL_GET_HELP_DEFAULT_HOMEPAGE = typeof FAIL_GET_HELP_DEFAULT_HOMEPAGE;

export interface VirtualAccount {
  id: number;
  is_default: boolean;
  bank: string;
  number: string;
  type: VAPaymentType;
  payment_provider_id: number;
}

export enum VAPaymentType {
  OPEN = 'open',
  CLOSE = 'close',
}

export enum PaymentProviders {
  DOKU = 'doku',
  XENDIT = 'xendit',
}

export enum PaymentProvidersID {
  DOKU = 1,
  XENDIT = 2,
}

export enum BankNames {
  BCA = 'Bank Central Asia (BCA)',
  CIMB = 'Commerce International Merchant Bankers (CIMB)',
  DANAMON = 'Bank Danamon',
  MANDIRI = 'Bank Mandiri',
  MAYBANK = 'Bank Maybank',
  PERMATA = 'Permata Bank',
  BRI = 'Bank Rakyat Indonesia',
}

export interface Memberships {
  membership_statuses: string[];
  license_expiration?: string;
  sia_expiration?: string;
  sia_show_contact_us?: boolean;
  sipa_expiration?: string;
  sipa_show_contact_us?: boolean;
  sipnap?: boolean;
  sipnap_show_contact_us?: boolean;
}
