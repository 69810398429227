import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Typography, Grid } from '@material-ui/core';
import { Done } from '@material-ui/icons';

import { TranslateProps } from 'utils/Localization/types';

import { Tour } from 'components/common/Tour';
import { getProductCardTours } from 'utils/tours';
import { SnackbarCartComponent } from 'components/common/snackbar-cart';
import { ISnackbarCart } from 'store/reducers/Utils/actions';
import ShowIf from 'components/views/ShowIf';
import { ProductListingWidget } from 'components/common/product-list/interfaces/generic-product-list.interface';
import { AfterListingWidgets, EndOfResultDivider, withFeaturedMarketingWidget } from 'components/common';
import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';
import { GetMarketingFeaturesParams } from 'store/reducers/MarketingFeatures/actions';
import { ProductCardLoader } from '../../common/ProductCard/components/product-card-loader/ProductCardLoader';
import { State as FavoriteState } from '../../../store/reducers/FavoriteProduct';
import { State as TourState } from '../../../store/reducers/Tour';
import { BinocularComponent } from '../../common/Binocular/component';
import { ProductCard } from '../../common/ProductCard';
import Content from '../../views/Content';

const PageEnd = styled(Typography)`
  color: #7c7c7c;
  text-align: center;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  svg {
    color: #44a340;
    font-size: 40px;
    margin: 0 auto 4px;
    display: block;
  }
`;

const Container = styled(Grid)`
  height: calc(100% - 9rem);
  flex-direction: column;
  position: absolute;
`;

export interface StateProps {
  favorites: FavoriteState;
  loading: boolean;
  hasMore: boolean;
  tours: TourState;
  snackbarCart: ISnackbarCart;
}

export interface DispatchProps {
  fetchFavoriteProducts: (params: any) => void;
  removeFromFavoriteProduct: (favorite_product_id: number) => void;
  clearFavoriteProducts: () => void;
  clearDelayedFavoriteRequest: () => void;
  setCloseSnackbarCart: () => void;
  getMarketingFeatures: (payload: GetMarketingFeaturesParams) => void;
}

export interface OwnProps extends RouteComponentProps<any> {
  widgets?: ProductListingWidget[];
  showCondition?: boolean;
}

class FavoritesProductPage extends React.Component<StateProps & OwnProps & DispatchProps & TranslateProps> {
  state = {
    limit: 20,
  };

  private infiniteScrollElementReference = React.createRef<InfiniteScroll>();

  componentDidMount(): void {
    const { fetchFavoriteProducts, clearDelayedFavoriteRequest, setCloseSnackbarCart, getMarketingFeatures } =
      this.props;
    clearDelayedFavoriteRequest();
    getMarketingFeatures({ featureType: MarketingFeaturesType.FAVORITES });
    const { limit } = this.state;
    fetchFavoriteProducts({
      limit,
      page: 1,
    });
    setCloseSnackbarCart();
  }

  componentWillUnmount(): void {
    const { clearFavoriteProducts } = this.props;
    clearFavoriteProducts();
  }

  fetchMoreData = async (): Promise<void> => {
    const { favorites, fetchFavoriteProducts } = this.props;
    const { limit } = this.state;
    fetchFavoriteProducts({
      limit,
      page: favorites.nextPage,
    });
  };

  endMessageComponent = (collectionLength: number): JSX.Element | undefined => {
    if (collectionLength <= 0) return undefined;

    const { t } = this.props;
    return (
      <PageEnd>
        <Done />
        {t('pageEnd')}
      </PageEnd>
    );
  };

  render(): JSX.Element {
    const { t, favorites, tours, snackbarCart, widgets, showCondition } = this.props;

    const isTourOver = !tours?.loading && tours?.tours?.includes('wishlist_page');

    // Shown when finished loading and have data
    const favoriteListComp = favorites?.products.map((entry, i) => (
      <ProductCard product={entry} key={entry.id} tourIds={getProductCardTours(i)} />
    ));

    // Shown when finished loading and don't have data
    const binocularComp = (
      <div style={{ marginBottom: '1rem' }}>
        <BinocularComponent t={t} showLabel />
      </div>
    );

    // Shown when still loading with 5 dummy records
    const productShimmerComp = new Array(5).fill({ loading: true }).map((item, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ProductCardLoader key={i} />
    ));

    const widgetsLength = widgets?.length || 0;

    const noData = favorites.products.length <= 0;
    const contentComp =
      !favorites.loading && noData
        ? binocularComp
        : !favorites.loading && !noData
        ? favoriteListComp
        : productShimmerComp;

    const collectionLength = favorites.products.length + widgetsLength;

    return (
      <>
        <Container container direction="column">
          <Content>
            <InfiniteScroll
              height="auto"
              style={{
                top: 8,
                left: 0,
                right: 0,
                bottom: 0,
                position: 'absolute',
                overflow: isTourOver ? 'scroll' : 'fit-content',
              }}
              loader={<Typography align="center">Loading...</Typography>}
              dataLength={favorites.products.length}
              hasMore={favorites.hasMore}
              next={this.fetchMoreData}
              ref={this.infiniteScrollElementReference}
              endMessage={this.endMessageComponent(collectionLength)}
            >
              {contentComp}
              <EndOfResultDivider end={!!showCondition && widgetsLength > 0} />
              <AfterListingWidgets widgets={widgets} />
            </InfiniteScroll>
          </Content>
          <ShowIf condition={snackbarCart.open}>
            <SnackbarCartComponent
              open={!!snackbarCart.open}
              productId={snackbarCart.productId}
              itemCount={snackbarCart.itemCount}
              purchaseableQuantity={snackbarCart.maxPurchaseLimit.purchaseableQuantity}
              hasPurchased={snackbarCart.maxPurchaseLimit.hasPurchased}
              style={{ bottom: '0px' }}
            />
          </ShowIf>
        </Container>

        <Tour
          module="wishlist_page"
          welcomeTitle="tour.wishlistPage.welcomeTitle"
          welcomeMessage="tour.wishlistPage.welcomeMessage"
          styles={{
            options: {
              zIndex: 99999,
            },
          }}
        />
      </>
    );
  }
}

const FavoritesPageWithWidget = withFeaturedMarketingWidget(FavoritesProductPage);

const FavoritesPage: React.FC<StateProps & OwnProps & DispatchProps & TranslateProps> = (props) => {
  const { favorites } = props;
  const showCondition =
    favorites.initialProductLength !== null && favorites.initialProductLength <= 10 && !favorites.loading;

  return (
    <FavoritesPageWithWidget
      featureType={MarketingFeaturesType.FAVORITES}
      withMarketingFeatureWidget
      showCondition={showCondition}
      {...props}
    />
  );
};

export default FavoritesPage;
