import axios, { AxiosInstance, CancelTokenSource } from 'axios';

const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

const client: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROFILER_BASE_URL,
  timeout: 3000 * 10,
  headers: { Authorization: `Bearer ${process.env.REACT_APP_PROFILER_API_KEY}` },
});

client.interceptors.request.use((config) => ({ ...config, cancelTokenSource: cancelTokenSource.token }));

export { client as searchProfilerClient };
