export enum OrderLogisticsDeliveryStatus {
  PENDING = 'pending',
  ONGOING = 'ongoing',
  DELIVERED = 'delivered',
  DELAYED = 'delayed',
  FAILED = 'failed',
  CANCELED = 'canceled',
  INVALID_TRACKING_CODE = 'invalid-tracking-code',
}

export enum OrderLogisticsDeliveryStatusDetail {
  PHARMACY_CLOSED = 'pharmacy_closed',
  FORCE_MAJOR = 'force_major',
  CHANGE_DELIVERY_PARTNER = 'change_delivery_partner',
  UPDATE_TRACKING_NUMBER = 'update_tracking_number',
}

export interface ILogisticsDeliveryStatus {
  status: OrderLogisticsDeliveryStatus;
  status_detail?: OrderLogisticsDeliveryStatusDetail;
  status_updated_at: string;
}
