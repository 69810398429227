import React from 'react';

import { S } from './Divider.styles';

interface Props {
  border?: string;
  color?: string;
  marginY?: number;
}

export const Divider: React.FC<React.PropsWithChildren<Props>> = ({ border, color, marginY }) => (
  <S.Divider border={border} color={color} marginY={marginY} />
);

Divider.defaultProps = {
  border: 'solid',
  color: '#bcbcbc',
};
