import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import * as colors from 'components/styles/colors';

const DateTime = styled(Typography)`
  && {
    padding-right: 5px;
    font-size: 12px;
    font-weight: 400;
    color: ${colors.BLUISH_GRAY_LIGHT};
    text-transform: capitalize;
    display: inline;
  }
`;

export const S = { DateTime };
