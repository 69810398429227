import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  snackbarOnHome: {
    marginBottom: '60px',
  },
  snackbarOnListings: {
    marginBottom: '130px',
  },
  snackbarOnProductDetail: {
    marginBottom: '90px',
  },
}));

export const getSnackbarStyle = (currentPage, classes) => {
  switch (currentPage) {
    case 'pt_view_home':
      return classes.snackbarOnHome;
    case 'pt_view_products':
      return classes.snackbarOnListings;
    case 'pt_view_product_detail_page':
    case 'pt_view_search':
      return classes.snackbarOnProductDetail;
    default:
      return undefined;
  }
};

export default useStyles;
