import { Deposit } from 'store/reducers/Payment';
import { SwipeRxPt } from '../../SwipeRxPt.class';
import { InvoiceAllocationList } from './interfaces/invoice-allocation-list.interface';
import { InvoiceAllocationListParam } from './interfaces/invoice-allocation-list-param.interface';
import { IInvoiceAllocation } from './interfaces/invoice-allocation.interface';

export class SwipeRxPtInvoiceAllocation {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async list(params?: InvoiceAllocationListParam): Promise<InvoiceAllocationList> {
    const response = await this.base.getV2('/invoice-allocation', params);

    return response;
  }

  async getByInvoice(invoiceId: number): Promise<IInvoiceAllocation & { deposit: Deposit }[]> {
    const { data } = await this.base.getV2(`/invoice-allocation/invoice/${invoiceId}`);

    return data;
  }
}
