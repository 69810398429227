import * as Redux from 'redux';
import * as constants from './constants';
import { Actions } from './actions';
import { CartPackage, DistributorCarts, OrderSummary } from '../Cart';

export interface PriceRequest {
  [id: string]: {
    quantity: number;
  };
}

export type CartPricePackage = PricePackage & CartPackage;

export interface CartPricePackages {
  [id: string]: CartPricePackage;
}

export interface CartPrices {
  [product: string]: CartPricePackages;
}

export interface PricePackage {
  distributor: {
    id: number;
    name: string;
  };
  package: string;
  selling_price: number;
  discount_rate: number;
  net_price: number;
}

export interface PricePackages {
  [id: string]: PricePackage;
}

export interface Prices {
  [product: string]: PricePackages;
}

export enum OrganizationSettingType {
  GENERIC = 'generic',
  DPD = 'dpd',
}
export interface Settings {
  id: number;
  name: string;
  organization_id: number;
  value: string;
  type: OrganizationSettingType;
}

export interface Cart {
  distributors: DistributorCarts[];
  order_summary: OrderSummary[];
}

export interface State {
  items: Prices;
  error?: string;
  settings: Settings[];
}

const InitialState: State = {
  items: {},
  settings: [],
};

const PricesReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.UPDATE_PRICES:
      return {
        ...state,
        items: action.items,
        settings: action.settings ? action.settings : [],
      };
    case constants.FAIL_PRICES:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default PricesReducer;
