import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface IMenuStyleProps {
  isInputFocus?: boolean;
  visualViewportHeight?: number;
}

const NoProductContainer = styled(Typography)`
  && {
    font-size: 10pt;
    color: #00000054;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-top: 16px;
  }
`;

const Menu = styled.div<IMenuStyleProps>`
  background-color: white;
  min-height: ${(props) => (props.isInputFocus ? '0' : '26.5rem')};
  overflow: hidden auto;
  border-width: 1px 1px 1px;
  outline: 0px;
  border-radius: 0.285714rem 0.285714rem;
  transition: opacity 0.1s ease 0s;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 2px 3px 0px;
  border-color: rgb(150, 200, 218);
  border-style: solid;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  margin-top: 58px;
  z-index: 20;
  padding-bottom: ${(props) => (props.isInputFocus ? `calc(100vh - ${props.visualViewportHeight}px)` : '16px')};
`;

export const S = {
  NoProductContainer,
  Menu,
};
