import React from 'react';
import moment from 'moment-timezone';
import { S } from './date-card.styles';

export interface Props {
  title: string;
  date: Date | null;
  onClick?: (date) => void;
}

export const DateCard: React.FC<React.PropsWithChildren<Props>> = ({ title, date, onClick: handleClick }) => {
  const hasValue = date !== null;

  return (
    <S.Container onClick={handleClick}>
      <S.Title>{title}</S.Title>
      <S.DateField active={hasValue}>{hasValue ? moment(date).format('DD MMM YYYY') : '-------'}</S.DateField>
    </S.Container>
  );
};
