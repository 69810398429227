import { OrderFiltersActions, OrderFiltersEnum } from './order-filters.actions';
import { OrderFilters } from '../orders-filter.interface';

export const orderFiltersReducer = (state: OrderFilters, action: OrderFiltersActions): OrderFilters => {
  switch (action.type) {
    case OrderFiltersEnum.ADD_DISTRIBUTOR_FILTER:
      return {
        ...state,
        distributors: state.distributors.concat(action.payload.distributorId),
      };

    case OrderFiltersEnum.REMOVE_DISTRIBUTOR_FILTER:
      return {
        ...state,
        distributors: state.distributors.filter((distributor) => distributor !== action.payload.distributorId),
      };

    case OrderFiltersEnum.ADD_DOCUMENT_FILTER:
      return {
        ...state,
        documents: state.documents.concat(action.payload.documentId),
      };

    case OrderFiltersEnum.REMOVE_DOCUMENT_FILTER:
      return {
        ...state,
        documents: state.documents.filter((document) => document !== action.payload.documentId),
      };

    case OrderFiltersEnum.SET_PREVIOUS_FILTER:
      return {
        distributors: action.payload.prevFilters.distributors,
        documents: action.payload.prevFilters.documents,
      };

    case OrderFiltersEnum.RESET_FILTER:
      return {
        distributors: [],
        documents: [],
      };

    default:
      return state;
  }
};
