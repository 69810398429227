import { useReducer, useState } from 'react';

import { OrderFilters } from '../orders-filter.interface';
import { orderFiltersReducer } from './order-filters.reducer';
import {
  addDistributorFilter,
  addDocumentFilter,
  removeDistributorFilter,
  removeDocumentFilter,
  resetFilter,
  setPrevFilters,
} from './order-filters.actions';

const uiFilterState: OrderFilters = {
  distributors: [],
  documents: [],
};

export interface UseOrderFilters {
  addDistributorFilterHandler: (id: number) => void;
  addDocumentFilterHandler: (id: number) => void;
  removeDistributorFilterHandler: (id: number) => void;
  removeDocumentFilterHandler: (id: number) => void;
  resetFilterHandler: () => void;
  onSaveHandler: () => void;
  onCancelHandler: () => void;
  filters: OrderFilters;
  uiFilters: OrderFilters;
}

export const useOrderFilters = (): UseOrderFilters => {
  const [filters, setFilters] = useState<OrderFilters>({
    distributors: [],
    documents: [],
  });
  const [uiFilters, dispatch] = useReducer(orderFiltersReducer, uiFilterState);

  const addDistributorFilterHandler = (id: number): void => {
    dispatch(addDistributorFilter(id));
  };

  const removeDistributorFilterHandler = (id: number): void => {
    dispatch(removeDistributorFilter(id));
  };

  const addDocumentFilterHandler = (id: number): void => {
    dispatch(addDocumentFilter(id));
  };

  const removeDocumentFilterHandler = (id: number): void => {
    dispatch(removeDocumentFilter(id));
  };

  const resetFilterHandler = (): void => {
    dispatch(resetFilter());
  };

  const onSaveHandler = (): void => {
    setFilters(uiFilters);
  };

  const onCancelHandler = (): void => {
    dispatch(setPrevFilters(filters));
  };

  return {
    addDistributorFilterHandler,
    addDocumentFilterHandler,
    removeDistributorFilterHandler,
    removeDocumentFilterHandler,
    resetFilterHandler,
    onSaveHandler,
    onCancelHandler,
    filters,
    uiFilters,
  };
};
