import React from 'react';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { ReactComponent as ProductCoinIcon } from 'images/ic_coin.svg';
import { ReactComponent as ProductCoinRewardIcon } from 'images/ic_coin_reward.svg';
import { toCurrency } from 'utils/Format';
import { TFunction } from 'utils/Localization';

import { S } from './LoyaltyPointReward.styles';

export interface LoyaltyPointRewardComponentProps {
  points?: number;
  rewards?: string[];
  t: TFunction;
}

const LoyaltyPointRewardComponent: React.FC<React.PropsWithChildren<LoyaltyPointRewardComponentProps>> = ({ points = 0, rewards = [], t }) => {
  if (!points) return null;

  const hasBonusRewards = rewards.length > 0;
  return (
    <S.ProductPointContainer className={hasBonusRewards ? 'potential-has-rewards' : ''}>
      <Grid item>
        <Grid container alignItems="flex-end">
          <Grid item>
            <S.ProductPointEarnText className="potential-points">
              {t('orderEarnPoint')} {toCurrency(points)}
            </S.ProductPointEarnText>
          </Grid>
          <Grid item style={{ height: '16px', marginLeft: '3px' }}>
            {hasBonusRewards ? (
              <ProductCoinRewardIcon width={16} height={16} />
            ) : (
              <ProductCoinIcon width={16} height={16} />
            )}
          </Grid>
        </Grid>
      </Grid>
      {rewards.map((reward) => (
        <Grid item>
          <S.ProductPointEarnText>{reward}</S.ProductPointEarnText>
        </Grid>
      ))}
    </S.ProductPointContainer>
  );
};

export const LoyaltyPointReward = translate('loyalty')(LoyaltyPointRewardComponent);
