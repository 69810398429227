import { swipeRxPt, PaymentDirectParams, PaginationParams, ListMeta } from 'services';
import { State } from 'store';

import { recordException } from 'utils/Reporting/Sentry';

import { PaymentDirectActionType } from './action.type';

export const resetPaymentDirectList = () => ({
  type: PaymentDirectActionType.RESET_PAYMENT_DIRECT_LIST,
});

export const setPaymentDirectLoading = (loading: boolean) => ({
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_LOADING,
  payload: { loading },
});

export const setPaymentDirectList = (data: any[], meta: ListMeta) => ({
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_LIST,
  payload: { data, meta },
});

export const setPaymentDirecDetail = (data: any) => ({
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_DETAIL,
  payload: { data },
});

export const resetPaymentDirectDetail = () => ({
  type: PaymentDirectActionType.RESET_PAYMENT_DIRECT_DETAIL,
});

export const setPaymentDirectError = (error: string) => ({
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_ERROR,
  payload: { error },
});

export const fetchPaymentDirectList =
  (id: number, params: PaginationParams & PaymentDirectParams = {}) =>
  async (dispatch) => {
    const { page, ...options } = params;

    try {
      if (!page || page === 1) {
        dispatch(resetPaymentDirectList());
      }

      dispatch(setPaymentDirectLoading(true));
      const { data, meta } = await swipeRxPt.paymentDirect.list({
        pharmacy_id: id,
        page: page || 1,
        expand: ['purchase_order', 'voucher_usage'],
        ...options,
      });

      dispatch(setPaymentDirectList(data, meta));
    } catch (error) {
      recordException(error, 'fetchPaymentDirectList', { id, params });
      dispatch(setPaymentDirectError(error.message));
    } finally {
      dispatch(setPaymentDirectLoading(false));
    }
  };

export const fetchPaymentDirectDetails = (id) => async (dispatch, getState) => {
  try {
    const {
      paymentDirect: {
        payments: { data },
      },
    } = getState() as State;

    let payment = data.find((payment) => payment.id === id);

    if (!payment) {
      dispatch(setPaymentDirectLoading(true));
      payment = await swipeRxPt.paymentDirect.retrieve(id);

      if (!payment) throw new Error('Order not found.');
    }

    dispatch(setPaymentDirecDetail(payment));
  } catch (error) {
    recordException(error, 'fetchPaymentDirectDetails', { id });
    dispatch(setPaymentDirectError(error.message));
  }
};
