import React, { useState, useEffect, useCallback } from 'react';

import { Grid } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import { translate } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Modal } from 'components/common';
import { useGetCurrentUser } from 'hooks';
import { Order, OrderStatus } from 'services';
import { State as StoreState } from 'store';
import { trackContactCustomerCare } from 'utils/Analytics/Segment';
import { closeOrderStatus } from 'store/reducers/Orders/actions';
import ShowIf from 'components/views/ShowIf';

import { ORDER_CONFIRMED_INDEX } from '../../constants';
import { OrdersModalSubTitle } from '../orders-modal/orders-modal-subtitle.component';
import { OrdersRatingEntrypoint } from '../orders-rating';

import { OrderStatusModalHistory } from './orders-status-modal-history.component';
import { OrderStatusModalNote } from './orders-status-modal-note.component';
import { OrderStatusModalSequence } from './orders-status-modal-sequence.component';
import { S } from './orders-status-modal.styles';

interface Props {
  t: any;
}

const OrderStatusModalComponent: React.FC<React.PropsWithChildren<Props>> = ({ t }) => {
  const dispatch = useDispatch();
  const {
    purchaseOrder: { orderDetail },
    config: { market },
    orders: {
      orderStatus: { isOpen },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);
  const {
    coreUser: { organization_id: pharmacyId },
  } = useGetCurrentUser();
  const location = useLocation();
  const [shouldShowRating, setShouldShowRating] = useState(false);

  const { logistics_delivery_status: deliveryStatus } = orderDetail as Order;

  const openChat = useCallback(() => {
    trackContactCustomerCare(pharmacyId);
    window.location.href = market.customerSuccessSupport.link;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacyId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClose = useCallback(() => dispatch(closeOrderStatus()), []);

  useEffect(() => {
    const isOrderConfirmed = location.pathname.split('/')[ORDER_CONFIRMED_INDEX] === 'order-confirmed';

    if (!isOrderConfirmed) setShouldShowRating(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <S.Wrapper>
        <S.Header>
          <Grid container alignItems="center" justifyContent="center">
            <S.Title>{t('detailStatus')}</S.Title>
          </Grid>
          <S.CancelBtn onClick={onClose} data-testid="close-btn" />
        </S.Header>
        <OrderStatusModalSequence order={orderDetail} delivery={deliveryStatus} t={t} />
        <OrderStatusModalNote order={orderDetail} delivery={deliveryStatus} t={t} />
        <OrdersModalSubTitle text={t('orderStatusTitle')} style={{ paddingLeft: '10px' }} />
        <OrderStatusModalHistory openChat={openChat} closeModal={onClose} />
        <ShowIf
          condition={shouldShowRating && [OrderStatus.FULFILLED, OrderStatus.COMPLETED].includes(orderDetail.status)}
        >
          <OrdersRatingEntrypoint />
        </ShowIf>
        <S.NeedAssistanceContainer>
          <S.NeedAssistanceText>{t('needAssistanceForReturn')}</S.NeedAssistanceText>
          <S.NeedAssistanceBtn onClick={openChat} data-testid="need-assistance-btn">
            <MessageIcon /> {t('needAssistance')}
          </S.NeedAssistanceBtn>
        </S.NeedAssistanceContainer>
      </S.Wrapper>
    </Modal>
  );
};

export const OrderStatusModal = translate('orders')(OrderStatusModalComponent);
