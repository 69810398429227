import React from 'react';

import { translate } from 'react-i18next';
import i18n from 'utils/Localization';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { TranslateProps } from 'utils/Localization/types';

import { MaintenanceActions, useMaintenaceState, useMaintenanceDispatch } from './context';
import { GRAY31, WHITE, PRIMARY_COLOR, BLACK } from '../../styles/colors';
import MaintenanceImage from '../../../images/maintenance.svg';
import ReloadDialog from './ReloadDialog';

type MaintenancePageProps = TranslateProps;

const MaintenancePageBase: React.FC<React.PropsWithChildren<MaintenancePageProps>> = (props) => {
  const { t } = props;
  const maintenanceDispatch = useMaintenanceDispatch();

  const { config, askReopen } = useMaintenaceState();
  const message = config?.message[i18n.language];

  return (
    <Container style={{ height: '100vh', width: '100vw', background: WHITE }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <img src={MaintenanceImage} alt="page is under maintenance" width={250} height={250} />

        <Typography
          variant="h5"
          align="center"
          style={{ marginTop: 10, marginBottom: 30, color: BLACK, fontSize: 20, fontWeight: 700 }}
        >
          {message.title || t('title')}
        </Typography>

        <Typography variant="subtitle1" align="center" style={{ color: GRAY31, fontSize: 18 }}>
          {message.description || t('subtitle')}
        </Typography>

        <Button
          variant="outlined"
          onClick={(): void => {
            window.location.href = 'swrx://exit';
          }}
          style={{
            marginTop: 40,
            marginBottom: 40,
            padding: 5,
            background: PRIMARY_COLOR,
            color: WHITE,
            borderRadius: 20,
            borderColor: PRIMARY_COLOR,
            minWidth: 180,
            minHeight: 42,
            fontWeight: 'bold',
          }}
        >
          {t('back')}
        </Button>
      </Grid>

      <ReloadDialog
        open={askReopen}
        close={(): void => {
          maintenanceDispatch({ type: MaintenanceActions.ASK_REOPEN, askReopen: false });
          window.location.href = 'swrx://exit';
        }}
      />
    </Container>
  );
};

export default translate('maintenancePage')(MaintenancePageBase);
