import { useSelector } from 'react-redux';
import { getEventPageIdentity, trackApplyFilter, trackViewFilter } from 'utils/Analytics/Segment';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { State } from 'store';
import { useMarketing } from './use-marketing';

interface IHook {
  trackViewFilterSort: () => void;
  trackApplyFilterSort: (selectedFilter?: string[], selectedSort?: string) => void;
}

export const useFilterSortAnalytics = (): IHook => {
  const match = useRouteMatch();
  const location = useLocation();
  const { marketing } = useMarketing();
  const pharmacy_id = useSelector((state: State) => state.auth.coreUser?.organization_id) || 0;
  const eventPageIdentity = getEventPageIdentity(match, location.search);

  const defaultPayload = {
    pharmacy_id,
    from_page: eventPageIdentity,
    marketing_name: marketing?.name,
  };

  const trackViewFilterSort = (): void => {
    trackViewFilter(defaultPayload);
  };

  const trackApplyFilterSort = (selected_filter?: string[], selected_sort?: string): void => {
    trackApplyFilter({
      ...defaultPayload,
      ...(selected_filter?.length ? { selected_filter } : {}),
      ...(selected_sort ? { selected_sort } : {}),
    });
  };

  return {
    trackViewFilterSort,
    trackApplyFilterSort,
  };
};
