import React from 'react';

import { translate } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PharmacyOrderStatus } from 'services';
import { routeKey } from 'utils/route.utils';
import { S } from './PaidNotification.styles';

interface OwnProps {
  billReference?: string;
  isFullyPaid: boolean;
}

type Props = { t: any } & OwnProps;

const PaidNotificationComponent: React.FC<React.PropsWithChildren<Props>> = ({ billReference, isFullyPaid, t }) => {
  const history = useHistory();

  const handleClick = (): void => {
    history.push(`${routeKey('main_orders_history')}/${PharmacyOrderStatus.PAID}`);
  };

  const messageKey = isFullyPaid ? 'paymentFullNotification' : 'paidBillNotification';

  const PaymentSuccess = (): JSX.Element => (
    <S.Wrapper>
      <S.Title>
        <S.Icon fontSize="small" /> {t(`${messageKey}.title`)}
      </S.Title>
      <S.Content>{t(`${messageKey}.content`, { billReference })}</S.Content>
      <S.Action variant="button" onClick={handleClick}>
        {t(`${messageKey}.action`)}
      </S.Action>
    </S.Wrapper>
  );

  return (
    <S.Container>
      <PaymentSuccess />
    </S.Container>
  );
};

export const PaidNotification = translate('directPayment')(PaidNotificationComponent);
