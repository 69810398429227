/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from 'reselect';
import { State as StoreState } from 'store';
import { BasePageTracker, ImpressionType } from 'utils/Analytics/Segment';
import includes from 'lodash/includes';

import { State as ProductImpressionState } from '.';

const selectorProductImpression = (state: StoreState): ProductImpressionState => state.productImpression;

export const isProductImpressionSelector = (
  displayType: ImpressionType,
  productId: number,
  marketingId?: number,
  page?: BasePageTracker,
) =>
  createSelector(selectorProductImpression, (state) => {
    if (!displayType) return true;
    let currentState = state[displayType];

    if (displayType === ImpressionType.CAROUSEL && marketingId) {
      if (page === BasePageTracker.PRODUCT_DETAIL) {
        currentState = state.productDetailCarousel?.[marketingId];
      } else {
        currentState = currentState?.[marketingId] || [];
      }
    }

    const isImpression = includes(currentState as number[], productId);

    return isImpression;
  });
