import { useGetCurrentUser } from 'hooks';
import React, { MouseEventHandler } from 'react';
import { translate } from 'react-i18next';

import { store } from 'store';
import { trackContactCustomerCare } from 'utils/Analytics/Segment';
import { getCallCenterNumber, getCustomerSuccessSupport, getWhatsappSupportLink } from 'utils/MarketConfig';
import { TranslateProps } from 'utils/Localization/types';

import { S } from './HelpAlert.styles';

interface Props {
  style?: React.CSSProperties;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const HelpAlertBase: React.FC<React.PropsWithChildren<TranslateProps & Props>> = ({ t, style }) => {
  const { config } = store.getState();
  const marketId = config?.marketId || 'id';
  const language = config?.market.language || 'en';
  const callCenterNumber = getCallCenterNumber();
  const {
    coreUser: { organization_id: pharmacyId },
  } = useGetCurrentUser();

  const messages = {
    marketId: {
      id: {
        language: {
          en: {
            title: 'Please contact our Customer Success if you have any other questions/concerns:',
            content1: 'WA Business',
            subcontent1: 'Chat on WhatsApp with SwipeRx Shopping',
            content2: `Phone : ${callCenterNumber}`,
            content3: 'Monday - Friday / 08.00 - 21.00',
            content4: 'Saturday - Sunday / 08.00-17.00',
            content5: 'National Holidays / 08.00-17.00',
          },
          id: {
            title:
              'Silahkan hubungi Customer Success kami jika ada pertanyaan/kendala lainnya melalui beberapa cara dibawah ini :',
            content1: 'WA Bisnis :',
            subcontent1: 'Chat on WhatsApp with SwipeRx Belanja',
            content2: `Phone : ${callCenterNumber}`,
            content3: 'Senin - Jumat / 08.00 - 21.00',
            content4: 'Sabtu - Minggu / 08.00-17.00',
            content5: 'Libur Nasional / 08.00-17.00',
          },
        },
      },
      th: {
        language: {
          en: {
            title: 'Need help ?',
            content1: 'Please contact customer service at',
            content2: 'Line: @swiperx_th or',
            subcontent2: 'click the link',
            content3: 'Tel: +66-60-003-5146',
            content4: 'Mon-Fri 9.00-18.00',
          },
          th: {
            title: 'ต้องการความช่วยเหลือ ?',
            content1: 'กรุณาติดต่อฝ่ายบริการลูกค้าที่',
            content2: 'Line: @swiperx_th หรือกดลิงค์',
            subcontent2: 'SwipeRx | LINE Official Account',
            content3: 'โทร: +66-60-003-5146',
            content4: 'จันทร์-ศุกร์ 9.00-18.00น.',
          },
        },
      },
    },
  };

  const customerSuccessSupport = getCustomerSuccessSupport();
  const whatsappSupportLink = getWhatsappSupportLink(t(messages.marketId[marketId].language[language].subcontent1));

  const onClickSupport: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    trackContactCustomerCare(pharmacyId);

    if (marketId === 'id') {
      window.location.href = whatsappSupportLink || customerSuccessSupport.link;
    } else {
      window.location.href = 'https://page.line.me/373xaoez?openQrModal=true';
    }
  };

  return (
    <S.Wrapper style={style}>
      <S.Message>
        {marketId === 'id' ? (
          <>
            <div>{messages.marketId[marketId].language[language].title}</div>
            <div>
              <ul>
                <li>
                  {messages.marketId[marketId].language[language].content1}{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" onClick={onClickSupport}>
                    {messages.marketId[marketId].language[language].subcontent1}
                  </a>
                </li>
                <li>{messages.marketId[marketId].language[language].content2}</li>
                <li>{messages.marketId[marketId].language[language].content3}</li>
                <li>{messages.marketId[marketId].language[language].content4}</li>
                <li>{messages.marketId[marketId].language[language].content5}</li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div>{messages.marketId[marketId].language[language].title}</div>
            <div>
              <ul>
                <li>{messages.marketId[marketId].language[language].content1} </li>
                <li>
                  {messages.marketId[marketId].language[language].content2}{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#" onClick={onClickSupport}>
                    {messages.marketId[marketId].language[language].subcontent2}
                  </a>
                </li>
                <li>{messages.marketId[marketId].language[language].content3}</li>
                <li>{messages.marketId[marketId].language[language].content4}</li>
              </ul>
            </div>
          </>
        )}
      </S.Message>
    </S.Wrapper>
  );
};

HelpAlertBase.defaultProps = {
  style: undefined,
};

export const HelpAlert = translate('accountPage')(HelpAlertBase);
