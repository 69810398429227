import React from 'react';
import { Dialog } from '@material-ui/core';
import { S } from './DialogBox.styles';

interface Props {
  isOpen: boolean;
  children?: React.ReactNode;
}

export const DialogBox: React.FC<React.PropsWithChildren<Props>> = ({ isOpen, children }) => (
  <Dialog open={isOpen} maxWidth="xs">
    <S.DialogContent>{children}</S.DialogContent>
  </Dialog>
);
