/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  OrderStatus,
  OrderFulfillmentStatus,
  PharmacyOrderStatus,
  InvoicePaymentStatus,
  OrderPrecursorStatus,
} from 'services';
import { PaymentMethods } from 'utils/constants';

export const getOrderStatusFilters = (
  pharmacyOrderStatus: PharmacyOrderStatus,
  orderPrecursorStatus?: OrderPrecursorStatus | null,
) => {
  switch (pharmacyOrderStatus) {
    case PharmacyOrderStatus.ORDER_RECEIVED:
      return {
        status: OrderStatus.PENDING,
        payment_method: PaymentMethods.CREDIT_LINE,
      };

    case PharmacyOrderStatus.PAID:
      return {
        status: OrderStatus.PENDING,
        payment_method: PaymentMethods.DIRECT_PAYMENT,
        invoice_payment_status: InvoicePaymentStatus.PAID,
      };

    case PharmacyOrderStatus.PREPARING_GOODS:
      return { statuses: [OrderStatus.PROCESSING, OrderStatus.ACCEPTED] };

    case PharmacyOrderStatus.OUT_OF_DELIVERY:
      return { fulfillment_status: OrderFulfillmentStatus.DISPATCHED };

    case PharmacyOrderStatus.DELAYED:
      return { is_delayed: true };

    case PharmacyOrderStatus.DELIVERED:
      return { statuses: [OrderStatus.COMPLETED, OrderStatus.DELIVERED, OrderStatus.FULFILLED] };

    case PharmacyOrderStatus.CANCELLED:
      return { status: OrderStatus.CANCELLED };

    case PharmacyOrderStatus.UNPAID:
      return {
        status: OrderStatus.PENDING,
        payment_method: PaymentMethods.DIRECT_PAYMENT,
        invoice_payment_status: InvoicePaymentStatus.PENDING,
      };

    case PharmacyOrderStatus.WAITING_DOCUMENT:
      return {
        status: OrderStatus.PENDING,
        only_document_pending: true,
        precursor_status:
          orderPrecursorStatus === OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL
            ? ('submitted,resubmitted' as OrderPrecursorStatus)
            : orderPrecursorStatus,
      };

    case PharmacyOrderStatus.ALL_ORDERS:
    default:
      return {};
  }
};
