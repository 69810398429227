import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';

import { TranslateProps } from 'utils/Localization';
import { S } from './TimeLeft.style';

interface Props extends TranslateProps {
  dateTime: Date | string;
  timeOutAfter?: number | null;
  hideLabel?: boolean;
  inline?: boolean;
  background?: string;
}

export const TimeLeftComponent: React.FC<React.PropsWithChildren<Props>> = ({ t, dateTime, timeOutAfter, hideLabel, background, inline }) => {
  const current = moment([]);
  const expiredIn = moment(dateTime).add(timeOutAfter, 'seconds');

  const calculateTimeLeft = (current, expiredIn): moment.Moment => moment(expiredIn.diff(current)).utc();

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(current, expiredIn));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(current, expiredIn));
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <S.Pill background={background} inline={inline}>
      {!hideLabel && <S.TimeLeftLabel data-testid="time-left-component-label">{t('orders.timeLeft')}:</S.TimeLeftLabel>}
      <S.TimeLeft data-testid="time-left-component-time">
        {current.isBefore(expiredIn) ? timeLeft.format('HH:mm:ss') : '00:00:00'}
      </S.TimeLeft>
    </S.Pill>
  );
};

TimeLeftComponent.defaultProps = {
  timeOutAfter: 14400,
};

export const TimeLeft = translate()(TimeLeftComponent);
