import Warp from 'warp-sdk-js';
import { store } from 'store';
import { removeTrailingSlash } from 'utils/URL/url.util';

const baseUrl = removeTrailingSlash(process.env.REACT_APP_API_BASE_URL || '');

const getMarketId = (): string => {
  const state = store.getState();
  return state.config.marketId || '';
};

const WarpDefaultInstance = Warp.extend({
  serverURL: `${baseUrl}/pharmacy` || '',
  apiKey: process.env.REACT_APP_PH_API_KEY || '',
  timeout: 20,
});

// With Market ID
export const warpFunctionRun = async (functionName: string, param: { [name: string]: any } = {}): Promise<any> => {
  const warpMarketIdInstance = Warp.extend({
    serverURL: `${baseUrl}/${getMarketId()}/pharmacy` || '',
    apiKey: process.env.REACT_APP_PH_API_KEY || '',
    timeout: 20,
  });

  return warpMarketIdInstance.Function.run(functionName, param);
};

export default WarpDefaultInstance;
