import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-i18next';

import { DateTime } from 'components/common';
import { Order } from 'services';
import { calculateAmountAfterTax, toCurrency } from 'utils/Format';

import { Typography } from '@material-ui/core';
import { useTooltipTour, TooltipTour } from 'components/common/TooltipTour';
import { useOrderStatus } from 'hooks';
import { S } from './orders-history-card.styles';
import * as colors from 'components/styles/colors';

let firstTooltipId: null | number = null;

interface Props {
  t: any;
  order: Order;
  onClick: () => void;
}

const OrderHistoryCard: React.FC<React.PropsWithChildren<Props>> = ({ t, order, onClick }) => {
  const { tooltipParams, visible } = useTooltipTour('prekursorTooltipTour.activityCard', 'bottom');

  const {
    status,
    values: statusValues,
    showSecondaryStatusLabel,
    showAlkesLabel,
    documentType,
    showPrekursorLabel,
    showPendingUploadPrekursorLabel,
  } = useOrderStatus(order, t);

  useEffect(() => {
    if (!firstTooltipId && showPrekursorLabel) firstTooltipId = order.id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);
  return (
    <S.Card onClick={() => onClick()}>
      <S.CardStatusHeader container status={status} documentType={documentType} data-testid="order-card-header">
        <Grid item className="primary">
          <S.Status>{statusValues[0]}</S.Status>
        </Grid>
        {showSecondaryStatusLabel && (
          <Grid item className="secondary">
            <S.Status>{statusValues[1]}</S.Status>
          </Grid>
        )}
      </S.CardStatusHeader>
      <S.CardContainer>
        <S.Row>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={7}>
              <DateTime dateTime={order.ordered_at} />
            </Grid>
            <Grid item xs={5} container direction="row" alignItems="flex-start" justify="flex-end" spacing={1}>
              <Grid item>
                <S.ViewDetailText data-testid={`seeDetails-btn-${order.po_number}`}>
                  {t('moreDetails')}
                </S.ViewDetailText>
              </Grid>
              <Grid item>
                <S.ViewDetailIcon />
              </Grid>
            </Grid>
          </Grid>
        </S.Row>
        <S.CardHeader>
          <S.Title>{order.distributor?.name}</S.Title>
        </S.CardHeader>
        <S.Row>
          <Grid item xs={8}>
            <S.Label>{t('invoice')}</S.Label>
            <S.Value className={!order.invoice?.invoice_number ? 'text-gray' : ''}>
              {order.invoice?.invoice_number ?? t('invoiceNotAvailable')}
            </S.Value>
          </Grid>
          <Grid item xs={8}>
            <S.Label align="right">{t('totalAmountHistory')}</S.Label>
            <S.Currency align="right">
              {order.invoice?.invoiced_amount
                ? toCurrency(order.invoice.invoiced_amount)
                : toCurrency(calculateAmountAfterTax(order.net_amount, order.tax_rate))}
            </S.Currency>
            {firstTooltipId === order.id && <div ref={tooltipParams.setTriggerRef} />}
          </Grid>
        </S.Row>
        {firstTooltipId === order.id && (
          <TooltipTour
            name="prekursorTooltipTour.activityCard"
            tooltipParams={tooltipParams}
            visible={visible && showPendingUploadPrekursorLabel}
            width={210}
            offsetX={-26}
            zIndex={10}
          >
            <Typography>{t('prekursorTooltipTour.activityCard')}</Typography>
          </TooltipTour>
        )}
      </S.CardContainer>
    </S.Card>
  );
};

export const OrdersHistoryCard = translate('orders')(OrderHistoryCard);
