/* eslint-disable no-plusplus */
export function linspace(a: number, b: number, n: number): number[] {
  if (typeof n === 'undefined') n = Math.max(Math.round(b - a) + 1, 1);
  if (n < 2) {
    return n === 1 ? [a] : [];
  }
  let i: number;
  const ret = Array(n);
  n--;
  for (i = n; i >= 0; i--) {
    ret[i] = (i * b + (n - i) * a) / n;
  }
  return ret;
}

export function generateScrollRange(a: number, b: number, n: number): number[][] {
  const scrollRange: number[][] = [];

  const range = linspace(a, b, n + 1);

  for (let i = 0; i < n; i++) {
    if (i === n - 1) scrollRange.push([range[i], b]);
    else scrollRange.push([range[i], range[i + 1]]);
  }

  return scrollRange;
}
