import * as Redux from 'redux';
import { Actions } from './actions';
import * as constants from './constants';

export interface State {
  loading: boolean;
  requirements: any;
  error: string | null;
  disabled: boolean;
}

const InitialState: State = {
  loading: false,
  requirements: [],
  error: null,
  disabled: false,
};

export const RequirementReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_PURCHASE_ORDER_REQUIREMENT:
      return {
        ...state,
        loading: false,
        error: null,
        requirements: action.payload,
      };

    case constants.PATCH_PURCHASE_ORDER_REQUIREMENT:
      return {
        ...state,
        loading: false,
        error: null,
        requirements: [action.payload],
      };

    case constants.SET_PURCHASE_ORDER_REQUIREMENT_DISABLED:
      return {
        ...state,
        loading: false,
        disabled: true,
      };

    case constants.SET_PURCHASE_ORDER_REQUIREMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case constants.FAIL_PATCH_PURCHASE_ORDER_REQUIREMENT:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        requirements: [],
      };

    default:
      return state;
  }
};

export default RequirementReducer;
