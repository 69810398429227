import React from 'react';
import { ICreateSectionConfigs, IItemConfig, IMenuContent, ISearchItem } from '../interface';
import { ChipSection, ListSection } from '../search-menu-section';

export enum SectionType {
  CHIP = 'chip',
  LIST = 'list',
  COMPONENT = 'component',
}

interface ISectionFactoryProps extends ICreateSectionConfigs, Omit<IMenuContent, 'generalSections' | 'isInitial'> {
  id: string;
  items: IItemConfig;
  title: string;
  subtitle?: any;
  data: ISearchItem[];
  sectionIndex;
  isSectionGeneral: boolean;
  onSeeAllClick?: () => void;
  additionalExpandToggle: (value: string) => void;
  expandSection: string;
}

export const SectionFactory = (displayType: SectionType, props: ISectionFactoryProps): JSX.Element | null => {
  const {
    id,
    SectionComponent,
    title,
    highlightedIndex,
    selectedItem,
    items,
    getItemProps,
    expandSection,
    inputValue,
    data,
    t,
    subtitle,
    additionalExpandToggle,
    isSectionGeneral,
    sectionIndex,
    onSeeAllClick,
    hideThumbnail,
    ...restConfig
  } = props;

  if (displayType === SectionType.COMPONENT && SectionComponent) {
    return (
      <SectionComponent
        sectionId={id}
        highlightedIndex={highlightedIndex as number}
        selectedItem={selectedItem}
        items={items}
        getItemProps={getItemProps}
        expandSection={expandSection}
        title={title}
        data={data}
        t={t}
        subTitle={subtitle}
        additionalExpandFunction={additionalExpandToggle}
        {...restConfig}
      />
    );
  }

  if (displayType === SectionType.CHIP) {
    return (
      <ChipSection
        data-testid={`section-${id}`}
        sectionId={id}
        highlightedIndex={highlightedIndex as number}
        items={items}
        expandSection={expandSection}
        title={title}
        data={data}
        t={t}
        subTitle={subtitle}
        additionalExpandFunction={additionalExpandToggle}
        {...restConfig}
      />
    );
  }
  if (displayType === SectionType.LIST) {
    const hideFromSection = hideThumbnail && !!data?.length;
    const keyDoesNotExist = !hideThumbnail && !!data?.length && !Object.keys(data[0]).includes('thumbnail_photo');
    const isHideThumbnail = hideFromSection || keyDoesNotExist;

    return (
      <ListSection
        data-testid={`section-${id}`}
        sectionId={id}
        expandSection={expandSection}
        data={data}
        t={t}
        title={title}
        inputValue={inputValue}
        isSectionGeneral={isSectionGeneral}
        highlightedIndex={highlightedIndex as number}
        items={items}
        sectionIndex={sectionIndex}
        onSeeAllButton={onSeeAllClick}
        subTitle={subtitle}
        additionalExpandFunction={additionalExpandToggle}
        hideThumbnail={isHideThumbnail}
        {...restConfig}
      />
    );
  }

  return null;
};
