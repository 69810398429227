import { createTheme } from '@material-ui/core/styles';
import * as colors from './colors';

export const theme = createTheme({
  palette: {
    primary: { main: colors.PRIMARY_COLOR },
    secondary: { main: colors.SECONDARY_COLOR },
  },
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
  },
  overrides: {
    MuiCardContent: {
      root: {
        paddingBottom: '16px !important',
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: colors.WHITE_SMOKE,
        borderTop: `2px solid gainsboro`,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: colors.GRAY,
        fontWeight: 300,
        letterSpacing: '0.05em',
      },
      selected: {
        color: `${colors.PRIMARY_COLOR} !important`,
        fontWeight: 300,
        letterSpacing: '0.05em',
      },
    },
    MuiTypography: {
      body1: {
        color: colors.MEDIUM_GRAY,
      },
    },
    MuiCollapse: {
      entered: {
        height: 'auto',
        overflow: 'visible',
      },
    },
  },
});
