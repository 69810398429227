import { BranchIO, SegmentIO } from './providers';
import { SwipeRxAnalytics } from './SwipeRxAnalytics';
import { setAnalyticsTag } from './utils/branchIoAnalyticsTag.utils';

export const initialiseAnalytics = (): void => {
  try {
    const swipeRxAnalytics = SwipeRxAnalytics.getInstance();
    const segmentToken = process.env.REACT_APP_SEGMENT_TOKEN as string;
    const branchToken = process.env.REACT_APP_BRANCH_TOKEN as string;

    if (segmentToken) {
      const segmentIO = new SegmentIO();
      segmentIO.init(segmentToken);
      swipeRxAnalytics.attach(segmentIO);
    }

    if (branchToken) {
      const branchIO = new BranchIO();
      branchIO.init(
        branchToken,
        (error) => {
          if (!error) {
            swipeRxAnalytics.attach(branchIO);
          }
        },
        setAnalyticsTag,
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to initialise analytics', error);
  }
};
