import { OrderFilters } from '../orders-filter.interface';

export enum OrderFiltersEnum {
  ADD_DISTRIBUTOR_FILTER = 'ADD_DISTRIBUTOR_FILTER',
  REMOVE_DISTRIBUTOR_FILTER = 'REMOVE_DISTRIBUTOR_FILTER',
  ADD_DOCUMENT_FILTER = 'ADD_DOCUMENT_FILTER',
  REMOVE_DOCUMENT_FILTER = 'REMOVE_DOCUMENT_FILTER',
  RESET_FILTER = 'RESET_FILTER',
  SET_PREVIOUS_FILTER = 'SET_PREVIOUS_FILTER',
}

export type OrderFiltersActions =
  | AddDistributorFilter
  | RemoveDistributorFilter
  | AddDocumentFilter
  | RemoveDocumentFilter
  | SetPrevFilters
  | ResetFilter;

interface AddDistributorFilter {
  type: OrderFiltersEnum.ADD_DISTRIBUTOR_FILTER;
  payload: { distributorId: number };
}

interface RemoveDistributorFilter {
  type: OrderFiltersEnum.REMOVE_DISTRIBUTOR_FILTER;
  payload: { distributorId: number };
}

interface AddDocumentFilter {
  type: OrderFiltersEnum.ADD_DOCUMENT_FILTER;
  payload: { documentId: number };
}

interface RemoveDocumentFilter {
  type: OrderFiltersEnum.REMOVE_DOCUMENT_FILTER;
  payload: { documentId: number };
}

interface SetPrevFilters {
  type: OrderFiltersEnum.SET_PREVIOUS_FILTER;
  payload: { prevFilters: OrderFilters };
}

interface ResetFilter {
  type: OrderFiltersEnum.RESET_FILTER;
}

export const addDistributorFilter = (distributorId: number): AddDistributorFilter => ({
  type: OrderFiltersEnum.ADD_DISTRIBUTOR_FILTER,
  payload: { distributorId },
});

export const removeDistributorFilter = (distributorId: number): RemoveDistributorFilter => ({
  type: OrderFiltersEnum.REMOVE_DISTRIBUTOR_FILTER,
  payload: { distributorId },
});

export const addDocumentFilter = (documentId: number): AddDocumentFilter => ({
  type: OrderFiltersEnum.ADD_DOCUMENT_FILTER,
  payload: { documentId },
});

export const removeDocumentFilter = (documentId: number): RemoveDocumentFilter => ({
  type: OrderFiltersEnum.REMOVE_DOCUMENT_FILTER,
  payload: { documentId },
});

export const setPrevFilters = (prevFilters: OrderFilters): SetPrevFilters => ({
  type: OrderFiltersEnum.SET_PREVIOUS_FILTER,
  payload: { prevFilters },
});

export const resetFilter = (): ResetFilter => ({
  type: OrderFiltersEnum.RESET_FILTER,
});
