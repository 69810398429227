export enum LoyaltyHistoryStatus {
  ALL = 'all',
  VERIFIED = 'verified',
  BONUS = 'bonus',
  PENDING = 'pending',
  REDEEMED = 'redeemed',
  DEDUCTED = 'deducted',
  EXPIRED = 'expired',
  EXPIRING_SOON = 'expiring',
}
