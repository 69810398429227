import { useLocation, useRouteMatch } from 'react-router-dom';
import qs from 'query-string';

import { Product } from 'store/reducers/Product';

import { trackItemMarketingProps } from 'store/reducers/Cart/actions';
import { useDispatch } from 'react-redux';

import { ProductSimpleSearchLocationProps, SegmentPropsBuilder, trackProductAdd } from 'utils/Analytics/Segment';

import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { useCampaignTracking } from './useCampaignTracking';
import { State as FilterToolbarState } from '../store/reducers/FilterToolbar';

export interface LocationSearchProps extends ProductSimpleSearchLocationProps {
  filters?: string[];
  goBackTo?: string;
  isModalOpen?: string;
  product_ids?: number[];
}

export interface ProductAnalyticProps extends Product {
  marketing_id?: number;
  marketing_name?: string;
  utm_source?: string;
  utm_medium?: string;
  from_instock_reminder?: boolean;
  filter?: string[];
}

export const useAddProductAnalytics = (
  marketing_id?: number | string,
  marketing_name?: string,
  selectedFilters?: FilterToolbarState['selectedFilters'],
  indexNumber?: number,
): any => {
  const location = useLocation<LocationSearchProps>();

  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { query }: LocationSearchProps = qs.parse(location.search, BRACKET_QS_OPTIONS) || {};

  const { utmSource, utmMedium, marketingId, marketingName } = useCampaignTracking(marketing_id, marketing_name);
  const { product_ids, from_instock_reminder } =
    (location?.state as { product_ids?: number[]; from_instock_reminder?: boolean }) || {};

  const trackProductWithMarketing = (product: Product): void => {
    const isReminder =
      from_instock_reminder && product_ids ? product_ids.find((prodId) => prodId === product.id) : false;
    const analyticProps = {
      ...(marketingId ? { marketing_id: marketingId } : {}),
      ...(marketingName ? { marketing_name: marketingName } : {}),
      ...(utmSource ? { utm_source: utmSource } : {}),
      ...(utmMedium ? { utm_medium: utmMedium } : {}),
      ...(isReminder ? { from_instock_reminder: true } : {}),
      ...(selectedFilters ? { filter: selectedFilters } : {}),
    };

    const productAnalyticProps: ProductAnalyticProps = {
      ...product,
      ...analyticProps,
    };

    if (marketingId || marketingName || utmSource || utmMedium) {
      dispatch(trackItemMarketingProps(productAnalyticProps));
    }

    const search_section = location?.state?.search_section;

    const searchProps = {
      ...(search_section ? { search_section } : {}),
      ...(query ? { query } : {}),
    };

    const productAddedAnalyticProps = SegmentPropsBuilder.buildProductAddedMapping(
      product,
      match,
      analyticProps,
      location.search,
      searchProps,
      indexNumber,
    );
    trackProductAdd(productAddedAnalyticProps);
  };

  return {
    trackProductWithMarketing,
  };
};
