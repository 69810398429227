/* eslint-disable no-console */
import { Action } from 'redux';

import { recordException } from 'utils/Reporting/Sentry';

import { swipeRxPt } from 'services';
import { v3Client } from 'utils/Client/v3';
import { getPaymentProvider } from 'utils/helpers';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import ClientRequest from '../../../utils/Client';

export enum AccountActionType {
  SET_DEFAULT_VIRTUAL_ACCOUNT = '@@account/SET_DEFAULT_VIRTUAL_ACCOUNT',
  SET_VIRTUAL_ACCOUNTS = '@@account/SET_VIRTUAL_ACCOUNTS',
  SET_VIRTUAL_ACCOUNTS_LOADING = '@@account/SET_VIRTUAL_ACCOUNTS_LOADING',
  SET_MEMBERSHIPS = '@@account/SET_MEMBERSHIPS',
  SET_MEMBERSHIPS_LOADING = '@@account/SET_MEMBERSHIPS_LOADING',
  SET_PAYMENT_PROVIDER = '@@account/SET_PAYMENT_PROVIDER',
}

interface SetDefaultVirtualAccount extends Action<AccountActionType.SET_DEFAULT_VIRTUAL_ACCOUNT> {
  payload: {
    virtualAccountID: number;
  };
}

interface SetVirtualAccounts extends Action<AccountActionType.SET_VIRTUAL_ACCOUNTS> {
  payload: {
    virtualAccounts: constants.VirtualAccount[];
  };
}

interface SetVirtualAccountsLoading extends Action<AccountActionType.SET_VIRTUAL_ACCOUNTS_LOADING> {
  payload: {
    loading: boolean;
  };
}

interface SetMemberships extends Action<AccountActionType.SET_MEMBERSHIPS> {
  payload: {
    memberships?: constants.Memberships | undefined;
  };
}

interface SetMembershipsLoading extends Action<AccountActionType.SET_MEMBERSHIPS_LOADING> {
  payload: {
    membershipsLoading: boolean;
  };
}

interface SetPaymentProvider extends Action<AccountActionType.SET_PAYMENT_PROVIDER> {
  payload: {
    paymentProvider: constants.PaymentProviders;
  };
}

/**
 * Action Types
 */
export interface AccountPtDefault extends Action {
  type: constants.ACCOUNT_HELP_DEFAULT_HOMEPAGE;
  options: any;
}

export interface FailAccountPtDefault extends Action {
  type: constants.FAIL_ACCOUNT_HELP_DEFAULT_HOMEPAGE;
  error: string;
}

export interface GetAccountPtDefault extends Action {
  type: constants.GET_HELP_DEFAULT_HOMEPAGE;
  options: any;
}

export interface GetFailAccountPtDefault extends Action {
  type: constants.FAIL_GET_HELP_DEFAULT_HOMEPAGE;
  error: string;
}

export type Actions =
  | AccountPtDefault
  | FailAccountPtDefault
  | GetAccountPtDefault
  | GetFailAccountPtDefault
  | SetDefaultVirtualAccount
  | SetVirtualAccounts
  | SetVirtualAccountsLoading
  | SetMembershipsLoading
  | SetMemberships
  | SetPaymentProvider;

export const setPtHomepage: ThunkActionCreator<Actions, any> = (flag: boolean) => async (dispatch) => {
  try {
    const response = await ClientRequest.updateCoreUserOptions('', {
      isPtHomepage: flag,
      coreUserId: localStorage.getItem('coreUserId'),
    });

    const dispatchSetOpt = response && response.isPtHomepage ? response.isPtHomepage : false;

    dispatch({ type: constants.ACCOUNT_HELP_DEFAULT_HOMEPAGE, options: dispatchSetOpt });
  } catch (error) {
    // Dispatch the error
    recordException(error, 'setPtHomepage');
    console.error(error.message);
    dispatch({ type: constants.FAIL_ACCOUNT_HELP_DEFAULT_HOMEPAGE, error: error.message });
  }
};

export const getPtHomePage: ThunkActionCreator<Actions, any> = () => async (dispatch) => {
  try {
    // Use the core session
    const response = await ClientRequest.getCoreProfile('', localStorage.getItem('coreUserId'));
    const options: any = response && response.id ? response.isPtHomepage : false;
    dispatch({ type: constants.GET_HELP_DEFAULT_HOMEPAGE, options });
  } catch (error) {
    // Dispatch the error
    recordException(error, 'getPtHomePage');
    console.error(error.message);
    dispatch({ type: constants.FAIL_GET_HELP_DEFAULT_HOMEPAGE, error: error.message });
  }
};

export const setDefaultVirtualAccount = (virtualAccountID: number): SetDefaultVirtualAccount => ({
  type: AccountActionType.SET_DEFAULT_VIRTUAL_ACCOUNT,
  payload: { virtualAccountID },
});

export const setVirtualAccounts = (virtualAccounts: constants.VirtualAccount[]): SetVirtualAccounts => ({
  type: AccountActionType.SET_VIRTUAL_ACCOUNTS,
  payload: { virtualAccounts },
});

export const setVirtualAccountsLoading = (loading: boolean): SetVirtualAccountsLoading => ({
  type: AccountActionType.SET_VIRTUAL_ACCOUNTS_LOADING,
  payload: { loading },
});

export const setPaymentProvider = (paymentProvider: constants.PaymentProviders): SetPaymentProvider => ({
  type: AccountActionType.SET_PAYMENT_PROVIDER,
  payload: { paymentProvider },
});

export const fetchVirtualAccounts: ThunkActionCreator<Actions> = (pharmacyID: string) => async (dispatch) => {
  try {
    dispatch<SetVirtualAccountsLoading>(setVirtualAccountsLoading(true));

    const response = await v3Client.get(`/organizations/${pharmacyID}/virtual-accounts`);

    if (response.data.length > 0) {
      const paymentProviderId = response.data[0].payment_provider_id;
      const paymentProvider = getPaymentProvider(paymentProviderId);
      dispatch<SetPaymentProvider>(setPaymentProvider(paymentProvider));
    }

    dispatch<SetVirtualAccounts>(setVirtualAccounts(response.data));
  } catch (e) {
    const { message } = e;
    recordException(e, 'fetchVirtualAccounts');
    console.error('@AccountActions::fetchVirtualAccounts', message);
    dispatch({
      type: constants.FAIL_GET_HELP_DEFAULT_HOMEPAGE,
      error: message,
    });
  } finally {
    dispatch<SetVirtualAccountsLoading>(setVirtualAccountsLoading(false));
  }
};

export const updateDefaultVirtualAccount: ThunkActionCreator<Actions> =
  (pharmacyID: number, virtualAccountID: number) => async (dispatch) => {
    try {
      await v3Client.patch(`/organizations/${pharmacyID}/virtual-accounts/${virtualAccountID}/set-default`);

      dispatch<SetDefaultVirtualAccount>(setDefaultVirtualAccount(virtualAccountID));
    } catch (e) {
      const { message } = e;
      recordException(e, 'updateDefaultVirtualAccount');
      console.error('@AccountActions::updateDefaultVirtualAccount', message);
      dispatch({
        type: constants.FAIL_GET_HELP_DEFAULT_HOMEPAGE,
        error: message,
      });
    }
  };

export const setMembershipsLoading = (loading: boolean): SetMembershipsLoading => ({
  type: AccountActionType.SET_MEMBERSHIPS_LOADING,
  payload: { membershipsLoading: loading },
});

export const setMemberships = (memberships: constants.Memberships): SetMemberships => ({
  type: AccountActionType.SET_MEMBERSHIPS,
  payload: { memberships },
});

export const fetchMemberships: ThunkActionCreator<Actions> = (pharmacyID: string) => async (dispatch) => {
  try {
    dispatch<SetMembershipsLoading>(setMembershipsLoading(true));

    const data = await swipeRxPt.accounts.memberships(Number.parseInt(pharmacyID, 10));

    dispatch<SetMemberships>(setMemberships({ ...data, membership_statuses: data.membership_statuses.split(',') }));
  } catch (e) {
    const { message } = e;
    recordException(e, 'fetchMemberships');
    console.error('@AccountActions::fetchMemberships', message);
    dispatch({
      type: constants.FAIL_GET_HELP_DEFAULT_HOMEPAGE,
      error: message,
    });
  } finally {
    dispatch<SetMembershipsLoading>(setMembershipsLoading(false));
  }
};
