import React, { useReducer } from 'react';
import { initialState, MarketingDispatchContext, marketingReducer, MarketingStateContext } from './marketingContext';

const withMarketing = () => (Component) => (props) => {
  const [state, dispatch] = useReducer(marketingReducer, initialState);

  return (
    <MarketingStateContext.Provider value={state}>
      <MarketingDispatchContext.Provider value={dispatch}>
        <Component {...props} />
      </MarketingDispatchContext.Provider>
    </MarketingStateContext.Provider>
  );
};

export default withMarketing;
