import { ProductListingParam } from 'components/common';
import { FilterChipOptions } from 'components/common/FilterToolbar/components/FilterChip.component';
import { SortType, FilterType } from 'components/common/FilterToolbar/constants';
import { Action } from 'redux';

import * as constants from './constants';

export interface SetupFilterAction extends Action {
  type: constants.SETUP_FILTER;
  toolbarFilterMenu: FilterMenuItem[];
}

export interface SelectFilterAction extends Action {
  type: constants.SELECT_FILTER;
  selectedFilters: string[];
  selectedSorts: string[];
  searchParameters: ProductListingParam;
  filterUrl?: string;
  filterSort?: SortType;
  lvl2SortBy: SortType[];
  allowSearch: boolean;
}

export interface ClearFiltersAction extends Action {
  type: constants.CLEAR_FILTERS;
}

export interface ErrorOnFilterAction extends Action {
  type: constants.ERROR_ON_FILTER;
  error?: string;
}

export interface SetShowFilterSortModalAction extends Action {
  type: constants.SET_SHOW_FILTER_SORT_MODAL;
  show?: boolean;
}

export interface SetReinitializeFilters extends Action {
  type: constants.SET_REINITIALIZE_FILTERS;
  reinitialize: boolean;
}

export type Actions =
  | SelectFilterAction
  | ClearFiltersAction
  | ErrorOnFilterAction
  | SetupFilterAction
  | SetShowFilterSortModalAction
  | SetReinitializeFilters;

export enum ConjuctionType {
  HORIZONTAL = 'horizontal',
}

export interface FilterMenuItem extends FilterChipOptions {
  type: FilterType;
  filterUrl?: string;
  filterSort?: SortType;
  isSelected?: boolean;
  isMultiSelect?: boolean;
  isRegex?: boolean;
  conjunction?: ConjuctionType;
}
