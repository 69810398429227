import { PaymentMethods } from 'utils/constants';
import { SwipeRxPt } from '../../SwipeRxPt.class';
import { VoucherList } from './interface/voucher-list.interface';
import { VoucherListParam } from './interface/voucher-list-param.interface';
import { VoucherApplyParam } from './interface/voucher-apply-param.interface';
import { VoucherApplyResponse } from './interface/voucher-apply-response.interface';
import { VoucherEligibilityParam } from './interface/voucher-eligibility-param.interface';
import { VoucherEligibilityResponse } from './interface/voucher-eligibility-response.interface';

export class SwipeRxPtVoucher {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async list(params?: VoucherListParam): Promise<VoucherList> {
    const { data } = await this.base.getV2('/pharmacy-voucher', params);

    return data;
  }

  async applyVoucher(paymentMethods: PaymentMethods, params: VoucherApplyParam): Promise<VoucherApplyResponse | null> {
    let data: VoucherApplyResponse | null;

    if (paymentMethods === PaymentMethods.DIRECT_PAYMENT) {
      ({ data } = await this.base.patch('/payment/apply-voucher', params));
    } else {
      ({ data } = await this.base.patch('/deposit/voucher/apply', params));
    }

    return data;
  }

  async isEligible(params?: VoucherEligibilityParam): Promise<VoucherEligibilityResponse> {
    const { data } = await this.base.getV2('/pharmacy-voucher/check-eligibility', params);

    return data;
  }
}
