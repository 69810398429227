import styled from 'styled-components';
import { Grid, Typography, ButtonBase } from '@material-ui/core';

import * as colors from 'components/styles/colors';

const Container = styled(Grid)`
  && {
    padding: 5px 15px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;
    -ms-overflow-style: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Pill = styled(ButtonBase)<{ isSelected?: boolean }>`
  && {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 15px;
    border-radius: 20px;
    background-color: ${({ isSelected }) => (isSelected ? '#F4FFFB' : colors.SUBTLE_GRAY)};
    box-shadow: ${({ isSelected }) => (isSelected ? '0px 1px 8px rgba(2, 84, 68, 0.23)' : 'none')};
    border: ${({ isSelected }) => `1px solid ${isSelected ? colors.PRIMARY_COLOR : 'transparent'}`};
    animation: all 0.5s ease-in-out;
  }

  &:not(:first-child) {
    margin-left: 7px;
  }
`;

const Label = styled(Typography)<{ isSelected?: boolean }>`
  && {
    white-space: nowrap;
    color: ${({ isSelected }) => (isSelected ? colors.PRIMARY_COLOR : colors.BLUISH_GRAY_LIGHT)};
    font-weight: ${({ isSelected }) => (isSelected ? 600 : 400)};
    text-transform: capitalize;
  }
`;

export const S = { Container, Pill, Label };
