/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { Action } from 'redux';
import { swipeRxPt } from 'services';

import { recordException } from 'utils/Reporting/Sentry';
import { v3Client } from 'utils/Client/v3';

import { ThunkActionCreator } from '../../../types/thunk';
import { State } from '../../index';

import { DepositRequestActionTypes } from './constants';
import { CreateDepositRequestPayload, DepositRequest, IDepositRequestLatestProcessed } from './types';

export type Actions =
  | FetchActiveDepositRequest
  | SetActiveDepositRequestLoading
  | ClearActiveDepositRequest
  | SetDepositRequestError
  | FetchLatestProcessedDepositRequest;

export interface FetchActiveDepositRequest extends Action {
  type: DepositRequestActionTypes.FETCH_ACTIVE_DEPOSIT_REQUEST;
  payload: DepositRequest;
}

export interface SetActiveDepositRequestLoading extends Action {
  type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING;
  payload: boolean;
}

export interface ClearActiveDepositRequest extends Action {
  type: DepositRequestActionTypes.CLEAR_ACTIVE_DEPOSIT_REQUEST;
}
export interface SetDepositRequestError extends Action {
  type: DepositRequestActionTypes.SET_ACTIVE_DEPOSIT_ERROR;
  payload: { error: string | null; errorCode: string | null };
}

export const setActiveDepositRequestLoading = (loading: boolean) => async (dispatch) => {
  dispatch({
    type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING,
    payload: loading,
  });
};

export const clearActiveDepositRequest = () => async (dispatch) => {
  dispatch({
    type: DepositRequestActionTypes.CLEAR_ACTIVE_DEPOSIT_REQUEST,
  });
};

export const setDepositRequestError =
  (errorResponse: { errorCode: null | string; error: null | string }) => async (dispatch) => {
    dispatch({
      type: DepositRequestActionTypes.SET_ACTIVE_DEPOSIT_ERROR,
      payload: errorResponse,
    });
  };

export interface FetchLatestProcessedDepositRequest extends Action {
  type: DepositRequestActionTypes.FETCH_LATEST_PROCESSED_DEPOSIT_REQUEST;
  payload: IDepositRequestLatestProcessed;
}

export interface FetchActiveDepositRequestArgs {
  fetchDetails?: boolean;
  reuseDeposit?: boolean;
  page?: number;
  pageSize?: number;
  callback?: () => void;
}

export const fetchActiveDepositRequest: ThunkActionCreator<Action, State> =
  (params: FetchActiveDepositRequestArgs = {}) =>
  async (dispatch, getState) => {
    try {
      const { fetchDetails, reuseDeposit, page = 0, pageSize = 5, callback } = params;

      setActiveDepositRequestLoading(true);

      let deposit: DepositRequest | null;
      if (!reuseDeposit) {
        const resultResponse = await v3Client.get(`deposit-request/active`);

        deposit = resultResponse.data.deposit_request;
      } else {
        const {
          depositRequest: { active },
        } = getState();

        deposit = active;
      }

      if (fetchDetails && deposit) {
        const firstItemIndex = page * pageSize;
        const lastItemIndex = (1 + page) * pageSize;

        const ordersIds = deposit.deposit_request_priority
          .slice(firstItemIndex, lastItemIndex)
          .map((item) => item.invoice.purchase_order_id);

        const purchaseOrders = await swipeRxPt.orders.listV2({
          order_ids: ordersIds,
          expand: ['invoice', 'distributor'],
          page_size: ordersIds.length,
        });

        purchaseOrders.data.forEach((order) => {
          const dprIndex = (deposit as DepositRequest).deposit_request_priority.findIndex(
            (dpr) => dpr.invoice_id === order.invoice?.id,
          );

          if (dprIndex >= 0) {
            (deposit as DepositRequest).deposit_request_priority[dprIndex] = {
              ...(deposit as DepositRequest).deposit_request_priority[dprIndex],
              invoiceAmount: order.invoice?.invoiced_amount || 0,
              distributorName: order.distributor?.name,
            };
          }
        });
      }

      dispatch({
        type: DepositRequestActionTypes.FETCH_ACTIVE_DEPOSIT_REQUEST,
        payload: deposit,
      });

      if (callback) callback();
    } catch (error) {
      recordException(error, 'fetchActiveDepositRequest');
      setDepositRequestError(error);
    } finally {
      setActiveDepositRequestLoading(false);
    }
  };

export const createDepositRequest: ThunkActionCreator<Action, State> =
  (payload: CreateDepositRequestPayload, callback?: () => void) => async (dispatch) => {
    try {
      dispatch({
        type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING,
        payload: true,
      });

      await v3Client.post(`deposit-request/create`, payload);

      if (callback) callback();
    } catch (error) {
      recordException(error, 'createDepositRequest', { payload });
      dispatch({
        type: DepositRequestActionTypes.SET_ACTIVE_DEPOSIT_ERROR,
        payload: error.response.data,
      });
    } finally {
      dispatch({
        type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING,
        payload: false,
      });
    }
  };

export const cancelDepositRequest: ThunkActionCreator<Action, State> = (callback?: () => void) => async (dispatch) => {
  try {
    dispatch({
      type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING,
      payload: true,
    });

    await v3Client.post(`deposit-request/cancel`);

    if (callback) callback();
  } catch (error) {
    recordException(error, 'cancelDepositRequest');
    setDepositRequestError(error);
  } finally {
    dispatch({
      type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING,
      payload: false,
    });
  }
};

export const submitDepositProof: ThunkActionCreator<Action, State> =
  (files: FileList, progress?: (progressEvent: any) => void, finalCb?: () => void) => async (dispatch) => {
    try {
      dispatch({
        type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING,
        payload: true,
      });

      await v3Client.uploadFile(`deposit-request/submit-proof`, files[0], progress);
    } catch (error) {
      recordException(error, 'submitDepositProof', { files });
      setDepositRequestError(error);
    } finally {
      if (finalCb) finalCb();

      dispatch({
        type: DepositRequestActionTypes.SET_DEPOSIT_REQUESTS_LOADING,
        payload: false,
      });
    }
  };

export const fetchLatestProcessedDepositRequest: ThunkActionCreator<Action, State> = () => async (dispatch) => {
  try {
    setActiveDepositRequestLoading(true);

    const resultResponse = await v3Client.get(`deposit-request/latest-processed`);

    dispatch({
      type: DepositRequestActionTypes.FETCH_LATEST_PROCESSED_DEPOSIT_REQUEST,
      payload: resultResponse.data,
    });
  } catch (error) {
    recordException(error, 'fetchActiveDepositRequest');
    setDepositRequestError(error);
  } finally {
    setActiveDepositRequestLoading(false);
  }
};
