import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { translate } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { Row } from 'components/common';
import ShowIf from 'components/views/ShowIf';
import { State as StoreState } from 'store';
import { calculateAmountAfterTax, calculateAmountBeforeTax, calculateTax, toCurrency } from 'utils/Format';
import { shouldShowCreditInfo } from 'utils/helpers/show-credit-info';
import { shouldShowTaxBreakdown } from 'utils/MarketConfig';
import { TFunction } from 'utils/Localization';
import { OrderItem } from 'services';

import { S } from './orders-modal-summary.styles';

const OrdersModalSummaryPaymentsComponent: React.FC<React.PropsWithChildren<{ t: TFunction }>> = ({ t }) => {
  const {
    purchaseOrder: { orderDetail },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const [previousPayment, setPreviousPayment] = useState({
    flag: false,
    totalOrders: 0,
    totalTax: 0,
    totalAmount: 0,
  });

  useEffect(() => {
    const flag: boolean = orderDetail.flagged_item_counts !== null;
    const { items } = orderDetail;
    if (flag && items) {
      const totalOrders: number = items.reduce((total: number, item: OrderItem) => {
        const unitPrice = (item.previous_selling_price as number) || item.selling_price;
        const qty = (item.previous_quantity as number) || item.quantity;
        const subTotal = unitPrice * qty;
        const discountRate = (item.previous_discount_rate as number) || item.discount_rate;
        const discountPrice = subTotal * discountRate;
        total += subTotal - discountPrice;

        return total;
      }, 0);
      const totalTax: number = totalOrders * orderDetail.tax_rate;

      setPreviousPayment({
        flag,
        totalOrders,
        totalTax,
        totalAmount: Math.ceil(totalOrders + totalTax),
      });
    }
  }, [orderDetail]);

  const totalOrders = calculateAmountBeforeTax(orderDetail.net_amount, orderDetail.tax_rate);
  const totalTax = calculateTax(orderDetail.net_amount, orderDetail.tax_rate);
  const totalAmount =
    orderDetail?.invoice?.invoiced_amount ?? calculateAmountAfterTax(orderDetail.net_amount, orderDetail.tax_rate);

  return (
    <S.Card>
      <S.Row>
        <div style={{ width: '100%' }}>
          <S.InnerRow>
            <S.Label>{t('totalOrders')}</S.Label>
            <Row>
              {previousPayment.flag && previousPayment.totalOrders !== totalOrders && (
                <S.Value className="old">{toCurrency(previousPayment.totalOrders)}</S.Value>
              )}
              <S.Value>{toCurrency(totalOrders)}</S.Value>
            </Row>
          </S.InnerRow>
          {shouldShowTaxBreakdown() && (
            <S.InnerRow>
              <S.Label>{t('tax')}</S.Label>
              <Row>
                {previousPayment.flag && previousPayment.totalTax !== totalTax && (
                  <S.Value className="old">{toCurrency(previousPayment.totalTax)}</S.Value>
                )}
                <S.Value>{toCurrency(totalTax)}</S.Value>
              </Row>
            </S.InnerRow>
          )}
        </div>
      </S.Row>
      <S.Row>
        <S.Text>{t('totalAmount')}</S.Text>
        <Row>
          {previousPayment.flag && previousPayment.totalAmount !== totalAmount && (
            <S.Total className="old">{toCurrency(previousPayment.totalAmount)}</S.Total>
          )}
          <S.Total className="amount">{toCurrency(totalAmount)}</S.Total>
        </Row>
      </S.Row>
      <S.Row>
        <div style={{ width: '100%' }}>
          <S.GridRow>
            <div>
              <S.Label>{t('paymentDate')}</S.Label>
              <S.Value>
                {orderDetail?.invoice?.paid_at ? moment(orderDetail.invoice.paid_at).format('DD/MM/YY') : '-'}
              </S.Value>
            </div>
            <ShowIf condition={shouldShowCreditInfo()}>
              <div>
                <S.Label>{t('dueDate')}</S.Label>
                <S.Value>
                  {orderDetail?.invoice?.invoiced_at
                    ? moment(orderDetail.invoice && orderDetail.invoice.invoiced_at)
                        .add(30, 'days')
                        .format('DD/MM/YY')
                    : '-'}
                </S.Value>
              </div>
            </ShowIf>
          </S.GridRow>
        </div>
      </S.Row>
    </S.Card>
  );
};

export const OrdersModalSummaryPayments = translate('orders')(OrdersModalSummaryPaymentsComponent);
