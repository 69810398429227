import { Product, ProductCategory } from 'store/reducers/Product';
import { TFunction } from 'utils/Localization';

export const getProductCategory = (categories: ProductCategory[] = []): string =>
  categories.find((c) => c.product_category.parent_path === null)?.product_category.name || '';

export const getProductSubCategories = (categories: ProductCategory[] = []): string =>
  categories
    .filter((c) => c.product_category.parent_path !== null)
    .map((c) => c.product_category.name)
    .join(', ');

export const getProductInformation = (
  t: TFunction,
  product: Product,
): Array<{
  id: string;
  title: string;
  content: string;
}> =>
  [
    {
      id: 'tour__prod_description',
      title: t('description'),
      content: product.description,
      isOpen: true,
    },
    {
      id: 'tour__prod_composition',
      title: t('composition'),
      content: product.molecule,
    },
    {
      id: 'tour__prod_coreMolecule',
      title: t('coreMolecule'),
      content: product?.core_molecule ?? '',
    },
    {
      id: 'tour__prod_category',
      title: t('category'),
      content: getProductCategory(product.categories),
    },
    {
      id: 'tour__prod_atc',
      title: t('atcClassification'),
      content: getProductSubCategories(product.categories),
    },
    {
      id: 'tour__prod_manufacturer',
      title: t('manufacturer'),
      content: product.manufacturer,
    },
  ].filter((e) => !!e.content);

export const initialProduct: Product = {
  id: 0,
  name: '',
  packaging: '',
  molecule: '',
  selling_price: 0,
  discount_rate: 0,
  display_photo: '',
  thumbnail_photo: '',
  sku_code: '',
  flag: null,
  net_price: 0,
  promoted: false,
  distributor: {
    id: 0,
    name: '',
    minimum_invoice_amount: 0,
    is_minimum_invoice_meet: false,
  },
  remaining_quantity: 0,
  quantity_threshold: 0,
  low_stock_threshold: 0,
  available: false,
  availability_requested: false,
  availability_request_id: 0,
  distributor_product_id: 0,
  favorited: false,
  favorite_product_id: 0,
  reminder_removal_date: '',
  tier_discount: [],
  description: '',
  images: {
    images: [],
  },
  categories: [],
  manufacturer: '',
  marketing_id: undefined,
};

export enum TabReferenceId {
  SUMMARY = 'summary',
  DETAILS = 'details',
  SHIPPING = 'shipping',
  RECOMMENDATION = 'recommendation',
}

export const defaultTabs = [
  { id: TabReferenceId.SUMMARY, offsetPosition: 0, elementHeight: 0, hidden: false },
  { id: TabReferenceId.DETAILS, offsetPosition: 0, elementHeight: 0, hidden: false },
  { id: TabReferenceId.SHIPPING, offsetPosition: 0, elementHeight: 0, hidden: false },
  { id: TabReferenceId.RECOMMENDATION, offsetPosition: 0, elementHeight: 0, hidden: true },
];

export enum DetailPageDisplayType {
  ROUTE = 'route',
  MODAL = 'modal',
}
