import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

export const KeywordLoader: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div>
    {new Array(6).fill({ loading: true }).map(() => (
      <Skeleton
        width={105}
        height={32}
        variant="rect" // can be rounded in newer version
        style={{ borderRadius: '32px', margin: '4px 8px 4px 4px', display: 'inline-block' }}
      />
    ))}
  </div>
);
