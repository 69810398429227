export const SETUP_FILTER = 'SETUP_FILTER';
export type SETUP_FILTER = typeof SETUP_FILTER;

export const SELECT_FILTER = 'SELECT_FILTER';
export type SELECT_FILTER = typeof SELECT_FILTER;

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export type CLEAR_FILTERS = typeof CLEAR_FILTERS;

export const ERROR_ON_FILTER = 'ERROR_ON_FILTER';
export type ERROR_ON_FILTER = typeof ERROR_ON_FILTER;

export const SET_SHOW_FILTER_SORT_MODAL = 'SET_SHOW_FILTER_SORT_MODAL';
export type SET_SHOW_FILTER_SORT_MODAL = typeof SET_SHOW_FILTER_SORT_MODAL;

export const SET_REINITIALIZE_FILTERS = 'SET_REINITIALIZE_FILTERS';
export type SET_REINITIALIZE_FILTERS = typeof SET_REINITIALIZE_FILTERS;

export const LABEL_VALUE_SEPARATOR = '-';
