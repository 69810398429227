import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import { TranslateProps } from '../../../../utils/Localization/types';

const IntroContainerContainer = styled(Grid)`
  .hooray {
    font-size: 16pt;
    font-weight: bolder;
    color: #269781;
  }
  .summary-text {
    font-size: 12px;
    width: 290px;
    padding: 8px 0 8px 0;
  }
  .detail-text {
    font-size: 12px;
    color: #666666;
    padding-top: 8px;
  }
`;

const IntroContainer: React.FC<React.PropsWithChildren<TranslateProps>> = (props) => {
  const { t } = props;

  return (
    <IntroContainerContainer container direction="column" alignItems="center">
      <Grid container direction="column">
        <Grid container direction="row" item>
          <Grid container justify="center" alignItems="flex-start" item direction="column">
            <Grid item>
              <Typography className="hooray">{t('hooray')}</Typography>
            </Grid>
            <Grid item>
              <Typography className="summary-text">{t('ordersSuccessfullySubmitted')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </IntroContainerContainer>
  );
};

export default IntroContainer;
