import { Action, Dispatch } from 'redux';

import { v3Client } from 'utils/Client/v3';

import { recordException } from 'utils/Reporting/Sentry';
import * as constants from './constants';
import { PharmacySearchSquareResDto } from './types';

export interface FetchCampaignSearchMarketing extends Action {
  type: constants.FETCH_CAMPAIGN_SEARCH_MARKETING_DATA;
}

export interface SetCampaignSearchMarketingData extends Action {
  type: constants.SET_CAMPAIGN_SEARCH_MARKETING_DATA;
  payload: PharmacySearchSquareResDto;
}

export type Actions = FetchCampaignSearchMarketing | SetCampaignSearchMarketingData;

export const fetchCampaignSearchMarketingData =
  (campaignBasedMarketingId: number) =>
  async (dispatch: Dispatch<Actions>, _getState): Promise<boolean> => {
    try {
      const resp = await v3Client.get<{ data: PharmacySearchSquareResDto }>(
        `pharmacy-squares/${campaignBasedMarketingId}`,
      );

      if (!resp) {
        throw new Error(`Campaigned based marketing data`);
      }

      dispatch({ type: constants.SET_CAMPAIGN_SEARCH_MARKETING_DATA, payload: resp.data });

      window.localStorage.setItem(constants.LOCAL_CAMPAIGN_SEARCH_MARKETING_DATA_KEY, JSON.stringify(resp.data));
      window.dispatchEvent(new Event('local-storage'));

      return true;
    } catch (error) {
      recordException(error, 'fetchCampaignSearchMarketingData');
      console.error('@Marketing:fetchCampaignSearchMarketingData:error', error);
      return false;
    }
  };
