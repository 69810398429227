import React from 'react';
import styled from 'styled-components';
import { IconButton, Grid } from '@material-ui/core';
import LogoSwipeRXSmall from 'images/logo_swiperx_s.svg';

const Container = styled(Grid)`
  margin: 0px 5px;
  height: 100%;

  .logo_button {
    width: 45px;
    height: 45px;
    padding: 0px;
    margin: -5px 0px;
  }
`;

const Icon = styled.img`
  && {
    margin: auto;
    width: 68%;
    height: 68%;
  }
`;

interface Props {
  onCLick?: (event?: any) => void;
}

export const LogoSmall: React.FC<React.PropsWithChildren<Props>> = ({ onCLick }) => (
  <Container>
    <IconButton className="logo_button" onClick={onCLick}>
      <Icon src={LogoSwipeRXSmall} />
    </IconButton>
  </Container>
);
