/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';

import { useToggle } from 'hooks';
import { translate } from 'react-i18next';

import { PhotoSwipe } from 'components/common';
import ShowIf from 'components/views/ShowIf';
import { OrderStatus } from 'services/swipe-rx-pt/resources/orders/types';
import { ImageDimension } from 'utils/helpers';
import { TranslateProps } from 'utils/Localization';

import { OrderStatusModalChanges } from './orders-status-modal-changes.component';
import { S } from './orders-status-modal-history.styles';
import { OrderStatusHistory, useOrderStatusHistory } from './use-order-status-history.hook';

interface Props {
  openChat: () => void;
  closeModal: () => void;
}

const Component: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ t, openChat, closeModal }) => {
  const {
    orderStatusHistories,
    getPrimaryStatusHistoryTranslate,
    getSecondaryStatusHistoryTranslate,
    getStatusHistoryDate,
    shouldShowOnce,
    hasDeliveryPhoto,
  } = useOrderStatusHistory(t);

  const { isOpen: isOrdersOpen, toggle: toggleOrdersModal, closeHandler: closeOrdersModal } = useToggle();

  const [photoItems, setPhotoItems] = useState<ImageDimension[]>([]);

  const openPhotos = (orderStatusHistory: OrderStatusHistory): void => {
    const { photos = [], photosWithDimension = [] } = orderStatusHistory;
    if (photosWithDimension.length) {
      setPhotoItems(photosWithDimension.map((photo) => ({ ...photo })));
    } else if (photos.length) {
      setPhotoItems(
        photos.map((photo) => ({
          src: photo,
          w: 800,
          h: 800,
        })),
      );
    }
  };
  const closePhotos = (): void => setPhotoItems([]);

  const shouldShowOrderChanges = (status: OrderStatus): boolean => status === OrderStatus.ORDER_CHANGED;

  const showDetails = (status: OrderStatus): void => {
    if (status !== OrderStatus.CANCELLED) return;
    closeModal();
  };

  return (
    <>
      <S.Card>
        {orderStatusHistories.map((item, index) => (
          <ShowIf condition={shouldShowOnce(item.status as OrderStatus)} key={item.id}>
            <S.DetailContainer status={item.status} className={index === 0 ? 'active' : ''}>
              <S.DetailHeader>
                <S.Date>{getStatusHistoryDate(item.date, 'dddd, DD MMMM YYYY')}</S.Date>
                <S.Time>{getStatusHistoryDate(item.date, 'HH:mm')}</S.Time>
              </S.DetailHeader>
              <S.Informations className="informations">
                <S.Text className="title">{getPrimaryStatusHistoryTranslate(item)}</S.Text>
                <S.Text
                  onClick={() => showDetails(item.status as OrderStatus)}
                  dangerouslySetInnerHTML={{ __html: getSecondaryStatusHistoryTranslate(item) }}
                />
                <ShowIf condition={shouldShowOrderChanges(item.status as OrderStatus)}>
                  <S.ButtonDetail onClick={toggleOrdersModal} fullWidth>
                    {t('moreDetails')}
                  </S.ButtonDetail>
                </ShowIf>
                <ShowIf condition={hasDeliveryPhoto(item)}>
                  <S.ButtonDetail onClick={() => openPhotos(item)} fullWidth>
                    {t('orderStatusDeliveryProof')}
                  </S.ButtonDetail>
                </ShowIf>
              </S.Informations>
            </S.DetailContainer>
          </ShowIf>
        ))}
      </S.Card>
      <OrderStatusModalChanges open={isOrdersOpen} onClose={closeOrdersModal} openChat={openChat} />
      <PhotoSwipe
        isOpen={photoItems.length > 0}
        items={photoItems}
        onClose={closePhotos}
        options={{
          closeOnScroll: false,
          zoomEl: false,
          fullscreenEl: false,
          modal: false,
          closeOnVerticalDrag: false,
          shareEl: false,
          arrowEl: true,
          tapToClose: true,
        }}
      />
    </>
  );
};

export const OrderStatusModalHistory = translate('orders')(Component);
