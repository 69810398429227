import React from 'react';
import { Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser } from 'hooks';
import { emptyCart } from 'store/reducers/Cart/actions';
import { TranslateProps } from 'utils/Localization';
import { ClearCartDialogStyles } from './ClearCartDialog.styles';

interface Props {
  open: boolean;
  itemCount: number;
  onCancel: () => void;
}

export const ClearCartDialog: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ open, itemCount, onCancel, t }) => {
  const {
    coreUser: { organization_id },
  } = useGetCurrentUser();
  const dispatch = useDispatch();

  const handleClose = (): void => {
    onCancel();
  };

  const handleConfirm = (): void => {
    dispatch(emptyCart(organization_id));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      open={open}
      PaperProps={{ style: { padding: '1.2rem', borderRadius: '20px' } }}
      disableBackdropClick
    >
      <ClearCartDialogStyles.DialogTitleWrapper disableTypography>
        <ClearCartDialogStyles.DialogTitle>{t('clearCart.title')}</ClearCartDialogStyles.DialogTitle>
        <ClearCartDialogStyles.CloseButtonWrapper data-testid="close-clear-cart-btn" onClick={handleClose}>
          <ClearCartDialogStyles.CloseButton />
        </ClearCartDialogStyles.CloseButtonWrapper>
      </ClearCartDialogStyles.DialogTitleWrapper>
      <ClearCartDialogStyles.DialogContent>
        <ClearCartDialogStyles.DialogText>{t('clearCart.content', { itemCount })}</ClearCartDialogStyles.DialogText>
      </ClearCartDialogStyles.DialogContent>
      <ClearCartDialogStyles.DialogActions>
        <ClearCartDialogStyles.CancelButton data-testid="cancel-clear-cart-btn" autoFocus onClick={handleClose}>
          {t('clearCart.no')}
        </ClearCartDialogStyles.CancelButton>
        <ClearCartDialogStyles.DeleteButton data-testid="confirm-clear-cart-btn" onClick={handleConfirm}>
          {t('clearCart.delete')}
        </ClearCartDialogStyles.DeleteButton>
      </ClearCartDialogStyles.DialogActions>
    </Dialog>
  );
};
