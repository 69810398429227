import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { trackSimilarProductListPage } from 'utils/Analytics/Segment';

interface IUseSimilarParams {
  isSimilarProduct: boolean;
  loading: boolean;
  totalCount: number;
}

export const useSimilarProductAnalytic = (params: IUseSimilarParams): void => {
  const { isSimilarProduct, totalCount, loading } = params;

  const location = useLocation();
  const match = useRouteMatch<any>();
  const { sku_code: skuCode } = match?.params || {};
  const initialPageState = (location?.state as any)?.initial_page;

  const hasProducts = !!totalCount;
  const isEmpty = !totalCount && !loading;

  useEffect(() => {
    if (!isSimilarProduct) return;

    if ((hasProducts || isEmpty) && !loading) {
      trackSimilarProductListPage({
        result_count: totalCount || 0,
        from_page: initialPageState,
        referrer_sku_code: skuCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasProducts, isEmpty]);
};
