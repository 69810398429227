import * as Redux from 'redux';
import { ISearchSection } from 'components/common/SearchMenuSuggestions/components/interface';
import * as constants from './constants';

import { Actions } from './interface';
import { Product } from '../Product';

export interface State {
  menuDrawerOpen: boolean;
  query: string;
  previousQuery: string;
  suggestions: ISearchSection[];
  respondAt: Date | null;
  respondTime: number;
  recentlyViewedSectionProducts: Array<Product>;
  loading: boolean;
}

const initialState: State = {
  menuDrawerOpen: false,
  query: '',
  previousQuery: '',
  suggestions: [],
  respondAt: null,
  respondTime: 0,
  recentlyViewedSectionProducts: [],
  loading: false,
};

const SearchSuggestionsReducer: Redux.Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case constants.SEARCH_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
        query: action.query,
        previousQuery: state.query,
        loading: action.loading,
      };
    case constants.CLEAR_SUGGESTIONS:
      return {
        ...state,
        query: '',
        previousQuery: state.query,
        suggestions: [],
        respondAt: null,
        respondTime: 0,
        loading: false,
      };
    case constants.SET_MENU_DRAWER:
      return {
        ...state,
        menuDrawerOpen: action.menuDrawerOpen,
      };
    case constants.SET_RECENTLY_VIEWED_SECTION_PRODUCTS:
      return {
        ...state,
        recentlyViewedSectionProducts: action.products,
      };
    case constants.SET_SEARCH_SUGGESTION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default SearchSuggestionsReducer;
