import { Product } from 'store/reducers/Product';
import { trackTierPricing } from 'utils/Analytics/Segment';

export const setTrackTierPricing = (product: Product): void => {
  const tier_discount = product?.tier_discount.map((el) => ({
    id: el.id,
    distributor: el.distributor,
    price: el.price,
    selling_price: el.selling_price,
  }));
  const tierPricingData = { product_id: product.id, name: product.name, tier_discount };
  trackTierPricing(tierPricingData);
};
