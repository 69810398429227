import qs from 'query-string';
import { LoyaltyTierName } from 'store/reducers/loyalty-program';

// Purchase order statuses
export const ORDER_STATUS_PENDING = 'pending';
export const ORDER_STATUS_ACCEPTED = 'accepted';
export const ORDER_STATUS_DELIVERED = 'delivered';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_CANCELLED = 'cancelled';

// Invoice statuses
export const INVOICE_STATUS_PENDING = 'pending';
export const INVOICE_STATUS_INVOICED = 'invoiced';
export const INVOICE_STATUS_DELIVERED = 'delivered';
export const INVOICE_STATUS_CLAIMED = 'claimed';
export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_CANCELLED = 'cancelled';

// Product Change types
export const PRICE_DECREASED = 'price_decreased';
export const PRICE_INCREASED = 'price_increase';
export const PRODUCT_UNAVAILABLE = 'unavailable';
export const MEMBERSHIP_CHANGED = 'membership_changed';
export const SIPNAP_REQUIRED = 'sipnap_required';

// Deposit statuses
export const DEPOSIT_STATUS_APPROVED = 'approved';

export const LOYALTY_PROGRAM_URL = 'https://belanja.swiperxapp.com/swiperx-pass-rewards-q3/';

export enum OrderStatus {
  DELIVERY = 'delivery',
  ORDER_RECEIVED = 'order received',
  ACCEPTED = 'accepted',
}

export enum PaymentMethods {
  CREDIT_LINE = 'CREDIT_LINE',
  DIRECT_PAYMENT = 'DIRECT_PAYMENT',
}

export enum SuppportedMarkets {
  ID = 'id',
  TH = 'th',
}

export enum LoyaltyRewardVendors {
  MP = 'mobile-pulsa',
}

export const LoyaltyRewardErrorCodes = ['E', 'OL', 'MP'];

export const LoyaltyLevels = [
  LoyaltyTierName.BRONZE,
  LoyaltyTierName.SILVER,
  LoyaltyTierName.GOLD,
  LoyaltyTierName.PLATINUM,
  LoyaltyTierName.TITANIUM,
  LoyaltyTierName.SPECIALCASHBACK,
];

export enum PaymentProvider {
  DIGIO = 'DIGIO',
  DOKU = 'DOKU',
}

export const DEFAULT_MARKET_MINIMUM_INVOICE_AMOUNT = 250000;

export const CHARACTER = {
  TILDE: '~',
};

export const DEFAULT_MAX_SEARCH_INPUT_LENGTH = 131;

export const AllZIndexes = {
  FilterTabTour: 99,
  FilterSortModal: 100,
  ScrollableFilterSortModal: 200,
  FilterSortButton: 10,
  BottomNav: 10,
  FilterSortModalTour: 20,
};

export const BRACKET_QS_OPTIONS: qs.StringifyOptions = {
  encode: true,
  sort: false,
  arrayFormat: 'bracket',
  skipNull: true,
};

export enum NavigationAction {
  DEFAULT = 'DEFAULT',
  PUSH = 'PUSH',
  POP = 'POP',
  REPLACE = 'REPLACE',
}

export const DEFAULT_MARKETING_LIST_PAGE_SIZE = 10;
