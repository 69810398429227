export const FETCH_AVAILABLE_VOUCHER = 'SET_AVAILABLE_VOUCHER';
export type FETCH_AVAILABLE_VOUCHER = typeof FETCH_AVAILABLE_VOUCHER;

export const FETCH_AVAILABLE_VOUCHER_LOADING = 'FETCH_AVAILABLE_VOUCHER__LOADING';
export type FETCH_AVAILABLE_VOUCHER_LOADING = typeof FETCH_AVAILABLE_VOUCHER_LOADING;

export const FETCH_AVAILABLE_VOUCHER_FAILED = 'FETCH_AVAILABLE_VOUCHER_FAILED';
export type FETCH_AVAILABLE_VOUCHER_FAILED = typeof FETCH_AVAILABLE_VOUCHER_FAILED;

export const SELECTED_VOUCHER_ELIGIBILE = 'SELECTED_VOUCHER_ELIGIBILE';
export type SELECTED_VOUCHER_ELIGIBILE = typeof SELECTED_VOUCHER_ELIGIBILE;

export const SELECTED_VOUCHER_ELIGIBILITY_FAILED = 'SELECTED_VOUCHER_ELIGIBILITY_FAILED';
export type SELECTED_VOUCHER_ELIGIBILITY_FAILED = typeof SELECTED_VOUCHER_ELIGIBILITY_FAILED;

export const SET_SELECTED_VOUCHER = 'SET_SELECTED_VOUCHER';
export type SET_SELECTED_VOUCHER = typeof SET_SELECTED_VOUCHER;

export const CLEAR_SELECTED_VOUCHER = 'CLEAR_SELECTED_VOUCHER';
export type CLEAR_SELECTED_VOUCHER = typeof CLEAR_SELECTED_VOUCHER;

export const APPLY_VOUCHER = 'APPLY_VOUCHER';
export type APPLY_VOUCHER = typeof APPLY_VOUCHER;

export const APPLY_VOUCHER_FAILED = 'APPLY_VOUCHER_FAILED';
export type APPLY_VOUCHER_FAILED = typeof APPLY_VOUCHER_FAILED;

export const CLEAR_APPLIED_VOUCHER = 'CLEAR_APPLIED_VOUCHER';
export type CLEAR_APPLIED_VOUCHER = typeof CLEAR_APPLIED_VOUCHER;
