import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

import { LOYALTY_POINT } from 'components/styles/colors';

const LoyaltyPointText = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 900;
    font-style: italic;
    line-height: 15px;
    padding-right: 6px;
  }
`;

const LoyaltyPointRibbon = styled(({ ...props }) => <Grid {...props} />)`
  && {
    width: auto;
    bottom: 0;
    left: 0;
    padding: 6.5px 6px;
    z-index: 5;
    position: absolute;
    border-top-right-radius: 8px;
    background-color: ${(props) => (props.multiple ? LOYALTY_POINT.secondary.light : LOYALTY_POINT.light)};

    ${LoyaltyPointText} {
      color: ${(props) => (props.multiple ? LOYALTY_POINT.secondary.dark : LOYALTY_POINT.dark)};
    }
  }
`;

export const S = {
  LoyaltyPointRibbon,
  LoyaltyPointText,
};
