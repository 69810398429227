import React, { MouseEventHandler } from 'react';

import IconButton from '@material-ui/core/IconButton';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { Badge } from '@material-ui/core';

import { getCustomerSuccessSupport } from 'utils/MarketConfig';
import { useGetCurrentUser } from 'hooks';
import { trackContactCustomerCare } from 'utils/Analytics/Segment';

interface Props {
  // eslint-disable-next-line react/require-default-props
  color?: string;
}

const ChatBubbleBase = ({ color }: Props) => {
  const { link: chatLink } = getCustomerSuccessSupport();
  const {
    coreUser: { organization_id: pharmacyId },
  } = useGetCurrentUser();

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    trackContactCustomerCare(pharmacyId);
    window.location.href = chatLink;
  };

  return (
    <IconButton onClick={onClick} data-testid="chat-icon-component-button" style={{ padding: 0 }} id="tour__appChat">
      <Badge id="chat-badge" color="secondary" data-testid="chat-bubble-component-badge">
        <HeadsetMicIcon
          data-testid="chat-bubble-component-icon"
          style={{
            color: color || '#fff',
            width: '0.95em',
            height: '0.95em',
          }}
        />
      </Badge>
    </IconButton>
  );
};

export const ChatBubble = ChatBubbleBase;
