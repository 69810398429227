import React from 'react';

import { Img } from 'components/pages/ProductDetailPage/components/ProductImage/style';

import { SkeletonLoader } from 'components/common/skeleton-loader';
import { S } from './search-sections.style';

export const IconedRowLoader = () => (
  <div>
    {new Array(2).fill({ loading: true }).map(
      (_): JSX.Element => (
        <div style={{ position: 'relative' }}>
          <S.Item customPadding="4rem" marginRight="42px">
            <S.ImageWrapper isEmpty="">
              <Img alt="" />
            </S.ImageWrapper>

            <SkeletonLoader type="heading" variant="lg" width={350} height={35} />
          </S.Item>
        </div>
      ),
    )}
  </div>
);
