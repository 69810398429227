import { SwipeRxPt } from '../../SwipeRxPt.class';

import { PaymentDirectList, PaymentDirectListParams, PaymentDirect } from './interfaces';

export class SwipeRxPtPaymentDirect {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  list(params?: PaymentDirectListParams): Promise<PaymentDirectList> {
    return this.base.get('/payment', params);
  }

  retrieve(id: number): Promise<PaymentDirect> {
    return this.base.get(`/payment/${id}`);
  }
}
