import { Reducer } from 'redux';
import { Actions } from './interface';
import * as constants from './constants';

export interface State {
  listing: Array<string | number>;
  carousel: {
    [x: string]: Array<string | number>;
  };
  productDetailCarousel: {
    [x: string]: Array<string | number>;
  };
}

const initialState: State = {
  listing: [],
  carousel: {},
  productDetailCarousel: {},
};

const ProductImpressionReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_PRODUCT_IMPRESSION_STATE:
      return {
        ...state,
        listing: action.listing || [],
        carousel: action.carousel || {},
        productDetailCarousel: action.productDetailCarousel,
      };

    case constants.CLEAR_PRODUCT_IMPRESSION:
      return {
        ...state,
        ...initialState,
      };

    case constants.CLEAR_PRODUCT_DETAIL_IMPRESSION:
      return {
        ...state,
        productDetailCarousel: {},
      };

    default:
      return state;
  }
};

export default ProductImpressionReducer;
