import React from 'react';
import { translate } from 'react-i18next';

import { toCurrency } from 'utils/Format';
import { OrderItem } from 'services/swipe-rx-pt/resources/orders/interfaces';

import { S } from './orders-modal-products.styles';

interface Props {
  orderItem: OrderItem;
  t: any;
}
const OrdersModalComponent: React.FC<React.PropsWithChildren<Props>> = ({
  orderItem: {
    quantity,
    selling_price,
    net_price,
    discount_rate,
    previous_discount_rate,
    previous_selling_price,
    previous_quantity,
    product,
    is_out_of_stock,
  },
  t,
}) => {
  const toRate = (rate: number): number => Number((rate * 100).toFixed(1));

  const isChanged =
    previous_discount_rate !== null || previous_selling_price !== null || previous_quantity !== null || is_out_of_stock;

  const weight = is_out_of_stock ? 0 : 1;

  const finalPrice = net_price;
  const subTotalPrice = quantity * selling_price * weight;
  const totalPrice = quantity * net_price * weight;
  const discountRate = toRate(discount_rate);

  const previousSellingPrice = previous_selling_price ?? selling_price;
  const previousQuantity = previous_quantity ?? quantity;
  const previousDiscount = previous_discount_rate ?? discount_rate;
  const previousDiscountRate = toRate(previousDiscount);
  const previousFinalPrice = previousSellingPrice - previousSellingPrice * previousDiscount;

  return (
    <S.Card>
      {/* product detail */}
      <S.ProductDetail>
        <div className="product-detail">
          <S.ProductNameContainer>
            <S.ProductName>{product.name}</S.ProductName>
            <S.Package>{product.package}</S.Package>
          </S.ProductNameContainer>
          {isChanged && (
            <>
              <ProductDetail
                isPrevious
                price={previousSellingPrice}
                quantity={previousQuantity}
                discount={previousDiscountRate}
                finalPrice={previousFinalPrice}
                t={t}
              />
            </>
          )}
          {isChanged && (
            <S.ProductAttr>
              <S.ChangedTitle>{t('orderChanged')}</S.ChangedTitle>
            </S.ProductAttr>
          )}
          <ProductDetail
            price={selling_price}
            quantity={quantity}
            discount={discountRate}
            finalPrice={finalPrice}
            isOutOfStock={is_out_of_stock}
            t={t}
          />
        </div>
      </S.ProductDetail>
      {/* sub total */}
      <S.Row>
        <S.Text>{t('subTotal')}</S.Text>
        <S.ProductAttr>
          <S.Text>{toCurrency(subTotalPrice)}</S.Text>
        </S.ProductAttr>
      </S.Row>
      {/* total */}
      <S.Row>
        <S.Text>{t('total')}</S.Text>
        <S.ProductAttr>
          <S.Text>{toCurrency(totalPrice)}</S.Text>
        </S.ProductAttr>
      </S.Row>
    </S.Card>
  );
};

const ProductDetail: React.FC<React.PropsWithChildren<{
  price: number;
  quantity: number;
  discount: number;
  finalPrice: number;
  // eslint-disable-next-line react/require-default-props
  isPrevious?: boolean | null;
  // eslint-disable-next-line react/require-default-props
  isOutOfStock?: boolean | null;
  t: any;
}>> = ({ price, quantity, discount, finalPrice, isPrevious = null, isOutOfStock = null, t }) => (
  <>
    <S.ProductAttr>
      <S.Text className={`light-text${isPrevious ? ' old' : ''}`}>{t('hna')}</S.Text>
      <S.Text className={`light-text${isPrevious ? ' old' : ''}`}>
        {toCurrency(price)}
        <span className="unit"> / {t('unit')}</span>
      </S.Text>
    </S.ProductAttr>
    <S.ProductAttr>
      <S.Text className={`light-text${isPrevious ? ' old' : ''}`}>
        {t('discount')} ({quantity} {t('units')})
      </S.Text>
      <S.Discount className={isPrevious ? 'old' : ''}>-{discount}%</S.Discount>
    </S.ProductAttr>
    <S.ProductAttr>
      <S.Text className={`light-text${isPrevious ? ' old' : ''}`}>Final Price</S.Text>
      <S.Text className={`light-text${isPrevious ? ' old' : ''}`}>
        {toCurrency(finalPrice)}
        <span className="unit"> / {t('unit')}</span>
      </S.Text>
    </S.ProductAttr>
    {isOutOfStock && <S.OutOfStock>{t('outOfStock')}</S.OutOfStock>}
  </>
);

export const OrdersModalProducts = translate('orders')(OrdersModalComponent);
