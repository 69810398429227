import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Layout from '../../views/Layout';
import AppNav from '../../common/AppNav/component';
import Container from '../../views/Container';
import Content from '../../views/Content';
import * as colors from '../../styles/colors';
import ChatSVG from '../../../images/ic-chat-support.svg';
import { TranslateProps } from '../../../utils/Localization/types';

export interface OwnProps extends RouteComponentProps<any> {}

const HelpContainer = styled(Container)`
  .title {
    font-size: 28pt;
    font-weight: 300;
    line-height: 32pt;
    color: ${colors.DARK_GRAY};
    margin: 24px;

    .icon {
      width: 64px;
      height: auto;
      margin-bottom: 16px;
    }

    div {
      text-align: center;
    }

    small {
      font-size: 22pt;
      margin-top: 8px;
      display: block;
      font-weight: 400;
    }
  }

  .info {
    padding: 24px;
    background: ${colors.WHITE};
    font-size: 10pt;
    color: ${colors.MEDIUM_GRAY};

    .text {
      margin-bottom: 24px;
    }

    .contact-button {
      background: ${colors.SECONDARY_COLOR};
      color: ${colors.DARK_SECONDARY_COLOR};
    }
  }
`;

const HelpPage: React.FC<React.PropsWithChildren<OwnProps & TranslateProps>> = (props) => {
  const { history, t } = props;

  const goTo = (): void => {
    history.goBack();
  };

  return (
    <Layout full>
      <Container>
        <AppNav title={t('title')} onBack={goTo} removeToolbar />
        <Content>
          <HelpContainer>
            <Typography className="title">
              <Grid container direction="column" alignItems="center">
                <img className="icon" src={ChatSVG} alt="chat icon" />
                <div>{t('helpOrdering')}</div>
                <small>{t('chatWithUs')}</small>
              </Grid>
            </Typography>
            <Content className="info">
              <Typography component="div" className="text">
                {t('gladToHelp')}
              </Typography>
            </Content>
          </HelpContainer>
        </Content>
      </Container>
    </Layout>
  );
};

export default HelpPage;
