/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ActivityCard } from 'components/common/activity-card';
import { ActivityCardLoader } from 'components/common/activity-card/activity-card-loader.component';
import { useGetCurrentUser, useToggle } from 'hooks';
import { ActivityStatus } from 'services';
import { State as StoreState } from 'store';
import { fetchPurchaseOrderDetails, updatePurchaseOrderActivityOnRead } from 'store/reducers';
import { toggleOrderModal } from 'store/reducers/Orders/actions';
import { trackContactCustomerCare } from 'utils/Analytics/Segment';
import { mapOrderStatus } from 'utils/helpers';
import { getCustomerSuccessSupport } from 'utils/MarketConfig';

import { OrdersModal } from '../orders-modal';
import { OrderStatusModalChanges } from '../orders-status-modal/orders-status-modal-changes.component';

import { useOrdersActivity } from './use-orders-activity.hook';

const ActivityContainer = styled.div`
  && {
    padding-bottom: 46px;
  }
`;

export const OrdersActivityContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    activities: { data },
    loading,
  } = useOrdersActivity();
  const {
    coreUser: { organization_id: pharmacyId },
  } = useGetCurrentUser();

  const dispatch = useDispatch();

  const {
    orders: {
      orderModal: { isOpen: isOrdersOpen },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const toggleOrdersModal = (): void => {
    dispatch(toggleOrderModal(!isOrdersOpen));
  };

  const closeOrdersModal = (): void => {
    dispatch(toggleOrderModal(false));
  };

  const {
    isOpen: isOrdersChangeOpen,
    toggle: toggleOrdersChangeModal,
    closeHandler: closeOrdersChangeModal,
  } = useToggle();

  const customerSuccessSupport = getCustomerSuccessSupport();

  const openChat = (): void => {
    trackContactCustomerCare(pharmacyId);

    window.open(customerSuccessSupport.link, customerSuccessSupport.target);
  };

  const openOrderDetailsModal = useCallback((orderId: number, status: string, read: boolean) => {
    if (status === ActivityStatus.ORDER_CHANGED) {
      toggleOrdersChangeModal();
    } else {
      toggleOrdersModal();
    }

    dispatch(fetchPurchaseOrderDetails(orderId));

    if (!read) dispatch(updatePurchaseOrderActivityOnRead(orderId, status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ActivityContainer>
      {loading ? (
        <ActivityCardLoader />
      ) : (
        data.map(({ id, po_number, orderStatus, fulfillmentStatus, activityStatus, dateTime, read }) => {
          const mappedStatus = mapOrderStatus({ status: orderStatus, fulfillmentStatus });

          return (
            <ActivityCard
              data-testid={`activity-card-${po_number}-${activityStatus}`}
              po_number={po_number}
              status={mappedStatus}
              onClick={() => openOrderDetailsModal(id, activityStatus, !!read)}
              dateTime={dateTime}
              activityStatus={activityStatus}
              isRead={!!read}
            />
          );
        })
      )}
      <OrdersModal open={isOrdersOpen} onClose={closeOrdersModal} />
      <OrderStatusModalChanges open={isOrdersChangeOpen} onClose={closeOrdersChangeModal} openChat={openChat} />
    </ActivityContainer>
  );
};
