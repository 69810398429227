import numeral from 'numeral';
import moment from 'moment';

import { toMarketPaymentWiseAmount } from 'utils/MarketConfig';

interface ToCurrencyOptions {
  enableRounding?: boolean;
  format?: string;
}

export const getFlooredFixed = (value: number, decimals: number): string =>
  (Math.floor(value * 10 ** decimals) / 10 ** decimals).toFixed(decimals);

export const getCeiledFixed = (value: number, decimals: number): string =>
  (Math.ceil(value * 10 ** decimals) / 10 ** decimals).toFixed(decimals);

export const getStrFixed = (value: string, decimals: number): string => Number.parseFloat(value).toFixed(decimals);

export const toCurrency = (value: number, options: ToCurrencyOptions = {}): string => {
  const { enableRounding = true, format = '$ 0,0' } = options;

  if (enableRounding) return numeral(getCeiledFixed(value, 0)).format(format);
  return numeral(value).format(format);
};

export const toPercent = (value: number, decimals: number): number => {
  // round off percentage value to one decimal. ex 5.06 = 5.1
  const num = value * 100;
  const result = +Number(num).toFixed(decimals);
  return result;
};

export const toInt = (value: number): number => Math.floor(value);

export const toIntThousand = (value: number): string => numeral(toInt(value)).format('0,0[.]00');

export const toDate = (value: moment.MomentInput, format = 'D MMMM, YYYY'): string => moment(value).format(format);

export const toMonthDateFormat = (value: moment.MomentInput): string => moment(value).format('ll');

export const capitalizeFirstChar = (str: string): string =>
  str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const accumulateAmountBeforeTax = (amountsBeforeTax: number[], taxRate: number): number =>
  amountsBeforeTax.reduce((accumulation, amountBeforeTax) => {
    const amount = toMarketPaymentWiseAmount(amountBeforeTax);
    const total = toMarketPaymentWiseAmount(amount * (1 + taxRate));
    return accumulation + toMarketPaymentWiseAmount(total / (1 + taxRate), 'floor');
  }, 0);

export const accumulateTax = (amountsBeforeTax: number[], taxRate: number): number =>
  amountsBeforeTax.reduce((accumulation, amountBeforeTax) => {
    const amount = toMarketPaymentWiseAmount(amountBeforeTax);
    const total = toMarketPaymentWiseAmount(amount * (1 + taxRate));
    return accumulation + toMarketPaymentWiseAmount((total * taxRate) / (1 + taxRate), 'ceil');
  }, 0);

export const accumulateAmountAfterTax = (amountsBeforeTax: number[], taxRate: number): number =>
  amountsBeforeTax.reduce((accumulation, amountBeforeTax) => {
    const amount = toMarketPaymentWiseAmount(amountBeforeTax);
    const total = toMarketPaymentWiseAmount(amount * (1 + taxRate));
    return accumulation + total;
  }, 0);

export const calculateAmountBeforeTax = (net_amount: number, tax_rate: number): number => {
  const total = toMarketPaymentWiseAmount(net_amount * (1 + tax_rate));
  return toMarketPaymentWiseAmount(total / (1 + tax_rate), 'floor');
};

export const calculateTax = (net_amount: number, tax_rate: number): number => {
  const total = toMarketPaymentWiseAmount(net_amount * (1 + tax_rate));
  return toMarketPaymentWiseAmount((total * tax_rate) / (1 + tax_rate), 'ceil');
};

export const calculateAmountAfterTax = (net_amount: number, tax_rate: number): number => {
  const total = toMarketPaymentWiseAmount(net_amount * (1 + tax_rate));
  return total;
};
