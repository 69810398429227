import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { prepareContentForCss } from './widget.util';

export const CarouselContaier = styled.div<{ noPadding?: boolean }>`
  position: relative;
  ${({ noPadding }) => (noPadding ? 'padding: 0 0 8px 0;' : 'padding: 0 12px 8px 12px;')}
`;

export const Title = styled.div<any>`
  font-size: 18px;
  font-weight: 700;
  vertical-align: sub;
  padding-bottom: 20px;
  cursor: pointer;

  &::before {
    position: absolute;
    padding: 12px 16px;
    left: 0;
    right: 0;
    height: 80px;
    content: '${(props) => prepareContentForCss(props.content)}';
    background: ${(props) => props.background};
    color: ${(props) => props.color};
  }
`;

export const Navigator = styled(Button).attrs({
  color: 'primary',
})`
  && {
    padding-right: 4px;
  }
`;

export const Divider = styled.div`
  height: 32px;
`;
