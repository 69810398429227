import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { withStyles, Theme, StyledComponentProps, StyleRules } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export interface ChildPageAppBarProps {
  backURL: string;
  title: string;
}

type Props = ChildPageAppBarProps & StyledComponentProps & RouteComponentProps;

const styles = (theme: Theme): StyleRules => ({
  root: {
    boxShadow: theme.shadows[0],
    color: '#fff',
  },
  title: {
    flexGrow: 1,
    fontWeight: 800,
  },
});

const CWithStylesBase: React.FC<React.PropsWithChildren<Props>> = ({ classes = {}, backURL, history, location, title }) => {
  const handleBackClick = (): void => {
    history.push({
      pathname: backURL,
      search: location.search,
    });
  };

  return (
    <AppBar className={classes.root} position="sticky">
      <Toolbar>
        <IconButton aria-label="menu" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color="inherit" variant="h6" className={classes.title}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const CWithStyles = withStyles(styles)(CWithStylesBase);

export const ChildPageAppBar = withRouter(CWithStyles);
