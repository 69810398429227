import React from 'react';

import { GradientCard, GradientCardProps } from '../../GradientCard.component';
import { S } from './ButtonGradientCard.style';

export interface Props extends GradientCardProps {
  text: string;
}

export const ButtonGradientCard: React.FC<React.PropsWithChildren<Props>> = ({ color, bgImage, onClick, text }) => (
  <GradientCard color={color} bgImage={bgImage} onClick={onClick}>
    <S.Button focusRipple>{text}</S.Button>
  </GradientCard>
);
