import React from 'react';
import { ButtonBase } from '@material-ui/core';

import { Product } from 'store/reducers/Product';
import keyBy from 'lodash/keyBy';
import { TourIds } from '../../ProductCard.interface';
import { S } from './ProductCardImage.styles';

interface Props {
  product: Product;
  tourIds: TourIds;
  isStockOut: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ProductCardImage: React.FC<React.PropsWithChildren<Props>> = ({ children, product, tourIds, isStockOut, onClick }) => {
  const { images: imgColumn, display_photo, thumbnail_photo } = product;
  const { images } = imgColumn || {};
  const { original, display, thumb } = keyBy(images, ({ version_name }) => version_name);
  const thumbnailImage = thumb?.url || thumbnail_photo || display?.url || display_photo || original?.url || undefined;

  return (
    <S.ImageContainer>
      <ButtonBase>
        <S.Image
          onClick={onClick}
          isEmpty={!!thumbnailImage}
          isStockOut={isStockOut}
          src={thumbnailImage}
          id={tourIds.imageId}
          isUnpurchasable={product?.is_unpurchasable}
        />
        {children}
      </ButtonBase>
    </S.ImageContainer>
  );
};
