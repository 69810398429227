import { Action } from 'redux';
import * as constants from './constants';
import { Product } from '../Product';

export enum SimilarProductLocation {
  SEARCH_PAGE = 'search-page',
  DETAIL_PAGE = 'detail-page',
}

export interface GetSimilarProductCarouselAction extends Action {
  type: constants.GET_SIMILAR_PRODUCT_CAROUSEL;
  products: Product[];
  location: SimilarProductLocation;
  skuCode: string;
  loading?: boolean;
  marketingId: number;
}
export interface ClearSimilarProductCarouselAction extends Action {
  type: constants.CLEAR_SIMILAR_PRODUCT_CAROUSEL;
  location: SimilarProductLocation;
}

export interface SetSimilarProductCarouselLoadingAction extends Action {
  type: constants.SET_SIMILAR_PRODUCT_CAROUSEL_LOADING;
  loading: boolean;
  location: SimilarProductLocation;
}
export interface SetSimilarProductProcessedIdsAction extends Action {
  type: constants.SET_SIMILAR_PRODUCT_PROCESSED_IDS;
  ids: string[];
}
export interface ClearSimilarProductProcessedIdsAction extends Action {
  type: constants.CLEAR_SIMILAR_PRODUCT_PROCESSED_IDS;
}

export type Actions =
  | GetSimilarProductCarouselAction
  | ClearSimilarProductCarouselAction
  | SetSimilarProductCarouselLoadingAction
  | SetSimilarProductProcessedIdsAction
  | ClearSimilarProductProcessedIdsAction;
