import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as colors from 'components/styles/colors';

const Container = styled(Grid)`
  height: 100%;
  position: relative;
  padding-top: 10px;
`;

const SearchContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
`;

const DataContainer = styled(Grid)`
  && {
    padding: 20px;
  }
`;

const Loader = styled(CircularProgress)`
  && {
    color: ${colors.DARK_PRIMARY_COLOR};
  }
`;

export const S = {
  Container,
  SearchContainer,
  DataContainer,
  Loader,
};
