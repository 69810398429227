import styled from 'styled-components';
import {
  Typography,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const DialogText = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 400;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0px;
  }
`;

const CloseButtonWrapper = styled(IconButton)`
  && {
    cursor: pointer;
    padding: 0px;
  }
`;

const CloseButton = styled(Close)`
  && {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 5px;
    font-size: 16px;
  }
`;

const DialogTitleWrapper = styled(MuiDialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  }
`;

const DialogTitle = styled(Typography)`
  && {
    font-weight: 700 !important;
  }
`;

const DeleteButton = styled(Button).attrs({ disableElevation: true })`
  && {
    background: #ed3437;
    border-radius: 20px;
    border: 1px solid #ed3437;
    color: white;
    font-size: 12px;
    width: 50%;
    padding: 0.1rem;
  }
`;

const CancelButton = styled(Button).attrs({ disableElevation: true })`
  && {
    background: white;
    border-radius: 20px;
    border: 1px solid #14c46d;
    font-size: 12px;
    color: #14c46d;
    width: 50%;
    padding: 0.1rem;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  && {
    padding: 14px 0px;
  }
`;

export const ClearCartDialogStyles = {
  DialogActions,
  DialogText,
  DialogContent,
  DialogTitleWrapper,
  DialogTitle,
  CloseButtonWrapper,
  DeleteButton,
  CancelButton,
  CloseButton,
};
