import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';

export const BaseCard = styled(Paper)`
  && {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    color: #000;
    padding: 10px;
  }
`;
