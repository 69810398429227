import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { NotificationsActiveOutlined } from '@material-ui/icons';

import { translate } from 'react-i18next';

import { Product } from 'store/reducers/Product';
import { TranslateProps } from 'utils/Localization';
import { ConditionalRender } from 'components/common/ConditionalRender';
import { S } from '../product-card-details/ProductCardDetails.styles';

interface Props {
  product: Product;
}

const LowOnStockContainer = styled.div`
  width: 100%;
`;

const LowOnStockBox = styled(Grid)`
  height: 38px;
  background: #fff7ef;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 2px 0px;

  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16px;

  color: #dd5435;

  .notification-icon {
    font-size: 24px;
    margin: 0px 10px;
  }
`;

const StockOut = styled(S.StockOut)`
  && {
    margin-top: 5px;
  }
`;

const BaseProductCardLowStockInfo: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({ t, product }) => {
  const { remaining_quantity, quantity_threshold, low_stock_threshold } = product;

  if (remaining_quantity >= quantity_threshold && remaining_quantity <= low_stock_threshold) {
    const stockLeft = remaining_quantity - quantity_threshold;
    const isOutOfStock = stockLeft <= 0;
    return (
      <LowOnStockContainer>
        <ConditionalRender condition={isOutOfStock} data-testid="div-outOfStock">
          <StockOut>{t('outOfStock')}</StockOut>
        </ConditionalRender>
        <ConditionalRender condition={!isOutOfStock}>
          <LowOnStockBox data-testid="box-stockout">
            <NotificationsActiveOutlined className="notification-icon" /> {t('lowOnStock', { stockLeft })}
          </LowOnStockBox>
        </ConditionalRender>
      </LowOnStockContainer>
    );
  }
  return null;
};

export const ProductCardLowStockInfo = translate('stockOut')(BaseProductCardLowStockInfo);
