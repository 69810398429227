import React from 'react';

import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';

import * as colors from '../../../../../styles/colors';

export interface HeaderProps {
  title: string;
  onBack?: () => void;
}

const HeaderContainer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  background: ${colors.WHITE};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
`;

const HeaderButton = styled(IconButton)`
  && {
    color: ${colors.BLACK};
  }
`;

const HeaderTitle = styled.span`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
`;

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = (props) => {
  const { title, onBack } = props;
  return (
    <HeaderContainer>
      <HeaderButton onClick={onBack} data-testid="back-btn">
        <ArrowBack />
      </HeaderButton>
      <HeaderTitle>{title}</HeaderTitle>
    </HeaderContainer>
  );
};
