import produce, { Draft } from 'immer';

import { PaymentDirectStore } from './reducer.interface';
import { PaymentDirectActions, PaymentDirectActionType } from './action.type';

const paymentState: PaymentDirectStore = {
  loading: false,
  payments: {
    data: [],
    meta: {
      page: 0,
      page_size: 0,
      page_count: 0,
      total_count: 0,
    },
  },
  paymentDetail: null,
  error: '',
};

export const paymentDirectReducer = (
  state: PaymentDirectStore = paymentState,
  action: PaymentDirectActions,
): PaymentDirectStore =>
  produce(state, (draft: Draft<PaymentDirectStore>) => {
    switch (action.type) {
      case PaymentDirectActionType.SET_PAYMENT_DIRECT_LOADING:
        draft.loading = action.payload.loading;
        break;

      case PaymentDirectActionType.SET_PAYMENT_DIRECT_LIST:
        draft.payments = {
          data: [...draft.payments.data, ...action.payload.data],
          meta: { ...action.payload.meta },
        };
        break;

      case PaymentDirectActionType.SET_PAYMENT_DIRECT_DETAIL:
        draft.paymentDetail = action.payload.data;
        break;

      case PaymentDirectActionType.SET_PAYMENT_DIRECT_ERROR:
        draft.error = action.payload.error;
        break;

      case PaymentDirectActionType.RESET_PAYMENT_DIRECT_LIST:
        draft.payments = {
          data: [],
          meta: {
            page: 0,
            page_size: 0,
            page_count: 0,
            total_count: 0,
          },
        };
        break;

      case PaymentDirectActionType.RESET_PAYMENT_DIRECT_DETAIL:
        draft.paymentDetail = null;
        break;
    }
  });
