import { DistributorCarts, ICartChange } from 'store/reducers/Cart';

/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
export const checkDistributorProducts = (distributors: DistributorCarts[]): ICartChange => {
  const check = { unavailable: false, changeDistributor: false };
  for (let i = 0; i < distributors.length; i++) {
    const distributor = distributors[i];
    if (!distributor.products) continue;

    for (let j = 0; j < distributor.products.length; j++) {
      const product = distributor.products[j];
      if (!product?.update_status) continue;

      switch (product.update_status.change_type) {
        case 'unavailable':
          check.unavailable = true;
          break;

        default:
          if (product.update_status.change_distributor) {
            check.changeDistributor = true;
          }
          break;
      }
    }
  }

  return check;
};
