import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { ConditionalRender } from 'components/common';
import { OrderItem } from 'services';

import { S } from './orders-modal.styles';
import { OrdersModalProducts } from './orders-modal-products.component';

export const OrdersModalProductsPagination: React.FC<React.PropsWithChildren<{
  t: any;
  items: OrderItem[];
  size: number;
}>> = ({ t, items, size }) => {
  const [count, setCount] = useState<number>(0);
  const [productItems, setProductItems] = useState<OrderItem[]>([]);

  const minimize = (): void => {
    const sliceItems = items.slice(0, size);
    setProductItems(sliceItems);
  };

  useEffect(() => {
    minimize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCount(items.length - productItems.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productItems]);

  const loadAll = (): void => {
    setProductItems(items);
  };

  return (
    <S.ProductItemsContainer key={uuidv4()}>
      <div className="product__items">
        {productItems.map((item) => (
          <OrdersModalProducts orderItem={item} key={item.id} />
        ))}
      </div>
      <ConditionalRender condition={count > 0}>
        <Grid container justifyContent="flex-end" alignItems="center" className="product__pagination" onClick={loadAll}>
          <S.ProductItemsLoadMoreTitle>{t('moreOrderItems', { count })}</S.ProductItemsLoadMoreTitle>
          <S.ProductItemsLoadMoreIcon />
        </Grid>
      </ConditionalRender>
      <ConditionalRender condition={count === 0 && items.length > size}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className="product__pagination"
          onClick={minimize}
        >
          <S.ProductItemsLoadMoreTitle>{t('minimizeOrderItems')}</S.ProductItemsLoadMoreTitle>
          <S.ProductItemsLoadLessIcon />
        </Grid>
      </ConditionalRender>
    </S.ProductItemsContainer>
  );
};
