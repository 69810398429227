import { RouterHistory } from '@sentry/react/dist/reactrouter';
import { routeKey } from 'utils/route.utils';

export const toDirectPaymentRoute = (history: RouterHistory, billCode: string, paid: boolean): void => {
  const pathName = `${routeKey('direct_payment', {
    bill_code: billCode,
  })}`;

  history.push({
    state: { update: true },
    search: `?voucherPaid=${paid}`,
    pathname: pathName,
  });
};
