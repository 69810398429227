import qs from 'query-string';
import { BRACKET_QS_OPTIONS } from 'utils/constants';

export function removeTrailingSlash(url: string): string {
  return url.replace(/\/$/, '');
}

export function appendParamsToPath(path: string, params: any): string {
  return `${path}?${qs.stringify(params, BRACKET_QS_OPTIONS)}`;
}

export const generalizeIdParameterInLoc = (location: string): string => {
  const idEnclosedSlashRegex = /\/(\d+)($|\/*)/;
  const hasIdParam = idEnclosedSlashRegex.test(location);
  if (hasIdParam) {
    const idRegex = /\d+/g;

    location = location.replace(idRegex, ':id');
  }
  return location;
};
