import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import HighlightOff from '@material-ui/icons/HighlightOff';
import WatchLater from '@material-ui/icons/WatchLater';
import Info from '@material-ui/icons/Info';

import ScrollView from 'components/views/ScrollView';

import * as colors from '../../styles/colors';
import { ReactComponent as Logo } from '../../../images/logo_transparent.svg';
import { ReactComponent as PaymentFullSvg } from '../../../images/payment-full.svg';

export const Container = styled(ScrollView)`
  background-color: ${colors.OFF_WHITE};
  height: 100%;
  padding: 0 0px 34px 0;
`;

export const SwipeRxLogo = styled(Logo)`
  && {
    height: 46px;
    width: 120px;
  }
`;

export const PaymentFullImg = styled(PaymentFullSvg)`
  && {
    height: auto;
    width: 70%;
  }
`;

export const PaymentFullContainer = styled.div`
  && {
    flex: 1;
    display: flex;
    width: 100%;
    background-color: white;
    justify-content: center;
    flex-direction: column;
  }
`;

export const PaymentFullImgWrapper = styled.div`
  && {
    flex: 1;
    display: flex;
    width: 100%;
    background-color: white;
    justify-content: center;
  }
`;

export const PaymentFullCaptionWrapper = styled.div`
  && {
    text-align: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const PaymentFullCaptionTitle = styled.div`
  && {
    color: ${colors.PRIMARY_COLOR};
    font-weight: bold;
  }
`;

export const PaymentFullCaptionMessage = styled.div``;

export const Instructions = styled(Typography)`
  && {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.16px;
    color: rgba(0, 0, 0, 0.49);
    text-align: center;
  }
`;

export const BillReference = styled(Typography).attrs({ variant: 'subtitle1' })`
  && {
    font-size: 14px;
    margin-top: 0;
    text-align: center;
  }
`;

export const QRCodeSection = styled.div`
  margin-top: 0px;
  padding: 10px;
`;

export const PaymentDetailsSection = styled.div`
  margin-top: 2px;
  width: 100vw;
`;

export const QRCode = styled.img`
  height: 268px;
  width: 268px;
`;

export const BaseButton = styled(Button)`
  && {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.16px;
  }
`;

export const DownloadButton = styled(BaseButton)`
  && {
    background: rgba(222, 243, 239, 0.5);
    border-radius: 30px;
    color: ${colors.PRIMARY_COLOR};
    margin-top: 60px;
    padding: 8px 80px;
    font-weight: bold;
    font-size: 12px;
    text-transform: none;
    max-width: 90vw;

    :hover {
      background: rgba(222, 243, 239, 0.5);
    }

    :disabled {
      color: ${colors.PRIMARY_COLOR};
    }
  }
`;

export const CloseIcon = styled(HighlightOff)`
  && {
    font-size: 14px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
`;

export const CloseButton = styled(BaseButton)`
  && {
    color: rgba(0, 0, 0, 0.49);
    text-transform: none;
    margin-top: 60px;
    margin-bottom: 34px;
  }
`;

export const TimerIconWrapper = styled(Grid).attrs({ item: true })`
  margin-top: 15px !important;
  color: #ff7f30 !important;
  text-align: center;
`;

export const TimerIcon = styled(WatchLater)`
  font-size: 48px !important;
`;

export const TimerLabel = styled(Typography).attrs({ variant: 'subtitle1' })`
  font-size: 14px;
  color: #ff7f30 !important;
`;

export const FooterInstruction = styled(Grid).attrs({ item: true })`
  text-align: left !important;
  margin-top: 25px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-bottom: 25px !important;
`;

export const FooterInstructionTitle = styled(Typography)`
  line-height: 3 !important;
  font-size: 15px !important;
`;

export const FooterInstructionTitleIcon = styled(Info)`
  line-height: inherit !important;
  font-size: inherit !important;
`;

export const FooterInstructionItem = styled(Typography).attrs({ variant: 'caption' })`
  line-height: 1.75 !important;
  display: block;
`;

export default {
  Container,
  QRCode,
  DownloadButton,
  CloseButton,
  CloseIcon,
  SwipeRxLogo,
  Instructions,
  QRCodeSection,
  PaymentDetailsSection,
  TimerIcon,
  TimerIconWrapper,
  TimerLabel,
  PaymentFullImg,
  PaymentFullContainer,
  PaymentFullCaptionWrapper,
  PaymentFullImgWrapper,
  PaymentFullCaptionTitle,
  PaymentFullCaptionMessage,
  BillReference,
  FooterInstruction,
  FooterInstructionTitle,
  FooterInstructionTitleIcon,
  FooterInstructionItem,
};
