import { SEARCH_INPUT_CLASS } from './constant';

export const getSearchTypedQuery = (): string | undefined => {
  const searchElement: any = document.getElementsByClassName(SEARCH_INPUT_CLASS);

  if(!searchElement || !searchElement.length) return undefined;

  const lastIndex = searchElement?.length-1
  return searchElement?.[lastIndex]?.firstChild?.value || undefined;
};
