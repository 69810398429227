import * as Redux from 'redux';
import { Actions } from './actions';
import constants from './constants';

export interface State {
  isOpen: boolean;
}

const InitialState: State = {
  isOpen: false,
};

const ProductRequestReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.SHOW_DIALOG:
      return {
        isOpen: true,
      };
    case constants.HIDE_DIALOG:
      return {
        isOpen: false,
      };
    default:
      return state;
  }
};

export default ProductRequestReducer;
