import React, { useState } from 'react';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import ShowIf from 'components/views/ShowIf';
import { DialogPage } from 'components/common/DialogPage/DialogPage.component';
import Typography from '@material-ui/core/Typography';

import { Product } from 'store/reducers/Product';
import { store } from 'store';

import { TranslateProps } from 'utils/Localization';

import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG } from 'services';
import { useTooltipTour, TooltipTour } from 'components/common/TooltipTour';
import { S } from './RequiredDocument.style';

interface RequiredDocumentProps extends TranslateProps {
  product: Product;
}

const RequiredDocumentBase: React.FC<React.PropsWithChildren<RequiredDocumentProps>> = ({ product, t }) => {
  const [showInstruction, setShowInstruction] = useState(false);
  const { flag } = product;
  const state = store.getState();
  const { tooltipParams, visible } = useTooltipTour('prekursorTooltipTour.productCard', 'top');

  return (
    <>
      <ShowIf condition={!!flag && flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG}>
        <S.Wrapper>
          <Grid item xs={1}>
            <S.DocumentIcon flag={flag} />
          </Grid>
          <Grid item xs={8}>
            <S.Label>{t('prekursorDocumentRequired')}</S.Label>
          </Grid>
          <Grid item xs={3} style={{ marginRight: 4 }}>
            <div ref={tooltipParams.setTriggerRef}>
              <S.InstructionButton onClick={() => setShowInstruction(true)}>{t('instruction')}</S.InstructionButton>
            </div>
          </Grid>
        </S.Wrapper>

        <TooltipTour
          name="prekursorTooltipTour.productDetail"
          tooltipParams={tooltipParams}
          visible={visible && product.flag && product.flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG}
          width={210}
          offsetY={-3}
          offsetX={-7}
          zIndex={5}
        >
          <Typography>{t('prekursorTooltipTour.productDetail')}</Typography>
        </TooltipTour>
      </ShowIf>
      <ShowIf condition={showInstruction}>
        <DialogPage
          open={showInstruction}
          link={state.config.market.prekursorPageUrl}
          close={() => setShowInstruction(false)}
        />
      </ShowIf>
    </>
  );
};

export const RequiredDocument = translate('productDetailPage')(RequiredDocumentBase);
