import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import * as colors from 'components/styles/colors';

const Row = styled(Grid)<{ gutter?: boolean }>`
  display: flex;
  padding: ${(props) => (props.gutter ? '10px 15px' : `0`)};
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 4px;
`;

const Label = styled(Typography)<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? '700' : `400`)};
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.16px;
  color: ${(props) => (props.bold ? '#181D29' : `#717786`)};
`;

const Value = styled(Typography)<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? '700' : `400`)};
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.16px;
  color: ${(props) => (props.bold ? '#000000' : `#181D29`)};
`;

const ViewButton = styled.button`
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.disabled ? colors.BLUISH_GRAY_LIGHTER : colors.DARK_PRIMARY_COLOR)};
  display: flex;
  align-items: center;
  padding: 0;
  text-transform: uppercase;

  &:active,
  &:focus {
    outline: none;
  }

  & svg {
    height: 14px;
    width: 14px;
    margin-left: 9px;
  }
`;

const Warning = styled(Grid)`
  padding: 10px 11px;
  background: rgba(255, 127, 48, 0.1);
  border-radius: 4px;
`;

export const S = {
  Row,
  Label,
  Value,
  ViewButton,
  Warning,
};
