import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const HelpDialogContainer = styled(Dialog)`
  && {
    .close-button {
      position: absolute;
      bottom: 0%;
      padding: 0px !important;
      background-color: #ffcc66;
      height: 6%;
    }
  }
`;

export const HelpDialogContent = styled(DialogContent)`
  width: 100%;
  && {
    .help {
      position: absolute;
      width: 100%;
      height: 90%;
      border: 0;
      background: transparent;
    }

    &:first-child {
      padding: 0;
    }
  }
`;
