import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { State as StoreState } from 'store';
import { showError } from 'store/reducers/Global/actions';
import { setPaymentInformation } from 'store/reducers/Payment/actions';
import { getMarketingFeatures } from 'store/reducers/MarketingFeatures/actions';

import {
  applyVoucher,
  getAvailableVouchers,
  clearVoucherApplied,
  clearSelectedVoucher,
  checkEligibility,
} from 'store/reducers/Voucher/actions';
import OrderConfirmedWithWidget from './component';
import { StateProps } from './interface';

const mapStateToProps = (state: StoreState): StateProps => ({
  purchaseOrderBatchId: state.orders.purchaseOrderBatchId,
  orders: state.orders.orders,
  cartItems: state.counter.cartItems,
  carts: state.cart,
  vouchers: state.voucher.vouchers,
  voucherApplied: state.voucher.used,
  paymentMethod: state.pharmacy.paymentMethod,
  vouchersMeta: state.voucher.meta,
  voucherError: state.voucher.error,
});

const mapDispatchToProps = {
  showError,
  setPaymentInformation,
  getAvailableVouchers,
  clearVoucherApplied,
  clearSelectedVoucher,
  checkEligibility,
  applyVoucher,
  getMarketingFeatures,
};

export default translate('orderConfirmed')(connect(mapStateToProps, mapDispatchToProps)(OrderConfirmedWithWidget));
