import { OrderStatus } from 'services/swipe-rx-pt/resources/orders/types';

export const OrderStatusSequence: OrderStatus[] = [
  OrderStatus.PENDING,
  OrderStatus.PROCESSING,
  OrderStatus.ACCEPTED,
  OrderStatus.ORDER_CHANGED,
  OrderStatus.DISPATCHED,
  OrderStatus.DELIVERED,
  OrderStatus.RETURNED,
  OrderStatus.CANCELLED,
];
