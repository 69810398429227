import React from 'react';
import { translate } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { State as StoreState } from 'store';
import { closeOrderRatingThankYou } from 'store/reducers/Orders/actions';
import { TFunction } from 'utils/Localization';
import CloseIcon from '@material-ui/icons/Close';

import { Box, IconButton, Modal, Grid } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import range from 'lodash/range';
import * as colors from 'components/styles/colors';
import ThankYou from 'images/thank-you.png';
import ThankYouLowRating from 'images/thank-you-low-rating.png';
import { useHistory } from 'react-router-dom';
import { routeKey } from 'utils/route.utils';
import { S } from './orders-rating-thank-you.styles';

interface Props {
  t: TFunction;
}

export const OrdersRatingThankYouBase: React.FC<React.PropsWithChildren<Props>> = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    orders: {
      orderRating: { showThankYou, rate },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const handleClose = (): void => {
    dispatch(closeOrderRatingThankYou());
    history.push(routeKey('main_home'));
  };

  const getHeaderText = (): string => {
    if (rate && rate > 3) {
      return t('gladYouEnjoyed.header');
    }

    return t('sorryForYourInconvenience.header');
  };

  const getBodyText = (): string => {
    if (rate && rate > 3) {
      return t('gladYouEnjoyed.text');
    }

    return t('sorryForYourInconvenience.text');
  };

  const getImage = (): string => {
    if (rate && rate > 3) {
      return ThankYou;
    }

    return ThankYouLowRating;
  };

  return (
    <Modal open={showThankYou} onClose={handleClose} style={{ zIndex: 100 }}>
      <S.ThankYouWrapper>
        <Grid style={{ height: '45%' }}>
          <IconButton data-testid="order-rating-thank-you-close-btn">
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <S.Header>{getHeaderText()}</S.Header>
          <S.Body>{getBodyText()}</S.Body>
          <Box flex textAlign="center" style={{ marginTop: '12px' }}>
            {range(5).map((index) => (
              <Star
                style={{
                  fontSize: 36,
                  color: (rate ?? 0) >= index + 1 ? colors.STAR_COLOR : colors.BLUISH_GRAY_LIGHTER,
                  paddingRight: '9px',
                }}
              />
            ))}
          </Box>
        </Grid>
        <S.StyledImg src={getImage()} alt="thank-you" />
        <S.ActionsContainer>
          <S.DoneButton
            size="medium"
            variant="contained"
            onClick={handleClose}
            data-testid="order-rating-thank-you-done-btn"
          >
            {t('done')}
          </S.DoneButton>
        </S.ActionsContainer>
      </S.ThankYouWrapper>
    </Modal>
  );
};

export const OrdersRatingThankYou = translate('orders')(OrdersRatingThankYouBase);
