import * as Redux from 'redux';
import * as constants from './constants';
import { Actions } from './actions';

export interface Banner {
  banner_link: string;
  banner_url: string;
  created_at: Date;
  deleted_at: Date | null;
  display_priority: number;
  id: number;
  target_segments: string[] | null;
  updated_at: Date;
  visibility: string;
}
export interface State {
  list: Banner[];
  error?: string;
  loading: boolean;
}

const InitialState: State = {
  list: [],
  loading: true,
};

const BannerReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_BANNER:
      return {
        ...state,
        loading: false,
        list: action.banner,
      };
    case constants.FAIL_GET_BANNER:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default BannerReducer;
