/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import { translate } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import withStyles, { StyledComponentProps } from '@material-ui/core/styles/withStyles';

import { WHITE, REBRAND_GREEN, REBRAND_DARK_BLUE } from 'components/styles/colors';
import { TabPage, A11yProps } from 'components/common/Tabs/Tab.types';
import { TabNavLink } from 'components/common/Tabs/TabNavLink.component';

import { OrderPrecursorStatus } from 'services';
import { TranslateProps } from 'utils/Localization';
import { S } from './orders-history-filter-precursor-tabs.styles';

const styles = {
  indicator: {
    height: '3px',
    backgroundColor: REBRAND_GREEN,
  },
};

interface Props extends StyledComponentProps, TranslateProps {
  tKey: string;
  filters: OrderPrecursorStatus[];
  value: OrderPrecursorStatus | null;
  onClick: (status: OrderPrecursorStatus) => void;
}

const BaseOrdersHistoryFilterPrecursorTabs: React.FC<React.PropsWithChildren<Props>> = ({
  classes = {},
  filters,
  value,
  onClick,
  t,
  tKey = '',
}) => {
  const tabs: TabPage[] = useMemo(
    () =>
      filters.map((filter, index) => ({
        id: index,
        path: filter,
        label: t(`${tKey}.${filter}`),
      })),
    [filters, t, tKey],
  );

  const [tabIndex, setTabIndex] = useState<number>(0);

  useEffect(() => {
    const selectedIndex = value ? filters.findIndex((filter) => filter === value) : 0;
    if (selectedIndex > -1) {
      setTabIndex(selectedIndex);
    }
  }, [value, filters]);

  const a11yProps = (index: number): A11yProps => ({
    id: `order-history-filter-secondary-tab-${index}`,
    'aria-controls': `order-history-filter-secondary-tabpanel-${index}`,
  });

  const handleTabNavClick = (tab: TabPage): void => {
    onClick(tab.path as OrderPrecursorStatus);
  };

  return (
    <S.Container>
      <Tabs classes={{ indicator: classes.indicator }} value={tabIndex} variant="scrollable" scrollButtons="auto">
        {tabs.map((tab, index) => (
          <TabNavLink
            key={tab.id}
            tab={tab}
            index={index}
            a11yProps={a11yProps}
            tabStyle={{
              textTransform: 'capitalize',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
              whiteSpace: 'nowrap',
              color: `${tab.id === tabIndex ? REBRAND_GREEN : REBRAND_DARK_BLUE}`,
              backgroundColor: WHITE,
            }}
            onClick={handleTabNavClick}
          />
        ))}
      </Tabs>
    </S.Container>
  );
};

const OrdersHistoryFilterPrecursorTabsWithStyles = withStyles(styles)(BaseOrdersHistoryFilterPrecursorTabs);

export const OrdersHistoryFilterPrecursorTabs = translate('orders')(OrdersHistoryFilterPrecursorTabsWithStyles);
