import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { State as StoreState } from 'store';
import { useNavigate } from 'hooks/useNavigate';

import { BasketIcon } from './basket.component';

export const Basket: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    counter: { totalItem },
  } = useSelector((state: StoreState) => state, shallowEqual);
  const { navigateTo } = useNavigate();

  const isBasketEmpty = !!totalItem;

  return <BasketIcon onClick={navigateTo} isEmpty={isBasketEmpty} cartCount={totalItem} />;
};
