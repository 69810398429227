/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { IMarketing } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { v3Client } from 'utils/Client/v3';

export interface MarketingWidgetHook {
  marketingList: IMarketing[];
  hasMore?: boolean;
  loadMore?: () => void;
  loading: boolean;
}

const PRODUCT_DETAIL_PAGE_CONSTANT = '/id/product/:id';

export const useMarketingWidget = (params?: { page?: number; pageSize?: number }): MarketingWidgetHook => {
  const marketingMaxLimit = Number.parseInt(process.env.REACT_APP_MARKETING_LIST_MAX || '100', 10);

  const { page = 1, pageSize = 10 } = params || {};
  const { pathname, search } = useLocation();

  const locationSearch = qs.parse(search) as { isModalOpen?: boolean };

  const { isModalOpen = false } = locationSearch;

  const [marketingList, setMarketingList] = useState<IMarketing[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [loading, setLoading] = useState<boolean>(false);

  const generalizeIdParameterInLoc = (location: string): string => {
    const regex = /(\d+)(\/.*)?$/;
    const hasIdParam = regex.test(location);
    if (hasIdParam) {
      const regexDigit = /(\d+)/;
      location = location.replace(regexDigit, ':id');
    }
    return location;
  };

  useEffect(() => {
    let location = generalizeIdParameterInLoc(pathname.replace(/\/+$/i, ''));

    if (isModalOpen) {
      location = PRODUCT_DETAIL_PAGE_CONSTANT;
    }
    setLoading(true);
    v3Client
      .get('marketing', {
        is_published: true,
        location,
        fields: ['id', 'name', 'type', 'config'].join(','),
        page: currentPage,
        page_size: pageSize,
      })
      .then(({ data, meta }) => {
        const rows = currentPage === 1 ? data : [...marketingList, ...data];
        const hasMoreData = meta.page < meta.page_count && marketingList.length < marketingMaxLimit;

        setMarketingList(rows);
        setHasMore(hasMoreData);
      })
      .finally(() => setLoading(false));
  }, [currentPage]);

  const loadMore = (): void => setCurrentPage((currPage) => currPage + 1);

  return {
    marketingList,
    hasMore,
    loadMore,
    loading,
  };
};
