import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Photoswipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import classnames from 'classnames';
import events from './events';

// export interface Props {
//     isOpen: boolean,
//     items: any[],
//     options?: object,
//     onClose?: () => void,
//     id?: string,
//     className?: string
// }

class PhotoSwipe extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        items: PropTypes.array.isRequired,
        options: PropTypes.object,
        onClose: PropTypes.func,
        id: PropTypes.string,
        className: PropTypes.string
    };

    static defaultProps = {
        options: {},
        onClose: () => {},
        id: '',
        className: ''
    };

    state = {
        isOpen: this.props.isOpen
    };

    componentDidMount = () => {
        
        const { isOpen } = this.state;
        
        if (isOpen) {
            this.openPhotoSwipe(this.props);
        }

    };

    // componentDidUpdate = () => {
    //     const { isOpen } = this.state;
    //     if (isOpen) {
    //         if (!isOpen) {
    //             this.openPhotoSwipe(this.props);
    //         } else {
    //             this.updateItems(this.props.items);
    //         }
    //     } else if (isOpen) {
    //         this.closePhotoSwipe();
    //     }
    // }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { isOpen } = this.state;
        if (nextProps.isOpen) {
            if (!isOpen) {
                this.openPhotoSwipe(nextProps);
            } else {
                this.updateItems(nextProps.items);
            }
        } else if (isOpen) {
            this.closePhotoSwipe();
        }
    };

    componentWillUnmount = () => {
        this.closePhotoSwipe();
    };

    openPhotoSwipe = (props) => {
        
        const { items, options } = props;
        
        const pswpElement = this.pswpElement;
        
        this.photoSwipe = new Photoswipe(pswpElement, PhotoswipeUIDefault, items, options);

        this.photoSwipe.listen('preventDragEvent', function(e, isDown, preventObj) {
            preventObj.prevent = !('ontouchstart' in document.documentElement);
        });

        events.forEach((event) => {
        
            const callback = props[event];

            if (callback || event === 'destroy') {
                const self = this;

                this.photoSwipe.listen(event, function (...args) {
                    if (callback) {
                        args.unshift(this);
                        callback(...args);
                    }
                    if (event === 'destroy') {
                        self.handleClose();
                    }
                });
            }
            
        });

        this.setState({
            isOpen: true
        }, () => {
            this.photoSwipe.init();
        });

    };

    updateItems = (items = []) => {

        this.photoSwipe.items.length = 0;

        items.forEach((item) => {
            this.photoSwipe.items.push(item);
        });

        this.photoSwipe.invalidateCurrItems();
        this.photoSwipe.updateSize(true);

    };

    closePhotoSwipe = () => {
        if (!this.photoSwipe) {
            return;
        }
        this.photoSwipe.close();
    };

    handleClose = () => {
        const { onClose } = this.props;
        this.setState({
            isOpen: false
        },
        () => {
            if (onClose) {
                onClose();
            }
        });
    };

  render() {
    const { id } = this.props;
    let { className } = this.props;
    className = classnames(['pswp', className]).trim();
    return (
        <div
            id={id}
            className={className}
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
            ref={(node) => {
                this.pswpElement = node;
            }}
        >

        {/* <!-- Background of PhotoSwipe.  */}
        {/* It's a separate element as animating opacity is faster than rgba(). --> */}
        <div className="pswp__bg"></div>

            {/* <!-- Slides wrapper with overflow:hidden. --> */}
            <div className="pswp__scroll-wrap">

                {/* <!-- Container that holds slides. 
                    PhotoSwipe keeps only 3 of them in the DOM to save memory.
                    Don't modify these 3 pswp__item elements, data is added later on. --> */}
                <div className="pswp__container">
                    <div className="pswp__item"></div>
                    <div className="pswp__item"></div>
                    <div className="pswp__item"></div>
                </div>

                {/* <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. --> */}
                <div className="pswp__ui pswp__ui--hidden">

                    <div className="pswp__top-bar">

                        {/* <!--  Controls are self-explanatory. Order can be changed. --> */}
                        <div className="pswp__counter"></div>

                        <button className="pswp__button pswp__button--close" title="Close (Esc)"></button>

                        <button className="pswp__button pswp__button--share" title="Share"></button>

                        <button className="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                        <button className="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                        {/* <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                        <!-- element will get className pswp__preloader--active when preloader is running --> */}
                        <div className="pswp__preloader">
                            <div className="pswp__preloader__icn">
                            <div className="pswp__preloader__cut">
                                <div className="pswp__preloader__donut"></div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                        <div className="pswp__share-tooltip"></div> 
                    </div>

                    <button className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                    </button>

                    <button className="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                    </button>

                    <div className="pswp__caption">
                        <div className="pswp__caption__center"></div>
                    </div>

                </div>
            </div>
        </div>

    );
  }
}

function PhotoSwipeModal(props) {
    return createPortal(<PhotoSwipe {...props} />, document.querySelector('#photo-swipe-modal'));
  }

export default PhotoSwipeModal;
