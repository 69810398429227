import { Action } from 'redux';
import { Location } from 'history';

import constants from './constants';

export interface WidgetListLoaded {
  index: number;
  finishLoaded: boolean;
}

/**
 * Action types
 */
export interface ShowError extends Action {
  type: constants.SHOW_ERROR;
  message: string;
}

export interface ShowSuccess extends Action {
  type: constants.SHOW_SUCCESS;
  message: string;
}

export interface HideError extends Action {
  type: constants.HIDE_ERROR;
}

export interface HideSuccess extends Action {
  type: constants.HIDE_SUCCESS;
}

export interface StartAppLoadingAction extends Action {
  type: constants.START_APP_LOADING;
}

export interface EndAppLoadingAction extends Action {
  type: constants.END_APP_LOADING;
}

export interface SetBackStackAction extends Action {
  type: constants.SET_BACK_STACK;
  backStack: Location[];
  backStackLength: number;
}

export interface SetHomeWidgetFinishLoaded extends Action {
  type: constants.SET_HOME_WIDGET_FINISH_LOADED;
  widgetIndex: number;
}

export interface ResetHomeWidgetsLoaded extends Action {
  type: constants.RESET_HOME_WIDGETS_LOADED;
}

export type Actions =
  | ShowError
  | ShowSuccess
  | HideError
  | HideSuccess
  | StartAppLoadingAction
  | EndAppLoadingAction
  | SetBackStackAction
  | SetHomeWidgetFinishLoaded
  | ResetHomeWidgetsLoaded;

/**
 * Actions
 */
export const showError = (message: string): ShowError => ({
  type: constants.SHOW_ERROR,
  message,
});

export const showSuccess = (message: string): ShowSuccess => ({
  type: constants.SHOW_SUCCESS,
  message,
});

export const hideError = (): HideError => ({
  type: constants.HIDE_ERROR,
});

export const hideSuccess = (): HideSuccess => ({
  type: constants.HIDE_SUCCESS,
});

/*
    Loading functions in case we need to manually 
    trigger it in the state when performing counter related asynchronous methods
    - Immanuel
*/
export const startAppLoading = (): StartAppLoadingAction => ({
  type: constants.START_APP_LOADING,
});

export const endAppLoading = (): EndAppLoadingAction => ({
  type: constants.END_APP_LOADING,
});

export const setBackStack = (backStackLength: number, backStack: Location[]): SetBackStackAction => ({
  type: constants.SET_BACK_STACK,
  backStackLength,
  backStack,
});

export const setHomeWidgetFinishLoaded = (widgetIndex: number): SetHomeWidgetFinishLoaded => ({
  type: constants.SET_HOME_WIDGET_FINISH_LOADED,
  widgetIndex,
});

export const resetHomeWidgetsLoaded = (): ResetHomeWidgetsLoaded => ({
  type: constants.RESET_HOME_WIDGETS_LOADED,
});
