import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  children?: React.ReactNode;
}

export const TabPanel: React.FC<React.PropsWithChildren<Props>> = ({ children }) => (
  <Typography component="div" role="tabpanel" id="distributor-tabpanel" aria-labelledby="distributor-tab">
    {children}
  </Typography>
);
