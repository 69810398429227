import React from 'react';
import { MarketingFeaturesDisplayPosition } from 'services/swipe-rx-pt/resources/marketings/constants';

import { getWidgetsByPosition } from '../product-list/utils';
import { ProductListingWidget } from '../product-list/interfaces/generic-product-list.interface';

export const AfterListingWidgets: React.FC<{
  widgets?: ProductListingWidget[];
}> = ({ widgets = [] }) => {
  if (widgets.length) {
    const afterListingWidgets = getWidgetsByPosition(MarketingFeaturesDisplayPosition.AFTER_LISTING, widgets);
    const results = afterListingWidgets.map((widget) => widget.getWidgets({})).flat();
    return <>{results}</>;
  }
  return null;
};

AfterListingWidgets.defaultProps = {
  widgets: [],
};
