export enum PharmacyOrderStatus {
  ALL_ORDERS = 'all-orders',
  ORDER_RECEIVED = 'order-received',
  WAITING_DOCUMENT = 'waiting-document',
  PREPARING_GOODS = 'preparing-goods',
  OUT_OF_DELIVERY = 'out-of-delivery',
  DELIVERED = 'delivered',
  DELAYED = 'delayed',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  ORDER_CHANGED = 'order-changed',
  FULFILLED = 'fulfilled',
  PAID = 'paid',
  UNPAID = 'unpaid',
  CANCELLED_FULL_RETURN = 'full-return',
}
