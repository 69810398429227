/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */

import branch from 'branch-sdk';
import startCase from 'lodash/startCase';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

import { Analytics, AnalyticsProvider } from '../interfaces';

export class BranchIO implements AnalyticsProvider {
  init(token: string, callback: (error: string | null) => void, dataCallback: (data: any) => void): void {
    branch.init(token, undefined, (error, payload) => {
      if (error) {
        console.warn(`[branch.io] failed to init "${error}"`);
      } else {
        dataCallback(payload);
      }
      callback(error);
    });
  }

  identify(id: string, _: Record<string, string>, analytics: Analytics): void {
    const isDebug = analytics.isDebug();
    if (isDebug) console.info(`[branch.io] identify`, id);

    branch.setIdentity(id, (err, data) => {
      if (!isDebug) return;
      if (err) {
        console.error(`[branch.io] identify error=${err}`);
      } else {
        console.info(`[branch.io] identify`, data);
      }
    });
  }

  track(event: string, payload: Record<string, any>, analytics: Analytics) {
    const isDebug = analytics.isDebug();
    const cleanPayload = omitBy(payload, isUndefined);

    if (isDebug) {
      console.info(`[branch.io] track`, { event, payload: cleanPayload });
    }

    branch.logEvent(event, {
      ...cleanPayload,
      description: payload.dashboard ?? startCase(event),
    });
  }

  page(event: string, payload: Record<string, any>, analytics: Analytics) {
    const isDebug = analytics.isDebug();
    if (isDebug) console.info(`[branch.io] page`, { event, payload });

    branch.logEvent(event, payload);
  }
}
