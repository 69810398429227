import React from 'react';
import styled from 'styled-components';
import { TranslateProps } from 'utils/Localization/types';
import { Button as MUIButton, Typography } from '@material-ui/core';
import ShowIf from 'components/views/ShowIf';

export interface OwnProps {
  showSeparator?: boolean;
}

export interface DispatchProps {
  showDialog: () => void;
}

type Props = OwnProps & DispatchProps & TranslateProps;

const Container = styled.div`
  padding: 0.5rem 1rem;
  text-align: center;
`;

const Button = styled(MUIButton)`
  && {
    text-transform: none;
    color: #14c47d;
    border: 1px solid #14c47d;
    background: none;
    box-shadow: none;
  }
`;

const Subheading = styled(Typography)`
  && {
    padding-bottom: 0.5rem;
  }
`;

export const ProductRequestLink: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { t, showDialog, showSeparator = false } = props;

  return (
    <Container>
      <ShowIf condition={showSeparator}>
        <hr />
      </ShowIf>

      <Subheading>{t('question')}</Subheading>

      <Button
        id="tour__suggest__a__product"
        fullWidth
        color="primary"
        variant="contained"
        onClick={showDialog}
        data-testid="btn-recommend-product"
      >
        {t('title')}
      </Button>
    </Container>
  );
};
