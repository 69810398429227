import { useLocation, useRouteMatch } from 'react-router-dom';
import qs from 'query-string';

import {
  getEventPageIdentity,
  ProductListPageAnalyticsProps,
  ProductSimpleSearchLocationProps,
} from 'utils/Analytics/Segment';

import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { useCampaignTracking } from './useCampaignTracking';

interface LocationSearchProps extends ProductSimpleSearchLocationProps {
  category_ids?: string;
  goBackTo?: string;
}

export const useMarketingAnalytics = (
  marketing_id?: number | string,
  marketing_name?: string,
): ProductListPageAnalyticsProps => {
  const location = useLocation();
  const match = useRouteMatch();

  const { query }: LocationSearchProps = qs.parse(location.search, BRACKET_QS_OPTIONS) || {};

  const { utmSource, utmMedium, marketingId, marketingName } = useCampaignTracking(marketing_id, marketing_name);

  const pageIdentity = (match && getEventPageIdentity(match, location.search)) || '';

  const analyticProps: ProductListPageAnalyticsProps = {
    ...(marketingId ? { marketing_id: marketingId } : undefined),
    ...(marketingName ? { marketing_name: marketingName } : undefined),
    ...(query ? { query } : undefined),
    ...(utmSource ? { utmSource } : undefined),
    ...(utmMedium ? { utmMedium } : undefined),
    ...(pageIdentity ? { from_page: pageIdentity } : undefined),
  };

  return analyticProps;
};
