import React from 'react';
import { DividerWithTextStyle } from './DividerWithText.styles';

export interface DividerWithTextProps {
  text: string;
  margin?: number;
}

const DividerWithText: React.FC<DividerWithTextProps> = ({ text, margin = 16 }) => {
  return (
    <DividerWithTextStyle.Wrapper margin={margin}>
      <DividerWithTextStyle.Divider />
      <DividerWithTextStyle.Text margin={margin}>{text}</DividerWithTextStyle.Text>
      <DividerWithTextStyle.Divider />
    </DividerWithTextStyle.Wrapper>
  );
};
export default DividerWithText;
