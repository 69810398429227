import React from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import ShowIf from 'components/views/ShowIf';

import { toCurrency } from 'utils/Format';
import { TFunction } from 'utils/Localization';
import { Product } from 'store/reducers/Product';
import { clearProductListing } from 'store/reducers/ProductListing/actions';
import { getMarketId } from 'utils/MarketConfig';

import { ConditionalRender } from 'components/common';
import { SimilarProductsButton } from 'components/common/SimilarProductsButton';
import * as colors from 'components/styles/colors';
import { BasePageTracker } from 'utils/Analytics/Segment';
import { AvailabilityTimer } from 'components/common/ProductCard/components/AvailabilityTimer';
import { useProductLimit } from 'components/common/ProductCard/components/product-card-quantity-box/hooks/use-product-limit.hook';
import {
  FlexContainer,
  OutOfStockContainer,
  PriceContainer,
  OutOfStockText,
  PriceLabel,
  RoundedButton,
  SubtotalPrice,
  TotalPrice,
  BellIcon,
  CartDivider,
  MaxPurchaseContainer,
  MaxPurchaseText,
  NotAvailableText,
} from './style';

interface Props {
  t: TFunction;
  product: Product;
  isStockOut: boolean;
  availability: {
    request: any;
    remove: any;
    requested: boolean;
  };
  cart: any;
  isUnverifiedCartOpen: boolean;
  similarProductList?: Product[] | undefined;
  isLoading: boolean;
}

const Wrapper: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { t, product, isStockOut, cart, availability, similarProductList, children, isUnverifiedCartOpen, isLoading } =
    props;
  const marketId = getMarketId();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const {
    maxPurchasedReached,
    remainingStock,
    maxQuantityEnabled,
    purchaseableQuantity,
    maxPurchaseLimit,
    purchasedQuantityMonthly,
    purchaseableMonthlyQuantity,
    maxMonthlyQuantityEnabled,
    maxPurchaseMonthlyReached,
    maxPurchaseMonthlyThreshold,
    maxPurchaseMonthlyLimit,
  } = useProductLimit(product, cart);

  const cartInfo = cart?.items[product.id];
  const isAddedToCart = cartInfo?.count > 0 || false;
  const cartProduct = cartInfo?.cartItems?.[cartInfo.productName]?.[product.id] ?? cartInfo ?? null;
  const subtotalPrice = toCurrency(cartProduct?.selling_price * cartProduct?.quantity);
  const totalPrice = toCurrency(cartProduct?.net_price * cartProduct?.quantity);
  const isProductLoading = product.id === 0 && isLoading;
  const shouldShowSubtotal = subtotalPrice > totalPrice;
  const isProductNotAvailable = product.id === 0 && !isLoading;
  const isDailyPrioritized = purchaseableQuantity < purchaseableMonthlyQuantity;
  const isWithinMonthlyLimitThreshold = maxPurchaseMonthlyThreshold <= cartInfo?.count + purchasedQuantityMonthly;

  const goToSimilarProductList = (): void => {
    dispatch(clearProductListing());
    push(`/${marketId}/similar-products/${product?.sku_code}`, { initial_page: BasePageTracker.PRODUCT_DETAIL });
  };

  if (isProductNotAvailable) {
    return (
      <FlexContainer>
        <NotAvailableText>{t('productUnavailable')}</NotAvailableText>
      </FlexContainer>
    );
  }
  if (isStockOut) {
    return (
      <>
        <ShowIf condition={!isProductLoading}>
          <OutOfStockContainer>
            <OutOfStockText>{t('outOfStock')}</OutOfStockText>
          </OutOfStockContainer>
        </ShowIf>
        <FlexContainer>
          {!availability.requested ? (
            <>
              <ShowIf condition={!isProductLoading}>
                <RoundedButton
                  variant="outlined"
                  style={{
                    backgroundColor: colors.REBRAND_GREEN,
                    color: colors.WHITE,
                  }}
                  onClick={availability.request}
                >
                  <BellIcon style={{ color: colors.WHITE }} />
                  {t('notify')}
                </RoundedButton>
                <ShowIf condition={!!similarProductList && similarProductList?.length > 0}>
                  <CartDivider orientation="vertical" flexItem />
                  <SimilarProductsButton text={t('similarProducts')} onClick={goToSimilarProductList} />
                </ShowIf>
              </ShowIf>
              <ConditionalRender condition={isProductLoading}>
                <CircularProgress color="primary" />
              </ConditionalRender>
            </>
          ) : (
            <>
              <RoundedButton
                variant="outlined"
                style={{
                  backgroundColor: colors.WHITE,
                  color: colors.REBRAND_GREEN,
                }}
                onClick={availability.remove}
              >
                <BellIcon style={{ color: colors.REBRAND_GREEN }} />
                {t('unnotify')}
              </RoundedButton>
              <ShowIf condition={!!similarProductList && similarProductList?.length > 0}>
                <CartDivider orientation="vertical" flexItem />
                <SimilarProductsButton text={t('similarProducts')} onClick={goToSimilarProductList} />
              </ShowIf>
            </>
          )}
        </FlexContainer>
      </>
    );
  }

  if (isAddedToCart || isUnverifiedCartOpen) {
    return (
      <>
        <ShowIf condition={maxQuantityEnabled && remainingStock >= purchaseableQuantity && isDailyPrioritized}>
          <ShowIf condition={cartInfo?.count < purchaseableQuantity}>
            <ShowIf condition={!maxPurchasedReached && purchaseableQuantity < maxPurchaseLimit}>
              <MaxPurchaseContainer>
                <MaxPurchaseText>{t('maxPurchaseRemaining', { value: purchaseableQuantity })}</MaxPurchaseText>
              </MaxPurchaseContainer>
            </ShowIf>
          </ShowIf>
          <ShowIf condition={!maxPurchasedReached && cartInfo?.count >= purchaseableQuantity}>
            <OutOfStockContainer>
              <OutOfStockText>{t('maxPurchaseReached', { value: purchaseableQuantity })}</OutOfStockText>
            </OutOfStockContainer>
          </ShowIf>
        </ShowIf>
        <ShowIf condition={maxMonthlyQuantityEnabled && isWithinMonthlyLimitThreshold && !isDailyPrioritized}>
          <ShowIf condition={cartInfo?.count < purchaseableMonthlyQuantity}>
            <ShowIf condition={!maxPurchaseMonthlyReached}>
              <MaxPurchaseContainer>
                <MaxPurchaseText>
                  {t('maxPurchaseMonthlyThresholdReached', { value: maxPurchaseMonthlyLimit })}
                </MaxPurchaseText>
              </MaxPurchaseContainer>
            </ShowIf>
          </ShowIf>
          <ShowIf condition={!maxPurchaseMonthlyReached && cartInfo?.count >= purchaseableMonthlyQuantity}>
            <OutOfStockContainer>
              <OutOfStockText>{t('maxPurchaseMonthlyReached', { value: maxPurchaseMonthlyLimit })}</OutOfStockText>
            </OutOfStockContainer>
          </ShowIf>
        </ShowIf>
        <FlexContainer style={{ marginLeft: '10px' }}>
          <ShowIf condition={!!similarProductList && similarProductList?.length > 0}>
            <SimilarProductsButton text={t('similarProducts')} onClick={goToSimilarProductList} />
            <CartDivider
              orientation="vertical"
              flexItem
              style={{ marginTop: '5px', marginBottom: '5px', marginRight: '10px' }}
            />
          </ShowIf>
          <PriceContainer>
            <PriceLabel>{t('totalPrice')}:</PriceLabel>
            {shouldShowSubtotal && <SubtotalPrice>{subtotalPrice}</SubtotalPrice>}
            <TotalPrice>{totalPrice}</TotalPrice>
          </PriceContainer>
          {children}
        </FlexContainer>
      </>
    );
  }

  return (
    <>
      <AvailabilityTimer
        show={maxPurchasedReached || maxPurchaseMonthlyReached}
        isMonthly={maxPurchaseMonthlyReached}
        rounded
      />
      <FlexContainer>{children}</FlexContainer>;
    </>
  );
};

Wrapper.defaultProps = {
  similarProductList: undefined,
};

export const CartButton = translate('productDetailPage')(Wrapper);
