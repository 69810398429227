/**
 * Firebase Analytics for WebViews
 * https://firebase.google.com/docs/analytics/android/webview
 */

const isAndroid = typeof window['AnalyticsWebInterface'] !== 'undefined';
const isIOS = typeof window['webkit'] !== 'undefined'
    && typeof window['webkit'].messageHandlers !== 'undefined' 
    && typeof window['webkit'].messageHandlers.firebase !== 'undefined';

export const logEvent = (name, parameters) => {
    try {
        if (!name) {
            return;
        }
    
        if (isAndroid) {
            // Call Android interface
            window['AnalyticsWebInterface'].logEvent(name, JSON.stringify(parameters));
        } 
        else if (isIOS) {
            // Call iOS interface
            const message = {
                command: 'logEvent',
                name,
                parameters
            };
            window['webkit'].messageHandlers.firebase.postMessage(message);
        } 
        else {
            // No Android or iOS interface found
            console.error('No native APIs found for Firebase Analytics.');
        }
    }
    catch(error) {
        console.error('There was an error with analytics:', error.message, error.stack);
    }
}
  
export const setUserProperty = (name, value) => {
    if (!name || !value) {
      return;
    }
  
    if (isAndroid) {
        // Call Android interface
        window['AnalyticsWebInterface'].setUserProperty(name, value);
    } 
    else if (isIOS) {
        // Call iOS interface
        const message = {
            command: 'setUserProperty',
            name,
            value
        };

        window['webkit'].messageHandlers.firebase.postMessage(message);
    } 
    else {
        // No Android or iOS interface found
        console.error('No native APIs found for Firebase Analytics.');
    }
}
  