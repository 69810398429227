import { Action } from 'redux';
import { swipeRxPt } from 'services';
import { IVoucher } from 'services/swipe-rx-pt/resources/voucher/interface/voucher.interface';
import { recordException } from 'utils/Reporting/Sentry';
import { PaymentMethods } from 'utils/constants';
import { VoucherRuleException } from 'services/swipe-rx-pt/errors/VoucherRuleException.error';
import * as constants from './constants';
import { showError } from '../Global/actions';
import { State } from '../../index';
import { ThunkActionCreator } from '../../../types/thunk';

/**
 * Action types
 */
export interface SetAvailableVouchers extends Action {
  type: constants.FETCH_AVAILABLE_VOUCHER;
  payload: { data: IVoucher[]; meta: any; refreshVoucher?: boolean };
}

export interface SetAvailableVouchersFailed extends Action {
  type: constants.FETCH_AVAILABLE_VOUCHER_FAILED;
  error: any;
}

export interface SetAvailableVouchersLoading extends Action {
  type: constants.FETCH_AVAILABLE_VOUCHER_LOADING;
}

export interface SetVoucherEligible extends Action {
  type: constants.SELECTED_VOUCHER_ELIGIBILE;
  payload: IVoucher;
}

export interface SetVoucherEligibilityFailed extends Action {
  type: constants.SELECTED_VOUCHER_ELIGIBILITY_FAILED;
  error: any;
}

export interface SetSelectedVoucher extends Action {
  type: constants.CLEAR_SELECTED_VOUCHER;
  payload: IVoucher;
}

export interface ClearSelectedVoucher extends Action {
  type: constants.CLEAR_SELECTED_VOUCHER;
}

export interface SetVoucherApplied extends Action {
  type: constants.APPLY_VOUCHER;
  payload: IVoucher;
}

export interface SetVoucherApplyFailed extends Action {
  type: constants.APPLY_VOUCHER_FAILED;
  error: any;
}

export interface ClearVoucherApplied extends Action {
  type: constants.CLEAR_APPLIED_VOUCHER;
}

export type Actions =
  | SetAvailableVouchers
  | SetAvailableVouchersFailed
  | SetVoucherEligible
  | SetVoucherEligibilityFailed
  | SetSelectedVoucher
  | ClearSelectedVoucher
  | SetVoucherApplied
  | SetVoucherApplyFailed
  | ClearVoucherApplied
  | SetAvailableVouchersLoading;

export type ApplyVoucherOption = {
  purchaseOrderIds?: number[];
  depositRequestId?: number;
  billCode?: string;
};

export const getAvailableVouchers: ThunkActionCreator<Action> =
  (option?: { voucherCode?: string; page?: number; refreshVoucher?: boolean }) => async (dispatch) => {
    dispatch({ type: constants.FETCH_AVAILABLE_VOUCHER_LOADING });

    try {
      const params: Record<string, any> = {};

      if (option?.voucherCode) params.voucherCode = option?.voucherCode;
      if (option?.page) params.page = option?.page;

      const { data, meta } = await swipeRxPt.voucher.list(params);

      dispatch({
        type: constants.FETCH_AVAILABLE_VOUCHER,
        payload: { data, meta, refreshVoucher: option?.refreshVoucher || (option?.voucherCode && meta.page === 1) },
      });
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'getAvailableVouchers');
      }
      dispatch({
        type: constants.FETCH_AVAILABLE_VOUCHER_FAILED,
        payload: error,
      });
    }
  };

export const checkEligibility: ThunkActionCreator<Action, State> =
  (code: string, options?: ApplyVoucherOption, callback?: (response: any) => void) => async (dispatch) => {
    try {
      dispatch({ type: constants.FETCH_AVAILABLE_VOUCHER_LOADING });

      const response = await swipeRxPt.voucher.isEligible({
        voucherCode: code,
        ...options,
      });

      if (response.isEligible) {
        dispatch({
          type: constants.SELECTED_VOUCHER_ELIGIBILE,
          payload: { ...response.voucher, voucher_usage_id: response.voucherUsage?.id || null },
        });

        if (callback) callback(response);
      } else {
        const message = 'Voucher not eligible';

        dispatch({
          type: constants.SELECTED_VOUCHER_ELIGIBILITY_FAILED,
          error: message,
        });

        dispatch(showError(message));
      }
    } catch (error) {
      let message: string;
      if (error instanceof VoucherRuleException) {
        message = error.message;
      } else {
        message = 'Failed to apply Voucher';
      }

      dispatch({ type: constants.APPLY_VOUCHER_FAILED, error: message });
      recordException(error, 'applyVoucher');
    }
  };

export const applyVoucher: ThunkActionCreator<Action> =
  (paymentMethod: PaymentMethods, code: string, options?: ApplyVoucherOption, callback?: (response: any) => void) =>
  async (dispatch) => {
    try {
      if (paymentMethod !== PaymentMethods.CREDIT_LINE && !options?.billCode && !options?.depositRequestId) {
        throw new Error('No Payment specified');
      }

      const response = await swipeRxPt.voucher.applyVoucher(paymentMethod, {
        voucherCode: code,
        purchaseOrderIds: options?.purchaseOrderIds,
        billCodeOrDepositRequestId: (options?.billCode || options?.depositRequestId) as number | string,
      });

      if (!response) {
        throw new Error('Failed to apply Voucher');
      }

      if (response.isEligible) {
        dispatch({
          type: constants.APPLY_VOUCHER,
          payload: { ...response.voucher, voucher_usage_id: response.voucherUsage?.id || null },
        });

        /* eslint-disable-next-line no-unused-expressions */
        callback && callback(response);
      } else {
        const message = 'Voucher not eligible';

        dispatch({ type: constants.APPLY_VOUCHER_FAILED, error: message });
        dispatch(showError(message));
      }
    } catch (error) {
      let message: string;
      if (error instanceof VoucherRuleException) {
        message = error.message;
      } else {
        message = 'Failed to apply Voucher';
      }

      dispatch({ type: constants.APPLY_VOUCHER_FAILED, error: message });
      recordException(error, 'applyVoucher');
    }
  };

export const setAppliedVoucher: ThunkActionCreator<Action> = (voucher: IVoucher) => async (dispatch) => {
  dispatch({
    type: constants.SELECTED_VOUCHER_ELIGIBILE,
    payload: voucher,
  });
};

export const setVoucherError: ThunkActionCreator<Action> = (error: string) => async (dispatch) => {
  dispatch({ type: constants.APPLY_VOUCHER_FAILED, error });
};

export const setSelectedVoucher: ThunkActionCreator<Action> = (voucher: IVoucher) => async (dispatch) => {
  dispatch({
    type: constants.SET_SELECTED_VOUCHER,
    payload: voucher,
  });
};

export const clearSelectedVoucher: ThunkActionCreator<Action> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_SELECTED_VOUCHER,
  });
};

export const clearVoucherApplied: ThunkActionCreator<Action> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_APPLIED_VOUCHER,
  });
};
