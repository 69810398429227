import { IHomesquareIcons } from 'components/common';
import { useEffect, useState } from 'react';
import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { v3Client } from 'utils/Client/v3';

interface IParams {
  marketingId: number;
  marketingType: string;
  fetchAllowed: boolean;
}

interface IUseHomesquare {
  data: IHomesquareIcons[];
  isLoading: boolean;
}

export const useHomesquareCarousel = ({ marketingId, marketingType, fetchAllowed }: IParams): IUseHomesquare => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!fetchAllowed) return;
    if (!marketingId || marketingId < 0) return;
    if (!marketingType || marketingType !== MarketingType.HOMESQUARE) return;

    const fetchHomesquare = async (): Promise<void> => {
      setIsLoading(true);
      const res = await v3Client.get('homesquares', {
        fields: ['id', 'title', 'icon_url', 'display_priority', 'listing_link'],
        visibility: true,
        marketing_id: marketingId,
        order_by: 'display_priority',
        sort_by: 'asc',
      });
      setIsLoading(false);
      const { data } = res || {};

      setData(data);
    };

    fetchHomesquare();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllowed]);

  return {
    data,
    isLoading,
  };
};
