/* eslint-disable class-methods-use-this */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import { getMarketId } from 'utils/MarketConfig';

import { DataObject } from 'types';
import { VoucherRuleError } from 'components/pages/PaymentsPage/components/Voucher/type';
import { registerRefreshTokenInterceptor } from 'utils/Client/refreshToken/refreshToken.interceptor';
import { PaymentError } from './errors/Payment.error';
import { LimitedActivePaymentError } from './errors/LimitedActivePayment.error';
import { ERROR_CODE_ENUM } from './errors/error.enum';

import {
  SwipeRxPtOrders,
  SwipeRxPtDirectPaymentBills,
  SwipeRxPtPaymentDirect,
  SwipeRxPtOrderRequirement,
  SwipeRxPtVoucher,
  SwiperxPtVoucherTracking,
  SwipeRxPtCart,
  SwipeRxPtDeposit,
  SwipeRxPtInvoice,
  SwipeRxPtInvoiceAllocation,
  SwiperxPtAccount,
  SwipeRxPtCreditRequest,
  SwipeRxPtLogisticsPharmacyArea,
  SwiperxPtReturns,
  SwipeRxPtLogisticsDelivery,
  SwipeRxPtRating,
  SwiperxPtPrecursorStatus,
} from './resources';
import { VoucherRuleException } from './errors/VoucherRuleException.error';
import { ProductUnpurchaseable } from './errors/ProductUnpurchaseable.error';
import { PharmacySuspendedError } from './errors/PharmacySuspendedError';
import { MaxPurchaseLimitReachedError } from './errors/MaxPurchaseLimitReached.error';
import { UploadPrecursorApprovedFailedError } from './errors/UploadPrecursorApprovedFailed.error';
import { CartMaxLimitExceededError } from './errors/CartMaxLimitExceeded.error';

type SwipeRxPtInstance = Omit<SwipeRxPt, 'get'>;

export class SwipeRxPt {
  readonly orders: SwipeRxPtOrders;

  readonly paymentDirectBills: SwipeRxPtDirectPaymentBills;

  readonly paymentDirect: SwipeRxPtPaymentDirect;

  readonly orderRequirement: SwipeRxPtOrderRequirement;

  readonly voucher: SwipeRxPtVoucher;

  private readonly accessToken: string;

  readonly voucherTracking: SwiperxPtVoucherTracking;

  readonly cart: SwipeRxPtCart;

  readonly deposit: SwipeRxPtDeposit;

  readonly invoice: SwipeRxPtInvoice;

  readonly invoiceAllocation: SwipeRxPtInvoiceAllocation;

  readonly accounts: SwiperxPtAccount;

  readonly creditRequest: SwipeRxPtCreditRequest;

  readonly logisticsPharmacyArea: SwipeRxPtLogisticsPharmacyArea;

  readonly returns: SwiperxPtReturns;

  readonly logisticsDelivery: SwipeRxPtLogisticsDelivery;

  readonly rating: SwipeRxPtRating;

  readonly prekursorStatus: SwiperxPtPrecursorStatus;

  private readonly httpClient: AxiosInstance;

  constructor(baseURL: string) {
    this.accessToken = '';
    this.httpClient = axios.create({ baseURL });

    this.httpClient.interceptors.request.use((config) => {
      const newConfig = { ...config };
      const marketId = getMarketId();
      if (newConfig.baseURL?.split('/').pop() !== marketId) {
        newConfig.baseURL += `/${marketId}`;
      }
      return newConfig;
    });

    registerRefreshTokenInterceptor(this.httpClient);

    this.orders = new SwipeRxPtOrders(this);
    this.paymentDirectBills = new SwipeRxPtDirectPaymentBills(this);
    this.paymentDirect = new SwipeRxPtPaymentDirect(this);
    this.orderRequirement = new SwipeRxPtOrderRequirement(this);
    this.voucher = new SwipeRxPtVoucher(this);
    this.voucherTracking = new SwiperxPtVoucherTracking(this);
    this.cart = new SwipeRxPtCart(this);
    this.deposit = new SwipeRxPtDeposit(this);
    this.invoice = new SwipeRxPtInvoice(this);
    this.invoiceAllocation = new SwipeRxPtInvoiceAllocation(this);
    this.accounts = new SwiperxPtAccount(this);
    this.creditRequest = new SwipeRxPtCreditRequest(this);
    this.logisticsDelivery = new SwipeRxPtLogisticsDelivery(this);
    this.logisticsPharmacyArea = new SwipeRxPtLogisticsPharmacyArea(this);
    this.rating = new SwipeRxPtRating(this);
    this.returns = new SwiperxPtReturns(this);
    this.prekursorStatus = new SwiperxPtPrecursorStatus(this);
  }

  /**
   * @deprecated use getV2, will replace in the next release once `getV2` is proven to be stable
   * @param path
   * @param params
   */
  async get(path: string, params?: DataObject): Promise<any> {
    const finalPath = params ? this.appendParamsToPath(path, params) : path;

    try {
      const { data } = await this.httpClient.get(finalPath, this.getConfig());

      return data;
    } catch (e) {
      return this.throwError(e);
    }
  }

  /**
   * Params are longer `order_ids[]=200&order_ids[]=300` but supported by backend without needing to transform/serialize it `order_ids=100,200,300`.
   *
   * For example:
   * On the API backend  GET /orders - `order.services.ts` -> params (2nd parameter) -> purcahse-order-options.type.ts -> order_ids: number[].
   *  This will be used directly to `findAndPaginate`. Point is, `order_ids[]=200&order_ids[]=300` is directly to converted to array natively by express/nestjs.
   *
   * References: https://github.com/axios/axios/issues/2510, https://github.com/OperationCode/resources_api/pull/303
   *
   * @param path
   * @param params
   */
  async getV2(path: string, params?: DataObject): Promise<any> {
    try {
      const { data } = await this.httpClient.get(path, {
        ...this.getConfig(),
        params,
      });

      return data;
    } catch (e) {
      return this.throwError(e);
    }
  }

  async post(path: string, payload?: DataObject, params?: DataObject): Promise<any> {
    const finalPath = params ? this.appendParamsToPath(path, params) : path;

    try {
      const { data } = await this.httpClient.post(finalPath, payload, this.getConfig());

      return data;
    } catch (e) {
      return this.throwError(e);
    }
  }

  async postMultipart(path: string, payload: FormData, params?: DataObject): Promise<any> {
    const finalPath = params ? this.appendParamsToPath(path, params) : path;

    const config = this.getConfig();
    config.headers['Content-Type'] = 'multipart/form-data';

    try {
      const { data } = await this.httpClient.post(finalPath, payload, config);

      return data;
    } catch (e) {
      return this.throwError(e);
    }
  }

  async put(path: string, payload?: DataObject, params?: DataObject): Promise<any> {
    const finalPath = params ? this.appendParamsToPath(path, params) : path;

    try {
      const { data } = await this.httpClient.put(finalPath, payload, this.getConfig());

      return data;
    } catch (e) {
      return this.throwError(e);
    }
  }

  async patch(path: string, payload?: DataObject, params?: DataObject): Promise<any> {
    const finalPath = params ? this.appendParamsToPath(path, params) : path;

    try {
      const { data } = await this.httpClient.patch(finalPath, payload, this.getConfig());

      return data;
    } catch (e) {
      return this.throwError(e);
    }
  }

  async delete(path: string, params?: DataObject): Promise<any> {
    const finalPath = params ? this.appendParamsToPath(path, params) : path;

    try {
      const { data } = await this.httpClient.delete(finalPath, this.getConfig());

      return data;
    } catch (e) {
      return this.throwError(e);
    }
  }

  private appendParamsToPath(path: string, params: DataObject): string {
    // FIXME: should work on changing this to BRACKET_QS_OPTIONS
    return `${path}?${qs.stringify(params, {
      arrayFormat: 'comma',
      skipNull: true,
    })}`;
  }

  private getConfig(): AxiosRequestConfig {
    const bearerToken = this.accessToken || localStorage.getItem('access_token');

    return {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'x-warp-api-key': process.env.REACT_APP_PH_API_KEY,
      },
    };
  }

  private throwError(e: any): void {
    if (e.response) {
      const { data } = e.response;
      if (data.errorCode && data.errorCode === ERROR_CODE_ENUM.DIGIO_ERROR) {
        throw new PaymentError(data.error || data.message);
      }

      if (data.errorCode && data.errorCode === ERROR_CODE_ENUM.PRODUCT_UNPURCHASEABLE_ERROR) {
        throw new ProductUnpurchaseable(data.error || data.message);
      }

      if (data.errorCode && data.errorCode === ERROR_CODE_ENUM.CREATE_FAILED_ACTIVE_EXIST) {
        throw new LimitedActivePaymentError(data.error || data.message);
      }

      if (data.errorCode && data.errorCode === ERROR_CODE_ENUM.PHARMACY_SUSPENDED_ERROR) {
        throw new PharmacySuspendedError(data.error || data.message);
      }

      if (
        Object.values(VoucherRuleError).includes(data.error) ||
        Object.values(VoucherRuleError).includes(data.message)
      ) {
        throw new VoucherRuleException(data.error || data.message);
      }

      if (data.errorCode && data.errorCode === ERROR_CODE_ENUM.MAX_PURCHASE_LIMIT_REACHED) {
        throw new MaxPurchaseLimitReachedError(data.errorCode, data.error);
      }

      if (data.errorCode && data.errorCode === ERROR_CODE_ENUM.MAX_SKU_LIMIT_REACHED) {
        throw new CartMaxLimitExceededError(data.errorCode, data.error);
      }

      if (data.errorCode && data.errorCode === ERROR_CODE_ENUM.UPLOAD_PRECURSOR_APPROVED_FAILED) {
        throw new UploadPrecursorApprovedFailedError(data.errorCode, data.error);
      }

      throw new Error(data.error || data.message);
    }

    throw new Error(e.message);
  }
}

export const swipeRxPt: SwipeRxPtInstance = new SwipeRxPt(process.env.REACT_APP_PH_API_VTHREE as string);
