/* eslint-disable max-len */
export interface ImageDimension {
  src: string;
  w: number;
  h: number;
}

export const getImageDimensions = (url: string, defaultWidth = 800, defaultHeight = 600): Promise<ImageDimension> =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () =>
      resolve({
        src: url,
        w: img.width,
        h: img.height,
      });
    img.onerror = () =>
      resolve({
        src: url,
        w: defaultWidth,
        h: defaultHeight,
      });
  });
