import React from 'react';
import * as colors from 'components/styles/colors';

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

export const HomeIconOutlined: React.FC<React.PropsWithChildren<Props>> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 17"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z" />
  </svg>
);

HomeIconOutlined.defaultProps = {
  fill: colors.PRIMARY_COLOR,
  width: '21px',
  height: '21px'
};
