import React, { useEffect } from 'react';
import { TabPage, A11yProps } from './Tab.types';
import { TabComponent } from './Tab.component';

interface Props extends Record<string, unknown> {
  tabs: TabPage[];
  tabContents: React.FC<React.PropsWithChildren<unknown>>;
  pathname: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  noBrowserHistory?: boolean;
}

const a11yProps = (index: number): A11yProps => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabBase: React.FC<React.PropsWithChildren<Props>> = ({ tabs, tabContents, pathname, style, fullWidth, ...restProps }) => {
  const index = tabs.findIndex((tab) => tab.path === pathname);
  const [active, setActive] = React.useState(index);

  useEffect(() => {
    setActive(index);
  }, [pathname, index]);

  const tabProps = {
    tabs,
    active,
    a11yProps,
    style,
    tabContents,
    fullWidth,
    ...restProps,
  };

  return <TabComponent {...tabProps} />;
};

export const Tab = TabBase;

TabBase.defaultProps = {
  style: {},
  fullWidth: false,
  noBrowserHistory: false,
};
