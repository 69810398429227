import styled from 'styled-components';
import { Grid, Card } from '@material-ui/core';
import { breakpoints } from 'components/styles/breakpoints';

const Wrapper = styled(Grid)`
  padding: 5px 0;
`;

const ProductCardContainer = styled(Card)`
  && {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 5;
    transition: 0.3s ease-in;
  }
`;

const ProductImageContainer = styled(Grid)`
  && {
    margin-top: -3px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const ProductDetailsContainer = styled(Grid)`
  && {
    width: 100%;
    margin-left: 8px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media (max-width: ${breakpoints.phoneSmall}) {
    && {
      padding-top: 5px;
    }
  }
`;

const PricingContainer = styled(Grid)`
  && {
    width: 100%;
    padding-top: 10px;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const S = {
  Wrapper,
  ProductCardContainer,
  ProductImageContainer,
  ProductDetailsContainer,
  PricingContainer,
};
