import { SwipeRxPt } from '../../SwipeRxPt.class';
import { AccountStatusResDto } from './interfaces';

export class SwiperxPtAccount {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async memberships(orgId: number): Promise<AccountStatusResDto> {
    const { data } = await this.base.getV2(`/organizations/${orgId}/account-status`);

    return data;
  }
}
