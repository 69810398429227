import styled from 'styled-components';

import { BLUE, WHITE } from 'components/styles/colors';

export const CartNotificationContainer = styled.div`
  position: sticky;
  bottom: 100px;
  margin: 12px;

  .card {
    padding: 10px;
    box-shadow: none;
    border-radius: 8px;
    background: ${BLUE};

    .grid-view {
      .cart-text {
        line-height: 0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12pt;
        color: ${WHITE};
      }
    }

    .basket-icon {
      color: ${WHITE};
    }
  }
`;
