import { BasePageTracker, trackRecentlyViewedPage, trackSearchSection } from 'utils/Analytics/Segment';
import { deleteRecentlySearchedKey } from 'store/reducers/Search/actions';
import { ISearchItem, ISearchSection } from 'components/common/SearchMenuSuggestions/components/interface';
import { SectionType } from 'components/common/SearchMenuSuggestions/components/search-menu/SectionFactory';

import { CampaigBasedSuggestionContainer } from 'components/common/SearchMenuSuggestions/components/search-menu-section/CampaignBasedSuggestionContainer.component';
import { MostPopularProduct } from 'store/reducers/Search';
import MarketingFeatureSection from 'components/common/SearchMenuSuggestions/components/search-menu-section/MarketingFeatureSection.component';

export function buildCampaingBasedSearchSection(): ISearchSection | undefined {
  return {
    id: BasePageTracker.CAMPAIGN_BASED_SEARCH,
    title: 'campaignBased',
    display: SectionType.COMPONENT,
    SectionComponent: CampaigBasedSuggestionContainer,
  };
}

export function buildMostPopularSection(mostPopularProducts: MostPopularProduct[]): ISearchSection | undefined {
  return {
    id: BasePageTracker.MOST_POPULAR_SEARCH,
    title: 'mostPopular',
    products: mostPopularProducts as unknown as ISearchItem[],
    shouldNotShowLoadingIfEmpty: true,
    limit: 5,
    display: SectionType.LIST,
  };
}

export function buildRecentlySearchSection(recentSearchProducts: ISearchItem[], dispatch): ISearchSection | undefined {
  return {
    id: BasePageTracker.RECENTLY_SEARCHED_SEARCH,
    title: 'recentlySearched',
    products: recentSearchProducts,
    shouldNotShowLoadingIfEmpty: true,
    display: SectionType.CHIP,
    limit: 5,
    expandable: true,
    onDelete: (value) => {
      dispatch(deleteRecentlySearchedKey(value.name));
    },
    onExpand: () => {
      trackSearchSection({ result_count: recentSearchProducts?.length });
    },
  };
}

export function buildRecentlyViewedSection(recentlyViewedProducts: any[]): ISearchSection | undefined {
  return {
    id: BasePageTracker.RECENTLY_VIEWED_SEARCH,
    title: 'recentlyViewed',
    products: recentlyViewedProducts,
    shouldNotShowLoadingIfEmpty: false,
    display: SectionType.LIST,
    limit: 3,
    expandable: true,
    onExpand: () => {
      trackRecentlyViewedPage();
    },
  };
}

export function buildMarketingFeaturesSection(): ISearchSection | undefined {
  return {
    id: BasePageTracker.BLANK,
    title: '',
    display: SectionType.COMPONENT,
    SectionComponent: MarketingFeatureSection,
  };
}
