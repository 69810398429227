import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { State as StoreState } from 'store';
import { setCheckedOptions, setFeedbacks } from 'store/reducers/Orders/actions';
import { TFunction } from 'utils/Localization';

import ShowIf from 'components/views/ShowIf';
import { CurrentOrderRating } from 'store/reducers/Orders';
import { S } from './orders-rating.styles';

export interface FeedbackSectionProps {
  t: TFunction;
  currentOrderRating?: CurrentOrderRating | null;
}

export const FeedbackSection = React.forwardRef<any, FeedbackSectionProps>((props, ref) => {
  const { t, currentOrderRating } = props;
  const dispatch = useDispatch();

  const {
    config,
    orders: {
      orderRating: { checkedOptions, feedbackOptions, feedbacks, rate },
    },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const lang = config.market.language || 'id';

  const handleCheck = (event) => {
    if (currentOrderRating) return;

    const value = event.target.name;
    const newCheckedOptions = checkedOptions.includes(value)
      ? checkedOptions.filter((option) => option !== value)
      : [...checkedOptions, value];

    dispatch(setCheckedOptions(newCheckedOptions));
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentOrderRating) return;

    if (event.target.value.length <= 300) {
      dispatch(setFeedbacks(event.target.value));
    }
  };

  const feedbackOption = feedbackOptions.find((opt) => opt.rate === rate) || { rate: 0, feedbacks: [] };

  return (
    <div style={{ marginTop: 20, marginBottom: 80 }}>
      <S.FeedbackSectionTitle>
        {(rate || 0) >= 4 ? t('enjoyedLetUsKnow') : t('whatCouldBeImproved')}
      </S.FeedbackSectionTitle>
      <S.FeedbackSectionCard>
        <ShowIf condition={(currentOrderRating?.feedbacks || []).length > 0 || feedbackOption.feedbacks.length > 0}>
          <FormControl component="fieldset">
            <ShowIf condition={!currentOrderRating}>
              <FormGroup>
                {feedbackOption.feedbacks
                  .filter((option) => option.lang === lang)
                  .map((option) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedOptions.includes(option.text)}
                          onChange={handleCheck}
                          name={option.text}
                          color="primary"
                        />
                      }
                      label={option.text}
                    />
                  ))}
              </FormGroup>
            </ShowIf>
            <ShowIf condition={!!currentOrderRating && currentOrderRating.feedbacks.length > 0}>
              <FormGroup>
                {(currentOrderRating?.feedbacks || []).map((option) => (
                  <FormControlLabel
                    control={<Checkbox checked onChange={handleCheck} name={option} color="primary" />}
                    label={option}
                  />
                ))}
              </FormGroup>
            </ShowIf>
          </FormControl>
        </ShowIf>

        <S.FeedbackSectionSubTitle marginTop={feedbackOption.feedbacks.length > 0 ? 20 : 0}>
          {t('tellUsMore')} ({t('optional')})
        </S.FeedbackSectionSubTitle>
        <S.FeedbackSectionTextarea
          ref={ref}
          placeholder={t('yourOpinionMatter')}
          value={currentOrderRating?.open_feedback || feedbacks}
          onChange={handleInput}
          disabled={!!currentOrderRating}
        />
      </S.FeedbackSectionCard>
    </div>
  );
});
