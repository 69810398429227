import * as Redux from 'redux';

import * as constants from './constants';
import { Actions } from './actions';

export interface Credit {
  credit_limit: number;
  remaining_credit: number;
  amount_payable: number;
  amount_used: number;
  amount_paid: number;
}

export interface State {
  details: Credit;
  error?: string;
}

const InitialState: State = {
  details: {
    credit_limit: 0,
    remaining_credit: 0,
    amount_payable: 0,
    amount_used: 0,
    amount_paid: 0,
  },
};

const CreditReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.UPDATE_CREDIT:
      return {
        ...state,
        details: action.details,
      };
    case constants.UPDATE_PRICES:
      return {
        ...state,
        items: action.items,
        settings: action.settings,
      };
    case constants.UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case constants.FAIL_CREDIT:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default CreditReducer;
