import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Flipper, Flipped } from 'react-flip-toolkit';

import Grid from '@material-ui/core/Grid';

import { ChatBubble } from 'components/pages/MainPage/components/chat-bubble';
import { SectionCardCloseButton } from './SectionCardCloseButton.component';
import { SectionCardButton } from './SectionCardButton.component';
import { SectionCardSearchBar } from './SectionCardSearchBar.component';

import { S } from './SectionCard.style';

import { toggleSectionCard } from '../../../store/reducers';
import { State } from '../../../store';

interface StateProps {
  expanded: boolean;
}

interface DispatchProps {
  toggleSectionCard: typeof toggleSectionCard;
}

interface Config {
  color: string;
  dark?: boolean;
  end?: boolean;
  expandBtnText?: string;
  name: string;
  noBorderRadius?: boolean;
  style?: React.CSSProperties;
  onClose?: () => void;
  onExpand?: () => void;
  onSearch?: (query: string) => void;
}

export interface OwnProps {
  config: Config;
  content?: React.ReactChild | React.ReactChild[];
  description?: string | React.ReactChild;
  title?: string | React.ReactChild;
}

type Props = StateProps & DispatchProps & OwnProps;

export const Actions: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Grid container direction="column" justify="center" alignItems="stretch">
    <Grid item>{children}</Grid>
  </Grid>
);

export const SectionCardTitle = S.Title;

const SectionCardBase: React.FC<React.PropsWithChildren<Props>> = ({
  config: { color, dark, end, expandBtnText, name, noBorderRadius, onClose, onExpand, onSearch, style },
  content,
  description,
  title,
  toggleSectionCard,
  expanded,
}) => {
  useEffect(() => {
    toggleSectionCard(name, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpand = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (onExpand) {
      onExpand();
    }

    toggleSectionCard(name);
  };

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }

    toggleSectionCard(name);
  };

  const ContentItem: React.FC<React.PropsWithChildren<{ item: React.ReactChild; lastContent: boolean }>> = ({ item, lastContent }) => (
    <S.Content dark={dark} end={lastContent} expanded={!description && expanded}>
      {item}
    </S.Content>
  );

  const renderContent = (): React.ReactChild | React.ReactChild[] | null => {
    if (!content) {
      return null;
    }

    if (Array.isArray(content)) {
      return content.map((item, i) => <ContentItem item={item} lastContent={i === content.length - 1} />);
    }

    return <ContentItem item={content} lastContent />;
  };

  const allowExpand = expandBtnText && !expanded;

  return (
    <Flipper
      flipKey={expanded}
      spring={{
        stiffness: 500,
        damping: 50,
      }}
      staggerConfig={{
        default: {
          speed: 1,
        },
      }}
    >
      <Flipped flipId="section-card">
        <S.Wrapper
          className={`section-card ${expanded ? 'expanded' : ''}`}
          color={color}
          dark={dark}
          end={end}
          expanded={expanded}
          hasSearch={!!onSearch}
          noBorderRadius={noBorderRadius}
          style={style}
        >
          <Flipped inverseFlipId="section-card" scale>
            <>
              {expanded ? <SectionCardCloseButton onClick={handleClose} /> : null}

              {title ? <S.Title gutter={!description}>{title}</S.Title> : null}

              {description ? (
                <S.Description>
                  <Grid alignItems="center" container>
                    <div style={{ marginRight: 'auto' }}>{description}</div>
                    <div>
                      <ChatBubble color={dark ? '#000000' : '#ffffff'} />
                    </div>
                  </Grid>
                </S.Description>
              ) : null}

              {renderContent()}

              {allowExpand ? <SectionCardButton onClick={handleExpand}>{expandBtnText}</SectionCardButton> : null}

              {expanded && onSearch ? (
                <SectionCardSearchBar dark={dark} color={color} onClose={handleClose} onSearch={onSearch} />
              ) : null}
            </>
          </Flipped>
        </S.Wrapper>
      </Flipped>
    </Flipper>
  );
};

const mapStateToProps = ({ sectionCard }: State, { config: { name } }: OwnProps): StateProps => ({
  expanded: (sectionCard[name] || {}).expanded,
});

const dispatchProps: DispatchProps = { toggleSectionCard };

export const SectionCard = connect<StateProps, DispatchProps, OwnProps, State>(
  mapStateToProps,
  dispatchProps,
)(SectionCardBase);
