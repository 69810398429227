import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Info from '@material-ui/icons/Info';
import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG } from 'services';

import styled from 'styled-components';

import { ORANGE, GRAY31 } from '../../../../styles/colors';

import PKSIcon from '../../../../../images/prekursor-icon.svg';

const Wrapper = styled(Grid).attrs({ container: true, spacing: 1, alignItems: 'flex-start' })`
  && {
    background: ${ORANGE}25;
    border-radius: 4px;
    padding: 10px;
  }
`;

const Label = styled(Typography)`
  && {
    font-size: 14px;
    color: ${GRAY31};
    padding-left: 5px;
    padding-right: 5px;
    line-height: 15px;
  }
`;

const InstructionButton = styled(Button).attrs({ startIcon: <Info />, size: 'small' })`
  && {
    font-size: 10px;
    text-transform: uppercase;
    color: ${ORANGE};
    font-weight: 700;
  }

  && .MuiButton-startIcon {
    margin-right: 4px;
    .MuiSvgIcon-root {
      font-size: 12px;
      margin-bottom: 3px;
    }
  }
`;

interface DocumentIconProps {
  flag: string;
}

const DocumentIcon = styled.img.attrs((props: DocumentIconProps) => {
  let icon: React.ReactNode = null;
  let style: Record<string, any> = {};

  if (props.flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG) {
    icon = PKSIcon;
    style = { ...style, width: '25px', height: '28px' };
  }

  return { src: icon, style };
})<DocumentIconProps>``;

export const S = { DocumentIcon, Wrapper, Label, InstructionButton };
