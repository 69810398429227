export enum BannerVisibility {
  Visible = 'visible',
  Hidden = 'hidden',
}

export interface IBanner {
  id: number;
  title: string;
  banner_url: string;
  banner_link: string;
  display_priority: number;
  visibility: BannerVisibility;
}
