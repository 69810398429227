import { ISearchMenuSuggestions } from 'components/common/SearchMenuSuggestions/searchMenuSuggestion.interface';
import { BasePageTracker, getIsMarketingListing, getIsSimilarProductListing } from 'utils/Analytics/Segment';
import {
  ListingPageConfig,
  SearchPlaceHolderOptions,
  useListingPageConfigParam,
} from '../ProductListingPage.interface';

interface DefaultParams {
  page_size: number;
  page: number;
  url?: string;
  limit: number;
}

interface SearchParameters {
  [key: string]: string[] | string | undefined;
  filters?: string[];
  sorts?: string[];
}

interface DefaultProps {
  full: boolean;
  key: string;
  listingPage: BasePageTracker;
  placeholder: string;
}

interface SearchProps extends Partial<ISearchMenuSuggestions> {
  params?: {
    url?: string;
    marketing_id?: string | number;
    organization_id?: number;
    searchParameters?: SearchParameters;
  };
}

const updateSearchUrl = (
  params: useListingPageConfigParam,
  listingPageConfig: ListingPageConfig,
): string | undefined => {
  let { searchUrl } = listingPageConfig;
  Object.keys(params).forEach((key) => {
    if (searchUrl) {
      const paramsValue = key === 'marketing_type' ? params[key] : params[key];
      searchUrl = searchUrl.replace(`{{${key}}}`, paramsValue);
    }
  });
  return searchUrl;
};

export const updateSearchResultsUrl = (url?: string): string | undefined => {
  if (url) return `${url}/search`;
  return undefined;
};

const URL_TEMPLATE_CONVERTIBLE_PAGES = [
  BasePageTracker.MARKETING_LISTING,
  BasePageTracker.MARKETING_LISTING_SEARCH,
  BasePageTracker.LAST_PURCHASED,
  BasePageTracker.LAST_PURCHASED_SEARCH,
  BasePageTracker.RECENTLY_VIEWED,
  BasePageTracker.RECENTLY_VIEWED_SEARCH,
  BasePageTracker.SIMILAR_PRODUCTS,
];

export const updateListingConfigByParam = (
  params: useListingPageConfigParam,
  listingPageConfig?: ListingPageConfig,
  // FIXME: delete if not needed
  _locationSearch?: { query?: string; category_ids?: string },
): ListingPageConfig | undefined => {
  if (!listingPageConfig) return undefined;
  const updatedPageConfig = {
    ...listingPageConfig,
  };

  // FIXME: verify if this can be omitted
  // if (listingPageConfig.shownFilters.includes(FilterType.Category) && locationSearch?.category_ids) {
  //   const { category_ids } = locationSearch;
  //   const selectedCategory = category_ids.split(',').map((cat) => `${FilterType.Category}-${cat}`);
  //   updatedPageConfig.defaultFilter = selectedCategory;
  // }

  const allowToConvert = URL_TEMPLATE_CONVERTIBLE_PAGES.includes(listingPageConfig.page);
  if (allowToConvert && params.marketing_id && params.organization_id) {
    const searchUrl = updateSearchUrl(params, listingPageConfig);
    updatedPageConfig.searchUrl = searchUrl;
  }

  return updatedPageConfig;
};

const recentlyViewBasePages = [BasePageTracker.RECENTLY_VIEWED, BasePageTracker.RECENTLY_VIEWED_SEARCH];
const recentSearchPages = [
  BasePageTracker.LAST_PURCHASED,
  BasePageTracker.LAST_PURCHASED_SEARCH,
  ...recentlyViewBasePages,
];

const getMarketingSearchProps = (
  defaultProps: DefaultProps,
  defaultParams: DefaultParams,
  params: SearchProps['params'],
): ISearchMenuSuggestions => {
  const marketingSearchProps = {
    ...defaultProps,
    params: {
      ...defaultParams,
      limit: 15,
      marketing_id: params?.marketing_id,
      organization_id: params?.organization_id,
      params: {
        order_by: 'name',
        sort_by: 'asc',
        segmentationChecking: true,
        ...params?.searchParameters,
      },
    },
    suggestionParams: {
      url: params?.url,
      params: { fields: ['id', 'name', 'package'], page: 1, page_size: 5 },
    },
  };

  return marketingSearchProps;
};

const getSimilarProductProps = (
  defaultProps: DefaultProps,
  defaultParams: DefaultParams,
  params: SearchProps['params'],
  listingPage: BasePageTracker,
): ISearchMenuSuggestions => {
  const similarProductSearchProps = {
    ...defaultProps,
    params: {
      ...defaultParams,
      limit: 20,
      params: { sku_code: params?.searchParameters?.sku_code },
    },
    listingPage,
  };

  return similarProductSearchProps;
};

const getRecentMarketingSearchProps = (
  defaultProps: DefaultProps,
  defaultParams: DefaultParams,
  params: SearchProps['params'],
  listingPage: BasePageTracker,
): ISearchMenuSuggestions => {
  const recentlyPurchasedProps = {
    ...defaultProps,
    params: {
      ...defaultParams,
      organization_id: params?.organization_id,
      // TODO: Remove redundant params inside params
      params: {
        ...params?.searchParameters,
      },
    },
    suggestionParams: {
      url: params?.url,
      params: {
        ...defaultParams,
        fields: ['id', 'name', 'package'],
        product_ids: recentlyViewBasePages.includes(listingPage) ? params?.searchParameters?.product_ids : undefined,
      },
    },
  };

  return recentlyPurchasedProps;
};

export const getSearchProps = (
  listingPage: BasePageTracker,
  placeholder: string,
  params?: {
    url?: string;
    marketing_id?: string | number;
    organization_id?: number;
    searchParameters?: any & { filters?: string[]; sorts?: string[]; };
  },
): ISearchMenuSuggestions => {
  const defaultParams = {
    page_size: 15,
    page: 1,
    url: params?.url,
    limit: 15,
  };

  const defaultSuggestionsParams = {
    url: 'products/search/suggestions',
  };

  const defaultProps = {
    full: true,
    key: placeholder,
    listingPage,
    placeholder,
  };

  const isSimilarProduct = getIsSimilarProductListing(listingPage);
  const isMarketingListing = getIsMarketingListing(listingPage);
  // NOTE: specific query for search marketing
  if (isMarketingListing) {
    return getMarketingSearchProps(defaultProps, defaultParams, params);
  }

  if (isSimilarProduct) {
    return getSimilarProductProps(defaultProps, defaultParams, params, listingPage);
  }

  if (recentSearchPages.includes(listingPage)) {
    return getRecentMarketingSearchProps(defaultProps, defaultParams, params, listingPage);
  }

  const resDefaultProps = {
    ...defaultProps,
    params: { ...defaultParams, params: params?.searchParameters },
    suggestionParams: defaultSuggestionsParams,
  };

  return resDefaultProps;
};

export const getSearchPlaceHolder = (searchPlaceHolderOptions: SearchPlaceHolderOptions, t: any): string => {
  const { productListingName } = searchPlaceHolderOptions;
  const searchPlaceHolder = productListingName ? t('search', { productListingName }) : t('searchPlaceholder');
  return searchPlaceHolder;
};
