import numeral from 'numeral';
import { ID_LOCALE } from '../../Localization';

// Formatting for id numerals
numeral.register('locale', ID_LOCALE, {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(num: number) {
    const b = num % 10;
    // Double tilde refer to : https://stackoverflow.com/a/12299678
    // eslint-disable-next-line no-bitwise
    return ~~((num % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
  },
  currency: {
    symbol: 'Rp',
  },
});
