import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

const FullGrid = styled(Grid)`
  height: 100%;
  flex-direction: column;
`;

const Container: React.FC<React.PropsWithChildren<unknown>> = ({ children, ...props }) => (
  <FullGrid container direction="column" {...props}>
    {children}
  </FullGrid>
);

export default Container;
