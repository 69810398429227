import { useOrderStatus } from 'hooks';
import React from 'react';
import { Order, OrderStatus } from 'services';
import { DataObject } from 'types';

import { S } from './orders-status-modal-note.styles';

interface Props {
  t: any;
  order: Order | DataObject;
  delivery: Order['logistics_delivery_status'];
}

export const OrderStatusModalNote: React.FC<React.PropsWithChildren<Props>> = ({ order, delivery = null, t }) => {
  const { status } = useOrderStatus(order, t);

  const { comments, cancellation_reason } = order;

  let note = comments || cancellation_reason;
  if (status === OrderStatus.DELIVERED) {
    note = t(`orderNote.${OrderStatus.DELIVERED}`);
  } else if (delivery?.status_detail) {
    note = t([`orderNote.${delivery.status_detail}`, 'orderNote.DEFAULT']);
  }

  return (
    <S.Wrapper>
      <S.MiniLabel>{t('note')}</S.MiniLabel>
      <S.NoteText>{note}</S.NoteText>
    </S.Wrapper>
  );
};
