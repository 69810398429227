import { AnalyticsEvent } from '../interfaces';

import { pageTracker } from '.';
import { makeAnalyticsRequest } from './make-analytics-request';
import {
  FavoritedProductAnalayticsProps,
  IMappingProductDataAnalytic,
  ImpressionDataAnalyticProps,
  InstockReminderDataAnalyticProps,
  ProductAnalyticsProps,
  ProductDetailDataAnalyticsProps,
  ProductListPageAnalyticsProps,
  ProductSimpleAnalyticsProps,
  ProductSuggestionDataAnalyticsProps,
  SearchDataAnalyticsProps,
  SearchSectionProps,
  SimilarProductListPageAnalyticsProps,
  HomesquareWidgetProps,
  FilterSortDataProps,
  StoredPharmacyIdPayload,
  CommunityDataProps,
} from './segment.types';

export const storedPharmacyId = (): string => {
  const organizationId = localStorage.getItem('organization_id') || '';
  return organizationId.toString();
};

export const trackSearch = (searchInfo: SearchDataAnalyticsProps): void => {
  makeAnalyticsRequest('pt_product_search', AnalyticsEvent.Track, {
    ...searchInfo,
    pharmacyId: storedPharmacyId(),
  } as StoredPharmacyIdPayload & SearchDataAnalyticsProps);
};

export const trackProductAdd = (productData: IMappingProductDataAnalytic): void => {
  makeAnalyticsRequest('pt_product_added', AnalyticsEvent.Track, {
    ...productData,
    pharmacyId: storedPharmacyId(),
  } as StoredPharmacyIdPayload & IMappingProductDataAnalytic);
};

export const trackRemoveProduct = (productData: ProductAnalyticsProps): void => {
  makeAnalyticsRequest('pt_product_remove', AnalyticsEvent.Track, {
    ...productData,
    from_page: pageTracker.currentPage,
  });
};

export const trackEditProductQty = (productData: ProductAnalyticsProps, qty = 0): void => {
  makeAnalyticsRequest('pt_product_edit_qty', AnalyticsEvent.Track, {
    ...productData,
    from_page: pageTracker.currentPage,
    quantity: qty,
  });
};

export const trackFavoritePage = (): void => {
  makeAnalyticsRequest('pt_view_favorites_page', AnalyticsEvent.Track, undefined);
};

export const trackSuggestionsPage = (): void => {
  makeAnalyticsRequest('pt_view_suggestions_page', AnalyticsEvent.Track, undefined);
};

export const trackAddProductSuggestion = (productSuggestionData: ProductSuggestionDataAnalyticsProps): void => {
  makeAnalyticsRequest('pt_add_product_suggestion', AnalyticsEvent.Track, { ...productSuggestionData });
};

export const trackRemindersPage = (): void => {
  makeAnalyticsRequest('pt_view_reminders_page', AnalyticsEvent.Track, undefined);
};

export const trackRemoveProductFromRemindersPage = (productSimpleData: ProductSimpleAnalyticsProps): void => {
  makeAnalyticsRequest('pt_remove_product_from_reminders_page', AnalyticsEvent.Track, { ...productSimpleData });
};

export const trackAddFavoriteProduct = (productData: FavoritedProductAnalayticsProps): void => {
  makeAnalyticsRequest('pt_add_favorite_product', AnalyticsEvent.Track, {
    ...productData,
    pharmacyId: storedPharmacyId(),
  } as StoredPharmacyIdPayload & FavoritedProductAnalayticsProps);
};

export const trackRemoveFavoriteProduct = (productData: FavoritedProductAnalayticsProps): void => {
  makeAnalyticsRequest('pt_remove_favorite_product', AnalyticsEvent.Track, {
    ...productData,
    pharmacyId: storedPharmacyId(),
  } as StoredPharmacyIdPayload & FavoritedProductAnalayticsProps);
};

export const trackDecreaseProduct = (pharmacy_id: number, product: ProductAnalyticsProps): void => {
  makeAnalyticsRequest('pt_product_decrease_qty_1', AnalyticsEvent.Track, {
    product,
    from_page: pageTracker.currentPage,
    pharmacy_id,
  });
};

export const trackIncreaseProduct = (pharmacy_id: number, product: ProductAnalyticsProps): void => {
  makeAnalyticsRequest('pt_product_increase_qty_1', AnalyticsEvent.Track, {
    product,
    from_page: pageTracker.currentPage,
    pharmacy_id,
  });
};

export const trackAddReminderProduct = (productSimpleData: ProductSimpleAnalyticsProps): void => {
  makeAnalyticsRequest('pt_add_remind_me_product', AnalyticsEvent.Track, { ...productSimpleData });
};

export const trackProductDetailPage = (productData: ProductSimpleAnalyticsProps): void => {
  makeAnalyticsRequest('pt_view_product_detail_page', AnalyticsEvent.Track, {
    ...productData,
    from_page: pageTracker.currentPage,
  });
};

export const trackProductDetail = (productSimpleData: ProductDetailDataAnalyticsProps): void => {
  makeAnalyticsRequest('pt_view_product_detail', AnalyticsEvent.Track, {
    ...productSimpleData,
    from_page: pageTracker.currentPage,
  });
};

export const trackProductListingPage = (productListPageData: ProductListPageAnalyticsProps): void => {
  makeAnalyticsRequest('pt_view_product_listing_page', AnalyticsEvent.Track, { ...productListPageData });
};

export const trackRecentlyViewedPage = (): void => {
  makeAnalyticsRequest('pt_view_recently_viewed', AnalyticsEvent.Track, undefined);
};

export const trackImpression = (productData: ImpressionDataAnalyticProps): void => {
  makeAnalyticsRequest('pt_product_impression', AnalyticsEvent.Track, {
    ...productData,
    pharmacyId: storedPharmacyId(),
  } as StoredPharmacyIdPayload & ImpressionDataAnalyticProps);
};

export const trackSearchSection = (searchInfo: SearchSectionProps): void => {
  makeAnalyticsRequest('pt_view_recently_searched', AnalyticsEvent.Track, { ...searchInfo });
};

export const trackSimilarProductListPage = (similarProductListPageData: SimilarProductListPageAnalyticsProps): void => {
  makeAnalyticsRequest('pt_view_similar_product_recommendation', AnalyticsEvent.Track, {
    ...similarProductListPageData,
  });
};

export const trackHomesquarePage = (homesquareWidgetData: HomesquareWidgetProps): void => {
  makeAnalyticsRequest('pt_homesquare_widget', AnalyticsEvent.Track, { ...homesquareWidgetData });
};

export const trackInstockReminder = (productData: InstockReminderDataAnalyticProps): void => {
  makeAnalyticsRequest('pt_in_stock_reminder', AnalyticsEvent.Track, { ...productData });
};

export const trackViewFilter = (data: FilterSortDataProps): void => {
  makeAnalyticsRequest('pt_view_filter', AnalyticsEvent.Track, { ...data });
};

export const trackApplyFilter = (data: FilterSortDataProps): void => {
  makeAnalyticsRequest('pt_apply_filter', AnalyticsEvent.Track, { ...data });
};

export const trackCommunityNewsfeed = (data: CommunityDataProps): void => {
  makeAnalyticsRequest('pt_community_newsfeed', AnalyticsEvent.Track, { ...data });
};

export const trackCommunityEducation = (data: CommunityDataProps): void => {
  makeAnalyticsRequest('pt_community_education', AnalyticsEvent.Track, { ...data });
};
