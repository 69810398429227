export const FAIL_PATCH_PREKURSOR_STATUS = 'FAIL_PATCH_PREKURSOR_STATUS';
export type FAIL_PATCH_PREKURSOR_STATUS = typeof FAIL_PATCH_PREKURSOR_STATUS;

export const SUCCESS_PATCH_PREKURSOR_STATUS = 'SUCCESS_PATCH_PREKURSOR_STATUS';
export type SUCCESS_PATCH_PREKURSOR_STATUS = typeof SUCCESS_PATCH_PREKURSOR_STATUS;

export const SET_PREKURSOR_STATUS_LOADING = 'SET_PREKURSOR_STATUS_LOADING';
export type SET_PREKURSOR_STATUS_LOADING = typeof SET_PREKURSOR_STATUS_LOADING;

export const SET_PREKURSOR_STATUS_DISABLED = 'SET_PREKURSOR_STATUS_DISABLED';
export type SET_PREKURSOR_STATUS_DISABLED = typeof SET_PREKURSOR_STATUS_DISABLED;

export const SET_PREKURSOR_CONFIG = 'SET_PREKURSOR_CONFIG';
export type SET_PREKURSOR_CONFIG = typeof SET_PREKURSOR_CONFIG;

export const SUCCESS_GET_PENDING_UPLOAD_PREKURSOR = 'SUCCESS_GET_PENDING_UPLOAD_PREKURSOR';
export type SUCCESS_GET_PENDING_UPLOAD_PREKURSOR = typeof SUCCESS_GET_PENDING_UPLOAD_PREKURSOR;

export const FAIL_GET_PENDING_UPLOAD_PREKURSOR = 'FAIL_GET_PENDING_UPLOAD_PREKURSOR';
export type FAIL_GET_PENDING_UPLOAD_PREKURSOR = typeof FAIL_GET_PENDING_UPLOAD_PREKURSOR;
