import * as Redux from 'redux';
import { Action, SectionCardActionType } from './SectionCard.action';

interface CardState {
  expanded: boolean;
}

export interface SectionCardState {
  [key: string]: CardState;
}

const INITIAL_STATE: SectionCardState = {};

export const SectionCardReducer: Redux.Reducer<SectionCardState, Action> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SectionCardActionType.TOGGLE_SECTION_CARD: {
      const { init, name } = action.payload;

      return {
        ...state,
        [name]: {
          expanded:
            typeof init !== 'undefined' ? init : !(state[name] || {}).expanded,
        },
      };
    }
    default:
      return state;
  }
};
