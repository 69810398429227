/* eslint-disable no-await-in-loop */
import { LogisticsDeliveryStatus, swipeRxPt } from 'services';
import { getImageDimensions } from 'utils/helpers';
import { recordException } from 'utils/Reporting';

export const getOrderDeliveries = async (orderId: number): Promise<LogisticsDeliveryStatus[]> => {
  try {
    const logisticDeliveries = await swipeRxPt.logisticsDelivery.getOrderDeliveries(orderId);
    // get image dimensionss
    for (let i = 0; i < logisticDeliveries.length; i += 1) {
      const delivery = logisticDeliveries[i];
      if (delivery.photos) {
        delivery.photosWithDimension = await Promise.all(delivery.photos.map((photo) => getImageDimensions(photo)));
      }
    }
    return logisticDeliveries;
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'getOrderDeliveries', { orderId });
    }

    return [];
  }
};
