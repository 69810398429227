import React from 'react';

import { ChildPageAppBar, ChildPageAppBarProps } from './components';

interface Props extends ChildPageAppBarProps {}

export const ChildPage: React.FC<React.PropsWithChildren<Props>> = ({ backURL, children, title }) => (
  <>
    <ChildPageAppBar backURL={backURL} title={title} />
    {children}
  </>
);
