import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { translate } from 'react-i18next';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';

import { getMarketId } from 'utils/MarketConfig/market-config.getter';
import { ReactComponent as EyeIc } from 'images/eye-solid.svg';
import { ReactComponent as EyeSlashIc } from 'images/eye-slash-solid.svg';
import { TranslateProps } from '../../../utils/Localization/types';
import { S } from './styles';

type Props = RouteComponentProps & TranslateProps;

export const ChangePasswordPageComponent: React.FC<React.PropsWithChildren<Props>> = ({ t }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [oldPass, setOldPass] = useState('password');
  const [newPass, setNewPass] = useState('password');
  const [confirmPass, setConfirmPass] = useState('password');

  const [swipeRxToken] = useState(decodeURIComponent(localStorage.getItem('swiperx_token') || ''));
  useEffect(() => {
    const swipeImage = document.getElementById('swipeImage');
    // eslint-disable-next-line no-unused-expressions
    swipeImage?.remove();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PH_API_VTHREE}/${getMarketId()}/users/${swipeRxToken}`, {
        headers: {
          'x-warp-api-key': `${process.env.REACT_APP_PH_API_KEY}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setOldPassword(response.data.data);
      })
      .catch(() => {
        setHasError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      newPass: '',
      confirmPass: '',
    },
    validationSchema: Yup.object({
      newPass: Yup.string()
        .required(t('validationEnterPass'))
        .min(8, `${t('validationMinPass')}`)
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, `${t('ValidationPassReq')}`),
      confirmPass: Yup.string()
        .required(t('validationEnterPass'))
        .oneOf([Yup.ref('newPass')], t('ValidationPassMatch')),
    }),
    onSubmit: (values) => {
      axios
        .post(
          `${process.env.REACT_APP_PH_API_VTHREE}/${getMarketId()}/users/change-password`,
          {
            oldPassword,
            newPassword: values.newPass,
            swipeSessToken: swipeRxToken,
          },
          {
            headers: {
              'x-warp-api-key': `${process.env.REACT_APP_PH_API_KEY}`,
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => setSuccess(true))
        .catch(() => {
          setSuccess(true);
          setHasError(true);
        });
    },
  });

  const login = (): void => {
    window.location.href = `${process.env.REACT_APP_SWIPE_DEEPLINK}`;
  };

  const handleChange = (event: any): void => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const togglePassVisibility = (id: string): void => {
    const inputElement: any = document.getElementById(id);
    if (inputElement.type === 'password') {
      inputElement.type = 'text';

      if (id === 'oldPass') {
        setOldPass('text');
      }
      if (id === 'newPass') {
        setNewPass('text');
      }
      if (id === 'confirmPass') {
        setConfirmPass('text');
      }
    } else {
      inputElement.type = 'password';
      if (id === 'oldPass') {
        setOldPass('password');
      }
      if (id === 'newPass') {
        setNewPass('password');
      }
      if (id === 'confirmPass') {
        setConfirmPass('password');
      }
    }
  };

  return (
    <S.ChangePassContainer>
      <Grid container direction="column" justify="center" alignItems="center">
        <S.ImgSwipeLogo>
          <img src="/assets/logo.svg" alt="swipe-logo" style={{ height: '10vh' }} />
        </S.ImgSwipeLogo>
        <S.FormContainer>
          {!hasError && !isSuccess ? (
            <>
              <Grid container direction="column" xs={12} justify="center" alignItems="center">
                <Grid xs={10} alignItems="center">
                  <S.FormTitle> {t('title')}</S.FormTitle>
                  <S.ChangePassForm onSubmit={handleSubmit}>
                    <S.FormLabel>{t('oldPass')}</S.FormLabel>
                    <S.InputPassword id="oldPass" name="oldPass" type="password" value={oldPassword} />
                    <S.IconPass onClick={() => togglePassVisibility('oldPass')}>
                      {oldPass === 'password' ? <EyeSlashIc /> : <EyeIc />}
                    </S.IconPass>
                    <S.FormLabel>
                      {t('newPass')}
                      <S.RequiredFields>*</S.RequiredFields>
                    </S.FormLabel>
                    <S.InputPassword
                      id="newPass"
                      name="newPass"
                      type="password"
                      placeholder={t('enterPass')}
                      value={values.newPass}
                      onChange={handleChange}
                    />
                    <S.IconPass onClick={() => togglePassVisibility('newPass')}>
                      {newPass === 'password' ? <EyeSlashIc /> : <EyeIc />}
                    </S.IconPass>
                    {touched.newPass && errors.newPass ? <S.ErrorContainer>{errors.newPass}</S.ErrorContainer> : null}
                    <S.PasswordReq> {t('passwordReq')} </S.PasswordReq>
                    <S.FormLabel>
                      {t('confirmPass')}
                      <S.RequiredFields>*</S.RequiredFields>
                    </S.FormLabel>
                    <S.InputPassword
                      id="confirmPass"
                      name="confirmPass"
                      type="password"
                      placeholder={t('enterPass')}
                      value={values.confirmPass}
                      onChange={handleChange}
                    />
                    <S.IconPass aria-hidden="true" onClick={() => togglePassVisibility('confirmPass')}>
                      {confirmPass === 'password' ? <EyeSlashIc /> : <EyeIc />}
                    </S.IconPass>
                    {touched.confirmPass && errors.confirmPass ? (
                      <S.ErrorContainer>{errors.confirmPass}</S.ErrorContainer>
                    ) : null}
                    <S.ChangePassButtonContainer>
                      <S.ChangePassButton type="submit" id="change-password-success">
                        {' '}
                        {t('submitPass')}{' '}
                      </S.ChangePassButton>
                    </S.ChangePassButtonContainer>
                  </S.ChangePassForm>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              direction="column"
              xs={12}
              justify="center"
              alignItems="center"
              style={{ marginTop: '20px' }}
            >
              <Grid xs={10} alignItems="center" style={{ display: 'contents' }}>
                <S.SuccessTitle> {hasError ? '' : t('successTitle')} </S.SuccessTitle>
                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                  {hasError ? (
                    <S.GeneralError>{t('generalError')}</S.GeneralError>
                  ) : (
                    <S.SuccessHeader>{t('successHeader')}</S.SuccessHeader>
                  )}
                  <S.SuccessFooter> {hasError ? '' : t('successFooter')}</S.SuccessFooter>
                  <S.ChangePassButtonContainer>
                    <S.LoginButton id="change-password-login" onClick={login}>
                      {' '}
                      {t('LoginSwipe')}{' '}
                    </S.LoginButton>
                  </S.ChangePassButtonContainer>
                </Grid>
              </Grid>
            </Grid>
          )}
        </S.FormContainer>
      </Grid>
    </S.ChangePassContainer>
  );
};

export const ChangePasswordPage = translate('changePasswordPage')(ChangePasswordPageComponent);
