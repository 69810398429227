import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const Row = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const S = { Row };
