import styled, { keyframes } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import * as colors from 'components/styles/colors';

interface CardProps {
  isRead?: boolean;
}

const Card = styled(Grid)<CardProps>`
  padding: 15px 20px;
  background-color: ${({ isRead }) => (!isRead ? '#F5FAFF' : colors.WHITE)};
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.SUBTLE_GRAY};
  }
`;

const Title = styled(Typography)`
  && {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 800;
    text-transform: capitalize;
  }
`;

const Row = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
`;

const pulsate = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }`;

const Badge = styled.div`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: ${colors.DARK_PRIMARY_COLOR};
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: ${pulsate} 2s infinite;
`;

const Description = styled(Typography)`
  && {
    font-size: 12px;
    color: ${colors.BLUISH_GRAY_LIGHT};
  }
`;

export const S = { Card, Badge, Title, Description, Row };
