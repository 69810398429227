/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { translate } from 'react-i18next';
import Done from '@material-ui/icons/Done';
import { TranslateProps } from 'utils/Localization/types';
import { ProductRequestLink } from 'components/common/ProductRequest';
import { S } from '../product-list.styles';

const PageEndC: React.FC<React.PropsWithChildren<TranslateProps>> = ({ t }) => (
  <>
    <S.PageEnd>
      <Done />
      {t('pageEnd')}
    </S.PageEnd>
    <ProductRequestLink showSeparator />
  </>
);

export const ProductListPageEnd = translate('productList')(PageEndC);
