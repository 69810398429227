/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Grid } from '@material-ui/core';

import { SkeletonLoader } from 'components/common/skeleton-loader';

import { S } from './activity-card.styles';

export const ActivityCardLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const loaderCount = Array.from({ length: 6 });

  return (
    <>
      {loaderCount.map((_, index) => (
        <S.Card key={index} style={{ backgroundColor: '#fff' }}>
          <S.Row>
            <Grid container>
              <SkeletonLoader type="sub" width={220} />
            </Grid>
          </S.Row>
          <SkeletonLoader type="sub" variant="sm" />
          <SkeletonLoader type="sub" width={260} />
          <SkeletonLoader type="sub" variant="lg" />
        </S.Card>
      ))}
    </>
  );
};
