import styled from 'styled-components';
import * as colors from '../../styles/colors';
import Container from '../../views/Container';

export const ErrorContainer = styled(Container)`
  padding: 8px;

  .box {
    text-align: center;
    min-width: 275px;
    padding: 16px;

    .alert-icon {
      font-size: 32pt;
      margin-bottom: 8px;
      color: ${colors.MEDIUM_GRAY};
    }

    .title {
      color: ${colors.MEDIUM_GRAY};
      font-size: 14pt;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .message {
      margin-bottom: 16px;
      background: ${colors.LIGHT_RED};
      border: 1px solid ${colors.RED};
      color: ${colors.RED};
      padding: 8px;
      font-style: italic;
      border-radius: 3px;
      font-size: 9pt;
    }

    .description {
      color: ${colors.MEDIUM_GRAY};
      font-size: 10pt;
      margin-bottom: 16px;
    }

    .main-button {
      background: ${colors.PRIMARY_COLOR};
      color: ${colors.WHITE};
    }

    .refresh-link,
    .exit-link {
      font-size: 9pt;
      font-weight: 500;
      color: ${colors.MEDIUM_GRAY};
      text-transform: uppercase;
      margin-top: 8px;
    }
  }
`;
