import styled from 'styled-components';
import Img from 'react-image-appear';

export const Image = styled(Img)`
  && {
    display: block;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
  }
`;
