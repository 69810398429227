import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

const Container = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px;
  }
`;

const SubTitle = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.4px;
    text-transform: uppercase;
    color: #717786;
  }
`;

export const S = { Container, SubTitle };
