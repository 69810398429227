import React, { CSSProperties } from 'react';

import { Skeleton } from '@material-ui/lab';

import ShowIf from 'components/views/ShowIf';

import styled from 'styled-components';
import { CarouselContaier, Title, Navigator, Divider } from './widget.style';

export interface CarouselContainerProps {
  hideTitle?: boolean;
  titleCaption: string;
  bgColorConfig: string;
  textColorConfig: string;
  seeAllCaption: string;
  seeAllStyling: CSSProperties;
  showDivider: boolean;
  showSeeAll: boolean;
  noPadding?: boolean;
  tours?: {
    id: string;
  };
  onSeeAll: () => void;
  loading?: boolean;
  show: boolean;
}

const WidgetTopContainer = styled.div`
  && {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 12px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`;

const TitleSkeleton = styled(Skeleton).attrs({ variant: 'text' })`
  && {
    height: 1.5em;
    padding-bottom: 20px;
    width: 30%;
  }
`;

const SeeAllSkeleton = styled(Skeleton).attrs({ variant: 'text' })`
  && {
    padding-right: 4px;
    width: 25%;
  }
`;

export const CarouselContainer: React.FC<React.PropsWithChildren<CarouselContainerProps>> = ({
  hideTitle = false,
  titleCaption,
  bgColorConfig,
  textColorConfig,
  seeAllCaption,
  seeAllStyling,
  showDivider,
  showSeeAll,
  noPadding,
  children,
  tours,
  onSeeAll,
  loading,
  show,
}) => {
  if (!show) {
    return null;
  }
  return (
    <CarouselContaier id={tours?.id} noPadding={noPadding}>
      {loading && (
        <WidgetTopContainer>
          <TitleSkeleton />
          <SeeAllSkeleton />
        </WidgetTopContainer>
      )}

      {!loading && (
        <>
          <ShowIf condition={!hideTitle}>
            <Title
              content={titleCaption}
              background={bgColorConfig}
              color={textColorConfig}
              data-testid={`title-${titleCaption}`}
              onClick={onSeeAll}
            >
              <br />
            </Title>
          </ShowIf>
        </>
      )}
      {children}
      {!loading && (
        <>
          <ShowIf condition={showSeeAll}>
            <Navigator style={seeAllStyling} onClick={onSeeAll} data-testid={`btn-seeAll-${titleCaption}`}>
              {seeAllCaption}
            </Navigator>
          </ShowIf>
          <ShowIf condition={showDivider}>
            <Divider />
          </ShowIf>
        </>
      )}
    </CarouselContaier>
  );
};
