import styled from 'styled-components';

import EmptyVoucherImageBase from 'images/empty-voucher.svg';

export const EmptyVoucherImage = styled.img.attrs({ src: EmptyVoucherImageBase, alt: 'No Voucher' })`
  width: 250px;
  margin-top: 33px;
`;

export default EmptyVoucherImage;
