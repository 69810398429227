import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { LOYALTY_POINT } from 'components/styles/colors';

const ProductPointContainer = styled(Grid)`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;

    &&.potential-has-rewards {
      p {
        font-style: italic;
        color: ${LOYALTY_POINT.secondary.dark};
        line-height: 16px;
      }
    }
  }
`;
const ProductPointEarnText = styled(Typography)`
  && {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: ${LOYALTY_POINT.dark};

    &&.potential-points {
      font-style: italic;
      padding-left: 5px;
    }
  }
`;

export const S = {
  ProductPointContainer,
  ProductPointEarnText,
};
