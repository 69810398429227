import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { getHeight, getWidth } from './get-loader-dimensions';

export interface SkeletonLoaderProps {
  type?: 'sub' | 'text' | 'title' | 'heading';
  variant?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  width?: number | string;
  height?: number;
  circle?: boolean;
  color?: string;
  highlightColor?: string;
}

export const SkeletonLoader: React.FC<React.PropsWithChildren<SkeletonLoaderProps>> = ({
  type = 'text',
  variant = 'md',
  width,
  height,
  circle,
  color,
  highlightColor,
}) => {
  const variantWidth = width || getWidth(variant);
  const typeHeight = height || getHeight(type);

  return (
    <SkeletonTheme color={color} highlightColor={highlightColor}>
      <Skeleton circle={circle} height={typeHeight} width={width || variantWidth} />
    </SkeletonTheme>
  );
};
