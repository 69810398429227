import { Action } from 'redux';
import { recordException } from 'utils/Reporting/Sentry';

import { ThunkActionCreator } from '../../../types/thunk';
import { v3Client } from '../../../utils/Client/v3';

import { Settings } from '../Prices';

import { Credit } from '.';
import * as constants from './constants';

/**
 * Action types
 */
export interface UpdateCreditAction extends Action {
  type: constants.UPDATE_CREDIT;
  details: Credit;
}

export interface FailCreditAction extends Action {
  type: constants.FAIL_CREDIT;
  error?: string;
}

// hotfixed transaction disable
export interface UpdatePricesAction extends Action {
  type: constants.UPDATE_PRICES;
  items: Record<string, unknown>;
  settings: Settings[];
}

export interface UpdateSettingsAction extends Action {
  type: constants.UPDATE_SETTINGS;
  settings: Settings[];
}

export type Actions = UpdateCreditAction | FailCreditAction | UpdatePricesAction | UpdateSettingsAction;

/**
 * Actions
 */
export const fetchCredit: ThunkActionCreator<Actions> = (callback?: (limit: number) => void) => async (dispatch) => {
  try {
    const organizationId = localStorage.getItem('organization_id');
    // Fetch credit
    const getSettings = await v3Client.get(`/organizations/${organizationId}/settings`);
    const getDetails = await v3Client.get(`organizations/${organizationId}/latest-credits`);
    const [settings, details] = await Promise.all([getSettings, getDetails]);

    const creditDetails = {
      credit_limit: details.data.total_credit_limit || 0,
      remaining_credit: details.data.available_credit || 0,
      amount_payable: 0,
      amount_used: Math.max(details.data.total_credit_limit - details.data.available_credit, 0),
      amount_paid: details.data.total_payments || 0,
    };

    if (callback) {
      callback(creditDetails.credit_limit as number);
    }

    // Dispatch the credit
    dispatch({ type: constants.UPDATE_CREDIT, details: creditDetails });

    // Dispatch the prices
    // hotfixed transaction disabled
    dispatch({ type: constants.UPDATE_PRICES, items: {}, settings: settings.data });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'fetchCredit');
      console.error(error.message);
      // Dispatch the error
      dispatch({ type: constants.FAIL_CREDIT, error: error.message });
    }
  }
};

export const getSettings = async (): Promise<Settings[]> => {
  let settings: Settings[] = [];
  try {
    const organizationId = localStorage.getItem('organization_id');
    const response = await v3Client.get(`/organizations/${organizationId}/settings`);

    settings = response.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }

  return settings;
};

export const fetchSettings: ThunkActionCreator<Actions> = () => async (dispatch) => {
  try {
    const settings = await getSettings();

    dispatch({ type: constants.UPDATE_SETTINGS, settings });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'fetchSettings');
      console.error(error.message);
      dispatch({ type: constants.FAIL_CREDIT, error: error.message });
    }
  }
};

export const clearCreditError: ThunkActionCreator<Actions> = () => async (dispatch) => {
  // Clear the error
  dispatch({ type: constants.FAIL_CREDIT, error: undefined });
};
