import { store } from 'store';
import { PaymentMethods, PaymentProvider, SuppportedMarkets } from 'utils/constants';

export const isDirectPaymentEnabled = (): boolean => {
  const { config } = store.getState();

  return !!config.paymentMethod?.directPayment?.active;
};

export const getPaymentBillExpiration = (): number | undefined | null => {
  const { config } = store.getState();

  return config.paymentMethod?.directPayment?.billExpiration;
};

export const isCreditLineEnabled = (): boolean => {
  const { config } = store.getState();

  return !!config.paymentMethod?.credit?.active;
};

export const getPaymentMethod = (): PaymentMethods | null => {
  const { pharmacy } = store.getState();
  return pharmacy.paymentMethod;
};

export const getPaymentProvider = (): PaymentProvider | null => {
  const { config, pharmacy } = store.getState();

  let provider: PaymentProvider | null = null;

  if (pharmacy.paymentMethod === PaymentMethods.CREDIT_LINE) {
    provider = config.paymentMethod?.credit?.provider || null;
  } else if (pharmacy.paymentMethod === PaymentMethods.DIRECT_PAYMENT) {
    provider = config.paymentMethod?.directPayment?.provider || null;
  }

  return provider;
};

export const shouldShowTaxBreakdown = (): boolean => {
  const { config } = store.getState();

  return config.marketId !== SuppportedMarkets.TH;
};

export const toMarketPaymentWiseAmount = (
  amount: number,
  roundingRule: 'ceil' | 'round' | 'floor' = 'ceil',
): number => {
  const paymentMethod = getPaymentMethod();
  const paymentProvider = getPaymentProvider();

  let finalAmount = Number(amount.toFixed(4));

  if (paymentProvider === PaymentProvider.DOKU && paymentMethod === PaymentMethods.DIRECT_PAYMENT) {
    if (roundingRule === 'ceil') finalAmount = Math.ceil(finalAmount);
    if (roundingRule === 'round') finalAmount = Math.round(finalAmount);
    if (roundingRule === 'floor') finalAmount = Math.floor(finalAmount);
  }

  return finalAmount;
};

export const isExpectedDeliveryDateShown = (): boolean => {
  const { config } = store.getState();

  return !!config.market.orders?.show_delivery_estimates_pharmacy;
};

export const isVoucherEnabled = (): boolean => {
  const { config } = store.getState();

  const enabled = !!config.paymentMethod?.voucher?.enabled;

  return enabled;
};
