import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';

import { ConditionalRender } from 'components/common';

import { IReturnItemExtended } from 'store/reducers/Return';
import { S } from './orders-modal.styles';
import { OrdersModalProducts } from './orders-modal-products.component';

type Props = {
  t: any;
  items: Array<IReturnItemExtended>;
  size: number;
};

export const OrdersModalReturnPagination: React.FC<React.PropsWithChildren<Props>> = ({ t, items, size }) => {
  const [count, setCount] = useState<number>(0);
  const [returnedItems, setReturnsItems] = useState<Array<IReturnItemExtended>>([]);

  const minimize = (): void => {
    const sliceItems = items.slice(0, size);
    setReturnsItems(sliceItems);
  };

  useEffect(() => {
    minimize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCount(items.length - returnedItems.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnedItems]);

  const loadAll = (): void => {
    setReturnsItems(items);
  };

  return (
    <S.ProductItemsContainer key={uuidv4()}>
      <div className="product__items">
        {returnedItems.map((returnedItem) => (
          <>
            <OrdersModalProducts orderItem={returnedItem.order_item} isReturnedItem />

            <Grid container direction="column" style={{ marginBottom: 10 }}>
              <S.ReturnReasonTitle>{t('return.reason')}</S.ReturnReasonTitle>
              <S.ReturnReason>{returnedItem.reason}</S.ReturnReason>
            </Grid>
          </>
        ))}
      </div>
      <ConditionalRender condition={count > 0}>
        <Grid container justify="flex-end" alignItems="center" className="product__pagination" onClick={loadAll}>
          <S.ProductItemsLoadMoreTitle>{t('moreReturnedItems', { count })}</S.ProductItemsLoadMoreTitle>
          <S.ProductItemsLoadMoreIcon />
        </Grid>
      </ConditionalRender>
      <ConditionalRender condition={count === 0 && items.length > size}>
        <Grid container justify="flex-end" alignItems="center" className="product__pagination" onClick={minimize}>
          <S.ProductItemsLoadMoreTitle>{t('minimizeOrderItems')}</S.ProductItemsLoadMoreTitle>
          <S.ProductItemsLoadLessIcon />
        </Grid>
      </ConditionalRender>
    </S.ProductItemsContainer>
  );
};
