import styled from 'styled-components';
import { Warning } from '@material-ui/icons';
import ButtonBase from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import * as colors from 'components/styles/colors';

const ModalDialog = styled(Dialog)`
  padding: 20px 14px;
  text-align: center;
`;

const DialogText = styled(DialogContentText)`
  padding: 10px 30px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0;
`;

const DialogTitleText = styled(DialogTitle)`
  padding: 16px 24px 0 24px;
  & > p {
    font-size: 16px;
    line-height: 20.8px;
    font-weight: 700;
  }
  & > img {
    width: 40px;
    height: 40px;
  }
`;

const Button = styled(ButtonBase).attrs({ fullWidth: true, elevation: 0, variant: 'contained' })`
  && {
    background: ${(props) => (props.className === 'warning' ? colors.ORANGE : colors.REBRAND_GREEN)};
    color: ${colors.WHITE};
    height: 31px;
    box-shadow: none;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 800;
  }
`;

const WarningIcon = styled(Warning)`
  color: ${colors.DANGER};
  font-size: 36px;
  font-weight: 800;
`;

export const S = {
  ModalDialog,
  DialogTitleText,
  DialogText,
  Button,
  WarningIcon,
};
