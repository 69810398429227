import React from 'react';

import { MarketingFeaturesDisplayPosition } from 'services/swipe-rx-pt/resources/marketings/constants';
import { ProductListData, ProductListingWidget } from '../interfaces/generic-product-list.interface';
import { getWidgetsByPosition } from '../utils';

export const InBetweenWidgets: React.FC<{
  widgets?: ProductListingWidget[];
  product: ProductListData['products'][0];
  index: number;
}> = ({ widgets, product, index }) => {
  if (widgets) {
    const inBetweenWidgets = getWidgetsByPosition(MarketingFeaturesDisplayPosition.BETWEEN_LISTING, widgets);
    const response = inBetweenWidgets.map((widget) => widget.getWidgets({ product, index })).flat();
    return <>{response}</>;
  }
  return null;
};

InBetweenWidgets.defaultProps = {
  widgets: [],
};
