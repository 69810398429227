import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

interface Props {
  bordered: boolean;
}

const ParentWrapper = styled.div`
  .bg-image {
    background-position: -30px -30px;
    height: 145px;
    width: 145px;
  }
`;

const SummaryWrapper = styled(Grid)`
  && {
    color: #fff;
    height: 100%;
    width: 85%;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 20px;
    border-bottom: ${({ bordered }: Props) =>
      bordered ? '1px solid #fff' : undefined};
  }
`;

const BaseText = styled.span`
  display: block;
  font-weight: 800;
`;

const Title = styled(BaseText)`
  font-size: 0.7rem;
  text-transform: uppercase;
`;

const Text = styled(BaseText)`
  font-size: 1.25rem;
`;

const ActionButton = styled(Button)`
  && {
    color: #fff;
    font-weight: 700;
    padding: 13px;
  }
`;

export const S = {
  ParentWrapper,
  SummaryWrapper,
  Title,
  Text,
  ActionButton,
};
