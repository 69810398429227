export enum LogisticDeliveryStatus {
  DELIVERY_COURIER_ASSIGNED = 'delivery_courier_assigned',
  DELIVERY_COURIER_DEPARTED = 'delivery_courier_departed',
  DELIVERY_PICKED_UP = 'delivery_parcel_picked_up',
  DELIVERY_ONGOING = 'delivery_ongoing',
  DELIVERY_COURIER_ARRIVED = 'delivery_courier_arrived',
  DELIVERY_DELIVERED = 'delivery_delivered',
  DELIVERY_DELAYED = 'delivery_delayed',
  DELIVERY_FAILED = 'delivery_failed',
  DELIVERY_CANCELED = 'delivery_canceled',
}

export enum LogisticDeliveryStatusDetail {
  PHARMACY_CLOSED = 'pharmacy_closed',
  FORCE_MAJOR = 'force_major',
  CHANGE_DELIVERY_PARTNER = 'change_delivery_partner',
  UPDATE_TRACKING_NUMBER = 'update_tracking_number',
  INVALID_CONTACT = 'invalid_contact',
  CANCELED = 'canceled',
  RESCHEDULED = 'rescheduled',
  DELAYED = 'delayed',
  OTHER = 'other',
  RETURNED = 'returned',
}
