import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneIcon from '@material-ui/icons/Done';
import FilterListIcon from '@material-ui/icons/FilterList';

import * as colors from 'components/styles/colors';

const BaseButton = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.WHITE};
  font-size: 10px;
  color: ${colors.BLUISH_GRAY_LIGHT};
  font-weight: 800;
`;

const BaseIcon = css`
  height: 12px;
  width: 12px;
  fill: ${colors.BLUISH_GRAY_LIGHT};
  padding-right: 5px;
`;

const PillFilterContainer = styled.div`
  position: fixed;
  bottom: 100px;
  width: 100%;
  z-index: 50;
`;

const PillBtnLeft = css`
  ${BaseButton};
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 1px solid ${colors.SUBTLE_GRAY};
`;

const PillBtnRight = css`
  ${BaseButton};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 1px solid ${colors.SUBTLE_GRAY};
`;

const PillContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20;
  width: 100%;
`;

const Pill = styled(Grid)`
  && {
    padding: 8px 10px;
    width: 100%;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.WHITE};
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(140, 150, 173, 0.2), 0px 1px 10px rgba(140, 150, 173, 0.12),
      0px 4px 5px rgba(140, 150, 173, 0.14);
  }
`;

const PillButton = styled(ButtonBase)<any>`
  && {
    ${(props) => (props.order === 1 ? PillBtnLeft : PillBtnRight)}
  }
`;

const CalendarIcon = styled(DateRangeIcon)`
  && {
    ${BaseIcon};
  }
`;

const ActiveIcon = styled(DoneIcon)<any>`
  && {
    width: 13px;
    height: 13px;
    fill: ${colors.DARK_PRIMARY_COLOR};
  }
`;

const FilterIcon = styled(FilterListIcon)`
  && {
    ${BaseIcon};
  }
`;

export const S = {
  Pill,
  PillContainer,
  PillFilterContainer,
  PillButton,
  CalendarIcon,
  FilterIcon,
  ActiveIcon,
};
