import { useSelector, shallowEqual } from 'react-redux';
import { searchProfileService } from 'services/profiler/resources/search-profile-service.class';
import { State } from 'store';

export interface ISearchProfilerHook<T = any> {
  clickThroughSRPTrack: (productId: number) => Promise<void>;
}

export const useSearchProfiler = (): ISearchProfilerHook => {
  const { products: results, previousQuery } = useSelector((state: State) => state.productListing, shallowEqual);

  const trackClickSRPThrough = async (productId: number): Promise<void> => {
    let index = -1;
    if (results?.length > 0) {
      results.forEach((product, i) => {
        if (product.id === productId) {
          index = i;
        }
      });
    }
    if (index === -1) {
      return;
    }

    await searchProfileService.clickThrough({
      keyword: previousQuery,
      row_number: index + 1,
      product_id: productId,
      clicked_at: Date.now(),
    });
  };

  return {
    clickThroughSRPTrack: trackClickSRPThrough,
  };
};
