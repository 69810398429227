/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';
import { State as StoreState } from 'store';
import { Product } from 'store/reducers/Product';
import { PROMO_CARD, NORMAL_CARD } from 'components/styles/colors';

export const useTierPricing = (
  product: Product,
  promoted?: boolean,
): {
  itemCount: number;
  color: any;
  changeColor: any;
  isPromoApplied: boolean;
  activePrice: number;
  activeDiscount: number;
  activeDiscountedPrice: number;
  isTierDiscountAvailable: boolean;
} => {
  const {
    counter: { counterList },
  } = useSelector((state: StoreState) => state, shallowEqual);

  const itemCount = counterList[product.id] ? counterList[product.id].count : 0;

  const isTierDiscountAvailable = product.tier_discount !== undefined && product.tier_discount.length !== 0;

  const minPurchase = isTierDiscountAvailable ? product.tier_discount[0].min : 0;

  const isPromoApplied = itemCount >= minPurchase && minPurchase !== 0;

  let dynamicColors = NORMAL_CARD;
  if (isTierDiscountAvailable && itemCount >= minPurchase) {
    dynamicColors = PROMO_CARD;
  }

  const activeDiscount =
    get(counterList, [product?.id, 'cartItems', product?.name, product?.id, 'discount_rate']) || product?.discount_rate;
  const activeDiscountedPrice =
    get(counterList, [product?.id, 'cartItems', product?.name, product?.id, 'net_price']) || product?.net_price;
  const activePrice =
    get(counterList, [product?.id, 'cartItems', product?.name, product?.id, 'selling_price']) || product?.selling_price;

  const cardColor = promoted ? PROMO_CARD : dynamicColors;

  const [color, setColor] = useState(cardColor);

  useEffect(() => {
    setColor(promoted ? PROMO_CARD : dynamicColors);
  }, [product]);

  const changeColor = (itemCount: number): void => {
    if (promoted) return;

    if (minPurchase !== 0 && itemCount >= minPurchase) {
      setColor(PROMO_CARD);
    } else {
      setColor(NORMAL_CARD);
    }
  };

  return {
    itemCount,
    color,
    changeColor,
    isPromoApplied,
    activePrice,
    activeDiscount,
    activeDiscountedPrice,
    isTierDiscountAvailable,
  };
};
