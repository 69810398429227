import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { TranslateProps } from 'utils/Localization/types';
import { S } from './PrekursorDialogSuccess.style';

export interface Props {
  show: boolean;
  onClose: (close) => void;
  fileName: string;
  error: string;
}

export const PrekursorDialogFailed: React.FC<React.PropsWithChildren<Props & TranslateProps>> = ({
  show,
  onClose,
  t,
  fileName,
  error,
}) => {
  const errorMessage = {
    Error: 'uploadFailed',
    UploadPrecursorApprovedFailedError: 'uploadApprovedFailed',
  };
  return (
    <S.ModalDialog open={show} onClose={() => onClose(false)}>
      <S.DialogTitleText disableTypography>
        <S.WarningIcon />
        <Typography>{t(`prekursor.${errorMessage[error]}.title`)}</Typography>
      </S.DialogTitleText>
      <DialogContent>
        <S.DialogText>
          {t(`prekursor.${errorMessage[error]}.info`)} {fileName}
        </S.DialogText>
      </DialogContent>
      <DialogActions>
        <S.Button onClick={() => onClose(false)} className="warning">
          {t(`prekursor.${errorMessage[error]}.action`)}
        </S.Button>
      </DialogActions>
    </S.ModalDialog>
  );
};
