import React from 'react';

import { Skeleton } from '@material-ui/lab';

import styled from 'styled-components';

import { WidgetContainer, LeftPane, RightPane, BottomContainer } from '../ProductCarouselCard.style';

const ImageSkeleton = styled(Skeleton).attrs({ variant: 'rect' })`
  && {
    height: 165px;
    width: 100%;
  }
`;

const TitleSkeletonContainer = styled.div`
  && {
    width: 80%;
  }
`;

const TitleTextSkeleton = styled(Skeleton).attrs({ variant: 'text' })`
  && {
  }
`;

const FavoriteButtonSkeletonContainer = styled.div`
  && {
    height: 1.5em;
    width: 1.5em;
    position: absolute;
    top: 1em;
    right: 6px;
  }
`;

const FavoriteButtonSkeleton = styled(Skeleton).attrs({ variant: 'rect' })`
  && {
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    width: 1.2em;
    height: 1.2em;
    display: inline-block;

    padding: 0.1em;
    top: 1em;
    border-radius: 0.5em 0.5em 0.5em 0.5em;
  }
`;

const PricingSkeletonContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 50%;
  }
`;

const PricingTextSkeleton = styled(Skeleton).attrs({ variant: 'text' })`
  && {
    width: 80%;
  }
`;

const QtySkeletonContainer = styled.div`
  && {
    display: flex;
    flex: 1;
    width: 70%;
    justify-content: flex-end;
  }
`;

const QtySkeleton = styled(Skeleton).attrs({ variant: 'rect' })`
  && {
    width: 50%;
    min-width: 64px;
    height: 1.75em;
    border-radius: 1em;
  }
`;

export const ProductCarouselCardSkeleton: React.FunctionComponent = () => (
  <WidgetContainer>
    <LeftPane>
      <ImageSkeleton />
    </LeftPane>

    <RightPane>
      <TitleSkeletonContainer>
        <TitleTextSkeleton width="100%" />
        <TitleTextSkeleton width="85%" />
        <TitleTextSkeleton width="70%" />
      </TitleSkeletonContainer>
      <FavoriteButtonSkeletonContainer>
        <FavoriteButtonSkeleton />
      </FavoriteButtonSkeletonContainer>

      <BottomContainer>
        <PricingSkeletonContainer>
          <PricingTextSkeleton />
          <PricingTextSkeleton />
        </PricingSkeletonContainer>
        <QtySkeletonContainer>
          <QtySkeleton />
        </QtySkeletonContainer>
      </BottomContainer>
    </RightPane>
  </WidgetContainer>
);
