import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

export const SectionTitle = styled(Typography)`
  && {
    font-size: 1.6rem;
    font-weight: 800;
    margin-bottom: 10px;
  }
`;
