import { recordException } from 'utils/Reporting/Sentry';
import { v3Client } from '../../../utils/Client/v3';
import { showError } from '../Global/actions';

export const fetchPassword = (swipeRxToken: string) => async (dispatch) => {
  try {
    const result = await v3Client.get(`/users/${swipeRxToken}`);
    return result.data;
  } catch (error) {
    recordException(error, 'fetchPassword');
    dispatch(showError(error.message));
    return error;
  }
};

export const postChangePassword =
  (oldPassword: string, newPassword: string, swipeSessToken: string) => async (dispatch) => {
    try {
      const result = await v3Client.post(`/users/change-password`, { oldPassword, newPassword, swipeSessToken });
      if (!result) {
        return false;
      }

      return true;
    } catch (error) {
      recordException(error, 'postChangePassword', { oldPassword, newPassword });
      dispatch(showError(error.message));
      return error;
    }
  };
