export const SEARCH_SUGGESTIONS = 'SEARCH_SUGGESTIONS';
export type SEARCH_SUGGESTIONS = typeof SEARCH_SUGGESTIONS;

export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';
export type CLEAR_SUGGESTIONS = typeof CLEAR_SUGGESTIONS;

export const SET_MENU_DRAWER = 'SET_MENU_DRAWER';
export type SET_MENU_DRAWER = typeof SET_MENU_DRAWER;

export const SET_RECENTLY_VIEWED_SECTION_PRODUCTS = 'SET_RECENTLY_VIEWED_SECTION_PRODUCTS';
export type SET_RECENTLY_VIEWED_SECTION_PRODUCTS = typeof SET_RECENTLY_VIEWED_SECTION_PRODUCTS;

export const START_SEARCH_SUGGESTION = 'START_SEARCH_SUGGESTION';
export type START_SEARCH_SUGGESTION = typeof START_SEARCH_SUGGESTION;

export const SET_SEARCH_SUGGESTION_LOADING = 'SET_SEARCH_SUGGESTION_LOADING';
export type SET_SEARCH_SUGGESTION_LOADING = typeof SET_SEARCH_SUGGESTION_LOADING;
