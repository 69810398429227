import React from 'react';

import { BannerLazyImage } from '../BannerCarousel';
import { SkeletonLoader } from '../skeleton-loader';
import { LazyImage } from '../LazyImage';

import { Image } from './banner.style';

export interface BannerProps {
  src: string;
  link: string;
  alt?: string;
  onClick?: (url: string) => void;
  onPlay?: () => void;
  onPause?: () => void;
  lazyImage?: BannerLazyImage | undefined;
}

export const Banner: React.FC<React.PropsWithChildren<BannerProps>> = ({ src, alt, link, onClick, onPlay, onPause, lazyImage }) => {
  const handleClick = (): void => {
    if (!onClick) return;

    onClick(link);
  };

  if (lazyImage) {
    return (
      <LazyImage height={lazyImage.height} width={lazyImage.width} src={src} alt={alt || src} onClick={handleClick}>
        {lazyImage.skeletonLoader && <SkeletonLoader {...lazyImage.skeletonLoader} />}
      </LazyImage>
    );
  }

  return (
    <Image src={src} alt={alt} showLoader={false} onClick={handleClick} onPointerDown={onPause} onPointerUp={onPlay} />
  );
};
