import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import { BLUE, WHITE } from 'components/styles/colors';

export const SnackbarCartNotification = styled.div`
  position: sticky;
  bottom: 100px;
  margin: 12px;
  z-index: 9999;

  .card {
    padding: 10px;
    box-shadow: none;
    border-radius: 8px;
    background: ${BLUE};

    .grid-view {
      .cart-text {
        margin-left: 2px;
        line-height: 0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12pt;
        color: ${WHITE};
      }
    }

    .basket-icon {
      color: ${WHITE};
    }
  }
`;

export const AddToCartText = styled(Typography)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: ${WHITE};
  }
`;

export const MaxPurchaseText = styled(Typography)`
  && {
    margin-top: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.16px;
    color: ${WHITE};
  }
`;
