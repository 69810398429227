import axios from 'axios';
import { getMarketId } from 'utils/MarketConfig';
import { registerRefreshTokenInterceptor } from './refreshToken/refreshToken.interceptor';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 5000 * 10,
});

client.interceptors.request.use((config) => {
  const newConfig = { ...config };

  newConfig.headers.Authorization = `Bearer ${localStorage.getItem('access_token') || null}`;
  newConfig.headers['x-warp-api-key'] = process.env.REACT_APP_PH_API_KEY;

  if (newConfig.baseURL?.split('/').pop() !== 'pharmacy') {
    newConfig.baseURL += `${getMarketId()}/pharmacy`;
  }

  return newConfig;
});

registerRefreshTokenInterceptor(client);

const request = {
  fetch: async (url, fields?) => {
    const params = fields ? fields.params : {};
    return client.get(url, { params }).then((response) => response.data);
  },

  update: async (url, fields) => {
    const payload = fields ? fields.params : {};
    return client.put(url, payload).then((response) => response.data);
  },

  create: async (url, fields) => {
    const payload = fields ? fields.params : {};
    return client.post(url, payload).then((response) => response.data);
  },

  patch: async <D = any, P = any>(url: string, payload?: D, params?: P): Promise<D | undefined> => {
    try {
      const { data } = await client.patch<D>(url, payload, { params });

      return data;
    } catch (e) {
      console.error('@requestClient.post', e.message);
      throw e;
    }
  },

  updateCoreUserOptions: async (_, fields) => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SWIPERX_API_URL,
      timeout: 5000 * 10,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_SWIPERX_API_KEY,
      },
    });

    return instance
      .put(`/pt/profile/${fields.coreUserId}`, { isPtHomepage: fields.isPtHomepage, isPtActive: true })
      .then((response) => response.data);
  },

  getCoreProfile: async (_, coreUserId) => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SWIPERX_API_URL,
      timeout: 5000 * 10,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_SWIPERX_API_KEY,
      },
    });

    return instance.get(`/pt/profile/${coreUserId}`).then((response) => response.data);
  },
};

export default request;
