import i18n from 'i18next';

import en from './lang/en';
import id from './lang/id';
import th from './lang/th';

import { market } from '../MarketConfig';
import { FALLBACK_LOCALE } from './types';

export default i18n.init({
  appendNamespaceToMissingKey: true,
  debug: process.env.NODE_ENV === 'development',
  defaultNS: 'app',
  lng: market.marketId || FALLBACK_LOCALE,
  fallbackLng: FALLBACK_LOCALE,
  interpolation: {
    escapeValue: false,
  },
  ns: ['app'],
  react: {
    wait: true,
  },
  resources: {
    en,
    id,
    th,
  },
});

export * from './locale.utils';
export * from './types';
