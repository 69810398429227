import * as React from 'react';

import {
  StyledIconButton,
  SimilarProductsContainer,
  SimilarProductsIcon,
  SimilarProductsText,
} from './similar-products-button.styles';

interface Props {
  text?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => any;
}

export const SimilarProductsButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { text, onClick } = props;

  return (
    <StyledIconButton onClick={onClick}>
      <SimilarProductsContainer>
        <SimilarProductsIcon />
        <SimilarProductsText>{text}</SimilarProductsText>
      </SimilarProductsContainer>
    </StyledIconButton>
  );
};

SimilarProductsButton.defaultProps = {
  text: 'Similar Products',
  onClick: undefined,
};
