import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Divider } from 'components/common/Divider';
import { Container, Label, Value, IconButton, FileCopy } from './PaymentDetailsCard.styles';

import { Copied } from '../../Copied';

interface Props {
  label: string;
  value: string;
  width?: string;
  variant?: 'horizontal' | 'vertical';
  paid?: boolean;
  billCode?: string;
  billReference?: string;
}

export const PaymentDetailsCard: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { label, value, width, paid, billCode, billReference, variant = 'vertical' } = props;
  const [showClipboard, setShowClipBoard] = useState(false);

  return (
    <>
      <Container
        container
        item
        direction="column"
        alignItems="center"
        justify="center"
        alignContent="center"
        width={width}
        paid={paid}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {variant === 'vertical' ? (
          <>
            <Grid item>
              <Label>{label}</Label>
            </Grid>
            <Grid item>
              <Value>{value}</Value>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} style={{ width }}>
            {billReference && (
              <Grid container item direction="row" justify="space-between" style={{ marginTop: 5 }}>
                <Grid item>
                  <Label weight={500} textTransform="none">
                    Bill reference
                  </Label>
                </Grid>
                <Grid item>
                  <Value color="#181D29" size={12}>
                    {billReference}
                  </Value>
                </Grid>
              </Grid>
            )}
            {billCode && (
              <Grid container item direction="row" justify="space-between" style={{ marginTop: 5 }}>
                <Grid item>
                  <Label weight={500} textTransform="none">
                    Transaction ID
                  </Label>
                </Grid>
                <Grid item>
                  <Value color="#181D29" size={12}>
                    {billCode}
                    <CopyToClipboard
                      text={billCode}
                      onCopy={() => {
                        setShowClipBoard(true);
                      }}
                    >
                      <IconButton>
                        <FileCopy />
                      </IconButton>
                    </CopyToClipboard>
                  </Value>
                </Grid>
              </Grid>
            )}
            {(billReference || billCode) && <Divider color="rgba(0, 0, 0, 0.1)" />}
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6} style={{ textAlign: 'left' }}>
                <Label color="initial" size={14}>
                  {label}
                </Label>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Value color="primary" size={20} weight={700}>
                  {value}
                </Value>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
      <Copied
        hidden={!showClipboard}
        onDismiss={() => {
          setShowClipBoard(false);
        }}
      />
    </>
  );
};
