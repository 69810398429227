import { useEffect, useRef, useState } from 'react';

interface IVisualViewport {
  panelRef: any;
  getPanelHeight?: () => number | undefined;
  getVisualViewportHeight?: () => number;
  visualViewportHeight: number;
}
export const useVisualViewport = (skip?: boolean): IVisualViewport => {
  const panelRef = useRef(null);
  const visualViewport = ((window as any)?.visualViewport as any) || {};
  const [visualViewportHeight, setVisualViewportHeight] = useState(Math.round(visualViewport?.height));

  const getPanelHeight = (): number | undefined => (panelRef?.current as any)?.offsetHeight;

  const getVisualViewportHeight = (): number => visualViewportHeight;

  const getMaxPanelHeight = (): number => {
    // Eventually get directly from CSS to avoid magic number
    const arbitraryBlockMarginEndValue = 16;

    const getAvailableSpaceForPanel = (): number =>
      (visualViewport?.height || 0) -
      arbitraryBlockMarginEndValue -
      (panelRef?.current as any)?.getBoundingClientRect().top;

    const availableSpaceForPanel = getAvailableSpaceForPanel();

    return availableSpaceForPanel;
  };

  // TODO: Add check for --panel-max-block-size. If missing, add it with a default value.
  /**
   * @description Sets the css custom property, --panel-max-block-size,
   * to the max-block-size available for the panel
   * @returns {undefined}
   */
  const setMaxpanelHeight = (): number =>
    panelRef && (panelRef?.current as any)?.style.setProperty('--panel-max-block-size', `${getMaxPanelHeight()}px`);

  const handleVisualViewportResize = (): void => {
    setVisualViewportHeight(Math.round(visualViewport?.height));
    setMaxpanelHeight();
  };

  useEffect(() => {
    if (skip) return;
    visualViewport.addEventListener('resize', handleVisualViewportResize);
  });

  return {
    panelRef,
    getPanelHeight,
    getVisualViewportHeight,
    visualViewportHeight,
  };
};
