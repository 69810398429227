import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Div = styled.div`
  outline: none;
  position: relative;
`;

const AnimatedDiv = animated(Div);

export const ModalWrapper = React.forwardRef<HTMLDivElement, FadeProps>(
  (props, ref) => {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
      from: { opacity: 0, top: open ? '100%' : '0%' },
      to: { opacity: open ? 1 : 0, top: open ? '0%' : '100%' },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });

    return (
      <AnimatedDiv ref={ref} style={style} {...other}>
        {children}
      </AnimatedDiv>
    );
  }
);
