import * as Redux from 'redux';

import * as constants from './constants';
import { Actions, AccountActionType } from './actions';

export interface State {
  options: boolean;
  defaultVirtualAccountID?: number;
  virtualAccounts?: constants.VirtualAccount[];
  loading: boolean;
  memberships?: constants.Memberships;
  membershipsLoading: boolean;
  paymentProvider?: constants.PaymentProviders;
}

const InitialState: State = {
  options: false,
  loading: false,
  membershipsLoading: true,
};

const AccountReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.ACCOUNT_HELP_DEFAULT_HOMEPAGE:
      return {
        ...state,
        options: action.options,
      };

    case constants.FAIL_ACCOUNT_HELP_DEFAULT_HOMEPAGE:
      return {
        ...state,
        coreUser: {},
        error: action.error,
      };

    case constants.GET_HELP_DEFAULT_HOMEPAGE:
      return {
        ...state,
        options: action.options,
      };

    case constants.FAIL_GET_HELP_DEFAULT_HOMEPAGE:
      return {
        ...state,
        coreUser: {},
        error: action.error,
      };

    case AccountActionType.SET_DEFAULT_VIRTUAL_ACCOUNT: {
      const { virtualAccountID } = action.payload;
      const { virtualAccounts } = state;

      const remarkedVirtualAccounts =
        virtualAccounts &&
        virtualAccounts.map((virtualAccount) => ({
          ...virtualAccount,
          is_default: virtualAccount.id === virtualAccountID && !virtualAccount.is_default,
        }));

      return {
        ...state,
        virtualAccounts: remarkedVirtualAccounts,
      };
    }

    case AccountActionType.SET_VIRTUAL_ACCOUNTS: {
      const { virtualAccounts } = action.payload;

      return {
        ...state,
        virtualAccounts,
      };
    }

    case AccountActionType.SET_VIRTUAL_ACCOUNTS_LOADING: {
      const { loading } = action.payload;

      return {
        ...state,
        loading,
      };
    }

    case AccountActionType.SET_MEMBERSHIPS: {
      const { memberships } = action.payload;

      return {
        ...state,
        memberships,
      };
    }

    case AccountActionType.SET_MEMBERSHIPS_LOADING: {
      const { membershipsLoading } = action.payload;

      return {
        ...state,
        membershipsLoading,
      };
    }

    case AccountActionType.SET_PAYMENT_PROVIDER: {
      const { paymentProvider } = action.payload;

      return {
        ...state,
        paymentProvider,
      };
    }

    default:
      return state;
  }
};

export default AccountReducer;
