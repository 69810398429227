import { Action as ReduxAction } from 'redux';

export enum SectionCardActionType {
  TOGGLE_SECTION_CARD = '@@section-card/TOGGLE',
}

interface ToggleSectionCard
  extends ReduxAction<SectionCardActionType.TOGGLE_SECTION_CARD> {
  payload: {
    init?: boolean;
    name: string;
  };
}

export type Action = ToggleSectionCard;

export const toggleSectionCard = (
  name: string,
  init?: boolean,
): ToggleSectionCard => ({
  type: SectionCardActionType.TOGGLE_SECTION_CARD,
  payload: { init, name },
});
