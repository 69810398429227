import * as Redux from 'redux';
import { SortType } from 'components/common/FilterToolbar/constants';
import * as constants from './constants';
import { Actions } from './actions';

export interface PromotedRequest {
  page: number;
  limit: number;
  query?: string;
  sort_order?: SortType;
}

export interface PromotedDetails {
  distributor: {
    id: number;
    name: string;
  };
  product_id: number;
  product_name: string;
  package: string;
  selling_price: number;
  net_price: number;
  discount_rate: number;
}

export interface State {
  error?: string;
  loading: boolean;
  list: any;
  homePage: any;
}

const InitialState: State = {
  loading: true,
  list: [],
  homePage: [],
};

const PromotedReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_PROMOTED_PRICE:
      return {
        ...state,
        loading: false,
        nextPage: action.nextPage,
        list: action.list,
        homePage: action.homePage,
        hasMore: action.hasMore,
      };
    case constants.FAIL_GET_PROMOTED_PRICE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default PromotedReducer;
