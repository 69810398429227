import React from 'react';

import { S } from './Alert.style';

export enum AlertVariant {
  DANGER = 'red',
  SUCCESS = '#E3F4F2',
  WARNING = 'yellow',
  INFO = 'blue',
}

interface Props {
  title: string;
  message: string | React.ReactChild;
  variant: AlertVariant;
}

export const Alert: React.FC<React.PropsWithChildren<Props>> = ({ title, message, variant }) => (
  <S.Wrapper variant={variant}>
    <S.Title>{title}</S.Title>
    <S.Message>{message}</S.Message>
  </S.Wrapper>
);
