export const UPDATE_CART = 'UPDATE_CART';
export type UPDATE_CART = typeof UPDATE_CART;

export const FAIL_CART = 'FAIL_CART';
export type FAIL_CART = typeof FAIL_CART;

export const GET_CART = 'GET_CART';
export type GET_CART = typeof GET_CART;

export const UPDATE_SERVING_DISTRIBUTOR = 'UPDATE_SERVING_DISTRIBUTOR';
export type UPDATE_SERVING_DISTRIBUTOR = typeof UPDATE_SERVING_DISTRIBUTOR;

export const GET_MINIMUM_INVOICE_AMOUNT = 'GET_MINIMUM_INVOICE_AMOUNT';
export type GET_MINIMUM_INVOICE_AMOUNT = typeof GET_MINIMUM_INVOICE_AMOUNT;

export const GETTING_CART = 'GETTING_CART';
export type GETTING_CART = typeof GETTING_CART;

export const CART_UPDATED = 'CART_UPDATED';
export type CART_UPDATED = typeof CART_UPDATED;

export const EMPTY_BASKET_CART = 'EMPTY_BASKET_CART';
export type EMPTY_BASKET_CART = typeof EMPTY_BASKET_CART;

export const UPDATE_PRODUCTS_EXCEEDING_LIMIT = 'UPDATE_PRODUCTS_EXCEEDING_LIMIT';
export type UPDATE_PRODUCTS_EXCEEDING_LIMIT = typeof UPDATE_PRODUCTS_EXCEEDING_LIMIT;
