import { Settings } from 'store/reducers/Prices';

export const getMostRecentSuspensionReason = (settingList: Settings[]): Settings | null => {
  const suspensions = (settingList || []).filter(({ name }) => name === 'transaction_disabled_reason');

  const mostRecentSuspension = suspensions[suspensions.length - 1] || null;

  return mostRecentSuspension;
};

export const getMostRecentSuspension = (settingList: Settings[]): Settings | null => {
  const suspensions = (settingList || []).filter(({ name }) => name === 'transaction_disabled');

  const mostRecentSuspension = suspensions[suspensions.length - 1] || null;

  return mostRecentSuspension;
};
