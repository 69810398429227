import React from 'react';

import { translate } from 'react-i18next';

import S from './DirectPaymentPage.styles';

import { PaidNotification } from './components';

const BillPaymentStatusNotificationBase = ({ isBillPaid, billReference, isFullyPaid, t }): JSX.Element => (
  <>
    {(isBillPaid || isFullyPaid) && <PaidNotification billReference={billReference} isFullyPaid={isFullyPaid} />}
    {isFullyPaid && (
      <S.PaymentFullContainer>
        <S.PaymentFullCaptionWrapper>
          <S.PaymentFullCaptionTitle>{t('headerPaymentFullTitle')}</S.PaymentFullCaptionTitle>
          <S.PaymentFullCaptionMessage>{t('headerPaymentFullMessage')}</S.PaymentFullCaptionMessage>
        </S.PaymentFullCaptionWrapper>
        <S.PaymentFullImgWrapper>
          <S.PaymentFullImg />
        </S.PaymentFullImgWrapper>
      </S.PaymentFullContainer>
    )}
    {!isFullyPaid && (
      <S.TimerIconWrapper>
        <S.TimerIcon />
        <S.TimerLabel>{t('timerLabel')}</S.TimerLabel>
      </S.TimerIconWrapper>
    )}
  </>
);

export const BillPaymentStatusNotification = translate('directPayment')(BillPaymentStatusNotificationBase);
