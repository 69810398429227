import styled from 'styled-components';
import { Checkbox as MuiCheckbox, Typography } from '@material-ui/core';

import { Card as SharedCard } from 'components/common';

const Card = styled(SharedCard)`
  padding: 5px;
  box-shadow: none;
  width: 100%;
`;

const Checkbox = styled(MuiCheckbox)`
  && {
    padding: 10px 0;
  }
`;
const Text = styled(Typography)`
  && {
    font-size: 14px;
  }
`;

export const S = { Card, Checkbox, Text };
