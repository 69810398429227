import { ILogisticsArea } from 'services/swipe-rx-pt/resources/logistics-pharmacy-area/interfaces/LogisticsPharmacyArea.interface';
import { PaymentMethods } from 'utils/constants';
import { ServingDistributor, MarketingSegment } from './pharmacy.interface';

export enum PharmacyActionTypes {
  SET_PHARMACY_SERVING_DISTRIBUTORS = '@@pharmacy/SET_PHARMACY_SERVING_DISTRIBUTORS',
  SET_PHARMACY_LOADING = '@@pharmacy/SET_PHARMACY_LOADING',
  SET_PHARMACY_ERROR = '@@pharmacy/SET_PHARMACY_ERROR',
  SET_PHARMACY_MARKETING_SEGMENT = '@@pharmacy/SET_PHARMACY_MARKETING_SEGMENT',
  SET_PHARMACY_PAYMENT_METHOD = '@@pharmacy/SET_PHARMACY_PAYMENT_METHOD',
  SET_PHARMACY_LOGISTIC_AREAS = '@@pharmacy/SET_PHARMACY_LOGISTIC_AREAS',
}

export interface SetPharmacyServingDistributors {
  type: PharmacyActionTypes.SET_PHARMACY_SERVING_DISTRIBUTORS;
  payload: { data: ServingDistributor[] };
}

export interface SetPharmacyMarketingSegment {
  type: PharmacyActionTypes.SET_PHARMACY_MARKETING_SEGMENT;
  payload: { data: MarketingSegment };
}

export interface SetPharmacyLoading {
  type: PharmacyActionTypes.SET_PHARMACY_LOADING;
  payload: { loading: boolean };
}

export interface SetPharmacyError {
  type: PharmacyActionTypes.SET_PHARMACY_ERROR;
  payload: { error: string };
}

export interface SetPharmacyPaymentMethod {
  type: PharmacyActionTypes.SET_PHARMACY_PAYMENT_METHOD;
  payload: PaymentMethods;
}

export interface SetPharmacyLogisticAreas {
  type: PharmacyActionTypes.SET_PHARMACY_LOGISTIC_AREAS;
  payload: ILogisticsArea[];
}

export type PhamarcyActions =
  | SetPharmacyServingDistributors
  | SetPharmacyLoading
  | SetPharmacyError
  | SetPharmacyMarketingSegment
  | SetPharmacyPaymentMethod
  | SetPharmacyLogisticAreas;
