import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import * as colors from 'components/styles/colors';
import ScrollView from 'components/views/ScrollView';

const Wrapper = styled(ScrollView)`
  height: 100vh;
  background-color: ${colors.SNOW};
`;

const Header = styled(Grid)`
  && {
    padding: 18px 0;
    background-color: ${colors.WHITE};
    box-shadow: 0px 0px 1px #b5b9c2;
    width: 100%;
    position: relative;
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
`;

const CancelBtn = styled(CancelIcon)`
  && {
    width: 30px;
    height: 30px;
    fill: #9399a8;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
  }
`;

export const S = {
  Wrapper,
  Header,
  Title,
  CancelBtn,
};
