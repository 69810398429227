export const GET_SIMILAR_PRODUCT_CAROUSEL = 'GET_SIMILAR_PRODUCT_CAROUSEL';
export type GET_SIMILAR_PRODUCT_CAROUSEL = typeof GET_SIMILAR_PRODUCT_CAROUSEL;

export const CLEAR_SIMILAR_PRODUCT_CAROUSEL = 'CLEAR_SIMILAR_PRODUCT_CAROUSEL';
export type CLEAR_SIMILAR_PRODUCT_CAROUSEL = typeof CLEAR_SIMILAR_PRODUCT_CAROUSEL;

export const SET_SIMILAR_PRODUCT_CAROUSEL_LOADING = 'SET_SIMILAR_PRODUCT_CAROUSEL_LOADING';
export type SET_SIMILAR_PRODUCT_CAROUSEL_LOADING = typeof SET_SIMILAR_PRODUCT_CAROUSEL_LOADING;

export const SET_SIMILAR_PRODUCT_PROCESSED_IDS = 'SET_SIMILAR_PRODUCT_PROCESSED_IDS';
export type SET_SIMILAR_PRODUCT_PROCESSED_IDS = typeof SET_SIMILAR_PRODUCT_PROCESSED_IDS;

export const CLEAR_SIMILAR_PRODUCT_PROCESSED_IDS = 'CLEAR_SIMILAR_PRODUCT_PROCESSED_IDS';
export type CLEAR_SIMILAR_PRODUCT_PROCESSED_IDS = typeof CLEAR_SIMILAR_PRODUCT_PROCESSED_IDS;
