import React from 'react';

import { ButtonProps } from '@material-ui/core/Button';

import { S } from './Button.style';

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({ children, ...restProps }) => (
  <S.Wrapper {...restProps} data-testid="seeAll-btn">
    {children}{' '}
  </S.Wrapper>
);
