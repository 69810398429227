import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import {
  fetchProductListing,
  clearProductListing,
  clearListingPageReferer,
  setListingPageReferer,
} from 'store/reducers/ProductListing/actions';
import { setCloseSnackbarCart } from 'store/reducers/Utils/actions';
import {
  clearGlobalSuggestions,
  searchGlobalSuggestions,
  setSearchSuggestionLoading,
} from 'store/reducers/SearchSuggestions/actions';
import { setRecentlySearchedProducts } from 'store/reducers/Search/actions';
import { clearFilter } from 'store/reducers/FilterToolbar/actions';
import { State as StoreState } from '../../../store';

import SearchMenuSuggestionsC from './SearchMenuSuggestions.component';
import { ISearchMenuSuggestions, IStateProps } from './searchMenuSuggestion.interface';

const mapStateToProps = (state: StoreState): IStateProps => ({
  suggestions: state.searchSuggestions.suggestions,
  campaign: state.campaign,
  previousQuery: state.productListing.previousQuery,
  suggestionsQuery: state.searchSuggestions.query,
  selectedFilters: state.filterToolbar.selectedFilters,
  selectedSorts: state.filterToolbar.selectedSorts,
  backStack: state.global.backStack,
  reinitialize: !state.filterToolbar.allowSearch,
});

const mapDispatchToProps = {
  fetchProductListing,
  searchSuggestions: searchGlobalSuggestions,
  clearSuggestions: clearGlobalSuggestions,
  clearProductListing,
  clearListingPageReferer,
  setCloseSnackbarCart,
  setListingPageReferer,
  setRecentlySearchedProducts,
  clearFilter,
  setSearchSuggestionLoading,
};

export const SearchMenuSuggestions: React.FC<React.PropsWithChildren<ISearchMenuSuggestions>> = translate('searchPage')(
  connect(mapStateToProps, mapDispatchToProps)(SearchMenuSuggestionsC),
);
