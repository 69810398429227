import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHelpDialog } from 'hooks';

import { defaultWidgetConfig } from 'components/pages/HomePage/style';

import {
  MarketingFeaturesDisplayPosition,
  MarketingFeaturesType,
} from 'services/swipe-rx-pt/resources/marketings/constants';

import { ProductListingWidget } from 'components/common/product-list/interfaces/generic-product-list.interface';
import {
  marketingfeaturesLoadingSelector,
  marketingfeaturesSelector,
} from 'store/reducers/MarketingFeatures/selectors';
import { Widget } from '../../Widget';

interface IOptionalProps {
  withMarketingFeatureWidget?: boolean;
  showCondition?: boolean;
  overrideLimit?: number;
  cleanupFunction?: () => void;
  widgets?: any[];
  [x: string]: any;
}

interface IProps extends Partial<IOptionalProps> {
  featureType: MarketingFeaturesType;
}

export interface FeaturedMarketingProps {
  featureType?: MarketingFeaturesType;
  widgets?: ProductListingWidget[];
}

export const withFeaturedMarketingWidget = (Component: any) => (props: IProps) => {
  const {
    cleanupFunction,
    overrideLimit,
    showCondition = false,
    withMarketingFeatureWidget = false,
    featureType,
    ...rest
  } = props || {};

  const { toggleHelpDialog } = useHelpDialog();

  const marketingFeatures = useSelector(marketingfeaturesSelector(featureType, overrideLimit)) || [];
  const isLoading = useSelector(marketingfeaturesLoadingSelector(featureType)) || false;
  useEffect(
    () => () => {
      if (cleanupFunction) {
        cleanupFunction();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!withMarketingFeatureWidget || isLoading) {
    return <Component {...rest} />;
  }

  const widgets = showCondition
    ? marketingFeatures.map((feature) => ({
        id: feature?.marketing?.id,
        getWidgets: () => [
          <Widget
            key={feature?.id}
            isHomepage={false}
            marketing={{ ...feature.marketing }}
            onItemClick={toggleHelpDialog}
            defaultStyling={defaultWidgetConfig.styling}
          />,
        ],
        position: MarketingFeaturesDisplayPosition.AFTER_LISTING,
      }))
    : props.widgets || [];

  return <Component {...props} widgets={widgets} />;
};
