import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Grid, IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import { routeKey } from 'utils/route.utils';
import { S } from './DPDBlockMessage.style';

interface Props {
  blocked: boolean;
  daysPastDue: number;
  reason: string;
  pastDueInvoiceCount: number;
  t: any;
}

export const DPDBlockMessage: React.FC<React.PropsWithChildren<Props>> = ({ blocked, daysPastDue, reason, pastDueInvoiceCount, t }) => {
  const history = useHistory();
  const location = useLocation();

  const handleRedirectClick = () => {
    history.push({
      pathname: `${routeKey('main_payments')}/due`,
      search: location.search,
    });
  };

  const message = blocked
    ? t('dpd.blockMessage', { pastDueInvoiceCount, dpd: daysPastDue })
    : reason
    ? `${t('suspended.heading')}: ${reason}`
    : null;

  return !message ? null : (
    <S.Wrapper container direction="row" justify="space-between" alignItems="center" onClick={handleRedirectClick}>
      <Grid item xs={11}>
        {message}
      </Grid>
      <Grid item>
        <IconButton aria-label="Payments" onClick={handleRedirectClick}>
          <ChevronRight />
        </IconButton>
      </Grid>
    </S.Wrapper>
  );
};
