import { Language } from 'utils/Localization';

import { SwipeRxPt } from '../../SwipeRxPt.class';
import { Rating } from './interface';

export class SwipeRxPtRating {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  async list(lang: Language): Promise<any> {
    const result = await this.base.getV2('/rating/options', { lang });

    return result;
  }

  async create(payload: Rating): Promise<any> {
    const result = await this.base.post('/rating', payload);

    return result;
  }
}
