import React from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import { ButtonProps } from '@material-ui/core/Button';
import { SectionCardCloseButton } from '../SectionCard';

const Title = styled(Typography)`
  && {
    font-weight: 800;
    margin-bottom: 10px;
  }
`;

const CloseButton = styled(SectionCardCloseButton)`
  && {
    background: rgba(0, 0, 0, 0.1);
    height: 40px;
    width: 40px;
    top: 15px;
    right: 15px;
  }
` as React.ComponentType<React.PropsWithChildren<ButtonProps>>;

export const S = {
  Title,
  CloseButton,
};
