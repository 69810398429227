import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';

import { State as StoreState } from 'store';

import { TranslateProps } from 'utils/Localization';
import { getCallCenterNumber } from 'utils/MarketConfig';
import ShowIf from 'components/views/ShowIf';
import { Tour } from 'components/common/Tour';

import { BasePageTracker } from 'utils/Analytics/Segment';
import { LoadingContainer } from 'components/common/product-list';
import { CircularProgress } from '@material-ui/core';
import { IMenuContent, ICreateSectionConfigs, IItemConfig, ISearchItem } from '../interface';

import { generateRandomId } from '../../../../../utils/Random';
import { EMPTY_EXPAND_SECTION } from '../../constant/search-menu.constants';
import { S } from './search-menu.style';
import { SectionFactory, SectionType } from './SectionFactory';
import { useVisualViewport } from '../../hooks/useVisualViewport';

export const SearchMenuC: React.FC<React.PropsWithChildren<IMenuContent & TranslateProps>> = ({
  t,
  generalSections = [],
  initialSections = [],
  fixedSections = [],
  highlightedIndex,
  getItemProps,
  selectedItem,
  isInitial,
  inputValue = '',
  defaultElementWhenEmpty,
  onSearch,
  menuOpen,
  onSelect,
  listingPage = BasePageTracker.GENERAL_SEARCH,
  isInputFocus,
}) => {
  const isLoading = useSelector((state: StoreState) => state.searchSuggestions.loading, shallowEqual);
  const [expandSection, setExpandSection] = useState<string>(EMPTY_EXPAND_SECTION);

  const clearExpandSectionOnSearch = (): void => {
    const shouldExpandClose = !isInitial && !!expandSection;
    if (shouldExpandClose) {
      setExpandSection(EMPTY_EXPAND_SECTION);
    }
  };
  useEffect(
    clearExpandSectionOnSearch,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!!isInitial],
  );

  const callCenterNumber = getCallCenterNumber();

  const isWebviewIos = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /(iphone|ipod|ipad).*applewebkit.*mobile.*/.test(userAgent);
  };

  const { panelRef, visualViewportHeight } = useVisualViewport(!isWebviewIos());

  const generalSearchTours = [BasePageTracker.GENERAL_SEARCH, BasePageTracker.ALPHABETICAL];
  const showSearchTours = generalSearchTours.includes(listingPage);

  const createSection = (
    items: IItemConfig,
    id: string,
    title: string,
    subtitle: string | null,
    data: ISearchItem[],
    sectionIndex,
    isSectionGeneral: boolean,
    onSeeAllClick?: () => void,
    otherConfig?: ICreateSectionConfigs,
  ): IItemConfig => {
    const { display = SectionType.LIST, ...restConfig } = otherConfig || {};

    const additionalExpandToggle = (key: string): void => {
      setExpandSection(key);
    };

    const section = SectionFactory(display, {
      id,
      t,
      items,
      title,
      subtitle,
      additionalExpandToggle,
      data,
      sectionIndex,
      isSectionGeneral,
      onSeeAllClick,
      expandSection,
      selectedItem,
      getItemProps,
      onSearch,
      highlightedIndex,
      inputValue,
      onSelect,
      ...restConfig,
    });

    if (section) {
      items.sections.push(section);
    }
    return items;
  };

  let itemConfig: IItemConfig = { sections: [], itemIndex: 0 };
  if (!isInitial) {
    itemConfig = generalSections.reduce((result, section, sectionIndex) => {
      if (!section.products?.length && section.shouldNotShowLoadingIfEmpty) return itemConfig;

      const totalCount = section.meta?.total_count;
      const haveSeeAllButton = totalCount > 0;
      const buttonLabel = haveSeeAllButton ? t('seeAllRelated', { count: section.meta.total_count }) : undefined;
      return createSection(
        result,
        section.id,
        section.title,
        null,
        section.products ?? [],
        sectionIndex,
        true,
        haveSeeAllButton ? () => onSearch(inputValue, section.id) : undefined,
        { hideThumbnail: section?.hideThumbnail, buttonLabel, totalCount, ...section },
      );
    }, itemConfig);

    fixedSections.forEach((item) => {
      const { products = [], shouldNotShowLoadingIfEmpty, title, id, ...restConfig } = item || {};
      if (!products?.length && shouldNotShowLoadingIfEmpty) return;
      itemConfig = createSection(
        itemConfig,
        id,
        title,
        null,
        products,
        itemConfig.sections.length + 1,
        false,
        undefined,
        { ...restConfig },
      );
    });
  } else {
    initialSections.forEach(({ products = [], title, id, ...restConfig }) => {
      if (!products.length && restConfig.shouldNotShowLoadingIfEmpty) return;
      itemConfig = createSection(itemConfig, id, title, null, products, generateRandomId(title), true, undefined, {
        ...restConfig,
      });
    });
  }

  const isSectionsEmpty = itemConfig.sections.length === 0 && inputValue !== '';
  const emptyProductElement = defaultElementWhenEmpty || (
    <S.NoProductContainer className="subheading" variant="h3">
      {t('noProducts', { callCenterNumber })}
    </S.NoProductContainer>
  );

  const loader = (
    <LoadingContainer padding="36px 0 0 0">
      <CircularProgress />
    </LoadingContainer>
  );

  return (
    <>
      <ShowIf condition={!!menuOpen && !!showSearchTours}>
        <Tour
          module="search_page"
          welcomeTitle="tour.searchPage.welcomeTitle"
          welcomeMessage="tour.searchPage.welcomeMessage"
          styles={{
            buttonClose: {
              display: 'none',
            },
          }}
        />
      </ShowIf>
      <S.Menu isInputFocus={isInputFocus && isWebviewIos()} visualViewportHeight={visualViewportHeight} ref={panelRef}>
        {isLoading && loader}
        {!isLoading && !isSectionsEmpty && itemConfig.sections}
        {!isLoading && isSectionsEmpty && emptyProductElement}
      </S.Menu>
    </>
  );
};

export const SearchMenu = translate('searchPage')(React.memo(SearchMenuC));
