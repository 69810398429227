export const FETCH_MARKET_CONFIG = 'FETCH_MARKET_CONFIG';
export type FETCH_MARKET_CONFIG = typeof FETCH_MARKET_CONFIG;

export const SET_MARKET_ID = 'SET_MARKET_ID';
export type SET_MARKET_ID = typeof SET_MARKET_ID;

export const SET_MARKET_LANGUAGE = 'SET_MARKET_LANGUAGE';
export type SET_MARKET_LANGUAGE = typeof SET_MARKET_LANGUAGE;

export const SET_MARKET_LOCALE = 'SET_MARKET_LOCALE';
export type SET_MARKET_LOCALE = typeof SET_MARKET_LOCALE;
