import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import * as colors from 'components/styles/colors';

const VoucherTitleContainer = styled(Grid).attrs({ container: true })`
  && {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
`;

const VoucherContainer = styled(Grid).attrs({ container: true, alignItems: 'center' })`
  && {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 1px rgba(99, 106, 122, 0.12);
    background: ${colors.WHITE};
    margin-bottom: 10px;
  }
`;

const VoucherCode = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
  }
`;

const VoucherUsed = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 14px;
    text-align: right;
    color: #eb5757;
  }
`;

const Title = styled(Typography).attrs({ variant: 'body2' })`
  && {
    font-weight: 700;
  }
`;

export const S = { VoucherTitleContainer, VoucherContainer, VoucherCode, VoucherUsed, Title };
