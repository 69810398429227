import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { PharmacyOrderStatus } from 'services';
import * as colors from 'components/styles/colors';

interface StatusProps {
  status: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getColorBasedFromOrderStatus = (status: PharmacyOrderStatus) => {
  if (status === PharmacyOrderStatus.CANCELLED) {
    return {
      bgColor: '#FFE8E8',
      color: colors.DANGER_DARK,
    };
  }

  return {
    bgColor: '#EEF0F4',
    color: colors.BLUISH_GRAY_LIGHT,
  };
};

const Container = styled(Grid)<StatusProps>`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    border-radius: 20px;
    background-color: ${(props) => getColorBasedFromOrderStatus(props.status).bgColor};
  }
`;

const Status = styled(Typography)<StatusProps>`
  && {
    font-size: 10px;
    text-transform: capitalize;
    color: ${({ status }) => getColorBasedFromOrderStatus(status).color};
  }
`;

export const S = { Container, Status };
