import { useSelector, shallowEqual } from 'react-redux';
import { Required } from 'utility-types';

import { State } from 'store';

type RequiredCoreUserAuth = Required<State['auth'], 'coreUser'>;

export const useGetCurrentUser = (): RequiredCoreUserAuth => {
  const user = useSelector((state: State) => state.auth, shallowEqual);
  if (!user.coreUser) {
    throw Error('Empty coreUser');
  }

  return user as RequiredCoreUserAuth;
};
