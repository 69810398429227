/* eslint-disable @typescript-eslint/no-unused-vars */

import { ProductListingParam } from 'components/common';
import { FilterType } from 'components/common/FilterToolbar/constants';

import { LABEL_VALUE_SEPARATOR } from './constants';
import { FilterMenuItem } from './interface';

export const getValueFromFilterOption = (filterOption: FilterMenuItem): string => {
  const [_, ...value] = filterOption.value.split(LABEL_VALUE_SEPARATOR);
  return value;
};

export const getFilterOptionValue = (filterType: FilterType, label: string): string =>
  `${filterType}${LABEL_VALUE_SEPARATOR}${label}`;

export const assignFiltersBasedOnConfig = (
  initialParam: ProductListingParam,
  filterMenuItem: FilterMenuItem,
): ProductListingParam => {
  const { type } = filterMenuItem;

  switch (type) {
    case FilterType.DISCOUNT:
    case FilterType.LOYALTY_EXTRA_POINTS:
    case FilterType.ALPHABETICAL:
    case FilterType.ALL:
    case FilterType.LAST_PURCHASED: {
      const prevFilters = new Set(initialParam.filters);
      prevFilters.add(filterMenuItem.value);
      initialParam.filters = Array.from(prevFilters);

      return initialParam;
    }
    case FilterType.CATEGORY: {
      const value = getValueFromFilterOption(filterMenuItem);
      const { category_ids = [] } = initialParam;
      initialParam.category_ids = [...category_ids, value[0]];
      return initialParam;
    }

    default:
      return initialParam;
  }
};

export const getFilterUrlBySelectedFilters = (selectedFilterOptions: FilterMenuItem[]): string | undefined => {
  const filterOption = selectedFilterOptions.find((filter) => !!filter.filterUrl);
  return filterOption?.filterUrl;
};
