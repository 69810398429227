import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import { updateDefaultVirtualAccount } from '../../../store/reducers/Account/actions';
import { State as RootState } from '../../../store';

import { Button } from '../Button';
import { S } from './VirtualAccountCard.style';
import { VirtualAccount } from '../../../store/reducers/Account/constants';
import { TranslateProps } from '../../../utils/Localization/types';
import * as colors from '../../styles/colors';

interface StateProps {
  pharmacyID: number;
}

interface DispatchProps {
  updateDefaultVirtualAccount: (pharmacyID: number, virtualAccountID: number) => void;
}

export interface VirtualAccountCardProps {
  control?: {
    copy?: boolean;
    setAsDefault?: boolean;
  };
  data: VirtualAccount;
  logo: string;
}

type Props = VirtualAccountCardProps & StateProps & DispatchProps & TranslateProps;

const VirtualAccountCardBase: React.FC<React.PropsWithChildren<Props>> = ({
  data: { bank, id, is_default, number },
  control,
  logo,
  pharmacyID,
  t,
  updateDefaultVirtualAccount,
}) => {
  const [isCopied, setCopyStatus] = useState(false);
  const numberRef = useRef<HTMLInputElement>(null);

  const handleSetAsDefaultClick = (): void => {
    updateDefaultVirtualAccount(pharmacyID, id);
  };

  const handleCopyTooltipStatus = (): void => {
    setCopyStatus((prev) => {
      if (!prev) {
        setTimeout(() => {
          setCopyStatus(false);
        }, 3000);
      }

      return !prev;
    });
  };

  const formattedNumber = `${number.substring(0, 5)}-${number.substring(5, number.length)}`;

  const renderCopyControl = (): JSX.Element => (
    <Grid item xs={control && !control.setAsDefault ? 8 : 5}>
      <CopyToClipboard onCopy={handleCopyTooltipStatus} text={number}>
        <Button
          fullWidth
          disabled={isCopied}
          size="medium"
          variant="contained"
          style={{
            fontWeight: 700,
            fontSize: '10px',
            color: colors.WHITE,
            background: colors.REBRAND_GREEN,
          }}
        >
          {!isCopied ? (
            <>
              {/* <FileCopyIcon fontSize="small" /> */}
              {t('virtualAccount.copy')}
            </>
          ) : (
            t('virtualAccount.copied')
          )}
        </Button>
      </CopyToClipboard>
    </Grid>
  );

  const renderSetAsDefaultControl = (): JSX.Element => (
    <Grid item xs={6}>
      <Button
        fullWidth
        color="default"
        onClick={handleSetAsDefaultClick}
        size="medium"
        variant="outlined"
        style={{
          fontWeight: 700,
          fontSize: '10px',
          border: `1px solid ${colors.REBRAND_GREEN}`,
          color: colors.REBRAND_GREEN,
        }}
      >
        {/* <BookmarkBorderIcon fontSize="small" /> */}
        {is_default ? t('virtualAccount.default') : t('virtualAccount.setAsDefault')}
      </Button>
    </Grid>
  );

  const renderControls = (): JSX.Element | null => {
    if (!control) return null;

    const { copy, setAsDefault } = control;

    return (
      <S.ControlGrid container justify="center" spacing={1}>
        {setAsDefault ? renderSetAsDefaultControl() : null}
        {copy ? renderCopyControl() : null}
      </S.ControlGrid>
    );
  };

  const fullControl = control && control.copy && control.setAsDefault;

  return (
    <S.Wrapper className="virtual-account-card" fullControl={fullControl}>
      <S.Image src={logo} alt={bank} />
      <S.Title>Virtual Account Number</S.Title>
      <S.Number ref={numberRef}>{formattedNumber}</S.Number>
      {renderControls()}
    </S.Wrapper>
  );
};

const mapStateToProps = ({ auth: { coreUser } }: RootState): StateProps => {
  const user: any = coreUser;

  return {
    pharmacyID: user.organization_id,
  };
};

const dispatchProps: DispatchProps = {
  updateDefaultVirtualAccount,
};

export const VirtualAccountCard = translate('accountPage')(
  connect<StateProps, DispatchProps, VirtualAccountCardProps, RootState>(
    mapStateToProps,
    dispatchProps,
  )(VirtualAccountCardBase),
);
