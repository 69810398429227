/* eslint-disable react/require-default-props */
import React from 'react';
import { Modal as MuiModal, Backdrop, ModalProps } from '@material-ui/core';

import { ModalWrapper } from './modal-wrapper';

interface Props extends ModalProps {
  open: boolean;
  onClose: () => void;
  children: any;
  style?: React.CSSProperties;
  BackdropProps?: any;
  onLoad?: () => void;
}

export const Modal: React.FC<React.PropsWithChildren<Props>> = (
  { open, onClose, onLoad, children, style, BackdropProps = {}, ...rest },
  ...modalProps
) => (
  <MuiModal
    onLoad={onLoad}
    style={style}
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      ...BackdropProps,
      timeout: 500,
    }}
    {...modalProps}
    {...rest}
  >
    <ModalWrapper in={open}>{children}</ModalWrapper>
  </MuiModal>
);
