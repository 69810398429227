import React, { useEffect, Suspense } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Virtuoso } from 'react-virtuoso';

import { useBatchImpression } from 'utils/ProductImpressions';
import { ProductCard } from 'components/common/ProductCard';
import { TOURS } from 'components/common/Tours';
import Content from 'components/views/Content';
import { useCartNotification } from 'hooks';
import { State } from 'store';
import { Product } from 'store/reducers/Product';
import {
  startTrackImpression as causalFoundryStartTrackImpression,
  stopTrackImpression as causalFoundryStopTrackImpression,
  trackSearchEvent as causalFoundryTrackSearchEvent,
} from 'utils/Analytics/CausalFoundry';
import { TranslateProps } from 'utils/Localization/types';
import { getProductCardTours } from 'utils/tours';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';
import { BasePageTracker } from 'utils/Analytics/Segment';
import { IanalyticsDataLog } from 'utils/Analytics/interfaces';

import { S } from './product-list.styles';
import { withSimilarProductWidget } from './hoc/withSimilarProductWidget';
import { ProductListData, ProductListingWidget } from './interfaces/generic-product-list.interface';
import {
  LoaderComponent,
  EndMessage,
  TopMessage,
  ProductShimmer,
  InBetweenWidgets,
  EndOfResultDivider,
} from './components';
import { ProductCardLoader } from '../ProductCard/components/product-card-loader';
import { AfterListingWidgets, withFeaturedMarketingWidget } from '../AfterListingWidgets';

export interface ProductListProps extends TranslateProps {
  data: ProductListData;
  emptyMessage?: string | JSX.Element;
  message?: string | JSX.Element;
  endMessage?: JSX.Element;
  fetchProducts: (caller: string) => void;
  analyticsDataLog?: IanalyticsDataLog;
  onProductClear?: (product: Product) => void;
  toursModule?: keyof typeof TOURS;
  style?: any;
  productImpressionPage?: BasePageTracker;
  singleImpression?: boolean;
  hideEndMessage?: boolean;
  widgets?: ProductListingWidget[];
  detailPageDisplayType?: DetailPageDisplayType;
  query?: string;
  combineStyle?: React.CSSProperties;
}

const ProductListBase: React.FC<React.PropsWithChildren<ProductListProps>> = ({
  fetchProducts,
  data,
  endMessage,
  message,
  onProductClear,
  analyticsDataLog,
  toursModule,
  style,
  singleImpression,
  productImpressionPage,
  hideEndMessage,
  widgets,
  detailPageDisplayType = DetailPageDisplayType.ROUTE,
  query,
  combineStyle = {},
}) => {
  const { impressionCollector } = useBatchImpression();
  const { shouldShowCart } = useCartNotification();

  useEffect(() => {
    if (data?.products?.length === 0) {
      const scrollableDiv = document.querySelector('.infinite-scroll-component');
      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    }
  }, [data.products, query]);

  useEffect(() => {
    if (data.products && data.products.length > 0 && query) {
      return () => {
        causalFoundryTrackSearchEvent(
          {
            contentBlock: 'e-commerce',
            filter: {},
            page: 1,
            query,
            results_list: data.products.map((result) => `${result.id}`),
          },
          false,
        );
      };
    }
    return () => null;
  }, [data.products, query]);

  useEffect(() => {
    if (analyticsDataLog) {
      causalFoundryStartTrackImpression(analyticsDataLog.containerName, analyticsDataLog.itemName);
      return () => {
        causalFoundryStopTrackImpression(analyticsDataLog.containerName);
      };
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const tours = useSelector((state: State) => state?.tours);
  const isFuzzy = !!useSelector((state: State) => state?.productListing.isApproximate);
  const isTourOver = toursModule && !tours?.loading && tours?.tours?.includes(toursModule);
  const isShowSuggestion = !data.loading && data?.products?.length < 8;
  const isProductsEmpty = !data.products.length;
  const finishedLoadingEmptyProducts = !data.loading && isProductsEmpty;
  const isLoadingAndEmptyProducts = data.loading && isProductsEmpty;

  const defaultStyle = {
    top: 8,
    left: 0,
    right: 0,
    bottom: shouldShowCart ? 60 : 0,
    position: 'absolute',
    overflowY: isTourOver ? 'scroll' : 'auto',
    overflowX: 'hidden',
    ...combineStyle,
  };

  const parentScroll = document.getElementById('generic-product-wrapper') as HTMLElement;

  const shouldHideEndMessage = hideEndMessage && !widgets?.length;

  return (
    <S.Wrapper container direction="column">
      <Content>
        <div
          className={analyticsDataLog?.containerName || ''}
          id="generic-product-wrapper"
          style={style || defaultStyle}
        >
          <TopMessage empty={isFuzzy || finishedLoadingEmptyProducts} fuzzy={isFuzzy} query={query} message={message} />

          <Virtuoso
            key="virtuoso"
            totalCount={data.products.length}
            data={data.products}
            endReached={() => data.hasMore && !data.loading && fetchProducts('generic infinite scroll')}
            overscan={{ reverse: 200, main: 0 }}
            itemContent={(i, entry) => (
              <React.Fragment key={entry.id}>
                <Suspense fallback={<ProductCardLoader />}>
                  <ProductCard
                    isReminder
                    product={entry}
                    key={entry.id}
                    tourIds={getProductCardTours(i)}
                    onClear={onProductClear}
                    analyticsDataLog={{ causalFoundry: { itemClassName: analyticsDataLog?.itemName } }}
                    productImpressionPage={productImpressionPage}
                    indexNumber={i + 1}
                    impressionCollector={impressionCollector}
                    singleImpression={singleImpression}
                    detailPageDisplayType={detailPageDisplayType}
                  />
                </Suspense>
                <InBetweenWidgets widgets={widgets} product={entry} index={i} />
              </React.Fragment>
            )}
            customScrollParent={parentScroll}
          />
          <div>
            {isLoadingAndEmptyProducts && <ProductShimmer />}
            <EndOfResultDivider end={isShowSuggestion && data?.products?.length > 0} />
            <AfterListingWidgets widgets={widgets} />
            {/* NOTE: intended to avoid switch UI */}
            {data.hasMore && <LoaderComponent />}
            {!data.hasMore && !data.loading && !shouldHideEndMessage && <EndMessage message={endMessage} />}
          </div>
        </div>
      </Content>
    </S.Wrapper>
  );
};

export const GenericProductList = translate('productList')(ProductListBase);

export const GenericProductListWithWidget = compose(
  withFeaturedMarketingWidget,
  withSimilarProductWidget,
)(ProductListBase);
