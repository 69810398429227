import React, { useEffect, useRef, useState } from 'react';
import { KeyDown } from 'components/types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import qs from 'query-string';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {
  getEventPageIdentity,
  trackProductDetail,
  SegmentPropsBuilder,
  getIsMarketingListing,
  BasePageTracker,
} from 'utils/Analytics/Segment';
import { getMarketId } from 'utils/MarketConfig';
import { useSearchSectionBuilder, useMarketing, useTrackSuggestionAnalytic } from 'hooks';

import Downshift, { ControllerStateAndHelpers, StateChangeTypes } from 'downshift';

import { BRACKET_QS_OPTIONS } from 'utils/constants';
import Search from '../Search';
import { SearchMenu } from './components';
import { downshiftStateReducer, itemToString } from './utils';
import { IDispatchProps, ILocationProps, ISearchMenuSuggestions, IStateProps } from './searchMenuSuggestion.interface';
import { DebounceConstant, SECTION_TITLE_TRANSLATION } from './constant';
import { useSearchMenuHandler } from './hooks';
import { categoryFilterSeparator, updateSuggestionThumbnail } from './utils/suggestions.util';
import { getSearchTypedQuery } from '../Search/utils';
import { ISearchItem } from './components/interface';

const SearchMenuSuggestions: React.FC<React.PropsWithChildren<ISearchMenuSuggestions & IDispatchProps & IStateProps>> =
  (props) => {
    const {
      full = true,
      defaultValue = '',
      suggestions = [],
      fixedSuggestions = [],
      initialSuggestions = [],
      readOnly = false,
      autoFill = true,
      suggestionParams,
      params,
      listingPage,
      detailPageId,
      suggestionsQuery,
      ...dispatchProps
    } = props;

    const {
      searchSuggestions,
      fetchProductListing,
      clearProductListing,
      clearListingPageReferer,
      setRecentlySearchedProducts,
      setCloseSnackbarCart,
      setListingPageReferer,
      clearSuggestions,
      clearFilter,
      setSearchSuggestionLoading,
    } = dispatchProps || {};

    const match = useRouteMatch();
    const history = useHistory();
    const { trackSearchSuggestion } = useTrackSuggestionAnalytic();

    const locationParsed = qs.parse(history.location.search, BRACKET_QS_OPTIONS) as ILocationProps;
    const { query = '', goBackTo, filters } = locationParsed;

    const isOnSearchPage = match.url.includes('search');
    const defaultInputValue = autoFill ? query || defaultValue : '';

    const isInitModalOpen = isOnSearchPage && !(query || filters?.length);
    const { isMenuOpen, setMenuOpen } = useSearchMenuHandler(isInitModalOpen);
    const [inputValue, setInputValue] = useState(defaultInputValue);

    const debounceWaitTime = Number(process.env.REACT_APP_SUGGESTION_DEBOUNCE) || DebounceConstant.WAIT;

    const debounceMaxWaitTime = Number(process.env.REACT_APP_SUGGESTION_DEBOUNCE_MAX_WAIT) || DebounceConstant.MAX_WAIT;
    const [delayedValue] = useDebounce(inputValue, debounceWaitTime, { maxWait: debounceMaxWaitTime });

    const isMarketingListing = getIsMarketingListing(listingPage);
    const isMarketingAvailable = isMarketingListing ? !!params?.marketing_id : true;

    const { marketing } = useMarketing(false);
    const eventPageIdentity = getEventPageIdentity(match, history.location.search);

    const sectionProps = {
      initialSuggestions,
      fixedSuggestions,
    };

    const { initialSections, fixedSections } = useSearchSectionBuilder(sectionProps);

    const isGeneralSearch = [BasePageTracker.GENERAL_SEARCH, BasePageTracker.ALPHABETICAL].includes(listingPage);
    let search_section: string | undefined = isGeneralSearch ? BasePageTracker.GENERAL_SEARCH : undefined;

    const inputRef = useRef<any>(null);

    const focusOnClear = (): void => {
      if (inputRef.current) inputRef.current.focus();
    };

    const handleBlurOnSearch = (): void => {
      if (inputRef.current) inputRef.current.blur();
    };

    const isInputFocus = inputRef?.current?.matches(':focus');

    const handleSearch = (value: string, searchSection?: string, resetSortFilter = true): void => {
      if (searchSection) {
        search_section = searchSection;
      }
      clearListingPageReferer();
      // Injectable function along with searching
      if (props.onSearch) props.onSearch(value);

      const searchSuffix = isOnSearchPage ? '' : '/search';
      let pathname = `${match.url}${searchSuffix}`;
      if (props.searchResultsPage) {
        pathname = props.searchResultsPage;
      }

      const { goBackTo, marketing_name, marketing_id, marketing_type, ...restParams } = locationParsed || {};

      let locationParams = omit(restParams, ['query', 'isModalOpen', 'product_id']);

      if (resetSortFilter) {
        clearFilter();
        locationParams = omit(locationParams, ['filters', 'sorts']);
      }

      const marketingUrlProperties = {
        marketing_id,
        marketing_name,
        marketing_type,
      };

      const searchLoactionParams = {
        ...locationParams,
        ...(isMarketingListing ? marketingUrlProperties : {}),
        query: value,
      };

      const seachLoactionQuery = qs.stringify(searchLoactionParams, BRACKET_QS_OPTIONS);

      const isSearchSectionAutocomplete = search_section?.includes('autocomplete_listing');
      const typed_query = isSearchSectionAutocomplete ? getSearchTypedQuery() : undefined;

      history.push({
        pathname,
        search: seachLoactionQuery,
        state: {
          ...(history.location.state as any),
          goBackTo,
          initial_page: eventPageIdentity,
          search_section,
          typed_query,
        },
      });

      if (isGeneralSearch) setRecentlySearchedProducts({ name: value });
      setMenuOpen(false);
    };

    const handleSearchSuggestions = (): void => {
      searchSuggestions({
        query: inputValue,
        url: suggestionParams?.url || params?.url,
        params: suggestionParams?.params,
        isGeneralSearch,
      });
    };

    const handleKeyDown = (e): void => {
      switch (e.key) {
        case KeyDown.Enter: {
          e.preventDefault();
          if (!e.target.value || e.target.value.trim() === '') return;

          if (e.target.value === query) {
            setMenuOpen(false);
            return;
          }
          handleSearch(e.target.value, '');
          if (props.onPressEnter) props.onPressEnter(e);
          break;
        }
        case KeyDown.Escape: {
          setMenuOpen(false);
          if (props.onPressEscape) props.onPressEscape(e);
          break;
        }
        default: {
          if (props.defaultKeyDown) props.defaultKeyDown(e);
          break;
        }
      }
    };

    const handleValueChange = (
      value: string,
      state: ControllerStateAndHelpers<ISearchItem> & { type?: StateChangeTypes },
    ): void => {
      if (value === undefined || value === inputValue) return;

      if (detailPageId && detailPageId === state?.selectedItem?.id) return;
      if (props.onDownshiftStateChange) props.onDownshiftStateChange(value);

      if (!isMenuOpen) setMenuOpen(true);
      if (state?.type !== Downshift.stateChangeTypes.changeInput) return;

      if (value !== '' && value !== delayedValue) {
        setSearchSuggestionLoading(true);
      } else {
        setSearchSuggestionLoading(false);
      }

      setInputValue(value);
    };

    const handleDownshiftChange = (selectedItem: any): void => {
      if (!selectedItem) return;

      setMenuOpen(false);
      if (detailPageId && detailPageId === selectedItem?.id) return;

      const prevSearchSection = (history.location?.state as any)?.search_section;
      search_section = selectedItem.sectionId || prevSearchSection || search_section;

      if ((!selectedItem?.id && selectedItem?.name) || selectedItem?.id === selectedItem?.name) {
        handleSearch(selectedItem.name, search_section);
        setInputValue(selectedItem.name);
      } else {
        clearProductListing();
        const analyticQuery = inputValue && inputValue !== '' ? inputValue : selectedItem?.name;
        const queryObject = {
          marketing_id: marketing?.id,
          marketing_name: marketing?.name,
          ...(analyticQuery ? { query: analyticQuery } : {}),
        };

        const locationSearch = qs.stringify(queryObject, BRACKET_QS_OPTIONS);
        const pathname = `/${getMarketId()}/product/${selectedItem?.id}`;

        const locationData = {
          ...locationParsed,
          ...{ query: analyticQuery, marketing_id: marketing?.id, marketing_name: marketing?.name || '' },
        };
        const isStockOut = !selectedItem?.in_stock;
        const indexNumber =
          selectedItem?.title === SECTION_TITLE_TRANSLATION.PRODUCTS &&
          [
            BasePageTracker.GENERAL_SEARCH,
            BasePageTracker.HOME,
            BasePageTracker.PRODUCT_DETAIL,
            BasePageTracker.CATEGORY_LISTING,
          ].includes(eventPageIdentity)
            ? selectedItem.index
            : undefined;

        const productDetailPayload = SegmentPropsBuilder.buildProductDetailMapping(
          selectedItem,
          isStockOut,
          locationData,
          search_section,
          props.campaign,
          indexNumber,
          eventPageIdentity,
          true,
        );

        // TODO: supporting SPT-16792: to enable selected on click suggestion product sends pt_product_search, just pass - category_ids - on 2nd params
        trackSearchSuggestion(inputValue, [], isGeneralSearch, search_section);

        trackProductDetail(productDetailPayload);

        history.push({
          pathname,
          search: locationSearch,
          state: {
            search_section,
            base_page: eventPageIdentity,
            indexNumber,
            fromSuggestion: true,
          },
        });
      }

      if (props.onDownshiftChange) props.onDownshiftChange(selectedItem);
    };

    const handleClear = (): void => {
      if (props.onClear) props.onClear();
      setInputValue('');
    };

    const handleCancel = (): void => {
      if (props.onCancel) props.onCancel();
    };

    const handleOnClick = (): void => {
      setMenuOpen(true, {
        trackEventPage: true,
        callMarketingFeatures: true,
      });
      setCloseSnackbarCart();

      if (props.onClick) props.onClick();
    };

    const handleFocus = (): void => {
      if (props.onFocus) props.onFocus();
      if (inputValue && suggestionsQuery !== inputValue && isOnSearchPage) handleSearchSuggestions();
    };

    const initialSearchPageCondition = isOnSearchPage && isMenuOpen && (query || filters?.length);
    const initialRegularCondition = !isOnSearchPage && isMenuOpen;

    const handleBackButton = (): void => {
      if (initialRegularCondition || initialSearchPageCondition) {
        setMenuOpen(false, { trackEventPage: true });
        if (autoFill && !inputValue) setInputValue(query);
        if (props.onClose) props.onClose();
      } else if (props.onBack) {
        setListingPageReferer(listingPage);
        props.onBack();
      } else if (goBackTo) {
        history.push(goBackTo as string);
      } else {
        setListingPageReferer(listingPage);
        history.goBack();
      }
    };

    /**
     * NOTE
     * can be removed and create one saerch handler hook or move to the parent
     * or on load handled by parent component
     * and this component accept fetchMore to rigger when searching (not onload)
     */

    const isSortsSynched = isEqual(props.selectedSorts, locationParsed.sorts || []) && props.reinitialize;
    const isFilterSynched = isEqual(props.selectedFilters, locationParsed.filters || []) && props.reinitialize;
    const lastBackStackSearch = props.backStack[(props.backStack?.length || 0) - 1]?.search;

    useEffect(() => {
      if (!isMarketingAvailable) return;
      if (!isOnSearchPage) return;
      if (!isFilterSynched || !isSortsSynched) return;

      const isValidQuery = !!query && query !== props.previousQuery;

      // isValidCategoryIds
      const previousLocationParsed = qs.parse(lastBackStackSearch, BRACKET_QS_OPTIONS) as ILocationProps;
      const { filters: previousFilters } = previousLocationParsed || {};
      const isValidCategoryIds =
        [BasePageTracker.CATEGORY_LISTING].includes(eventPageIdentity) &&
        (isEqual(previousFilters, locationParsed.filters || []) || !lastBackStackSearch);

      const mappedFilters = categoryFilterSeparator(locationParsed?.filters || []);
      if (isValidQuery || isValidCategoryIds) {
        clearProductListing();
        handleBlurOnSearch();
        if (props.searchUrl) {
          params.url = props.searchUrl;
        }

        fetchProductListing({
          caller: 'search suggestions',
          ...params,
          params: {
            ...params?.params,
            query,
            filters: mappedFilters.filters,
            category_ids: mappedFilters.category_ids,
            sorts: locationParsed?.sorts || [],
          },
        });

        if (autoFill) setInputValue(query);
      } else if (!query && !filters?.length) {
        setMenuOpen(true, { callMarketingFeatures: true });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, isMarketingAvailable, isSortsSynched, lastBackStackSearch, isFilterSynched]); // NOTE: review this dependency

    useEffect(() => {
      if (!isMenuOpen) return;
      if (!delayedValue || delayedValue === '') {
        clearSuggestions();
        return;
      }
      handleSearchSuggestions();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayedValue]);

    // FIXME: should be handled by BE, field filter doesn't work
    const suggestionSelectedFields = suggestionParams?.params?.fields ?? [];
    let generalSections = suggestions;
    generalSections = updateSuggestionThumbnail(generalSections, suggestionSelectedFields);

    return (
      <Downshift
        id={eventPageIdentity}
        isOpen={isMenuOpen}
        onChange={handleDownshiftChange}
        defaultIsOpen={isMenuOpen}
        itemToString={itemToString}
        inputValue={inputValue}
        stateReducer={downshiftStateReducer}
        onInputValueChange={handleValueChange}
      >
        {({
          getInputProps,
          isOpen: isDownshiftOpen,
          getItemProps,
          highlightedIndex,
          selectedItem,
          inputValue: inputValueDownshift,
          clearSelection,
          clearItems,
          reset,
        }) => (
          <div style={{ width: '100%' }}>
            <Search
              full={full}
              autoFocus={props.autoFocus}
              placeholder={props.placeholder}
              onBack={() => {
                handleBackButton();

                if (!autoFill) {
                  reset({ inputValue: '' });
                  setInputValue('');
                  clearSuggestions();
                }
              }}
              loading={props.loading}
              onCancel={handleCancel}
              getInputProps={getInputProps}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              clearSelection={() => {
                handleClear();
                clearSelection();
                clearItems();
                focusOnClear();
              }}
              readOnly={readOnly}
              onClick={handleOnClick}
              hideBackButton={!!props.hideBackOnClose && !isMenuOpen}
              inputRef={inputRef}
            />
            {isDownshiftOpen && (
              <SearchMenu
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                isInitial={inputValueDownshift === ''}
                generalSections={generalSections}
                fixedSections={fixedSections}
                initialSections={initialSections}
                inputValue={inputValueDownshift}
                onSearch={handleSearch}
                menuOpen={isDownshiftOpen}
                listingPage={listingPage}
                onSelect={handleDownshiftChange}
                isInputFocus={isInputFocus}
              />
            )}
          </div>
        )}
      </Downshift>
    );
  };

export default SearchMenuSuggestions;
