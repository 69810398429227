import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-i18next';

import { PharmacyOrderStatus, OrderStatus, ActivityStatus } from 'services';

import { S } from './activity-card.styles';
import { ActivityCardStatus } from './activity-card-status.component';
import { DateTime } from '../date-time';

interface Props {
  t: any;
  po_number: string;
  status: PharmacyOrderStatus | OrderStatus;
  activityStatus: ActivityStatus;
  dateTime: Date | string;
  isRead: boolean;
  onClick: () => void;
}

const ActivityCardBase: React.FC<React.PropsWithChildren<Props>> = ({ t, po_number, status, dateTime, isRead, onClick, activityStatus }) => {
  const [read, setRead] = useState(isRead);

  const markActivityAsRead = (): void => {
    onClick();
    if (!read) setRead(true);
  };

  return (
    <S.Card
      isRead={!!read}
      data-testid={`activity-card-component-${po_number}`}
      data-test-po-number={po_number}
      onClick={markActivityAsRead}
    >
      <S.Row>
        <Grid container>
          <DateTime dateTime={dateTime} />
          <ActivityCardStatus status={status} data-testid="activity-card-status-component" />
        </Grid>
        {!read && <S.Badge data-testid="activity-card-component-badge" />}
      </S.Row>
      <S.Title data-testid="activity-card-component-title">{t(`activityStatus.title.${activityStatus}`)}</S.Title>
      <S.Description data-testid="activity-card-component-description">
        {t(`activityStatus.description.${activityStatus}`, { po_number })}
      </S.Description>
    </S.Card>
  );
};

export const ActivityCard = translate('orders')(ActivityCardBase);
