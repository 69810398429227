import { AnalyticsTag } from '../interfaces';

export const analyticsTagKey = 'analytics_tag';

export const getAnalyticsTag = (): AnalyticsTag => {
  let campaign;
  let channel;
  let feature;

  const branch_io_payload = localStorage.getItem(analyticsTagKey);
  if (branch_io_payload) {
    const cache = JSON.parse(branch_io_payload);
    campaign = cache['~campaign'];
    channel = cache['~channel'];
    feature = cache['~feature'];
  }
  return {
    campaign,
    channel,
    feature,
  };
};

export const setAnalyticsTag = (data): void => {
  if (data?.data_parsed) {
    localStorage.setItem(analyticsTagKey, JSON.stringify(data?.data_parsed));
  }
};

export const removeAnalyticsTag = (): void => {
  localStorage.removeItem(analyticsTagKey);
};
