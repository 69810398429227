import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

import { HeaderContent as HeaderContentBase } from 'components/common/header';
import * as colors from 'components/styles/colors';

const Header = styled(Grid)`
  background: ${colors.BLUE};
  text-transform: capitalize;
  width: 100vw;
  padding: 18px 20px;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 26px;

  && .header-content-title {
    font-size: 20px !important;
    color: ${colors.WHITE};
  }
`;

const HeaderContent = styled(HeaderContentBase)``;

const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 800;
    text-transform: capitalize;
    color: ${colors.TEXT_LIGHT};
  }
`;

export const S = { Header, HeaderContent, Title };
