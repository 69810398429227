import Grid from '@material-ui/core/Grid';

import styled from 'styled-components';

export const PrepSignedPurchaseOrderContainer = styled(Grid)`
  .expand-to-screen {
    box-shadow: none;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    margin: 0;
    width: 100%;
    ::before {
      content: none;
    }
    background-color: #f4eae2;
  }

  .po-title {
    font-size: 9pt;
    font-weight: bolder;
    color: #cb7331;
    padding-bottom: 8px;
  }

  .po-details {
    font-size: 8pt;
    padding-bottom: 8px;
    color: #666666;
  }

  .payment-border-header {
    padding-bottom: 8px;
  }

  .icon {
    height: 92px;
    width: 83px;
  }
`;
