import React from 'react';
import styled from 'styled-components';
import { TranslateProps } from 'utils/Localization/types';
import { Button as MUIButton, Dialog, DialogContent, DialogTitle, DialogActions, TextField } from '@material-ui/core';

export interface StateProps {
  isOpen: boolean;
}

export interface DispatchProps {
  hideDialog: () => void;
  submitProductRequest: (
    productName: string,
    message: {
      success: string;
      failure: {
        lengthLessThan3: string;
        unknown: string;
      };
    },
  ) => void;
}

type Props = StateProps & DispatchProps & TranslateProps;

const Button = styled(MUIButton)`
  && {
    margin-right: 1rem;
    text-transform: none;
  }
`;

export const ProductRequestDialog: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { t, isOpen, hideDialog, submitProductRequest } = props;

  const messageCollection = {
    success: t('thankYou'),
    failure: {
      lengthLessThan3: t('lengthLessThan3'),
      unknown: t('oops'),
    },
  };

  const [productName, setProductName] = React.useState('');

  const handleSubmission = (productName: string): void => {
    const trimmedProductName = productName.trim();
    submitProductRequest(trimmedProductName, messageCollection);
    setProductName('');
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={hideDialog}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent data-testid="product-reco-dialog">
        <TextField
          data-testid="input-product-reco"
          autoFocus
          fullWidth
          multiline
          required
          type="text"
          rows={2}
          label={t('inputLabel')}
          placeholder={t('inputPlaceholder')}
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button data-testid="recommend-product-btn" onClick={() => handleSubmission(productName)} color="primary">
          {t('buttonSubmit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
