import numeral from 'numeral';
import moment from 'moment';
import momentTz from 'moment-timezone';
import i18next from 'i18next';

import {
  Locale,
  DEFAULT_LOCALE,
  DEFAULT_LANG,
  SupportedLanguageList,
  SupportedLocaleList,
  Language,
  EN_LANG,
  EN_LOCALE,
  ID_LANG,
  ID_LOCALE,
  TH_LANG,
  TH_LOCALE,
} from './types';

export const InitialMarketConfig = {
  id: {
    locale: ID_LOCALE,
    lang: ID_LANG,
  },
  th: {
    locale: TH_LOCALE,
    lang: TH_LANG,
  },
  others: {
    locale: EN_LOCALE,
    lang: EN_LANG,
  },
};

export const setNumeralLocale = (locale: Locale): void => {
  numeral.locale(locale);
};

export const setMomentLocale = (locale: Locale): void => {
  moment.locale(locale);
  momentTz.locale(locale);
};

export const setLanguage = (language: Language): void => {
  if (!SupportedLanguageList.includes(language)) language = DEFAULT_LANG;

  i18next.changeLanguage(language);
};

export const setLocale = (locale: Locale = DEFAULT_LOCALE): void => {
  if (!SupportedLocaleList.includes(locale)) locale = DEFAULT_LOCALE;

  setNumeralLocale(locale);
  setMomentLocale(locale);
};

export const setDefaultConfig = (marketId: string): void => {
  const config = InitialMarketConfig[marketId] || InitialMarketConfig.others;

  setNumeralLocale(config.locale);
  setMomentLocale(config.locale);
  i18next.changeLanguage(config.language);
};
