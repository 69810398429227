import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { store } from 'store';

export const initialiseReporting = (): void => {
  try {
    const state = store.getState();
    const isSentryEnabled = !!state.config.market.thirdPartyConfig?.sentry?.enabled;
    if (!isSentryEnabled) {
      // eslint-disable-next-line no-console
      console.log(`Sentry is disabled`);
      return;
    }

    const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    if (!SENTRY_DSN || SENTRY_DSN === 'disabled') return;

    const appVersion = process.env.REACT_APP_VERSION;
    /* eslint-disable no-restricted-globals */
    const environment = location.hostname;
    const traceRate = process.env.REACT_APP_SENTRY_TRACE_RATE as string;

    Sentry.init({
      dsn: SENTRY_DSN,
      release: `${appVersion}`,
      integrations: [new Integrations.BrowserTracing()],
      environment,
      tracesSampleRate: parseFloat(traceRate),
      normalizeDepth: 3, // redux state depth
    });
  } catch (error) {
    /* eslint-disable no-console */
    console.error('Failed to initialise sentry', error);
  }
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === 'SUCCEED_AUTH') {
      const { organization_id: id, email, user_name: username } = action.coreUser;
      Sentry.setUser({ id, email, username });
    }
    return action;
  },
});

export const recordException = (error: Error, method: string, extra?: Record<string, any>): void => {
  try {
    const state = store.getState();
    const isSentryEnabled = !!state.config.market.thirdPartyConfig?.sentry?.enabled;
    if (!isSentryEnabled) {
      // eslint-disable-next-line no-console
      console.log(`Sentry is disabled`);
      return;
    }
    const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    if (!SENTRY_DSN || SENTRY_DSN === 'disabled') return;
    Sentry.captureException(error, { tags: { method }, extra });
  } catch (error) {
    /* eslint-disable no-console */
    console.error('Failed to send exception to sentry', error);
  }
};
