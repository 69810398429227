import React from 'react';

import ShowIf from 'components/views/ShowIf';
import { S } from './search-input.styles';

interface Props {
  id: string;
  placeholder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClear: React.ChangeEventHandler<HTMLInputElement>;
}

export const SearchInput: React.FC<React.PropsWithChildren<Props>> = ({ placeholder, onChange, value, onClear, id }) => (
  <S.Container id={id}>
    <S.Icon />
    <S.Input
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type="search"
      data-testid="order-search-input"
    />
    <ShowIf condition={value.length > 0}>
      <S.Clear onClick={onClear} />
    </ShowIf>
  </S.Container>
);
