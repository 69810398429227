import { AxiosInstance } from 'axios';
import { getTokenByRefreshToken, getNewToken } from '../../../store/reducers/Auth/actions';
import { IRefreshToken } from './refreshToken.interface';

let isRefreshing = false;
let subscribers: IRefreshToken[] = [];

function subscribeTokenRefresh(cb: IRefreshToken) {
  subscribers.push(cb);
}

function onRefreshed(token: string) {
  subscribers.map((cb) => cb(token));
}

export function registerRefreshTokenInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { response, config: originalRequest } = error;

      if (response?.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          getTokenByRefreshToken()
            .then(onRefreshed)
            .catch(() => getNewToken().then(onRefreshed))
            .finally(() => {
              isRefreshing = false;
              subscribers = [];
            });
        }

        const retryOriginalRequest = new Promise((resolve) => {
          subscribeTokenRefresh((token: string) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });

        return retryOriginalRequest;
      }
      return Promise.reject(error);
    },
  );
}
