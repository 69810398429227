import React from 'react';
import { Grid } from '@material-ui/core';

import { Modal, ConditionalRender } from 'components/common';

import { S } from './orders-modal-view-tax-photo.styles';

interface Props {
  // eslint-disable-next-line react/require-default-props
  url?: string | null;
  titleText: string;
  onClose: () => void;
}

export const OrderModalViewPhoto: React.FC<React.PropsWithChildren<Props>> = ({ url, titleText, onClose }) => {
  const handleClose = (): void => {
    onClose();
  };
  const fileType = url ? url.substring(url.length - 3) : '';

  return (
    <Modal open={!!url} onClose={handleClose}>
      <S.Wrapper>
        <S.Header>
          <Grid container alignItems="center" justify="center">
            <S.Title>View {titleText}</S.Title>
          </Grid>
          <S.CancelBtn onClick={handleClose} />
        </S.Header>
        <ConditionalRender condition={!!url}>
          {fileType === 'pdf' ? (
            <iframe style={{ overflow: 'scroll', position: 'absolute', height: '100%', border: 'none' }} title="pdf-photo" src={`${url}`} width="100%" height="100%" />
          ) : (
            <img
              alt="document"
              style={{ overflow: 'scroll' }}
              title="img-photo"
              src={`${url}`}
              width="100%"
              height="100%"
            />
          )}
        </ConditionalRender>
      </S.Wrapper>
    </Modal>
  );
};
