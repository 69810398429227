import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Card as SharedCard } from 'components/common';

import * as colors from 'components/styles/colors';

const Card = styled(SharedCard)`
  padding: 22px 10px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 1px #676f82;
`;

const Informations = styled.div`
  padding: 4px 4px 16px 10px;
  border-left: 2px solid #b3b8c8;
  margin-top: -4px;
`;

const Date = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 600;
    color: #545454;

    &::before {
      content: '\\2022';
      font-size: 20px;
      margin-left: -4px;
      margin-right: 6px;
      vertical-align: middle;
      position: relative;
      bottom: 2px;
    }
  }
`;

const Text = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #9399a8;
  line-height: 19px;
  &&.title {
    font-size: 14px;
    text-transform: capitalize;
  }
  &&.active {
    font-weight: 700;
  }
`;

const ButtonDetail = styled(Button)`
  && {
    margin: 10px 0 0;
    box-shadow: none;
    border: 1px solid #eef0f4;
    border-radius: 5px;
    background-color: #eef0f4;
    color: #9399a8;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
  }
`;

const DetailContainer = styled.div<any>`
  margin-top: -2px;

  &:last-child .informations {
    border-left: 2px solid #fff;
  }

  && {
    &* {
      color: #9399a8;
    }
    &.informations {
      border-left-color: #9399a8;
    }
  }

  &&.active {
    ${Date} {
      &::before {
        color: ${(props) => colors.getOrderStatusColor(props, 'primary')};
      }
    }

    ${Informations} {
      border-color: ${(props) => colors.getOrderStatusColor(props, 'primary')};
    }

    ${Text} {
      color: #181d29;
      font-size: 13px;
      font-weight: 400;
      &.title {
        color: ${(props) => colors.getOrderStatusColor(props, 'primary')};
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
`;

const DetailHeader = styled.div`
  display: flex;
`;

const Time = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: normal;
    color: #717786;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

export const S = {
  DetailContainer,
  Card,
  DetailHeader,
  Date,
  Time,
  Informations,
  Text,
  ButtonDetail,
};
