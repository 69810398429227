import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import * as colors from '../../styles/colors';

const Wrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-left: -25px;
  margin-right: -25px;
  padding: 2px 25px;

  && {
    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-track {
      padding-right: 25px;
    }

    &::after {
      content: '';
      flex: 0 0 10px;
    }
  }
`;

const DotWrapper = styled.div`
  display: flex;
`;

interface DotProps {
  active?: boolean;
}

const Dot = styled.span`
  height: 6px;
  width: 6px;
  margin-right: 3px;
  background-color: ${(p: DotProps) => (p.active ? colors.REBRAND_GREEN : colors.GRAY90)};
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
`;

const SkeletonWrapper = styled.div`
  display: flex;

  span {
    margin-right: 8px;
  }
`;

const VirtualAccountFooterContainer = styled(Grid).attrs({ container: true })`
  && {
    margin-top: 10px;
  }
`;

export const S = { Wrapper, SkeletonWrapper, Dot, DotWrapper, VirtualAccountFooterContainer };
