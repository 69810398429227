import { PaymentDirect, ListMeta } from 'services';
import { SetPurchaseOrderDetails } from '../purchase-order';

export enum PaymentDirectActionType {
  SET_PAYMENT_DIRECT_LOADING = '@@payment-direct/SET_PAYMENT_DIRECT_LOADING',
  SET_PAYMENT_DIRECT_LIST = '@@payment-direct/SET_PAYMENT_DIRECT_LIST',
  SET_PAYMENT_DIRECT_DETAIL = '@@payment-direct/SET_PAYMENT_DIRECT_DETAIL',
  RESET_PAYMENT_DIRECT_DETAIL = '@@payment-direct/RESET_PAYMENT_DIRECT_DETAIL',
  SET_PAYMENT_DIRECT_ERROR = '@@payment-direct/SET_PAYMENT_DIRECT_ERROR',
  RESET_PAYMENT_DIRECT_LIST = '@@payment-direct/RESET_PAYMENT_DIRECT_LIST',
}

export interface SetPaymentDirectLoading {
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_LOADING;
  payload: { loading: boolean };
}

export interface SetPaymentDirectList {
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_LIST;
  payload: { data: PaymentDirect[]; meta: ListMeta };
}

export interface SetPaymentDirectDetail {
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_DETAIL;
  payload: { data: PaymentDirect };
}

export interface SetPaymentDirectError {
  type: PaymentDirectActionType.SET_PAYMENT_DIRECT_ERROR;
  payload: { error: string };
}

export interface ResetPaymentDirectList {
  type: PaymentDirectActionType.RESET_PAYMENT_DIRECT_LIST;
}

export interface ResetPaymentDirectDetail {
  type: PaymentDirectActionType.RESET_PAYMENT_DIRECT_DETAIL;
}

export type PaymentDirectActions =
  | SetPaymentDirectLoading
  | SetPaymentDirectList
  | SetPaymentDirectDetail
  | SetPaymentDirectError
  | ResetPaymentDirectList
  | SetPurchaseOrderDetails
  | ResetPaymentDirectDetail;
