export enum AnalyticsEvent {
  Identify = 'IDENTIFY',
  Track = 'TRACK',
  Page = 'PAGE',
}

export enum PushActionDeliveryType {
  Bounced = 'Push Notification Bounced',
  Received = 'Push Notification Received',
  Tapped = 'Push Notification Tapped',
}

export type AnalyticsPayload = Record<string, any>;

export interface Analytics {
  attach(provider: AnalyticsProvider): void;
  detach(provider: AnalyticsProvider): void;
  request(type: AnalyticsEvent, event: string, payload: AnalyticsPayload): void;
  isDebug(): boolean;
}

export interface AnalyticsProvider {
  init(token: string, errorCallback?: (error: string | null) => void, dataCallback?: (data: any) => void): void;
  identify(id: string, payload: AnalyticsPayload, analytics: Analytics): void;
  track(event: string, payload: AnalyticsPayload, analytics: Analytics): void;
  page(event: string, payload: AnalyticsPayload, analytics: Analytics): void;
}

export interface AnalyticsTag {
  campaign: string;
  channel: string;
  feature: string;
}
