export const SET_OPEN_SNACKBAR = 'SET_OPEN_SNACKBAR';
export type SET_OPEN_SNACKBAR = typeof SET_OPEN_SNACKBAR;

export const SET_CLOSE_SNACKBAR = 'SET_CLOSE_SNACKBAR';
export type SET_CLOSE_SNACKBAR = typeof SET_CLOSE_SNACKBAR;

export const SET_OPEN_SNACKBAR_CART = 'SET_OPEN_SNACKBAR_CART';
export type SET_OPEN_SNACKBAR_CART = typeof SET_OPEN_SNACKBAR_CART;

export const SET_CLOSE_SNACKBAR_CART = 'SET_CLOSE_SNACKBAR_CART';
export type SET_CLOSE_SNACKBAR_CART = typeof SET_CLOSE_SNACKBAR_CART;
